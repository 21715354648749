import React from "react";
import styled from "styled-components";
import { forEach, includes } from "lodash";

const Header = ({
  _id,
  title,
  handleSelectAllSlides,
  selectSlides,
  contentSlidesListId
}) => {
  let selectedSlides = [];
  forEach(contentSlidesListId[_id], singleSlide => {
    if (
      selectSlides.indexOf(singleSlide) > -1 &&
      !includes(selectedSlides, singleSlide)
    )
      selectedSlides.push(singleSlide);
  });

  let changeText =
    (contentSlidesListId[_id] || []).length === selectedSlides.length;
  return (
    <TitleContainer>
      <HeaderText>{title}</HeaderText>
      <SelectAllText
        onClick={() => handleSelectAllSlides(_id, changeText)}
      >{`[${
        changeText ? "Deselect All Slides" : "Select All Slides"
      }]`}</SelectAllText>
    </TitleContainer>
  );
};

const TitleContainer = styled.div``;

const HeaderText = styled.h4`
  padding-left: 11px;
  display: inline-block;
  vertical-align: top;
  color: ${props => props.theme.COLOR.MAIN};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 18px;
  font-weight: bold;
  opacity: 0.5;
`;

const SelectAllText = styled.span`
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  opacity: 0.9;
  cursor: pointer;
`;

export default Header;
