//libraries
import React from "react";
import styled from "styled-components";
import { get, map } from "lodash";
import Module from "./components/module";
// components
import { ThumbNail, List } from "assets/icons";
import hexToRgba from "utils/hexToRgba";

const NONE_OPTION_TEXT =
  "If you’d like to add Topics to your document, please select either “Single” or “Stack” on the right.";

const ModuleSelector = props => {
  let {
    handleModuleStateChange,
    isLayoutTypeThumbnail,
    modules,
    contentItemRemovalHandler,
    topicsContentBlock
  } = props;
  return (
    <>
      <ModuleTypeSelector>
        <LayoutSelector>
          <IconWrapper
            onClick={() =>
              handleModuleStateChange({
                key: "isLayoutTypeThumbnail",
                value: true
              })
            }
            active={isLayoutTypeThumbnail}
          >
            <ThumbNail />
          </IconWrapper>
          <IconWrapper
            active={!isLayoutTypeThumbnail}
            onClick={() =>
              handleModuleStateChange({
                key: "isLayoutTypeThumbnail",
                value: false
              })
            }
          >
            <List />
          </IconWrapper>
        </LayoutSelector>
        <Modules
          modules={get(modules, `length`)}
          topicsContentBlock={get(topicsContentBlock, `length`)}
        >
          <ModuleContainer>
            {!get(modules, `length`) && get(topicsContentBlock, `length`) ? (
              <NoneLayoutText>{NONE_OPTION_TEXT}</NoneLayoutText>
            ) : null}
            {!get(topicsContentBlock, `length`) ? (
              <NoDataText>No data found.</NoDataText>
            ) : null}
            {map(modules, eachModule => {
              return (
                <Module
                  key={get(eachModule, `_id`)}
                  eachModule={eachModule}
                  isLayoutTypeThumbnail={isLayoutTypeThumbnail}
                  contentItemRemovalHandler={contentItemRemovalHandler}
                />
              );
            })}
          </ModuleContainer>
        </Modules>
      </ModuleTypeSelector>
    </>
  );
};

const NoneLayoutText = styled.span`
  display: block;
  margin: auto;
  width: 100%;
  font-size: 12px;
  text-align: center;
`;

const NoDataText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
`;

const ModuleContainer = styled.div`
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
  height: 100%;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
    border-radius: 4px;
    outline: 1px solid ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
  }
`;

const Modules = styled.div`
  margin: 20px 0 50px;
  height: 100%;
  padding: ${props =>
    !props.modules && props.topicsContentBlock ? "20px" : "20px 0 20px 20px"};
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
`;

const ModuleTypeSelector = styled.div`
  width: 30%;
`;

const LayoutSelector = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0;
`;

const IconWrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;
  svg {
    vertical-align: middle;

    g {
      fill-opacity: ${props => (props.active ? "1" : "0.5")};
      fill: ${props => props.theme.COLOR.BROWNISH_GREY};
    }
  }

  :hover {
    g {
      fill-opacity: 1;
      fill: ${props => props.theme.COLOR.BROWNISH_GREY};
      transition: 0.2s ease-in-out;
    }
  }
`;

export default ModuleSelector;
