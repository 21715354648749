//import network services
import {
  getContentRepoList,
  getContentSlideListOfSelectedRepo
} from "./services/setupContentRepoList";
import { triggerPresentationBuild } from "./services/triggerPresentationBuild";

import {
  getCustomerLogoList,
  createNewCustomerLogo,
  deleteLogo
} from "./services/setupCustomerLogoList";

import { getThemeList, getCoverList } from "./services/themeCoverList";
import { getDynamicCoverFieldsList } from "./services/presentationDynamicFields";
import { getImageCategoryListOfSelectedRepo } from "./services/imageCategoryList";

import {
  getLibraryBySearch,
  getLibraryByTopic,
  getLibraryFiltersList,
  loggingDownloadInDatabase,
  searchLibraryResultSlides
} from "./services/libraryTopicSearchFilters";

import {
  getDividerListOfSelectedRepo,
  getBlankSlideListOfSelectedRepo
} from "./services/dividerBlankSlideList";

import {
  getMultipleSlidesIdForMetadata,
  getSlideDetails,
  downloadDeck,
  stopDownloadDeckLoading
} from "./services/libraryOverlay";

import {
  createPresentation,
  getPresentationDetail,
  resetPresentationDetails
} from "./services/saveBuildData";
import { getPresentationBuildStatus } from "./services/presentationBuildStatus";

import { getFilePollingStatus } from "../admin/contentRepo/themes/services";
import { pmSetSetupDetailsData } from "./services/documentBuilderSetupPanel";
import { pmSetSelectedContent } from "./services/documentBuilderSetupPanel";
import { onSelectedFiltersChange } from "./services/documentBuilderSetupPanel";


const mapStateToProps = state => {
  const {
    SUCCESS_USER_PROFILE,
    SUCCESS_LIBRARY_FILTERS,
    SUCCESS_LIBRARY_SEARCH,
    SUCCESS_LIBRARY_TOPIC,
    SUCCESS_IMAGE_CATEGORY_LIST,
    SUCCESS_DYNAMIC_COVER_FIELDS,
    SUCCESS_SETUP_CONTENTREPO,
    SUCCESS_CUSTOMER_LOGO,
    SUCCESS_THEME,
    SUCCESS_COVER,
    LOADING_THEME,
    LOADING_COVER,
    LOADING_SETUP_CONTENTREPO,
    LOADING_DYNAMIC_COVER_FIELDS,
    LOADING_IMAGE_CATEGORY_LIST,
    LOADING_LIBRARY_TOPIC_SEARCH,
    LOADING_LIBRARY_FILTERS,
    LOADING_CUSTOMER_LOGO,
    LOADING_SLIDE_DETAILS,
    SUCCESS_DIVIDER_LIST,
    LOADING_DIVIDER_LIST,
    SUCCESS_BLANK_SLIDE_LIST,
    LOADING_BLANK_SLIDE_LIST,
    SUCCESS_SLIDE_DETAILS,
    SUCCESS_MULTIPLE_SLIDES_DATA,
    LOADING_SAVING_DATA,
    SUCCESS_PRESENTATION_DATA,
    SUCCESS_PRESENTATION_BUILD,
    LOADING_PRESENTATION_BUILD,
    RESET_PRESENTATION_DATA,
    SUCCESS_DOWNLOAD_DECK,
    SUCCESS_THEME_LIST,
    LOADING_THEME_LIST,
    LIBRARY_DATA_DOWNLOAD,
    LIBRARY_DATA_LOADING,
    PM_SET_SETUP_DETAILS,
    // PM For selecting Content Repositories on the Setup Panel
    PM_SET_SELECTED_CONTENT,
    pm
  } = state;

  return {
    ...LOADING_CUSTOMER_LOGO,
    ...LOADING_LIBRARY_FILTERS,
    ...LOADING_THEME,
    ...LOADING_COVER,
    ...LOADING_SETUP_CONTENTREPO,
    ...LOADING_DYNAMIC_COVER_FIELDS,
    ...LOADING_IMAGE_CATEGORY_LIST,
    ...LOADING_LIBRARY_TOPIC_SEARCH,
    ...SUCCESS_SETUP_CONTENTREPO,
    ...SUCCESS_CUSTOMER_LOGO,
    ...SUCCESS_THEME,
    ...SUCCESS_COVER,
    ...SUCCESS_DYNAMIC_COVER_FIELDS,
    ...SUCCESS_IMAGE_CATEGORY_LIST,
    ...SUCCESS_LIBRARY_SEARCH,
    ...SUCCESS_LIBRARY_TOPIC,
    ...SUCCESS_LIBRARY_FILTERS,
    ...LOADING_SLIDE_DETAILS,
    ...SUCCESS_DIVIDER_LIST,
    ...LOADING_DIVIDER_LIST,
    ...SUCCESS_BLANK_SLIDE_LIST,
    ...LOADING_BLANK_SLIDE_LIST,
    ...SUCCESS_USER_PROFILE,
    ...SUCCESS_SLIDE_DETAILS,
    ...SUCCESS_MULTIPLE_SLIDES_DATA,
    ...LOADING_SAVING_DATA,
    ...RESET_PRESENTATION_DATA,
    ...SUCCESS_PRESENTATION_DATA,
    ...SUCCESS_PRESENTATION_BUILD,
    ...LOADING_PRESENTATION_BUILD,
    ...SUCCESS_DOWNLOAD_DECK,
    ...SUCCESS_THEME_LIST,
    ...LOADING_THEME_LIST,
    ...LIBRARY_DATA_DOWNLOAD,
    ...LIBRARY_DATA_LOADING,
    pmSetupDetailsData: PM_SET_SETUP_DETAILS,
    contentRepositorySelected: PM_SET_SELECTED_CONTENT,
    pm
  };
};

const actions = {
  getContentRepoList,
  getCustomerLogoList,
  getThemeList,
  getCoverList,
  createNewCustomerLogo,
  getDynamicCoverFieldsList,
  getImageCategoryListOfSelectedRepo,
  getLibraryByTopic,
  getLibraryBySearch,
  getLibraryFiltersList,
  deleteLogo,
  getDividerListOfSelectedRepo,
  getBlankSlideListOfSelectedRepo,
  getMultipleSlidesIdForMetadata,
  getSlideDetails,
  createPresentation,
  getPresentationDetail,
  triggerPresentationBuild,
  getPresentationBuildStatus,
  resetPresentationDetails,
  downloadDeck,
  getFilePollingStatus,
  stopDownloadDeckLoading,
  getContentSlideListOfSelectedRepo,
  loggingDownloadInDatabase,
  pmSetSetupDetailsData,
  pmSetSelectedContent,
  searchLibraryResultSlides,
  onSelectedFiltersChange
};

export { mapStateToProps, actions };
