import React from "react";
import { get, map } from "lodash";

// components
import InputField from "components/inputField";
import styled from "styled-components";
import Button from "components/button";

const TemplateModal = props => {
  let {
    addNewTemplateHandler,
    templateFormFields,
    handleInputChange,
    createTemplateHandler
  } = props;

  return (
    <ContentWrapper>
      <FormHeading>Create a new Template</FormHeading>
      {map(templateFormFields, (eachField, index) => {
        return (
          <InputField
            key={index}
            label={get(eachField, `label`)}
            labelFontSize="14px"
            inputRef={get(eachField, `ref`)}
            fieldType={get(eachField, `fieldType`)}
            handleInputChange={e => handleInputChange(e, index)}
            type="text"
            error={get(eachField, `error`)}
            placeholder={get(eachField, `placeholder`)}
          />
        );
      })}
      <ButtonContainer>
        <Button
          text="Create Template"
          width="calc(30% - 2px)"
          float="none"
          buttonClass="create"
          onClick={createTemplateHandler}
        />
        <Button
          text="Cancel"
          width="calc(30% - 2px)"
          float="none"
          buttonClass="cancel"
          onClick={addNewTemplateHandler}
        />
      </ButtonContainer>
    </ContentWrapper>
  );
};

const FormHeading = styled.h4`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  padding: 4px 40px;
  width: 56%;
  margin-top: 40px;
  .create {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
`;

export default TemplateModal;
