import React from "react";
import styled, { css } from "styled-components";
import Checkbox from "components/checkbox";

const Slides = ({
  title,
  _id,
  handleCheckBoxChange,
  parentObj,
  selectSlides,
  group,
  firstGroupElem,
  lastGroupElem,
  adjacentGroupElem,
  addPresentation,
  slideDetail,
  isUpdated,
  isNew,
  isRequired,
  handleMouseOver,
  previewThumbnailUrl,
  singlePreviewSlideData,
  handleMouseLeave,
  imageOnload,
  popupPosition,
  popupHorizontalPosition,
  allSlidesData
}) => {
  let className = `${group ? "group" : ""}  slides ${
    firstGroupElem ? "first" : ""
  } ${lastGroupElem ? "last" : ""} ${
    adjacentGroupElem ? "adjacent-group" : ""
  }`;

  const parentObject = allSlidesData ? allSlidesData : parentObj;

  return (
    <CheckboxWrapper className={className} isRequired={isRequired}>
      <Checkbox
        label={title}
        id={_id}
        checked={selectSlides.indexOf(_id) > -1 ? true : false}
        handleChange={e => {
          handleCheckBoxChange(
            e.target.checked,
            _id,
            (group && group.title) || "",
            parentObj,
            slideDetail
          );
        }}
        useButton={true}
        addPresentation={() => addPresentation(_id, parentObject, slideDetail)}
        handleMouseOver={e => handleMouseOver(e, slideDetail)}
        handleMouseLeave={handleMouseLeave}
        hideTitle={true}
      />
      {isNew && <NewLabel title="New">N</NewLabel>}
      {isUpdated && <UpdateLabel title="Updated">U</UpdateLabel>}
      {previewThumbnailUrl && (singlePreviewSlideData || {})._id === _id && (
        <PreviewImagewrapper
          popupPosition={popupPosition}
          popupHorizontalPosition={popupHorizontalPosition}
        >
          <PreviewThumbnail src={previewThumbnailUrl} onLoad={imageOnload} />
        </PreviewImagewrapper>
      )}
    </CheckboxWrapper>
  );
};

const BorderCSS = css`
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  background-color: ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
  opacity: 0.3;
`;

const NewLabel = styled.span`
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  padding: 4px 5px 3px 7px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  background-color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  font-weight: bold;
  position: relative;
  right: 10px;
  top: 5px;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
`;

const UpdateLabel = styled.span`
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  padding: 4px 5px 3px 7px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  background-color: ${props => props.theme.COLOR.DODGER_BLUE};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  font-weight: bold;
  position: relative;
  right: 10px;
  top: 5px;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
`;

const CheckboxWrapper = styled.div`
  position: relative;
  vertical-align: top;
  .checkbox-container {
    width: 90%;
    padding: 5px 0 5px 5%;
    display: inline-block;
    vertical-align: top;
  }

  &.group {
    background: #eef7f9;
  }

  &.first {
    padding-top: 4px;
    position: relative;

    &:not(:first-child) {
      margin-top: 2px;
    }

    &:after {
      ${props => props.theme.SNIPPETS.BOX_SHADOW_DARK};
      ${BorderCSS};
      top: 0;
    }
  }

  &.group:not(:first-of-type).adjacent-group {
    margin-top: 10px;
  }

  &.last {
    padding-bottom: 7px;
    position: relative;
    &:before {
      ${BorderCSS};
      bottom: 0;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.38);
    }
  }

  button {
    width: calc(100% - 30px);
    padding: 1px 2% 2px;
    color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  }

  input[type="checkbox"] {
    ${props => props.isRequired && `cursor :not-allowed`}
    &:checked {
      ${props =>
        props.isRequired &&
        `background-color: ${props.theme.COLOR_PALLETE.BROWNISH_GREY}; border: solid 2px ${props.theme.COLOR_PALLETE.BROWNISH_GREY};`}
    }
  }
`;

const PreviewImagewrapper = styled.figure`
  width: 300px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 4;
  ${props =>
    props.popupPosition ? "visibility: visible" : "visibility: hidden"};
  ${props =>
    props.popupHorizontalPosition === "left" ? "left: 5%" : "right: 0"}

  ${props => (props.popupPosition === "bottom" ? "top: 30px" : "bottom: 30px")};
`;

const PreviewThumbnail = styled.img`
  width: 100%;
  display: block;
`;

export default Slides;
