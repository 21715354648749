import React, { Component } from "react";
import { get, each, map, fill } from "lodash";
import styled from "styled-components";

// components
import { tableMetaData, uploadedFilesList } from "../../data";
import UploadedFiles from "./index";

class UploadedFilesPage extends Component {
  state = {
    tableColumns: [],
    tableColumnKeys: [],
    filesList: [],
    columnWidth: []
  };

  componentDidMount() {
    this._extractColumns();
    this._extractColumnKeys();
    this._calculateColumnWidth();

    this.setState({
      filesList: uploadedFilesList
    });
  }

  _extractColumns = () => {
    let { tableColumns } = this.state;
    let columnNames = {};

    // formatting columns name as col1 : columnName
    each(tableMetaData, (eachMetaData, index) => {
      columnNames[`col${index + 1}`] = get(eachMetaData, `label`);
    });

    // pushing last action column
    columnNames[`col${tableMetaData.length + 1}`] = "Actions";

    tableColumns = [
      {
        ...columnNames
      }
    ];

    this.setState({ tableColumns });
  };

  _extractColumnKeys = () => {
    let { tableColumnsKeys } = this.state;

    tableColumnsKeys = map(tableMetaData, eachMetadata => {
      return get(eachMetadata, `key`);
    });
    tableColumnsKeys.push(""); // pushing empty string for action column to be displayed

    this.setState({
      tableColumnsKeys
    });
  };

  _calculateColumnWidth = () => {
    let { columnWidth } = this.state;
    // -70 as that width space has to be kept for actions
    let eachColumnWidth = (910 - 70) / get(tableMetaData, `length`);

    // each column width will be of same size, therefore populating same width length many times in the column width array
    columnWidth = fill(Array(tableMetaData.length), eachColumnWidth);

    // pushing width for actions,(static width as actions in this case is always going to have only one button for selection)
    columnWidth.push(70);
    this.setState({
      columnWidth
    });
  };

  // set active status true/false stating whether the row is selected or not
  setRowActiveStatus = setActiveId => {
    let { filesList } = this.state;
    each(filesList, eachItem => {
      if (eachItem._id === setActiveId) {
        eachItem.active = true;
      } else {
        eachItem.active = false;
      }
    });

    this.setState({
      filesList
    });
  };

  showIcon = rowInfo => {
    return (
      <IconWrapper>
        <SelectButton
          className="select-rates"
          onClick={e => {
            this.setRowActiveStatus(get(rowInfo, `_id`));
            this.props.setSelectedFile(get(rowInfo, `_id`));
          }}
        >
          Select
        </SelectButton>
      </IconWrapper>
    );
  };

  renderHead = () => {
    return (
      <div className="heading">
        <span>Documents</span>
      </div>
    );
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <UploadedFiles {...stateMethodProps} />;
  }
}

const IconWrapper = styled.span`
  display: inline-block;
`;

const SelectButton = styled.button`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  outline: none;
  width: 64px;
  border: 1px solid ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  height: 22px;
  background: ${props => props.theme.COLOR.WHITE};
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s all ease;
  font-size: 12px;
  font-weight: 900;
  &.active-group,
  &:hover {
    border-color: ${props => props.theme.COLOR.WHITE};
    background: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
    color: ${props => props.theme.COLOR.WHITE};
  }
`;

export default UploadedFilesPage;
