import React from "react";
import styled from "styled-components";

//icons
import { Folder } from "assets/icons";

//components
import ImageDetails from "./components/imageDetails";

const ImageGrid = props => {
  let {
    imageFolderDetails,
    imageList,
    editImageDetailsHandler,
    imageCategory,
    imageListLoading,
    selectedTab
  } = props;

  let userNote = "No images found in this folder.";

  if (
    selectedTab !== "folder" &&
    Object.keys(imageFolderDetails || {}).length
  ) {
    userNote =
      imageFolderDetails.level === 0
        ? "No attributes/images found in this category."
        : "No images linked to the given attribute.";
  }
  return Object.keys(imageFolderDetails || {}).length ? (
    <>
      <FolderTitleContainer>
        <FolderIconWrapper>
          <Folder size={22} />
        </FolderIconWrapper>
        <Title>{imageFolderDetails.title || ""}</Title>
      </FolderTitleContainer>
      {Array.isArray(imageList) && imageList.length ? (
        <ImagesContainer>
          {imageList.map((item, index) => {
            return (
              <ImageDetails
                item={item}
                key={index}
                editImageDetailsHandler={editImageDetailsHandler}
              />
            );
          })}
        </ImagesContainer>
      ) : (
      <NotFound>{userNote}</NotFound>
      )}
    </>
  ) : Array.isArray(imageCategory) && imageCategory.length ? (
    <NotFound>Please select a category.</NotFound>
  ) : (
    !imageListLoading && <NotFound>No categories found.</NotFound>
  );
};

export default ImageGrid;

const FolderTitleContainer = styled.div`
  padding: 18px 24px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
`;

const ImagesContainer = styled.div``;

const Title = styled.h2`
  width: calc(100% - 40px);
  display: inline-block;
  color: ${props => props.theme.COLOR.HEADING};
  font-size: 18px;
  font-weight: bold;
`;

const FolderIconWrapper = styled.a`
  margin-right: 16px;
  display: inline-block;
  vertical-align: top;
`;

const NotFound = styled.span`
  padding: 24px;
  display: block;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
`;
