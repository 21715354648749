import React from "react";
import styled, { css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { FaRegQuestionCircle } from "react-icons/fa";
import { SlidePreviewRightArrow, SlidePreviewLeftArrow } from "assets/icons";
import { get } from "lodash";

import GearModal from "../../gearModal";

import {
  SaveBorder,
  PreviewBorder,
  ShareBordered,
  BulkEdit
} from "assets/icons";

const StepDetailsComponent = ({
  title,
  description,
  _continue,
  _build,
  _back,
  _preview,
  _save,
  _edit,
  _share,
  _download,
  _next,
  onEdit,
  onDownload,
  onPreview,
  onContinue,
  onSave,
  onShare,
  onBuild,
  onBack,
  disableUploadButton,
  onNext,
  onSkip,
  _skip,
  _slideCount,
  _showCount,
  maximumSlideCount,
  _bulkEdit,
  _gear,
  onBulkEdit,
  _upload,
  onUpload,
  className,
  gearData,
  notDangerously,
  _previous,
  onPrevious,
  // these are used in the tools>summaryMaker>build>components>preview>index.jsx
  _navigationArrows,
  pageNavigationHandler,
  navigationInputHandler,
  navigationInput,
  previewPageNo,
  pagesDetails,
  _selectDropdownForWidth_sm_preview,
  changeSetWidthOptionForPreview,
  setWidthOptionForPreview
}) => {
  let previewRef, buildRef, downloadRef;

  const UI_STRINGS = {
    CONTENT_HINT: "Add slides to your presentation below.",
    SLIDE_COUNT_HINT: `Presentations can have a maximum of ${maximumSlideCount} slides. This includes the cover and overview slides added when the presentation is built.`
  };
  return (
    <DetailsContainer className={`cf ${className}`}>
      <TitleDescription>
        <Title>{title}</Title>
        {notDangerously ? (
          <Description>{description}</Description>
        ) : (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </TitleDescription>
      <div>
        {_showCount && (
          <SlideCount>
            Slide count:
            <span>
              {_slideCount}/{maximumSlideCount}
            </span>
            <FaRegQuestionCircleIcon
              size={12}
              data-rh={UI_STRINGS.SLIDE_COUNT_HINT}
              data-rh-at="right"
            />
          </SlideCount>
        )}
      </div>
      <NavigationArrowsPanel>
        {_navigationArrows && (
          <NavigationPanel>
            <LeftIconWrapper
              title="Previous"
              showIcon={previewPageNo > 0}
              onClick={e => {
                pageNavigationHandler && pageNavigationHandler(true);
              }}
            >
              <SlidePreviewLeftArrow />
            </LeftIconWrapper>

            <Input
              ref={navigationInput}
              defaultValue={previewPageNo + 1}
              onKeyDown={navigationInputHandler}
            />

            <RightIconWrapper
              title="Next"
              showIcon={previewPageNo < get(pagesDetails, `length`) - 1}
              onClick={e => {
                pageNavigationHandler && pageNavigationHandler(false, true);
              }}
            >
              <SlidePreviewRightArrow />
            </RightIconWrapper>
          </NavigationPanel>
        )}
      </NavigationArrowsPanel>
      <span>
        {_selectDropdownForWidth_sm_preview && (
          <DropDown
            value={setWidthOptionForPreview}
            onChange={e => {
              changeSetWidthOptionForPreview &&
                changeSetWidthOptionForPreview(e.target.value);
            }}
          >
            <option value={false}>100%</option>
            <option value={true}>Fit Width</option>
          </DropDown>
        )}
      </span>
      <ButtonsContainer>
        {_preview && (
          <IconButton
            ref={preview => (previewRef = preview)}
            title="Preview"
            onClick={e => {
              onPreview && onPreview(e, previewRef);
            }}
            className="preview-icon"
          >
            <PreviewIcon />
          </IconButton>
        )}
        {_save && (
          <IconButton
            title="Save"
            onClick={e => {
              onSave && onSave(e);
            }}
          >
            <SaveIcon />
          </IconButton>
        )}
        {_share && (
          <IconButton
            title="Share"
            onClick={e => {
              onShare && onShare();
            }}
          >
            <ShareIcon />
          </IconButton>
        )}
        {_bulkEdit && (
          <IconButton
            title="Bulk Edit"
            onClick={e => {
              onBulkEdit && onBulkEdit();
            }}
          >
            <BulkEdit />
          </IconButton>
        )}
        {_gear && (
          <GearContainer>
            <GearModal data={gearData} />
          </GearContainer>
        )}

        {_upload && (
          <NextButton
            className="next-button"
            title="Upload"
            onClick={e => onUpload && onUpload(e)}
          >
            Upload
          </NextButton>
        )}
        {_continue && (
          <ContinueButton
            onClick={e => onContinue && onContinue(e)}
            title="Continue"
          >
            Continue
          </ContinueButton>
        )}
        {_skip && (
          <SkipButton title="Skip" onClick={e => onSkip && onSkip(e)}>
            Skip
          </SkipButton>
        )}
        {_previous && (
          <PreviousButton
            title="Previous"
            onClick={e => onPrevious && onPrevious(e)}
          >
            Previous
          </PreviousButton>
        )}
        {_next && (
          <NextButton title="Next" onClick={e => onNext && onNext(e)}>
            Next
          </NextButton>
        )}
        {_edit && (
          <ContinueButton title="Edit" onClick={() => onEdit && onEdit()}>
            {_edit}
          </ContinueButton>
        )}
        {_build && (
          <BuildButton
            ref={build => (buildRef = build)}
            onClick={e => onBuild && onBuild(e, buildRef)}
            title="Build"
            disabled={!!disableUploadButton}
            className="build-button"
          >
            Build
          </BuildButton>
        )}
        {_back && (
          <ContinueButton title="Back" onClick={() => onBack && onBack()}>
            Back
          </ContinueButton>
        )}
        {_download && (
          <BuildButton
            ref={ref => (downloadRef = ref)}
            onClick={e => onDownload && onDownload(e, downloadRef)}
            disabled={!!disableUploadButton}
          >
            Download
          </BuildButton>
        )}
      </ButtonsContainer>
    </DetailsContainer>
  );
};

StepDetailsComponent.defaultProps = {
  title: "",
  description: "",
  _edit: "",
  _preview: false,
  _save: false,
  _continue: false,
  _build: false,
  _back: false,
  _add: false,
  _download: false,
  _share: false,
  modifyStep: () => {}
};

const SharedIconCSS = css``;

const IconButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  vertical-align: top;
  cursor: pointer;
  margin-left: 20px;
`;

const PreviewIcon = styled(PreviewBorder)`
  ${SharedIconCSS}
`;
const SaveIcon = styled(SaveBorder)`
  ${SharedIconCSS}
`;

const ShareIcon = styled(ShareBordered)`
  ${SharedIconCSS}
`;

const Description = styled.p`
  padding-top: 5px;
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  opacity: 0.67;
  margin-bottom: -7px;
  .bolde-note {
    font-weight: bold;
  }

  /* Removing the description field on smaller devices so as to get more space*/
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const DetailsContainer = styled.div`
  padding: 40px 0 20px;
  border-bottom: solid 1px ${hexToRgba("#979797", 0.24)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleDescription = styled.div`
  display: inline-block;
  max-width: 49%;

  @media screen and (max-width: 1023px) {
    max-width: 40%;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-transform: capitalize;
`;

const ButtonsContainer = styled.div`
  float: right;
  height: 40px;
`;

const ButtonSharedCSS = css`
  width: 113px;
  height: 40px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.WHITE};
  vertical-align: top;
`;

const SkipButton = styled.button`
  ${ButtonSharedCSS};
  background-color: transparent;
  margin-left: 20px;
  border: 1px solid ${props => props.theme.COLOR.MAIN};
  color: ${props => props.theme.COLOR.MAIN};
  &:hover {
    color: ${props => props.theme.COLOR.WHITE};
    background: ${props => props.theme.COLOR.MAIN};
  }
`;

const ContinueButton = styled.button`
  ${ButtonSharedCSS}
  margin-left: 20px;
  background: ${props =>
    props.title === "Edit"
      ? props.theme.COLOR_PALLETE.BROWNISH_GREY
      : props.theme.COLOR.USER_PRIMARY};
  &:hover {
    color: ${props =>
      props.title === "Edit"
        ? props.theme.COLOR_PALLETE.BROWNISH_GREY
        : props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid
      ${props =>
        props.title === "Edit"
          ? props.theme.COLOR_PALLETE.BROWNISH_GREY
          : props.theme.COLOR.USER_PRIMARY};
  }
`;

const NextButton = styled.button`
  ${ButtonSharedCSS}
  margin-left: 20px;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const PreviousButton = styled.button`
  ${ButtonSharedCSS}
  margin-left: 20px;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const BuildButton = styled.button`
  ${ButtonSharedCSS}
  background-color: ${props =>
    props.title === "Build"
      ? props.theme.COLOR.USER_PRIMARY
      : props.theme.COLOR_PALLETE.APPLE_GREEN};
  margin-left: 20px;
  &:hover:enabled {
    color: ${props =>
      props.title === "Build"
        ? props.theme.COLOR.USER_PRIMARY
        : props.theme.COLOR_PALLETE.APPLE_GREEN};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid
      ${props =>
        props.title === "Build"
          ? props.theme.COLOR.USER_PRIMARY
          : props.theme.COLOR_PALLETE.APPLE_GREEN};
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const SlideCount = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  padding: 9px 12px;
  border-radius: 2px;
  background: ${props => hexToRgba(props.theme.COLOR_PALLETE.COOL_BLUE, 0.09)};
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  display: inline-flex;
  align-items: center;

  span {
    font-weight: bold;
  }

  svg {
    margin-left: 5px;
    font-size: 16px;
    opacity: 0.7;
  }
`;

const GearContainer = styled.div`
  display: inline;
  position: relative;
`;

const FaRegQuestionCircleIcon = styled(FaRegQuestionCircle)`
  cursor: pointer;
`;

const NavigationPanel = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

const LeftIconWrapper = styled.div`
  display: flex;
  align-items: center;
  visibility: ${props => !props.showIcon && "hidden"};
  cursor: pointer;
`;

const RightIconWrapper = styled.div`
  display: flex;
  align-items: center;
  visibility: ${props => !props.showIcon && "hidden"};
  cursor: pointer;
`;

const IconWrapper = css`
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
  }
`;
const Input = styled.input`
  margin-right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: transparent;
  border: 1px solid ${props => props.theme.COLOR.MAIN};
  padding: 4px;
  text-align: center;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  margin: 0 10px;
  &:focus {
    outline: none;
  }
`;

const NavigationArrowsPanel = styled.div`
  position: relative;
  top: 0.6rem;
`;

const DropDown = styled.select`
  width: 100%;
  height: ${props => props.height};
  padding: 0 9px;
  border: none;
  border-radius: 4px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  font-family: ${props => props.theme.FONT.REG};
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 30px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  outline: none;
  padding: 4px;
`;

export default StepDetailsComponent;
