import React from "react";
import Container from "./container";
import styled from "styled-components";
import BgWrapper from "components/bgWrapper";
import { Sidebar } from "./components";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";

import FullPageLoader from "components/FullPageLoader";

import {
  AccountForm,
  Design,
  Updates,
  SearchPriorities,
  Announcement,
  Security,
  Fonts
} from "./components";

const _renderContent = props => {
  switch (props.selectedTab) {
    case "account":
      return <AccountForm {...props} />;
    case "design":
      return <Design {...props} />;
    case "updates":
      return <Updates {...props} />;
    case "searchPriorities":
      return <SearchPriorities {...props} />;
    case "announcement":
      return <Announcement {...props} />;
    case "security":
      return <Security {...props} />;
    case "fonts":
      return <Fonts {...props} />;
    default:
      return null;
  }
};

const AccountSetup = props => {
  const ReactHint = ReactHintFactory(React);

  let {
    onTabSelected,
    TabList,
    selectedTab,
    isAnnouncementLoading,
    isSystemConfigurationDataLoading,
    isAccountEnabled,
    tabHandler,
    isSearchPrioritiesLoading,
    isFontLoading
  } = props;

  let isFullPageLoaderActive =
    isAnnouncementLoading ||
    isSystemConfigurationDataLoading ||
    isSearchPrioritiesLoading ||
    isFontLoading;

  return (
    <>
      {isFullPageLoaderActive ? <FullPageLoader layerIndex={21} /> : null}
      <PageWrapper isAccountEnabled={isAccountEnabled}>
        {!isAccountEnabled && (
          <AccountError>
            Your account is currently inactive. Please complete the "
            <InactiveSection
              title="Account"
              onClick={() => tabHandler("account")}
            >
              Account
            </InactiveSection>
            " and "
            <InactiveSection
              title="Design"
              onClick={() => tabHandler("design")}
            >
              Design
            </InactiveSection>
            " sections below to activate your account.
          </AccountError>
        )}
        <ReactHint autoPosition events />
        <Sidebar
          tabList={TabList}
          onTabSelected={onTabSelected}
          selectedTab={selectedTab}
        />
        <ContentWrapper>{_renderContent({ ...props })}</ContentWrapper>
      </PageWrapper>
    </>
  );
};

let EnchancedAccountSetup = BgWrapper(AccountSetup);
export default Container(EnchancedAccountSetup);

const ContentWrapper = styled.div`
  width: 73.52%;
  padding-left: 22px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
`;

const PageWrapper = styled.div`
  padding: ${props => (!props.isAccountEnabled ? "20px 40px" : "105px 40px")};
`;

const AccountError = styled.div`
  padding: 26px 0;
  margin-bottom: 20px;
  background-color: ${props => props.theme.COLOR.ERROR};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-weight: bold;
  text-align: center;

  a {
    color: ${props => props.theme.COLOR.WHITE};
  }
`;

const InactiveSection = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
