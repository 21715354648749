import React from "react";
import styled from "styled-components";
import "intro.js/introjs.css";
import { map } from "lodash";

// images
import {
  PresentationMakerIcon,
  LibraryIcon,
  SummaryMakerIcon,
  CollateralMakerIcon,
  RenewalGeneratorIcon
} from "assets/images";

export const LandingPageComponent = props => {
  const { toolOptions, handleOptionClick } = props;

  const renderToolIcon = toolSlug => {
    switch (toolSlug) {
      case "rg":
        return RenewalGeneratorIcon;
      case "pg":
        return PresentationMakerIcon;
      case "mg":
        return CollateralMakerIcon;
      case "sm":
        return SummaryMakerIcon;
      case "lib":
        return LibraryIcon;
    }
  };
  return (
    <>
      <LandingPageWrapper>
        <SelectTool>
          <Heading>select the tool</Heading>
          <ToolList>
            {map(toolOptions, eachOption => {
              return (
                <Tool
                  onClick={e =>
                    handleOptionClick({ selectedTool: eachOption, event: e })
                  }
                >
                  <ToolFigure>
                    <ToolImage
                      src={renderToolIcon(eachOption.slug)}
                      alt="Tool Icon"
                    />
                  </ToolFigure>
                  <ToolName>{eachOption.name}</ToolName>
                </Tool>
              );
            })}
          </ToolList>
        </SelectTool>
      </LandingPageWrapper>
    </>
  );
};

const SelectTool = styled.div`
  width: 940px;
  height: 717px;
  padding: 76px;
  margin: 10px auto 20px;
  background-color: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  box-sizing: border-box;
`;

const Heading = styled.h2`
  padding-bottom: 30px;
  text-transform: capitalize;
  font-size: 18px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-weight: 600;
`;

const ToolList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Tool = styled.li`
  width: 169px;
  height: 170px;
  border: 1px solid #d9d7d7;
  border-radius: 5px;
  margin-right: 37px;
  margin-bottom: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  &:nth-of-type(4) {
    margin-right: 0;
  }
`;

const ToolFigure = styled.figure``;

const ToolImage = styled.img`
  height: 57px;
`;

const ToolName = styled.p`
  padding-top: 10px;
  font-weight: 600;
  font-size: 12px;
  color: #172976;
`;

const LandingPageWrapper = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 130px auto 0;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }

  .content {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

export default LandingPageComponent;
