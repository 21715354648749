import { SavePresentationTypes } from "tools/library/store/types";
import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

/**
 *
 * Store data dynamically based on Step selected.
 * @param {Object} data - API response on success/error/loading
 */

const onSuccessPresentation = (
  payload = {},
  type = SavePresentationTypes.SUCCESS_PRESENTATION_DATA
) => {
  return {
    type,
    payload
  };
};

const onSavePressentationLoading = (
  payload = {},
  type = SavePresentationTypes.LOADING_SAVING_DATA
) => {
  return {
    type,
    payload
  };
};

const onGetPresentationLoading = (
  payload = {},
  type = SavePresentationTypes.LOADING_PRESENTATION_DATA
) => {
  return {
    type,
    payload
  };
};

/**
 * Post Form Data
 * @param {Object}  This object will have the keys depending upon the Steps selected. This body will be unique for different steps
 */
const createPresentation = (userId, body, presentationId) => async dispatch => {
  const URL = presentationId
    ? `/${userId}/presentations/${presentationId}`
    : `/${userId}/presentations`;

  dispatch(onSavePressentationLoading({ isSaveDataLoading: true }));

  const response = presentationId
    ? await FetchUtils.patchData(URL, body, "Post Build Data ==>")
    : await FetchUtils.postData(URL, body, "Post Build Data ==>");

  //save data  on success
  dispatch(onSavePressentationLoading({ isSaveDataLoading: false }));

  if (response.success && response.data) {
    dispatch(onSuccessPresentation({ presentationData: response.data }));

    ToastUtils.handleToast({
      operation: "dismiss"
    });
  }
  //return reponse to check in component if required
  return response;
};

/**  Get presentation detail
 *  @param {String} - userId
 *  @param {String} - presentationId
 */
const getPresentationDetail = (userId, presentationId) => async dispatch => {
  const URL = `/${userId}/presentations/${presentationId}`;

  // start loading
  dispatch(onGetPresentationLoading({ isGetPresentationLoading: true }));

  //set header
  const HEADER = {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache"
  };

  const response = await FetchUtils.getDataWithHeader(
    URL,
    "Get Presentation Detail ==>",
    HEADER
  );

  if (response.success && response.data) {
    dispatch(onSuccessPresentation({ presentationData: response.data }));

    // stop loading
    dispatch(onGetPresentationLoading({ isGetPresentationLoading: true }));
  } else {
    // stop loading
    dispatch(
      onGetPresentationLoading({
        isGetPresentationLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Post Form Data
 * @param {Object}  This object will have the Four keys i.e type {String}, email{String}, custom note{String}, Send copy to myself Flag{Boolean}
 */
const shareBuild = async body => {
  const URL = `/emails`;
  const response = await FetchUtils.postData(URL, body, "Send mail ==>");
  return response;
};

export { createPresentation, shareBuild, getPresentationDetail };
