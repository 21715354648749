import React from "react";
import { EditWithNoShadow } from "assets/icons";
import { MdDone, MdClear } from "react-icons/md";
import styled, { withTheme } from "styled-components";

const EditableInput = props => {
  let {
    defaultValue,
    title,
    isFieldEditable,
    titleError,
    inputEditHandler,
    inputSaveHandler,
    onResetHandler,
    handleTitleValidation,
    inputRef,
    inputFieldClassName
  } = props;

  return (
    <FieldWrapper>
      <DisabledInputWrapper>
        <InputField
          defaultValue={defaultValue}
          title={title}
          ref={inputRef}
          onChange={handleTitleValidation}
          disabled={!isFieldEditable}
          className={inputFieldClassName}
        />
        {!isFieldEditable && (
          <DisabledInput
            title={title}
            onClick={e => e.stopPropagation()}
            onDoubleClick={e => inputEditHandler(e)}
          ></DisabledInput>
        )}
      </DisabledInputWrapper>
      <ErrorMessage className="error-message-input">{titleError}</ErrorMessage>

      {!isFieldEditable ? (
        <EditIconWrapper
          title="Edit"
          onClick={e => inputEditHandler(e)}
          className="edit-icon"
        >
          <EditWithNoShadow />
        </EditIconWrapper>
      ) : (
        <SaveClearIconWrapper className="save-reset-icon">
          <SaveIconWrapper title="Save" onClick={e => inputSaveHandler(e)}>
            <MdDone
              size={16}
              color={props.theme.COLOR.MAIN}
              style={{ opacity: "0.7" }}
            />
          </SaveIconWrapper>
          <ResetIconWrapper title="Reset" onClick={onResetHandler}>
            <MdClear
              size={16}
              color={props.theme.COLOR.MAIN}
              style={{ opacity: "0.7" }}
            />
          </ResetIconWrapper>
        </SaveClearIconWrapper>
      )}
    </FieldWrapper>
  );
};

const DisabledInput = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
`;

const DisabledInputWrapper = styled.span`
  display: inline-block;
  width: 100%;
`;

const FieldWrapper = styled.div`
  position: relative;
`;

const SaveClearIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 2px;
  transform: translateY(-50%);
`;

const SaveIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 2px;
`;

const ResetIconWrapper = styled.a`
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  position: absolute;
  margin-top: 5px;
`;

const EditIconWrapper = styled.a`
  position: absolute;
  right: 4px;
  top: 3px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const InputField = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 3px 34px 0 0px;
  display: block;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: ${props => `${props.theme.FONT.REG}`};
  color: ${props => props.theme.COLOR.HEADING};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::-ms-clear {
    display: none;
  }
`;

export default withTheme(EditableInput);
