export function downloadFile(file, name = "") {
  //exit if no location is present
  if (!file) {
    return;
  }

  let link = document.createElement("a");
  link.dataType = "json";
  link.href = file;
  link.download = name;
  link.dispatchEvent(new MouseEvent("click"));
}
