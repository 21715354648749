import { CobrandLogoTypes as types } from "tools/summaryMaker/store/types";

import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
/**
 *
 * Store/Reset/Remove data dynamically based on .
 * @param {Object} payload - API response on success/error/loading
 * @param {String} type - type for sucess/error/loading
 */

const onCustomerLogoSuccess = (
  payload = null,
  type = types.SUCCESS_CUSTOMER_LOGO
) => {
  return {
    type,
    payload
  };
};

const isCustomerLogoLoading = (
  payload = null,
  type = types.LOADING_CUSTOMER_LOGO
) => {
  return {
    type,
    payload
  };
};

const getCustomerLogoList = userId => async dispatch => {
  const URL = `/customer/${userId}/logo`;
  console.log("dispatching this action", userId);
  // show loader
  dispatch(
    isCustomerLogoLoading({
      isLogoListUploading: true
    })
  );

  const response = await FetchUtils.getData(URL, "Get Customer logo==>");

  if (response && response.success) {
    // dispatch logo list on success
    dispatch(
      onCustomerLogoSuccess({
        customerLogoList: response.data
      })
    );
    // hide loader
    dispatch(
      isCustomerLogoLoading({
        isLogoListUploading: false
      })
    );
  } else {
    // hide loader
    dispatch(
      isCustomerLogoLoading({
        isLogoListUploading: false
      })
    );
    ToastUtils.handleToast({
      operation: "dismiss"
    });
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};
/**
 * post new customer logo
 *
 * @param {String} userId id of the user logged in
 * @param {Object} logo logo to be saved
 */
const createNewCustomerLogo = (userId, logo) => async dispatch => {
  const URL = `/customer/${userId}/logo`;

  // show loader
  dispatch(
    isCustomerLogoLoading({
      isLogoListUploading: true
    })
  );

  const response = await FetchUtils.postData(URL, logo, "post user data");

  if (response.success && response.data) {
    dispatch(
      onCustomerLogoSuccess({
        customerLogoDetails: response.data
      })
    );
    // dispatch logo list on success
    ToastUtils.handleToast({
      operation: "success",
      // message: "Logo has been uploaded successfully.",
      message: `${
        logo.saveToProfile
          ? "Logo has been saved successfully."
          : "Logo has been uploaded successfully."
      }`,
      autoclose: 3000
    });
    // hide loader
    dispatch(
      isCustomerLogoLoading({
        isLogoListUploading: false
      })
    );
  } else {
    // hide loader
    dispatch(
      isCustomerLogoLoading({
        isLogoListUploading: false
      })
    );

    ToastUtils.handleToast({
      operation: "dismiss"
    });
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const deleteLogo = (userId, logoId) => async dispatch => {
  const URL = `/customer/${userId}/logo/${logoId}`;
  // show loader
  dispatch(
    isCustomerLogoLoading({
      isLogoListUploading: true
    })
  );

  const response = await FetchUtils.deleteData(URL, "delete logo");

  if (response && response.success) {
    // dispatch logo list on success
    ToastUtils.handleToast({
      operation: "success",
      message: "Logo has been deleted successfully.",
      autoclose: 3000
    });
    // hide loader
    dispatch(
      isCustomerLogoLoading({
        isLogoListUploading: false
      })
    );
  } else {
    // hide loader
    dispatch(
      isCustomerLogoLoading({
        isLogoListUploading: false
      })
    );

    ToastUtils.handleToast({
      operation: "dismiss"
    });
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 * update customer logo
 * @param {String} userId id of the user logged in
 * @param {Object} logoId id og the logo to be updated
 */
const updateCustomerLogoTitle = (userId, logoId, title) => async dispatch => {
  const URL = `/customer/${userId}/logo/${logoId}`;

  // show loader
  dispatch(
    isCustomerLogoLoading({
      isLogoListUploading: true
    })
  );

  const response = await FetchUtils.patchData(URL, title, "post user data");

  // hide loader
  dispatch(
    isCustomerLogoLoading({
      isLogoListUploading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: `Logo title has been updated successfully.`,
      autoclose: 3000
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const attachCobrandLogoToDocument = (
  userId,
  documentId,
  cobrandLogoId,
  imageUrl
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}`;
  // show loader
  dispatch(
    isCustomerLogoLoading({
      isLogoListUploading: true
    })
  );
  await FetchUtils.patchData(URL, {
    variables: [{
      _id: "customerLogo",
      value: {
        type: "image",
        content: imageUrl
      }
    }]
  });
};

export {
  getCustomerLogoList,
  createNewCustomerLogo,
  deleteLogo,
  updateCustomerLogoTitle,
  attachCobrandLogoToDocument
};
