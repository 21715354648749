import React, { useEffect } from "react";
import styled from "styled-components";

import hexToRgba from "utils/hexToRgba";
import SlideDropDown from "components/dropDown";

//dropdown options
const dropDownOption = ["16:9", "4:3", "Custom"];

let inputRef = null;
const RepoDetail = ({
  onChangeInput,
  onSubmitSelectedRepo,
  clearSelectedRepo,
  selectedRepoDetail,
  isHideRepoDetail,
  selectedDimension,
  selectedRepoId,
  noOfSlides,
  handleDimensionKeyDown,
  isEditClicked
}) => {
  const detailExistFlag = !!(selectedRepoDetail && selectedRepoDetail.length);
  useEffect(() => {
    if (inputRef && isEditClicked) {
      inputRef.focus();
    }
  }, [isEditClicked]);

  const setRef = refToBeAssigned => {
    inputRef = refToBeAssigned;
  };

  const fieldList = selectedRepoDetail.map((item, index) => {
    const {
      label,
      input,
      value,
      note,
      error,
      key,
      isItemDimension,
      valueType
    } = item;
    const gropClassName =
      label === "Slide Width (inches)"
        ? "width-field"
        : label === "Slide Height (inches)"
        ? "height-field"
        : label === "Max Slides"
        ? "max-slide-field"
        : "";

    const isShowWidthAndHeight =
      selectedDimension === "Custom" ||
      dropDownOption.indexOf(selectedDimension) === -1;

    let widthValue, heightValue;

    switch (selectedDimension) {
      case "16:9":
        widthValue = 13.33;
        heightValue = 7.5;
        break;

      case "4:3":
        widthValue = 10;
        heightValue = 7.5;
        break;

      default:
        widthValue = input;
        heightValue = input;
    }

    const labelGroup = (
      <LabelGroup
        key={index}
        label={label}
        selectedDimension={selectedDimension}
        className={gropClassName}
      >
        {/* Label */}
        {label && !isItemDimension && <Label>{label}</Label>}

        {/* input and text area */}
        {valueType === "input" && !isHideRepoDetail && !isItemDimension ? (
          <LabelInput
            defaultValue={input}
            onChange={e => onChangeInput({ key, e, valueType: "input" })}
            ref={ref => {
              if (key === "name") setRef(ref);
            }}
          />
        ) : (
          valueType === "textarea" &&
          !isHideRepoDetail && (
            <LabelContent
              onChange={e => onChangeInput({ key, e, valueType: "input" })}
              maxLength={250}
              textarea={true}
              defaultValue={input}
            />
          )
        )}

        {label && isItemDimension && <Label>{label}</Label>}

        {isItemDimension && (
          <LabelInput
            value={key === "width" ? widthValue : heightValue}
            disabled={!isShowWidthAndHeight}
            onChange={e =>
              key === "width" || key === "height"
                ? handleDimensionKeyDown({ key, e, valueType: "input" })
                : onChangeInput({ key, e, valueType: "input" })
            }
          />
        )}

        {value && <LabelValue>: {value}</LabelValue>}

        {/* Drop down */}
        {valueType === "dropDown" && (
          <SlideDropDownWrapper>
            <SlideDropDown
              handleChange={e =>
                onChangeInput({ key, e, valueType: "dropDown" })
              }
              option={dropDownOption}
              value={
                dropDownOption.indexOf(input) === -1 && selectedRepoId
                  ? "Custom"
                  : input
              }
              disabled={noOfSlides && selectedRepoId}
            />
          </SlideDropDownWrapper>
        )}

        {/* Note */}
        {note && <NoteText>{note}</NoteText>}

        {/* Error message */}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </LabelGroup>
    );
    return labelGroup;
  });

  return (
    <RrpoDetailContainer detailExistFlag={detailExistFlag}>
      {detailExistFlag && (
        <>
          <SelectedRepo>Selected Repo</SelectedRepo>
          {fieldList}
          <RepoCtaContainer>
            <SubmitCta onClick={onSubmitSelectedRepo} id="__test_submit_button">
              Submit
            </SubmitCta>
            <CancelSlide onClick={clearSelectedRepo}>Cancel</CancelSlide>
          </RepoCtaContainer>
        </>
      )}
    </RrpoDetailContainer>
  );
};

const RrpoDetailContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  min-height: ${props => (props.detailExistFlag ? "auto" : "415px")};
  float: right;
  min-width: calc(100% - 880px);
  padding: 16px 12px;
  background: ${props => props.theme.COLOR.WHITE};
  @media (max-width: 1039px) {
    min-width: calc(100% - 692px);
  }
  @media (max-width: 880px) {
    width: calc(100% - 590px);
  }
  @media (max-width: 768px) {
    width: 33%;
  }
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  -webkit-box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.14);

  .width-field {
    width: calc(51% - 11px);
    display: inline-block;
  }

  .height-field {
    width: calc(51% - 11px);
    display: inline-block;
    margin-left: 16px;
  }

  .max-slide-field {
    display: inline-block;
  }
`;

const LabelGroup = styled.div`
  padding-top: ${props => (props.description ? "17px" : 0)};
  margin-top: 19px;
  vertical-align: top;
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
`;

const LabelValue = styled.span`
  font-size: 12px;
  font-style: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
`;

const SlideDropDownWrapper = styled.div`
  margin-top: 3px;
`;

const SelectedRepo = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: -2px;
`;

const LabelInput = styled.input`
  ${props => props.theme.SNIPPETS.SHARED_INPUT_STYLE};
  cursor: ${props => props.disabled && "not-allowed"};
  text-transform: none;
`;

const LabelContent = styled.textarea`
  ${props => props.theme.SNIPPETS.SHARED_INPUT_STYLE};
  min-height: ${props => (props.textarea ? "50px" : "auto")};
  resize: none;
  overflow: hidden;
  text-transform: none;
`;

const NoteText = styled.span`
  font-family: ${props => props.theme.FONT.REG};
  font-size: 10px;
  display: block;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  margin-top: 5px;
`;

const ErrorMessage = styled.span`
  font-family: ${props => props.theme.FONT.REG};
  font-size: 10px;
  display: block;
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;

const RepoCtaContainer = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const SubmitCta = styled.button`
  width: calc(50% - 3px);
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: ${props => props.theme.COLOR.WHITE};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  cursor: pointer;
  transition: all 0.5s ease 0s;
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const CancelSlide = styled.button`
  width: calc(50% - 3px);
  height: 40px;
  margin-left: 6px;
  background: transparent;
  font-family: ${props => props.theme.FONT.REG};
  border: none;
  outline: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  border: solid 1px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  cursor: pointer;
  transition: all 0.5s ease 0s;
  &:hover {
    color: ${props => props.theme.COLOR.WHITE};
    background: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
    border-color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  }
`;
export default RepoDetail;
