import React, { Component } from "react";
import _, { get, uniqBy, find } from "lodash";
import handleBodyScroll from "utils/handleBodyScroll";
import { connect } from "react-redux";
import { menuOptions } from "./constants";
import { actions, mapStateToProps } from "./mapStateToProps";

const container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Container extends Component {
      constructor(props) {
        super(props);
        this.state = {
          isPopupOpen: false,
          isOpen: false,
          isHeaderSticky: false,
          isDropDownOpen: false,
          selectedDropDown: {},
          isLoading: true,
          toolOptions: [],
          notiCount: 0,
          showAll: false
        };
        this.domElem = React.createRef();
      }
      announcementLimit = 5;
      static defaultProps = {
        menuOptions
      };

      componentDidMount() {
        // fetch new tools list from v2
        this.props.getToolsList();
        this.props.userProfileMeta && this.setToolOptions();
        window.addEventListener("scroll", this.checkHeader);

        // fetch announcement list only when user has access for ["PG-Users", "PG-System-Admin"]
        this.fetchAnnouncementFlag() && this.fetchAnnouncementList();

        // fire storage event since if user directly inputs url in a tab, session storage is not shared hence the user will be again redirected to the last dashboard
        // listen for changes to localStorage
        window.addEventListener(
          "storage",
          this.transferSessionStorageData,
          false
        );

        // Ask other tabs for session storage if not present in current tab(this is ONLY to trigger event)
        if (!sessionStorage.length) {
          localStorage.setItem("REQUESTING_SHARED_SESSION_STORAGE_DATA", "");
          localStorage.removeItem("REQUESTING_SHARED_SESSION_STORAGE_DATA", "");
        }
      }

      // this function is fired when any interaction occurs with localStorage
      transferSessionStorageData = event => {
        if (event.key === "REQUESTING_SHARED_SESSION_STORAGE_DATA") {
          // another tab asked for the sessionStorage -> send it
          localStorage.setItem(
            "sessionStorage",
            JSON.stringify(sessionStorage)
          );
          // the other tab should now have it, so we're done with it.
          localStorage.removeItem("sessionStorage");
        } else if (event.key === "sessionStorage" && !sessionStorage.length) {
          // another tab sent data <- get it
          let data = JSON.parse(event.newValue);
          for (let key in data) {
            sessionStorage.setItem(key, data[key]);
          }
        }
      };

      // flag for fetchAnnouncementList
      fetchAnnouncementFlag = () => {
        let pgRoles = ["PG-Users", "PG-System-Admin"];

        let permittedRoles = pgRoles.filter(
          role =>
            this.props.userProfileMeta &&
            this.props.userProfileMeta.roles.indexOf(role) !== -1
        );

        return permittedRoles.length;
      };

      fetchAnnouncementList = async (announcementLimit, pageNumber) => {
        let {
          userProfileMeta: { _id: userId }
        } = this.props || {};

        if (pageNumber && announcementLimit) {
          await this.props.getAnnouncementList(
            announcementLimit,
            pageNumber,
            userId
          );
        } else {
          await this.props.getAnnouncementList(5, 1, userId);
        }

        this.setState({
          notiCount: get(this.props.announcementList, "data.unreadCount") || 0
        });
      };

      updateAnnouncementHandler = async (
        announcementId,
        announcementLimit = 5,
        pageNumber = 1
      ) => {
        let {
          userProfileMeta: { _id: userId }
        } = this.props || {};

        await this.props.updateAnnouncement(userId, announcementId);
        this.fetchAnnouncementList(announcementLimit, pageNumber);
      };

      /**
       * Check current Active user roles and set the tool options
       *
       */
      setToolOptions = () => {
        const { tools } = this.props;
        this.updateToolOptions(tools);
      };

      // update tool options array based on the tool last logged in
      updateToolOptions = (toolOptions) => {
        const newToolList = [...toolOptions];

        // let's ignore legacy FE last used tool completely as the redirect logic is now handled in the new "home" app
        // we can assume that PG is always enabled tool for now, so that we can redirect all the time to it
        const lastToolIndex = (() => {
          const foundPmaToolIndex = newToolList.findIndex(({ slug }) => slug === 'pga');

          if (window.location.origin.includes('pma.') && foundPmaToolIndex !== -1) {
            return foundPmaToolIndex;
          }

          return newToolList.findIndex(({ slug }) => slug === 'pg');
        })();

        if (lastToolIndex >= 0 && newToolList.length > 0) {
          newToolList.splice(lastToolIndex, 1);
          newToolList.unshift(toolOptions[lastToolIndex]);
        }

        // set default (selected) status for the 1st one the list
        newToolList[0].default = true;

        this.setState(
          {
            toolOptions: newToolList
          },
          () => {
            this.redirectToLastTool();
            this._checkPermissions();
          }
        );
      };

      redirectToLastTool = () => {
        // setting flag in session storage so that redirection again happens on window reopen
        if (!sessionStorage.getItem("isLastToolSet")) {
          // set flag to true so that on page refresh, redirection to last tool used does not happen
          sessionStorage.setItem("isLastToolSet", true);
          let { toolOptions } = this.state;
          let { clientConfig } = this.props;

          this.props.history.push({
            pathname: "/home",
            state: {
              selectedTool: toolOptions[0],
            },
          });
        }
      };

      componentDidUpdate(prevProps) {
        if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
          this.setToolOptions();
          this.fetchAnnouncementFlag() && this.fetchAnnouncementList();
        }

        let updatedUnreadCount =
          this.props.announcementList &&
          this.props.announcementList.data &&
          this.props.announcementList.data.unreadCount;

        let prevUnreadCount =
          prevProps.announcementList &&
          prevProps.announcementList.data &&
          prevProps.announcementList.data.unreadCount;

        if (updatedUnreadCount !== prevUnreadCount) {
          this.setState({
            notiCount: updatedUnreadCount
          });
        }

        if (this.props.clientConfig !== prevProps.clientConfig) {
          this.setDocumentTitle();
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
          this._checkPermissions();
        }
      }

      componentWillUnmount() {
        window.removeEventListener("scroll", this.checkHeader);
      }

      /**
       * Function to select active menu
       *
       * @param {*} activePath
       */
      _activeLink = (activePath, defaultDropDown) => {
        const activeModule = _.get(defaultDropDown, "slug");
        (this.props.menuOptions[activeModule] || []).forEach(menuItem => {
          let path = menuItem.link && menuItem.link.slice(0).split("?")[0];
          if (path === activePath) {
            this.setState({
              isActiveMenu: menuItem.value
            });
          }
        });
      };

      /**
       * function to check if the page is available to the user
       * @param {Array} menuAccess Array of roles which can access the given page/route
       */
      _checkUserAcess = menuAccess => {
        let { userProfileMeta: profile } = this.props;
        return (
          profile &&
          !!Object.keys(profile).length &&
          _.intersection(menuAccess, profile.roles).length
        );
      };

      /**
       * function to handle hamburger click
       * @param {String} action open/close to open and close menu
       */
      handleMenuClick = ({ action }) => {
        const { isDropDownOpen } = this.state;
        if (isDropDownOpen) {
          this.setState({
            isDropDownOpen: false
          });
        } else {
          if (action === "open") {
            this.setState({ isOpen: true });
            this.scrollBarWidth = handleBodyScroll({});
            handleBodyScroll({ action: "open" });
            this.props.setMenuOpenCloseState(true);
          } else {
            this.setState({ isOpen: false });
            this.scrollBarWidth = handleBodyScroll({});
            handleBodyScroll({ action: "close" });
            this.props.setMenuOpenCloseState(false);
          }
        }
      };

      checkHeader = _.throttle(() => {
        // Run JavaScript stuff here
        let scrollPosition = Math.round(window.scrollY);
        if (scrollPosition > 100) {
          this.setState({
            isHeaderSticky: true
          });
        }
        // If not, remove "sticky" class from header
        else {
          this.setState({
            isHeaderSticky: false
          });
        }
      }, 300);

      // Set the page/document title
      setDocumentTitle = (title = "") => {
        let location = this.props.location;
        location.pathname === "/optumhome" && (title = "Presentation HUB");
        document.title = title || get(this.props, "clientConfig.siteName", "");
      };

      /**
       * function to handle menu item click
       * @param {Object} event event from on click
       * @param {String} value value of the clicked menu event
       *
       */
      handleMenuItemClick = ({ event, value }) => {
        const { isDropDownOpen } = this.state;

        if (isDropDownOpen) {
          this.setState({
            isDropDownOpen: false
          });
        } else {
          this.setState({
            isActiveMenu: value
          });
        }
        this.handleMenuClick({ action: "close" });
      };

      handleSelectClick = ({ e, value }) => {
        if (value !== false) {
          e.stopPropagation();
        }
        this.setState(prev => {
          return {
            isDropDownOpen: value === false ? value : !prev.isDropDownOpen
          };
        });
      };

      onProfileClick = ({ isOpen }) => {
        this.setState({
          isProfileOpen: isOpen
        });
      };

      onBellIconClick = () => {
        this.setState({
          showAll: !this.state.showAll
        });
      };

      showAllAnnouncement = (e, origin = null, id) => {
        const POPUP_ANNOUNCEMENT_COUNT = 5;

        this.setState(
          {
            showAll: !this.state.showAll,
            selectedId: id
          },
          () => {
            this.fetchAnnouncementList(POPUP_ANNOUNCEMENT_COUNT);

            !this.state.showAll && this.props.clearAnnouncementList();
          }
        );
        if (origin !== "announcementPopup") {
          this.setState({
            selectedId: ""
          });
        }

        if (e.target !== e.currentTarget && origin !== "announcementPopup")
          return;
      };

      getLineHeight = reference => {
        const lineHeight = window.getComputedStyle(reference)["line-height"];
        if (lineHeight === "normal") {
          return (
            2 * parseFloat(window.getComputedStyle(reference)["font-size"])
          );
        } else {
          return parseFloat(lineHeight);
        }
      };

      limitCharacter = (reference, description, lines = 3) => {
        if (!reference) return;

        const truncateText = description || reference.innerHTML;
        const StrippedString = truncateText.replace(/(<([^>]+)>)/gi, "");
        reference.innerHTML = StrippedString;
        const truncateTextParts = truncateText.split(" ");
        const lineHeight = this.getLineHeight(reference);
        while (lines * lineHeight < reference.clientHeight) {
          truncateTextParts.pop();
          reference.innerHTML = truncateTextParts.join(" ") + "...";
        }
      };

      showAllContent = (reference, description) => {
        const truncateText = description || reference.innerHTML;
        if (reference) {
          let content1 = truncateText.replace(/(<p><\/p>)+/g, "</br>");
          reference.innerHTML = content1;
        }
      };
      /**
       *Callback function for dropdown option  click
       * @param event event object of the option
       * @param selectedTool The selected option data
       */
      handleOptionClick = ({ selectedTool, event }) => {
        event.preventDefault();

        // url parameter is present only if selectedTool comes from V2
        // so whenever it is present we will redirect whole app to the new tool
        return window.location.replace(selectedTool.url);
      };

      /**
       * Function to check if user has tool access
       *  @param {Array} access Array of accessible tools
       */
      _checkPermissions = () => {
        const { pathname } = this.props.location;
        let { toolOptions } = this.state;
        let tool = this.props.location.search.slice(0).split("=")[1];
        let selectedDropDown = toolOptions[0] || {};

        if (pathname && pathname.indexOf("presentation") > -1) {
          selectedDropDown = find(toolOptions, { slug: "pg" });
        } else if (pathname && pathname.indexOf("marketing-materials") > -1) {
          selectedDropDown = find(toolOptions, {
            slug: "mg"
          });
        } else if (pathname && pathname.indexOf("renewal") > -1) {
          selectedDropDown = find(toolOptions, {
            slug: "rg"
          });
        } else if (pathname && pathname.indexOf("summarymaker") > -1) {
          selectedDropDown = find(toolOptions, {
            slug: "sm"
          });
        } else if (pathname && pathname.indexOf("library") > -1) {
          selectedDropDown = find(toolOptions, {
            slug: "lib"
          });
        }

        // this is used when url has a query parameter like for user pages
        if (tool === "rg") {
          selectedDropDown = find(toolOptions, {
            slug: "rg"
          });
        } else if (tool === "pg") {
          selectedDropDown = find(toolOptions, { slug: "pg" });
        } else if (tool === "mg") {
          selectedDropDown = find(toolOptions, {
            slug: "mg"
          });
        } else if (tool === "sm") {
          selectedDropDown = find(toolOptions, {
            slug: "sm"
          });
        } else if (tool === "lib") {
          selectedDropDown = find(toolOptions, {
            slug: "lib"
          });
        }

        const foundPmaTool = find(toolOptions, { slug: 'pga' });

        if (window.location.origin.includes('pma.') && foundPmaTool) {
          selectedDropDown = foundPmaTool;
        }

        this.setState(
          {
            selectedDropDown
          },
          () => {
            this.setDocumentTitle(selectedDropDown.name);
            this._activeLink(pathname, selectedDropDown);
          }
        );
      };

      render() {
        let MainProps = {
          ...this,
          ...this.props,
          ...this.state,
          scrollBarWidth: this.scrollBarWidth || 0,
          handleMenuClick: this.handleMenuClick,
          handleMenuItemClick: this.handleMenuItemClick,
          handleSelectClick: this.handleSelectClick,
          onProfileClick: this.onProfileClick,
          handleOptionClick: this.handleOptionClick,
          _checkUserAcess: this._checkUserAcess
        };

        return <Main {...MainProps} />;
      }
    }
  );

export default container;
