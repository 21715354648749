import React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { DragSource } from "react-dnd";

const Types = {
  BLOCKITEM: "blockItem"
};

const builtinBlockSource = {
  beginDrag(props, monitor, component) {
    // this props wld be available in drop target as the info of the the item being dropped at them
    return {
      ...props.builtinBlockDetail
    };
  },

  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { addToCustomBlock } = props;
      const item = monitor.getItem();
      addToCustomBlock(item);
    }
  }
};

const BuiltinCarousel = props => {
  let {
    connectDragSource,
    builtinBlockDetail,
    builtinBlockIndex,
    isDragging
  } = props;

  return connectDragSource(
    <div
      key={builtinBlockIndex}
      style={{
        marginRight: "20px",
        opacity: `${isDragging ? 0.2 : 1}`,
        transform: "translate3d(0, 0, 0)",
        cursor: "pointer"
      }}
    >
      <BlockWrapper>
        <ImageWrapper>
          <StyledImage src={builtinBlockDetail.thumbnailLocation} />
        </ImageWrapper>
        <BlockName title={get(builtinBlockDetail, `name`)}>
          {get(builtinBlockDetail, `name`)}
        </BlockName>
      </BlockWrapper>
    </div>
  );
};

const ImageWrapper = styled.div`
  width: 100px;
  position: relative;
  border: 1px solid ${props => props.theme.COLOR.MODAL_FADED_GREY};
  padding: 4px;
`;

const BlockName = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98px;
  display: inline-block;
  overflow: hidden;
`;

const BlockWrapper = styled.div`
  display: inline-block;
`;

const StyledImage = styled.img`
  height: auto;
  max-width: 51px;
  max-height: 66px;
  display: block;
  margin: 0 auto;
`;

export default DragSource(
  Types.BLOCKITEM,
  builtinBlockSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(BuiltinCarousel);
