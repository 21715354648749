import React, { Component } from "react";
import { get, each, filter } from "lodash";

const Container = Main =>
  class SliderContent extends Component {
    state = {
      slideNo: 0,
      dynamicInputFocus: false,
      isNavigating: false,
      showImageEditor: false,
      scale: 1,
      translation: { x: 0, y: 0 },
      imageHeight: 0,
      imageWidth: 0,
      placeholderWithDeletedImage: []
    };

    dynamicImageRef = React.createRef();
    dynamicImageContainer = React.createRef();

    imageTransformHandler = (
      { scale = this.state.scale, translation },
      isImageEdited = true
    ) => {
      let {
        setDynamicImagesDimensions,
        activeSlideDetail = {},
        placeholderPosition
      } = this.props;
      let { slideNo } = this.state;
      this.setState(
        {
          scale,
          translation
        },
        () => {
          let rect = {};
          if (this.dynamicImageContainer.current) {
            rect = this.dynamicImageContainer.current.getBoundingClientRect();
          }
          this.setImageDimensions(); // set new ImageWidth and ImageHeight as image changes
          setDynamicImagesDimensions &&
            setDynamicImagesDimensions({
              activeSlideId: activeSlideDetail._id,
              imagePosition: slideNo,
              position: placeholderPosition,
              dimensions: {
                scale: scale || 1,
                ...translation,
                containerWidth: rect.width,
                containerHeight: rect.height,
                ...(isImageEdited && { isImageEdited: true })
              }
            });
        }
      );
    };

    imageLoadHandler = () => {
      let { slideNo } = this.state;
      let images = get(this.props, `slideDetails.images`) || [];
      let placeholderCurrentImage = images[slideNo];

      let {
        dimensions: { isImageEdited = false } = {}
      } = placeholderCurrentImage;

      this.setImageDimensions();

      this.props.onStepEdit(); // call save function on image load so that image dimensions are sent
      // if image was edited by the user do not set the admin's default focal point
      if (isImageEdited) return;

      setTimeout(() => {
        this.setFocalPoint();
      });
    };

    setImageDimensions = () => {
      if (this.dynamicImageRef.current) {
        let imageRect = this.dynamicImageRef.current.getBoundingClientRect();
        let { width, height } = imageRect;

        this.setState({
          imageWidth: width, // image width
          imageHeight: height // image height
        });
      }
    };

    setFocalPoint = () => {
      let { slideNo } = this.state;
      let images = get(this.props, `slideDetails.images`) || [];

      let placeholderCurrentImage = images[slideNo] || {};
      let { focalPoint: { x = 0, y = 0 } = {} } = placeholderCurrentImage;

      if (!this.dynamicImageRef.current) return;

      let imageData = {
        width: this.dynamicImageRef.current.offsetWidth,
        height: this.dynamicImageRef.current.offsetHeight
      };

      if (!this.dynamicImageContainer.current) return;

      let rect =
        this.dynamicImageContainer.current &&
        this.dynamicImageContainer.current.getBoundingClientRect();
      let scale = this.setDefaultScale(imageData, rect);
      let cx = imageData.width * scale * (x / 2 + 0.5) - rect.width / 2;
      let cy = imageData.height * scale * (y / -2 + 0.5) - rect.height / 2;

      //call imageTransformHandler so that current image translation details are stored in selectedSlideListDetail
      this.imageTransformHandler(
        { translation: { x: -cx, y: -cy }, scale },
        false
      );
    };

    setDefaultScale = (
      imageDimension = {},
      containerDimension = {},
      scale = 1
    ) => {
      let getReciprocalRatio = (dividend = 0, divisor = 0, scale) => {
        return scale / (dividend / divisor);
      };
      // Get the min possible scale value the image height become smaller than the container height
      let heightRatio = getReciprocalRatio(
        imageDimension.height,
        containerDimension.height,
        scale
      );
      // Get the minimum possible scale value without letting the image width become smaller than the container width
      let widthRatio = getReciprocalRatio(
        imageDimension.width,
        containerDimension.width,
        scale
      );
      // Return the maximum value so that both height and width min scale so that the image does not scale smaller than the container
      return Math.max(heightRatio, widthRatio);
    };

    setPlaceHolderWithDeletedImage = () => {
      let {
        activeSlideDetail,
        presentationData: { slides }
      } = this.props;

      let previousSavedSlide = filter(slides, eachSlide => {
        return (
          get(eachSlide, `slideId._id`) === get(activeSlideDetail, `_id`) ||
          get(eachSlide, `slideId`) === get(activeSlideDetail, `_id`) //"Get" gives _id inside slideId and "patch" gives _id as slideId
        );
      });

      if (!previousSavedSlide.length) return;

      let previousSavedSlideData = get(previousSavedSlide[0], `slideData`);
      let placeholderWithDeletedImage = [];

      each(previousSavedSlideData, (eachSlideData, index) => {
        if (get(eachSlideData, `image.imageId.deleted`)) {
          placeholderWithDeletedImage.push(index);
        }
      });

      this.setState({
        placeholderWithDeletedImage
      });
    };

    componentDidMount() {
      let images = get(this.props, `slideDetails.images`) || [];
      let selectedImage = get(this.props, `slideDetails.selectedImage`) || {};
      // check if for the selectedImage is present
      let updatedSlideDetailExist = this.checkIfSelectedImageExists();

      this.getSlideNo(images, selectedImage);
      this.setPlaceHolderWithDeletedImage();

      // if in edit mode, inputType is image and selectedImage is not present, set the last saved image in the placeholder
      if (
        this.props.match.params.id &&
        this.props.slideDetails.inputType === "image" &&
        !updatedSlideDetailExist
      ) {
        this.getPlaceholderDetails();
      }
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.isSlidePreviewToggleOpen !==
        prevProps.isSlidePreviewToggleOpen
      ) {
        this.setFocalPoint();
      }
    }

    /**
     * check if selected image is present in the slideDetails(current placeholder details)
     * @returns {Boolean} states if selected image is present or not
     */
    checkIfSelectedImageExists = () => {
      let selectedImageDetails = JSON.parse(
        JSON.stringify(this.props.slideDetails)
      );

      return (
        get(this.props, `slideDetails.inputType`) === "image" &&
        get(selectedImageDetails, `selectedImage`)
      );
    };

    getPlaceholderDetails = () => {
      let {
        presentationData: { slides },
        activeSlideDetail = {}
      } = this.props;

      // get the current slide data which was saved earlier
      let currentOpenSlidePreviousData = filter(slides, eachSlide => {
        return (
          get(eachSlide, `slideId._id`) === get(activeSlideDetail, `_id`) &&
          eachSlide.slideData.length
        );
      });

      // set previously selected image
      if (currentOpenSlidePreviousData.length) {
        let slideDataWithLastSavedImage = get(
          currentOpenSlidePreviousData[0],
          `slideData`
        ); // contains last saved placeholder details with selected image

        // contains new placeholder details with all the images
        let slideDataWithAllImages = get(activeSlideDetail, `slideData`);
        this.setSelectedImageonLoad(
          slideDataWithLastSavedImage,
          slideDataWithAllImages
        );
      }
    };

    setSelectedImageonLoad = (
      slideDataWithLastSavedImage,
      slideDataWithAllImages
    ) => {
      let {
        setDynamicFieldChanges,
        placeholderPosition,
        activeSlideDetail
      } = this.props;

      //TODO this code is written considering any placeholder will not be deleted, check for placeholder _id once placeholder _id is provided
      for (
        let imageIndex = 0;
        imageIndex < slideDataWithAllImages.length;
        imageIndex++
      ) {
        each(slideDataWithAllImages[imageIndex].images, eachImage => {
          if (
            eachImage._id ===
              get(
                slideDataWithLastSavedImage[imageIndex],
                `image.imageId._id`
              ) &&
            placeholderPosition === imageIndex
          ) {
            eachImage.dimensions = get(slideDataWithLastSavedImage, [
              imageIndex,
              "image",
              "imageDimension"
            ]);

            // if the image's dimension(in edit) is same as that of placeholder, this means that the slide was not opened the last time it was saved
            let wasSlidePreviouslyNotOpened =
              get(eachImage, `dimensions.width`) ===
                get(this.props, `slideDetails.widthInPixel`) &&
              get(eachImage, `dimensions.height`) ===
                get(this.props, `slideDetails.heightInPixel`);

            if (!wasSlidePreviouslyNotOpened) {
              // set the selected image isImageEdited to true so that translation(focal point) is not set and user last saved translation is applied
              eachImage.dimensions.isImageEdited = true;
            }

            setDynamicFieldChanges({
              value: eachImage,
              position: placeholderPosition,
              activeSlideId: activeSlideDetail._id,
              dynamicFieldType: "selectedImage"
            });

            // set the slide no as per the last saved image
            this.getSlideNo(
              slideDataWithAllImages[imageIndex].images,
              eachImage
            );
          }
        });
      }
    };

    getSlideNo = (images = [], selectedImage = {}) => {
      // if the image slider was set before, then set the slideNo to that element index value
      let slideNo = 0;

      each(images, (eachImage, index) => {
        if (eachImage._id === get(selectedImage, `_id`)) {
          slideNo = index;
        }
      });

      this.setState({
        slideNo
      });
    };

    onSliderNavigation = (slideNo, btnDirection, noOfSlides, slideDetails) => {
      let {
        setDynamicFieldChanges,
        placeholderPosition,
        activeSlideDetail,
        currentPlaceholderDetails
      } = this.props;

      if (btnDirection === "previous" && slideNo > 0) {
        this.setState(
          {
            slideNo: slideNo - 1,
            isNavigating: true
          },
          () => {
            setDynamicFieldChanges &&
              setDynamicFieldChanges({
                value: currentPlaceholderDetails.images[this.state.slideNo],
                position: placeholderPosition,
                activeSlideId: activeSlideDetail._id,
                dynamicFieldType: "selectedImage"
              });
          }
        );
      }
      if (btnDirection === "next" && slideNo < noOfSlides - 1) {
        this.setState(
          {
            slideNo: slideNo + 1,
            isNavigating: true
          },
          () => {
            setDynamicFieldChanges &&
              setDynamicFieldChanges({
                value: slideDetails.images[this.state.slideNo],
                position: placeholderPosition,
                activeSlideId: activeSlideDetail._id,
                dynamicFieldType: "selectedImage"
              });
          }
        );
      }
    };

    // handler function for dynamic text boxes on overlay
    dynamicTextBoxHandler = e => {
      let {
        setDynamicFieldChanges,
        placeholderPosition,
        activeSlideDetail
      } = this.props;
      let inputValue = e.target && e.target.value.trim();
      setDynamicFieldChanges &&
        setDynamicFieldChanges({
          value: inputValue,
          position: placeholderPosition,
          activeSlideId: activeSlideDetail._id,
          dynamicFieldType: "text",
          ...(activeSlideDetail.isCover && {
            isCover: activeSlideDetail.isCover
          })
        });
      this.setState({
        dynamicInputFocus: false
      });
    };

    imageEditorHandler = () => {
      this.setState({
        showImageEditor: !this.state.showImageEditor
      });
    };

    _handleInputFocus = e => {
      this.setState({
        dynamicInputFocus: true
      });
    };

    changeScale = delta => {
      let rect = {};
      if (this.dynamicImageContainer.current) {
        rect = this.dynamicImageContainer.current.getBoundingClientRect();
      }
      let {
        imageHeight: height,
        imageWidth: width,
        scale: prevScale
      } = this.state;
      const minScale = this.setDefaultScale({ height, width }, rect, prevScale),
        maxScale = 3;

      const targetScale = (this.state.scale || 1) + delta;
      const scale = this.clamp(minScale, targetScale, maxScale);
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;
      const translation = this.clientPosToTranslatedPos({ x, y });

      this.scaleFromPoint(scale, translation);
    };

    // The amount that a value of a dimension will change given a new scale
    coordChange = (coordinate, scaleRatio) => {
      return scaleRatio * coordinate - coordinate;
    };

    scaleFromPoint(newScale, focalPt) {
      const { translation, scale = 1 } = this.state;
      const scaleRatio = newScale / (scale !== 0 ? scale : 1);

      const focalPtDelta = {
        x: this.coordChange(focalPt.x, scaleRatio),
        y: this.coordChange(focalPt.y, scaleRatio)
      };

      const newTranslation = {
        x: translation.x - focalPtDelta.x,
        y: translation.y - focalPtDelta.y
      };

      this.setState(
        {
          scale: newScale,
          translation: this.clampTranslation(newTranslation)
        },
        () =>
          this.imageTransformHandler({
            scale: this.state.scale,
            translation: this.state.translation
          })
      );
    }

    clamp = (min, value, max) => {
      return Math.max(min, Math.min(value, max));
    };

    clampTranslation(desiredTranslation) {
      const { x, y } = desiredTranslation;

      let xMax = Infinity,
        yMax = Infinity,
        xMin = -Infinity,
        yMin = -Infinity;

      return {
        x: this.clamp(xMin, x, xMax),
        y: this.clamp(yMin, y, yMax)
      };
    }

    translatedOrigin(translation = this.state.translation) {
      const clientOffset = this.dynamicImageContainer.current.getBoundingClientRect();
      return {
        x: clientOffset.left + translation.x,
        y: clientOffset.top + translation.y
      };
    }

    clientPosToTranslatedPos({ x, y }, translation = this.state.translation) {
      const origin = this.translatedOrigin(translation);

      return {
        x: x - origin.x,
        y: y - origin.y
      };
    }

    discreteScaleStepSize() {
      let rect = {};
      if (this.dynamicImageContainer.current) {
        rect = this.dynamicImageContainer.current.getBoundingClientRect();
      }
      let { imageHeight: height, imageWidth: width, scale } = this.state;
      const minScale = this.setDefaultScale({ height, width }, rect, scale),
        maxScale = 3;

      //Given a const step size to allow constant scaling
      const FIXED_SCALE = 0.6919140493515088;

      const delta = FIXED_SCALE || Math.abs(maxScale - minScale);

      return delta / 10;
    }

    /**
     * called when image is zoomed
     */
    handleZoom = zoomValue => {
      let step = this.discreteScaleStepSize();
      switch (zoomValue) {
        case "plus":
          this.changeScale(step);
          break;

        default:
          this.changeScale(-step);
          break;
      }
    };

    /**
     * called when image is panned
     * @param {String} direction specifies the direction in which image is panned
     */
    handlePanning = direction => {
      let {
        translation: { x, y },
        imageWidth,
        imageHeight
      } = this.state;
      let rect = {};
      if (this.dynamicImageContainer.current)
        rect = this.dynamicImageContainer.current.getBoundingClientRect();

      x = Math.abs(x);
      y = Math.abs(y);
      switch (direction) {
        case "top":
          if (y) y -= 10;
          break;
        case "right":
          if (imageWidth - rect.width > x) x += 10;
          break;
        case "bottom":
          if (imageHeight - rect.height > y) y += 10;
          break;
        default:
          if (x) x -= 10;
          break;
      }

      this.setState({
        translation: { x: -x, y: -y }
      });
    };

    /**
     * set dimensions for the image
     * @param {Number} scale image zoom level
     * @param {Number} x translation at X-axis
     * @param {Number} y translation at Y-axis
     */
    setDimensions = (scale, x, y, minScale) => {
      this.setState({
        scale,
        translation: {
          x,
          y
        }
      });
    };

    /**
     * called on resetting image dimension(reset button of image editor box)
     */
    resetImageDimensions = () => {
      this.setState(
        {
          scale: 1
        },
        () => {
          this.setFocalPoint();
        }
      );
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.props,
        ...$this.state
      };
      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
