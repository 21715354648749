// libraries
import React from "react";
import styled from "styled-components";
import { map, get, values, upperFirst, find, size } from "lodash";
// components
import RadioButton from "components/radioBtn";
import Placeholder from "./../placeholder";

const LayoutSelector = props => {
  /*
    1.  layoutDetails @Array
        Example: [
          {
            layoutTypeDetails : @Object {None, Single, Stack, Quad},
            name:  @String "Page 1",
            types: @Array ["None", "Single","Stack","Quad"]
            _id:   @String 
          }

        ]

    2.  setSelectedThumbnail @Function 
        @params:   _id,
                  placeholderName,
                  currentIndex,
                  source,
                  pageLayoutIndex

    3.  removeSelectedDocument @Funcation
        @params:   _id,
                  placeholderName,
                  currentIndex,
                  currentSelectedModule,
                  pageLayoutIndex
    
    4.  handleTopicsPageCheckboxChange @async Function
        @params:   e, eachType, pageLayoutId

    5.  activePlaceholderDetails @Array

    6.  filterTopicModules  @Array 
        @params: eachType
    
    7.  resetTopics @Function
        @params: nothing
  
    8.  modules @Array 
        Example: [{
          layout: @String "Single|Stack|Quad",
          name:   @String "Name of the Slide",
          type:   @String "jpeg",
          url:    @String 
          _id:    @String
          size:   @Object {width: @Number, height: @Number}
        }]
  */
  let {
    layoutDetails,
    setSelectedThumbNail,
    removeSelectedDocument,
    handleTopicsPageCheckboxChange,
    activePlaceholderDetails,
    selectedTemplate,
    filterTopicModules,
    resetTopics,
    modules
  } = props;

  /***
   * allTypesFromLayoutTemplateData @Object which has the TemplateBlockData
   * layoutTemplateDataArray is the covert the @Object into @Array and convert each starting String to UpperCase
   *
   **/
  let layoutOptionsWithTypes = layoutDetails;
  let allTypesFromLayoutTemplateData;

  // Case senstivity checker to accept any case sensitive values coming from backend
  selectedTemplate.blocks.map(blocksData => {
    if (blocksData.name.toLowerCase() === "topics") {
      allTypesFromLayoutTemplateData = blocksData.layoutOptions;
    }
  });

  if (allTypesFromLayoutTemplateData === undefined) {
    allTypesFromLayoutTemplateData = ["None"];
    layoutOptionsWithTypes[0].types = allTypesFromLayoutTemplateData;
  } else {
    let layoutTemplateDataArray = map(
      allTypesFromLayoutTemplateData,
      templateData => {
        return upperFirst(templateData);
      }
    );

    layoutOptionsWithTypes[0].types = layoutTemplateDataArray;
  }

  return (
    <LayoutWrapper>
      {map(layoutOptionsWithTypes, (eachLayoutPage = {}, eachLayoutIndex) => {
        return (
          <LayoutPageWrapper key={get(eachLayoutPage, `_id`)}>
            <RadioButtonWrapper>
              {map(eachLayoutPage.types, (eachType, index) => {
                let lastSelectedOption =
                  get(activePlaceholderDetails, [
                    get(eachLayoutPage, `_id`),
                    0,
                    "type"
                  ]) ||
                  get(props, [
                    "currentDocumentData",
                    "blocks",
                    "topics",
                    "pageLayouts",
                    0,
                    "layout"
                  ]);

                return (
                  size(eachLayoutPage.types) > 1 && (
                    <RadioButton
                      key={index + eachLayoutPage._id}
                      id={index + eachLayoutPage._id}
                      name={get(eachLayoutPage, "name")}
                      label={eachType}
                      radioType="controlled"
                      checked={
                        !lastSelectedOption
                          ? index === 0
                          : eachType === upperFirst(lastSelectedOption)
                      }
                      handleChange={e => {
                        handleTopicsPageCheckboxChange(
                          e,
                          eachType,
                          get(eachLayoutPage, `_id`)
                        );
                        filterTopicModules(eachType);
                        resetTopics();
                      }}
                    />
                  )
                );
              })}
            </RadioButtonWrapper>
            <PlaceholderWrapper>
              {/* loop in the current active placeholder - single/stack/quad*/}
              {map(
                get(activePlaceholderDetails, [get(eachLayoutPage, `_id`)]) ||
                  values(get(eachLayoutPage, `layoutTypeDetails`))[0],
                (eachPlaceholder, currentIndex) => {
                  return (
                    <Placeholder
                      key={get(eachPlaceholder, `type`) + currentIndex}
                      placeholderDimensions={eachPlaceholder}
                      currentIndex={currentIndex}
                      pageLayoutIndex={eachLayoutIndex}
                      placeholderName={
                        upperFirst(
                          get(activePlaceholderDetails, [
                            get(eachLayoutPage, `_id`),
                            0,
                            "type"
                          ])
                        ) || get(eachLayoutPage, `types[0]`)
                      }
                      type={get(eachPlaceholder, `type`)}
                      setSelectedThumbNail={setSelectedThumbNail}
                      activeLayout={eachLayoutPage}
                      removeSelectedDocument={removeSelectedDocument}
                      activePlaceholderDetails={activePlaceholderDetails}
                      modules={modules}
                      {...eachPlaceholder}
                    />
                  );
                }
              )}
            </PlaceholderWrapper>
          </LayoutPageWrapper>
        );
      })}
    </LayoutWrapper>
  );
};

const PlaceholderWrapper = styled.div`
  width: 100%;
  margin: 42px 0;
  display: flex;
  flex-wrap: wrap;
`;

const RadioButtonWrapper = styled.div`
  box-sizing: content-box;
  .checkmark {
    box-sizing: content-box;
  }
  .form-group {
    margin-bottom: 10px;
  }
`;

const LayoutPageWrapper = styled.div`
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  width: 60%;
  margin-right: 20px;
  padding: 20px;
  min-height: 32.5rem;
`;

const LayoutWrapper = styled.div`
  width: 67%;
  display: flex;
  padding: 40px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  justify-content: center;
  height: auto;
  position: relative;
  top: 49px;
`;

export default LayoutSelector;
