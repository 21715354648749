import React , {useEffect} from "react";
import styled from "styled-components";
import { map, each } from "lodash";

import Category from "./components/Cateogry";
import Header from "./components/Header";
import Slides from "./components/Slides";
import Deck from "./components/Deck";

/**
 * Helper function to fetch view tabs data
 * @param {View by topic tab data} data
 * 
 * the topics property is the slide hiearchy. to display a filtered
 * subset, the hierarchy passed in must be the filter results
 */

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const ViewByTopic = props => {
  const {
    topics,
    selectSlides,
    handleDeselect,
    contentRepo,
    selectedFilters,
    setAllSlidesData,
    fromPresentationScreen
  } = props;

  let topicsList = [],
    parentObj = null,
    slidesList = [],
    slideSets = [],
    subHeaderCount = 0,
    groupName = "",
    firstElem = false,
    allSlidesData = [],
    lastElem = false;

  /**
   * Recursive function to segrigate slides, header, sub-header and category
   * @param {Array} topics Array of object which containes slides data
   * 
   * side-effects:
   *   populates topicsList[]
   *     <Category> element for every label="category"
   *     <Header> element for every label="header"
   *     <Deck> element for every label="subheader"
   *     <SlidesHolder> <Deck> </SlidesHolder> for every subheader which has no children
   *     <Slide> element for every label="slide"
   *   populates allSlidesData[]
   *     the children of each "subheader" which has no subheader children
   *     all label=slide 
   *   populates slideSets[]
   *     <Deck> for every "subheader" which has no subheader children
   */
  const renderTopic = topics => {
    each(topics, (obj, index) => {
      let { _id, title, label, children, group, isDeck, isRequired } = obj;
      // console.log( "renderTopic: " , { _id, title, label, children, group, isDeck, isRequired } );
      switch (label) {
        case "category":
          if (checkIfHidden(obj)) {
            return;
          }

          !isDeck &&
            topicsList.push(
              <Category key={_id} _id={_id} title={title} {...props} />
            );
          parentObj = obj.children;
          break;
        case "header":
          // console.log("case header: ", { _id, slidesList } )
          // console.log( "renderTopic: " , { _id, title, label, children, group, isDeck, isRequired } );
          if (slidesList.length) {
            // wrapSlides() adds a <SlideHolder> with key= the 2nd parameter
            // it can't be 'index' - because then when any header is at the same
            // index as some previous header, it will be a duplicate key
            // it can't be _id (the header id) because then the <SlidesHolder>
            // and <Header> this is about to add will have the same key.
            // wrapSlides(slidesList, index);
            // wrapSlides(slidesList, _id);
            wrapSlides(slidesList, uuidv4() );
            slidesList = [];
          }
          if (checkIfHidden(obj)) {
            return;
          } 

          if( !isDeck ) {
            // if( [ "6040fbef25b4523352c71649",
            //       "606e0c7225b4523352c879a1"].indexOf(_id) > -1 ) {
            //   console.log( { topicsList } );
            //   console.log( "when bad header id: ", {props});
            // }
            topicsList.push(
              <Header key={_id} _id={_id} title={title} {...props} />
            );
          }
          parentObj = obj.children;
          break;
        case "sub-header":
          subHeaderCount = 0;
          each(parentObj.children, childObj => {
            if (childObj.label !== "sub-header") {
              subHeaderCount++;
            }
          });
          if (subHeaderCount === 0) {
            slideSets = [];  // each subheader with no subheader children resets slideSets[]
            if (index < topics.length) { // this is redundant see each() at the top of the fn

              Array.isArray(children) &&
                children.length &&
                allSlidesData.push(...children);

                if (!checkIfHidden(obj)) {
                  if (Array.isArray(children)) {
                    const visibleChildren = children.filter(
                      child => !child.hidden
                    );
    
                    if (visibleChildren.length) {
                      slidesList.push(
                        <Deck
                          key={_id}
                          id={_id}
                          title={title}
                          childList={visibleChildren}
                          isRequired={isRequired}
                          deckDetails={obj}
                          {...props}
                        />
                      );
                    }
                  }
                }

              // slidesList just had 1 <Deck> pushed, and was reset earlier
              // it is guaranteed to always have length==1
              if (slidesList.length <= 5) {
                slideSets = seperateTopics(slidesList, true);
              } else {
                slideSets = seperateTopics(splitTopics(slidesList));
              }
            }

            if (index === topics.length - 1) { // the last element of topics[]
              Array.isArray(children) &&
                children.length &&
                topicsList.push(
                  <SlidesHolder key={_id}>{slideSets}</SlidesHolder>
                );
              slidesList = [];
              slideSets = [];
              subHeaderCount = 0;
            }
          } else {

            Array.isArray(children) &&
              children.length &&
              allSlidesData.push(...children);
              
              if (checkIfHidden(obj)) {
                return;
              } else {
                if (Array.isArray(children)) {
                  const visibleChildren = children.filter(
                    child => !child.hidden
                  );
  
                  if (visibleChildren.length) {
                    slidesList.push(
                      <Deck
                        key={_id}
                        id={_id}
                        title={title}
                        childList={visibleChildren}
                        isRequired={isRequired}
                        deckDetails={obj}
                        {...props}
                      />
                    );
                  }
                }
              }
          }
          return null;
        case "slide":
          // don't render slides marked 'hidden'. this may be done by the reducer when the
          // set of selected filter values is changed.
          if (obj.hidden) {
            if (index === topics.length - 1) {
              wrapSlides(slidesList, _id);
              slideSets = [];
              slidesList = [];
            }
            return true; // skip the next element of each() iteration
          }

          parentObj = topics;

          // this assumes entries in topics[] are in order by group.title
          // detect changes in group.title, save new groupName + firstElem true marks first entry
          // of new group
          if (index < topics.length) {
            if (group && groupName !== group.title) {
              groupName = group.title;
              firstElem = true;
            }
            // a lot very fragile (i.e. array out-of-bounds exceptions) code to 
            // lastElem to mark last entry of a group
            // @todo this has to be redone to be correct at the end of the topics[] array
            if (
              (topics[index + 1] &&
                group &&
                topics[index + 1].group &&
                topics[index + 1].group.title !== group.title) ||
              (topics.length - 1 === index &&
                topics[topics.length - 1].group) ||
              (topics[index + 1] && group && !topics[index + 1].group)
            ) {
              lastElem = true;
            }

            allSlidesData.push(obj);

            // @todo figure out the strange 'parentObj' property
            // it is the topics[] array here, but if the recursion level > 0
            // then it is the parent of the current slide
            slidesList.push(
              <Slides
                firstGroupElem={firstElem}
                lastGroupElem={lastElem}
                adjacentGroupElem={
                  firstElem && topics[index - 1] && topics[index - 1].group
                    ? true
                    : false
                }
                key={_id}
                {...obj}
                slideDetail={obj}
                parentObj={parentObj}
                {...props}
              />
            );

            firstElem = false;
            lastElem = false;
          }

          if (index === topics.length - 1) {
            wrapSlides(slidesList, _id);
            slideSets = [];
            slidesList = [];
          }
          break;
        default:
          break;
      }

      if (Array.isArray(children)) {
        return renderTopic(children);
      }
    });
  };

  /**
   * Recursive function to check if all children nested in category, header, sub-header are hidden or not
   * @param {Object} topicData Object which contains topic as well as children slides data
   * 
   **/
  const checkIfHidden = (topicData) => {
    let flag = false;
    return (flag = topicData.children.every((childObj) => {
      if (childObj.hidden) return true;
      if (childObj.children && childObj.children.length > 0 && checkIfHidden(childObj))
        return true;
    }));
  };
      

  /*
   * add a <SlidesHolder> from slidelist, using separateTopics() for short lists
   * and seperateTopics(splitTopics(slideList)) for long lists (> 5 slides)
   * splitTopics simply divides a list into 3 lists of (near-)equal lengths
   */
  const wrapSlides = (slidesList, id) => {
    if (slidesList.length <= 5) {
      slideSets = seperateTopics(slidesList, true);
    } else {
      slideSets = seperateTopics(splitTopics(slidesList));
    }
    // slideSets[] is now a single <InnerSlidesHolder> or a list of InnerSlidesHolder>
    topicsList.push(<SlidesHolder key={id}>{slideSets}</SlidesHolder>);
  };

  /**
   * Function to wrap the divided slides
   * @param {Array} renderSet Array of divided slides
   * @param {Boolean} flag Boolean value to render slides without spllitting
   * @returns array of jsx
   */
  const seperateTopics = (renderSet, flag) => {
    if (flag) return <InnerSlidesHolder key={0}>{renderSet}</InnerSlidesHolder>;
    return map(renderSet, (singleSet, index) => {
      if (singleSet.length > 0)
        return <InnerSlidesHolder key={index}>{singleSet}</InnerSlidesHolder>;
    });
  };

  /**
   * Function to divid the provided array in three sets
   * @param {Array} arr array of slided to be divided in three sets
   * @returns array of divided elements
   */
  const splitTopics = arr => {
    let m,
      n,
      splitedArray = [];

    m = Math.ceil(arr.length / 3);
    n = Math.ceil((2 * arr.length) / 3);

    splitedArray.push(arr.slice(0, m));
    splitedArray.push(arr.slice(m, n));
    splitedArray.push(arr.slice(n, arr.length));

    return splitedArray;
  };

  // Recursive function call to make hierarchy of topics
  renderTopic(topics);

  // runtime complains about useEffect in this component.
  // commenting out for now, as it's unclear what this actually does.
  // on its face, it calls setAllSlidesData() with the current value of allSidesData,
  // which has been updated by the renderTopics() call above. Given the dependency on 
  // [topics] it seems unlikely this effect is ever run other than on after the first
  // render (object comparison is shallow - topics is the full category/slide hierarchy)
  
  useEffect(()=> { setAllSlidesData && setAllSlidesData(allSlidesData) },[topics.length])

  // we're expecting slides[] and filteredSlides[] to be in props
  // presentation/pages/build/components/library/index.js => ViewTabs => ViewByTopic
  let slides = 'slides' in props ? props.slides : undefined;
  let filteredSlides = 'filteredSlides' in props ? props.filteredSlides : undefined;
  let visibleSlideCount = Array.isArray(filteredSlides) ? filteredSlides.length : 0;
  let totalSlideCount = Array.isArray(slides) ? slides.length : 0;

  return (
    <>
      {fromPresentationScreen && (
        <CenteredText>{`showing ${visibleSlideCount} of ${totalSlideCount} slides`}</CenteredText>
      )}
      <ButtonContainer>
        <DeselectButton
          disabledButton={!(Array.isArray(selectSlides) && selectSlides.length)}
          onClick={handleDeselect}
          className="deselect-button"
        >
          Remove All Slides From Presentation
        </DeselectButton>
      </ButtonContainer>
      {Array.isArray(topics) && topics.length ? (
        <TabContent>{topicsList}</TabContent>
      ) : (
        <TabContent>
          <NoContentSlide>
            {contentRepo && contentRepo.title
              ? `No content slides available under selected ${
                  selectedFilters.length ? `filters` : `theme`
                }.`
              : `Please select content repo.`}
          </NoContentSlide>
        </TabContent>
      )}
    </>
  );
};

const SlidesHolder = styled.div`
  padding-bottom: 26px;
  margin-top: 18px;
`;

const TabContent = styled.div`
  padding: 0 27px 12px 34px;
`;

const DeselectButton = styled.button`
  padding: 4px 18px;
  border: 1px solid ${props => props.theme.COLOR.MAIN};
  border-radius: 4px;
  margin: 22px 22px 0 0;
  position: relative;
  z-index: 1;
  background-color: transparent;
  color: ${props => props.theme.COLOR.HEADING};
  cursor: pointer;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  font-weight: bold;
  opacity: 0.7;
  transition: 0.5s all ease;
  pointer-events: ${props => (props.disabledButton ? "none" : "auto")};
  &:hover {
    background: ${props => props.theme.COLOR.HEADING};
    color: ${props => props.theme.COLOR.WHITE};
  }
`;

const InnerSlidesHolder = styled.div`
  width: 33%;
  display: inline-block;
  vertical-align: top;
`;

const ButtonContainer = styled.div`
  text-align: right;
  box-shadow: 0px 9px 9px 0px rgba(255, 255, 255, 1);
`;

const NoContentSlide = styled.p`
  padding: 10px 0;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;

// this style is temporary. for the plain slide count display div
// remove it when a real display is designed/implemented
const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  width: 50%;
`;
