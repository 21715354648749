import React, { Component } from "react";
import { intersection } from "lodash";

const Container = Main =>
  class UserTabs extends Component {
    state = {
      rolesList: [
        {
          label: "PG Users",
          id: "PG-Users",
          name: "PG-Users",
          defaultChecked: "true",
          hint: "Select to assign Presentation Generator User Role.",
          accessTo: ["PG-System-Admin"]
        },
        {
          label: "PG System Admin",
          id: "PG-System-Admin",
          name: "PG-System-Admin",
          hint: "Select to assign Presentation Generator System Admin Role.",
          accessTo: ["PG-System-Admin"]
        },
        {
          label: "RG Admin",
          id: "RG-Admin",
          name: "RG-Admin",
          hint: "Select to assign Renewal Generator Admin Role.",
          accessTo: ["RG-Admin"]
        },
        {
          label: "RG Sales",
          id: "RG-Sales",
          name: "RG-Sales",
          hint: "Select to assign Renewal Generator Sales Role.",
          accessTo: ["RG-Admin"]
        },
        {
          label: "RG Underwriter",
          id: "RG-Underwriter",
          name: "RG-Underwriter",
          hint: "Select to assign Renewal Generator Underwriter Role.",
          accessTo: ["RG-Admin"]
        },
        {
          label: "MG Admin",
          id: "MG-Admin",
          name: "MG-Admin",
          hint: "Select to assign Collateral Maker admin role.",
          accessTo: ["MG-Admin"]
        },
        {
          label: "MG Users",
          id: "MG-Users",
          name: "MG-Users",
          hint: "Select to assign Collateral Maker user role.",
          accessTo: ["MG-Admin"]
        },
        {
          label: "PG Library",
          id: "PG-Library",
          name: "PG-Library",
          hint: "Select to assign Library user role.",
          accessTo: ["PG-Library"]
        },
        {
          label: "SM Admin",
          id: "Summary Maker Admin",
          name: "SM Admin",
          hint: "Select to assign Summary Maker admin role.",
          accessTo: ["Summary Maker Admin"]
        },
        {
          label: "SM Users",
          id: "Summary Maker",
          name: "SM Users",
          hint: "Select to assign Summary Maker user role.",
          accessTo: ["Summary Maker"]
        }
      ]
    };
    /**
     *Check which the current user role and compare which users the cuurnet admin can create
     *
     * @param {*} accessTo Array of user roles who can create the tool based user.
     *
     */
    checkAvailableRoles = accessTo => {
      let { userProfileMeta: profile } = this.props;
      if (!profile) return null;
      return (
        !!Object.keys(profile).length &&
        intersection(accessTo, profile.roles).length
      );
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props,
        setTab: this.props.setTab,
        selectedTabValue: this.props.selectedTabValue,
        onFileUpload: this.props.onFileUpload,
        uploadedFile: this.props.uploadedFile
      };
      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
