import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { map } from "lodash";
import { Dropdown } from "assets/icons";
import Checkbox from "components/checkbox";

const CheckBoxComponent = ({ title, _id, checked, handleCheckBoxChange }) => {
  return (
    <CheckBoxWrapper key={_id}>
      <Checkbox
        handleChange={e => {
          let flag = e.target.checked;
          handleCheckBoxChange(_id, flag);
        }}
        label={title}
        id={`${_id}`}
        checked={checked}
        chooseContent={true}
      />
    </CheckBoxWrapper>
  );
};

const FilterList = ({
  children,
  title,
  selectedFilters,
  handleStateChange
}) => {
  let [showMore, setShowMore] = useState(5);
  let [showList, setShowList] = useState(true);
  let allChildsList = [];
  let childrensList = [];

  if (Array.isArray(children)) {
    allChildsList = children.slice(0, showMore);
    childrensList = children.map(({ _id }) => _id);
    if (allChildsList.length < children.length) {
      allChildsList.push({
        _id: "more" + allChildsList[0]._id,
        title: "More"
      });
    }
  }

  // set filters children to check if exists
  selectedFilters = Array.isArray(selectedFilters) ? selectedFilters : [];

  const handleCheckBoxChange = (_id, checked) => { 
    const selectedIndex = selectedFilters.indexOf(_id);

    if (_id.indexOf("F-") > -1 && checked && selectedIndex < 0) {
      // check if children list already pushed in filters to avoid duplicates
      childrensList.forEach(elem => {
        let index = selectedFilters.indexOf(elem);
        index === -1 && selectedFilters.push(elem);
      });
    } else if (_id.indexOf("F-") > -1 && !checked && selectedIndex > -1) {
      // remove parent from selectedFilters
      selectedFilters.splice(selectedIndex, 1);
      // remove childrens from selectedFilters
      childrensList.forEach(elem => {
        let index = selectedFilters.indexOf(elem);
        index > -1 && selectedFilters.splice(index, 1);
      });
    } else if (selectedIndex < 0 && checked) {
      // select and handle child checked status
      selectedFilters.push(_id);

      //handle all flag status if childs are selected individuallly
      if (Array.isArray(children)) {
        let allFlagStatus = children.filter(
          ({ _id }) => selectedFilters.indexOf(_id) > -1
        );

        let allTagElement = children.filter(
          ({ _id }) => _id.indexOf("F-") > -1
        );

        if (allFlagStatus.length === children.length - 1) {
          selectedFilters.push(allTagElement[0]._id);
        }
      }
    } else if (selectedIndex > -1 && !checked) {
      // remove parent selected filter if child is unchecked
      let parentIndex = selectedFilters.indexOf(
        childrensList.filter(elem => elem.indexOf("F-") > -1)[0]
      );
      parentIndex > -1 && selectedFilters.splice(parentIndex, 1);

      // remove selected filter if unchecked
      const selectedIndex = selectedFilters.indexOf(_id);
      selectedIndex > -1 && selectedFilters.splice(selectedIndex, 1);
    }

    handleStateChange({
      key: "selectedFilters",
      value: selectedFilters
    });
  };

  return (
    <FilterTypeWrapper>
      <FilterTypeHeader onClick={() => setShowList(!showList)}>
        <FilterTypeHeading title={title}>{title}</FilterTypeHeading>
        <DropdownIcon showList={showList}>
          <Dropdown />
        </DropdownIcon>
      </FilterTypeHeader>
      {showList && (
        <FilterTypeValues>
          {map(allChildsList, ({ _id, title }, index) => {
            return (
              <Fragment key={_id}>
                {_id.indexOf("more") > -1 ? (
                  <More onClick={() => setShowMore(children.length)}>
                    + More
                  </More>
                ) : (
                  <CheckBoxComponent
                    title={title}
                    index={index}
                    _id={_id}
                    checked={selectedFilters.indexOf(_id) > -1}
                    handleCheckBoxChange={handleCheckBoxChange}
                  />
                )}
              </Fragment>
            );
          })}
        </FilterTypeValues>
      )}
    </FilterTypeWrapper>
  );
};

export default FilterList;

const DropdownIcon = styled.span`
  cursor: pointer;
  transform: ${props => (props.showList ? "rotate(180deg)" : "rotate(0deg)")};
`;

const More = styled.span`
  display: inline-block;
  padding-top: 5px;
  color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const FilterTypeHeader = styled.div`
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-transform: capitalize;
`;

const FilterTypeValues = styled.div``;

const FilterTypeWrapper = styled.div`
  padding: 0 12px 0 12px;
  margin-bottom: 17px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FilterTypeHeading = styled.h4``;

const CheckBoxWrapper = styled.div`
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  /* If last child is > 5th child */
  &:nth-of-type(n + 5):last-of-type {
    margin-bottom: 0;
  }

  .checkbox-container {
    label {
      font-size: 14px;
      color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
      text-transform: capitalize;
    }
  }
`;
