import React from "react";
import styled, { css } from "styled-components";
import { get } from "lodash";
import { SlidePreviewRightArrow, SlidePreviewLeftArrow } from "assets/icons";

//components
import StepDetails from "components/buildProcess/stepDetails";
import PreviewPanel from "./components/PreviewPanel";
import PagesPanel from "./components/PagesPanel";

const Preview = props => {
  let {
    modifyStepHandler,
    activeStep,
    isPollingOngoing,
    onDownload,
    pageNavigationHandler,
    navigationInput,
    navigationInputHandler,
    previewPageNo = 0,
    pagesDetails,
    changeSetWidthOptionForPreview,
    setWidthOptionForPreview
  } = props;

  return (
    <>
      <StepDetails
        title="Preview"
        _previous
        disableUploadButton={isPollingOngoing}
        _download={true}
        _navigationArrows={true}
        _selectDropdownForWidth_sm_preview={true}
        onDownload={onDownload}
        pageNavigationHandler={pageNavigationHandler}
        navigationInputHandler={navigationInputHandler}
        navigationInput={navigationInput}
        previewPageNo={previewPageNo}
        pagesDetails={pagesDetails}
        onPrevious={() => modifyStepHandler(activeStep - 1)}
        description="You can share/download your document once all the pages are built."
        changeSetWidthOptionForPreview={changeSetWidthOptionForPreview}
        setWidthOptionForPreview={setWidthOptionForPreview}
        {...props}
      />

      <PageContent>
        <PagesPanel {...props} />
        <PreviewPanel {...props} />
      </PageContent>
    </>
  );
};

const PageContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 80px;
  position: relative;
`;

export default Preview;
