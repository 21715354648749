// libraries
import React, { Component } from "react";
// components
import CoverBlock from "./Cover";
import { get, filter, map, toLower, find, size } from "lodash";

class CoverBlockpage extends Component {
  state = {
    coverImages: [],
    layoutContentElements: [],
    layoutOptions: [],
    selectedLayout: "single",
    selectedOption: "type",
    metaDataName: "",
    metaDataDefaultName: "",
    metaDataList: [],
    metaDataError: false,
    checkboxValue:false
  };

  specialCharacterValidation = "*Please do not enter the special character.";
  emptyFormValidation = "*please fill both fields and then submit the form";
  duplicateVariableNameError = "Variable with same name already exists.";
  duplicateImageMetaDataError = "You can add image metadata only once.";

  async componentDidMount() {
    const templateId = get(this.props, `match.params.templateId`);
    await this.props.addTemplateBlocksAdmin(templateId, false);
    this.fetchCoverImageData();
    this.fetchBlockVariableData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.layoutBlocks !== prevProps.layoutBlocks) {
      this.setState({
        layoutContentElements: this.props.layoutBlocks
      });
    }
  }

  handleOptionChange = e => {
    const { metaDataList, metaDataError } = this.state;
    let isImageMetaData = false;

    metaDataList &&
      size(metaDataList) > 0 &&
      map(metaDataList, eachMetaData => {
        eachMetaData.type === "image" &&
          e.target.value === "image" &&
          (isImageMetaData = true);
      });

    if (isImageMetaData) {
      this.setState({
        metaDataError: this.duplicateImageMetaDataError,
        selectedOption: e.target.value
      });
    } else {
      this.setState({
        selectedOption: e.target.value
      });

      if (metaDataError === "You can add image metadata only once.") {
        this.setState({
          metaDataError: false
        });
      }
    }
  };

  handleInputChange = e => {
    const regExp = /^[a-zA-Z0-9 ]*$/;
    if (!regExp.test(e.target.value)) {
      this.setState({
        metaDataError: this.specialCharacterValidation
      });
    } else {
      this.setState({
        metaDataError: false
      });
    }
    this.setState({ metaDataName: e.target.value });
  };

  handleCheckboxHandler = e =>{
    console.log('checkbox Value ', e.target)
    this.setState({
      checkboxValue: e.target.checked
    })
  }

  handleInputDefaultValueChange = e => {
    this.setState({ metaDataDefaultName: e.target.value });
  };

  addMetaData = e => {
    e.preventDefault();
    const {
      metaDataName,
      metaDataDefaultName,
      checkboxValue,
      selectedOption,
      metaDataError,
      metaDataList
    } = this.state;

    let isDuplicateVariableName = false;

    map(metaDataList, eachVariable => {
      metaDataName.trim() === eachVariable.label &&
        (isDuplicateVariableName = true);
    });

    if (isDuplicateVariableName) {
      this.setState({
        metaDataError: this.duplicateVariableNameError
      });
      return false;
    }

    let isImageMetaData = false;

    metaDataList &&
      size(metaDataList) > 0 &&
      map(metaDataList, eachMetaData => {
        eachMetaData.type === "image" &&
          selectedOption === "image" &&
          (isImageMetaData = true);
      });

    if (isImageMetaData) {
      this.setState({
        metaDataError: this.duplicateImageMetaDataError
      });
      return false;
    }

    if (metaDataName && selectedOption !== "Type" && !metaDataError) {
      this.setState(
        {
          metaDataList: [
            ...this.state.metaDataList,
            {
              label: metaDataName,
              type: selectedOption.toLowerCase(),
              defaultValue: metaDataDefaultName,
              required: checkboxValue
            }
          ]
        },
        () => {
          this.setState({
            metaDataName: "",
            selectedOption: "image",
            metaDataError: false,
            metaDataDefaultName: "",
            checkboxValue: false
          });
        }
      );

      this.props.handleStateChange({ key: "isBlockEdited", value: true });
    } else {
      this.setState({
        metaDataError: !metaDataError ? this.emptyFormValidation : metaDataError
      });
    }
  };

  saveHandler = async () => {
    const { metaDataList } = this.state;
    const { showToastMessage, updateTemplateBlockHandler } = this.props;
    const templateId = get(this.props, `match.params.templateId`);

    if (metaDataList.length > 0 && this.props.isBlockEdited) {
      this.setState({
        selectedOption: "Type",
        metaDataName: "",
        metaDataDefaultName: "",
        metaDataError: false
      });
      const response = await updateTemplateBlockHandler({
        variables: [...metaDataList]
      });

      if (response.success) {
        const {
          data: { variables }
        } = response;

        variables && variables.length > 0
          ? await this.setState({
              metaDataList: [...variables]
            })
          : await this.setState({
              metaDataList: []
            });
      }
    } else {
      showToastMessage("error", "Please add new metadata.");
    }
  };

  fetchBlockVariableData = async () => {
    const {
      isActiveOnClickTemplateBlocks: { _id },
      templateBlocks
    } = this.props;

    let coverBlock = {};

    size(templateBlocks) &&
      map(templateBlocks, eachBlock => {
        eachBlock._id === _id &&
          eachBlock.name === "cover" &&
          (coverBlock = eachBlock);
      });

    const { variables = {} } = coverBlock;

    variables && variables.length > 0
      ? await this.setState({
          metaDataList: [...variables]
        })
      : await this.setState({
          metaDataList: []
        });
  };

  fetchCoverImageData = async () => {
    const {
      isActiveOnClickTemplateBlocks: { _id: blockId }
    } = this.props;

    const templateId = get(this.props, `match.params.templateId`);

    if (blockId && templateId) {
      await this.props.getCoverImageData(blockId, templateId);
      const { coverImageData } = this.props;

      this.setState({
        coverImages: coverImageData ? coverImageData : []
      });
    }
  };

  removeMetaData = async (metaDataId, index) => {
    const { updateTemplateBlockHandler } = this.props;
    const templateId = get(this.props, `match.params.templateId`);
    if (metaDataId) {
      const response = await updateTemplateBlockHandler({}, metaDataId);

      if (response.success) {
        const {
          data: { variables }
        } = response;

        variables && variables.length > 0
          ? await this.setState({
              metaDataList: [...variables]
            })
          : await this.setState({
              metaDataList: []
            });
      }
    } else {
      let updatedList = [...this.state.metaDataList];
      updatedList.splice(index, 1);
      this.setState({
        metaDataList: updatedList
      });
    }
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <CoverBlock {...stateMethodProps} />;
  }
}

export default CoverBlockpage;
