import React from "react";
import styled from "styled-components";

import Container from "./container";
import Header from "./components/header";
import SortableTree from "./components/sortableTree";
import SlidesContainer from "./components/slideContainer";
import TabToggleHeader from "./components/tabHeader";
import ContentSlideGroup from "./components/ContentSlideGroup";
import Filters from "./components/Filters";
import handleBodyScroll from "utils/handleBodyScroll";
import hexToRgba from "utils/hexToRgba";
import { Close } from "assets/icons";

const LeftPanelRef = React.createRef();

const _renderContent = (props, { isLoading } ) => {
  switch (props.activeTab) {
    case "slide":
      return <SlidesContainer {...props} isLoading={isLoading} />;
    case "group":
      return <ContentSlideGroup {...props} isLoading={isLoading} />;
    case "filter":
      return <Filters {...props} isLoading={isLoading}/>;
    default:
      return null;
  }
};

const ContentRepoComponent = props => {
  const {
    contentRepoConfig: { header, levelManager },
    manageParentStates,
    currentSelectedSlide,
    activeTab: active,
    editedSlide,
    handleFile,
    showPreview,
    previewSlide,
    handleImageLoad,
    previewImageLoading,
    isLoading,
  } = props;

  let showSidebar = !!(Object.keys(editedSlide).length || currentSelectedSlide);
  const { repoTitle } = header || {}

  return (
    <ContentRepo>
      {showPreview
        ? handleBodyScroll({ action: "open" })
        : handleBodyScroll({ action: "close" })}
      {showPreview && (
        <>
          <Overlay onClick={handleFile}></Overlay>
          <OverlayImageWrapper>
            <OverlayCoverImg src={previewSlide} onLoad={handleImageLoad} />
            {!previewImageLoading && (
              <CloseIconWrapper>
                <CloseIcon onClick={handleFile} />
              </CloseIconWrapper>
            )}
          </OverlayImageWrapper>
        </>
      )}
      <ContentRepoWrapper>
        {/* Header */}
        <Header {...header} {...props} />

        {/* CategoryLevelManager */}
        <SortableTree
          {...levelManager}
          {...props}
          isLoading={isLoading}
          LeftPanelRef={LeftPanelRef}
        />

        {/* Slides/Group Manager */}
        <LeftPanelWrapper ref={LeftPanelRef}>
          <RepoSlideGroupComponent>
            <SlidesGroupManager>
              {/* Tab Toggle Header for slides and groups sections */}
              {showSidebar && (
                <>
                  <TabToggleHeader {...{ manageParentStates, active }} />
                  {_renderContent({ ...props }, { isLoading: isLoading } )}
                </>
              )}
            </SlidesGroupManager>
          </RepoSlideGroupComponent>
        </LeftPanelWrapper>
      </ContentRepoWrapper>
    </ContentRepo>
  );
};

ContentRepoComponent.defaultProps = {
  contentRepoConfig: {
    header: {},
    levelManager: {}
  }
};

const LeftPanelWrapper = styled.div`
  width: calc(100% - 75.4% - 5px);
  display: inline-block;
  vertical-align: top;
  @media (max-width: 1024px) {
    width: calc(100% - 648px);
  }
`;

const ContentRepo = styled.div`
  * {
    box-sizing: border-box;
  }
  min-height: 1px;
  width: 100%;
`;

const ContentRepoWrapper = styled.div`
  max-width: 1250px;
  padding: 0 40px;
  background-color: ${props => props.theme.COLOR.CONTAINER};
  width: 100%;

  .delete-confirm-note {
    padding-top: 0;
  }
`;

/**
 * Container styles
 */
const RepoSlideGroupComponent = styled.div`
  width: 100%;
  min-height: 540px;
  border-radius: 4px;
  margin-left: 9px;
  position: relative;
  background: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.14);
  /* Scrollbar hidden */
  .slide-scrollbar {
    > div:first-child > div:nth-child(2) {
      overflow-x: hidden;
      display: none;
    }
  }
`;

const SlidesGroupManager = styled.div`
  border-radius: 3px;
  background: ${props => props.theme.COLOR.WHITE};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 21;
`;

const OverlayCoverImg = styled.img`
  width: 100%;
  height: 100%;
`;

const OverlayImageWrapper = styled.div`
  width: 40%;
  padding: 10px;
  min-width: 600px;
  z-index: 21;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: content-box;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

export default Container(ContentRepoComponent);
