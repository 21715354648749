//libraries
import React from "react";
import styled from "styled-components";

//components
import CustomTable from "components/customTable";

const UploadedContent = props => {
  let {
    tableColumnsKeys,
    tableColumns,
    columnWidth,
    showIcon,
    filesList
  } = props;

  return (
    <DashboardTable>
      <CustomTable
        data={filesList || []}
        columnWidth={columnWidth}
        searchFields={tableColumnsKeys ? [tableColumnsKeys[0]] : []}
        renderHead={() => {}}
        showActiveRow={true}
        showIcon={showIcon}
        expanderWidth="25"
        tableColumnHeader={tableColumnsKeys}
        role="admin" // eslint-disable-line jsx-a11y/aria-role
        tableHeader={tableColumns}
        searchFieldName="name"
        defaultSorted={[
          {
            id: "name",
            desc: false
          }
        ]}
      />
    </DashboardTable>
  );
};

const DashboardTable = styled.div`
  .new-button {
    display: none;
  }

  .admin-search {
    position: static;
  }
  .table-wrapper {
    padding: 0;
  }

  @media (max-width: 1240px) {
    .ReactTable .rt-table {
      overflow: auto;
    }
  }
`;

export default UploadedContent;
