// libraries
import React from "react";
import styled, { css } from "styled-components";
import { map, get } from "lodash";

// components
import CheckBox from "components/checkbox";
import ChooseFile from "components/chooseFile";
import Button from "components/button";
import ButtonScrollSlider from "components/buttonScrollSlider";
import SlideDropDown from "components/dropDown";

const LayoutBlock = props => {
  const {
    layoutOptions,
    layoutOptionHandler,
    layoutContentElements,
    saveHandler,
    handleDropDownChange,
    uploadFileToRepo,
    layoutDropdownOption,
    selectedLayout,
    getLayoutBlocks,
    isActiveOnClickTemplateBlocks
  } = props;

  return (
    <PageWrapper>
      <UploadedSection>
        <BlockHeader>
          <Heading>Content Elements</Heading>
          <Button
            text="Save"
            width="100px"
            float="none"
            buttonClass="save"
            onClick={saveHandler}
          />
        </BlockHeader>
        <UploadedFileSection>
          <ButtonScrollSliderWrapper>
            {get(layoutContentElements, `length`) ? (
              <ButtonScrollSlider
                contentElements={layoutContentElements}
                isShowDeleteIcon={true}
              />
            ) : (
              <NoContentElement>
                Your content elements go here.
              </NoContentElement>
            )}
          </ButtonScrollSliderWrapper>
          <DropdownWrapper>
            <Name>Select Layout Option:</Name>
            <SlideDropDownWrapper>
              <SlideDropDown
                option={layoutDropdownOption}
                className="dropdown-container"
                handleChange={e => handleDropDownChange(e)}
              />
            </SlideDropDownWrapper>
          </DropdownWrapper>
          <ChooseFileWrapper>
            <ChooseFile
              {...props}
              uploadFileToRepo={({ metaDeta, file }, cb) => {
                uploadFileToRepo(
                  { metaDeta, file },
                  cb,
                  {
                    layout: selectedLayout
                  },
                  () => getLayoutBlocks(true)
                );
              }}
              uploadFileType="image"
              hint="Select a layout option and drag a .JPG or .PNG file here or click to browse"
            />
          </ChooseFileWrapper>
        </UploadedFileSection>
      </UploadedSection>
      <LayoutSelection>
        <Heading>User-selectable layouts</Heading>
        <CheckboxWrapper>
          {map(layoutOptions, (eachOption, index) => (
            <CheckBox
              key={index}
              label={get(eachOption, `label`)}
              id={index}
              checked={get(eachOption, `checked`)}
              handleChange={e => layoutOptionHandler(e, eachOption.label)}
            />
          ))}
        </CheckboxWrapper>
      </LayoutSelection>
      <UploadHtmlCss>
        <HtmlTemplateWrapper>
          <Heading>
            HTML Template:
            <Filename>
              {isActiveOnClickTemplateBlocks.htmlUploadedFileName
                ? get(isActiveOnClickTemplateBlocks, `htmlUploadedFileName`)
                : get(isActiveOnClickTemplateBlocks, `htmlFilename`)}
            </Filename>
          </Heading>
          <ChooseFile
            {...props}
            uploadFileType="html"
            hint="Drag a HTML file here or click to browse"
          />
        </HtmlTemplateWrapper>

        <CssUploadWrapper>
          <Heading>
            CSS file:
            <Filename>
              {isActiveOnClickTemplateBlocks.cssUploadedFileName
                ? get(isActiveOnClickTemplateBlocks, `cssUploadedFileName`)
                : get(isActiveOnClickTemplateBlocks, `cssFilename`)}
            </Filename>
          </Heading>
          <ChooseFile
            {...props}
            uploadFileType="css"
            hint="Drag a CSS file here or click to browse"
          />
        </CssUploadWrapper>
      </UploadHtmlCss>
    </PageWrapper>
  );
};

const UploadHtmlCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const SlideDropDownWrapper = styled.div`
  display: inline-block;
  width: 50%;
  margin: 10px 0;
`;

const StyledName = css`
  display: block;
  text-align: left;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
`;

const DropdownWrapper = styled.div`
  text-align: left;
  margin-top: 30px;
`;

const HtmlTemplateWrapper = styled.div`
  width: 48%;
`;

const CssUploadWrapper = styled.div`
  margin: 20px 0;
  width: 48%;
`;

const Filename = styled.span`
  font-size: 14px;
  margin-left: 3px;
`;

const Heading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin: 10px 0;
  text-align: left;
`;

const Name = styled.span`
  ${StyledName};
`;

const NoContentElement = styled.div`
  min-height: 100px;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonScrollSliderWrapper = styled.div``;

const UploadedFileSection = styled.div``;

const PageWrapper = styled.div`
  .checkbox-container {
    margin-right: 20px;
  }
`;

const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

const UploadedSection = styled.div``;

const LayoutSelection = styled.div`
  margin: 30px 0;
`;

const ChooseFileWrapper = styled.div`
  .btn-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  @media (max-width: 940px) {
    .filename {
      max-width: 117px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export default LayoutBlock;
