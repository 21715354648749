import { handleLastLoggedInTool, handleMenuOpenClose, handleSetToolsList } from "./actions";
import FetchUtilsV2 from "utils/FetchUtilsV2";

const getToolsList = () => async dispatch => {
  const { data } = await FetchUtilsV2.getData('/configuration');

  dispatch(handleSetToolsList({
    tools: data.tools
  }));
}

const setLastLoggedInTool = lastLoggedInTool => dispatch => {
  // let's check if the last logged in tool is one of the legacy tools
  // and restrict setting it for the new tools (sm v2, qc, msm, sl, ...)
  const legacyTools = ['rg', 'pg', 'mg', 'lib'];

  if (!legacyTools.includes(lastLoggedInTool)) {
    return false;
  }

  return dispatch(
    handleLastLoggedInTool({
      lastLoggedInTool
    })
  );
};

const setMenuOpenCloseState = menuState => dispatch => {
  dispatch(
    handleMenuOpenClose({
      isSideMenuOpen: menuState
    })
  );
};

export { getToolsList, setLastLoggedInTool, setMenuOpenCloseState };
