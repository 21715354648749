// libraries
import React, { Component } from "react";
import { layoutDetails } from "./data";
import { GenericStepperCircle } from "assets/icons";

import {
  get,
  map,
  find,
  orderBy,
  filter,
  each,
  uniqBy,
  toLower,
  indexOf,
  size
} from "lodash";

import { connect } from "react-redux";

// components
import SummaryMakerComponent from "./index";
import UI_STRINGS from "utils/stringConstants";
import PollingUtils from "utils/PollingUtils";
import ToastUtils from "utils/handleToast";
import blobToDataUri from "utils/blobToDataUri";

//services
import { getTemplateBlocks } from "tools/summaryMaker/store/actions/buildActions";
import {
  getBlockContents,
  setBlockContentData,
  saveContentBlockData,
  deleteFilesData,
  getFilesContent
} from "tools/summaryMaker/store/actions/contentBlockActions";
import {
  getTemplateList,
  getSelectedTemplate,
  getSingleTemplateList
} from "tools/summaryMaker/store/actions/dashboardActions";
import {
  getPreviewImages,
  getDocumentStatus,
  getPageImage,
  showPreviewLoader
} from "tools/summaryMaker/store/actions/previewBuildActions";

import {
  createDocument,
  getDocumentData,
  setCurrentDocumentData,
  getCoverData
} from "tools/summaryMaker/store/actions/documentBuildActions";

import {
  getCustomerLogoList,
  updateCustomerLogoTitle,
  deleteLogo,
  createNewCustomerLogo,
  attachCobrandLogoToDocument
} from "tools/summaryMaker/store/actions/setupCustomerLogoAction";

const mapStateToProps = state => {
  const {
    domain: {
      RECEIVE_TEMPLATE_BLOCKS,
      REQUEST_TEMPLATE_DATA,
      REQUEST_BLOCK_CONTENT,
      RECEIVE_BLOCK_CONTENT,
      REQUEST_BUILD_DOCUMENT_DATA,
      REQUEST_DATA,
      RECEIVE_PREVIEW_IMAGES,
      REQUEST_PREVIEW_DATA,
      RECEIVE_DOCUMENT_STATUS,
      SELECTED_TEMPLATE,
      RECEIVE_TEMPLATE_LIST,
      RECEIVE_BUILD_DOCUMENT_DATA,
      RECEIVE_COVER_DATA
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...RECEIVE_TEMPLATE_BLOCKS,
    ...REQUEST_TEMPLATE_DATA,
    ...REQUEST_BLOCK_CONTENT,
    ...RECEIVE_BLOCK_CONTENT,
    ...REQUEST_BUILD_DOCUMENT_DATA,
    ...SUCCESS_USER_PROFILE,
    ...REQUEST_DATA,
    ...RECEIVE_PREVIEW_IMAGES,
    ...REQUEST_PREVIEW_DATA,
    ...RECEIVE_DOCUMENT_STATUS,
    ...SELECTED_TEMPLATE,
    ...RECEIVE_TEMPLATE_LIST,
    ...RECEIVE_BUILD_DOCUMENT_DATA,
    ...RECEIVE_COVER_DATA
  };
};

const mapDispatchToProps = {
  getTemplateBlocks,
  getBlockContents,
  setBlockContentData,
  createDocument,
  getPreviewImages,
  getDocumentStatus,
  getPageImage,
  saveContentBlockData,
  deleteFilesData,
  getFilesContent,
  getTemplateList,
  getSelectedTemplate,
  getDocumentData,
  setCurrentDocumentData,
  showPreviewLoader,
  getSingleTemplateList,
  getCoverData,
  getCustomerLogoList,
  updateCustomerLogoTitle,
  deleteLogo,
  createNewCustomerLogo,
  attachCobrandLogoToDocument
};

class BuildContainer extends Component {
  state = {
    activeStep: 0,
    stepItems: [],
    layoutDetails: [],
    activePlaceholderDetails: [],
    selectedFileId: null, // selected file id from the rates block
    glossaryColumnItems: [], // items for the glossary
    documentFields: [],
    previewPageDetails: [],
    glossaryPageRef: [],
    isDataEdited: false, // states whether to send document data (call api on user interaction)
    ratesWorkBookName: null,
    isPageLayoutSet: false,
    isEnableTemplateSelection: true,
    selectedCoverLayout: null,
    selectedHtmlLayout:null,
    coverDetails: {},
    htmlTemplateDetails: {},
    documentCoverFields: {},
    documentHtmlTemplateUIFields: {},
    cobrandLogoUrl: null,
    isCoverBlockDeleted: false,
    isHtmlBlockDeleted:false,
    isSingleImagePresent: false,
    isSingleHtmlImagePresent: false,
    nextBlockToCoverBlock: false,
    htmlImageVariableId: null,
    shouldPreviewPoll: true,
    reRender: true,
    documentCurrentId: null,
  };

  componentDidMount() {
    //clearing last saved document data
    this.props.setCurrentDocumentData({});

    // Add event listener to check for unsaved data
    window.addEventListener("beforeunload", this.checkForUnsavedData);

    this.getStepperDetails();

    // empty old redux store data
    this.props.setBlockContentData([], "appendixContentBlock");
    this.props.setBlockContentData([], "glossaryContentBlock");
    this.props.setBlockContentData([], "topicsContentBlock");
    this.setState({
      layoutDetails: JSON.parse(JSON.stringify(layoutDetails))
    });

    this.getActiveDocumentData();
  }

  getActiveDocumentData = async (currentDocumentId = false) => {
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = currentDocumentId
      ? currentDocumentId
      : get(this.props, `match.params.documentId`);

    if (documentId && userId) {
      await this.props.getDocumentData(userId, documentId);
      const { currentDocumentData } = this.props;

      const selectedTemplate = find(
        get(this.props, `templateList`),
        eachTemplate =>
          eachTemplate._id === get(currentDocumentData, `template._id`)
      );
      const cobrandLogoUrl = get(selectedTemplate, `variables[0].value`);
      this.setState({
        isEnableTemplateSelection: false,
        cobrandLogoUrl: cobrandLogoUrl
          ? cobrandLogoUrl
          : this.state.cobrandLogoUrl
      });
      this.props.getSelectedTemplate(selectedTemplate);
      this.getStepperDetails();
    }
  };

  /**
   *
   * @param selectedLogo logo which was selected from the bottom or uploaded
   * @param {boolean} isImageSourceUrl states the selectedLogo is image url or data uri
   */
  selectedLogoHandler = selectedLogo => {
    if (!selectedLogo) {
      this.setState({
        selectedLogo: null,
        selectedLogoId: null,
        cobrandLogoUrl: null
      });
      this.state.selectedLogo && this.onStepEdit();
      return;
    }
    this.setState({
      selectedLogo: selectedLogo.url || selectedLogo,
      croppedImage: selectedLogo.url || selectedLogo, // storing same data as selectedLogo so that croppedImage could be used for preview in the placeholder and selectedLogo as the imageSrc
      selectedLogoId: selectedLogo._id
    });
    this.onStepEdit();
  };

  /**
   * Call on any input field edited in a step
   *
   */
  onStepEdit = (flag = true) => {
    let { completedSteps, activeStep } = this.state;
    let newStepArray = filter(completedSteps, ele => {
      return ele !== activeStep;
    });
    this.setState({
      completedSteps: newStepArray,
      isEdited: flag
    });
  };

  checkForUnsavedData = e => {
    if (this.state.isDataEdited) {
      e.preventDefault();
      e.returnValue = "";
    } else {
      return;
    }
  };

  componentDidUpdate(prevProps) {
    if (
      get(this.props, `selectedTemplate._id`) !==
      get(prevProps, `selectedTemplate._id`)
    ) {
      this.getStepperDetails();
      this.getActiveDocumentData();
    }
    if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
      this.getActiveDocumentData();
    }
  }

  componentWillUnmount() {
    PollingUtils.stopPolling();
    window.removeEventListener("beforeunload", this.checkForUnsavedData);
  }

  getStepperDetails = async () => {
    if (this.props.match.params.templateId) {
      const { templateId } = this.props.match.params;
      await this.props.getSingleTemplateList(templateId);
      if (get(this.props, `templateList.length`)) {
        this.props.getSelectedTemplate(this.props.templateList[0]);
      }
    } else {
      if (!this.props.selectedTemplate) {
        await this.props.getTemplateList();
        if (get(this.props, `templateList.length`)) {
          this.props.getSelectedTemplate(this.props.templateList[0]);
        }
      }
    }

    let templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    let isCoverBlockPresent = false;
    let coverBlockIndex = 0;
    let isHtmlBlockPresent = false;
    let htmlBlockIndex = 0;
    templateBlocks &&
      size(templateBlocks) > 0 &&
      map(templateBlocks, (eachBlock, index) => {
        if (eachBlock) {
          const { type } = eachBlock;
          if (type === "pptTemplate") {
            isCoverBlockPresent = true;
            coverBlockIndex = index;
          }else if(type === 'htmlTemplate'){
            isHtmlBlockPresent = true;
            htmlBlockIndex = index
          }
        }
      });

    if (isCoverBlockPresent) {
      const coverBlock = templateBlocks[coverBlockIndex];
      const { variables } = coverBlock;

      if (variables && size(variables) === 0) {
        console.log("first if");
        templateBlocks.splice(coverBlockIndex, 1);
        this.setState({
          isCoverBlockDeleted: true,
          isSingleImagePresent: false,
          nextBlockToCoverBlock:
            size(templateBlocks) > 1
              ? templateBlocks[coverBlockIndex + 1].name
              : "Preview"
        });
      } else if (
        variables &&
        size(variables) === 1 &&
        variables[0].type === "image"
      ) {
        const isSingleImagePresent = await this.fetchCoverImageData(
          coverBlock._id
        );

        if (isSingleImagePresent) {
          this.setState({
            isSingleImagePresent: true,
            isCoverBlockDeleted: true,
            coverImageVariableId: variables[0]._id,
            nextBlockToCoverBlock:
              size(templateBlocks) > 1
                ? templateBlocks[coverBlockIndex + 1].name
                : "Preview"
          });

          templateBlocks.splice(coverBlockIndex, 1);
        }
      } else {
        this.setState({
          isSingleImagePresent: false,
          isCoverBlockDeleted: false,
          nextBlockToCoverBlock: false
        });
      }
    }

    /**
     * This section of the code is for HTML template type.
     * This function will handle 2 scenarios. 
     * 1. When the stepper is of type htmlTemplate
     * 2. When the stepper is of type pptTemplate
     */
    if (isHtmlBlockPresent) {
      const htmlBlock = templateBlocks[htmlBlockIndex];
      const { variables } = htmlBlock;
      if (variables && size(variables) === 0) {
        /**
         * Logic to conditionally render the values.
         * This program is using a condition that if there 
         * are not metaValues present in the `htmlTemplate`
         * or on `pptTemplate`, then slice down the block 
         * from the stepper itself. @TODO instead of slicing the
         * value, we can simply hide it in the UI as template block
         *          
         * */
        templateBlocks.splice(htmlBlockIndex, 1);
        this.setState({
          isHtmlBlockDeleted: true,
          isSingleHtmlImagePresent: false,
          nextBlockToCoverBlock:
            size(templateBlocks) > 1
              ? templateBlocks[htmlBlockIndex + 1].name
              : "Preview"
        });
      } else if (
        variables &&
        size(variables) === 1 &&
        variables[0].type === "image"
      ) {
        /**
         * Another Logic which says that if the metavalue entered
         * by the admin is only of type image not `string` or `date`
         * The use of `isSingleHtmlImagePresent` (component state) 
         * is to take out the first array indexed value. 
         * @TODO this should be generic handling every scenario as there 
         * can be only 1 image block at the moment. 
         */
        const isSingleImagePresent = await this.props.getCoverData(
          htmlBlock._id,
          this.props.selectedTemplate._id
        );

        if (isSingleImagePresent) {
          this.setState({
            isSingleHtmlImagePresent: true,
            isHtmlBlockDeleted: true,
            coverImageVariableId: variables[0]._id,
            nextBlockToCoverBlock:
              size(templateBlocks) > 1
                ? templateBlocks[htmlBlockIndex + 1].name
                : "Preview"
          });

          templateBlocks.splice(htmlBlockIndex, 1);
        }
      } else {
        this.setState({
          isSingleHtmlImagePresent: false,
          isHtmlBlockDeleted: false,
          nextBlockToCoverBlock: false
        });
      }
    }

    // pushing first block as document block
    !find(templateBlocks, ["name", "Document"]) &&
      templateBlocks.unshift({
        description: UI_STRINGS.DOCUMENT_PANEL_DESCRIPTION,
        type: "document",
        name: "Document"
      });
    // pushing last block as preview panel as every template will have a preview block
    !find(templateBlocks, ["name", "Preview"]) &&
      templateBlocks.push({
        description: UI_STRINGS.PREVIEW_PANEL_DESCRIPTION,
        type: "preview",
        name: "Preview"
      });

    this.setState(
      {
        stepperDetails: templateBlocks
      },
      () => {
        this._formatStepDetails();
      }
    );
  };

  /**
   * we can refactor this function to be utilized 
   * directly where it is called. It is simply used
   * to determine whether or not the block contains
   * a single image or not. This boolen logic can be placed
   * directly. 
   */
  fetchCoverImageData = async blockId => {
    const {
      selectedTemplate: { _id: templateId }
    } = this.props;

    if (blockId && templateId) {
      /**
       * getCoverData function fetches the block content of any 
       * template block. @TODO rename it to a generic function here 
       * as it can be used to fetch any block content, not just
       * coverData.
       * There a 3rd parameter which stops the getCoverData to write to
       * redux store. 
       */
      await this.props.getCoverData(blockId, templateId, "pptTemplate");
      const { coverBlockData } = this.props;

      if (size(coverBlockData) === 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  /**
   * @param {String} imageKey states whether the call of api is for thumbnailKey or previewKey
   * @param {Number} pageIndex the index where the base64 data should be inserted
   * @param {String} dataUriKey image data uri
   * @param {boolean} [isStatusCurrent=false] states whether is page status si current or not(is the preview final)
   */
  getEachPageImage = async (
    imageKey,
    pageIndex,
    dataUriKey,
    isStatusCurrent = false
  ) => {
    const documentId = get(this.props, `currentDocumentData._id`);
    let response = await this.props.getPageImage(documentId, imageKey);
    /** The failed message co-relates to the ETL function 
     *  which throws error at any intermediate step.
     */
    if (response.message && response.message == "Failed") {
      return;
    }
    let { previewPageDetails } = this.state;

    const cb = base64Data => {
      if (previewPageDetails[pageIndex]) {
        previewPageDetails[pageIndex][dataUriKey] = base64Data;
        previewPageDetails[pageIndex]["isStatusCurrent"] = isStatusCurrent;

        // if status is current for a block, since the uri for the final preview is now also generated, isFinalPreviewFetched should be set to true
        if (isStatusCurrent) {
          previewPageDetails[pageIndex]["isFinalPreviewFetched"] = true;
        }

        // once the status is current, set isLargePreviewRendered to true so that large preview panel show the final image as well
        if (imageKey === "previewKey") {
          previewPageDetails[pageIndex][
            "isLargePreviewRendered"
          ] = isStatusCurrent;
        }

        this.setState({
          previewPageDetails
        });
      }
    };

    blobToDataUri(get(response, `data`), { type: "image/jpeg" }, cb);
  };

  // get preview images on preview mount if not already present
  handlePreviewImages = async () => {
    const documentId = get(this.props, `currentDocumentData._id`);
    await this.props.getPreviewImages(documentId);
    let { previewImages = [] } = this.props;

    let previewPageDetails = orderBy(previewImages, ["docPageNumber"], ["asc"]);

    this.setState({
      previewPageDetails
    });


  /**If the status of the build is not failed. Then it would continue
   * to Poll when on Preview page.
   * Only when the status is set to Failed, the FE would skip the poll
   * in-between steppers.
   */
  const buildStatus = previewPageDetails.some(
    i => i.buildStatus == "unknown" || i.buildStatus == "pending"
  );
  if(buildStatus){
    this.setState({
      isPollingOngoing: true
    });

    // start polling
    PollingUtils.startPolling({
      pollingAction: () => {
        this.pollingActionForBuild();
      },
      timeoutDuration: 180000,
      timeoutCallback: () => {
        PollingUtils.stopPolling();
        ToastUtils.handleToast({
          operation: "error",
          message: "Build is taking too long. Please try again later."
        });

        this.props.showPreviewLoader(false);
      }
    });
  }
  };

  getContentBlockWithUpdatedStatus = () => {
    let { previewPageDetails } = this.state;
    let { previewImages } = this.props;

    let blocksWithCurrentStatus = filter(previewImages, eachImage => {
      return get(eachImage, `buildStatus`) === "current";
    });

    each(blocksWithCurrentStatus, eachBlock => {
      each(previewPageDetails, eachPageDetail => {
        if (get(eachBlock, `blockId`) === get(eachPageDetail, `blockId`)) {
          eachPageDetail["isStatusCurrent"] = true;
        }
      });
    });

    this.setState({
      previewPageDetails
    });
  };

  /**
   * update preview images if new data comes in
   * @memberof BuildContainer
   */
  updatePreviewImages = () => {
    let { previewImages = [] } = this.props;
    let { previewPageDetails } = this.state;

    each(previewImages, eachImage => {
      if (
        !find(previewPageDetails, ["previewKey", eachImage.previewKey]) &&
        get(previewPageDetails, `length`)
      ) {
        let pageIndex = indexOf(previewImages, eachImage);
        previewPageDetails.splice(pageIndex, 0, eachImage);
      }
    });

    previewPageDetails = previewPageDetails.length
      ? uniqBy(previewPageDetails, "previewKey")
      : previewImages;

    this.setState({
      previewPageDetails
    });
  };

  // build polling
  pollingActionForBuild = async () => {
    const documentId = get(this.props, `currentDocumentData._id`);
      console.log(
        "polling action for build 2",
        get(this.props, `previewImages.length`)
      );

    const response = await this.props.getPreviewImages(
      documentId,
      !get(this.props, `previewImages.length`)
    );
    /**
     * If the server responds with Failed as the status message 
     * then the FE should Poll no further
     */
    if(response.message && response.message == "Failed"){
      PollingUtils.stopPolling()
      this.props.showPreviewLoader(false)
    }
    this.updatePreviewImages();
    let { previewImages = [] } = this.props;

    let status = map(previewImages, eachBlock => {
      return get(eachBlock, `buildStatus`);
    });

    await this.props.getDocumentStatus(documentId);
    const { documentStatus } = this.props;
    this.getContentBlockWithUpdatedStatus();

    // stop polling if whole document is built i.e all the pages have status as current
    if (
      status.every(value => value === "current") &&
      previewImages.length &&
      get(documentStatus, `buildStatus`) === "current"
    ) {
      PollingUtils.stopPolling();

      this.getContentBlockWithUpdatedStatus();
      console.log('polling action for build 3', get(this.props, `previewImages.length`))
      // call once again for the last page
      await this.props.getPreviewImages(
        documentId,
        !get(this.props, `previewImages.length`)
      );
      this.updatePreviewImages();
      await this.props.getDocumentStatus(documentId);

      this.setState({
        isPollingOngoing: false,
        shouldPreviewPoll: false
      });
    }
  };

  _formatStepDetails = () => {
    let { stepperDetails } = this.state;

    let stepItems = map(stepperDetails, eachDetail => {
      return {
        title: get(eachDetail, "name"),
        stepType: get(eachDetail, "type"),
        src: GenericStepperCircle
      };
    });

    this.setState({
      stepItems
    });
  };

  /**
   * check if data exists in redux or in state
   *
   * @param {String} contentBlockName content block name
   * @returns {Boolean} states whether data exists or not
   */
  checkIfContentBlockDataExists = contentBlockName => {
    let { glossaryColumnItems } = this.state;
    let { glossaryContentBlock = [] } = this.props;
    switch (contentBlockName) {
      case "Glossary":
        return (
          !!get(glossaryColumnItems, `length`) ||
          !!get(glossaryContentBlock, `length`)
        );

      default:
        return false;
    }
  };

  // function to call api of content block items
  fetchStepperData = async (contentBlockId, contentBlockType, documentId) => {
    const templateId = get(this.props, `selectedTemplate._id`);
    let contentBlockMap = {
      appendixPageSet: "appendixContentBlock",
      htmlTemplate: "glossaryContentBlock",
      layout: "topicsContentBlock"
    };

    // call api if the content block name exists as a key in contentBlockMap and redux store or state does not contain data for that particular content block
    if (
      contentBlockMap.hasOwnProperty(contentBlockType) &&
      !get(this.props, [contentBlockMap[contentBlockType], `length`]) &&
      !this.checkIfContentBlockDataExists(contentBlockType)
    ) {
      await this.props.getBlockContents(
        templateId,
        contentBlockId,
        contentBlockMap[contentBlockType]
      );
    }
  };

  // function to call api of content block items
  fetchFilesData = async (
    userId,
    contentBlockId,
    contentBlockType,
    documentId,
    isNewFileUploaded = false
  ) => {
    let contentBlockMap = {
      appendixPageSet: "appendixContentBlock"
    };

    // call api if the content block name exists as a key in contentBlockMap and redux store or state does not contain data for that particular content block or call api if new file is uploaded
    if (
      (contentBlockMap.hasOwnProperty(contentBlockType) &&
        !get(this.props, [contentBlockMap[contentBlockType], `length`]) &&
        !this.checkIfContentBlockDataExists(contentBlockType)) ||
      isNewFileUploaded
    ) {
      await this.props.getFilesContent(
        userId,
        documentId,
        contentBlockId,
        contentBlockMap[contentBlockType]
      );
    }
  };

  /**
   * get the current page details topic page
   * @param {String} pageLayoutId id of the page to be searched
   * @returns {Object} current page detail
   */
  getCurrentPage = pageLayoutId => {
    let { layoutDetails } = this.state;

    return (
      find(layoutDetails, eachPage => get(eachPage, `_id`) === pageLayoutId) ||
      {}
    );
  };

  /**
   *called on radio button change
   *
   * @param {Object} e event
   * @param {String} eachType type of radio button checked
   * @param {String} pageLayoutId id of the page
   */
  handleTopicsPageCheckboxChange = async (e, eachType, pageLayoutId) => {
    let { activePlaceholderDetails = {} } = this.state;
    // get the current active layout page and its placeholder details with whom user is interacting
    let activePageLayoutDetails =
      this.getCurrentPage(pageLayoutId).layoutTypeDetails || {};

    // attach the selectedType key
    let currentPage = this.getCurrentPage(pageLayoutId);
    currentPage.selectedType = eachType;

    //get the placeholders as per the selectedType
    activePlaceholderDetails[pageLayoutId] = activePageLayoutDetails[eachType];

    this.setState({
      activePlaceholderDetails,
      isDataEdited: true,
      topicsSelectedType: eachType
    });
  };

  modifyStepHandler = async activeStep => {
    let templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    const currentActiveStep = this.state.activeStep;
    const { isDataEdited } = this.state;
    const contentBlockType = this.getCurrentTabDetails().type;

    // only save if something has changed save the data
    if (activeStep > currentActiveStep || isDataEdited) {
      const isMoveToNextStep = await this.onDocumentSaveData(contentBlockType);
      if (!isMoveToNextStep) return false;
    }

    this.setState(
      {
        activeStep
      },
      () => {
        let {
          userProfileMeta: { _id: userId }
        } = this.props;

        let templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
        let { activeStep } = this.state;

        let contentBlockId = get(templateBlocks, [activeStep, `_id`]);

        let contentBlockType = this.getCurrentTabDetails().type;

        const documentId = get(this.props, `currentDocumentData._id`);

        let contentBlockTypeArray = ["appendixPageSet"];
        if (contentBlockTypeArray.indexOf(contentBlockType) > -1) {
          this.fetchFilesData(
            userId,
            contentBlockId,
            contentBlockType,
            documentId
          );
          return;
        }

        this.fetchStepperData(contentBlockId, contentBlockType, documentId);
      }
    );
  };

  // show toast message
  showToastMessage = (toastType = "success", message = "") => {
    ToastUtils.handleToast({
      operation: toastType,
      message: message
    });
  };

  resetTopics = () => {
    let { layoutDetails } = this.state;
    let { topicsContentBlock } = this.props;
    let layoutTypeDetails = layoutDetails[0].layoutTypeDetails;

    each(layoutTypeDetails, eachLayout => {
      each(eachLayout, eachModule => {
        if (get(eachModule, `selectedThumbnail`)) {
          topicsContentBlock.push(get(eachModule, `selectedThumbnail`));
          delete eachModule.selectedThumbnail;
        }
      });
    });

    this.props.setBlockContentData(topicsContentBlock, "topicsContentBlock");

    this.setState({
      layoutDetails
    });
  };

  /**
   * handler function for the document's tab
   * @returns payload to be sent or false if there are validation errors
   */
  saveDocumentVariableData = () => {
    let { documentFields } = this.state;

    let isDataInvalid = filter(documentFields, eachField => {
      if (eachField._id === "description") {
        return false;
      }
      // If there is no title given to the document 
      // then it will take Untitled as the default Value
      return !!get(eachField, `error`) || !!!get(eachField, `value`, 'Untitled');
    });
    this.setState({
      isDataEdited: true
    })
    if (isDataInvalid.length) {
      each(documentFields, eachField => {
        if (
          !get(eachField, `value`) &&
          get(eachField, `type`) !== "date" &&
          get(eachField, `_id`) !== "description"
        ) {
          eachField.error = UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        }
      });

      this.setState({
        documentFields
      });

      this.showToastMessage("error", "Please enter all the required fields.");
      return false;
    }

    // format data for post
    let documentVariables = filter(documentFields, eachField => {
      return (
        get(eachField, `_id`) !== "name" &&
        get(eachField, `_id`) !== "description"
      );
    }).map(eachField => {
      return {
        _id: eachField._id,
        value: {
          type: get(eachField, `type`),
          content: get(eachField, `value.content`) || get(eachField, `value`)
        }
      };
    });

    let postData = {
      templateId: get(this.props, `selectedTemplate._id`),
      name: (find(documentFields, { _id: "name" }) || {}).value || "Untitled",
      description: (find(documentFields, { _id: "description" }) || {}).value,
      variables: [...documentVariables]
    };

    return postData;
  };

  saveTopicsData = () => {
    let {
      layoutDetails,
      activePlaceholderDetails,
      topicsSelectedType
    } = this.state;

    let postData = {
      pageLayouts: []
    };

    if (toLower(topicsSelectedType) !== "none") {
      // hard wiring for OE case, change this when layout data comes from api
      let activeLayout = activePlaceholderDetails[layoutDetails[0]._id];

      let isDataEmpty = filter(activeLayout, eachLayout => {
        return !!!get(eachLayout, `selectedThumbnail`);
      });
      if (isDataEmpty.length) {
        this.showToastMessage("error", `Please add a topic to continue.`);
        return false;
      }
    }

    // formatting post data
    each(layoutDetails, eachLayout => {
      let pageLayouts = [];
      let formattedData = {};
      each(
        activePlaceholderDetails[get(eachLayout, `_id`)],
        (eachPlaceholder, index) => {
          formattedData.layout = eachPlaceholder.type;
          if (get(eachPlaceholder, `selectedThumbnail._id`)) {
            pageLayouts.push(get(eachPlaceholder, `selectedThumbnail._id`));
          }
          formattedData.content = pageLayouts;

          // if all the data is mapped with the ids then push the object in final object
          if (
            index ===
            activePlaceholderDetails[(get(eachLayout, `_id`), "length")]
          ) {
            postData.pageLayouts.push(formattedData);
          }
        }
      );
    });

    postData.pageCount = layoutDetails.length;
    return postData;
  };

  saveHtmlTemplateData = async ()=>{
    const {
      documentHtmlTemplateUIFields,
      selectedHtmlLayout,
      htmlTemplateDetails,
      htmlImageVariableId,
      isHtmlBlockDeleted,
      isSingleHtmlImagePresent
    } = this.state;
    let dataToPush = []
    const htmlTemplateValidation = map(
      documentHtmlTemplateUIFields,
      eachField => {
        if (eachField.required && eachField.value == '') {
          eachField.error = "This Field Cannot be blank";
          this.setState({
            reRender: !this.state.reRender
          });
          return true;
        } else {
          dataToPush.push({
            _id: eachField._id,
            content: eachField.value
          });
        }
      }
    );
    if (htmlTemplateValidation.includes(true)) return false;
    let templateBlocks = get(this.props, `selectedTemplate`) || [];
    let contentBlockId = get(templateBlocks.blocks, [this.state.activeStep, `_id`]);
    const htmlBlockData = await this.props.getCoverData(contentBlockId,templateBlocks._id);
    if (selectedHtmlLayout && htmlTemplateDetails && htmlImageVariableId) {
      dataToPush.push({
        _id: htmlImageVariableId,
        content: this.state.htmlTemplateDetails.url
      });
    } else if (
      isHtmlBlockDeleted &&
      isSingleHtmlImagePresent &&
      htmlBlockData[0].url &&
      htmlImageVariableId
    ) {
      dataToPush.push({
        _id: htmlImageVariableId,
        content: htmlBlockData[0].url
      });
    }
    let postData = {
      variables: [...dataToPush]
    };
    return postData;
  }

  saveCoverData = () => {
    let data = [];
    const {documentCoverFields} = this.state
    const validation = map(documentCoverFields, eachField => {
      if(eachField.required == true && eachField.value== ''){
        eachField.error = "This Field Cannot be blank"
        this.setState({
          reRender: !this.state.reRender
        })
        return true
      }else{
        data.push({
          _id: eachField._id,
          content: eachField.value
        });
      }
    });

    if(validation.includes(true)){
      return false
    }
    const {
      selectedCoverLayout,
      coverDetails,
      coverImageVariableId,
      isCoverBlockDeleted,
      isSingleImagePresent
    } = this.state;

    const { coverBlockData } = this.props;

    if (selectedCoverLayout && coverDetails && coverImageVariableId) {
      data.push({
        _id: coverImageVariableId,
        content: this.state.coverDetails.url
      });
    } else if (
      isCoverBlockDeleted &&
      isSingleImagePresent &&
      coverBlockData[0].url &&
      coverImageVariableId
    ) {
      data.push({
        _id: coverImageVariableId,
        content: coverBlockData[0].url
      });
    }

    let postData = {
      variables: [...data]
    };

    return postData;
  };

  saveAppendixData = () => {
    let { appendixContentBlock } = this.props;

    let items = map(appendixContentBlock, eachItem => {
      return get(eachItem, `_id`);
    });

    let postData = {
      items
    };
    return postData;
  };

  /**
   * calls appropriate handler function based on the current block
   * @param {String} contentBlockType current content block
   * @returns value returned by the respective handler function
   */
  checkBlockValidation = contentBlockType => {
    switch (contentBlockType) {
      case "document":
        return this.saveDocumentVariableData();

      case "htmlTemplate":
        return this.saveHtmlTemplateData();

      case "pptTemplate":
        return this.saveCoverData();

      case "layout":
        return this.saveTopicsData();

      case "appendixPageSet":
        return this.saveAppendixData();
      default:
        return true;
    }
  };

  /**
   * called for all the steps for saving data(document data)
   *
   * @param {String} blockType the current content block
   * @returns {Boolean } false if there are validation errors/ true if the data is saved successfully
   */
  onDocumentSaveData = async (contentBlockType, blockId = null) => {
    let templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    const userId = get(this.props, `userProfileMeta._id`);
    const payload = await this.checkBlockValidation(contentBlockType);
    const { isDataEdited, activeStep } = this.state;
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = blockId
      ? blockId
      : get(templateBlocks, [activeStep, `_id`]);

    // call patch api for the blocks which are content blocks
    const contentBlockTypes = ["layout", "appendixPageSet", "pptTemplate", "htmlTemplate"];
    // return if there were validation errors
    if (!payload) return false;

    if (contentBlockType === "document" && isDataEdited) {
      const cobrandLogoId = get(
        this.props.selectedTemplate,
        `variables[0]._id`
      );

      const coBrandLogoPayload = !cobrandLogoId
        ? { ...payload }
        : {
            ...payload,
            variables: [
              {
                _id: cobrandLogoId,
                value: {
                  label: "coBrandLogo",
                  type: "coBrandLogo",
                  content: this.state.cobrandLogoUrl
                }
              }
            ]
          };

      const responseData = await this.props.createDocument(
        userId,
        coBrandLogoPayload,
        documentId
      );
      // setting isDataEdited so that api is not called again if the user changes nothing
      this.setState({
        isDataEdited: false,
        previewPageDetails: [],
        isEnableTemplateSelection: false
      });

      const { success, data } = responseData;

      success && (await this.getActiveDocumentData(data._id));
      // There is no document Id set in the container state which would 
      // tell us the created document currentId, hence creating this key 
      // to determine the id of the document created. 
      this.setState({
        documentCurrentId : data._id
      })
      const {
        isCoverBlockDeleted,
        isSingleImagePresent,
        coverImageVariableId
      } = this.state;

      const { coverBlockData } = this.props;

      if (
        isCoverBlockDeleted &&
        isSingleImagePresent &&
        coverBlockData[0].url &&
        coverImageVariableId &&
        success
      ) {
        const {
          currentDocumentData: {
            blocks: {
              cover: { _id }
            }
          }
        } = this.props;
        await this.setState({
          isDataEdited: true
        });
        await this.onDocumentSaveData("pptTemplate", _id);
      }
      return true;
    } else if (
      contentBlockTypes.indexOf(contentBlockType) !== -1 &&
      typeof payload !== "boolean" &&
      isDataEdited
    ) {
      await this.props.saveContentBlockData(
        userId,
        payload,
        documentId,
        contentBlockId
      );
      await this.getActiveDocumentData(documentId);
      this.setState({
        isDataEdited: false,
        previewPageDetails: []
      });
    }

    // always return true so that if the data is not changed we can switch to the next step
    return true;
  };

  handleStateChange = ({ key, value, cb }) => {
    this.setState(
      {
        [key]: value
      },
      () => {
        cb && cb();
      }
    );
  };

  /**
   * add glossary items in the specified column
   * @param {Object} glossaryItem object to be added
   * @param {Number} columnIndex column index in which the item should be inserted
   * @returns the index at which the item was added
   */
  handleGlossaryItems = (glossaryItem, columnIndex) => {
    let { glossaryColumnItems } = this.state;
    this.computeDraggedElementPosition();
    let { pageChildrenHeight } = this.state;
    // let pageChildrenHeight = this.computeDraggedElementPosition();

    // For OE case
    let leftColumnHeight = pageChildrenHeight[0]; // first column
    let rightColumnHeight = pageChildrenHeight[1]; // second column

    let rightColumnNewHeight =
      rightColumnHeight + this.state.draggedElementHeight;

    if (rightColumnNewHeight <= leftColumnHeight) {
      // initialize an empty array for right column
      if (!Array.isArray(glossaryColumnItems[1])) {
        glossaryColumnItems[1] = [];
      }
      glossaryColumnItems[1].push(glossaryItem);
    } else {
      // initialize an empty array for left column
      if (!Array.isArray(glossaryColumnItems[0])) {
        glossaryColumnItems[0] = [];
      }
      if (get(glossaryColumnItems[1], `length`)) {
        // get the first element of the right column and push it to the left column
        glossaryColumnItems[1].push(glossaryItem);
        let rightColumnFirstElement = glossaryColumnItems[1].shift();
        glossaryColumnItems[0].push(rightColumnFirstElement);
      } else {
        glossaryColumnItems[0].push(glossaryItem);
      }
    }

    this.setState({
      glossaryColumnItems
    });

    return get(glossaryColumnItems, [columnIndex, `length`]) - 1;
  };

  setDraggedElementDimensions = domRect => {
    // we subtract 25 for the title of the content block
    let draggedElementHeight = get(domRect, `height`) - 25;

    this.setState({
      draggedElementHeight
    });
  };

  computeDraggedElementPosition = () => {
    let { glossaryPageRef } = this.state;
    let pageChildrenHeight = [];

    // loop through the pages
    for (let refIndex = 0; refIndex < glossaryPageRef.length; refIndex++) {
      // get all the children
      let currentRefChildren = get(glossaryPageRef, [refIndex, "children"]);

      if (get(currentRefChildren, `length`)) {
        let pageHeight = 0;
        for (let index = 0; index < currentRefChildren.length; index++) {
          pageHeight += currentRefChildren[index].offsetHeight;
        }
        pageChildrenHeight[refIndex] = pageHeight;
      } else {
        pageChildrenHeight[refIndex] = 0;
      }
    }

    this.setState({
      pageChildrenHeight
    });

    // return pageChildrenHeight;
  };

  // set selected file id for rates block
  setSelectedFile = selectedFileId => {
    this.setState({
      selectedFileId
    });
  };

  // delete files
  deleteFile = async (itemId, message, operationType) => {
    let {
      userProfileMeta: { _id: userId }
    } = this.props;
    let templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    let { activeStep } = this.state;
    let contentBlockId = get(templateBlocks, [activeStep, `_id`]);
    let contentBlockType = this.getCurrentTabDetails().type;
    const documentId = get(this.props, `currentDocumentData._id`);

    let contentBlockMap = {
      appendixPageSet: "appendixContentBlock"
    };
    if (itemId) {
      await this.props.deleteFilesData(
        userId,
        documentId,
        contentBlockId,
        itemId,
        message,
        operationType
      );

      await this.fetchFilesData(
        userId,
        contentBlockId,
        contentBlockMap[contentBlockType],
        documentId,
        true
      );

      // empty previewPageDetails to again start build
      this.setState({
        previewPageDetails: []
      });
    }
  };

  getCurrentTabDetails = () => {
    const selectedTemplate = get(this.props, `selectedTemplate`);
    const { activeStep } = this.state;

    return get(selectedTemplate, ["blocks", activeStep]) || {};
  };

  setUrlForDocument = url => {
    this.setState({
      cobrandLogoUrl: url
    });
    const userId = get(this.props.userProfileMeta, `_id`);
    const documentId = get(this.state, `documentCurrentId`);
    const cobrandLogoId = get(this.props.selectedTemplate, `variables[0]._id`);
    this.props.attachCobrandLogoToDocument(
      userId,
      documentId,
      cobrandLogoId,
      url
    );
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <SummaryMakerComponent {...stateMethodProps} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildContainer);
