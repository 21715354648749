import React from "react";
import Styled from "styled-components";

import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

import bgWrapper from "components/bgWrapper";
import RepoDetail from "./components/detail";
import Container from "./container";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FullPageLoader from "components/FullPageLoader";
import { filter, includes, map, size } from "lodash";
import ContentRepoDetails from "./ContentRepoDetails";
import SearchBox from "components/searchBox";
import ContentRepoShare from "../../../../../../components/contentRepositoryShareForm";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [replaced] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, replaced);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid / 8}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid / 8
});

class Dashboard extends React.Component {
  state = {
    items: []
  };

  onDragEnd = async result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
    await this.props.reorderContentRepo(
      result.draggableId,
      result.destination.index
    );
  };

  componentDidMount = () => {
    const itemList = this.props.contentRepoList;
    this.setState({
      items: itemList
    });
  };

  componentWillReceiveProps(nextProps) {
    // checking if the render is required on prop change
    if (nextProps.contentRepositoryList !== this.state.items) {
      this.setState({ items: nextProps.contentRepoList });
    }
  }

  render() {
    const { items } = this.state;
    const { isSearchEnabled, filteredResult } = this.props;
    return (
      <>
        {this.props.isShowOverlay && <ContentRepoShare {...this.props} showRadioBtn />}
        {this.props.isLoading && <FullPageLoader layerIndex={21} />}
        <LandingPageWrapper className="clearfix">
          <LandingPageLeftPanel>
            <Heading>Content Repositories</Heading>
            <Paragraph>
              Welcome to the Content Repo Dashboard. You can enable/disable,
              clone or edit the content repositories below or add a new content
              repository by clicking “Add Repo”. Click the hyperlinked "Name" to
              view, add and make changes to slide content.
            </Paragraph>

            <SearchBoxContentRepo>
              <SearchBox handleChange={this.props.handleChange} />
            </SearchBoxContentRepo>

            <HeaderTableContent>
              {!isSearchEnabled && <Column></Column>}
              <ColumnName>Name</ColumnName>
              <ColumnDimension>Dimension</ColumnDimension>
              <ColumnNoSlides>No. Slides</ColumnNoSlides>
              <ColumnMaxSlides>Max</ColumnMaxSlides>
              <ColumnActionButtons>Actions</ColumnActionButtons>
            </HeaderTableContent>
            {size(filteredResult) === 0 && isSearchEnabled ? (
              <NoResultFound>No result found</NoResultFound>
            ) : (
              <></>
            )}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={items && getListStyle(snapshot.isDraggingOver)}
                  >
                    {map(
                      isSearchEnabled ? filteredResult : items,
                      (item, index) => (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                          isDragDisabled={isSearchEnabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ContentRepoDetails
                                item={item}
                                showIcon={this.props.showIcon}
                                isSearchEnabled={isSearchEnabled}
                              ></ContentRepoDetails>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </LandingPageLeftPanel>
          <LandingPageRightPanel>
            <AddButtonWrapper className="clearfix">
              <AddButton title="Add Repo" onClick={this.props.onClickAddRepo}>
                Add Repo
              </AddButton>
            </AddButtonWrapper>
            <RepoDetailWrapper>
              <RepoDetail {...this.props} />
            </RepoDetailWrapper>
          </LandingPageRightPanel>
        </LandingPageWrapper>
      </>
    );
  }
}

const LandingPageWrapper = Styled.div`
  padding: ${props => (!props.isShowSearchBox ? "30px 42px" : "30px 0")};
  margin: 0 auto;
  background-color: ${props => props.theme.COLOR.CONTAINER};
  .ReactTable .rt-th {
    white-space: nowrap;
    padding: 22px 0 19px !important;
  }
  .sales-radio-btn {
    display: none;
  }
  .table-search {
    margin-top: 10px;
  }
`;

const SearchBoxContentRepo = Styled.div`
  float: left;
  margin-top: 10px;
  margin-bottom: 19px;
`;

const NoResultFound = Styled.span`
  text-align:center;
  font-size: 16px;
  font-weight: bold;
  display:flex;
  justify-content: center;
  margin: 2rem;
`;

const Column = Styled.span`
  width: 1.23%;
  background: white;
  padding: 2px 8px;
  &:hover {
    cursor: grab;
  };
`;

const ColumnName = Styled.span`
  width: 35%;
  padding: 2px 8px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.HEADING};
`;
const ColumnDimension = Styled.span`
  width: 15%;
  padding: 2px 8px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.HEADING};
  position: relative;
  left: -4px;
`;

const ColumnMaxSlides = Styled.span`
  width: 10%;
  padding: 2px 8px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.HEADING};
  position: relative;
  left: -3px;
`;

const ColumnNoSlides = Styled.span`
  width: 15%;
  padding: 2px 8px;
  font-weight: bold;
  color:${props => props.theme.COLOR.HEADING};
  position: relative;
  left: -4px;
`;

const ColumnActionButtons = Styled.span`
  width: 25%;
  padding: 2px 8px;
  font-weight: bold;
`;

const Heading = Styled.div`
  width: 74%;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 30px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin-bottom: 10px;
  @media (max-width: 844px) {
    font-size: 24px;
    width: 66%;
  }
`;

const HeaderTableContent = Styled.div`
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.25);
  padding: 15px 2px;
  width: 100%;
  height: 64px;
  color:${props => props.theme.COLOR.HEADING};
  font-size:12px;
  opacity: 0.64;
  &:hover {
    cursor: auto;
  }
`;

const Paragraph = Styled.p`
  width: 74%;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  margin-bottom: 16px;
  font-size: 12px;
  color: ${props => props.theme.COLOR.HEADING};
`;

const LandingPageLeftPanel = Styled.div`
  width: 68.8%;
  display: inline-block;
  @media (min-width: 1164px) {
    width: 820px;
  }
  @media (min-width: 1039px) and (max-width: 1164px) {
    width: 752px;
  }
`;

const LandingPageRightPanel = Styled.div`
  width: calc(31% - 9px);
  float: right;
  @media (min-width: 1164px) {
    width: calc(100% - 850px);
  }
  @media (min-width: 1039px) and (max-width: 1164px) {
    width: calc(100% - 770px);
  }
`;

const AddButton = Styled.button`
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_STYLE}
  float: right;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  margin-left: 20px;
  width: 160px;
  height: 46px;
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const AddButtonWrapper = Styled.div`
  margin: 40px 0 54px;
  height: 46px;
`;

const RepoDetailWrapper = Styled.div`
  margin-top: 6rem;
`;

export default Container(bgWrapper(Dashboard));
