import React, { Component } from "react";

import ToastUtils from "utils/handleToast";
import {
  deleteContentRepositoryAcls,
  postContentRepositoryAcls,
} from "./services";

const Container = (Main) =>
  /** State Controller for Content Repository */
  class contentRepoShareForm extends Component {
    constructor(props) {
      super(props);

      this.state = {
        noteCharsRemaining: 500,
        form: {
          contentRepositoryAclsOptions: [],
          selectedContentRepositoryAcls: [],
          shareIds: { value: [], error: "" },
          note: { value: "", error: "" },
          contentRepoOptions: { id: "" },
          sendCopyToMyselfFlag: false,
        },
        isShowOverlay: false,
        isSharing: false,
        notifyPeople: false
      };
    }

    componentDidMount() {
      const { form } = this.state;
      const { _id, name } = this.props.selectedContentRepo || {};

      if (_id) {
        form.contentRepoOptions.id = _id;
      }

      if (name) {
        form.contentRepoOptions.name = name;
      }

      this.setState({ form });
    }

    // on form submit handler
    formHandler = async () => {
      const { _id: contentRepoId } = this.props.selectedContentRepo || {};

      const { form } = this.state;

      // @todo
      // first implementation
      // only one available option `Everyone`
      const everyoneOption = form.contentRepositoryAclsOptions[0];
      const aclsIds = form.selectedContentRepositoryAcls.filter(selectedAcl => everyoneOption.who === selectedAcl.label);

      let response;
      if (aclsIds && aclsIds.length) {
        const body = {
          acls: aclsIds
        };
        const url = `/content-repositories/${contentRepoId}/acls`;
        this.setState({
          isSharing: true,
        });

        response = await postContentRepositoryAcls(url, body);
        this.setState({
          isSharing: false,
        });
      } else {
        const url = `/content-repositories/${contentRepoId}/acls/${everyoneOption._id}`;
        this.setState({
          isSharing: true,
        });

        response = await deleteContentRepositoryAcls(url);
        this.setState({
          isSharing: false,
        });
      }

      if (response && response.success) {
        ToastUtils.handleToast({
          operation: "success",
          message: "Content repository access has been updated successfully.",
        });
        this.props.overlayHandler();
      } else {
        ToastUtils.handleToast({
          operation: "error",
          message: response.data && response.data.message,
        });
      }
    };

    handleShareChange = (selectedItems) => {
      if (selectedItems) {
        const selectedIds = selectedItems.length ? selectedItems : [];
            this.setState((state) => {
              return {
                form: {
                  ...state.form,
                  selectedContentRepositoryAcls: selectedIds
                }
              };
            });
      }
    };

    setAclsOptions = aclsOptions => {
      this.setState((state) => {
        return {
          form: {
            ...state.form,
            contentRepositoryAclsOptions: aclsOptions
          }
        };
      });
    };

    handleNotifyPeopleChange = (event)  => {
      this.setState({ notifyPeople: event.target.checked })
    }

    manageInputStates = ({ propName, value }) => {
      let { form } = this.state;
      form[propName].value = value;
      this.setState({ form });
    };

    render() {
      const $this = this;

      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props,
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
