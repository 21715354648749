// libraries
import React, { Component } from "react";
import { get } from "lodash";
import { connect } from "react-redux";

// components
import Preview from "./index";
import ToastUtils from "utils/handleToast";
import PollingUtils from "utils/PollingUtils";

// services
import {
  getPreviewImages,
  getDocumentStatus,
  getPageImage,
  getDocumentPdf
} from "tools/summaryMaker/store/actions/previewBuildActions";

const mapStateToProps = state => {
  const {
    domain: {
      REQUEST_PREVIEW_DATA,
      RECEIVE_PAGE_IMAGE,
      RECEIVE_BUILD_DOCUMENT_DATA,
      RECEIVE_PREVIEW_IMAGES
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...REQUEST_PREVIEW_DATA,
    ...RECEIVE_PAGE_IMAGE,
    ...RECEIVE_BUILD_DOCUMENT_DATA,
    ...SUCCESS_USER_PROFILE,
    ...RECEIVE_PREVIEW_IMAGES
  };
};

const mapDispatchToProps = {
  getPreviewImages,
  getDocumentStatus,
  getPageImage,
  getDocumentPdf
};

class PreviewPage extends Component {
  state = {
    previewPageNo: 0,
    pagesDetails: [],
    setWidthOptionForPreview: false
  };

  navigationInput = React.createRef();

  componentDidMount() {
    this.handlePreviewImages();
  }

  handlePreviewImages = async () => {
    let { previewPageDetails, shouldPreviewPoll } = this.props;
    shouldPreviewPoll && await this.props.handlePreviewImages();
    if (!previewPageDetails.length == 0){
      this.setState({
        pagesDetails: previewPageDetails
      });
    }
  };

  componentWillUnmount() {
    /**Stops the Polling when the user is away from the stepper. */
    PollingUtils.stopPolling();
  }
  componentDidUpdate(prevProps) {
    if (
      get(this.props, `previewImages.length`) !==
        get(prevProps, `previewImages.length`) ||
      get(this.props, `previewPageDetails.length`) !==
        get(prevProps, `previewPageDetails.length`)
    ) {
      this.setState({
        pagesDetails: this.props.previewPageDetails
      });
    }
  }

  navigationInputHandler = e => {
    let { pagesDetails } = this.state;
    let inputValue = e.target.value;

    // navigate to a particular page once enter key is pressed
    if (e.keyCode === 13) {
      if (inputValue < 1 || isNaN(inputValue) || inputValue === "") {
        ToastUtils.handleToast({
          operation: "error",
          message: `Please enter a valid number between 1 and ${pagesDetails.length}.`
        });

        return;
      }

      if (inputValue < 0 || inputValue > pagesDetails.length) {
        this.navigationInput.current.value = 1;
        this.setState({
          previewPageNo: 0
        });
        return;
      }

      this.setState({
        previewPageNo: inputValue - 1
      });
    }
  };

  pageImageClickHandler = pageIndex => {
    this.setState(
      {
        previewPageNo: pageIndex
      },
      () => {
        this.navigationInput.current.value = this.state.previewPageNo + 1;
      }
    );
  };

  pageNavigationHandler = (previous, next) => {
    let { previewPageNo, pagesDetails } = this.state;
    // do not increase or decrease previewPageNo if end is reached
    if (
      (previous && previewPageNo < 1) ||
      (next && previewPageNo === get(pagesDetails, `length`) - 1)
    )
      return;

    this.setState(
      {
        previewPageNo: previous
          ? this.state.previewPageNo - 1
          : this.state.previewPageNo + 1
      },
      () => {
        this.navigationInput.current.value = this.state.previewPageNo + 1;
      }
    );
  };

  onDownload = async () => {
    const documentId = get(this.props, `currentDocumentData._id`);
    const { _id } = this.props.userProfileMeta;
    const { name } = this.props.currentDocumentData || {};
    const { handleStateChange } = this.props;

    handleStateChange({ key: "isPollingOngoing", value: true });

    const response = await this.props.getDocumentPdf(documentId, _id);

    if (get(response, "data")) {
      const anchor = document.createElement("a");
      const url = URL.createObjectURL(response.data);
      anchor.href = url;
      anchor.download = name;
      anchor.click();
    }

    handleStateChange({ key: "isPollingOngoing", value: false });
  };

  changeSetWidthOptionForPreview = (value = false) => {
    this.setState({
      setWidthOptionForPreview: value
    });
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Preview {...stateMethodProps} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPage);
