const RECEIVE_BUILD_DOCUMENT_DATA = "RECEIVE_BUILD_DOCUMENT_DATA";
const REQUEST_BUILD_DOCUMENT_DATA = "REQUEST_BUILD_DOCUMENT_DATA";
const REQUEST_GET_COVER_DATA = "REQUEST_GET_COVER_DATA";
const RECEIVE_COVER_DATA = "RECEIVE_COVER_DATA";

export const DocumentBuildTypes = {
  RECEIVE_BUILD_DOCUMENT_DATA,
  REQUEST_BUILD_DOCUMENT_DATA,
  REQUEST_GET_COVER_DATA,
  RECEIVE_COVER_DATA
};
