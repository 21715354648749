import React from "react";
import styled from "styled-components";


class CategoryTitleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.InputRef = React.createRef();
  }
  render() {
    const {
      _id,
      title,
    } = this.props;

    return  (
      <CategoryWrapper>
        <span
        key={_id}
        title={title}
        tabIndex={0}
      >
        {title}
      </span>
      </CategoryWrapper>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    )                                
  }
}

const CategoryWrapper = styled.div`
  position: absolute;
  width: 60%;
  left: 5px;
  top: 10px;
  span {
    color: ${props => props.theme.COLOR.MAIN};
    font-size:12px; 
    font-weight: initial;
  }
`;

export default CategoryTitleComponent;
