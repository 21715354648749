
import { LibraryDownloadTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";


export const onLibraryDataDownload = (
  payload = {},
  type = LibraryDownloadTypes.LIBRARY_DATA_DOWNLOAD
) => {
  return {
    type,
    payload
  };
};

export const isLibraryDataDownloading = (
  payload = {},
  type = LibraryDownloadTypes.LIBRARY_DATA_LOADING
) => {
  return {
    type,
    payload
  };
};

const loggingDownloadInDatabase = body => async dispatch => {
    const URL = `/downloads`;
  
    // start loading
    dispatch(isLibraryDataDownloading({ isDownloading: true }));
  
    const response = await FetchUtils.postData(
      URL,
      body,
      "Maintain download data record"
		);
		
		 // start loading
		 dispatch(isLibraryDataDownloading({ isDownloading: false }));
  
    if (response.success && response.data) {
      //dispatch data list on success
      dispatch(
        onLibraryDataDownload({
          downloadResponse: response.data
        })
      );
    } else {
      // stop loading
      dispatch(isLibraryDataDownloading({ isDownloading: false }));
      ToastUtils.handleToast({
        operation: "error",
        message: get(response, "data.error.message")
      });
    }
  
    //return reponse to check in component if required
    return response;
	};
	
	export  { loggingDownloadInDatabase };