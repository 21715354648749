import React, { Component } from "react";
import { filter, forEach, get } from "lodash";

const Container = Main =>
  class Build extends Component {
    state = {
      options: [
        {
          label: "PowerPoint (.PPTX)",
          id: "ppt"
        },
        {
          label: "PDF",
          id: "pdf"
        },
        {
          label: "Both",
          id: "zip"
        }
      ],
      buildValue: "ppt",
      showShare: false
    };

    componentDidMount() {
      // Get default selected radio button
      let type = this.getDefaultSelected();
      if (type) {
        this.setState({
          buildValue: type
        });
      }
    }

    componentDidUpdate(prevProp, prevState) {
      let { isBuilding } = this.props;

      // Scroll to bottom only if building
      if (prevProp.isBuilding !== isBuilding) {
        // Only scroll on build
        this.scrollToBottom();
      }
    }

    scrollToBottom = () => {
      window.scrollTo(0, document.body.scrollHeight);
    };

    getDefaultSelected = () => {
      let locationArray = this.getAvailableBuild();
      let type = null;
      if (locationArray.length) {
        let { presentationData } = this.props;
        // Both build type selected
        if (get(presentationData, "zipLocation.url")) {
          type = "zip";
        } else if (get(presentationData, "pptLocation.url")) {
          type = "ppt";
        } else if (get(presentationData, "pdfLocation.url")) {
          type = "pdf";
        }
      }
      return type;
    };

    //Update value on Radio Button Change
    changeHandler = id => {
      this.setState({ buildValue: id });
    };

    //Go to next step on build Click
    buildHandler = () => {
      let { onBuild } = this.props;
      let { buildValue } = this.state;
      onBuild && onBuild({ buildOption: buildValue });
    };

    //Overlay handler on share icon click
    overlayHandler = () => {
      this.setState({ showShare: !this.state.showShare });
    };

    /**
     * Check all three types of build location for any created build
     *
     */
    getAvailableBuild = () => {
      let { presentationData } = this.props;
      if (presentationData) {
        let { pdfLocation, pptLocation, zipLocation } = presentationData;
        let checkLocation = [pdfLocation, pptLocation, zipLocation];
        let availableLocation = filter(
          checkLocation,
          eachLocation => eachLocation && !!eachLocation["url"]
        );
        return availableLocation.length && availableLocation;
      }
      return false;
    };

    /**
     * Handle download button click
     *
     */
    handleDownload = () => {
      let { buildValue } = this.state;
      let { presentationData } = this.props;
      // Get urls
      let pptUrl = get(presentationData, "pptLocation.url");
      let pdfUrl = get(presentationData, "pdfLocation.url");
      let zipUrl = get(presentationData, "zipLocation.url");

      /**
       * Statistics Functionality. We will have to send that slide IDs
       * data to the backend to keep a record of slides used for analytical
       * purposes. It is intentded to run everytime a download has been done.
       */
      this.props.loggingDownloadInDatabase({
        downloadedSlides: presentationData.slides.map(s => s.slideId._id),
        type: "downloadPresentation",
        themeId: presentationData.theme
      });
      
      if (buildValue === "ppt" && pptUrl) {
        document.location.href = pptUrl;
      } else if (buildValue === "pdf" && pdfUrl) {
        document.location.href = pdfUrl;
      } else if (buildValue === "zip" && zipUrl) {
        document.location.href = zipUrl;
      }
    };
    /**
     *Check completed builds
     *
     * @param {*} buildType Type of build
     * @returns Boolean value
     */
    checkIfDownloadAvailable = buildType => {
      let { buildValue } = this.state;
      let { presentationData } = this.props;

      let type = buildType || buildValue;
      // Get urls
      let pptUrl = get(presentationData, "pptLocation.url");
      let pdfUrl = get(presentationData, "pdfLocation.url");
      let zipUrl = get(presentationData, "zipLocation.url");

      if (type === "ppt" && pptUrl) return true;
      else if (type === "pdf" && pdfUrl) return true;
      else if (type === "zip" && zipUrl) return true;
      else return false;
    };

    checkIfShareAvailable = (presentationData = {}, selectedOption) => {
      let { email = {}, status } = presentationData;
      return status === "Completed" && !!email[selectedOption];
    };

    /**
     *Get the dynamic values for build instructions
     *
     * @param {*} buildValue The type of build selected
     * @returns String values
     */
    getDocumentName = buildValue => {
      let documentName = {
        ppt: "presentation",
        pdf: "pdf",
        zip: "presentation and pdf"
      };
      return documentName[buildValue];
    };

    render() {
      const $this = this;

      /**Merge State and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.props,
        ...$this.state
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
