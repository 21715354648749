import React from "react";
import styled from "styled-components";
import BgWrapper from "components/bgWrapper";
import CustomTable from "components/customTable";
import { tableData } from "./data";
import TemplateModal from "./components/templateAddModal";
import FullPageLoader from "components/FullPageLoader";

const SummaryMakerAdmin = props => {
  let {
    tableColumnsKeys,
    tableColumns,
    columnWidth,
    renderHead,
    showIcon,
    filteredData,
    renderTableCell,
    showAddTemplateModal,
    addNewTemplateHandler,
    isTemplateListLoading,
    isCreatingTemplate
  } = props;

  const isShowFullPageLoader = isTemplateListLoading || isCreatingTemplate;

  return (
    <PageWrapper>
      {isShowFullPageLoader && <FullPageLoader layerIndex={2} />}
      {showAddTemplateModal ? (
        <TemplateModal {...props} />
      ) : (
        <DashboardTable>
          <CustomTable
            data={filteredData || []}
            columnWidth={columnWidth}
            renderHead={renderHead}
            showIcon={showIcon}
            expanderWidth="25"
            searchFields={tableData.searchFields}
            tableColumnHeader={tableColumnsKeys}
            customPagination={props.state.currentPage}
            customPaginationHandler={props.setCurrentPage}
            handleRadioButtonChange={props.handelTemplateActiveStatus}
            role="sales" // eslint-disable-line jsx-a11y/aria-role
            tableHeader={tableColumns}
            searchFieldName="name"
            handleNewButtonClick={addNewTemplateHandler}
            renderTableCell={renderTableCell}
            defaultSorted={[
              {
                id: "updatedAt",
                desc: true
              }
            ]}
          />
        </DashboardTable>
      )}
    </PageWrapper>
  );
};

const DashboardTable = styled.div``;

const PageWrapper = styled.div`
  max-width: 1024px;
  margin: 0px auto 30px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  .new-button {
    button {
      position: absolute;
      top: -77px;
      right: 0;
    }
  }

  .admin-search {
    position: absolute;
    top: 80px;
  }

  .heading {
    margin-bottom: 48px;
    font-size: 21px;
  }

  .action-buttons {
    width: 94%;
    display: flex;
    justify-content: space-between;
  }
  .overlay-content {
    z-index: 1000;
  }

  .active-select-rate {
    background: transparent;
  }
`;

export default BgWrapper(SummaryMakerAdmin);
