import React from "react";
import styled from "styled-components";

// Component for pagination
const defaultButton = props => (
  <PaginationButton {...props}>{props.children}</PaginationButton>
);
class PaginationRequests extends React.Component {
  showRecordsInfoText = () => {
    const { pageSize, totalRecordsUsers, paginationCounter } = this.props;
    let totalPages = Math.ceil(totalRecordsUsers.total / pageSize);
    if (totalPages <= 0) totalPages = 1;
    return (
      <RecordsInfo>
        Page {paginationCounter + 1} of {totalPages}
      </RecordsInfo>
    );
  };

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    let totalPages = Math.ceil(
      this.props.totalRecordsUsers.total / this.props.pageSize
    );
    if (totalPages <= 0) totalPages = 1;
    return (
      <PaginationWrapper className="Table__pagination">
        {/* Previous Page Button */}
        <div className="Table__prevPageWrapper">
          <PageButtonComponent
            onClick={() => {
              this.props.onPageChange(false);
            }}
            className={`Table__pageButton ${
              this.props.paginationCounter === 0 ? "disabled" : ""
            }`}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </div>

        {this.showRecordsInfoText()}

        {/* Next Page Button */}
        <div className="Table__nextPageWrapper">
          <PageButtonComponent
            onClick={() => {
              this.props.onPageChange(true);
            }}
            className={`Table__pageButton ${
              totalPages === this.props.paginationCounter + 1 ? "disabled" : ""
            }`}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </div>
      </PaginationWrapper>
    );
  }
}

const PaginationButton = styled.div`
  margin-left: 14px;
  vertical-align: bottom;
  cursor: pointer;
  opacity: 1;
`;

const PaginationWrapper = styled.div`
  .Table__prevPageWrapper {
    transform: rotateY(180deg);
    top: 3px;
    position: relative;
  }
  .Table__nextPageWrapper {
    top: 3px;
    position: relative;
  }
  .show-text {
    float: left;
    margin-left: 42px;
    ${props => props.theme.SNIPPETS.FONT_STYLE};
    font-size: 12px;
    color: ${props => props.theme.COLOR.HEADING};
  }
`;

const RecordsInfo = styled.div`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  /* width: 12px; */
  /* width: 96%; */
  display: inline-block;
  height: 15px;
  opacity: 0.7;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 12px;
  color: ${props => props.theme.COLOR.HEADING};
`;

export { PaginationRequests };
