import React from "react";
import styled from "styled-components";
import { Delete, Upload } from "assets/icons";
import Logo from "../Logo";
import hexToRgba from "utils/hexToRgba";

const SelectedLogo = props => {
  const { deleteCurrentLogoHandler, logoUploadHandler, cobrandLogoUrl } = props;

  return (
    <SelectedLogoBlock>
      <SelectedLogoContainer>
        {cobrandLogoUrl ? (
          <Logo item={cobrandLogoUrl} />
        ) : (
          <LogoPlaceholder>
            <Helpertext>
              Select from below/Upload a logo to add it to the presentation.
            </Helpertext>
          </LogoPlaceholder>
        )}
        <div className="logo-controls">
          <IconWrapper
            selectedLogo={cobrandLogoUrl}
            title={props.selectedLogo && "Delete"}
          >
            <Delete
              onClick={() =>
                cobrandLogoUrl &&
                deleteCurrentLogoHandler({ deletedLogo: "selectedLogo" })
              }
            />
          </IconWrapper>
          <FileInput
            type="file"
            accept="image/x-png,image/png,image/jpeg"
            id={"upload"}
            onChange={e => logoUploadHandler(e)}
            onClick={e => (e.target.value = "")}
          />
          <UploadIconWrapper>
            <Upload
              onClick={() => {
                document.querySelector("#upload").click();
              }}
            />
          </UploadIconWrapper>
        </div>
      </SelectedLogoContainer>
    </SelectedLogoBlock>
  );
};

const SelectedLogoBlock = styled.div`
  padding: 30px 0;
  border-bottom: solid 2px ${hexToRgba("#979797", 0.2)};
  display: flex;
  margin-right: 39px;
  .checkbox-container {
    align-self: flex-end;
    margin-left: 8%;
  }
`;

const Helpertext = styled.span`
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 1)};
  width: 86%;
  position: absolute;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SelectedLogoContainer = styled.div`
  padding: 4px;
  border: 1px solid #d9d9d9;
  display: inline-block;
  border-radius: 3px;
  width: 45%;
  .logo-controls {
    display: flex;
    justify-content: space-between;
    padding: 8px 5px 2px;
    svg {
      color: ${props => props.theme.COLOR_PALLETE.LIGHT_GREY};
      width: 15px;
      height: 16px;
    }
  }

  figure {
    border: 1px solid ${props => props.theme.COLOR_PALLETE.HIGHLIGHT_SELECTED};
    min-height: 50px;
    display: flex;
    justify-content: center;
  }
`;

const LogoPlaceholder = styled.div`
  height: 110px;
  width: 100%;
  position: relative;
  border: 1px solid #d9d9d9;
`;

const FileInput = styled.input`
  display: none;
`;

const IconWrapper = styled.span`
  cursor: ${props => (props.selectedLogo ? "pointer" : "not-allowed")};
`;

const UploadIconWrapper = styled.span`
  cursor: pointer;
`;

export default SelectedLogo;
