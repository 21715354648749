import React from "react";
import styled from "styled-components";

import Container from "./container";
import StepDetails from "components/buildProcess/stepDetails";
import SetupDetails from "./components/SetupDetails";

const Setup = props => {

  // this just makes the parameter list explicit and documents it a bit more
  // console.log( "Setup: ", { props } );
  const {
    setupDetailsData,
    onChangeInput, // called on change to text, date fields
    handleCheckbox, // called on change to checkboxes
    isSubContentRepo, // if not empty, generates multi-selects
    selectedSubContent, // called on change to isSubContentRepo multi-selects
    isFilterSelectedSetupPage, // values for isSubContentRepo multi-selects
    contentRepositorySelected, // .metadata is used to generate fields (date,integer,string,list)
    handleSelectedContent, // called on change to any of the .metadata[]-generated fields
    setRef,
    nextStepHandler,
    isEdited,
    onSave
  } = props;

  // all this does is eliminate the blind-spreading at this layer
  // make parameter lists explicit.
  return (
    <>
      <SetupContainer>
        <StepDetails
          title={"Setup"}
          onNext={() => nextStepHandler(1)}
          _next
          _save={isEdited}
          onSave={onSave}
          {...props}
        />
        <Content>
          <SetupDetails
            setupDetailsData={setupDetailsData}
            // onChangeInput, // called on change to text, date
            onChangeInput={onChangeInput}
            // handleCheckbox, // called on change to checkboxes
            handleCheckbox={handleCheckbox}
            // isSubContentRepo, // if not empty, generates multi-selects
            isSubContentRepo={isSubContentRepo}
            // selectedSubContent, // called on change to isSubContentRepo multi-selects
            selectedSubContent={selectedSubContent}
            // isFilterSelectedSetupPage, // values for isSubContentRepo multi-selects
            // contentRepositorySelected, // .metadata is used to generate fields (date,integer,string,list)
            contentRepositorySelected={contentRepositorySelected}
            // handleSelectedContent, // called on change to any of the .metadata[]-generated fields
            handleSelectedContent={handleSelectedContent}
            setRef={setRef}
          />
        </Content>
      </SetupContainer>
    </>
  );
};

const SetupContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  margin-bottom: 50px;
`;

const Content = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export default Container(Setup);
