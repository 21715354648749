import auth0 from "auth0-js";
import { AUTH_CONFIG } from "../../AuthLogin/auth0-config";

const ORIGIN = window.location.origin;

export const authOptions = {
  clientID: AUTH_CONFIG.clientID,
  audience: AUTH_CONFIG.audience,
  responseType: "token id_token",
  scope: "openid email"
};

export const webAuthOptions = {
  ...authOptions,
  domain: AUTH_CONFIG.domain,
  redirectUri: `${ORIGIN}/callback`
};

export const webAuth = new auth0.WebAuth(webAuthOptions);

export const setLocalStorageSession = (authResult) => {
  localStorage.setItem("isLoggedIn", "true");
  localStorage.setItem("token", authResult.accessToken);
  localStorage.setItem("idToken", authResult.idToken);
};

export const clearLocalStorageSession = () => {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("token");
  localStorage.removeItem("idToken");
};

export const refreshAccessToken = async () => {
  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      authOptions,
      (error, authResult) => {
        if (!error && authResult && authResult.accessToken) {
          setLocalStorageSession(authResult);

          resolve(authResult.accessToken);
        } else {
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("token");
          localStorage.removeItem("idToken");

          reject(error || new Error("Failed to refresh access token"));
        }
      }
    );
  });
};
