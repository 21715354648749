import React from "react";
import styled from "styled-components";
import PageImage from "../PageImage";

const PreviewPanel = props => {
  let {
    navigationInput,
    pageNavigationHandler,
    navigationInputHandler,
    previewPageNo = 0,
    pagesDetails,
    setWidthOptionForPreview
  } = props;

  let currentPagePreview = pagesDetails[previewPageNo];

  return (
    <ContentWrapper
      setWidthOptionForPreview={setWidthOptionForPreview === "true"}
    >
      <PreviewWrapper>
        <PageImage
          {...props}
          eachPage={currentPagePreview}
          pageIndex={previewPageNo}
          imageKey="previewKey"
          dataUriKey="thumbnailImageDataUri"
        />
      </PreviewWrapper>
    </ContentWrapper>
  );
};

const PreviewWrapper = styled.div`
  border-radius: 4px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  width: 75%;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  user-select: none;
  display: ${props => (props.setWidthOptionForPreview ? "block" : "flex")};
  flex-wrap: ${props => (props.setWidthOptionForPreview ? "nowrap" : "wrap")};
  align-items: ${props =>
    props.setWidthOptionForPreview ? "stretch" : "center"};
  height: ${props => (props.setWidthOptionForPreview ? "auto" : "36.99rem")};
  @media only screen and (min-width: 1920px) {
    height: ${props => (props.setWidthOptionForPreview ? "auto" : "48.99rem")};
  }
`;

export default PreviewPanel;
