import React from "react";
import styled from "styled-components";
import { get, map } from "lodash";

import Moment from "react-moment";

const metaDataDetails = metaData => {
  return [
    {
      title: "Date Created",
      value: get(metaData, `createdAt`)
    },
    {
      title: "Date Modified",
      value: get(metaData, `updatedAt`)
    },
    {
      title: "Available fields",
      subTitle: "(per primary cover):",
      value: get(metaData, `labels`)
    }
  ];
};

const ThemeSlideDetails = ({ activeThemeDetails }) => {
  const fields = metaDataDetails(activeThemeDetails);

  return (
    <>
      {map(fields, (field, index) => {
        let { title, subTitle, value } = field;
        return (
          value && (
            <MetaContentField key={index}>
              <MetaContentFieldTitle> {title} </MetaContentFieldTitle>
              {subTitle && <MetaSubtitle> {subTitle} </MetaSubtitle>}
              <MetaContentFieldValue>
                {(title === "Date Created" || title === "Date Modified") && (
                  <Moment format="MM/DD/YYYY">{value}</Moment>
                )}
                {title === "Available fields" && Array.isArray(value) && (
                  <>
                    {map(value, (item, index) => {
                      return (
                        <PrimaryCoverValue key={index}>
                          {item}
                        </PrimaryCoverValue>
                      );
                    })}
                  </>
                )}
              </MetaContentFieldValue>
            </MetaContentField>
          )
        );
      })}
    </>
  );
};

const MetaContentField = styled.div`
  margin-bottom: 15px;
`;
const MetaContentFieldTitle = styled.span`
  margin-bottom: 4px;
  font-weight: bold;
  opacity: 0.7;
`;

const MetaContentFieldValue = styled.span`
  line-height: 1.5;
`;

const MetaSubtitle = styled.span`
  margin: -4px 0 4px;
  font-weight: bold;
  opacity: 0.7;
`;

const PrimaryCoverValue = styled.span`
  display: block;
`;

export default ThemeSlideDetails;
