import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";
import { FileStatusTypes } from "tools/library/store/types";

const onFileStatusSuccess = (
  payload = {},
  type = FileStatusTypes.SUCCESS_FILE_STATUS
) => {
  return {
    type,
    payload
  };
};

const isFileStatusLoading = (
  payload = {},
  type = FileStatusTypes.LOADING_FILE_STATUS
) => {
  return {
    type,
    payload
  };
};

const getFilePollingStatus = id => async dispatch => {
  const URL = `/status/${id}`;

  const response = await FetchUtils.getData(URL, "File polling status");

  dispatch(
    isFileStatusLoading({
      isThemeLoading: true
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onFileStatusSuccess({
        filePollingStatus: response
      })
    );
    // stop loading
    dispatch(
      isFileStatusLoading({
        isThemeLoading:
          get(response, `data.status`) !== "Failed" &&
          get(response, `data.status`) !== "Completed"
      })
    );
  } else {
    // stop loading
    dispatch(
      isFileStatusLoading({
        isThemeLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

export { getFilePollingStatus };
