import React from "react";
import BgWrapper from "components/bgWrapper";
import CustomTable from "components/customTable";
import Container from "./container";
import Loader from "components/loader";
import styled from "styled-components";
import PresentationShare from "components/presentationShareForm";
import PresentationDownload from "components/presentationDownloadForm";
import FullPageLoader from "components/FullPageLoader";
import { filter } from "lodash";

const Presentation = props => {
  /**
   * We are relying on the FE for filtering presentations. The objective is to 
   * separate the library presentations and PM maker presentations.
   * The logic being used is 
   * 1. The customer name should not be an underscore("_")
   * and 
   * 2. The presentation name should not include the contentRepo name
   * 
   * Both conditions will ensure that the presentations are only from the PM tool 
   * and not from library tool
   * @TODO we implement a soft delete from the backend, remove this segment of code
   */
  const presentationDataFiltered  = filter(props.presentationData, data=>{
    let presentationName = data.presentationName;
    let contentRepositoryName = data.contentRepository.title;
    let findNameinContentRepo = presentationName.includes(contentRepositoryName.split(" ").join("_"))
    return data.customerName !== "_" && !findNameinContentRepo
  });
  
  return (
    <>
      {props.isLoading && <FullPageLoader layerIndex={2} />}
      {props.isPopShow && <PresentationDownload {...props} />}
      {props.isShowOverlay && <PresentationShare {...props} showRadioBtn />}
      {props.isRenderTable ? (
        <PageWrapper>
          <CustomTable
            data={presentationDataFiltered || []}
            tableHeader={props.state.cols}
            columnWidth={props.columnWidth}
            searchFields={props.searchFields}
            tableColumnHeader={props.tableColumnHeader}
            heading="Presentation"
            renderHead={props.renderHead}
            showIcon={props.showIcon}
            expanderWidth="25"
            searchFieldName="presentationName"
            handleNewButtonClick={() => {
              props.history.push("/presentation/setup");
            }}
            role="admin" // eslint-disable-line jsx-a11y/aria-role
            defaultSorted={[
              {
                id: "updatedAt",
                desc: true
              }
            ]}
          />
        </PageWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

const PageWrapper = styled.div`
  position: relative;
  .ReactTable .rt-tbody .rt-td:last-child {
    position: relative;
    overflow: visible;
    bottom: 4px;
  }
  .ReactTable .rt-tbody .rt-tr-group {
    padding: 15.5px 0px 2.5px;
  }
  .ReactTable {
    margin-top: 66px;
  }
  .ReactTable .rt-th {
    padding: 22px 0 19px !important;
  }
  .admin-search {
    top: 122px;
  }
  .new-button {
    button {
      top: -82px;
    }
  }
  .active {
    display: block;
  }
`;

export default Container(BgWrapper(Presentation));
