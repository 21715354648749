// libraries
import React, { Component } from "react";
import {
  map,
  each,
  includes,
  get,
  filter,
  toLower,
  forEach,
  intersection,
  keys,
  find
} from "lodash";
import { connect } from "react-redux";
import LibraryComponent from "./index";
import { Overview } from "assets/images";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import ToastUtils from "utils/handleToast";
import handleBodyScroll from "utils/handleBodyScroll";
import ValidationUtils from "utils/ValidationUtils";
import PollingUtils from "utils/PollingUtils";

//services
import { getContentRepoList } from "tools/library/store/action/getRepoAction";
import {
  getThemeList,
  getCoverList
} from "tools/library/store/action/getTheme";
import { getLibraryFiltersList } from "tools/library/store/action/libraryFilter";
import { getLibraryByTopic } from "tools/library/store/action/libraryByTopic";
import {
  getLibraryBySearch,
  resetSearchResult
} from "tools/library/store/action/libraryBySearch";

import { loggingDownloadInDatabase } from "tools/library/store/action/libraryDataDownloadAction";
import {
  downloadDeck,
  stopDownloadDeckLoading
} from "tools/library/store/action/downloadDeckAction";
import { getFilePollingStatus } from "tools/library/store/action/fileStatusAction";
import {
  getDividerListOfSelectedRepo,
  getBlankSlideListOfSelectedRepo
} from "tools/library/store/action/dividerBlankAction";

import {
  createPresentation,
  shareBuild,
  getPresentationDetail
} from "tools/library/store/action/savePresentation";
import { getPresentationBuildStatus } from "tools/library/store/action/presentationBuildStatus";
import { triggerPresentationBuild } from "tools/library/store/action/triggerPresentationBuild";
import { editUser } from "pages/users/services";
import { getProfileDetail } from "pages/app/services/getUserDetail";

const UI_STRINGS = {
  DESELECT_ALL_SLIDES:
    "Are you sure you want to remove all slides from your presentation?",
  ADD_GROUP_SLIDE_WARNING_MESSAGE:
    "Adding this slide will add all the slides belonging to this group. Do you still want to continue?",
  REMOVE_GROUP_SLIDE_WARNING_MESSAGE:
    "Removing this slide will remove all the slides belonging to this group. Do you still want to continue?",
  BUILD_ERROR: "Could not complete the build. Please try again.",
  BUILD_TIMEOUT: "Build is taking too long. PLease try again later."
};

const mapStateToProps = state => {
  const {
    lib: {
      SUCCESS_LIBRARY_TOPIC,
      SUCCESS_LIBRARY_SEARCH,
      LOADING_LIBRARY_TOPIC_SEARCH,
      SUCCESS_CONTENTREPO_LIST,
      LOADING_CONTENTREPO,
      LOADING_THEME_LIST,
      SUCCESS_THEME_LIST,
      SUCCESS_LIBRARY_FILTERS,
      LOADING_LIBRARY_FILTERS,
      LIBRARY_DATA_DOWNLOAD,
      LIBRARY_DATA_LOADING,
      LOADING_SLIDE_DETAILS,
      SUCCESS_DOWNLOAD_DECK,
      LOADING_SAVING_DATA,
      SUCCESS_PRESENTATION_DATA,
      LOADING_PRESENTATION_BUILD,
      SUCCESS_PRESENTATION_BUILD,
      ERROR_PRESENTATION_BUILD,
      LOADING_PRESENTATION_DATA,
      SUCCESS_FILE_STATUS,
      LOADING_FILE_STATUS,
      SUCCESS_COVER,
      LOADING_COVER,
      SUCCESS_DIVIDER_LIST,
      LOADING_DIVIDER_LIST,
      SUCCESS_BLANK_SLIDE_LIST,
      LOADING_BLANK_SLIDE_LIST
    },
    SUCCESS_USER_PROFILE,
    SUCCESS_USER_LIST,
    MENU_STATE
  } = state;

  return {
    ...SUCCESS_USER_PROFILE,
    ...SUCCESS_USER_LIST,
    ...SUCCESS_LIBRARY_TOPIC,
    ...SUCCESS_LIBRARY_SEARCH,
    ...LOADING_LIBRARY_TOPIC_SEARCH,
    ...SUCCESS_CONTENTREPO_LIST,
    ...LOADING_CONTENTREPO,
    ...LOADING_THEME_LIST,
    ...SUCCESS_THEME_LIST,
    ...SUCCESS_LIBRARY_FILTERS,
    ...LOADING_LIBRARY_FILTERS,
    ...LIBRARY_DATA_DOWNLOAD,
    ...LIBRARY_DATA_LOADING,
    ...LOADING_SLIDE_DETAILS,
    ...SUCCESS_DOWNLOAD_DECK,
    ...LOADING_SAVING_DATA,
    ...SUCCESS_PRESENTATION_DATA,
    ...LOADING_PRESENTATION_BUILD,
    ...SUCCESS_PRESENTATION_BUILD,
    ...ERROR_PRESENTATION_BUILD,
    ...LOADING_PRESENTATION_DATA,
    ...SUCCESS_FILE_STATUS,
    ...LOADING_FILE_STATUS,
    ...SUCCESS_COVER,
    ...LOADING_COVER,
    ...SUCCESS_DIVIDER_LIST,
    ...LOADING_DIVIDER_LIST,
    ...SUCCESS_BLANK_SLIDE_LIST,
    ...LOADING_BLANK_SLIDE_LIST,
    ...MENU_STATE
  };
};

const mapDispatchToProps = {
  getLibraryByTopic,
  getLibraryBySearch,
  getLibraryFiltersList,
  getContentRepoList,
  getThemeList,
  createPresentation,
  shareBuild,
  getPresentationBuildStatus,
  triggerPresentationBuild,
  getPresentationDetail,
  editUser,
  getProfileDetail,
  loggingDownloadInDatabase,
  downloadDeck,
  getFilePollingStatus,
  getCoverList,
  getDividerListOfSelectedRepo,
  getBlankSlideListOfSelectedRepo,
  stopDownloadDeckLoading,
  resetSearchResult
};

class BuildContainer extends Component {
  state = {
    viewByTopicSearch: { "View by Topic": [], "View by Search": [] },
    selectSlides: [],
    selectedTabValue: "View by Topic",
    maximumSlideCount: 1000,
    deckChildrenSlideId: [],
    deckChildrenData: [],
    allSlidesData: [],
    allSlidesDataIds: [],
    selectedSlidesListDetail: [],
    librarySearchString: "",
    contentSlideGroups: {},
    selectedRepo: "Sales",
    contentRepo: {},
    filters: [],
    selectedFilters: [],
    showModal: false,
    isInputFocused: false,
    isSlideDeck: false,
    slidePartOfDeck: null,
    requiredSlides: [],
    activeSlideDetail: {},
    themeList: [],
    selectedThemeLayout: "",
    userDetail: null,
    isBUildScreen: false,
    presentationId: null,
    isBuilding: false,
    buildProgress: {
      status: "InProgress"
    },
    pptLocation: {},
    isLibraryEdit: false,
    stepsEnabled: false,
    steps: [
      {
        element: ".repoclasss",
        position: "right",
        tooltipClass: "my-tour-tooltip",
        intro: `<span class='title-text'>Welcome to the Presentation Maker Library</span><span class='title-desc'>Choose the repository at left you'd like to begin with to get started. You can switch at any time.</span><br /><span class='title-desc'>From there, you can select slides by topic or search.</span><br /><span class='title-desc'>After you've selected your slides, click "Build" at the top right to download your slides.</span>`
      },
      {
        element: ".tabsData",
        tooltipClass: "my-tour-tooltip",
        intro:
          "<span class='title-text'>Welcome to the Presentation Maker Library.</span><span class='title-desc'>Select Slides by Topic or Search.</span>"
      }
    ],
    downloadDeck: false,
    isExited: false
  };

  componentDidMount() {
    //check if token is present to get profile data
    if (localStorage.getItem("token")) {
      this.props.getProfileDetail(true);
    }
    // set user detail profile if present and get presentation meta details
    this.setUserProfile();
  }

  setAllSlidesData = allSlidesData => {

    Array.isArray(allSlidesData) && (
      this.setState({
        allSlidesData : allSlidesData,
        allSlidesDataIds : allSlidesData.map((slide) => slide._id)
      })
    )
  }

  componentDidUpdate(prevProps) {
    // fetch and store user detail to fetch user id and get presentation required detail.
    if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
      this.setUserProfile();
    }
  }

  /**
   * Get User Profile based on API respone and store in local state
   *
   */
  setUserProfile = () => {
    const { userProfileMeta } = this.props;
    userProfileMeta &&
      this.setState(
        {
          userDetail: userProfileMeta
        },
        () => this.getContentRepo()
      );
  };

  getContentRepo = async () => {
    let {
      userDetail: {
        _id,
        libraryToolInfo: { lastSelectedRepoId }
      }
    } = this.state;

    await this.props.getContentRepoList(_id);
    let { contentRepoList } = this.props;
    this.setCurrentRepoDetails(contentRepoList, lastSelectedRepoId);
  };

  setCurrentRepoDetails = (repoList, lastSelectedRepoId) => {
    let repoDetail =
      lastSelectedRepoId && find(repoList, { _id: lastSelectedRepoId })
        ? find(repoList, { _id: lastSelectedRepoId })
        : repoList[0];

    if (!repoDetail) {
      return false;
    }

    this.setState(
      {
        selectedRepo: repoDetail.title,
        contentRepo: repoDetail,
        maximumSlideCount: repoList[0].maximumSlides
      },
      () => {
        this.fetchThemeList(this.state.contentRepo);
      }
    );
  };

  fetchThemeList = async contentRepo => {
    await this.props.getThemeList(contentRepo._id);
    await this.props.getLibraryFiltersList(contentRepo._id);
    forEach(this.props.themeListData, item => {
      if (item.isMaster) {
        this.setState(
          {
            selectedThemeLayout: item._id,
            filters: this.props.libraryFiltersList || []
          },
          () => {
            this.fetchCoverList();
            this.fetchContetSlides();
          }
        );
      }
    });
  };

  fetchCoverList = async () => {
    let { contentRepo, selectedThemeLayout } = this.state;

    await this.props.getCoverList(contentRepo._id, selectedThemeLayout);

    await this.props.getDividerListOfSelectedRepo(
      contentRepo._id,
      selectedThemeLayout
    );
    await this.props.getBlankSlideListOfSelectedRepo(
      contentRepo._id,
      selectedThemeLayout
    );

    if (Array.isArray(this.props.coverList) && this.props.coverList.length)
      this.setState({
        selectedCoverLayout: this.props.coverList[0]._id
      });
  };

  /** set the current tab*/
  setTab = ({ value }) => {
    this.setState({
      selectedTabValue: value
    });
  };

  /**
   *Create a array of Id of decks
   *
   * @param {*} slideIds
   */
  handleDeckSlidesIds = slideIds => {
    let { deckChildrenSlideId, deckChildrenData } = this.state;

    // extract the ids of all the slides in the deck
    let ids = map(slideIds, slideId => {
      return slideId._id;
    });
    deckChildrenData.push(slideIds);
    deckChildrenSlideId.push(ids);
    this.setState({
      deckChildrenSlideId, // array of arrays consisting of ids of slides in decks
      deckChildrenData
    });
  };

  /**
   * Checkbox handler
   * @param {Event} e
   * @param {String} groupName Group name of a selected group
   * @param {Array} children Set of slides
   */
  handleCheckBoxChange = (checked, id, groupName, children, slideData) => {
    let { groupId } = this.state;

    if (groupName) {
      // Check if required slides
      if (this.checkRequiredSlides(slideData)) return;

      groupId = [];
      let groupSlidesDetail = [];
      children &&
        each(children, child => {
          if (child.group && child.group.title === groupName) {
            groupId.push(child._id);
            groupSlidesDetail.push(child);
          }
        });

      this.handleSelectedSlides(groupId, checked, groupName, groupSlidesDetail);
    } else {
      this.handleSingleCheckbox(id, false, slideData);
    }
  };

  /**
   * Function to insert group of slides
   * @param {Array} slidesArr Set of selected slides when group or deck is selected
   * @param {Boolean} flag To insert or remove value in to an array
   * @param {String} groupName To check if group is selected
   * @returns
   */
  handleSelectedSlides = (slidesArr, flag, groupName, slidesData = []) => {
    let { selectSlides, selectedSlidesListDetail, showModal } = this.state;

    let { maximumSlideCount } = this.state;
    let counter = 0;

    let previousSelectedList = [...selectedSlidesListDetail];

    // With cover and overview slides
    let withCoverOverview = this.addRequiredSlidesSlides(
      selectedSlidesListDetail
    );

    if (flag && groupName) {
      // group
      if (
        withCoverOverview.length < maximumSlideCount &&
        maximumSlideCount - slidesArr.length >= withCoverOverview.length
      ) {
        each(slidesArr, (item, index) => {
          // Check if required slide
          if (this.checkRequiredSlides(item)) return;

          if (!includes(selectSlides, item)) {
            selectSlides.push(item);
            selectedSlidesListDetail.push(slidesData[index]);
          }
        });
      } else {
        counter++;
      }
    } else if (flag) {
      // deck
      each(slidesArr, (item, index) => {
        if (withCoverOverview.length < maximumSlideCount) {
          if (!find(withCoverOverview, { _id: item })) {
            selectSlides.push(item);
            selectedSlidesListDetail.push(slidesData[index]);
          }
        } else {
          counter++;
        }
      });
    } else {
      each(slidesArr, item => {
        // Check if required slide
        if (this.checkRequiredSlides(item)) return;

        selectSlides = selectSlides.filter(id => id !== item);

        // remove changedTitle property from slide which is getting removed
        each(selectedSlidesListDetail, eachSlide => {
          if (get(eachSlide, `changedTitle`)) {
            delete eachSlide.changedTitle;
          }
        });
        selectedSlidesListDetail = selectedSlidesListDetail.filter(
          ({ _id: slideId }) => slideId !== item
        );
      });
    }

    if (counter > 0) {
      this.handleToastError(
        "error",
        `You have reached a limit of ${maximumSlideCount} slides.`
      );
    }

    // Update slideId for preview
    showModal &&
      this.updatePreviewData(selectedSlidesListDetail, previousSelectedList);

    this.handleSelectSlides(selectSlides, selectedSlidesListDetail);
  };

  /**
   * Function to add single slide
   * @param {Number} id single slide id
   */
  handleSingleCheckbox = async (id, flag = false, slideData) => {
    let {
      maximumSlideCount,
      selectSlides,
      selectedSlidesListDetail,
      showModal
    } = this.state;
    let previousSelectedList = [...selectedSlidesListDetail];
    let withCoverOverview = this.addRequiredSlidesSlides(
      selectedSlidesListDetail
    );

    if (this.checkRequiredSlides(slideData)) return;

    if (!includes(selectSlides, id)) {
      if (withCoverOverview.length < maximumSlideCount) {
        selectSlides = [...selectSlides, id];
        selectedSlidesListDetail = [...selectedSlidesListDetail, slideData];
        if (flag)
          this.handleToastError(
            "success",
            "Slide has been added to the presentation."
          );
      } else {
        this.handleToastError(
          "error",
          `You have reached a limit of ${maximumSlideCount} slides.`
        );
      }
    } else {
      if (flag)
        this.handleToastError(
          "success",
          "Slide has been removed from the presentation."
        );
      selectSlides = filter(selectSlides, _id => _id !== id);

      // remove changedTitle property if the slide is removed
      each(selectedSlidesListDetail, eachSlide => {
        if (get(eachSlide, `changedTitle`) && eachSlide._id === id) {
          delete eachSlide.changedTitle;
        }
      });

      selectedSlidesListDetail = filter(
        selectedSlidesListDetail,
        (item = {}) => item._id !== id && item.refId !== id
      );
    }

    // Update slideId for preview
    showModal &&
      (await this.updatePreviewData(
        selectedSlidesListDetail,
        previousSelectedList
      ));

    this.handleSelectSlides(selectSlides, selectedSlidesListDetail);
  };

  /**
   *Add cover/overview to set of slides
   *
   */
  addRequiredSlidesSlides = (slides = []) => {
    let { includeCoverPage, coverDetails, overviewLimitExceeded } = this.state;
    let addedSlides = [];
    // get overview
    let overview = !overviewLimitExceeded && this.getOverviewDetails();

    // Set isCover true flag
    if (includeCoverPage && coverDetails) {
      coverDetails["isCover"] = true;
      // Add cover if cover is not already present
      addedSlides = overview
        ? [coverDetails, ...slides, overview]
        : [coverDetails, ...slides];
    } else if (overview) {
      addedSlides = [...slides, overview];
    } else {
      addedSlides = [...slides];
    }
    return addedSlides;
  };

  // TODO: Overview slide details get from backend
  getOverviewDetails = () => {
    let { buildSetupDetails } = this.state;
    if (get(buildSetupDetails, "includeOverview.value")) {
      return {
        _id: "overviewSlide",
        thumbnailLocation: { url: Overview },
        isOverview: true,
        title: "Overview",
        isDraggable: false
      };
    }
    return null;
  };

  // required slide check
  checkRequiredSlides = (item = {}) => {
    let { label, isRequired } = item;

    return label === "slide" && isRequired;
  };

  // handler for setting the slides id which are selected
  handleSelectSlides = (selectSlides = [], selectedSlidesListDetail = []) => {
    let overviewLimitExceeded = false;

    overviewLimitExceeded = !this.checkOverviewSlideLimit(
      selectedSlidesListDetail
    );

    this.setState({
      selectSlides,
      selectedSlidesListDetail,
      overviewLimitExceeded
    });
  };

  /**
   *Check if the max overview slide limit is reached
   *
   * @param {*} totalSlides all slides with cover and overview
   * @returns
   */
  checkOverviewSlideLimit = totalSlides => {
    let { buildSetupDetails } = this.state;
    let dividerCount = 0,
      slideCount = 0;

    each(totalSlides, (eachSlide = {}) => {
      if (eachSlide.slideType === "Divider") {
        dividerCount++;
      } else {
        slideCount++;
      }
    });

    let isValid = dividerCount <= 100 && slideCount <= 150;
    // Do not show error message if is valid or the overview slide has been disabled
    if (isValid || !get(buildSetupDetails, "includeOverview.value")) {
      return true;
    } else {
      return false;
    }
  };

  /** Deselect button handler*/
  handleDeselect = () => {
    // Handle required slide
    let requiredSlides = filter(this.state.requiredSlides, "isRequired") || [];

    let requiredSlidesId = map(requiredSlides, "_id") || [];

    DeleteConfirmationAlert({
      message: UI_STRINGS.DESELECT_ALL_SLIDES,
      onYesClick: () => {
        //empty all slides
        this.handleSelectSlides(requiredSlidesId, requiredSlides);
      }
    });
  };

  /**
   * Handle change event for Search Box
   * @param {e} event
   */
  handleLibrarySearchChange = e => {
    e.persist();
    this.librarySearchTimeout && clearTimeout(this.librarySearchTimeout);

    this.librarySearchTimeout = setTimeout(() => {
      this.fetchLibraryBySearch(e.target.value.trim());
    }, 1000);
  };

  fetchLibraryBySearch = async librarySearchString => {
    const { contentRepo, selectedFilters, selectedThemeLayout } = this.state;

    //check if length exists more than 3 and content repo is selected
    let checkStringLength =
      typeof librarySearchString === "string" &&
      (librarySearchString.length > 2 || librarySearchString.length === 0);

    //exit if contentRepoId Detail is not present
    if (typeof contentRepo === "object" && !Object.keys(contentRepo).length) {
      ToastUtils.handleToast({
        operation: "error",
        message: "Please select a content repository before searching slides."
      });
      return;
    }

    // get content slides list based on selected repo
    checkStringLength &&
      (await this.props.getLibraryBySearch(
        contentRepo._id,
        selectedThemeLayout,
        {
          search: librarySearchString,
          filters: selectedFilters.filter(elem => !(elem.indexOf("F-") > -1))
        }
      ));

    this.setState({
      librarySearchString
    });
  };

  /**
   * To remove slide from preview
   *
   * @param {Boolean} flag to add or remove group
   * @param {String} id to be added or removed
   */
  addRemoveSlideFromPreview = (flag, slideData = {}) => {
    let { contentSlideGroups, activeSlideDetail } = this.state;
    let { _id, refId } = activeSlideDetail || {};
    let grpName = "";

    // Do not allow add/remove operation on required slides
    if (this.checkRequiredSlides(slideData)) return;

    let slideId = _id || slideData._id;
    contentSlideGroups &&
      Object.keys(contentSlideGroups).forEach(item => {
        contentSlideGroups[item].forEach(elem => {
          if (slideId === elem._id) {
            grpName = item;
          }
        });
      });

    if (grpName) {
      let groupSlideIds =
        Array.isArray(contentSlideGroups[grpName]) &&
        contentSlideGroups[grpName].map(({ _id }) => _id);

      DeleteConfirmationAlert({
        message: flag
          ? UI_STRINGS.ADD_GROUP_SLIDE_WARNING_MESSAGE
          : UI_STRINGS.REMOVE_GROUP_SLIDE_WARNING_MESSAGE,
        onYesClick: () => {
          this.handleSelectedSlides(
            groupSlideIds,
            flag,
            grpName,
            contentSlideGroups[grpName]
          );
        }
      });
    } else {
      if (refId) {
        this.handleRemoveDividerBlank();
      } else {
        activeSlideDetail &&
          this.handleSingleCheckbox(slideId, false, slideData);
      }
    }
  };

  // fetch group slides only
  getContentSlideDetails = topicList => {
    let requiredSlides = [];
    let {
      userDetail: {
        libraryToolInfo: { isTourCompleted }
      },
      isExited
    } = this.state;
    const getSlides = (contentSlides, contentSlideGroups = {}) => {
      each(contentSlides, item => {
        let { children, group } = item;
        if (group && group.title) {
          contentSlideGroups[group.title] = contentSlideGroups[group.title]
            ? contentSlideGroups[group.title]
            : [];

          if (
            contentSlideGroups[group.title] &&
            contentSlideGroups[group.title].filter(
              ({ _id: id }) => item._id === id
            ).length === 0
          ) {
            contentSlideGroups[group.title].push(item);
          }
        }

        if (this.checkRequiredSlides(item)) {
          requiredSlides = [...requiredSlides, item];
        }

        if (Array.isArray(children)) {
          return getSlides(children, contentSlideGroups);
        }
      });

      return contentSlideGroups;
    };

    let contentSlideGroups = getSlides(topicList);

    let selectSlides = map(requiredSlides, "_id");

    this.setState({
      selectedSlidesListDetail: requiredSlides,
      contentSlideGroups,
      requiredSlides,
      selectSlides,
      stepsEnabled: !isExited && !isTourCompleted
    });
  };

  handleStateChange = ({ key, value, cb }) => {
    this.setState(
      {
        [key]: value
      },
      () => {
        cb && cb();
      }
    );
  };

  // content repo change handler
  handleRepoChanges = ({ key, value }) => {
    this.setState(
      {
        [key]: value
      },
      () => {
        this.props.resetSearchResult();
        this.props.contentRepoList.forEach(item => {
          if (item.title === this.state.selectedRepo) {
            this.setState(
              {
                viewByTopicSearch: {
                  "View by Topic": [],
                  "View by Search": []
                },
                selectedTabValue: "View by Topic",
                contentRepo: item,
                maximumSlideCount: item.maximumSlides,
                librarySearchString: ""
              },
              () => {
                this.fetchThemeList(this.state.contentRepo);
              }
            );
          }
        });
      }
    );
  };

  onChangeHandleFiltersCheck = (viewByTopic, resetFilters) => {
    const { selectedFilters, contentRepo, selectedThemeLayout } = this.state;

    //exit if contentRepoId Detail is not present
    if (typeof contentRepo === "object" && !Object.keys(contentRepo).length)
      return;

    //set content repo id and get lists required for presentation
    const selectedContentRepoId = contentRepo._id;

    // reset selected filters on clear all button
    if (resetFilters && selectedFilters.length) {
      this.setState({
        selectedFilters: []
      });
    } else if (resetFilters && !selectedFilters.length) {
      return;
    }

    //if view by search tab is active
    !viewByTopic && this.fetchLibraryBySearch();

    //if view by topic tab is active
    this.props.getLibraryByTopic(selectedContentRepoId, selectedThemeLayout, {
      filters: resetFilters
        ? []
        : selectedFilters.filter(elem => !(elem.indexOf("F-") > -1))
    });
  };

  /**
   *Handle preview Modal pass id in sort screen to show the current slide with selected slides
   *
   */
  onPreview = async currentIndex => {
    let { selectedSlidesListDetail } = this.state;

    let currentOpenSlide = {};

    // Required slides
    let withRequiredSlides = this.addRequiredSlidesSlides(
      selectedSlidesListDetail
    );
    let withSlideDetails = [...withRequiredSlides];

    // Required slide with ids
    let withRequiredSlidesId = map(withRequiredSlides, (eachSlide = {}) => {
      return eachSlide._id;
    });

    // Length of the presentation with cover and overview
    let pptLength = withRequiredSlides.length;

    // Check if index is not set then show first slide such case happens when universal preview is shown
    if (currentIndex !== 0 && !currentIndex && withRequiredSlides[0]) {
      currentOpenSlide = withRequiredSlides[0];
    } else if (currentIndex >= pptLength && withRequiredSlides[pptLength - 1]) {
      //Check if the slide length is more than last slide
      currentOpenSlide = withRequiredSlides[pptLength - 1];
    } else {
      currentOpenSlide = pptLength ? withRequiredSlides[currentIndex] : {};
    }
    // if preview presentation is clicked just open the modal
    if (!this.state.selectSlides.length) {
      ToastUtils.handleToast({
        operation: "error",
        message: "Please select slides to preview."
      });
      return;
    }

    this.setState({
      slidePartOfDeck: withRequiredSlidesId,
      isSlideDeck: true,
      showModal: true,
      isSingleSlidePreview: false,
      activeSlideDetail: currentOpenSlide,
      selectedSlidesListDetail: this.removeRequiredSlide(withSlideDetails),
      showEditIcons: true
    });
  };

  /**
   *Remove required slides after drag and drop
   *
   */
  removeRequiredSlide = slides => {
    let {
      includeCoverPage,
      buildSetupDetails,
      coverDetails,
      overviewLimitExceeded
    } = this.state;

    // Set isCover true flag
    if (includeCoverPage && coverDetails) {
      slides.shift();
    }

    // Remove last element
    get(buildSetupDetails, "includeOverview.value") &&
      !overviewLimitExceeded &&
      slides.pop();

    return slides;
  };

  /**
   *Check if the currently active slide in preview is added to presentation
   *
   */
  checkIfAddedToPresentation = () => {
    let { activeSlideDetail, selectSlides } = this.state;

    let id = get(activeSlideDetail, "_id");
    // Check if id is present
    let { details } = this.checkAndFindSlideDetail(id);

    let isAdded = details && Object.keys(details).length;

    return (
      !!isAdded || selectSlides.indexOf(get(activeSlideDetail, `_id`)) > -1
    );
  };

  /**
   * Check if the passed Id is added to presentation list and return its details
   * NOTE: Make sure to pass the refId for blank and dividers
   *
   * @param {*} id Id of the slide which needs to be checked
   * @param {*} slides Array of slides which needs to be checked
   * @returns If the passed Id is present in the presentation return its details else return null
   */
  checkAndFindSlideDetail = (id, slides) => {
    let { selectedSlidesListDetail } = this.state;
    let index = -1;
    // If slides is passed wil check in the slides deck or else will default to thhe presentation list
    let checkSlides = slides || selectedSlidesListDetail;
    let slideDetail = filter(checkSlides, (eachSlide = {}, eachSlideIndex) => {
      let originalId = eachSlide._id;
      if (originalId === id) {
        index = eachSlideIndex;
        return true;
      }
    });
    if (slideDetail[0])
      return {
        details: slideDetail[0],
        index: index
      };
    return {};
  };

  closeModal = () => {
    handleBodyScroll({ action: "close" });
    this.setState({
      showModal: false,
      activeSlideDetail: {},
      slideName: "",
      isInputFocused: false,
      isSlideDeck: false,
      slidePartOfDeck: [],
      slideNameError: "",
      showEditIcons: false,
      showDynamicImageOverlay: false
    });
  };

  hideModal = e => {
    if (
      e.target.classList.contains("modal-preview-container") ||
      e.target.classList.contains("modal-preview-subcontainer")
    ) {
      handleBodyScroll({ action: this.state.showModal ? "close" : "open" });
      this.setState({
        showModal: !this.state.showModal,
        slideName: "",
        isInputFocused: false,
        isSlideDeck: false
      });
    }
  };

  /**
   *Handle preview modal. This function is used only to open preview on click of content slides in library.
   *
   * @param {*} id Slide Id
   * @param {*} childList Array of group set to which the selected slide belongs
   * @returns
   */
  handleModal = async (id, childList = [], slideData) => {
    // Check if selected Id is part of a deck
    this.setSlideDeckPosition({ id });
    slideData.hideEditIcon = true;
    slideData.displayDynamicImage = true;
    handleBodyScroll({ action: "open" });
    this.setState({
      showModal: true,
      slideName: "", // unset slideName for another slide editing
      activeSlideDetail: slideData,
      isSingleSlidePreview: true,
      isInputFocused: false,
      slidePartOfDeck: this.state.allSlidesDataIds,
      isSlideDeck: true
    });
  };
  //end

  /**
   * Get themeList associated to slected slide based on its type
   *
   * @param {String} themeId selected theme id
   * @param {Object} slide selected slide
   * @returns thumbnail/file url
   */
  getThumbnailBasedOnSlideType = (themeId, slide, type) => {
    let slideType = toLower(get(slide, "slideLayoutType"));
    switch (slideType) {
      case "content":
        return this.getThemeBasedUrl(
          themeId,
          get(slide, "slideListByThemes"),
          type
        );

      case "divider":
        return this.getThemeBasedUrl(
          themeId,
          get(slide, "slideListByDividers"),
          type
        );

      case "blank":
        return this.getThemeBasedUrl(
          themeId,
          get(slide, "slideListByBlanks"),
          type
        );

      case "cover":
        return this.getThemeBasedUrl(
          themeId,
          get(slide, "slideListByCovers"),
          type
        );

      default:
        return null;
    }
  };

  /**
   *Get thumbnail/file location based on the selected theme
   *
   * @param {*} type thumbnail/ file
   * @param {*} themeId Currently selected theme ID
   * @param {*} themedSlides Slides based on available themes
   */
  getThemeBasedUrl = (themeId, themedSlides, type = "thumbnail") => {
    let selectedThemeSlide = filter(themedSlides, slide => {
      // Check if the themeId matches selected theme and return the slide
      return get(slide, "themeId") === themeId;
    });
    if (selectedThemeSlide.length && type === "thumbnail") {
      return get(selectedThemeSlide[0], "thumbnailLocation.url");
    } else if (selectedThemeSlide.length && type === "file") {
      return get(selectedThemeSlide[0], "fileLocation.url");
    }
    return null;
  };

  /**
   *Handle preview navigation
   *
   * @param {*} slidePosition Position of the index in which slide is present
   *
   */
  sliderBottomNavigation = async slidePosition => {
    const { isSingleSlidePreview, activeSlideDetail } = this.state;

    if (slidePosition < 0) return null;

    // TODO: Handle get slide details
    // let response = await this.getSingleSlideDetails(id, type);
    // if (!response.success) return;

    // Check if the slider is single slide preview
    if (!isSingleSlidePreview) {
      this.onPreview(slidePosition);
    } else {
      // Checks If single slide belongs to a deck
      this.setSlideDeckPosition({
        id: activeSlideDetail._id,
        activeIndex: slidePosition
      });
    }

    this.setState({
      slideName: "",
      isInputFocused: false,
      showEditIcons: true,
      showDynamicImageOverlay: false
    });
  };

  /**
   * validate slidetitle
   *
   * @param {*} value input value to be validated
   * @returns appropriate error message
   */
  slideNameValidationHandler = value => {
    if (ValidationUtils.checkIfspecialChar(value)) {
      return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfWhiteSpace(value)) {
      return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
    } else {
      return null;
    }
  };

  setSlideTitle = (e, revertToOldName = false) => {
    let { activeSlideDetail, selectedSlidesListDetail } = this.state;

    let currentSlideWithTitle = filter(selectedSlidesListDetail, eachSlide => {
      return eachSlide._id === activeSlideDetail._id;
    });

    let slideNameError = this.slideNameValidationHandler(
      get(e, "target.value")
    );

    this.setState({
      slideName: revertToOldName
        ? get(currentSlideWithTitle[0], `changedTitle`) ||
          get(currentSlideWithTitle[0], `title`)
        : e.target.value,
      slideNameError
    });
  };

  focusInputHandler = () => {
    this.setState({
      isInputFocused: true
    });
  };

  saveSlideTitle = () => {
    let { slideName, isInputFocused, slideNameError } = this.state;

    if ((!slideName && isInputFocused) || slideNameError) {
      ToastUtils.handleToast({
        operation: "error",
        message: "Please enter a valid slide title."
      });
      return false;
    }

    let { selectedSlidesListDetail, activeSlideDetail } = this.state;

    let newSelectedSlides = map(selectedSlidesListDetail, slideDetail => {
      if (get(slideDetail, `_id`) === get(activeSlideDetail, `_id`)) {
        slideDetail.changedTitle = slideName.trim();
        return slideDetail;
      } else {
        return slideDetail;
      }
    });

    this.setState({
      selectedSlidesListDetail: newSelectedSlides
    });

    return true;
  };

  /**
   *Check if the selected slide is part of a deck
   *
   * @param {*} id Id of the selected slide
   */
  _checkSlideIsDeck = (id = "") => {
    // Return if id is not present
    if (!id) return;

    let { deckChildrenSlideId } = this.state;
    let flag = false;
    // checking if the current selected id is a part of the deck
    each(deckChildrenSlideId, slideId => {
      each(slideId, deckId => {
        // if current slide is a part of deck set the associated deck
        if (deckId === id) flag = true;
      });
    });
    return flag;
  };

  /**
   *Update selected slides data
   *
   * @param {*} selectSlides Pass an array of Id on the basis of which the Id needs to be filtered
   */
  updatePreviewData = (selectSlides = [], prevSelectSlides) => {
    let { isSingleSlidePreview } = this.state;

    // Check if deck slide overlay do not handle delete in such case
    if (isSingleSlidePreview) return null;

    // Required slides
    let withCoverOverview = this.addRequiredSlidesSlides(selectSlides);

    // Required slide with ids
    let selectSlidesId = map(withCoverOverview, "_id");

    let isRemovedIndex = withCoverOverview.length;
    for (var i = 0; i < selectSlides.length; i++) {
      if (selectSlides[i]._id !== prevSelectSlides[i]._id) {
        // Adding cover slide count
        isRemovedIndex = i + 1;
        break;
      }
    }

    // Check if empty selected slides then close the modal
    if (!selectSlides.length) {
      this.setState({
        showModal: false
      });
      // Check if the removed index is null then it states the removed element was the last element in the array
    } else if (!isRemovedIndex && isRemovedIndex !== 0) {
      this.sliderBottomNavigation(withCoverOverview.length - 1, selectSlides);
    } else {
      this.sliderBottomNavigation(isRemovedIndex + 1, selectSlides);
    }
    this.setState({ slidePartOfDeck: selectSlidesId });
  };

  /**
   *Set slide deck set and details
   *
   * @param {*} id Id whose deck need to be found
   * @param {*} activeIndex If active index is present then set current deck details in activeSlideDetail
   *
   */
  setSlideDeckPosition = ({ id, activeIndex }) => {
    let { allSlidesDataIds, allSlidesData } = this.state;

    if (allSlidesData.length > 0 && activeIndex >= 0) {
      each(allSlidesData , async (slideData) => {
        if(id === slideData._id){
          let activeSlideDetail = allSlidesData[activeIndex];
          this.setState({
            isSlideDeck: true,
            slidePartOfDeck: allSlidesDataIds,
            isSingleSlidePreview: true,
            activeSlideDetail
          });
        }
      });
    }
  };

  /**
   * @param {Object} activeSlideDetail currently open slide
   */
  downloadDeckHandler = async (activeSlideDetail = {}, download, themeid) => {
    const {
      contentRepo: { _id: contentRepoId },
      selectedCoverLayout,
      selectedThemeLayout
    } = this.state;
    let categoryId = get(activeSlideDetail, `contentSlideCategory`);

    let { blankSlides, dividers } = this.props;

    let body = {
      themeId: selectedThemeLayout,
      coverId: selectedCoverLayout,
      ...((dividers || []).length &&
        Object.keys(dividers[0]).length && {
          dividerId: get(dividers[0], `_id`)
        }),
      ...((blankSlides || []).length &&
        Object.keys(blankSlides[0]).length && {
          blankId: get(blankSlides[0], `_id`)
        })
    };

    let response = await this.props.downloadDeck(
      contentRepoId,
      categoryId,
      body
    );

    if (response.success) {
      let { downloadDeckDetails } = this.props || {};

      if (downloadDeckDetails && "url" in downloadDeckDetails) {
        this.downloadDeckUrlHandler(
          downloadDeckDetails["url"],
          activeSlideDetail.contentSlideCategory,
          download,
          themeid
        );
        return;
      }

      let ingestId = get(downloadDeckDetails, `ingestId`);

      PollingUtils.startPolling({
        pollingAction: () => {
          this.pollingActionForDownload(
            ingestId,
            contentRepoId,
            categoryId,
            body
          );
        },
        timeoutDuration: 600000,
        timeoutCallback: () => {
          this.props.stopDownloadDeckLoading();
          ToastUtils.handleToast({
            operation: "error",
            message: "Deck download is taking too long. Please try again later."
          });
        }
      });
    }
  };

  /**
   * @param {String} ingestId ingest id to be used for polling
   * @param {String} contentRepoId currently selected content repo id
   * @param {String} categoryId category id which is the parent of the currently open slide
   * @param {Object} body body to be sent
   */
  pollingActionForDownload = async (
    ingestId,
    contentRepoId,
    categoryId,
    body
  ) => {
    let { activeSlideDetail, selectedThemeLayout } = this.state;
    await this.props.getFilePollingStatus(ingestId);

    let { filePollingStatus } = this.props || {};
    if (get(filePollingStatus, `data.status`) === "Completed") {
      PollingUtils.stopPolling();

      // call downloadDeck again to get url
      await this.props.downloadDeck(contentRepoId, categoryId, body);
      let { downloadDeckDetails } = this.props || {};
      if ("url" in downloadDeckDetails) {
        this.downloadDeckUrlHandler(
          downloadDeckDetails["url"],
          activeSlideDetail.contentSlideCategory,
          "contentSlideCategory",
          selectedThemeLayout
        );
      }
    } else if (
      get(filePollingStatus, `status` !== 200) ||
      get(filePollingStatus, `data.status`) === "Failed"
    ) {
      PollingUtils.stopPolling();
      ToastUtils.handleToast({
        operation: "error",
        message: get(filePollingStatus, `data.message`)
      });
    }
  };

  downloadDeckUrlHandler = async (urlToBeDownloaded, id, download, themeId) => {
    await this.setState({
      downloadDeck: true
    });

    this.props.loggingDownloadInDatabase({
      id,
      type: download,
      themeId
    });
    document.location.href = urlToBeDownloaded;
    this.setState({
      downloadDeck: false
    });
  };

  fetchContetSlides = async () => {
    const { selectedThemeLayout, contentRepo } = this.state;
    // Get selected filters before content slides for library page
    // Get selected filters before content slides for library page
    let { _id } = contentRepo;
    const selectedFilters = await this.fetchLibraryFiltersList(_id);
    await this.props.getLibraryByTopic(_id, selectedThemeLayout, {
      filters: (selectedFilters || []).filter(
        elem => !(elem.indexOf("F-") > -1)
      )
    });

    let contentSlides =
      (Array.isArray(this.props.libraryByTopicList) &&
        this.props.libraryByTopicList) ||
      [];

    this.getContentSlideDetails(contentSlides);
  };

  //fetch library filters list and keep default check all stored in filters
  fetchLibraryFiltersList = async _id => {
    _id && (await this.props.getLibraryFiltersList(_id));
    // extract filter list
    // const { libraryFiltersList } = this.props;
    let selectedFilters = [];
    // TODO: Uncomment for default filters
    // each(libraryFiltersList, ({ children }, index) => {
    //   let filtersList = map(children, ({ _id }) => {
    //     return _id;
    //   });
    //   filtersList.push(`F-${index}`);
    //   selectedFilters.push(...filtersList);
    // });

    // this.setState({
    //   selectedFilters
    // });

    return selectedFilters;
  };

  onBuildHandler = () => {
    let payload = this.generatePayloadToSave();
    let isError = false;
    let skipProps = [
      "customerName",
      "includePageNumber",
      "includeOverview",
      "includeCover",
      "clientName"
    ];

    forEach(Object.keys(payload), key => {
      if (
        skipProps.indexOf(key) < 0 &&
        (!payload[key] || (Array.isArray(payload[key]) && !payload[key].length))
      )
        isError = true;
    });

    if (!isError) {
      this.savePresentationDetails(payload);
    } else {
      ToastUtils.handleToast({
        operation: "error",
        message: "You must select a slide befor you continue."
      });
    }
  };

  generatePayloadToSave = () => {
    const temp = { ...this.state };
    let { contentRepo } = temp;

    let presentationName = `${contentRepo.title
      .split(" ")
      .join("_")}_${this.formatDate()}`;
    let payload = {};
    if (!temp.isLibraryEdit) {
      payload = {
        customerName: "_",
        includePageNumber: true,
        includeOverview: false,
        includeCover: false,
        presentationName
      };
    }

    forEach(Object.keys(temp), key => {
      switch (key) {
        case "contentRepo":
          payload["contentRepository"] = temp[key]._id;
          break;
        case "selectedSlidesListDetail":
          payload["slides"] = this.formatSlidesForApi(temp[key]) || [];
          break;
        case "selectedThemeLayout":
          payload["theme"] = temp[key];
          break;
      }
    });

    return payload;
  };

  formatDate = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year, hours, minutes, seconds].join("-");
  };

  savePresentationDetails = async payload => {
    let {
      userDetail,
      isLibraryEdit,
      presentationId,
      contentRepo: { _id: contentRepoId }
    } = this.state;
    let { _id: userId } = userDetail;
    if (userDetail) {
      let response = isLibraryEdit
        ? await this.props.createPresentation(
            userDetail._id,
            payload,
            presentationId
          )
        : await this.props.createPresentation(userDetail._id, payload);

      if (response.success && response.data) {
        await this.props.editUser(
          {
            libraryToolInfo: { lastSelectedRepoId: contentRepoId }
          },
          userId
        );

        this.setState(
          {
            isBUildScreen: true,
            presentationId: response.data["_id"]
          },
          () => {
            this.onBuild({ buildOption: "ppt" });
          }
        );
      } else {
        ToastUtils.handleToast({
          operation: "error",
          message: get(response, "data.message"),
          autoclose: false
        });
      }
    }
  };

  /**
   *Format the slides data for api calls after sort
   *
   * @param {*} slides Slide list
   * @returns Formatted slide data
   */
  formatSlidesForApi = slides => {
    return map(slides, (eachSlide = {}, index) => {
      let {
        type,
        _id,
        slideData,
        refId,
        slideId,
        title,
        changedTitle,
        slideType
      } = eachSlide;

      // Check if type is not defined type is available only in blank and divider slides
      // slide data for content slide
      if (type === "contentSlide" || !type) {
        return {
          slideId: _id,
          type: "contentSlide",
          slideTitle: changedTitle || title,
          order: index,
          slideType: "Content",
          ...(slideData && {
            slideData: this.formatSlideDataForApi(slideData)
          })
        };
      } else {
        let uniqueId = slideId ? slideId._id : refId || _id;
        //Slide data for blank or divider slide
        return {
          slideId: uniqueId,
          type: "themeSlide",
          order: index,
          slideTitle: changedTitle || title,
          slideType,
          ...(slideData && {
            slideData: this.formatSlideDataForApi(slideData)
          })
        };
      }
    });
  };

  /**
   *Start Build process
   *
   * @param {*} { buildOption } The type of build selected ppt/pdf/zip
   */
  onBuild = async ({ buildOption: contentType }) => {
    let { match, presentationData, triggerPresentationBuild } = this.props;
    const { _id: userId } = this.state.userDetail;

    // Presentation Id of the current presentation
    let presentationId =
      get(match, "params.id") || get(presentationData, "_id");

    if (presentationId) {
      this.setState({
        buildProgress: {
          status: "InProgress",
          percentage: 0
        },
        isBuilding: true
      });
      let response = await triggerPresentationBuild(
        userId,
        presentationId,
        contentType
      );
      let { buildId } = response.data;
      // on successfully triggering build
      if (buildId) {
        this.startBuildPolling(buildId, {
          presentationId,
          type: contentType
        });
      } else {
        this.setState({
          isBuilding: false
        });
        // Show error message popup
        ToastUtils.handleToast({
          operation: "error",
          message: get(response, "data.message", false)
        });
      }
    } else {
      this.setState({
        isBuilding: false
      });
      // Show error message popup
      ToastUtils.handleToast({
        operation: "error",
        message: UI_STRINGS.UNSAVED_BUILD_ERROR
      });
    }
  };

  /**
   *Function for starting polling for build
   *
   * @param {*} buildId
   */
  startBuildPolling = (buildId, buildDetails = {}) => {
    let { selectedSlidesListDetail } = this.state;
    // Get timeout duration each slide takes approx 4s
    let timeoutDuration =
      selectedSlidesListDetail && selectedSlidesListDetail.length > 10
        ? selectedSlidesListDetail.length * 10000
        : 100000;

    PollingUtils.startPolling({
      pollingAction: () => {
        this.pollingActionForBuild(buildId, buildDetails);
      },
      timeoutCallback: () => {
        this.setState({
          isBuilding: false,
          buildProgress: {
            status: "Failed",
            percentage: 100
          }
        });
        ToastUtils.handleToast({
          operation: "error",
          message: UI_STRINGS.BUILD_ERROR,
          autoclose: false
        });
      },
      timeoutDuration
    });
  };

  /**
   *The polling action for presentation build
   *
   * @param {*} id
   */
  pollingActionForBuild = async (id, buildDetails = {}) => {
    let { getPresentationBuildStatus } = this.props;

    const { _id: userId } = this.state.userDetail;
    let { match } = this.props;
    // Presentation Id of the current presentation
    let presentationId = get(match, "params.id") || this.state.presentationId;

    let response = await getPresentationBuildStatus(id);

    let pollingResponseStatus = get(response, "data.status");
    let responseData = get(response, "data") || {};

    // Get progress data for show progress bar
    let progressData = this.getCompletedPercentage(responseData);
    this.setState({
      buildProgress: progressData
    });

    if (
      responseData &&
      pollingResponseStatus === "Completed" &&
      progressData.status !== "Failed"
    ) {
      // Get presentation data
      await this.props.getPresentationDetail(userId, presentationId);

      // Send email to the user if shareable link available in presentationData
      this._checkIfShareAvailable(
        this.props.presentationData,
        buildDetails.type
      ) && this._sharePresentationOnSuccess(buildDetails);

      this.setState({
        isBuilding: false,
        pptLocation: responseData,
        buildProgress: progressData
      });

      PollingUtils.stopPolling();
    } else if (
      pollingResponseStatus === "Failed" ||
      progressData.status === "Failed"
    ) {
      ToastUtils.handleToast({
        operation: "error",
        message: UI_STRINGS.BUILD_ERROR,
        autoclose: false
      });
      PollingUtils.stopPolling();
      this.setState({
        isBuilding: false,
        buildProgress: progressData
      });
    }
  };

  // Checks if the specified selected option has a successful build
  _checkIfShareAvailable = (presentationData = {}, selectedOption) => {
    let { email = {}, status } = presentationData;
    return status === "Completed" && !!email[selectedOption];
  };

  // Share the downloadable link mail to the user on successful build
  _sharePresentationOnSuccess = async (buildDetails = {}) => {
    const { email } = this.state.userDetail;
    let { presentationId, type } = buildDetails;

    //body to post to an api endpoint
    const body = {
      type,
      emails: [email],
      presentationId,
      sendMailToSelf: false,
      isBuild: true
    };

    let response = await shareBuild(body);

    if (response.success && response.data) {
      ToastUtils.handleToast({
        operation: "success",
        message: "Please check your email for a download link."
      });
    } else {
      ToastUtils.handleToast({
        operation: "error",
        message: `Could not send the download link to your email. Please try again later.`
      });
    }
  };

  /**
   * Get the build progres by looping object keys of the completed steps in response.
   *
   * @param {*} response Build response object
   * @returns
   */
  getCompletedPercentage = response => {
    let { buildProgress } = this.state;
    let progress = { ...buildProgress };

    let completedSteps = intersection(
      ["coverSlideBuild", "contentSlidebuild", "concatPpt"],
      keys(response)
    );
    // Check if internal steps consist of failed steps
    // TODO: Not checking currently for content slide
    let failedSteps = filter(completedSteps, key => {
      return response[key]["status"] && response[key]["status"] === "Failed";
    });

    if (response.status === "Failed" || failedSteps.length) {
      progress = {
        percentage: 100,
        status: "Failed"
      };
    } else if (response.status === "Initialized") {
      // Iterate percentage
      let percentage = buildProgress.percentage
        ? buildProgress.percentage + 10
        : 10;
      if (percentage < 100) {
        progress = {
          percentage,
          status: "InProgress"
        };
      }
    } else if (response.status === "Completed" && completedSteps.length === 3) {
      progress = {
        percentage: 100,
        status: "Completed"
      };
    }
    return progress;
  };

  onExit = () => {
    this.setState(
      {
        stepsEnabled: false,
        isExited: true
      },
      async () => {
        let {
          userDetail: { _id }
        } = this.state;

        await this.props.editUser(
          {
            libraryToolInfo: { isTourCompleted: true }
          },
          _id
        );
      }
    );
  };

  render() {
    //set library for topic lists and search lists of content slides from props
    const viewByTopicSearch = {
      "View by Topic":
        (Array.isArray(this.props.libraryByTopicList) &&
          this.props.libraryByTopicList) ||
        [],
      "View by Search":
        (Array.isArray(this.props.libraryBySearchList) &&
          this.state.librarySearchString &&
          this.props.libraryBySearchList) ||
        []
    };
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props,
      viewByTopicSearch
    };

    return <LibraryComponent {...stateMethodProps} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildContainer);
