import React from "react";
import styled, { css } from "styled-components";
import ChooseFile from "components/chooseFile";
import Button from "components/button";
import ButtonScrollSlider from "components/buttonScrollSlider";
import { map, get, size } from "lodash";
import { Delete } from "assets/icons";
import SmallButton from "components/smallButton";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-forest-dark";
import Checkbox from "components/checkbox";

function CoverBlock(props) {
  const {
    uploadFileType,
    saveHandler,
    coverImages,
    uploadFileToRepo,
    selectedOption,
    handleOptionChange,
    fetchCoverImageData,
    metaDataName,
    handleInputChange,
    metaDataList,
    metaDataError,
    addMetaData,
    removeMetaData,
    isActiveOnClickTemplateBlocks,
    templateBlocks,
    handleInputDefaultValueChange,
    metaDataDefaultName,
    handleCheckboxHandler,
    checkboxValue
  } = props;

  let coverBlock = {};

  size(templateBlocks) &&
    map(templateBlocks, eachBlock => {
      eachBlock._id === isActiveOnClickTemplateBlocks._id &&
        eachBlock.name === "cover" &&
        (coverBlock = eachBlock);
    });

  return (
    <PageWrapper>
      <BlockHeader>
        <Button
          text="Save"
          width="100px"
          float="none"
          buttonClass="save"
          onClick={saveHandler}
        />
      </BlockHeader>
      <ButtonScrollSliderWrapper>
        {get(coverImages, `length`) ? (
          <ImageSlider>
            <ButtonScrollSlider
              contentElements={coverImages}
              isShowDeleteIcon={true}
            />
          </ImageSlider>
        ) : (
          <ImageSlider>
            <NoContentElement>Your cover images go here.</NoContentElement>
          </ImageSlider>
        )}
      </ButtonScrollSliderWrapper>
      <FileUploadWrapper>
        <UploadPptxFile>
          <Heading>
            PPTX File:
            <Filename>
              {coverBlock && coverBlock.pptUploadedFileName
                ? get(coverBlock, `pptUploadedFileName`)
                : coverBlock && coverBlock.pptFilename
                ? get(coverBlock, `pptFilename`)
                : ""}
            </Filename>
          </Heading>
          <ChooseFileWrapper>
            <ChooseFile
              className="upload-file"
              {...props}
              uploadFileType={uploadFileType}
              hint="Drag a .PPTX file here or click to browse"
            />
          </ChooseFileWrapper>
        </UploadPptxFile>
        <UploadImageFile>
          <Heading>Image File:</Heading>
          <ChooseFileWrapper>
            <ChooseFile
              className="upload-file"
              {...props}
              uploadFileToRepo={({ metaDeta, file }, cb) => {
                uploadFileToRepo({ metaDeta, file }, cb, {}, () =>
                  fetchCoverImageData()
                );
              }}
              uploadFileType="image"
              hint="Drag a .JPG or .PNG file here or click to browse"
            />
          </ChooseFileWrapper>
        </UploadImageFile>
      </FileUploadWrapper>
      <MetaDataWrapper>
        <Heading>Add Metadata:</Heading>
        <MetaDataForm>
          <FormGroup className="form-group">
            <Input
              value={metaDataName}
              type="text"
              placeholder="Enter Name"
              onChange={handleInputChange}
            />

            <Select value={selectedOption} onChange={handleOptionChange}>
              <Option value="image">Image</Option>
              <Option value="string">String</Option>
              <Option value="date">Date</Option>
              <Option value="type" className="default-selected">
                Type
              </Option>
            </Select>

            <CheckboxWrapper
              type="checkbox"
              name="Required"
              label="Required"
              checked={checkboxValue}
              handleChange={handleCheckboxHandler}
              defaultChecked={false}
            />

            {selectedOption === "string" && (
              <Input
                value={metaDataDefaultName}
                type="text"
                placeholder="Default Value"
                onChange={handleInputDefaultValueChange}
                style={{ marginLeft: "20px" }}
              />
            )}
            {metaDataError && <MetaDataError>{metaDataError}</MetaDataError>}
          </FormGroup>
          <FormControl className="form-control">
            <Button
              text="Add New"
              width={112}
              height={30}
              onClick={addMetaData}
            />
            {/* <Submit onClick={addMetaData}>Add New</Submit> */}
          </FormControl>
        </MetaDataForm>

        {get(metaDataList, `length`) ? (
          <MetaDataList>
            {map(metaDataList, (eachMetaData, index) => {
              return (
                <MetaDataLi>
                  <MetaDataName>{eachMetaData.label}</MetaDataName>
                  <MetaDataType>{eachMetaData.type}</MetaDataType>
                  <input type="checkbox" checked={eachMetaData.required} />
                  Required
                  {eachMetaData.type == "string" ? (
                    <MetaDataDefaultName>
                      {eachMetaData.defaultValue}
                    </MetaDataDefaultName>
                  ) : (
                    <EmptySpaceView />
                  )}
                  <IconWrapper title="Delete">
                    <Delete
                      onClick={() => {
                        removeMetaData(eachMetaData._id, index);
                      }}
                    />
                  </IconWrapper>
                </MetaDataLi>
              );
            })}
          </MetaDataList>
        ) : (
          <></>
        )}
      </MetaDataWrapper>
    </PageWrapper>
  );
}
CoverBlock.defaultProps = {
  uploadFileType: "ppt"
};

const CheckboxWrapper = styled(Checkbox)`
  display: inline-block;
  margin-right: 10px;
`;

const Heading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin: 10px 0;
  text-align: left;
`;

const Filename = styled.span`
  font-size: 14px;
  margin-left: 3px;
`;

const ChooseFileWrapper = styled.div`
  width: 100%;
  margin: auto;
  .btn-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  @media (max-width: 940px) {
    .filename {
      max-width: 117px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > div:first-child > div:first-child {
    height: 190px;
  }
`;

const PageWrapper = styled.div`
  text-align: center;
`;

const ImageSlider = styled.div`
  margin-top: 30px;
`;

const BlockHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
`;

const UploadedFileSection = styled.div``;

const ButtonScrollSliderWrapper = styled.div``;

const NoContentElement = styled.div`
  min-height: 100px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 13px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  background-color: rgba(0, 0, 0, 0.05);
`;

const DropdownWrapper = styled.div`
  text-align: left;
  margin: 10px 0;
`;

const Name = styled.span`
  margin-bottom: 10px;
  font-family: "Maven Pro", sans-serif;
  font-weight: bold;
  color: #636363;
  font-size: 20px;
  ${StyledName};
`;

const StyledName = css`
  display: block;
  text-align: left;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
`;

const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UploadImageFile = styled.div`
  width: 48%;
`;

const UploadPptxFile = styled.div`
  width: 48%;
`;

const MetaDataWrapper = styled.div``;

const MetaDataForm = styled.form`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const FormGroup = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 160px;
  height: 30px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  border-style: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 15px;
  font-weight: 500;
  color: ${props => props.theme.COLOR.MAIN};
  box-sizing: border-box;
`;

const Select = styled.select`
  width: 90px;
  height: 30px;
  padding: 4px 10px 6px;
  margin: 0 20px 0 0;
  border-style: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 15px;
  font-weight: 500;
  color: ${props => props.theme.COLOR.MAIN};
  background-color: transparent;
  box-sizing: border-box;
  transform: translateY(-1px);
`;

const Option = styled.option`
  &.default-selected {
    display: none;
  }
`;

const FormControl = styled.div`
  button {
    height: 30px;
  }
  margin-left: 20px !important;
`;

const MetaDataList = styled.ul`
  padding: 25px 0 10px;
`;

const MetaDataLi = styled.li`
  padding: 0 0 10px;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
`;

const MetaDataName = styled.span`
  width: 160px;
  height: 30px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  border-style: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 15px;
  font-weight: 500;
  color: ${props => props.theme.COLOR.MAIN};
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MetaDataDefaultName = styled.span`
  width: 160px;
  height: 30px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  border-style: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 15px;
  font-weight: 500;
  color: ${props => props.theme.COLOR.MAIN};
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  margin-left: 20px !important;
`;

const EmptySpaceView = styled.span`
  width: 160px;
  height: 30px;
  padding: 5px 10px;
  margin: 0 20px 0 0px;
`;

const MetaDataType = styled.span`
  width: 90px;
  height: 30px;
  padding: 5px 10px;
  margin: 0 20px 0 0;
  border-style: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 15px;
  font-weight: 500;
  color: ${props => props.theme.COLOR.MAIN};
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  text-transform: capitalize;
`;

const SharedIconWrapperCss = css`
  padding: 0;
  height: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  svg:hover {
    opacity: 0.7;
  }

  svg {
    height: 17px;
    cursor: pointer;
  }
`;

const IconWrapper = styled.span`
  ${SharedIconWrapperCss};
  margin-left: 20px !important;
`;

const MetaDataError = styled.span`
  position: absolute;
  bottom: -17px;
  left: 0;
  font-size: 12px;
  color: red;
`;
export default CoverBlock;
