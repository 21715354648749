// libraries
import React from "react";
import styled from "styled-components";
import { get } from "lodash";

// components
import ChooseFile from "components/chooseFile";
import StepDetails from "components/buildProcess/stepDetails";
import UploadedFiles from "./components/uploadedFiles/container";

// images
import { MsExcelIcon } from "assets/images";

const Rates = props => {
  let {
    uploadFileType,
    modifyStepHandler,
    activeStep,
    ratesWorkBookName,
    getCurrentTabDetails
  } = props;

  const currentTab = getCurrentTabDetails();

  return (
    <>
      <StepDetails
        title={get(currentTab, `name`)}
        _next
        _previous={activeStep !== 0}
        onNext={() => modifyStepHandler(activeStep + 1)}
        onPrevious={() => modifyStepHandler(activeStep - 1)}
        description={get(currentTab, `description`) || ""}
      />
      <PageWrapper>
        {get(currentTab, `multipleFiles`) && (
          <UploadedContent>
            <UploadedFiles {...props} />
          </UploadedContent>
        )}

        <ChooseFileWrapper isRatesSingle={!get(currentTab, `multipleFiles`)}>
          {!get(currentTab, `multipleFiles`) && (
            <RatesWorkbookName>
              Rates Workbook:
              {ratesWorkBookName && <ExcelIcon src={MsExcelIcon} />}
              {!ratesWorkBookName
                ? " Upload an Excel workbook to continue"
                : ratesWorkBookName}
            </RatesWorkbookName>
          )}
          <ChooseFile uploadFileType={uploadFileType} {...props} />
        </ChooseFileWrapper>
      </PageWrapper>
    </>
  );
};

Rates.defaultProps = {
  uploadFileType: "xls"
};

const ExcelIcon = styled.img`
  width: 15px;
  height: auto;
  display: inline-block;
  margin-left: 13px;
  position: relative;
  top: 2px;
  right: 5px;
`;

const RatesWorkbookName = styled.span`
  font-size: 18px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
  margin-bottom: 7px;
  display: inline-block;
`;

const ChooseFileWrapper = styled.div`
  width: ${props => (props.isRatesSingle ? "100%" : "20%")};
  margin-top: ${props => (props.isRatesSingle ? "25px" : "65px")};

  .btn-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;

    div:first-child {
      margin-right: ${props => !props.isRatesSingle && "0px"};
      margin-top: ${props => !props.isRatesSingle && "10px"};
    }
  }

  @media (max-width: 940px) {
    .filename {
      max-width: 117px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-start;
`;

const UploadedContent = styled.div`
  width: 79%;
  margin-right: 2%;
`;

export default Rates;
