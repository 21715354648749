import React from "react";
import { map } from "lodash";
import Block from "./block";

const CustomBlock = props => {
  let { customBlocks = [] } = props;
  return (
    <>
      {map(customBlocks, (eachBlock, index) => {
        return (
          <Block
            {...props}
            key={index}
            removeBlock={props.removeBlock}
            eachBlock={eachBlock}
            index={index}
            addToCustomTemplate={props.addToCustomTemplate}
            setFileNameEditedFlag={props.setFileNameEditedFlag}
            saveInputValueHandler={props.saveInputValueHandler}
            templateHandler={props.templateUpdateHandler}
          ></Block>
        );
      })}
    </>
  );
};

export default CustomBlock;
