import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { MdDone, MdClear } from "react-icons/md";

import hexToRgba from "utils/hexToRgba";
import {
  Dropdown as DropdownIcon,
  DeleteSquare,
  PlusIcon,
  EditSquareBorder
} from "assets/icons";

import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

const GroupOptionContainerMain = props => {
  let {
    onGroupDropDownHandler,
    groupDetails,
    groupTitleChangeHandler,
    editGroupDataHandler,
    addNewGroupHandler,
    resetGroupTitleHandler,
    updateGroupTitleHandler,
    deleteGroupHandler,
    isLoading,
  } = props;

  let {
    groupId,
    editGroupValue,
    inputError,
    groupOption,
    isUpdatingGroupTitle
  } = groupDetails;
  return (
    <GroupOptionContainer>
      {isUpdatingGroupTitle ? (
        <InputContainer>
          <GroupTitle
            value={editGroupValue}
            title={editGroupValue}
            onChange={e => groupTitleChangeHandler(e.target.value)}
            autoFocus
          />
          {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
          <SaveClearIconWrapper>
            <SaveIconWrapper title="Save" onClick={updateGroupTitleHandler}>
              <MdDone size={20} color="#a9a9a9" />
            </SaveIconWrapper>
            <ResetIconWrapper title="Close" onClick={resetGroupTitleHandler}>
              <MdClear size={20} color="#a9a9a9" />
            </ResetIconWrapper>
          </SaveClearIconWrapper>
        </InputContainer>
      ) : (
        <>
          <DropDownContainer>
            <AngleDown htmlFor="dropdown">
              <DropdownIcon />
            </AngleDown>
            <DropDown
              autoFocus={true}
              id="dropdown"
              className="category-select"
              onChange={e => onGroupDropDownHandler(e.target.value)}
            >
              {_.map(groupOption, (item, index) => {
                return (
                  <DropDownOption
                    value={index === 0 ? "" : item._id}
                    className="select-options"
                    key={index}
                    label={item.title}
                    selected={item._id === groupId}
                  >
                    {item.title}
                  </DropDownOption>
                );
              })}
            </DropDown>
          </DropDownContainer>

          <EditIconWrap
            title="Edit Group"
            onClick={editGroupDataHandler}
            isEnabled={!!groupId}
          >
            <EditSquareBorder />
          </EditIconWrap>
          <DeleteIcon
            title="Delete Group"
            isEnabled={!!groupId}
            onClick={() => {
              DeleteConfirmationAlert({
                onYesClick: () => {
                  deleteGroupHandler();
                }
              });
            }}
          >
            <DeleteSquare />
          </DeleteIcon>
          <PlusIconWrap title="Add Group" onClick={addNewGroupHandler} disabled={isLoading}>
            <PlusIcon />
          </PlusIconWrap>
        </>
      )}
    </GroupOptionContainer>
  );
};

export default GroupOptionContainerMain;

const GroupOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DropDownContainer = styled.div`
  flex-basis: 55.85%;
  height: 30px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: 4px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`;

const AngleDown = styled.label`
  position: absolute;
  padding-top: 5px;
  right: 0;
  width: 23px;
  height: 20px;
  top: 0;
  z-index: -1;
  height: 30px;
  background: ${props => props.theme.COLOR_PALLETE.SOLITUDE};
`;

const DropDown = styled.select`
  width: 100%;
  height: 30px;
  padding: 0 9px;
  border: none;
  border-radius: 4px;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  font-family: ${props => props.theme.FONT.REG};
  background: transparent;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 30px;
  cursor: pointer;
  outline: none;
  padding-right: 40px;
`;

const DropDownOption = styled.option``;

const DeleteIcon = styled.span`
  margin-left: 8px;
  height: 30px;
  cursor: ${props => (props.isEnabled ? "pointer" : "auto")};
  pointer-events: ${props => (props.isEnabled ? "auto" : "none")};
  opacity: ${props => (props.isEnabled ? 1 : 0.5)};

  svg {
    width: 30px;
    height: 30px;
  }
`;

const PlusIconWrap = styled.span`
  width: 30px;
  height: 30px;
  padding: 3px 0 3px 1px;
  border-radius: 4px;
  margin-left: 6px;
  text-align: center;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${props => props.theme.COLOR.WHITE};
    }
  }
`;

const EditIconWrap = styled.span`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin-left: 8px;
  text-align: center;
  cursor: ${props => (props.isEnabled ? "pointer" : "auto")};
  pointer-events: ${props => (props.isEnabled ? "auto" : "none")};
  opacity: ${props => (props.isEnabled ? 1 : 0.5)};

  svg {
    width: 30px;
    height: 30px;
  }
`;

const InputContainer = styled.div`
  flex-basis: 100%;
  height: 30px;
  position: relative;
`;

const GroupTitle = styled.input`
  width: calc(100% - 50px);
  height: 30px;
  box-sizing: border-box;
  padding: 3px 0 0 3px;
  border: none;
  border-bottom: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  display: block;
  color: grey;
  outline: none;
  font-size: 14px;
  font-family: ${props => `${props.theme.FONT.REG}`};
  color: ${props => props.theme.COLOR.MAIN};
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::-ms-clear {
    display: none;
  }
`;

const SaveClearIconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const SaveIconWrapper = styled.a`
  cursor: pointer;
  margin-right: 2px;
`;

const ResetIconWrapper = styled.a`
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  font-weight: normal;
  position: absolute;
  left: 0;
  bottom: -12px;
`;
