import React from "react";
import styled from "styled-components";
import RadioButton from "components/radioBtn";
import { FaRegQuestionCircle } from "react-icons/fa";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import { SlidePreviewLeftArrow, SlidePreviewRightArrow } from "assets/icons";

const ReactHint = ReactHintFactory(React);
/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <HintContainer className="custom-hint__content">
      <HintContent>{content}</HintContent>
    </HintContainer>
  );
};

const TaggingSlide = props => {
  const {
    setTagging,
    continueHandler,
    availableSlides,
    currentActiveIndex,
    navigationHandler
  } = props;

  let editedSlideType =
    availableSlides.length &&
    availableSlides[currentActiveIndex].editedSlideType;
  let slideTypes =
    availableSlides.length && availableSlides[currentActiveIndex].slideType;

  let previewRequiredFields = [
    "Cover",
    "PrimaryCover",
    "PrimaryContent",
    "Divider",
    "Blank",
    "Content"
  ];

  return props.activeThemeDetails.subMasterDetails &&
    props.activeThemeDetails.subMasterDetails.length ? (
    <TaggingSlideContainer>
      <ReactHint events onRenderContent={onRenderContent} />
      <Tagging>
        <TagSlideHeading>
          Tag Slide
          <FaRegQuestionCircleIcon
            size={12}
            data-rh="Tagging the slide"
            data-rh-at="right"
          />
        </TagSlideHeading>
        {props.activeThemeDetails.subMasterDetails &&
          previewRequiredFields.map((item, index) => {
            return (
              <RadioButtonWrapper key={index}>
                <RadioButton
                  radioType="controlled"
                  key={index}
                  id={index}
                  name="slide-type"
                  label={item}
                  checked={item === (editedSlideType || slideTypes)}
                  handleChange={e => setTagging(item)}
                />
              </RadioButtonWrapper>
            );
          })}
      </Tagging>
      <SlideCta>
        <IconWrapper
          title="Previous Slide"
          disabled={currentActiveIndex === 0}
          onClick={() => navigationHandler(true, false)}
        >
          Previous
        </IconWrapper>
        <IconWrapper
          title="Next Slide"
          disabled={currentActiveIndex === availableSlides.length - 1}
          onClick={() => navigationHandler(false, true)}
        >
          Next
        </IconWrapper>
      </SlideCta>
    </TaggingSlideContainer>
  ) : null;
};

export default TaggingSlide;

const TaggingSlideContainer = styled.div`
  width: 65%;
  margin-left: 35%;
`;

const Tagging = styled.div`
  width: 50%;
  vertical-align: middle;
  display: inline-block;
`;

const RadioButtonWrapper = styled.div`
  margin-top: 10px;
  label {
    color: ${props => props.theme.COLOR.HEADING};
  }
`;

const ContinueButton = styled.button`
  width: 33%;
  padding: 12px 20px;
  display: inline-block;
  border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  border-radius: 4px;
  outline: none;
  transition: all ease 0.4s;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
  }
`;

const TagSlideHeading = styled.span`
  margin-bottom: 10px;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
`;

const FaRegQuestionCircleIcon = styled(FaRegQuestionCircle)`
  width: 15px;
  height: 20px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: bottom;
  font-weight: normal;
  cursor: pointer;
`;

const HintContent = styled.span`
  color: ${props => props.theme.COLOR.WHITE};
`;

const HintContainer = styled.div`
  width: 100%;
  padding: 5px 10px;
  background-color: ${props => props.theme.COLOR.BLACK};
  border-radius: 4px;
`;

const SlideCta = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
`;

const IconWrapper = styled.button`
  width: 35%;
  padding: 10px 0;
  margin-right: 5%;
  border: 1px solid ${props => props.theme.COLOR_PALLETE.GREY};
  border-radius: 5px;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  font-weight: bold;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 0.9)};
  background: none;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  &:hover {
    background: ${props => !props.disabled && props.theme.COLOR_PALLETE.GREY};
    color: ${props => !props.disabled && props.theme.COLOR.WHITE};
  }
`;
