import React, { Component } from "react";
import ToastUtils from "utils/handleToast";
import { find, get, map, filter, isEmpty, each, size } from "lodash";
import featureFlags from "utils/featureFlags";

const Container = Main =>
  class Presentation extends Component {
    state = {
      saveLogo: false,
      isAnyLogoEdited: false,
      showSaveProfileCheckbox: true,
      showPreview: false,
      overlayCoverImage: null,
      doesCobrandLogoExist: false,
      setupDetails: this.props.pmSetupDetailsData,
      isDynamicCoverDetailsEdited: []
    };

    async componentDidMount() {
      let {
        contentRepo: { _id: contentRepoId }
      } = this.props;

      let { showCustomerLogo } = get(featureFlags, `presentation`);

      // This logic checks if theme is already present inside of coverDetails payload
      // theme is the themeId
      // LOGIC: Only make an API request to the server when new presentation is created.
      const { theme } = get(this.props, `coverDetails`);
      !size(theme) && (await this.props.fetchThemeList(contentRepoId));
      
      showCustomerLogo &&
        this.props.getCustomerLogoList(this.props.userDetail._id);

      this.props.checkCoverDynamicFields();
      // selected theme _id from presentation response
      let selectedTheme =
        this.props.presentationData &&
        get(this.props.presentationData, "theme");

      // save theme data if previously selected theme is been disabled by admin
      if (
        !find(this.props.themeList, {
          _id: selectedTheme
        }) &&
        get(this.props, "presentationData.contentRepository") === contentRepoId
      ) {
        this.props.onStepEdit();
      }

      this.checkIfCobrandLogoExits();

      this.setState({
        showSaveProfileCheckbox:
          !!!this.props.match.params.id && !this.props.selectedLogo // do not show the check at first in edit mode and on tab change
      });
    }

    onBlurInput = () => {
      this.setState({
        isDynamicCoverDetailsEdited: []
      });
    };

    onChangeInput = ({ value, label, key, date, flag }) => {
      // let customerName = filter(this.state.setupDetails, eachDetail => {
      //   return eachDetail.key === "customerName";
      // });

      // // only allow logo upload if customer field is filled
      // if (label.indexOf("Customer Name") !== -1) {
      //   this.props.handleStateChange({
      //     key: "allowLogoUpload",
      //     value: value.trim().length !== 0 && !!!customerName[0].error
      //   });
      // }

      const dynamicCoverDetails = [...this.props.coverDetails.slideData];
      each(dynamicCoverDetails, item => {
        if (item.label === label) {
          item.text = flag ? date : value;
        }
      });

      this.setState({
        isDynamicCoverDetailsEdited: [label]
      });

      this.props.handleStateChange({
        key: "dynamicCoverDetails",
        value: dynamicCoverDetails
      });

      this.props.handleStateChange({
        key: "isEdited",
        value: true
      });
    };

    checkIfCobrandLogoExits = () => {
      let themeList = this.props.themeList || [];

      for (let index = 0; index < themeList.length; index++) {
        if (themeList[index].isCobrandLogo) {
          this.setState({
            doesCobrandLogoExist: true
          });
          return;
        }
      }
    };

    handleCoverPreview = (imgSrc = "") => {
      this.setState({
        showPreview: !this.state.showPreview,
        overlayCoverImage: imgSrc
      });
    };

    onLogoClick = id => {
      let { contentRepo } = this.props;

      if (isEmpty(contentRepo)) {
        return;
      }
      const imgLogoList = this.props.customerLogoList;
      const selectedLogo = find(imgLogoList, item => item._id === id);

      // show full cropper if new image is uploaded
      this.props.handleStateChange({
        key: "cropData",
        value: { top: 0, left: 0, width: 1000, height: 1000 }
      });

      this.props.selectedLogoHandler(selectedLogo);
      this.setState({
        showSaveProfileCheckbox: false
      });
    };

    // called on upload click
    logoUploadHandler = e => {
      this.props.handleStateChange({
        key: "allowLogoUpload",
        value: true
      });

      // show full cropper if new image is uploaded
      this.props.handleStateChange({
        key: "cropData",
        value: { top: 0, left: 0, width: 1000, height: 1000 }
      });
      map(this.state.setupDetails, field => {
        if (
          field.key === "customerName" &&
          (field.value.trim().length === 0 || field.error)
        ) {
          ToastUtils.handleToast({
            operation: "error",
            message: `${
              field.error
                ? "Please enter a valid customer name."
                : "Please enter the customer name."
            }`
          });

          this.props.handleStateChange({
            key: "allowLogoUpload",
            value: false
          });
        }
      });
      if (!this.props.allowLogoUpload) {
        return;
      }

      let customerName = filter(this.state.setupDetails, [
        "key",
        "customerName"
      ]);

      let reader = new FileReader();
      let fileName = get(e, "target.files[0].name", null);
      if (/\.(jpe?g|png|gif)$/i.test(fileName)) {
        ToastUtils.handleToast({ operation: "dismiss" });
        let logoPreviewUri = null;
        reader.addEventListener(
          "load",
          () => {
            logoPreviewUri = reader.result;

            this.props.selectedLogoHandler(logoPreviewUri);
            this.setState({
              saveLogo: false,
              showSaveProfileCheckbox: true
            });

            this.props.handleSelectedLogo(
              logoPreviewUri,
              customerName[0].value,
              this.state.saveLogo
            );
          },
          false
        );
        reader.readAsDataURL(e.target.files[0]);
      } else {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please upload valid file format [.png or .jpg]."
        });
      }
    };

    deleteCurrentLogoHandler = ({ deletedLogo }) => {
      if (deletedLogo === "selectedLogo") {
        this.props.selectedLogoHandler(null);
        this.setState({
          showSaveProfileCheckbox: false
        });
        return;
      } else {
        this.props.deleteLogoHandler(deletedLogo);
      }
    };

    /**
     * set flag stating if any logo title is edited
     * @param {Boolean} isAnyLogoEdited whose value needs to be checked
     */
    setLogoEditedFlag = isAnyLogoEdited => {
      this.setState({
        isAnyLogoEdited
      });
    };

    handleCheckbox = (e, value, label, type) => {
      if (e.target.id === "save-logo") {
        let customerName = filter(this.state.setupDetails, [
          "key",
          "customerName"
        ]);
        if (customerName.length && customerName[0].error) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please enter a valid customer name."
          });
          return;
        }

        let saveLogo = this.state.saveLogo;
        saveLogo = value;
        this.setState({ saveLogo }, () => {
          // if save to profile is checked and logo is selected save the logo to profile
          if (value && this.props.selectedLogo) {
            this.props.handleSelectedLogo(
              this.props.croppedImage || this.props.selectedLogo,
              customerName[0].value,
              saveLogo
            );
            // again resetting the states
            this.setState({
              saveLogo: false,
              showSaveProfileCheckbox: false
            });
          }
        });
      } else {
        const setupDetails = [...this.state.setupDetails];
        setupDetails.forEach(item => {
          if (item.label === e.target.id && item.editable) {
            item.value = value;
            this.props.handleSetupDataChange(item.key, item.value);
          }
        });
        this.setState({ setupDetails });
      }
    };

    render() {
      const $this = this;

      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };
export default Container;
