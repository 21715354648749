import { DownloadDeckTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

export const isSlideDetailLoading = (
  payload = null,
  type = DownloadDeckTypes.LOADING_SLIDE_DETAILS
) => {
  return {
    type,
    payload
  };
};

export const onDownloadDeckSuccess = (
  payload = null,
  type = DownloadDeckTypes.SUCCESS_DOWNLOAD_DECK
) => {
  return {
    type,
    payload
  };
};

const downloadDeck = (
  contentRepositoryId,
  categoryId,
  body
) => async dispatch => {
  const URL = `/content-repository/${contentRepositoryId}/category/${categoryId}/deck`;

  dispatch(
    isSlideDetailLoading({
      isSlideDetailLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, body, "save theme data");

  // hide loader
  dispatch(
    isSlideDetailLoading({
      isSlideDetailLoading: false
    })
  );

  if (response.success && response.data) {
    // dispatch logo list on success
    dispatch(
      onDownloadDeckSuccess({
        downloadDeckDetails: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const stopDownloadDeckLoading = () => async dispatch => {
  dispatch(
    onDownloadDeckSuccess({
      isSlideDetailLoading: false
    })
  );
};

export { downloadDeck, stopDownloadDeckLoading };
