import React, { Component } from "react";
import { connect } from "react-redux";

import { getSlideDetails } from "../../services/libraryOverlay";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import { filter, map, get, size, isEmpty } from "lodash";
import featureFlags from "utils/featureFlags";
import { SEARCH_LIBRARY_SLIDES } from "../../services/documentBuilderSetupPanel/reducer";

const UI_STRINGS = {
  DESELECT_ALL_SLIDES:
    "Are you sure you want to remove all slides from your presentation?"
};

// This dispatch function clears off all the values of the search results from the slides
const clearAllSlides = () => async dispatch => {
  dispatch({
    type: SEARCH_LIBRARY_SLIDES,
    payload: {
      slides: []
    }
  });
};

const mapStateToProps = state => {
  const { SUCCESS_SLIDE_DETAILS, LOADING_SLIDE_DETAILS } = state;
  return {
    ...SUCCESS_SLIDE_DETAILS,
    ...LOADING_SLIDE_DETAILS,
    // the Library container+view are passing these down to
    // ViewByTopic
    slides: state.pm.slides,
    filteredSlides: state.pm.filteredSlides,
    // ViewBySearch
    searchResultSlides: state.pm.searchResultsSlides
  };
};

const mapDispatchToProps = {
  getSlideDetails,
  clearAllSlides
};

const Container = Main =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Lib extends Component {
      state = {
        filters: [],
        groupId: [],
        selectedTabValue: "View by Topic",
        slideId: ""
      };

      /** Deselect button handler*/
      handleDeselect = () => {
        // Handle required slide
        let requiredSlides =
          filter(this.props.requiredSlides, "isRequired") || [];

        let requiredSlidesId = map(requiredSlides, "_id") || [];

        DeleteConfirmationAlert({
          message: UI_STRINGS.DESELECT_ALL_SLIDES,
          onYesClick: () => {
            //empty all slides
            this.props.handleSelectSlides(requiredSlidesId, requiredSlides);
          }
        });
      };

      async componentDidMount() {
        const {fetchThemeList, contentRepo: {_id}} = this.props
        if(this.props.themeList === undefined){
          /**
           * When the user directly jumps to the library stepper without going to the Theme stepper
           * Then the APIs related to theme isn't called, so themeId is not set because of which on library stepper 
           * api/${contentRepoId}/${themeId}/slides?type=Content
           * theme ID is not defined, hence throwing error.
           * 
           * So this await function will see if the themeList is undefined and call the APIs of theme.
           */
          await fetchThemeList(_id);
        }

        if (
          this.props.libraryByTopicList == null ||
          this.props.libraryByTopicList == undefined ||
          !size(this.props.libraryByTopicList)
        )
          this.getLibraryPageDetails();
      }

      getLibraryPageDetails = async () => {
        let {
          contentRepo: { _id: selectedContentRepoId },
          selectedThemeLayout
        } = this.props;
        // This flag is used to decide if the filters tabs is to be shown on library page
        let { showPresentationFilter } = get(featureFlags, `presentation`);

        // Get selected filters before content slides for library page
        const selectedFilters =
          showPresentationFilter &&
          (await this.props.fetchLibraryFiltersList({
            _id: selectedContentRepoId
          }));
        await this.props.getLibraryByTopic(
          selectedContentRepoId,
          selectedThemeLayout,
          {
            filters: (selectedFilters || []).filter(
              elem => !(elem.indexOf("F-") > -1)
            )
          }
        );

        // Get required slides data
        let {
          withRequiredSlides,
          withRequiredSlideId
        } = this.props.getContentSlideDetails();

        await this.props.getContentSlideListOfSelectedRepo(
          selectedContentRepoId,
          selectedThemeLayout
        );

        let formattedList = this.props.formatSlidesForDragAndDrop(
          withRequiredSlides
        );

        // Handle overview slide limit flag
        let overviewLimitExceeded = !this.props.checkOverviewSlideLimit(
          formattedList
        );

        this.props.handleStateChange({
          key: "overviewLimitExceeded",
          value: overviewLimitExceeded
        }, true);

        this.props.handleStateChange({
          key: "selectedSlidesListDetail",
          value: formattedList
        }, true);

        this.props.handleStateChange({
          key: "selectSlides",
          value: withRequiredSlideId
        }, true);
      };

      /** set the current tab*/
      setTab = ({ value }) => {
        this.setState({
          selectedTabValue: value
        });
      };

      /**
       * Handle change event for Search Box
       * @param {e} event
       */
      handleLibrarySearchChange = e => {
        e.persist();
        this.librarySearchTimeout && clearTimeout(this.librarySearchTimeout);

        if (isEmpty(e.target.value)) {
          // Created a dispatch function above which clears off searchResultSlides from redux store in pm:{searchResultSlides:{slide:[]}}
          this.props.clearAllSlides();
        } else {
          // debouncing after 1sec
          this.librarySearchTimeout = setTimeout(() => {
            this.props.onChangeHandleLibraryBySearch({
              search: e && e.target.value.trim()
            });
          }, 1000);
        }
      };

      componentWillUnmount() {
        this.librarySearchTimeout && clearTimeout(this.librarySearchTimeout);
      }

      render() {
        const $this = this,
          { selectedTabValue, slideId } = this.state;

        //set library for topic lists and search lists of content slides from props
        const viewByTopicSearch = {
          "View by Topic":
            (Array.isArray(this.props.libraryByTopicList) &&
              this.props.libraryByTopicList) ||
            [],
          "View by Search":
            (Array.isArray(this.props.libraryBySearchList) &&
              this.props.libraryBySearchList) ||
            []
        };

        /**Merge State and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.props,
          filters: this.props.libraryFiltersList || [],
          viewByTopicSearch,
          selectedTabValue,
          slideId
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
