import React, { Fragment } from "react";
import styled, { css } from "styled-components";

//comoponents
import Checkbox from "components/checkbox";
import hexToRgba from "utils/hexToRgba";
import DatePicker from "components/datePicker";
import MultiSelect from "./components/MultiSelect";

//utils
import featureFlags from "utils/featureFlags.js";
import { get, map } from "lodash";

//Redux-Hooks
import { useDispatch } from 'react-redux'

const SetupDetails = props => {

  // console.log( "SetupDetails: ", {props} );

  const {
    setupDetailsData: setupDetails,
    onChangeInput, // called on change to text, date fields
    handleCheckbox, // called on change to checkboxes
    isSubContentRepo, // if not empty, generates multi-selects
    selectedSubContent, // called on change to isSubContentRepo multi-selects
    isFilterSelectedSetupPage, // values for isSubContentRepo multi-selects
    contentRepositorySelected, // .metadata is used to generate fields (date,integer,string,list)
    handleSelectedContent // called on change to any of the .metadata[]-generated fields
  } = props;

  let { showCustomerLogo } = get(featureFlags, `presentation`);

  // create a mutable variable to receive the map result
  let _isSubContentRepo = map(isSubContentRepo, rs => {
    rs.label = rs.title;
    rs.children = rs.children.map(children => {
      children.label = children.title;
      children.value = children._id;
      return children;
    });
    return rs;
  });

  const dispatch = useDispatch();

  let selectedListDefaultValues = [];
  return (
    <DetailsContainer isCenterAligned={!showCustomerLogo}>
      {setupDetails &&
        map(
          setupDetails,
          (
            {
              label,
              value,
              error,
              type,
              editable,
              options,
              key,
              isMulti,
              handleChange,
              defaultValue,
              minDate,
              showField
            },
            index
          ) => {
            const labelGroupItem = (
              <>
                <Fragment key={index}>
                  {type === "text" && (
                    <LabelGroup key={index}>
                      {label && <Label>{label}</Label>}
                      {type === "text" && editable && (
                        <LabelInput
                          type={type}
                          value={value}
                          onChange={e =>
                            onChangeInput({
                              value: e.target.value,
                              label,
                              key
                            })
                          }
                          ref={ref => {
                            if (label === "Presentation Name*")
                              props.setRef(ref);
                          }}
                          className="__test_text_input"
                        />
                      )}
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </LabelGroup>
                  )}
                  {type === "date" && (
                    <DatePickerContainer>
                      <DatePicker
                        className={"presentationDate"}
                        title={label}
                        toolTip={label}
                        value={value}
                        handleChange={date => {
                          onChangeInput({
                            label,
                            key: key,
                            date: date,
                            flag: true
                          });
                        }}
                        placeholderText={"From"}
                        minDate={minDate}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </DatePickerContainer>
                  )}
                  {type === "multi-select" &&
                    Array.isArray(options) &&
                    options.map(({ _id, label, options }) => (
                      <LabelGroup key={_id}>
                        {label && <Label>{label}</Label>}
                        <MultiSelect
                          placeholder={"Select an option"}
                          options={options}
                          value={
                            (typeof defaultValue[_id] === "object" &&
                              defaultValue[_id]) ||
                            null
                          }
                          closeMenuOnSelect={false}
                          allowSelectAll={true}
                          isClearable
                          isMulti
                          handleChange={handleChange}
                          id={_id}
                        />
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </LabelGroup>
                    ))}
                </Fragment>
                {type === "select" && showField && (
                  <LabelGroup>
                    {label && <Label>{"Content Repo*"}</Label>}
                    <SelectContainer
                      key={index}
                      top={index === 3}
                      bottom={index === 5}
                      className={isMulti ? "multi-select" : ""}
                    >
                      <MultiSelect
                        placeholder={label}
                        handleChange={e => {
                          // console.log( 'handleChange: ', {e});
                          handleChange(e);
                          dispatch({
                            type: "SUCCESS_LIBRARY_TOPIC",
                            payload: { libraryByTopicList: [] }
                          });
                        }}
                        options={options}
                        value={defaultValue}
                      />
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </SelectContainer>
                  </LabelGroup>
                )}
                {type === "select" &&
                  showField &&
                  _isSubContentRepo.length > 0 &&
                  _isSubContentRepo.map((rs, index) => {
                    return (
                      <Fragment key={`sub-content-${index}`}>
                        {rs.isVisibleOnSetup && (
                          <LabelGroup>
                            {rs.label && <Label>{rs.label}</Label>}
                            <MultiSelect
                              placeholder={rs.label}
                              options={rs.children}
                              closeMenuOnSelect={false}
                              allowSelectAll={true}
                              isClearable={true}
                              isMulti={true}
                              handleChange={selected => {
                                selectedSubContent(selected, rs._id);
                              }}
                              id={rs._id}
                              value={
                                (typeof isFilterSelectedSetupPage[rs._id] ===
                                  "object" &&
                                  isFilterSelectedSetupPage[rs._id]) ||
                                null
                              }
                            />
                            {error && <ErrorMessage>{error}</ErrorMessage>}
                          </LabelGroup>
                        )}
                      </Fragment>
                    );
                  })}

                {type === "checkbox" && label && (
                  <Checkbox
                    key={key}
                    handleChange={e => {
                      handleCheckbox(e, e.target.checked, label, type);
                    }}
                    checked={value}
                    label={label}
                    id={label}
                  />
                )}
                {type === "select" &&
                  showField &&
                  contentRepositorySelected &&
                  contentRepositorySelected.metadata !== undefined &&
                  contentRepositorySelected.metadata !== null &&
                  map(contentRepositorySelected.metadata, (data, index) => {
                    selectedListDefaultValues = [];
                    if (
                      data !== undefined &&
                      data.type !== undefined &&
                      data.type === "date"
                    ) {
                      return (
                        <DatePickerContainer>
                          <DatePicker
                            className={"presentationDate"}
                            title={
                              data.label + data.isMandatory ? (
                                <span>*</span>
                              ) : (
                                ""
                              )
                            }
                            toolTip={data.label}
                            placeholderText={"Select Date"}
                            value={data.value}
                            handleChange={date => {
                              handleSelectedContent(
                                date,
                                "date",
                                index,
                                data.label
                              );
                            }}
                          />
                          {data.error && (
                            <ErrorMessage>{data.error}</ErrorMessage>
                          )}
                        </DatePickerContainer>
                      );
                    } else if (
                      data !== undefined &&
                      data.type !== undefined &&
                      data.type === "integer"
                    ) {
                      return (
                        <LabelGroup key={index}>
                          {data.label && (
                            <Label>
                              {data.label}
                              {data.isMandatory ? <span>*</span> : ""}
                            </Label>
                          )}
                          <LabelInput
                            type="number"
                            value={data.value}
                            onChange={e => {
                              handleSelectedContent(
                                e.target.value,
                                "integer",
                                index,
                                data.label
                              );
                            }}
                          />
                          {data.error && (
                            <ErrorMessage>{data.error}</ErrorMessage>
                          )}
                        </LabelGroup>
                      );
                    } else if (
                      data !== undefined &&
                      data.type !== undefined &&
                      data.type === "string"
                    ) {
                      return (
                        <LabelGroup key={index}>
                          {data.label && (
                            <Label>
                              {data.label}
                              {data.isMandatory ? <span>*</span> : ""}
                            </Label>
                          )}
                          <LabelInput
                            type="text"
                            value={data.value}
                            onChange={e => {
                              handleSelectedContent(
                                e.target.value,
                                "string",
                                index,
                                data.label
                              );
                            }}
                          />
                          {data.error && (
                            <ErrorMessage>{data.error}</ErrorMessage>
                          )}
                        </LabelGroup>
                      );
                    } else if (
                      data !== undefined &&
                      data.type !== undefined &&
                      data.type === "list"
                    ) {
                      return (
                        <LabelGroup key={index}>
                          {data.label && (
                            <Label>
                              {data.label}
                              {data.isMandatory ? <span>*</span> : ""}
                            </Label>
                          )}
                          {data.defaultValue.forEach(data => {
                            selectedListDefaultValues.push({
                              label: data,
                              value: data
                            });
                          })}

                          <MultiSelect
                            placeholder="Select Options"
                            options={selectedListDefaultValues}
                            closeMenuOnSelect={true}
                            allowSelectAll={false}
                            isClearable={true}
                            isMulti={false}
                            handleChange={selectedOptionsList => {
                              handleSelectedContent(
                                get(selectedOptionsList, `label`),
                                "list",
                                index,
                                data.label
                              );
                            }}
                            value={{
                              label: data.value,
                              value: data.value
                            }}
                          />
                          {data.error && (
                            <ErrorMessage>{data.error}</ErrorMessage>
                          )}
                        </LabelGroup>
                      );
                    }
                  })}
              </>
            );

            return labelGroupItem;
          }
        )}
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  flex-basis: ${props => (props.isCenterAligned ? "67%" : "35.5%")};
  max-width: 650px;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 24px 41px;
  .dropdown-container {
    margin: 20px 0;
    label {
      padding-top: 8px;
    }
  }
  .checkbox-container {
    margin: 20px 0;
    label {
      margin-left: 4px;
      letter-spacing: -0.2px;
    }
  }
`;

const SelectContainer = styled.div`
  padding-bottom: 0px;
  ${props =>
    props.top
      ? "padding-top: 15px"
      : props.bottom
      ? "margin-bottom: 29px;"
      : ""};
  label {
    top: -2px;
  }

  &.multi-select {
    overflow: hidden;
    select {
      width: 108%;
      height: auto;
      padding: 10px;
      margin: -5px -20px -5px -5px;
    }

    option::selection {
      background: red;
    }
  }

  /*Override custom select list*/
  .custom-select__control {
    /*When select list is focuses*/
    &--is-focused {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    /*When select list is in active state*/
    &--menu-is-open {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }

  /*Overriding option styling*/
  .custom-select__menu {
    > div > div {
      background-color: ${props => props.theme.COLOR.WHITE};

      &:hover {
        background-color: ${props =>
          hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.2)};
      }
    }

    .custom-select__option--is-selected {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const sharedInputStyle = css`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 3px;
  padding: 7px 8px;
  height: 30px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  background: ${props => props.theme.COLOR.INPUT};
  outline: none;
`;

const LabelGroup = styled.div`
  margin-bottom: 10px;
`;

const LabelCSS = css`
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
  margin-bottom: 4px;
  display: inline-block;
`;

const Label = styled.span`
  ${LabelCSS}
`;

const LabelInput = styled.input`
  ${sharedInputStyle};

  &:focus {
    border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-family: ${props => props.theme.FONT.REG};
  color: ${props => props.theme.COLOR_PALLETE.ERROR};
  text-align: left;
`;

const DatePickerContainer = styled.div`
  margin-bottom: 7px;
  vertical-align: top;
  &:nth-of-type(2) {
    margin-right: 20px;
  }
  &:nth-of-type(3) {
    label:first-child {
      visibility: hidden;
    }
  }
  .presentationDate {
    label:first-child {
      ${LabelCSS}
      margin-bottom: 0;
    }
    input {
      height: 30px;
      ${sharedInputStyle}
      padding: 0;
    }
    svg {
      top: 66%;
    }
  }
`;

export default SetupDetails;
