import React from "react";
import { Route } from "react-router-dom";

// routes
import Landingpage from "../container";

const MasterHomePage = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        path="/masterhome"
        render={props =>
          checkAuthSession(<Landingpage auth={auth} {...props} />)
        }
      />
    </>
  );

  return routes;
};

export default MasterHomePage;
