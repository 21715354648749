import React, { Component } from "react";
import {
  EditWithNoShadow,
  Delete,
  Duplicate,
  DownloadColored,
  ShareColored,
  shareDisable,
  DownloadDisable
} from "assets/icons";
import styled, { css } from "styled-components";
import handleBodyScroll from "utils/handleBodyScroll";
import ToastUtils from "utils/handleToast";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import { downloadFile } from "utils/download";

import {
  getPresentationList,
  deletePresentation,
  clonePresentation
} from "./services";
import { get, set, each, filter, map } from "lodash";
import { connect } from "react-redux";
import { trackToolDashboardCount } from "services/trackDashboardCount";
import { loggingDownloadInDatabase } from "../../pages/build/services/libraryTopicSearchFilters";

const mapStateToProps = state => {
  const {
    SUCCESS_USER_PROFILE,
    LOADING_PRESENTATION,
    SUCCESS_PRESENTATION
  } = state;

  return {
    ...SUCCESS_USER_PROFILE,
    ...LOADING_PRESENTATION,
    ...SUCCESS_PRESENTATION
  };
};

const mapDispatchToProps = {
  getPresentationList,
  clonePresentation,
  loggingDownloadInDatabase
};

const Container = Main =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Presentation extends Component {
      static defaultProps = {
        role: "admin"
      };
      state = {
        data: [],
        cols: [],
        isRenderTable: true,
        tableColumnHeader: [
          "presentationName",
          "customerName",
          "updatedAt",
          "status",
          "repo",
          ""
        ],
        selectedTabValue: "users",
        uploadedFile: null,
        isShowOverlay: false,
        isPopShow: false,
        selectedPresentation: {}
      };

      presentationColumns = [
        {
          col1: "Presentation",
          col2: "Client",
          col3: "Last Updated",
          col4: "Status",
          col5: "Repo",
          col6: "Actions"
        }
      ];
      columnWidth = [240, 105, 145, 84, 154, 174];
      searchFields = ["presentationName", "customerName"];

      componentDidMount() {
        // load presentation list
        this._fetchPresentationList();
        this.trackPresentationDashboardCount();
      }

      componentDidUpdate(prevProps) {
        // check if user profile data is recieved and get the url
        if (prevProps.userProfileMeta !== this.props.userProfileMeta) {
          this._fetchPresentationList();
          this.trackPresentationDashboardCount();
        }
      }

      /**
       *Log whenever a user hits dashboard
       *
       */
      trackPresentationDashboardCount = async () => {
        let userId = (this.props.userProfileMeta || {})._id;
        userId && (await trackToolDashboardCount(userId, "pg"));
      };

      _fetchPresentationList = async () => {
        const userID =
          this.props.userProfileMeta && this.props.userProfileMeta._id;
        userID && (await this.props.getPresentationList(userID));
        this.formatResponse(this.props.presentationData);
      };

      formatResponse = presentationData => {
        // formatting the response so as to get repo details into one key "repo"
        Array.isArray(presentationData) &&
          each(presentationData, item => {
            set(
              item,
              "repo",
              `${get(item, `contentRepository.dimensions.aspectRatio`)} ${get(
                item,
                `contentRepository.title`
              )}`
            );
          });

        this.setState({
          cols: this.presentationColumns,
          isRenderTable: true
        });
      };

      // show share overlay
      overlayHandler = selectedPresentation => {
        !this.state.isShowOverlay
          ? handleBodyScroll({ action: "open" })
          : handleBodyScroll({ action: "close" });

        this.setState({
          selectedPresentation,
          isShowOverlay: !this.state.isShowOverlay
        });
      };

      //function to handle download
      downloadOverlayHandler = selectedPresentation => {
        const { pdfLocation, pptLocation, zipLocation } =
          selectedPresentation || {};
        let urlLocations = [pdfLocation, pptLocation, zipLocation];

        let moreThanOne = urlLocations.filter((item = {}) => item.url);

        // Check if urls are present or not
        if (!moreThanOne.length) return;

        if (moreThanOne.length === 1) {
          downloadFile(moreThanOne[0].url);
          return;
        }

        this.setState({
          isPopShow: true,
          selectedPresentation
        });
      };

      /**
       *Checks if a given presentation can be downloaded
       *
       * @returns Boolean value
       */
      _checkIfPresentationDownloadable = (selectedPresentation = {}) => {
        const {
          pdfLocation,
          pptLocation,
          zipLocation,
          status
        } = selectedPresentation;
        let urlLocations = [pdfLocation, pptLocation, zipLocation];
        let availabeBuilds = urlLocations.filter((item = {}) => item.url);
        // Check if build is completed and check if urls are present or not
        // Note: Url is available only if user is allowed to donwload by admin
        return status === "Completed" && !!availabeBuilds.length;
      };

      //handle closing of modal on click of close icon button and outside of content area
      closeModalHandler = () => {
        this.setState({
          isPopShow: false
        });
      };

      // Duplicate/clone presentation
      clonePresentationHandler = async id => {
        const userID =
          this.props.userProfileMeta && this.props.userProfileMeta._id;

        // Clone presentation
        let response = await this.props.clonePresentation(userID, id);

        // Show success message on successfull clone
        ToastUtils.handleToast({
          operation: "success",
          message: "Presentation has been cloned successfully."
        });

        // redirect to cloned presentation
        let clonePresentationId = get(response, "data._id");
        clonePresentationId &&
          this.props.history.push(`/presentation/setup/${clonePresentationId}`);
      };

      /**
       * Delete presentation handler
       *
       * @param {*} id id of the presentation to be deleted
       */
      deletePresentationData = async id => {
        const userID =
          this.props.userProfileMeta && this.props.userProfileMeta._id;
        userID && (await deletePresentation(userID, id));
        this._fetchPresentationList();
      };

      // function to show last column in table
      showIcon = rowData => {
        return (
          <>
            <IconWrapper
              title={
                this._checkIfPresentationDownloadable(rowData) ? "Download" : ""
              }
              className="__test_download_btn"
            >
              {!this._checkIfPresentationDownloadable(rowData) ? (
                <DisabledDownloadIcon />
              ) : (
                <>
                  <CompletedDownload
                    onClick={e => {
                      this.props.loggingDownloadInDatabase({
                        downloadedSlides: map(rowData.slides, s=>s.slideId),
                        type: "downloadPresentation",
                        themeId: rowData.theme
                      });
                      this.downloadOverlayHandler(rowData)
                    }}
                  />
                </>
              )}
            </IconWrapper>
            <ShareIconWrapper title="Edit" className="__test_edit_btn">
              <EditWithNoShadow
                onClick={() => this.redirectOnEditPresentation(rowData._id)}
              />
            </ShareIconWrapper>
            <IconWrapper title="Delete" className="__test_delete_btn">
              <Delete
                onClick={() => {
                  DeleteConfirmationAlert({
                    onYesClick: () => {
                      this.deletePresentationData(rowData._id);
                    }
                  });
                }}
              />
            </IconWrapper>
            <CloneIconWrapper title="Clone" className="__test_clone_btn">
              <Duplicate
                onClick={() => this.clonePresentationHandler(rowData._id)}
              />
            </CloneIconWrapper>
            <IconWrapper
              title={rowData.status === "Completed" ? "Share" : ""}
              className="__test_share_btn"
            >
              {get(rowData, `status`) === "Saved" ? (
                <ShareIcon />
              ) : (
                <CompletedShare onClick={() => this.overlayHandler(rowData)} />
              )}
            </IconWrapper>
          </>
        );
      };

      renderHead = () => {
        const HELPER_TEXT =
          "Welcome to the Presentations Dashboard. You can add presentations by clicking “New” or you can download, edit, delete, duplicate and share presentations below.";
        return (
          <div className="heading">
            <HeadingName>Presentations</HeadingName>
            <HelperText>{HELPER_TEXT}</HelperText>
          </div>
        );
      };

      redirectOnEditPresentation = id =>
        this.props.history.push(`/presentation/setup/${id}`);

      downloadPresentationHandler = rowData => {
        let { pdfLocation, pptLocation } = rowData;
        let urlLocations = [pdfLocation, pptLocation];

        // object which has url specified in it
        let urlsToBeDownloaded = filter(urlLocations, obj => {
          return get(obj, `url.length`);
        });

        each(urlsToBeDownloaded, url => {
          let link = document.createElement("a");
          link.href = url.url;
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent("click"));
        });
      };

      render() {
        const $this = this;

        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };
        return <Main {...stateMethodProps} />;
      }
    }
  );

const SharedIconWrapperCss = css`
  padding: 0 8px;
  height: auto;
  display: inline-block;
  svg:hover {
    opacity: 0.7;
  }

  svg {
    height: 17px;
    cursor: pointer;
  }
`;

const IconWrapper = styled.span`
  ${SharedIconWrapperCss};
`;

const ShareIconWrapper = styled.span`
  ${SharedIconWrapperCss};
`;

const CloneIconWrapper = styled.span`
  ${SharedIconWrapperCss};
`;

const HelperText = styled.p`
  ${props => props.theme.SNIPPETS.HELPER_TEXT};
  margin-bottom: 16px;
`;

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`;

const SharedIconStyle = css`
  cursor: pointer;
  pointer-events: none;
`;

const DisabledDownloadIcon = styled(DownloadDisable)`
  ${SharedIconStyle}
`;

const ShareIcon = styled(shareDisable)`
  ${SharedIconStyle}
`;

const CompletedDownload = styled(DownloadColored)`
  cursor: pointer;
`;

const CompletedShare = styled(ShareColored)`
  cursor: pointer;
`;

export default Container;
