import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { get } from "lodash";
import { FadeLoader } from "react-spinners";

const Image = props => {
  let {
    getEachPageImage,
    eachPage,
    pageIndex,
    dataUriKey,
    imageKey,
    pageImageClickHandler,
    previewPageNo,
    // This props will be responsible for expanding the width to 100%
    setWidthOptionForPreview
  } = props;

  useEffect(() => {
    (async () => {
      // cal image api if data uri is not present or status is now changed to current but the final preview is not fetched or status is current and but final preview is not fetched for the preview panel(big preview)
      let isCallImageApi =
        (eachPage && !get(eachPage, `${dataUriKey}`) && getEachPageImage) ||
        (get(eachPage, `isStatusCurrent`) &&
          !get(eachPage, `isFinalPreviewFetched`)) ||
        get(eachPage, `isLargePreviewRendered`);

      if (isCallImageApi) {
        await getEachPageImage(
          get(eachPage, `${imageKey}`),
          pageIndex,
          dataUriKey,
          get(eachPage, `isStatusCurrent`)
        );
      }
    })();
  }, [eachPage, get(eachPage, `isStatusCurrent`)]);

  return (
    <>
      <ImageWrapper
        previewKey={imageKey === "previewKey"}
        thumbnailKey={imageKey === "thumbnailKey"}
        pageIndex={pageIndex}
        setWidthOptionForPreview={setWidthOptionForPreview === "true"}
        onClick={() => {
          pageImageClickHandler(pageIndex);
        }}
        isFinalPreviewFetched={get(eachPage, `isFinalPreviewFetched`)}
      >
        {get(eachPage, `${dataUriKey}`) && (
          <PageImage
            pageIndex={pageIndex}
            previewKey={imageKey === "previewKey"}
            thumbnailKey={imageKey === "thumbnailKey"}
            setWidthOptionForPreview={setWidthOptionForPreview === "true"}
            previewPageNo={previewPageNo}
            alt={get(eachPage, `name`)}
            src={get(eachPage, `${dataUriKey}`)}
          />
        )}

        {!get(eachPage, `isFinalPreviewFetched`) && (
        <>
        {get(eachPage, `buildStatus`) != "current" && (
          <LoaderWrapper imageKey={imageKey}>
            <FadeLoader
              sizeUnit={"px"}
              size="30"
              margin={"2px"}
              color={props.theme.COLOR.USER_PRIMARY}
            />
          </LoaderWrapper>
        )}
        </>
        )}
        {imageKey === "thumbnailKey" && (
          <PageTitle>{get(eachPage, `blockName`)}</PageTitle>
        )}
      </ImageWrapper>
    </>
  );
};

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
`;

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: ${props =>
    props.previewKey ? "641px" : !props.isFinalPreviewFetched && "0px"};
  min-width: ${props =>
    props.previewKey ? "100%" : !props.isFinalPreviewFetched && "243px"};
  cursor: pointer;
  top: 0;

  @media only screen and (min-width: 661px) {
    width: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "28.6rem" : "100%"};
    top: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "0rem" : "inherit"};
  }

  @media only screen and (min-width: 1121px) {
    width: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "28.6rem" : "100%"};
    top: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "0rem" : "inherit"};
  }

  @media only screen and (min-width: 1440px) {
    width: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "28.6rem" : "100%"};
    top: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "0rem" : "inherit"};
  }
  @media only screen and (min-width: 1920px) {
    width: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "37.7rem" : "100%"};
    top: ${props =>
      props.previewKey && !props.setWidthOptionForPreview ? "0rem" : "inherit"};
  }
`;

const PageImage = styled.img`
  width: ${props => (props.thumbnailKey ? "50%" : "100%")};
  height: auto;
  display: block;
  margin: auto;
  border: ${props =>
    props.pageIndex === props.previewPageNo &&
    props.thumbnailKey &&
    `3px solid ${props.theme.COLOR.USER_PRIMARY}`};

  @media only screen and (max-width: 991px) {
    width: ${props => (props.thumbnailKey ? "60%" : "100%")};
  }
`;

const PageTitle = styled.span`
  display: block;
  text-align: center;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin-top: 5px;
`;

export default withTheme(Image);
