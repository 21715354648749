import React from "react";
import { isEmpty } from "lodash";
import DraggableTemplateBlock from "./draggableTemplateBlock";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

function TemplateBlockContainer(props) {
  const {
    stateTemplateBlocks,
    removeBlockTemplate,
    onClickPreviewTemplateBlock,
    isActiveOnClickTemplateBlocks
  } = props;

  const columnId = "1";

  return (
    <Droppable droppableId={columnId} type="TASK">
      {(provided, snapshot) => (
        <ScrollbarTemplateArea
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {isEmpty(props.stateTemplateBlocks)
            ? ""
            : Object.values(stateTemplateBlocks).map((eachBlock, index) => {
                return (
                  <DraggableTemplateBlock
                    key={index}
                    index={index}
                    values={eachBlock}
                    removeBlockTemplate={removeBlockTemplate}
                    onClickPreviewTemplateBlock={onClickPreviewTemplateBlock}
                    isActiveOnClickTemplateBlocks={
                      isActiveOnClickTemplateBlocks
                    }
                  />
                );
              })}
          {provided.placeholder}
        </ScrollbarTemplateArea>
      )}
    </Droppable>
  );
}

TemplateBlockContainer.defaultProps = {
  templateBlocks: {
    customTemplateArray: {}
  }
};

const ScrollbarTemplateArea = styled.div``;

export default TemplateBlockContainer;
