import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
import clientConfigDetails from "config";

// import actions
import { onGetClientConfig, isUserLoginLoading } from "./action";

// Get client based data
let clientConfig = clientConfigDetails[process.env.REACT_APP_CLIENT] || {};

// getClientConfig is also a key in the client config file src/config/clientConfig.json
// @todo refactor this
//
const getClientConfig = (isLandingPage = false) => async dispatch => {
  // if there is a value for REACT_APP_CLIENT (it should be fatal error if not)
  console.debug('getClientConfig() entry.', {isLandingPage});
  console.debug('getClientConfig() REACT_APP_CLIENT', process.env.REACT_APP_CLIENT);
  // this uses REACT_APP_CLIENT simply as a flag
  // get the 'getClientConfig' value from src/config/clientConfig.json
  let bGetClientConfig = get(clientConfig, "getClientConfig", false);
  console.debug('getClientConfig() ', {bGetClientConfig});
  if (
    process.env.REACT_APP_CLIENT && bGetClientConfig
  ) {
    //dispatch action with client config so it ends up in the storee
    //at the key CLIENT_CONFIG_SUCCESS
    //note: this is dispatched >1x for some reason...
    //@todo figure out why and fix it
    console.debug('getClientConfig() dispatching onGetClientConfig (1)',{clientConfig});
    dispatch(
      onGetClientConfig({
// clientConfig cannot be 'false' due to its initialization above
// @todo why is it wrapped in an extra {} ?
//        clientConfig: clientConfig || {}
        clientConfig
      })
    );
    return;
  }

  // if we're here, there was a) no REACT_APP_CLIENT set, or b)
  // the getClientConfig key of clientConfig.json is false
  const URL = `/info${isLandingPage ? "?isLandingPage=true" : ""}`;
  console.debug('getClientConfig() ',{URL});

  // @todo what is this for?

  // start loading
  dispatch(isUserLoginLoading({ isClientConfigLoading: true }));

  const response = await FetchUtils.getData(URL, "get client detail");
  console.debug('getClientConfig() ',{response});

  //set client config details untill it is configured from Technical Admin/Syystem Admin
  let clientConfigDetails = {};
  if (clientConfig) {
    clientConfigDetails = {
      ...(clientConfig || {}),
      ...get(response, "data", {})
    };
  }
  // stop loading
  dispatch(isUserLoginLoading({ isClientConfigLoading: false }));

  if (response.success && response.data) {
    //dispatch datalist on success
    console.debug('getClientConfig() dispatching onGetClientConfig (2)',{clientConfigDetails});
    dispatch(
      onGetClientConfig({
        clientConfig: clientConfigDetails
      })
    );
  } else {
    // stop loading
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
    return response;
  }
};

export { getClientConfig };
