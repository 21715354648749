//import network services
import * as services from "./services";

const mapStateToProps = state => {
  const {
    SUCCESS_THEME_LIST,
    LOADING_THEME_LIST,
    PREVIEW_DATA_SUCCESS,
    TAGGING_DATA_SUCCESS,
    UPLOAD_PROGRESS
  } = state;
  return {
    ...SUCCESS_THEME_LIST,
    ...LOADING_THEME_LIST,
    ...PREVIEW_DATA_SUCCESS,
    ...TAGGING_DATA_SUCCESS,
    ...UPLOAD_PROGRESS
  };
};

const actions = {
  ...services
};

export { mapStateToProps, actions };
