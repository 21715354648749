import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

// import actions
import { onFontSuccess, isFontLoading } from "./actions";

/**
 *
 * @param {Sring} userID
 */
const getFontList = ispollingOn => async dispatch => {
  const URL = `/fonts`;

  // start loading
  dispatch(
    isFontLoading({
      isFontLoading: ispollingOn ? false : true
    })
  );

  const response = await FetchUtils.getData(URL, "get presentations data");

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onFontSuccess({
        fontData: response.data
      })
    );
    // stop loading
    dispatch(
      isFontLoading({
        isFontLoading: false
      })
    );
  } else {
    // stop loading
    dispatch(
      isFontLoading({
        isFontLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 * delete font
 *
 * @param {STRING} fontId
 */
const deleteFont = (fontId, isPollingTimeOut) => async dispatch => {
  let URL = `/fonts/${fontId}`;

  // start loading
  dispatch(
    isFontLoading({
      isFontLoading: isPollingTimeOut ? false : true
    })
  );

  const deleteResponse = await FetchUtils.deleteData(URL, "delete font");

  if (deleteResponse.success) {
    !isPollingTimeOut &&
      ToastUtils.handleToast({
        operation: "success",
        message: "Font has been deleted successfully."
      });
  } else {
    // stop loading
    dispatch(
      isFontLoading({
        isFontLoading: false
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(deleteResponse, "data.message")
    });
  }

  return deleteResponse;
};

/**
 * Post Form Data
 * @param {Object}  This object will have the keys depending upon the Steps selected. This body will be unique for different steps
 */
const createFont = (body, fontId) => async dispatch => {
  const URL = fontId ? `/fonts/${fontId}` : `/fonts`;

  // start loading
  dispatch(
    isFontLoading({
      isFontLoading: true
    })
  );

  const response = fontId
    ? await FetchUtils.patchData(URL, body, "Post Build Data ==>")
    : await FetchUtils.postData(URL, body, "Post Build Data ==>");

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onFontSuccess({
        fontData: response.data
      })
    );
  } else {
    // stop loading
    dispatch(
      isFontLoading({
        isFontLoading: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 *Upload font to aws
 *
 * @param {*} presignedUrl The presigned url where the content needs to be uploaded
 * @param {*} file File object
 */
const uploadFontToAws = (presignedUrl, file) => async dispatch => {
  const headers = {
    "Content-Type": file.type
  };

  const response = await FetchUtils.awsPutData(
    presignedUrl,
    file,
    headers,
    "Upload Font to aws"
  );

  // stop loading
  dispatch(
    isFontLoading({
      isFontLoading: false
    })
  );

  //  Stop loading if incorrect response
  if (!response.success) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  } else {
    ToastUtils.handleToast({
      operation: "success",
      message: "Your font has been successfully uploaded"
    });
  }

  return response;
};

export { getFontList, deleteFont, createFont, uploadFontToAws };
