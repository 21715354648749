import React from "react";
import styled from "styled-components";
import { get } from "lodash";

const ThemePreviewImage = props => {
  let { isSlidePreviewToggleOpen, currentActiveIndex, availableSlides } = props;
  let slideType =
    get(availableSlides[currentActiveIndex], `slideType`) === "PrimaryCover"
      ? "Primary Cover"
      : get(availableSlides[currentActiveIndex], `slideType`);
  let thumbnails = get(
    availableSlides[currentActiveIndex],
    `thumbnailLocation`
  );
  return (
    <ThemeImageContainer isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}>
      <ThemeImageWrapper isSlidePreviewToggleOpen={isSlidePreviewToggleOpen}>
        <HeadingWrapper>
          <CurrentTag>
            Currently tagged as: <TagDetails>{slideType}</TagDetails>
          </CurrentTag>
          <SlideText>{`Layout ${currentActiveIndex + 1} of ${
            availableSlides.length
          }`}</SlideText>
        </HeadingWrapper>
        <ImageWrapper>
          <ThemeImage
            src={
              get(
                availableSlides[currentActiveIndex],
                `thumbnailLocation.url`
              ) || thumbnails
            }
          ></ThemeImage>
          <SlideCount>
            {get(availableSlides[currentActiveIndex], `count`) || 1}
          </SlideCount>
        </ImageWrapper>
      </ThemeImageWrapper>
    </ThemeImageContainer>
  );
};

const ThemeImageContainer = styled.div`
  box-sizing: border-box;
  width: ${props =>
    props.isSlidePreviewToggleOpen ? `68.4%` : `calc(100% - 76px)`};
  height: 100%;
  padding-bottom: 20px;
  border-left: 1px solid ${props => props.theme.COLOR.FADED_GREY};
  display: inline-block;
  vertical-align: top;
  position: relative;
  transition: 0.4s width ease-in;
`;

const ThemeImageWrapper = styled.div`
  box-sizing: border-box;
  width: ${props => (props.isSlidePreviewToggleOpen ? 83 : 77)}%;
  height: 100%;
  margin: 0 auto;
  transition: width 0.5s ease-in;
`;

const ThemeImage = styled.img`
  width: 100%;
  border: 1px solid ${props => props.theme.COLOR_PALLETE.LIGHT_GREY};
  display: block;
  max-height: 320px;
`;

const CurrentTag = styled.p`
  margin: 12px 0 20px;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  color: ${props => props.theme.COLOR.HEADING};
  text-align: center;
`;

const TagDetails = styled.span`
  font-weight: bold;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const SlideCount = styled.span`
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 12px 0 0;
  border: 6px solid ${props => props.theme.COLOR.WHITE};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  background: ${props => props.theme.COLOR_PALLETE.LIGHT_GREY};
  color: ${props => props.theme.COLOR.WHITE};
  font-size: 20px;
  text-align: center;
`;

const SlideText = styled.span`
  margin: 12px 0 20px;
  color: ${props => props.theme.COLOR.HEADING};
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  font-weight: bold;
  opacity: 0.7;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ThemePreviewImage;
