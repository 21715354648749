import React from "react";
import styled from "styled-components";
import { map } from "lodash";
import BuiltinBlock from "./components/builtinBlock";
import hexToRgba from "utils/hexToRgba";
import { QuestionIconSM } from "assets/icons";

const BUILTIN_BLOCKS_NO_DATA_TEXT = "Currently no blocks available.";

const BuiltinCarousel = props => {
  let { builtinBlocks = [] } = props;
  return (
    <>
      <Heading>
        Built-in Blocks
        <QuestionIcon />
      </Heading>
      <PageWrapper>
        {!builtinBlocks.length && (
          <NoDataText>{BUILTIN_BLOCKS_NO_DATA_TEXT}</NoDataText>
        )}

        <BuiltinBlocks>
          {map(builtinBlocks, (eachBlock, index) => {
            return (
              <BuiltinBlock
                builtinBlockDetail={eachBlock}
                builtinBlockIndex={index}
                key={index}
                {...props}
              />
            );
          })}
        </BuiltinBlocks>
      </PageWrapper>
    </>
  );
};

const QuestionIcon = styled(QuestionIconSM)`
  height: 16px;
  margin-left: 10px;
`;

const Heading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin: 10px 0 10px 0;
  text-align: left;
`;

const NoDataText = styled.span`
  padding: 0 4px;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.COLOR.SECONDARY};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
`;

const PageWrapper = styled.div`
  width: 100%;
  padding: 22px 15px;
  border: 1px dashed ${props => props.theme.COLOR.MAIN};
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 25px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
    border-radius: 4px;
    outline: 1px solid ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
  }
`;

const BuiltinBlocks = styled.div`
  display: flex;
  justify-content: flex-start;

  & > :last-child:after {
    content: " ";
    position: absolute;
    width: 20px;
    height: 100%;
  }
`;

export default BuiltinCarousel;
