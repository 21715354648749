import { ContentBlockTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

// action creators
const REQUEST_BLOCK_CONTENT = (
  payload = {},
  type = ContentBlockTypes.REQUEST_BLOCK_CONTENT
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_BLOCK_CONTENT = (
  payload = {},
  type = ContentBlockTypes.RECEIVE_BLOCK_CONTENT
) => {
  return {
    type,
    payload
  };
};

const setBlockContentData = (
  contentData,
  contentBlockType = "glossaryContentBlock"
) => dispatch => {
  dispatch(
    RECEIVE_BLOCK_CONTENT({
      [contentBlockType]: contentData
    })
  );
};

const getBlockContents = (
  templateId,
  contentBlockId,
  contentBlockType = "glossaryContentBlock"
) => async dispatch => {
  const URL = `/summary/blocks/${contentBlockId}/content?templateId=${templateId}`;

  // start loading
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_BLOCK_CONTENT({
        [contentBlockType]: response.data
      })
    );

    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const getFilesContent = (
  userId,
  documentId,
  contentBlockId,
  contentBlockType = "appendixContentBlock"
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}/blocks/${contentBlockId}/files`;

  // start loading
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_BLOCK_CONTENT({
        [contentBlockType]: response.data
      })
    );

    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const deleteFilesData = (
  userId,
  documentId,
  contentBlockId,
  itemId,
  message,
  operationType
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}/blocks/${contentBlockId}/${itemId}`;

  // start loading
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isFileDeleteLoading: true
    })
  );

  const response = await FetchUtils.deleteData(URL);

  dispatch(
    REQUEST_BLOCK_CONTENT({
      isFileDeleteLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: operationType || "success",
      message: message || "Appendix has been deleted successfully."
    });

    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const patchFilesData = (
  userId,
  patchData,
  documentId,
  contentBlockId
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}/blocks/${contentBlockId}`;

  // start loading
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: true
    })
  );
  const response = await FetchUtils.patchData(
    URL,
    patchData,
    "patch Files Data"
  );
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: false
    })
  );

  if (!response.success || !response.data) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const saveContentBlockData = (
  userId,
  postData,
  documentId = null,
  blockId = null
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}`;
  // start loading
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: true
    })
  );
  const response = await FetchUtils.patchData(
    URL,
    postData,
    "patch document data"
  );
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: false
    })
  );

  if (!response.success || !response.data) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const updateBlockItem = (
  userId,
  documentId,
  blockId,
  itemId,
  data,
  message
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/items/${itemId}`;

  // show loader
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, data, "post user data");

  // hide loader
  dispatch(
    REQUEST_BLOCK_CONTENT({
      isContentItemsLoading: true
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: message || `Item has been updated successfully.`,
      autoclose: 3000
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

export {
  getBlockContents,
  setBlockContentData,
  getFilesContent,
  deleteFilesData,
  saveContentBlockData,
  updateBlockItem,
  patchFilesData
};
