// libraries
import React from "react";
import styled, { css } from "styled-components";
import { get, map, size } from "lodash";

// components
import StepDetails from "components/buildProcess/stepDetails";
import { PreviewBig, Close } from "assets/icons";
import handleBodyScroll from "utils/handleBodyScroll";
import hexToRgba from "utils/hexToRgba";
import Input from "components/inputField";
import DatePicker from "components/datePicker";

const renderAccordion = ({
  coverImageList,
  handleStateChange,
  handleCoverPreview,
  selectedCoverLayout
}) => {
  return (
    <StyledAccordion>
      {Array.isArray(coverImageList) &&
        coverImageList.length &&
        map(coverImageList, cover => {
          let { _id, url, name: title } = cover;
          return (
            <CoverImageWrapper key={_id}>
              <CoverImg
                src={url}
                isSelected={_id === selectedCoverLayout}
                title={title}
                onClick={() => {
                  if (_id !== selectedCoverLayout) {
                    handleStateChange({
                      key: "selectedCoverLayout",
                      value: _id
                    });
                    handleStateChange({
                      key: "coverDetails",
                      value: cover
                    });
                    handleStateChange({
                      key: "isDataEdited",
                      value: true
                    });
                  }
                }}
              />
              <PreviewIcon onClick={() => handleCoverPreview(url)} />
            </CoverImageWrapper>
          );
        })}
    </StyledAccordion>
  );
};

const Cover = props => {
  let {
    modifyStepHandler,
    activeStep,
    getCurrentTabDetails,
    handleStateChange,
    selectedCoverLayout,
    handleCoverPreview,
    showPreview,
    overlayCoverImage,
    onDocumentSaveData,
    coverImageList,
    fields,
    handleInputChange,
    imageGallery,
    inputFieldGallery
  } = props;

  const currentTab = getCurrentTabDetails();

  return (
    <>
      <StepDetails
        title={get(currentTab, `name`)}
        _next
        _previous={activeStep !== 0}
        onNext={async () => {
          let isMoveToNextStep = await onDocumentSaveData("cover");
          if (isMoveToNextStep) {
            modifyStepHandler(activeStep + 1, "pptTemplate");
          }
        }}
        onPrevious={() => modifyStepHandler(activeStep - 1)}
        description={get(currentTab, `description`) || ""}
      />
      {size(imageGallery) > 0 &&
        map(imageGallery, eachGallery => {
          return (
            <>
              {showPreview
                ? handleBodyScroll({ action: "open" })
                : handleBodyScroll({ action: "close" })}
              {showPreview && (
                <>
                  <Overlay onClick={handleCoverPreview}></Overlay>
                  <OverlayImageWrapper>
                    <OverlayCoverImg src={overlayCoverImage} />
                    <CloseIconWrapper>
                      <CloseIcon onClick={() => handleCoverPreview()} />
                    </CloseIconWrapper>
                  </OverlayImageWrapper>
                </>
              )}
              <PageWrapper>
                <CoverListWrapper>
                  <CoverList>
                    {renderAccordion({
                      coverImageList,
                      handleStateChange,
                      selectedCoverLayout,
                      handleCoverPreview
                    })}
                  </CoverList>
                </CoverListWrapper>
              </PageWrapper>
            </>
          );
        })}
      {size(inputFieldGallery) > 0 && (
        <>
          <FieldsWrapper>
            {map(fields, (eachField, index) => {
              let { label, type, fieldType, value } = eachField;

              const inputField = (
                <InputFieldWrapper key={index}>
                  {(type === "text" || type === "textarea") && (
                    <Input
                      index={index}
                      label={label}
                      type={type}
                      fieldType={fieldType}
                      name={label}
                      error={get(eachField, `error`)}
                      value={value || ""}
                      handleInputChange={e =>
                        handleInputChange(e.target.value, index, type)
                      }
                    />
                  )}
                  {type === "date" && (
                    <DatePickerContainer>
                      <DatePicker
                        className={"date-field"}
                        title={label}
                        toolTip={label}
                        value={new Date(value)}
                        handleChange={date => {
                          handleInputChange(date, index, type);
                        }}
                      />
                    </DatePickerContainer>
                  )}
                </InputFieldWrapper>
              );

              return inputField;
            })}
          </FieldsWrapper>
        </>
      )}
    </>
  );
};

Cover.defaultProps = {
  uploadFileType: "xls"
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 12;
`;

const OverlayImageWrapper = styled.div`
  width: 40%;
  min-width: 600px;
  z-index: 21;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`;

const OverlayCoverImg = styled.img`
  width: 100%;
  height: 100%;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const ExcelIcon = styled.img`
  width: 15px;
  height: auto;
  display: inline-block;
  margin-left: 13px;
  position: relative;
  top: 2px;
  right: 5px;
`;

const RatesWorkbookName = styled.span`
  font-size: 18px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR.HEADING};
  margin-bottom: 7px;
  display: inline-block;
`;

const CoverListWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const FieldsWrapper = styled.div`
  width: 40%;
  padding: 24px 41px;
  margin: 28px 0 50px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  background: ${props => props.theme.COLOR.WHITE};
`;

const InputFieldWrapper = styled.div`
  label {
    text-transform: capitalize;
  }
`;

const LabelCSS = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  opacity: 0.64;
  display: inline-block;
  margin-bottom: 6px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  cursor: pointer;
  display: block;
  text-align: left;
  font-size: ${props => props.labelFontSize || "10px"};
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
  vertical-align: top;
  &:nth-of-type(2) {
    margin-right: 20px;
  }

  .date-field {
    label:first-child {
      ${LabelCSS}
      margin-bottom: 0;
    }
    input {
      height: 30px;
      ${sharedInputStyle}
      padding: 0;
    }
    svg {
      top: 66%;
    }
  }
`;

const sharedInputStyle = css`
  box-sizing: border-box;
  display: block;
  width: 100%;
  resize: none;
  border-radius: 4px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  border: 1px solid ${props => props.theme.COLOR.INPUT_BORDER_COLOR};
  background: ${props => props.theme.COLOR.INPUT};
  &::placeholder {
    ${props => props.theme.SNIPPETS.FONT_STYLE};
    opacity: 0.4;
  }

  &:focus {
    outline: none;
  }
`;

const CoverList = styled.div``;

const StyledAccordion = styled.div`
  padding: 31px 0 34px;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const CoverImageWrapper = styled.div`
  width: 18.4%;
  padding: 1px;
  border: 5px solid transparent;
  margin: 0 2% 20px 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:nth-child(5n) {
    margin-right: 0;
  }
`;

const CoverImg = styled.img`
  width: 100%;
  display: block;
  padding: 5px;
  border: ${props =>
    props.isSelected ? `5px solid ${props.theme.COLOR.USER_PRIMARY}` : ""};
`;

const PreviewIcon = styled(PreviewBig)`
  width: 19px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;

const ThemeCover = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;
const NoThemeData = styled.div`
  ${ThemeCover}
`;

const NoCoverData = styled.div`
  width: 100%;
  ${ThemeCover};
  padding: 0 28px 34px;
`;

const ChooseFileWrapper = styled.div`
  width: ${props => (props.isRatesSingle ? "100%" : "20%")};
  margin-top: ${props => (props.isRatesSingle ? "25px" : "65px")};

  .btn-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;

    div:first-child {
      margin-right: ${props => !props.isRatesSingle && "0px"};
      margin-top: ${props => !props.isRatesSingle && "10px"};
    }
  }

  @media (max-width: 940px) {
    .filename {
      max-width: 117px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-start;
`;

const UploadedContent = styled.div`
  width: 79%;
  margin-right: 2%;
`;

export default Cover;
