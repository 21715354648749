import { TopicTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";
import { ConvertQueryParamsArrayObjectToString } from "utils/queryString";

export const onLibraryTopicSuccess = (
  payload = {},
  type = TopicTypes.SUCCESS_LIBRARY_TOPIC
) => {
  return {
    type,
    payload
  };
};

export const isLibraryTopicSearchLoading = (
  payload = {},
  type = TopicTypes.LOADING_LIBRARY_TOPIC_SEARCH
) => {
  return {
    type,
    payload
  };
};

const getLibraryByTopic = (
  contentRepoId,
  selectedTheme,
  { filters }
) => async dispatch => {
  const filtersList =
    (Array.isArray(filters) && filters.map(elem => ({ "filter[]": elem }))) ||
    [];

  const params = ConvertQueryParamsArrayObjectToString([...filtersList]);

  /**
   * moving the content-slides to v2 slide heirarchy
   */
  let URL;
  if (params) {
    URL = `/${contentRepoId}/${selectedTheme}/content-slides${
      params ? `?${params}` : ""
    }`;
  } else {
    URL = `/v2/${contentRepoId}/${selectedTheme}/content-slides`
  }
  // start loading
  dispatch(isLibraryTopicSearchLoading({ isTopicLoading: true }));

  const response = await FetchUtils.getData(
    URL,
    "Get Libray By Topic for Setup==>"
  );

  // stop loading
  dispatch(isLibraryTopicSearchLoading({ isTopicLoading: false }));

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onLibraryTopicSuccess({
        libraryByTopicList: response.data
      })
    );
  } else {
    // stop loading
    dispatch(isLibraryTopicSearchLoading({ isTopicLoading: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export { getLibraryByTopic };
