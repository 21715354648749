import React, { Component } from "react";
import styled from "styled-components";
import ValidationUtils from "utils/ValidationUtils";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

import { Delete, EditWithNoShadow } from "assets/icons";
import {
  getFilterList,
  reorderFilterList,
  createFilter,
  deleteFilter,
  editFilter,
  updateExistingFilter
} from "./services";
import { getContentRepoList } from "../admin/contentRepo/dashboard/services";

import { connect } from "react-redux";

import { get } from "lodash";

/**
 * Map the state to props.
 */
const mapStateToProps = state => {
  const {
    FILTERS_LIST_SUCCESS,
    FILTERS_LIST_LOADING,
    SUCCESS_CONTENT_REPO_LIST,
    LOADING_CONTENT_REPO_LIST
  } = state;

  return {
    ...FILTERS_LIST_SUCCESS,
    ...FILTERS_LIST_LOADING,
    ...SUCCESS_CONTENT_REPO_LIST,
    ...LOADING_CONTENT_REPO_LIST
  };
};

/** Icons Style */
const StyledIcon = styled.span`
  opacity: ${props => (props.enableLevel ? 1 : 0.5)};
  color: ${props => props.theme.COLOR.DEAD_BLACK};
  cursor: pointer;
  margin-left: ${props => (props.required ? "29px" : 0)};
`;

const EditIcon = styled(EditWithNoShadow)`
  width: 15px;
  height: 14px;
  margin-right: 15px;
  transform: translateY(1px);
`;

const DeleteIcon = styled(Delete)`
  width: 14px;
  height: 15px;
`;

/**
 * Error messages
 */
const EMPTY_FEILD_ERROR_MESSAGE = "This field is required.";
const SPECIAL_CHAR_ERROR_MESSAGE = "Please do not enter the special character.";
const WHITE_SPACE_ERROR_MESSAGE = "Please enter a valid input.";

const Container = Main =>
  connect(mapStateToProps, {
    getFilterList,
    reorderFilterList,
    getContentRepoList,
    createFilter,
    deleteFilter,
    editFilter,
    updateExistingFilter
  })(
    class Presentation extends Component {
      constructor(props) {
        super(props);

        this.state = {
          addNew: false,
          createFilterForm: {
            name: {
              value: "",
              errorMessage: ""
            },
            parent: {
              value: "",
              _id: ""
            },
            queryType: {
              value: "or"
            }
          },
          treeData: [],
          nodeIsMoved: true,
          categoryContainerHeight: 0,
          selectedValue: [],
          selectedDropdownId: "",
          filterSelected: "",
          selectedRepo: "",
          isFilterEdited: false,
          editedFilter: ""
        };

        this.categoryLevelContainerRef = React.createRef();
      }

      // fetch filter list
      fetchFilterList = async contentRepoId => {
        await this.props.getFilterList(contentRepoId);

        this.setState({
          treeData: this.props.filtersList || [],
          prevTreeData: this.props.filtersList || []
        });
        this.setCategoryContainerHeight();
      };

      // fetch content repo list
      fetchContentRepoList = async () => {
        await this.props.getContentRepoList(true);
      };

      // reorder data list
      reorderDataList = async (contentRepoId, body) => {
        await this.props.reorderFilterList(contentRepoId, body);

        // fetch data after reorder
        this.fetchFilterList(contentRepoId);
      };

      // create filter
      createContentFilter = async (contentRepoId, body) => {
        await this.props.createFilter(contentRepoId, body);

        // fetch data after creating new filter
        this.fetchFilterList(contentRepoId);

        // change node move value to false
        this.nodeMoveToggle(false);
      };

      //update existin filter
      updateExisting = async () => {
        const contentRepoId = this.getContentRepoId();

        await this.props.updateExistingFilter(
          contentRepoId,
          this.state.selectedDropdownId
        );

        // fetch data after creating new filter
        this.fetchFilterList(contentRepoId);
      };

      // trigger when drag stat changes
      onDragStateChanged = ({ isDragging }) => {
        if (isDragging) {
          window.addEventListener("dragover", this.scrollWindow);
        } else {
          window.removeEventListener("dragover", this.scrollWindow);
        }
      };

      // scroll window when dragged element reaches window height
      scrollWindow = e => {
        if (window && e.screenY > window.innerHeight) {
          window.scrollTo({
            top: e.screenY,
            behavior: "smooth"
          });
        }
      };

      // update filter
      updateContentFilter = async (contentFilterId, body) => {
        const contentRepoId = this.getContentRepoId();

        await this.props.editFilter(contentRepoId, contentFilterId, body);

        // fetch data after creating new filter
        this.fetchFilterList(contentRepoId);
      };

      // node is moved toggler
      nodeMoveToggle = flag => {
        this.setState({
          nodeIsMoved: flag
        });
      };

      // get content repo id
      getContentRepoId = () => {
        const contentRepoId =
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.contentRepoId
            ? this.props.match.params.contentRepoId
            : "";

        return contentRepoId;
      };

      componentDidMount() {
        const contentRepoId = this.getContentRepoId();
        contentRepoId && this.fetchFilterList(contentRepoId);

        // fetch content repo list
        this.fetchContentRepoList();
      }

      // generate actions for repo manager
      generateButtonNodeList = ({ node }) => {
        let { _id } = node;
        return [
          <StyledIcon title="Edit">
            <EditIcon
              size={15}
              onClick={() => this.setEditModuleDetails(node, _id)}
            />
          </StyledIcon>,
          <StyledIcon
            onClick={() =>
              DeleteConfirmationAlert({
                onYesClick: () => this.deleteCategoryModule({ _id })
              })
            }
            title="Delete"
          >
            <DeleteIcon size={15} />
          </StyledIcon>
        ];
      };

      //set edit details for module
      setEditModuleDetails = (node, _id) => {
        const { createFilterForm } = JSON.parse(JSON.stringify(this.state));
        let filterSelected = "";

        createFilterForm.name.value = node.title;
        if (!node.parent) {
          filterSelected = {
            value: "",
            label: "",
            _id: ""
          };
          createFilterForm.parent._id = "";
          createFilterForm.queryType.value = node.queryType;
        } else if (node.parent) {
          this.props.filtersList.forEach(item => {
            if (item._id === node.parent) {
              filterSelected = {
                value: item.title,
                label: item.title,
                _id: item._id
              };
              createFilterForm.parent._id = item._id;
            }
          });
        }

        this.setState({
          isFilterEdited: true,
          addNew: false,
          createFilterForm,
          filterSelected,
          editedFilter: node
        });
      };

      /**
       * Reorder and remap categories with modules
       * @param REORDER_TYPE - type module/category to distinguish API
       * @param treeData - Filters list
       */
      reorderModulesCategoryOnDragAndDrop = async (
        treeData,
        node,
        nextParentNode
      ) => {
        this.nodeMoveToggle(true);
        // exit if no tree data
        if (!treeData || (Array.isArray(treeData) && !treeData.length)) return;

        let REORDERED_DATA_LIST = [],
          body = {};
        if (!nextParentNode) {
          REORDERED_DATA_LIST = treeData.map(({ _id, title }) => {
            return {
              _id,
              title
            };
          });

          body = {
            children: [...REORDERED_DATA_LIST]
          };
        } else if (nextParentNode) {
          const { children: modulesList, _id: parentNode } =
            nextParentNode || {};

          REORDERED_DATA_LIST = modulesList.map(({ _id, title }) => {
            const moduleItem = {
              _id,
              title
            };

            return moduleItem;
          });

          body = {
            nextParent: parentNode,
            children: [...REORDERED_DATA_LIST]
          };
        }

        // callback for reorderDataList
        const contentRepoId = this.getContentRepoId();
        this.reorderDataList(contentRepoId, body);
      };

      // check if parent filter can be drop or not
      checkIfElementCanBeDropped = () => {
        let highLightedElement =
        document.getElementsByClassName("rst__highlightBottomLeftCorner")
          .length ||
        document.getElementsByClassName("rst__highlightTopLeftCorner")
          .length ||
        document.getElementsByClassName("rst__highlightLineVertical").length;
 
           if (highLightedElement) { return false } else {
             return true;
           };
       }

      /** delete module/category
       * @param _id - string defines unique id for record
       *
       */
      deleteCategoryModule = async ({ _id }) => {
        const contentRepoId = this.getContentRepoId();

        await this.props.deleteFilter(contentRepoId, _id);

        // fetch new data after deleting a filter
        this.fetchFilterList(contentRepoId);

        // change node move value to false
        this.nodeMoveToggle(false);
      };

      /**
       * Add new filter
       */
      onAddNewClick = () => {
        this.fetchFilterList(this.props.match.params.contentRepoId);
        this.setState({
          addNew: true,
          isFilterEdited: false,
          editedFilter: ""
        });

        // reset form values
        this.resetFormValues(true);
      };

      /**
       * Cancel add filter form
       */
      onCancelClick = () => {
        // call back for reset form values
        this.setState({
          isFilterEdited: false,
          editedFilter: ""
        });
        this.resetFormValues();
      };

      // reset create filter form values
      resetFormValues = (addNew = false) => {
        const { createFilterForm } = JSON.parse(JSON.stringify(this.state));

        Object.keys(createFilterForm).forEach(filter => {
          if (filter !== "queryType") {
            createFilterForm[filter].value = "";
            createFilterForm[filter].errorMessage = "";
            createFilterForm[filter]._id = "";
          } else if (filter === "queryType") {
            createFilterForm.queryType.value = "or";
          }
        });

        this.setState({
          createFilterForm,
          selectedValue: "",
          addNew,
          filterSelected: "",
          editedFilter: "",
          isFilterEdited: false
        });
      };

      /**
       * Create new filter
       */
      onSaveFilter = operation => {
        const { createFilterForm, editedFilter } = JSON.parse(
          JSON.stringify(this.state)
        );

        // check name value is present
        this.checkFilterInput(createFilterForm.name.value);
        let saveFlag = false;

        if (
          !createFilterForm.name.errorMessage &&
          createFilterForm.name.value
        ) {
          saveFlag = true;
        }

        if (operation === "Save") {
          if (saveFlag) {
            const body = {
              title: createFilterForm.name.value,
              parent: createFilterForm.parent._id || null,
              ...(!createFilterForm.parent._id && {
                queryType:
                  createFilterForm.queryType.value || editedFilter.queryType
              })
            };
            const contentRepoId = this.getContentRepoId();

            // call back for create filter
            this.createContentFilter(contentRepoId, body);
          }
        } else {
          // Save only if id is present
          if (!editedFilter._id || !saveFlag) return;

          this._setErrorOnSave({
            name: createFilterForm.name.value
          });

          const body = {
            title: createFilterForm.name.value,
            parent: editedFilter.parent || null,
            ...((editedFilter.parent || createFilterForm.parent._id) &&
              editedFilter.parent !== createFilterForm.parent._id && {
                nextParent: createFilterForm.parent._id || null
              }),
            ...(!createFilterForm.parent._id && {
              queryType: createFilterForm.queryType.value
            })
          };

          // call back for update content filter
          this.updateContentFilter(editedFilter._id, body);
        }
        // reset form values
        this.resetFormValues(true);
      };

      _setErrorOnSave = ({ name }) => {
        let { createFilterForm } = this.state;
        createFilterForm.name.errorMessage = this.checkFormValidation(name);
        this.setState({
          createFilterForm
        });
      };

      /**
       * Function to validate add filter form fields
       */
      checkFormValidation = value => {
        if (ValidationUtils.checkIfEmptyField(value)) {
          return EMPTY_FEILD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return WHITE_SPACE_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(value)) {
          return SPECIAL_CHAR_ERROR_MESSAGE;
        } else {
          return null;
        }
      };

      /**
       * Check add filter form inputs
       */
      checkFilterInput = value => {
        const errorMessage = this.checkFormValidation(value);
        const { createFilterForm } = JSON.parse(JSON.stringify(this.state));

        createFilterForm.name.value = value;
        createFilterForm.name.errorMessage = errorMessage ? errorMessage : "";

        this.setState({
          createFilterForm
        });
      };

      //Update value on Radio Button Change
      changeHandler = id => {
        const { createFilterForm } = JSON.parse(JSON.stringify(this.state));
        createFilterForm.queryType.value = id;
        this.setState({ createFilterForm });
      };

      /**
       * Manage dropdown input value
       */
      onDropdownChange = selectedOption => {
        const {
          createFilterForm,
          editedFilter,
          filterSelected,
          isFilterEdited
        } = JSON.parse(JSON.stringify(this.state));
        const { value, _id } = selectedOption || {};

        createFilterForm.parent.value = value;
        createFilterForm.parent._id = _id || "";

        if (isFilterEdited) {
          if (
            editedFilter.parent === (filterSelected && filterSelected._id) &&
            editedFilter.assignedFilter &&
            selectedOption === null
          ) {
            DeleteConfirmationAlert({
              message: `Moving this level up will remove all associations with Categories/Slides.
          Do you want to proceed?' Clicking on "Yes" will remove filter associations from Categories/Slides`,
              onYesClick: () => {
                this.setState({
                  createFilterForm
                });
              },
              onNoClick: () => {
                this.setState({
                  filterSelected: filterSelected
                });
              },
              onClose: () => {
                this.setState({
                  filterSelected: filterSelected
                });
              }
            });
          } else {
            this.setState({
              createFilterForm,
              filterSelected: selectedOption
            });
          }
        } else {
          this.setState({
            createFilterForm
          });
        }
        this.setState({
          filterSelected: selectedOption
        });
      };

      /**
       * select existing filter dropdown changes
       */
      onExistingFilterChange = async selectedOption => {
        if (selectedOption) {
          let response = await this.props.getFilterList(selectedOption._id);

          if (response.success) {
            this.setState({
              selectedValue: response.data || [],
              selectedDropdownId: selectedOption._id,
              selectedRepo: selectedOption.value
            });
          }
        } else {
          this.setState({
            selectedValue: [],
            selectedRepo: ""
          });
        }
      };

      // handle when node is moved for category and modules
      onMoveNode = ({ treeData, node, nextParentNode }) => {
        node.parent = (nextParentNode || {})._id
          ? (nextParentNode || {})._id
          : null;

        this.setState({
          treeData
        });

        this.nodeMoveToggle(true);
        // Show association popup only if the moved filter has a flag assigned filter true
        // and if it is made a parent node
        if (node.assignedFilter && !nextParentNode) {
          DeleteConfirmationAlert({
            message: `Moving this level up will remove all associations with Categories/Slides.
            Do you want to proceed?' Clicking on "Yes" will remove filter associations from Categories/Slides`,
            onYesClick: () => {
              this.reorderModulesCategoryOnDragAndDrop(
                treeData,
                node,
                nextParentNode
              );
              this.setState({
                treeData,
                prevTreeData: treeData
              });
            },
            onNoClick: () => {
              this.setState({
                treeData: this.state.prevTreeData
              });
            },
            onClose: () => {
              this.setState({
                treeData: this.state.prevTreeData
              });
            }
          });
        } else {
          this.reorderModulesCategoryOnDragAndDrop(
            treeData,
            node,
            nextParentNode
          );
        }
      };

      handleTreeOnChange = treeData => {
        this.setState({
          treeData
        });

        this.setCategoryContainerHeight();
      };

      setCategoryContainerHeight = () => {
        // No method found when sortable tree is triggered, that's why used setInterval
        const checkOverflowContainer = setInterval(() => {
          if (
            get(
              this.categoryLevelContainerRef.current,
              "firstChild.firstChild.firstChild.firstChild.firstChild"
            )
          ) {
            this.setState({
              categoryContainerHeight: get(
                this.categoryLevelContainerRef.current,
                "firstChild.firstChild.firstChild.firstChild.firstChild.clientHeight"
              )
            });

            clearInterval(checkOverflowContainer);
          }
        }, 100);
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props,
          setCategoryContainerHeight: this.setCategoryContainerHeight
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
