import React from "react";
import Stepper from "components/buildProcess/stepper";
import { get, includes } from "lodash";
import styled from "styled-components";
import FullPageLoader from "components/FullPageLoader";
import { Prompt } from "react-router";

// components
import Appendix from "./components/appendix/container";
import Topics from "./components/topics/container";
import Preview from "./components/preview/container";
import Rates from "./components/rates/container";
import Cover from "./components/cover/container";
import HtmlTemplateUI from "./components/htmlTemplateUI/container";
import Documents from "./components/documents/container";
import UI_STRINGS from "utils/stringConstants";
import { Link } from "react-router-dom";

const SwitchComponentByStep = props => {
  const { activeStep, stepItems } = props;

  let stepperComponents = {
    appendixPageSet: <Appendix {...props} />,
    preview: <Preview {...props} />,
    layout: <Topics {...props} />,
    datatable: <Rates {...props} />,
    pptTemplate: <Cover {...props} />,
    htmlTemplate: <HtmlTemplateUI {...props} />,
    document: <Documents {...props} />
  };

  let componentType = get(stepItems, [activeStep, `stepType`]);
  let Component = stepperComponents[componentType];
  return Component ? Component : null;
};

const SummaryMakerComponent = props => {
  let {
    isTemplateBlocksLoading,
    isCurrentDocumentLoading,
    isContentItemsLoading,
    isPreviewLoading,
    isFileDeleteLoading,
    isTemplateListLoading
  } = props;

  let isShowFullPageLoader =
    isTemplateBlocksLoading ||
    isCurrentDocumentLoading ||
    isContentItemsLoading ||
    isPreviewLoading ||
    isTemplateListLoading ||
    isFileDeleteLoading;

  const breadCrumbs = () => {
    const { templateId } = props.match.params;
    const { selectedTemplate } = props;
    return (
      <LinkContainer>
        <StyledLink to={`/summarymaker/templates`}>Templates/</StyledLink>
        <StyledLink to={`/summarymaker/template/create/${templateId}`}>
          {selectedTemplate && selectedTemplate.name}
        </StyledLink>
      </LinkContainer>
    );
  };

  const { stepItems, modifyStepHandler, activeStep, isDataEdited } = props;
  const userRole = get(props, "userProfileMeta.roles");
  return (
    <>
      {isShowFullPageLoader && <FullPageLoader layerIndex={5} />}
      {isDataEdited && (
        <Prompt when={isDataEdited} message={UI_STRINGS.UNSAVED_WORK_PROMPT} />
      )}
      <BuildPageWrapper>
        <Stepper
          stepItems={stepItems}
          activeStep={activeStep}
          modifyStep={modifyStepHandler}
        />

        <TabContentContainer>
          {includes(userRole, "Summary Maker Admin") &&
            props.match.params.templateId &&
            breadCrumbs()}
          <SwitchComponentByStep {...props} />
        </TabContentContainer>
      </BuildPageWrapper>
    </>
  );
};

const BuildPageWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
  .step {
    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }
`;

const TabContentContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }
  .content {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

const LinkContainer = styled.span`
  position: relative;
  top: 8px;
`;

const InteractivityBreadCrumb = styled.span``;

const StyledLink = styled(Link)`
  color: ${props => props.theme.COLOR.PRIMARY_LIGHT};
  text-transform: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default SummaryMakerComponent;
