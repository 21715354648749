import { TemplateCreationTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";
import uniqId from "uniqid";

const REQUEST_TEMPLATE_BLOCK_CLIENT = (
  payload = null,
  type = TemplateCreationTypes.REQUEST_TEMPLATE_BLOCK_CLIENT
) => {
  return {
    type,
    payload
  };
};
const CREATE_TEMPLATE_PREVIEW_RENDER = (
  payload = null,
  type = TemplateCreationTypes.CREATE_TEMPLATE_PREVIEW_RENDER
) => {
  return {
    type,
    payload
  };
};
const RECEIVE_TEMPLATE_BLOCKS_ADMIN = (
  payload = null,
  type = TemplateCreationTypes.RECEIVE_TEMPLATE_BLOCKS_ADMIN
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_TEMPLATE_BLOCK_CONTENT = (
  payload = null,
  type = TemplateCreationTypes.RECEIVE_TEMPLATE_BLOCK_CONTENT
) => {
  return {
    type,
    payload
  };
};

const REQUEST_DATA = (
  payload = {},
  type = TemplateCreationTypes.REQUEST_TEMPLATE_BLOCKS_ADMIN
) => {
  return {
    type,
    payload
  };
};

const REQUEST_TEMPLATE_UPDATE = (
  payload = {},
  type = TemplateCreationTypes.REQUEST_TEMPLATE_UPDATE
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_ACTIVE_TEMPLATE = (
  payload = null,
  type = TemplateCreationTypes.RECEIVE_ACTIVE_TEMPLATE
) => {
  return {
    type,
    payload
  };
};

const REQUEST_ACTIVE_TEMPLATE = (
  payload = null,
  type = TemplateCreationTypes.REQUEST_ACTIVE_TEMPLATE
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_BLOCK_FUNCTIONS = (
  payload = [],
  type = TemplateCreationTypes.RECEIVE_BLOCK_FUNCTIONS
) => {
  return {
    type,
    payload
  };
};

const REQUEST_GET_COVER_IMAGE_DATA = (
  payload = null,
  type = TemplateCreationTypes.REQUEST_GET_COVER_IMAGE_DATA
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_COVER_IMAGE_DATA = (
  payload = null,
  type = TemplateCreationTypes.RECEIVE_COVER_IMAGE_DATA
) => {
  return {
    type,
    payload
  };
};

const setCustomBlocks = (blockType, data) => dispatch => {
  dispatch(
    RECEIVE_TEMPLATE_BLOCKS_ADMIN({
      [blockType]: data
    })
  );
};

/**
 * get template blocks
 *
 * @param {boolean} [isBuiltinBlock=false]
 * @param {string} [blockType="builtinBlocks"]
 * @param {Boolean} showLoader
 */
const getTemplateBlocks = (
  isBuiltinBlock = false,
  blockType = "builtinBlocks",
  showLoader
) => async dispatch => {
  const URL = `/summary/blocks${isBuiltinBlock ? "?builtin=true" : ""}`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: showLoader
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_TEMPLATE_BLOCKS_ADMIN({
        [blockType]: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

// Get template block admin side
const getTemplateBlocksAdmin = (
  templateId,
  showLoader = true
) => async dispatch => {
  const URL = `/summary/templates/${templateId}`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: showLoader
    })
  );

  if (response.success && response.data) {
    let modifiedIdBlocksData = [];
    if (response.data.blocks != undefined) {
      modifiedIdBlocksData = response.data.blocks.map(data => ({
        ...data,
        modifiedId: uniqId()
      }));
    }

    let modifiedData = { ...response.data, blocks: modifiedIdBlocksData };
    dispatch(
      RECEIVE_TEMPLATE_BLOCKS_ADMIN({
        templateBlocks: modifiedData.blocks
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message") + " Please use another template"
    });
  }
};

const setCustomTemplateBlock = dispatch => {
  dispatch(REQUEST_TEMPLATE_BLOCK_CLIENT);
};

const createTemplatePreviewRender = dispatch => {
  dispatch(CREATE_TEMPLATE_PREVIEW_RENDER);
};

/**
 * template creation functiom
 * @param {*} postData
 */
const createTemplate = (postData, templateId = null) => async dispatch => {
  const URL = templateId
    ? `/summary/templates/${templateId}`
    : `/summary/templates`;

  // start loading
  dispatch(
    REQUEST_ACTIVE_TEMPLATE({
      isCreatingTemplate: true
    })
  );

  const response = templateId
    ? await FetchUtils.patchData(URL, postData, "patch document data")
    : await FetchUtils.postData(URL, postData, "post document data");

  // start loading
  dispatch(
    REQUEST_ACTIVE_TEMPLATE({
      isCreatingTemplate: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Template created successfully."
    });
    dispatch(
      RECEIVE_ACTIVE_TEMPLATE({
        currentTemplateData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const updateTemplateBlock = (
  blockId,
  templateId,
  data,
  removeVariable
) => async dispatch => {
  const URL = `/summary/blocks/${blockId}?templateId=${templateId}${
    removeVariable ? "&removeVariable=" + removeVariable : ""
  }`;

  // show loader
  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: true
    })
  );

  const response = await FetchUtils.patchData(URL, data, "post user data");

  // hide loader
  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: `Block has been updated successfully.`,
      autoclose: 3000
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const updateCustomBlock = (blockId, data) => async dispatch => {
  const URL = `/summary/blocks/${blockId}`;

  // show loader
  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: true
    })
  );

  const response = await FetchUtils.patchData(URL, data, "post user data");

  // hide loader
  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: `Block has been updated successfully.`,
      autoclose: 3000
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

/**
 * get the files/content related to a block in a template
 *
 * @param {*} blockId
 * @param {string} [blockType="appendixPageSet"]
 */
const getCoverImageData = (blockId, templateId) => async dispatch => {
  const URL = `/summary/blocks/${blockId}/content?templateId=${templateId}`;

  // start loading
  dispatch(
    REQUEST_GET_COVER_IMAGE_DATA({
      isTemplateUpdating: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_GET_COVER_IMAGE_DATA({
      isTemplateUpdating: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_COVER_IMAGE_DATA({
        coverImageData: response.data
      })
    );
    return response.data
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

/**
 * get the files/content related to a block in a template
 *
 * @param {*} blockId
 * @param {string} [blockType="appendixPageSet"]
 */
const getTemplateBlockContent = (
  blockId,
  blockType = "appendixPageSet",
  templateId
) => async dispatch => {
  const URL = `/summary/blocks/${blockId}/content?templateId=${templateId}&config=true`;

  // start loading
  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_TEMPLATE_BLOCK_CONTENT({
        [blockType]: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

/**
 * get all the functions (etl and ingest)
 *
 */
const getBlockFunctions = () => async dispatch => {
  const URL = `/summary/blockfunction`;

  // start loading
  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_TEMPLATE_UPDATE({
      isTemplateUpdating: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_BLOCK_FUNCTIONS({
        blockFunctions: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

export {
  getTemplateBlocks,
  setCustomBlocks,
  createTemplate,
  updateTemplateBlock,
  updateCustomBlock,
  setCustomTemplateBlock,
  createTemplatePreviewRender,
  getTemplateBlockContent,
  getTemplateBlocksAdmin,
  getBlockFunctions,
  getCoverImageData
};
