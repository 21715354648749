// libraries
import React from "react";
import styled from "styled-components";
import { get } from "lodash";

// components
import ModuleSelector from "./components/moduleSelector/container";
import StepDetails from "components/buildProcess/stepDetails";
import LayoutSelector from "./components/layoutSelector/container";

const Topics = props => {
  let {
    modifyStepHandler,
    activeStep,
    onDocumentSaveData,
    getCurrentTabDetails
  } = props;
  const currentTab = getCurrentTabDetails();

  return (
    <>
      <StepDetails
        title={get(currentTab, `name`)}
        description="You may customize content pages in here."
        _next
        _previous={activeStep !== 0}
        onNext={async () => {
          let isMoveToNextStep = await onDocumentSaveData("layout");
          if (isMoveToNextStep) {
            modifyStepHandler(activeStep + 1, "layout");
          }
        }}
        onPrevious={() => modifyStepHandler(activeStep - 1)}
        _save
        onSave={() => onDocumentSaveData("layout")}
      />
      <ContentWrapper>
        <ModuleSelector {...props} />
        <LayoutSelector {...props} />
      </ContentWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  height: 38rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
`;

export default Topics;
