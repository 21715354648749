import React, { Component } from "react";
import { each, isEqual, get, find, filter, map } from "lodash";
import { connect } from "react-redux";

//utils
import PollingUtils from "utils/PollingUtils";
import ToastUtils from "utils/handleToast";

// components
import Appendix from "./index";
import blobToDataUri from "utils/blobToDataUri";

// services
import {
  getBlockContents,
  setBlockContentData,
  updateBlockItem,
  patchFilesData
} from "tools/summaryMaker/store/actions/contentBlockActions";

import { getImage } from "tools/summaryMaker/store/actions/previewBuildActions";

import {
  uploadFile,
  uploadFileToAws
} from "tools/summaryMaker/store/actions/uploadFileActions";

const mapStateToProps = state => {
  const {
    domain: {
      REQUEST_BLOCK_CONTENT,
      RECEIVE_BLOCK_CONTENT,
      RECEIVE_TEMPLATE_BLOCKS
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...REQUEST_BLOCK_CONTENT,
    ...RECEIVE_BLOCK_CONTENT,
    ...RECEIVE_TEMPLATE_BLOCKS,
    ...SUCCESS_USER_PROFILE
  };
};

const mapDispatchToProps = {
  getBlockContents,
  setBlockContentData,
  uploadFile,
  uploadFileToAws,
  getImage,
  updateBlockItem,
  patchFilesData
};

class AppendixPage extends Component {
  state = {
    includeDividerValue: false,
    isLayoutTypeThumbnail: true,
    isAnyFileNameEdited: false,
    activeFileId: null,
    pdfFiles: [],
    noOfFilesAllowed: null
  };

  componentWillUnmount() {
    PollingUtils.stopPolling();
  }

  componentDidMount() {
    let { selectedTemplate } = this.props;
    selectedTemplate.blocks.map(eachBlock => {
      if (eachBlock.name.toLowerCase() === "appendix") {
        this.setState({
          noOfFilesAllowed: eachBlock.maxDocNumber
        });
      }
    });
    this._formatPdfFiles();
    this.handlePreviewPolling();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.appendixContentBlock, this.props.appendixContentBlock)
    ) {
      this._formatPdfFiles();
    }
  }

  updatePdfFilesData = pdfFiles => {
    this.setState({
      pdfFiles
    });
  };

  _formatPdfFiles = () => {
    let { appendixContentBlock } = this.props;

    each(appendixContentBlock, eachFile => {
      //attach isDraggable for DnD
      eachFile.isDraggable = true;
    });

    this.setState({
      pdfFiles: appendixContentBlock
    });
  };

  getContentBlockId = () => {
    const templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    const contentBlockId = (
      find(templateBlocks, eachBlock => {
        return get(eachBlock, `type`) === "appendixPageSet";
      }) || {}
    )._id;

    return contentBlockId;
  };

  uploadFileToRepo = async ({ metaDeta, file }, cb) => {
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = this.getContentBlockId();

    delete metaDeta.resource;
    delete metaDeta.title;

    let postData = {
      ...metaDeta,
      fileType: "pdf",
      name: get(metaDeta, `fileName`)
        .split(".")
        .slice(0, -1)
        .join(".")
    };

    let response = await this.props.uploadFile(
      userId,
      postData,
      documentId,
      contentBlockId
    );

    let { presignedUrl = "" } = get(response, `data`);

    let awsResponse = await this.props.uploadFileToAws(presignedUrl, file);

    if (get(awsResponse, `status`) !== 200) {
      await this.props.deleteFile(
        get(response, `data._id`),
        "Error uploading appendix.",
        "error"
      );
      cb();

      return;
    }

    await this.props.fetchFilesData(
      userId,
      contentBlockId,
      "appendixPageSet",
      documentId,
      true
    );

    // empty previewPageDetails to again start build
    this.props.handleStateChange({
      key: "previewPageDetails",
      value: []
    });

    cb();

    this.handlePreviewPolling();
  };

  handlePreviewPolling = () => {
    const uploadedFiles = this.props.appendixContentBlock;

    let isAnyFileStatusUnknown = filter(uploadedFiles, eachFile => {
      return get(eachFile, `buildStatus`) !== "current";
    });

    if (isAnyFileStatusUnknown.length) {
      // start polling
      PollingUtils.startPolling({
        pollingAction: () => {
          this.startPollingForPreview();
        },
        timeoutCallback: () => {
          PollingUtils.stopPolling();
          ToastUtils.handleToast({
            operation: "error",
            message: "Preview is taking too long. Please try again later."
          });
        }
      });
    }
  };

  startPollingForPreview = async () => {
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = this.getContentBlockId();
    const { pdfFiles } = this.state;
    const { deleteFile } = this.props;

    await this.props.fetchFilesData(
      userId,
      contentBlockId,
      "appendixPageSet",
      documentId,
      true
    );

    const uploadedFiles = this.props.appendixContentBlock;
    const status = map(uploadedFiles, eachFile => {
      return get(eachFile, `buildStatus`);
    });

    this.updateFileStatus();
    // Also Stop the Polling if the API data provided by the backend gives a buildStatus of "exceed"
    const pdfFilesLength = pdfFiles.length;
    const pdfFilesStatus = get(pdfFiles[pdfFilesLength - 1], `buildStatus`);
    if (pdfFilesStatus === "exceed") {
      const message = "No of pages exceed the limit";
      const operation = "error";
      PollingUtils.stopPolling();
      deleteFile(pdfFiles[pdfFiles.length - 1]._id, message, operation);
    }
    if (status.every(value => value === "current")) {
      PollingUtils.stopPolling();
    }
  };

  updateFileStatus = () => {
    const { appendixContentBlock } = this.props;
    const { pdfFiles } = this.state;

    let filesWithCurrentStatus = filter(appendixContentBlock, eachFile => {
      return get(eachFile, `buildStatus`) === "current";
    });

    each(filesWithCurrentStatus, fileWithCurrentStatus => {
      each(pdfFiles, eachFile => {
        if (get(fileWithCurrentStatus, `_id`) === get(eachFile, `_id`)) {
          eachFile["isStatusCurrent"] = true;
        }
      });
    });

    this.setState({
      pdfFiles
    });
  };

  getImageHandler = async (URL, imageIndex, isFinalPreviewFetched) => {
    let { pdfFiles } = this.state;
    let { appendixContentBlock } = this.props;

    let response = await this.props.getImage(URL);

    const cb = base64Data => {
      pdfFiles[imageIndex]["dataUri"] = base64Data;
      pdfFiles[imageIndex]["isFinalPreviewFetched"] =
        appendixContentBlock[imageIndex]["buildStatus"] === "current"
          ? true
          : isFinalPreviewFetched;

      this.setState({
        pdfFiles
      });

      return base64Data;
    };

    blobToDataUri(get(response, `data`), { type: "image/jpeg" }, cb);
  };

  // called on click of files to handle delete functionality
  deleteFileHandler = activeFileId => {
    this.setState({
      activeFileId
    });
  };

  saveInputValueHandler = async (value, itemId) => {
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = this.getContentBlockId();
    let data = {
      name: value
    };

    await this.props.updateBlockItem(
      userId,
      documentId,
      contentBlockId,
      itemId,
      data,
      "Appendix title has been updated successfully."
    );

    await this.props.fetchFilesData(
      userId,
      contentBlockId,
      "appendixPageSet",
      documentId,
      true
    );
  };

  /**
   * set flag stating if any name name is edited
   * @param {Boolean} isAnyFileNameEdited whose value needs to be checked
   */
  setFileNameEditedFlag = isAnyFileNameEdited => {
    this.setState({
      isAnyFileNameEdited
    });
  };

  handleIncludeDividerCheckbox = () => {
    this.setState({
      includeDividerValue: !this.state.includeDividerValue
    });
  };

  // called on layout change list or grid
  onChangeLayoutType = isLayoutTypeThumbnail => {
    this.setState({
      isLayoutTypeThumbnail,
      activeFileId: null
    });
    this.setFileNameEditedFlag(false);
  };

  /**
   * called on every drag and drop
   * @param {*} pdfFiles new updated list data
   */
  onListUpdate = pdfFiles => {
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = this.getContentBlockId();
    const pdfFilesOnlyIds = pdfFiles.map(ids => ids._id);
    const contentBlock = { items: pdfFilesOnlyIds };
    this.setState({
      pdfFiles
    });

    this.props.handleStateChange({
      key: "isDataEdited",
      value: true
    });

    this.props.setBlockContentData(pdfFiles, "appendixContentBlock");
    this.props.patchFilesData(userId, contentBlock, documentId, contentBlockId);
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Appendix {...stateMethodProps} />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppendixPage);
