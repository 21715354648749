import { DashboardTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

// action creators
const setSelectedTemplate = (
  payload = null,
  type = DashboardTypes.SELECTED_TEMPLATE
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_TEMPLATE_LIST = (
  payload = null,
  type = DashboardTypes.RECEIVE_TEMPLATE_LIST
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_DOCUMENT_DATA = (
  payload = null,
  type = DashboardTypes.RECEIVE_DOCUMENT_DATA
) => {
  return {
    type,
    payload
  };
};

const REQUEST_DATA = (payload = {}, type = DashboardTypes.REQUEST_DATA) => {
  return {
    type,
    payload
  };
};

const RECEIVE_DOCUMENT_DASHBOARD = (
  payload = null,
  type = DashboardTypes.RECEIVE_DOCUMENT_DASHBOARD
) => {
  return {
    type,
    payload
  };
};

const setDocumentData = documentData => dispatch => {
  dispatch(
    RECEIVE_DOCUMENT_DATA({
      documentData
    })
  );
};

const getSelectedTemplate = selectedTemplate => dispatch => {
  dispatch(
    setSelectedTemplate({
      selectedTemplate
    })
  );
};

const getTemplateList = () => async dispatch => {
  const URL = `/summary/templates`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_TEMPLATE_LIST({
        templateList: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const getSingleTemplateList = templateId => async dispatch => {
  const URL = `/summary/templates?templateId=${templateId}`;
  // // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    if (response) {
      dispatch(
        RECEIVE_TEMPLATE_LIST({
          templateList: response.data
        })
      );
    } else {
      ToastUtils.handleToast({
        operation: "error",
        message: get(response, "data.message")
      });
    }
  }
};

const getDocumentList = userId => async dispatch => {
  const URL = `/summary/users/${userId}/documents`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_DOCUMENT_DASHBOARD({
        documentDashboardData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

/**
 * delete document
 *
 * @param {String} userId
 * @param {STRING} presentationId
 */
const deleteDocument = (userId, documentId) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );
  const response = await FetchUtils.deleteData(URL, "delete presentatio");

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Document has been deleted successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const deleteTemplateFromAdminDashboard = templateId => async dispatch => {
  const URL = `/summary/templates/${templateId}`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );
  const response = await FetchUtils.deleteData(URL, "delete presentation");

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Template has been deleted successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

/**
 * clone document
 *
 * @param {String} userId
 * @param {STRING} documentId
 */
const cloneDocument = (userId, documentId) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );
  const response = await FetchUtils.postData(URL, "clone document");

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Document has been cloned successfully."
    });

    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

export {
  getSelectedTemplate,
  getTemplateList,
  getDocumentList,
  deleteDocument,
  cloneDocument,
  setDocumentData,
  getSingleTemplateList,
  deleteTemplateFromAdminDashboard
};
