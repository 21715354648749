import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Close } from "assets/icons";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import { Draggable } from "react-beautiful-dnd";

class DraggableTemplateBlock extends React.Component {
  changePreview = event => {
    const { values } = event;
    const { onClickPreviewTemplateBlock } = this.props;
    onClickPreviewTemplateBlock(values);
    this.setState({
      isActiveBorderedTemplateBlock: values.modifiedId
    });
  };

  deleteTemplateBlock = e => {
    let { removeBlockTemplate } = this.props;
    removeBlockTemplate(this.props.index);
  };

  render() {
    const {
      values: { thumbnailLocation, modifiedId, name },
      isActiveOnClickTemplateBlocks,
      index
    } = this.props;

    return (
      <Draggable draggableId={modifiedId} index={index} key={modifiedId}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <ParentWrapper
              onClick={() => {
                this.changePreview(this.props);
              }}
            >
              <BlockWrapper key={this.props.index}>
                <ImageWrapper
                  modifiedId={modifiedId}
                  isActive={isActiveOnClickTemplateBlocks.modifiedId}
                >
                  <StyledImage src={thumbnailLocation} />
                  <CloseIconWrapper>
                    <CloseIcon
                      onClick={e => {
                        DeleteConfirmationAlert({
                          onYesClick: this.deleteTemplateBlock
                        });
                      }}
                    />
                  </CloseIconWrapper>
                </ImageWrapper>
                <center>
                  <Heading>{name}</Heading>
                </center>
              </BlockWrapper>
            </ParentWrapper>
          </Container>
        )}
      </Draggable>
    );
  }
}

const Container = styled.div``;

const Heading = styled.h3``;

const ImageWrapper = styled.div`
  width: 100px;
  position: relative;
  border: ${props => {
    return props.modifiedId === props.isActive
      ? "2px solid #2768aa"
      : `2px solid ${props.theme.COLOR.MODAL_FADED_GREY}`;
  }};
  padding: 4px;
`;
const BlockWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100px;
  margin-top: 10px;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 22px;
  height: 22px;
`;

const CloseIconWrapper = styled.div`
  background-color: ${props => props.theme.COLOR.WHITE};
  position: absolute;
  top: -7px;
  right: -6px;
  width: 20px;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
`;

const StyledImage = styled.img`
  height: auto;
  display: block;
  max-width: 51px;
  margin: 0 auto;
  max-height: 66px;
`;

const ParentWrapper = styled.div`
  margin: 5px 0;
  cursor: pointer;
  padding: 5px 10px;
  background: white;
`;

const mapStateToProps = state => {
  const createPreviewRenderer = state.domain.CREATE_TEMPLATE_PREVIEW_RENDER;
  return createPreviewRenderer;
};

export default connect(mapStateToProps)(DraggableTemplateBlock);
