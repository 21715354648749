import React from "react";
import styled from "styled-components";
import { ActiveStar, InactiveStar } from "assets/icons";
import { get } from "lodash";
import Moment from "react-moment";
import Indeterminate from "components/indeterminate";
import { ThemePreviewImage } from "assets/images";

import hexToRgba from "utils/hexToRgba";
import { FaRegQuestionCircle } from "react-icons/fa";

const UISTRING = {
  PROGRESS_BAR_NOTE:
    "This progress bar indicates the theme is being processed. Once the process is completed, this theme will be available to be used. Meanwhile you can navigate away from this screen and continue with other activities as applicable."
};

const CategoryTitleComponent = props => {
  let {
    title,
    updatedAt,
    handleTitleClick,
    isMaster,
    _id,
    saveEditedThemeDetails,
    enable,
    ingestId
  } = props;

  let filePollingStatus =
    props.filePollingStatus &&
    ingestId === props.filePollingStatus.data.ingestId &&
    get(props.filePollingStatus, `data.status`) !== "File Parsed" &&
    get(props.filePollingStatus, `data.status`) !== "Completed" &&
    get(props.filePollingStatus, `data.status`) !== "Failed";

  return (
    <>
      <ImageContainer>
        <Image
          ingestId={ingestId}
          src={
            props.thumbnailLocation
              ? props.thumbnailLocation
              : ThemePreviewImage
          }
          alt="Theme slide"
        />
      </ImageContainer>
      <Title
        title={title}
        ingestId={ingestId}
        onClick={() => handleTitleClick(_id)}
      >
        {title}
      </Title>
      <DateContainer>
        <Moment format="MM/DD/YYYY">{updatedAt}</Moment>
      </DateContainer>

      <DefaultThemeContainer
        ingestId={ingestId}
        enabled={enable}
        onClick={() => {
          saveEditedThemeDetails(
            { isMaster: !isMaster },
            _id,
            "Master Theme has been successfully updated."
          );
        }}
      >
        {isMaster ? (
          <ActiveStarContainer title="Default Theme">
            <ActiveStar />
          </ActiveStarContainer>
        ) : (
          <InactiveStarContainer>
            <InactiveStar />
          </InactiveStarContainer>
        )}
      </DefaultThemeContainer>
      {filePollingStatus ? (
        <IndetermineProgress>
          <Indeterminate />
          <StyledInfoIcon
            size={12}
            data-rh={UISTRING.PROGRESS_BAR_NOTE}
            data-rh-at="right"
          />
        </IndetermineProgress>
      ) : null}
    </>
  );
};

const ImageContainer = styled.div`
  font-size: 12px;
  white-space: normal;
  color: ${props => props.theme.COLOR_PALLETE.DARK_GREY};
  background-color: ${props => props.theme.COLOR_PALLETE.DARK_GREY};
  max-width: 58px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: 0.5px solid ${props => props.theme.COLOR.BORDER_GREY};
`;

const Image = styled.img`
  width: 100%;
  display: block;
  max-height: 32px;
  max-width: 58px;
  filter: ${props => (props.ingestId ? "grayscale(200%)" : "initial")};
`;

const Title = styled.h3`
  width: 104px;
  margin-left: 8%;
  margin-right: 4%;
  display: inline-block;
  vertical-align: middle;
  color: ${props => hexToRgba(props.theme.COLOR.DEFAULT_USER_PRIMARY, ".9")};
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DateContainer = styled.span`
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: ${props => props.theme.COLOR.MAIN};
  font-family: ${props => props.theme.FONT.REG};
  font-weight: normal;
`;

const DefaultThemeContainer = styled.span`
  width: 23px;
  display: inline-block;
  margin-left: 10%;
  text-align: center;
  vertical-align: middle;
  cursor: ${props => (props.enabled ? "pointer" : "default")};
  pointer-events: ${props => !props.enabled && "none"};
`;

const InactiveStarContainer = styled.span``;

const ActiveStarContainer = styled.span``;

const IndetermineProgress = styled.div`
  width: 2px;
  margin-left: 26%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }
`;

const StyledInfoIcon = styled(FaRegQuestionCircle)`
  margin-top: 2px;
  display: inline-block;
  vertical-align: text-top;
  size: 10px;
  transform: translate(5px, 1px);
  cursor: pointer;
  color: ${props => props.theme.COLOR.USER_PRIMARY};
`;

export default CategoryTitleComponent;
