const SUCCESS_THEME_LIST = "SUCCESS_THEME_LIST";
const LOADING_THEME_LIST = "LOADING_THEME_LIST";
const SUCCESS_COVER = "SUCCESS_COVER";
const LOADING_COVER = "LOADING_COVER";

export const ThemeTypes = {
  SUCCESS_THEME_LIST,
  LOADING_THEME_LIST,
  SUCCESS_COVER,
  LOADING_COVER
};
