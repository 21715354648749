export const reportingStats = {
  pg: {
    advanceStats: [
      {
        label: "All presentations",
        id: "presentation",
        default: true,
        value: { allPresentations: true }
      },
      {
        label: "Presentation generated by email address",
        id: "generated",
        value: { presentationsByEmail: true }
      },
      {
        label: "Individual slides by email address",
        id: "slide-email",
        value: { slidesByEmail: true }
      },
      {
        label: "Slides used per presentation",
        id: "used-presentation",
        value: { slidesPerPresentation: true }
      },
      {
        label: "Filtering breakout",
        id: "filtering",
        value: { filtersBreakout: true }
      },
      {
        label: "Slide download counts",
        id: "slide-download-count",
        value: { slidesByDownloadCount: true }
      }
    ],
    quickStats: [
      {
        label: "Total visit to landing page",
        id: "totalLandingPageVists",
        value: 0
      },
      {
        label: "Total logins",
        id: "totalLogins",
        value: 0
      },
      {
        label: "Total unique logins",
        id: "uniqueLogins",
        value: 0
      },
      {
        label: "Total presentation created",
        id: "totalPresentationsCreated",
        value: 0
      }
    ],
    selectedOption: "presentation"
  },
  mg: {
    advanceStats: [
      {
        label: "All documents",
        id: "documents",
        default: true,
        value: { allDocuments: true }
      },
      {
        label: "Documents generated by email address",
        id: "generated-document",
        value: { documentsByEmail: true }
      },
      {
        label: "Modules used per document",
        id: "module-used",
        value: { modulesPerDocument: true }
      }
    ],
    quickStats: [
      {
        label: "Total visit to landing page",
        id: "totalLandingPageVists",
        value: 0
      },
      {
        label: "Total logins",
        id: "totalLogins",
        value: 0
      },
      {
        label: "Total unique logins",
        id: "uniqueLogins",
        value: 0
      },
      {
        label: "Visits to Dashboard",
        id: "dashboardVisits",
        value: 0
      },
      {
        label: "Fliers Created",
        id: "Flier",
        value: 0
      },
      {
        label: "Brochures Created",
        id: "Brochure",
        value: 0
      },
      {
        label: "Placemats Created",
        id: "Placemat",
        value: 0
      }
    ],
    selectedOption: "documents"
  },
  rg: {
    advanceStats: [
      {
        label: "All renewals",
        id: "rg",
        value: { allRenewals: true },
        default: true
      },
      {
        label: "Renewals by user",
        id: "renewals-by-user",
        value: { renewalsByUser: true }
      },
      {
        label: "Audit trail",
        id: "audit",
        value: { audit: true }
      }
    ],
    quickStats: [
      {
        label: "Total visit to landing page",
        id: "totalLandingPageVists",
        value: 0
      },
      {
        label: "Total logins",
        id: "totalLogins",
        value: 0
      },
      {
        label: "Total unique logins",
        id: "uniqueLogins",
        value: 0
      },
      {
        label: "Total renewals created",
        id: "totalRenewalsCreated",
        value: 0
      }
    ],
    selectedOption: "rg"
  },
  sm: {
    advanceStats: [
      {
        label: "All documents",
        id: "documents",
        default: true,
        value: { allSmDocuments: true }
      },
      {
        label: "Documents by user",
        id: "generated-document",
        value: { smDocumentsByUser: true }
      }
    ],
    quickStats: [
      {
        label: "Total visit to landing page",
        id: "totalLandingPageVists",
        value: 0
      },
      {
        label: "Total logins",
        id: "totalLogins",
        value: 0
      },
      {
        label: "Total unique logins",
        id: "uniqueLogins",
        value: 0
      },
      {
        label: "Total documents created",
        id: "totalDocumentsCreated",
        value: 0
      }
    ],
    selectedOption: "documents"
  }
};

export const toolOptionsList = [
  {
    value: "rg",
    label: "Renewal Generator"
  },
  {
    value: "pg",
    label: "Presentation Maker"
  },
  {
    value: "mg",
    label: "Collateral Maker"
  },
  {
    value: "sm",
    label: "Summary Maker"
  }
];
