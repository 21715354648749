// libraries
import React from "react";
import styled, { css } from "styled-components";
import { map, get } from "lodash";

// components
import ChooseFile from "components/chooseFile";
import SlideDropDown from "components/dropDown";
import Button from "components/button";


export default function index(props) {
  const {
    isActiveOnClickTemplateBlocks,
    functionDownForm,
    onSavehandler,
    onChangeHandler
  } = props;

  return (
    <PageWrapper>
      <BlockHeader>
        <Button
          text="Save"
          width="100px"
          float="none"
          buttonClass="save"
          onClick={e => onSavehandler(e)}
        />
      </BlockHeader>
      {map(functionDownForm, eachOption => {
        return (
          <DropdownWrapper>
            <Name>{eachOption.label}</Name>
            <SlideDropDownWrapper >
              <SlideDropDown
                option={eachOption.values.map(value => ({...value, title: value.label, label: value.title}))}
                className="dropdown-container"
                value={eachOption.value}
                handleChange={e => onChangeHandler(e, eachOption._id)}
              />
            </SlideDropDownWrapper>
          </DropdownWrapper>
        );
      })}
      <ChooseFileWrapper>
        <CssFileName>
          CSS File:
          <FileName>
            {isActiveOnClickTemplateBlocks.cssUploadedFileName
              ? get(isActiveOnClickTemplateBlocks, `cssUploadedFileName`)
              : get(isActiveOnClickTemplateBlocks, `cssFilename`)}
          </FileName>
        </CssFileName>
        <ChooseFile
          {...props}
          uploadFileType="css"
          hint="Drag a CSS file here or click to browse"
        />
      </ChooseFileWrapper>
    </PageWrapper>
  );
}

const DropdownWrapper = styled.div`
  width: 100%;
  margin: 0 auto 40px;
  display: flex;
  justify-content: space-between;
  text-align: left;
`;

const FileName = styled.span`
  display: inline-block;
  margin-left: 12%;
  font-size: 14px;
`;

const StyledName = css`
  display: inline-block;
  width: 20%;
  text-align: left;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
`;

const Name = styled.span`
  ${StyledName};
`;

const CssFileName = styled.span`
  ${StyledName};
  display: block;
  width: 100%;
  margin-bottom: 20px;
`;

const ChooseFileWrapper = styled.div`
  width: 100%;

  .btn-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  @media (max-width: 940px) {
    .filename {
      max-width: 117px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const SlideDropDownWrapper = styled.div`
  display: inline-block;
  width: 80%;
`;

const PageWrapper = styled.div`
  margin: 1px auto 0;
  .dropdown-container {
    width: 75%;
  }
  .container {
    width: 60%;
    margin: 0 auto;
  }
`;

const BlockHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

