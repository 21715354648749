import React from "react";
import styled, { css } from "styled-components";
import { get, round, isEmpty, toLower } from "lodash";
import Container from "./container";
import DynamicImageEditor from "../../../dynamicImagesEditor";
import {
  PlusIcon,
  Minus,
  RightArrowCircle,
  LeftArrowCircle,
  RightMoveArrow,
  LeftMoveArrow,
  UpMoveArrow,
  DownMoveArrow,
  EditFilled
} from "assets/icons";
import Button, { SecondaryButton } from "components/button";
import DatePicker from "components/datePicker";

const SliderContent = props => {
  let {
    slideDetails,
    onSliderNavigation,
    slideNo,
    inputType,
    _handleInputFocus,
    dynamicInputFocus,
    isNavigating,
    editIconsHandler,
    showEditIcons,
    placeholderPosition,
    currentEditedPlaceholderPosition,
    handleZoom,
    handlePanning,
    dynamicImageContainer,
    resetImageDimensions,
    showDynamicImageOverlay,
    slidesWithDeletedImages,
    activeSlideDetail,
    placeholderWithDeletedImage
  } = props;

  let isDate = get(slideDetails, `label`, ``).trim() === "Presentation Date";
  let inputValue = !isDate
    ? get(slideDetails, `text`, ``).trim()
    : get(slideDetails, `text`, ``);
  let activeImageUrl, activeImageAlt, activeImageKey;

  let wasLastSavedImageDeleted =
    (slidesWithDeletedImages.indexOf(get(activeSlideDetail, `changedTitle`)) !==
      -1 ||
      slidesWithDeletedImages.indexOf(get(activeSlideDetail, `title`)) !==
        -1) &&
    placeholderWithDeletedImage.indexOf(placeholderPosition) !== -1;

  // Flag to check if images are present
  let isImageAvailable = !!(
    get(slideDetails, `images.length`) && inputType === "image"
  );
  // hide edit icon when single slide preview is opened.
  let hideEditIcon = activeSlideDetail.hideEditIcon;

  // for the dynamic images, show the last selected image if it was selected before else show the default that is first image
  if (inputType === "image") {
    activeImageUrl =
      !isEmpty(get(slideDetails, `selectedImage`)) && !isNavigating
        ? get(slideDetails, `selectedImage.thumbnailLocation`)
        : get(slideDetails, ["images", slideNo, "thumbnailLocation"]);

    activeImageAlt =
      !isEmpty(get(slideDetails, `selectedImage`)) && !isNavigating
        ? get(slideDetails, `selectedImage.title`)
        : get(slideDetails, ["images", slideNo, "title"]);

    activeImageKey =
      !isEmpty(get(slideDetails, `selectedImage`)) && !isNavigating
        ? get(slideDetails, `selectedImage._id`)
        : get(slideDetails, ["images", slideNo, "_id"]);
  }
  const slideData = (
    <>
      <DynamicImagesWrapper
        width={round(slideDetails.width, 2)}
        height={round(slideDetails.height, 2)}
        top={round(slideDetails.y, 2)}
        left={round(slideDetails.x, 2)}
        placeholderPosition={placeholderPosition}
        currentEditedPlaceholderPosition={currentEditedPlaceholderPosition}
        inputType={inputType}
        ref={dynamicImageContainer}
      >
        <SliderContentWrapper
          className={dynamicInputFocus && inputType === "text" && "active"}
          isFocused={dynamicInputFocus}
          inputType={inputType}
          placeholderPosition={placeholderPosition}
          currentEditedPlaceholderPosition={currentEditedPlaceholderPosition}
          wasLastSavedImageDeleted={wasLastSavedImageDeleted}
          isImageAvailable={isImageAvailable}
          isDate={isDate}
          alignRight={round(slideDetails.x, 2) > 40}
          textHorizontalAlignment={toLower(
            get(slideDetails, `font.xAlignment`)
          )}
          textVerticalAlignment={toLower(get(slideDetails, `font.yAlignment`))}
          typeText={inputType === "text"}
        >
          {isImageAvailable ? (
            <>
              <SlideImageWrapper showEditIcons={showEditIcons}>
                <SlideOverlay
                  placeholderPosition={placeholderPosition}
                  currentEditedPlaceholderPosition={
                    currentEditedPlaceholderPosition
                  }
                />

                <DynamicImageEditor
                  activeImageUrl={activeImageUrl}
                  activeImageAlt={activeImageAlt}
                  activeImageKey={activeImageKey}
                  key={activeImageKey}
                  imageDetails={get(slideDetails, ["images", slideNo])}
                  {...props}
                />
                {inputType === "image" &&
                  showEditIcons &&
                  !hideEditIcon &&
                  placeholderPosition !== currentEditedPlaceholderPosition && (
                    <EditSaveCtaWrapper
                      title="Edit"
                      onClick={() => {
                        editIconsHandler(slideDetails, placeholderPosition);
                      }}
                      placeholderPosition={placeholderPosition}
                      currentEditedPlaceholderPosition={
                        currentEditedPlaceholderPosition
                      }
                      showEditIcons={showEditIcons}
                    >
                      <EditFilled />
                    </EditSaveCtaWrapper>
                  )}
              </SlideImageWrapper>
            </>
          ) : (
            inputType !== "text" &&
            inputType !== "date" && (
              <NoImageText title="No images available.">
                No images available.
              </NoImageText>
            )
          )}

          {inputType === "text" && (
            <EditableInput
              defaultValue={inputValue}
              placeholder="Enter your text"
              colorValue={get(slideDetails, `font.color`)}
              fontSizeValue={
                Number(get(slideDetails, `font.size`)) /
                (Number(get(slideDetails, `font.size`)) > 50
                  ? 2
                  : Number(get(slideDetails, `font.size`)) > 40
                  ? 1.4
                  : 1.5)
              }
              fontWeightValue={get(slideDetails, `font.style.bold`)}
              fontStyleValue={get(slideDetails, `font.style.italic`)}
              fontFamily={get(slideDetails, `family`)}
              onBlur={e => props.dynamicTextBoxHandler(e)}
              onFocus={_handleInputFocus}
              alignRight={round(slideDetails.x, 2) > 40}
              textHorizontalAlignment={toLower(
                get(slideDetails, `font.xAlignment`)
              )}
              rows="1"
            />
          )}

          {inputType === "date" && (
            <DatePickerElem
              value={new Date(inputValue)}
              className={"presentationDate"}
              handleChange={date => {
                props.dynamicCoverFieldTextChange(
                  placeholderPosition,
                  date,
                  slideDetails["label"]
                );
              }}
              height={"14px"}
              popperPlacement="top-start"
              shouldCloseOnSelect={true}
              fontFamily={get(slideDetails, `family`)}
              colorValue={get(slideDetails, `font.color`)}
              fontWeightValue={get(slideDetails, `font.style.bold`)}
              fontSizeValue={Number(get(slideDetails, `font.size`)) / 2}
              fontStyleValue={get(slideDetails, `font.style.italic`)}
            />
          )}
        </SliderContentWrapper>
        {inputType === "image" &&
          placeholderPosition === currentEditedPlaceholderPosition &&
          showDynamicImageOverlay && (
            <ImageEditorBox>
              <ImageName title={activeImageAlt}>{activeImageAlt}</ImageName>
              <Navigation>
                <NavigationHeading>Select Alternate Images</NavigationHeading>
                <LeftArrowWrapper
                  disable={slideNo === 0}
                  className="prev-cta"
                  onClick={() => {
                    onSliderNavigation(
                      slideNo,
                      "previous",
                      get(slideDetails, `images.length`),
                      slideDetails
                    );
                  }}
                  title="Previous Image"
                >
                  <LeftArrowCircle />
                </LeftArrowWrapper>
                <RightArrowWrapper
                  disable={slideNo === get(slideDetails, `images.length`) - 1}
                  className="next-cta"
                  onClick={() => {
                    onSliderNavigation(
                      slideNo,
                      "next",
                      get(slideDetails, `images.length`),
                      slideDetails
                    );
                  }}
                  title="Next Image"
                >
                  <RightArrowCircle />
                </RightArrowWrapper>
              </Navigation>
              <PanZoomControls>
                <PanControl>
                  <PanControlHeading>Move</PanControlHeading>
                  <MovingIcons>
                    <LeftMoveArrowIcon
                      onClick={() => handlePanning("left")}
                      title="Move Left"
                    />
                    <UpMoveArrowIcon
                      onClick={() => handlePanning("top")}
                      title="Move Up"
                    />
                    <RightMoveArrowIcon
                      onClick={() => handlePanning("right")}
                      title="Move Right"
                    />
                    <DownMoveArrowIcon
                      onClick={() => handlePanning("bottom")}
                      title="Move Down"
                    />
                  </MovingIcons>
                </PanControl>
                <ZoomControl>
                  <ZoomControlHeading>Zoom</ZoomControlHeading>
                  <StyledPlusIcon
                    onClick={() => handleZoom("plus")}
                    title="Zoom in"
                  />
                  <StyledMinus onClick={handleZoom} title="Zoom out" />
                </ZoomControl>
              </PanZoomControls>
              <ActionIcons>
                <SecondaryButton
                  text="Reset"
                  width="40%"
                  buttonClass="reset-button"
                  onClick={resetImageDimensions}
                />
                <SecondaryButton
                  text="Cancel"
                  width="40%"
                  buttonClass="cancel-button"
                  onClick={editIconsHandler}
                />
                <Button
                  text="Select"
                  width="40%"
                  float="none"
                  buttonClass="save-button"
                  onClick={editIconsHandler}
                />
              </ActionIcons>
            </ImageEditorBox>
          )}
      </DynamicImagesWrapper>
    </>
  );

  return slideData;
};

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  margin-right: 10px;
`;

const NoImageText = styled.span`
  font-family: ${props => `${props.theme.FONT.REG}`};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.HEADING};
  display: block;
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: ${props => props.theme.COLOR.ERROR};
  text-align: center;
  width: 80%;
`;

const StyledMinus = styled(Minus)`
  vertical-align: text-top;
  cursor: pointer;
`;

const PanZoomControls = styled.div``;

const PanControl = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: top;
`;

const SharedHeading = css`
  font-family: ${props => `${props.theme.FONT.REG}`};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.HEADING};
  display: block;
  margin-bottom: 20px;
`;

const PanControlHeading = styled.span`
  ${SharedHeading};
`;

const ZoomControl = styled.div`
  display: inline-block;
  width: 50%;
`;

const ZoomControlHeading = styled.span`
  ${SharedHeading};
`;

const MovingIcons = styled.span`
  position: relative;
`;

const LeftMoveArrowIcon = styled(LeftMoveArrow)`
  cursor: pointer;
  opacity: 0.5;
  width: 18px;
  height: 18px;
`;

const UpMoveArrowIcon = styled(UpMoveArrow)`
  cursor: pointer;
  position: absolute;
  top: -18px;
  opacity: 0.5;
  width: 18px;
  height: 18px;
`;

const RightMoveArrowIcon = styled(RightMoveArrow)`
  cursor: pointer;
  position: absolute;
  top: -4px;
  right: -35px;
  opacity: 0.5;
  width: 18px;
  height: 18px;
`;

const DownMoveArrowIcon = styled(DownMoveArrow)`
  cursor: pointer;
  position: absolute;
  bottom: -11px;
  opacity: 0.5;
  width: 18px;
  height: 18px;
`;

const LeftArrowWrapper = styled.span`
  opacity: ${props => (props.disable ? 0.5 : 1)};
  cursor: ${props => (props.disable ? "default" : "pointer")};
  pointer-events: ${props => props.disable && "none"};
  margin-right: 8px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const RightArrowWrapper = styled.span`
  opacity: ${props => (props.disable ? 0.5 : 1)};
  cursor: ${props => (props.disable ? "default" : "pointer")};
  pointer-events: ${props => props.disable && "none"};
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Navigation = styled.div`
  margin-bottom: 10px;
`;

const NavigationHeading = styled.span`
  font-family: ${props => `${props.theme.FONT.REG}`};
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.HEADING};
  display: block;
  margin-bottom: 10px;
`;

const ImageEditorBox = styled.div`
  position: absolute;
  width: 180px;
  background: ${props => props.theme.COLOR.WHITE};
  bottom: -190.4px;
  left: -1px;
  border: 1px solid ${props => props.theme.COLOR.HEADING};
  border-top: none;
  padding: 10px;
  height: 191px;
`;

const ImageName = styled.span`
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.MAIN};
  white-space: nowrap;
  margin-bottom: 5.4px;
`;

const ActionIcons = styled.div`
  margin-top: 10px;

  button {
    width: 31%;
    height: 20px;
    margin-right: 5px;
  }

  .save-button {
    height: 20px;
    font-size: 12px;
    margin-right: 0;
    border-radius: 4px;
  }
`;

const EditSaveCtaWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  border: none;
  outline: none;
  width: 15px;
  height: 15px;
  color: ${props => props.theme.COLOR.MAIN};
  text-transform: uppercase;
  background-color: ${props => props.theme.COLOR.WHITE};
  padding: 2px;
  cursor: pointer;
  display: block;
  transform: translate(-50%, -50%);

  svg {
    width: 14px;
    height: 14px;
  }
`;

const SlideImageWrapper = styled.div``;

const SlideOverlay = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  opacity: ${props =>
    props.placeholderPosition === props.currentEditedPlaceholderPosition
      ? "0"
      : "0.5"};
  background-color: ${props => props.theme.COLOR.MODAL_FADED_GREY};
`;

const SliderContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${props => !props.isDate && "overflow : hidden"};
  border: ${props =>
    props.inputType === "text" || props.inputType === "date"
      ? props.isFocused
        ? `1px dashed ${props.theme.COLOR.PRIMARY_LIGHT}`
        : `1px dashed ${props.theme.COLOR_PALLETE.BROWNISH_GREY}`
      : props.wasLastSavedImageDeleted
      ? `2px dashed ${props.theme.COLOR.USER_PRIMARY}`
      : !props.isImageAvailable
      ? `1px solid ${props.theme.COLOR_PALLETE.BROWNISH_GREY}`
      : `none`};
  text-align: ${props =>
    props.alignRight && !props.textHorizontalAlignment
      ? "right"
      : !props.textHorizontalAlignment
      ? "left"
      : props.alignRight && props.textHorizontalAlignment === "not defined"
      ? "right"
      : props.textHorizontalAlignment === "not defined"
      ? "left"
      : props.textHorizontalAlignment};
  display: ${props => (props.typeText ? "flex" : "block")};
  align-items: ${props =>
    props.textVerticalAlignment === "not defined"
      ? "flex-start"
      : props.textVerticalAlignment === "top"
      ? "flex-start"
      : props.textVerticalAlignment === "bottom"
      ? "flex-end"
      : props.textVerticalAlignment};
`;

const DynamicImagesWrapper = styled.div`
  width: ${props => props.width}%;
  height: ${props => props.height}%;
  position: absolute;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  z-index: ${props =>
    props.placeholderPosition === props.currentEditedPlaceholderPosition &&
    props.inputType === "image" &&
    "2"};

  padding: ${props =>
    props.placeholderPosition === props.currentEditedPlaceholderPosition &&
    props.inputType === "image" &&
    "3px"};

  border: ${props =>
    props.placeholderPosition === props.currentEditedPlaceholderPosition &&
    props.inputType === "image" &&
    `1px solid ${props.theme.COLOR.HEADING}`};

  background: ${props => props.theme.COLOR.WHITE};
`;

const EditableInput = styled.textarea`
  width: 100%;
  padding: 0;
  border: none;
  font-size: ${props => props.fontSizeValue || "16"}px;
  line-height: 1;
  font-weight: ${props => props.fontWeightValue && "bold"};
  font-style: ${props => props.fontStyleValue && "italic"};
  font-family: ${props => props.fontFamily || `Arial`};
  color: rgba(${props => props.colorValue});
  height: inherit;
  overflow-wrap: break-word;
  background-color: ${props =>
    props.colorValue === "255,255,255" || props.colorValue === "white"
      ? "rgba(0, 0, 0, 0.2)"
      : "transparent"};
  resize: none;
  overflow: hidden;
  word-break: break-word;
  text-align: ${props =>
    props.alignRight && !props.textHorizontalAlignment
      ? "right"
      : !props.textHorizontalAlignment
      ? "left"
      : props.alignRight && props.textHorizontalAlignment === "not defined"
      ? "right"
      : props.textHorizontalAlignment === "not defined"
      ? "left"
      : props.textHorizontalAlignment};
  &:focus {
    outline: 0;
  }
`;

const DatePickerElem = styled(DatePicker)`
  label svg {
    width: 15px;
    height: 15px;
  }
  .react-datepicker__input-container {
    input {
      border-radius: 0;
      background: none;
      color: rgba(${props => props.colorValue});
      font-size: ${props => props.fontSizeValue || "16"}px;
      font-weight: ${props => props.fontWeightValue && "bold"};
      font-style: ${props => props.fontStyleValue && "italic"};
      text-indent: 0;
      line-height: 1;
    }
  }

  @media all and (max-width: 1024px) {
    /* Covert the sizes as responsive for smaller screen */
    .react-datepicker__input-container input {
      font-size: ${props => props.fontSizeValue * 0.089 || "16"}vw;
    }
  }
`;

export default Container(SliderContent);
