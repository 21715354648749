import React from "react";
import styled, { css } from "styled-components";
import StepDetails from "components/buildProcess/stepDetails";
import Container from "./container";
import hexToRgba from "utils/hexToRgba";
import handleBodyScroll from "utils/handleBodyScroll";
import "react-accessible-accordion/dist/fancy-example.css";
import Checkbox from "components/checkbox";
import {
  PreviewBig,
  Close,
  CobrandStamp,
  DynamicDataIndication
} from "assets/icons";
import { map } from "lodash";
import CustomerLogo from "./components/CustomerLogo";
//utils
import featureFlags from "utils/featureFlags.js";
import { get, size } from "lodash";

import DynamicPlaceholder from "./components/DynamicPlaceholders";

const renderAccordion = ({
  coverList,
  handleStateChange,
  handleCoverPreview,
  selectedCoverLayout,
  contentRepo,
  checkCoverDynamicFields
}) => {
  return (
    <StyledAccordion>
      {Array.isArray(coverList) && coverList.length ? (
        map(coverList, cover => {
          let { _id, thumbnailLocation, title, slideData } = cover;
          let showDynamicIcon = slideData && slideData.length;
          return (
            <CoverImageWrapper
              key={_id}
              isSelected={_id === selectedCoverLayout}
            >
              <CoverImg
                src={thumbnailLocation.url}
                title={title}
                onClick={() => {
                  handleStateChange({
                    key: "selectedCoverLayout",
                    value: _id
                  });
                  handleStateChange({
                    key: "coverDetails",
                    value: cover
                  });
                  checkCoverDynamicFields(slideData);
                }}
                className={`__test_cover_list ${
                  _id === selectedCoverLayout ? "__test_selected_cover" : ""
                }`}
              />
              {!!showDynamicIcon && <DynamicDataElem />}
              <PreviewIcon
                onClick={() => handleCoverPreview(thumbnailLocation.url)}
              />
            </CoverImageWrapper>
          );
        })
      ) : (
        <NoCoverData>
          {contentRepo && contentRepo.title
            ? `No cover available under selected theme.`
            : `Please select content repo.`}
        </NoCoverData>
      )}
    </StyledAccordion>
  );
};

const Theme = props => {
  const {
    themeList,
    coverList,
    handleStateChange,
    includeCoverPage,
    selectedThemeLayout,
    selectedCoverLayout,
    handleCoverPreview,
    showPreview,
    overlayCoverImage,
    contentRepo,
    fetchCoverList,
    nextStepHandler,
    coverListHandler,
    skipBtnHandler,
    isEdited,
    onSave,
    doesCobrandLogoExist,
    selectedThemeDetails,
    isDynamicCoverFieldsPresent,
    checkCoverDynamicFields,
    removeThemeSection,
    removeCoverSection,
    removeCoBrandSection,
    coverDetails
  } = props;

  let { showCustomerLogo } = get(featureFlags, `presentation`);

  let isCoBrandLogoPresent =
    selectedThemeDetails &&
    selectedThemeDetails.isCobrandLogo &&
    size(coverDetails.coBrandLogoData)
      ? true
      : false;

  return (
    <>
      {showPreview
        ? handleBodyScroll({ action: "open" })
        : handleBodyScroll({ action: "close" })}
      {showPreview && (
        <>
          <Overlay onClick={handleCoverPreview}></Overlay>
          <OverlayImageWrapper>
            <OverlayCoverImg src={overlayCoverImage} />
            <CloseIconWrapper>
              <CloseIcon onClick={() => handleCoverPreview()} />
            </CloseIconWrapper>
          </OverlayImageWrapper>
        </>
      )}
      <ThemeWrapper id="__test_theme_wrapper">
        <StepDetails
          _next
          _save={isEdited}
          _skip
          onNext={() => nextStepHandler(2)}
          onSkip={skipBtnHandler}
          title={"Theme"}
          notDangerously={true}
          onSave={onSave}
          description={
            doesCobrandLogoExist ? (
              <>
                <CobrandLogoStampIcon />
                <CoBrandText>
                  This represents that the given theme has co-brand logo
                  placeholder.
                </CoBrandText>
              </>
            ) : (
              ""
            )
          }
          {...props}
        />
        <ContentWrapper {...props} id="__test_theme_content_wrapper">
          {(!removeThemeSection || !removeCoverSection) && (
            <Content>
              {!removeThemeSection && (
                <ThemeBox>
                  <ThemeBoxHeading>Theme</ThemeBoxHeading>
                  <ThemeList>
                    {Array.isArray(themeList) && themeList.length ? (
                      map(themeList, theme => {
                        let {
                          thumbnailLocation,
                          _id,
                          title,
                          isCobrandLogo
                        } = theme;

                        return (
                          <ThemeContainer key={_id}>
                            {isCobrandLogo && (
                              <CobrandStampWrapper>
                                <CobrandLogoStampIcon />
                              </CobrandStampWrapper>
                            )}
                            <ThemeImageWrap
                              className={`__test_theme_list ${
                                _id === selectedThemeLayout
                                  ? "__test_selected_theme"
                                  : ""
                              }`}
                              isSelected={_id === selectedThemeLayout}
                              onClick={() => {
                                if (_id !== selectedThemeLayout) {
                                  handleStateChange({
                                    key: "selectedThemeLayout",
                                    value: _id,
                                    cb: () => {
                                      fetchCoverList && fetchCoverList();
                                    }
                                  });

                                  handleStateChange({
                                    key: "includeCoverPage",
                                    value: true
                                  });

                                  handleStateChange({
                                    key: "selectedThemeDetails",
                                    value: theme
                                  });
                                }
                              }}
                            >
                              <ThemeImg src={thumbnailLocation} />
                            </ThemeImageWrap>
                            <ThemeTitle title={title}>{title}</ThemeTitle>
                          </ThemeContainer>
                        );
                      })
                    ) : (
                      <NoThemeData>
                        {contentRepo && contentRepo.title
                          ? `No theme available under Content Repo.`
                          : `Please select content repo.`}
                      </NoThemeData>
                    )}
                  </ThemeList>
                </ThemeBox>
              )}
              {!removeCoverSection && (
                <CoverBox>
                  <CoverBoxHeading>Cover</CoverBoxHeading>
                  <CheckBoxWrapper>
                    <Checkbox
                      label={"Include Cover"}
                      checked={includeCoverPage}
                      handleChange={e => {
                        const valueChecked = e.target.checked;
                        const valueObject = {
                          key: "includeCoverPage",
                          value: e.target.checked,
                          cb: () => {
                            if (coverListHandler) {
                              coverListHandler(valueChecked);
                            }
                          }
                        };
                        handleStateChange({ ...valueObject });
                      }}
                    />
                  </CheckBoxWrapper>
                  <CoverListWrapper>
                    <CoverList>
                      {renderAccordion({
                        coverList,
                        handleStateChange,
                        selectedCoverLayout,
                        handleCoverPreview,
                        contentRepo,
                        checkCoverDynamicFields
                      })}
                    </CoverList>
                  </CoverListWrapper>
                </CoverBox>
              )}
            </Content>
          )}

          <RightPanel {...props}>
            {isDynamicCoverFieldsPresent && includeCoverPage && (
              <DynamicPlaceholder {...props} />
            )}
            {showCustomerLogo &&
              isCoBrandLogoPresent &&
              !removeCoBrandSection && <CustomerLogo {...props} />}
          </RightPanel>
        </ContentWrapper>
      </ThemeWrapper>
    </>
  );
};

const ContentWrapper = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${props =>
    props.removeThemeSection &&
    props.removeCoverSection &&
    !props.removeCoBrandSection &&
    !props.isDynamicCoverFieldsPresent
      ? `justify-content: center;`
      : `justify-content: space-between;`}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const ThemeWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  ${props =>
    props.removeThemeSection &&
    props.removeCoverSection &&
    !props.removeCoBrandSection
      ? `flex-basis: 50%;`
      : `flex-basis: 38%;`};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 12;
`;

const CoBrandText = styled.span`
  vertical-align: super;
  margin-left: 5px;
`;

const CobrandLogoStampIcon = styled(CobrandStamp)`
  width: 9px;
  height: 18px;
  transform: translateY(-1px);
`;

const CobrandStampWrapper = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: ${props => `3px solid ${props.theme.COLOR.USER_PRIMARY}`};
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  box-sizing: border-box;
`;

const OverlayCoverImg = styled.img`
  width: 100%;
  height: 100%;
`;

const PreviewIcon = styled(PreviewBig)`
  width: 19px;
  height: 15px;
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
`;

const OverlayImageWrapper = styled.div`
  width: 40%;
  min-width: 600px;
  z-index: 21;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const CoverListWrapper = styled.div`
  position: relative;
`;

const CoverImageWrapper = styled.div`
  width: 23.125%;
  padding: 1px;
  border: 5px solid transparent;
  border: ${props =>
    props.isSelected ? `5px solid ${props.theme.COLOR.USER_PRIMARY}` : ""};
  margin: 0 2.5% 20px 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:nth-child(4n) {
    margin-right: 0;
  }
`;

const StyledAccordion = styled.div`
  padding: 31px 30px 34px;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
`;

const CoverList = styled.div``;

const CheckBoxWrapper = styled.div`
  margin: 23px 28px;
  input {
    margin-right: 12px;
  }
`;

const CoverImg = styled.img`
  width: 100%;
  display: block;
`;

const CoverBox = styled.div`
  margin: 19px 0 0;
  background-color: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 35px 0 0;
`;

const ThemeBox = styled.div`
  margin-top: 19px;
  background-color: ${props => props.theme.COLOR.WHITE};
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  padding: 31px 30px 34px;
`;

const ThemeImageWrap = styled.div`
  border: 5px solid transparent;
  border: ${props =>
    props.isSelected ? `5px solid ${props.theme.COLOR.USER_PRIMARY}` : ""};
  cursor: pointer;
  padding: ${props => (props.isSelected ? `1px` : "")};
  display: inline-block;
  vertical-align: top;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.25);
  }
`;

const ThemeImg = styled.img`
  width: 100%;
  display: block;
`;

const ThemeList = styled.div``;

const ThemeBoxHeading = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  margin-bottom: 17px;
  display: inline-block;
`;

const CoverBoxHeading = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  display: inline-block;
  margin-left: 27px;
`;

const ThemeCover = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;
const NoThemeData = styled.div`
  ${ThemeCover}
`;

const NoCoverData = styled.div`
  ${ThemeCover};
  padding: 0 28px 34px;
`;

const ThemeTitle = styled.h4`
  display: inline-block;
  font-size: 14px;
  margin: 5px 0;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  max-width: 400px;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ThemeContainer = styled.div`
  width: 23.125%;
  margin: 0 2.5% 20px 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  &:nth-child(4n) {
    margin-right: 0;
  }
`;

const DynamicDataElem = styled(DynamicDataIndication)`
  width: 19px;
  height: 15px;
  position: absolute;
  top: 5px;
  left: 4px;
`;

export default Container(Theme);
