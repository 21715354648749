import React from 'react';
import styled from "styled-components";
import { BarLoader } from "react-spinners"; 

const Indeterminate = () => {
	return (
		<IndeterminateProgress>
			<BarLoader
			color={"#123abc"}
			/>
		</IndeterminateProgress>
	)
}

export default Indeterminate;

const IndeterminateProgress = styled.div``;

