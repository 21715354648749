import React from "react";
import styled from "styled-components";
import Container from "./container";

//Components
import BgWrapper from "components/bgWrapper";
import TabHeader from "components/tabHeader";
import Button from "components/button";
import FullPageLoader from "components/FullPageLoader";
import SidebarOverlay from "components/sidebarOverlay";
import SearchBox from "components/searchBox";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import SortableTreeContainer from "./components/sortableTree";
import ImageUploadStatus from "./components/imageUploadStatus";
import ImageFileUpload from "./components/imageFileUpload";
import ImageGrid from "./components/imageGrid";
import ImagePreview from "./components/imagePreview";
import UploadFile from "./components/uploadFile";

import { PlusIcon } from "assets/icons";

const TabList = [
  { title: "Folders", id: "folder", value: "folder" },
  { title: "Category", id: "category", value: "category" }
];

const ImageFolder = props => {
  const {
    sideBarRef,
    selectedTab,
    handleSlideSelectorTab,
    addNewNode,
    categoryIdToEdit,
    imageListLoading,
    imageFolderDetails,
    imageUploadLoading,
    showSidebarModal,
    handleOverlayStatus,
    uploadFileDetails,
    onSearch,
    showPreviewOverlay,
    imageUpdateLoading,
    isDownloadActive
  } = props;
  const UPLOAD_STATUS_TEXT =
    "All your unsaved changes in the upload process will be lost. Do you still want to continue?";
  let disableButton =
    categoryIdToEdit ||
    (selectedTab === "folder"
      ? (imageFolderDetails || {}).level === 2
      : (imageFolderDetails || {}).level === 1);

  return (
    <ImageUploadContainer>
      {(imageListLoading || imageUploadLoading || imageUpdateLoading) && (
        <FullPageLoader layerIndex={21} />
      )}
      <ImageFolderHeader>
        <Button
          float="none"
          text="Upload"
          width="24.3%"
          title="Click to upload new images"
          onClick={() => handleOverlayStatus(false)}
          icon={<PlusIcon />}
          buttonClass="upload-button"
        />
        <SearchBox handleChange={e => onSearch(e)} />
      </ImageFolderHeader>
      <Sidebar ref={sideBarRef}>
        <TabHeaderWrapper>
          <TabHeader
            data={TabList}
            manageStates={handleSlideSelectorTab}
            active={selectedTab}
            width="50%"
            padding="15px 8% 8px"
          />
        </TabHeaderWrapper>
        <NewCategory
          disableButton={disableButton}
          onClick={() => !disableButton && addNewNode()}
        >
          {selectedTab === "folder" ? "New Folder" : "New Category"}
        </NewCategory>
        <SortableTreeContainer {...props} />
      </Sidebar>
      <RightConatiner>
        <ImageGrid {...props} />
      </RightConatiner>

      {showSidebarModal && (
        <SidebarOverlay
          width="326px"
          closeSidebarOverlay={() => {
            if (uploadFileDetails.isEdited) {
              DeleteConfirmationAlert({
                message: UPLOAD_STATUS_TEXT,
                onYesClick: () => {
                  handleOverlayStatus();
                }
              });
            } else {
              handleOverlayStatus();
            }
          }}
        >
          {isDownloadActive ? (
            <UploadFile {...props} />
          ) : uploadFileDetails.uploadedFile ? (
            <ImageUploadStatus {...props} />
          ) : (
            <ImageFileUpload {...props} />
          )}
        </SidebarOverlay>
      )}
      {showPreviewOverlay && <ImagePreview {...props} />}
    </ImageUploadContainer>
  );
};

const ImageUploadContainer = styled.div`
  padding: 0 42px;
`;

const Sidebar = styled.div`
  width: 24.3%;
  height: 580px;
  margin-bottom: 50px;
  display: inline-block;
  vertical-align: top;
  background: ${props => props.theme.COLOR.WHITE};
  position: relative;
  overflow: hidden;
`;

const TabHeaderWrapper = styled.div``;

const RightConatiner = styled.div`
  width: calc(75.7% - 22px);
  min-height: 580px;
  margin-left: 22px;
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  font-family: ${props => props.theme.FONT.REG};
  position: relative;
`;

const NewCategory = styled.button`
  width: calc(100% - 40px);
  height: 35px;
  border: 1px solid ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  border-radius: 4px;
  margin: 22px auto 0;
  display: block;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  cursor: pointer;
  ${props => props.disableButton && "opacity: 0.6; cursor: not-allowed;"}
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  font-weight: bold;
  background-color: transparent;
`;

const ImageFolderHeader = styled.div`
  width: 100%;
  padding: 32px 0 31px;
  display: block;

  .upload-button {
    font-size: 16px;

    &:hover svg {
      path {
        fill: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-top: 1px;
      vertical-align: top;

      path {
        fill: ${props => props.theme.COLOR.WHITE};
      }
    }
  }
`;

export default Container(BgWrapper(ImageFolder));
