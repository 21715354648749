/**
 *  convert blob to data uri
 * @param {Array} data array buffer data to be converted to data uri
 * @param {Object} type type of blob
 * @param {Function} cb callback function to be called once the data uri is generated
 */
export default function blobToDataUri(data, type, cb) {
  let blob = new Blob([data], type);
  let reader = new FileReader();
  let base64Data = null;
  reader.onloadend = () => {
    base64Data = reader.result;
    cb && cb(base64Data);
  };

  reader.readAsDataURL(blob);
}
