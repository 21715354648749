import React, { Fragment } from "react";
import styled, { css } from "styled-components";

//comoponents
import Checkbox from "components/checkbox";
import hexToRgba from "utils/hexToRgba";
import DatePicker from "components/datePicker";
import MultiSelect from "./components/MultiSelect";

//utils
import featureFlags from "utils/featureFlags.js";
import { get, size, map } from "lodash";

const SetupDetails = props => {
  let {
    setupDetails,
    onChangeInput,
    handleCheckbox,
    coverDetails: { slideData },
    coverDetails,
    isDynamicCoverDetailsEdited,
    onBlurInput
  } = props;
  let { showCustomerLogo } = get(featureFlags, `presentation`);

  // console.log(setupDetails);
  // console.log(slideData);

  let updatedSlideData = [];

  if (size(slideData)) {
    map(slideData, eachData => {
      if (eachData.inputType !== "date") {
        updatedSlideData.push(eachData);
      }
    });

    map(slideData, eachData => {
      if (eachData.inputType === "date") {
        updatedSlideData.push(eachData);
      }
    });
  }

  return (
    <>
      {size(updatedSlideData) ? (
        <DetailsContainer isCenterAligned={!showCustomerLogo}>
          {updatedSlideData &&
            updatedSlideData.map(
              (
                {
                  label,
                  text: value,
                  error,
                  inputType: type,
                  editable,
                  handleChange,
                  minDate,
                  defaultValue,
                  name: defaultName
                },
                index
              ) => {
                let finalValue = value && value !== defaultName ? value : "";

                label === "Title" &&
                  value === coverDetails.title &&
                  (finalValue = "");

                // label group item for setup fields
                const labelGroupItem = (
                  <Fragment key={index}>
                    {type === "text" && label !== "Customer Name" && (
                      <LabelGroup key={index}>
                        {label && (
                          <Label>
                            {defaultName
                              ? defaultName.replace(/[\[\]]+/g, "")
                              : label.replace(/[\[\]]+/g, "")}
                          </Label>
                        )}
                        {type === "text" && (
                          <LabelInput
                            type={type}
                            value={finalValue}
                            // placeholder={defaultValue}
                            onBlur={onBlurInput}
                            onChange={e =>
                              onChangeInput({
                                value: e.target.value,
                                label,
                                key: label
                              })
                            }
                          />
                        )}
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </LabelGroup>
                    )}
                    {type === "date" && (
                      <LabelGroup key={index}>
                        <DatePickerContainer>
                          <DatePicker
                            className={"presentationDate"}
                            title={
                              defaultName
                                ? defaultName.replace(/[\[\]]+/g, "")
                                : label.replace(/[\[\]]+/g, "")
                            }
                            toolTip={
                              defaultName
                                ? defaultName.replace(/[\[\]]+/g, "")
                                : label.replace(/[\[\]]+/g, "")
                            }
                            value={value ? new Date(value) : new Date()}
                            handleChange={date => {
                              onChangeInput({
                                label,
                                key: label,
                                date: date,
                                flag: true
                              });
                            }}
                            placeholderText={"From"}
                            minDate={minDate}
                          />
                          {error && <ErrorMessage>{error}</ErrorMessage>}
                        </DatePickerContainer>
                      </LabelGroup>
                    )}
                  </Fragment>
                );

                return labelGroupItem;
              }
            )}
          {/* <PreviewCover>preview</PreviewCover> */}
        </DetailsContainer>
      ) : (
        ""
      )}
    </>
  );
};

const PreviewCover = styled.a`
  padding: 12px 0;
  margin-top: 25px;
  display: block;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  transition: all 0.5s ease 0s;
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 24px 41px;
  margin: 19px 0 0;
  .dropdown-container {
    margin: 20px 0;
    label {
      padding-top: 8px;
    }
  }
  .checkbox-container {
    margin: 20px 0;
    label {
      margin-left: 4px;
      letter-spacing: -0.2px;
    }
  }
`;

const SelectContainer = styled.div`
  padding-bottom: 0px;
  ${props =>
    props.top
      ? "padding-top: 15px"
      : props.bottom
      ? "margin-bottom: 29px;"
      : ""};
  label {
    top: -2px;
  }

  &.multi-select {
    overflow: hidden;
    select {
      width: 108%;
      height: auto;
      padding: 10px;
      margin: -5px -20px -5px -5px;
    }

    option::selection {
      background: red;
    }
  }

  /*Override custom select list*/
  .custom-select__control {
    /*When select list is focuses*/
    &--is-focused {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    /*When select list is in active state*/
    &--menu-is-open {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }

  /*Overriding option styling*/
  .custom-select__menu {
    > div > div {
      background-color: ${props => props.theme.COLOR.WHITE};

      &:hover {
        background-color: ${props =>
          hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.2)};
      }
    }

    .custom-select__option--is-selected {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const sharedInputStyle = css`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 3px;
  padding: 7px 8px;
  height: 30px;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.4);
  background: ${props => props.theme.COLOR.INPUT};
  outline: none;
`;

const LabelGroup = styled.div`
  margin-bottom: 10px;
`;

const LabelCSS = css`
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.64)};
  margin-bottom: 4px;
  display: inline-block;
`;

const Label = styled.span`
  ${LabelCSS}
`;

const LabelInput = styled.input`
  ${sharedInputStyle};

  &:focus {
    border: 2px solid ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  font-family: ${props => props.theme.FONT.REG};
  color: ${props => props.theme.COLOR_PALLETE.ERROR};
  text-align: left;
`;

const DatePickerContainer = styled.div`
  margin-bottom: 7px;
  vertical-align: top;
  &:nth-of-type(2) {
    margin-right: 20px;
  }
  &:nth-of-type(3) {
    label:first-child {
      visibility: hidden;
    }
  }
  .presentationDate {
    label:first-child {
      ${LabelCSS}
      margin-bottom: 0;
    }
    input {
      height: 30px;
      ${sharedInputStyle}
      padding: 0;
    }
    svg {
      top: 66%;
    }
  }
`;

export default SetupDetails;
