import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";
import history from "../../../../history";
//import content repo actions
import { onGetUserDetailsSuccess, isGetUserDetailsLoading } from "./actions";
/**
 * Get Content Repo List for presentation setup
 */
const getProfileDetail = (isProfileUpdating = false) => async dispatch => {
  const URL = `/user`;
  // start loading

  if (isProfileUpdating) {
    dispatch(isGetUserDetailsLoading({ isProfileUpdating: true }));
  } else {
    dispatch(isGetUserDetailsLoading({ isProfileLoading: true }));
  }

  const response = await FetchUtils.getData(URL);

  if (isProfileUpdating) {
    dispatch(isGetUserDetailsLoading({ isProfileUpdating: false }));
  } else {
    dispatch(isGetUserDetailsLoading({ isProfileLoading: false }));
  }
  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onGetUserDetailsSuccess({
        userProfileMeta: response.data
      })
    );
  } else {
    // if the user profile data is rejected by 401 Unauthorized access, the application throws a warning message and nothing loads!
    // The user will be confused what to do leading to a bad UX
    // The application couldn't fetch any other data because of this API error.
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
    // redirecting them back to the login page would help
    history.push("/logout");
  }
  //return reponse to check in component if required
  return response;
};
export { getProfileDetail };
