let tableMetaData = [
  {
    label: "Name",
    key: "name"
  },
  {
    label: "Created By",
    key: "createdBy"
  },
  {
    label: "Policy Number",
    key: "policyNumber"
  },
  {
    label: "Customer Number",
    key: "customerNumber"
  }
];

let uploadedFilesList = [
  {
    _id: "5dee4ad43d83c06667380717",
    name: "Document 1",
    customerNumber: "12345",
    policyNumber: "12345",
    createdBy: "Abc"
  },
  {
    _id: "5dee4ad43d83c06667380714",
    name: "Document 2",
    customerNumber: "12345",
    policyNumber: "12345",
    createdBy: "Xyz"
  },
  {
    _id: "5dee4ad43d83c06667380713",
    name: "Document 3",
    customerNumber: "12345",
    policyNumber: "12345",
    createdBy: "Pqr"
  },
  {
    _id: "5dee4ad43d83c06667380716",
    name: "Lorem ipsum",
    customerNumber: "12345",
    policyNumber: "12345",
    createdBy: "Abc"
  },
  {
    _id: "5dee4ad43d83c06667380517",
    name: "zzzz",
    customerNumber: "12345",
    policyNumber: "12345",
    createdBy: "Xyz"
  }
];

export { tableMetaData, uploadedFilesList };
