import React, { Component } from "react";
import Layout from "./index";
import { set } from "lodash";

class LayoutPage extends Component {
  state = {
    layoutTypes: []
  };

  // set the thumbnail url in the placeholder
  setSelectedThumbNail = (
    _id,
    placeholderName,
    currentIndex,
    source,
    pageLayoutIndex
  ) => {
    let layoutDetails = [...this.props.layoutDetails];

    set(
      layoutDetails,
      [
        pageLayoutIndex,
        `layoutTypeDetails`,
        placeholderName,
        currentIndex,
        `selectedThumbnail`
      ],
      source
    );

    this.props.handleStateChange({
      key: "layoutDetails",
      value: layoutDetails
    });
    this.props.handleStateChange({
      key: "isDataEdited",
      value: true
    });
  };

  // called on cross click of placeholder
  removeSelectedDocument = (
    _id,
    placeholderName,
    currentIndex,
    currentSelectedModule,
    pageLayoutIndex
  ) => {
    let layoutDetails = [...this.props.layoutDetails] || [];
    this.props.setModules(currentSelectedModule);

    set(
      layoutDetails,
      [
        pageLayoutIndex,
        "layoutTypeDetails",
        placeholderName,
        currentIndex,
        `selectedThumbnail`
      ],
      ""
    );

    this.props.handleStateChange({
      key: "layoutDetails",
      value: layoutDetails
    });
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Layout {...stateMethodProps} />;
  }
}

export default LayoutPage;
