import React, { Component } from "react";
import { get, each, find } from "lodash";
import { connect } from "react-redux";
import uniqId from "uniqid";

// components
import TemplateBuild from "./index";
import ToastUtils from "utils/handleToast";

//services
import { updateTemplate } from "tools/summaryMaker/store/actions/templateDashboardActions";
import {
  getTemplateBlocks,
  setCustomBlocks,
  updateTemplateBlock,
  updateCustomBlock,
  getTemplateBlockContent,
  getTemplateBlocksAdmin,
  getBlockFunctions,
  getCoverImageData
} from "tools/summaryMaker/store/actions/templateCreationActions";

import {
  uploadFileTemplate,
  uploadFileToAws
} from "tools/summaryMaker/store/actions/uploadFileActions";

const mapStateToProps = state => {
  const {
    domain: {
      RECEIVE_TEMPLATE_BLOCKS_ADMIN,
      REQUEST_TEMPLATE_BLOCKS_ADMIN,
      REQUEST_DATA,
      REQUEST_TEMPLATE_UPDATE,
      RECEIVE_TEMPLATE_BLOCK_CONTENT,
      RECEIVE_BLOCK_FUNCTIONS,
      REQUEST_GET_COVER_IMAGE_DATA,
      RECEIVE_COVER_IMAGE_DATA
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...RECEIVE_TEMPLATE_BLOCKS_ADMIN,
    ...REQUEST_TEMPLATE_BLOCKS_ADMIN,
    ...SUCCESS_USER_PROFILE,
    ...REQUEST_DATA,
    ...REQUEST_TEMPLATE_UPDATE,
    ...RECEIVE_TEMPLATE_BLOCK_CONTENT,
    ...RECEIVE_BLOCK_FUNCTIONS,
    ...REQUEST_GET_COVER_IMAGE_DATA,
    ...RECEIVE_COVER_IMAGE_DATA
  };
};

const mapDispatchToProps = {
  getTemplateBlocks,
  setCustomBlocks,
  updateTemplate,
  uploadFileTemplate,
  uploadFileToAws,
  updateTemplateBlock,
  updateCustomBlock,
  getTemplateBlockContent,
  getTemplateBlocksAdmin,
  getBlockFunctions,
  getCoverImageData
};

class DashboardContainer extends Component {
  state = {
    isAnyFileNameEdited: false,
    customBlocks: [],
    builtinBlocks: [],
    templateBlocks: [],
    isActiveOnClickTemplateBlocks: "",
    isSelectedBorderedId: "",
    isBlockEdited: false,
    isBlockUpdating: false
  };

  componentDidMount() {
    this.getBuiltinBlock();
  }

  getBuiltinBlock = async () => {
    await this.props.getTemplateBlocks(true, "builtinBlocks", true);
    await this.props.getTemplateBlocks(false, "customBlocks", false);
    const { builtinBlocks, customBlocks } = this.props;

    // adding new id for uniqueness
    each(customBlocks, eachBlock => {
      eachBlock.modifiedId = uniqId();
    });

    this.setState({
      builtinBlocks,
      customBlocks
    });
  };

  addToCustomBlock = blockToBeAdded => {
    let { customBlocks } = this.state;

    blockToBeAdded.modifiedId = uniqId();
    customBlocks.push(blockToBeAdded);

    this.setState({
      customBlocks
    });
    this.props.setCustomBlocks("customBlocks", customBlocks);
  };
  // Same Functionality as addToCustomBlocks
  addToCustomTemplate = blockToBeAdded => {
    let { templateBlocks } = this.state;
    const modifiedBlock = {
      ...blockToBeAdded,
      modifiedId: uniqId()
    };

    for (let iterator = 0; iterator < templateBlocks.length; iterator++) {
      if (templateBlocks[iterator].name === modifiedBlock.name) {
        modifiedBlock.name = modifiedBlock.name + `(clone)`;
        break;
      }
    }
    templateBlocks.push(modifiedBlock);
    this.setState({
      templateBlocks
    });
    this.props.setCustomBlocks("templateBlocks", templateBlocks);
  };

  addTemplateBlocksAdmin = async (templateId, showLoader = true) => {
    await this.props.getTemplateBlocksAdmin(templateId, showLoader);
    this.setState({
      templateBlocks: this.props.templateBlocks
    });
  };

  clearOffAllTemplateBlocks = () => {
    this.setState({
      templateBlocks: []
    });
  };

  // Same Functionality as addToCustomBlocks
  addToCustomTemplate = blockToBeAdded => {
    let { templateBlocks } = this.state;
    const modifiedBlock = {
      ...blockToBeAdded,
      modifiedId: uniqId()
    };

    for (let i = 0; i < templateBlocks.length; i++) {
      if (templateBlocks[i].name === modifiedBlock.name) {
        modifiedBlock.name = modifiedBlock.name + `(clone)`;
        break;
      }
    }
    templateBlocks.push(modifiedBlock);
    this.setState({
      templateBlocks: this.props.templateBlocks
    });
  };

  // Same Functionality as addToCustomBlocks
  addToCustomTemplate = blockToBeAdded => {
    let { templateBlocks } = this.state;
    const modifiedBlock = {
      ...blockToBeAdded,
      modifiedId: uniqId()
    };

    for (let i = 0; i < templateBlocks.length; i++) {
      if (templateBlocks[i].name === modifiedBlock.name) {
        modifiedBlock.name = modifiedBlock.name + `(clone)`;
        break;
      }
    }
    templateBlocks.push(modifiedBlock);
    this.setState({
      templateBlocks
    });
    this.props.setCustomBlocks("templateBlocks", templateBlocks);
  };

  // Same Functionality as addToCustomBlocks
  addToCustomTemplate = blockToBeAdded => {
    let { templateBlocks } = this.state;
    blockToBeAdded.modifiedId = uniqId();
    templateBlocks.push(blockToBeAdded);
    this.setState({
      templateBlocks
    });
    this.props.setCustomBlocks("templateBlocks", templateBlocks);
  };

  removeBlock = position => {
    let { customBlocks } = this.state;
    customBlocks.splice(position, 1);

    this.setState({
      customBlocks
    });
    this.props.setCustomBlocks("customBlocks", customBlocks);
  };

  // Same Funcationality as removeBlock
  removeBlockTemplate = position => {
    let { templateBlocks } = this.state;
    templateBlocks.splice(position, 1);
    this.props.setCustomBlocks("templateBlocks", templateBlocks);

    this.setState({
      templateBlocks,
      isActiveOnClickTemplateBlocks: ""
    });
    this.templateUpdateHandler(templateBlocks, true);
  };

  // onClick change Preview
  onClickPreviewTemplateBlock = (
    isActiveOnClickTemplateBlocks,
    value = false
  ) => {
    this.setState({
      isActiveOnClickTemplateBlocks
    });
    if (value) {
      this.setState({
        isSelectedBorderedId: value
      });
    }
  };

  // Reorder TemplateBlocks on Drag and Drop
  reorderTemplateBlock = templateBlocks => {
    this.setState({
      templateBlocks
    });
  };
  /**
   * set flag stating if any name name is edited
   * @param {Boolean} isAnyFileNameEdited whose value needs to be checked
   */
  setFileNameEditedFlag = isAnyFileNameEdited => {
    this.setState({
      isAnyFileNameEdited
    });
  };

  saveInputValueHandler = async (value, position) => {
    let { customBlocks } = this.state;
    customBlocks[position].name = value;

    const patchData = {
      name: value
    };

    await this.props.updateCustomBlock(
      get(customBlocks[position], `_id`),
      patchData
    );

    this.setState({
      customBlocks
    });
  };

  templateUpdateHandler = async (
    customTemplate,
    isRemoved = false,
    reorderedSuccesful = false
  ) => {
    const templateId = get(this.props, `match.params.templateId`);
    const { updateTemplate } = this.props;
    const customTemplateIds = this.state.templateBlocks.map(
      (templateBlocks, eachID) => {
        return templateBlocks._id;
      }
    );

    let postData = {
      blocks: customTemplateIds
    };

    await updateTemplate(
      postData,
      templateId,
      `Block has been successfully ${
        isRemoved ? "removed" : reorderedSuccesful ? "reordered" : "added"
      }.`
    );
  };

  uploadFileToRepo = async (
    { metaDeta, file },
    cb,
    otherData = {},
    getElements
  ) => {
    const templateId = get(this.props, `match.params.templateId`);
    const blockId = get(this.state, `isActiveOnClickTemplateBlocks._id`);

    delete metaDeta.resource;
    delete metaDeta.title;

    let postData = {
      fileType: get(metaDeta, `fileType`),
      fileName: get(metaDeta, `fileName`),
      ...otherData
    };

    let response = await this.props.uploadFileTemplate(
      postData,
      templateId,
      blockId
    );

    let { presignedUrl = "" } = get(response, `data`);

    await this.props.uploadFileToAws(presignedUrl, file);
    const uploadedFileName = get(metaDeta, `fileName`);
    this.setUploadedFileName(
      blockId,
      uploadedFileName,
      get(metaDeta, `fileType`)
    );

    cb();
    getElements && getElements();
  };

  /**
   * sets the file name as key in the template block for which the file was uploaded
   *
   */
  setUploadedFileName = (blockId, uploadedFileName, fileType) => {
    const { templateBlocks } = this.state;
    const currentSelectedBlock = find(templateBlocks, { _id: blockId });

    currentSelectedBlock[`${fileType}UploadedFileName`] = uploadedFileName;
    this.setState({
      templateBlocks
    });
  };

  updateTemplateBlockHandler = async (dataToBeUpdated, removeVariable) => {
    const { isBlockEdited } = this.state;
    const templateId = get(this.props, `match.params.templateId`);
    const blockId = get(this.state, `isActiveOnClickTemplateBlocks._id`);

    if (!isBlockEdited && !removeVariable) return;

    this.setState({
      isBlockUpdating: true
    });

    let response = await this.props.updateTemplateBlock(
      blockId,
      templateId,
      dataToBeUpdated,
      removeVariable
    );

    this.setState({
      isBlockUpdating: false
    });

    this.handleStateChange({ key: "isBlockEdited", value: false });
    if (!response.success) {
      this.handleStateChange({ key: "isBlockEdited", value: true });
      return response;
    }

    const { templateBlocks } = this.state;
    let currentSelectedBlock = find(templateBlocks, { _id: blockId });
    let modifiedBlock = {
      ...currentSelectedBlock,
      ...dataToBeUpdated
    };

    Object.assign(currentSelectedBlock, modifiedBlock);

    this.setState({
      templateBlocks
    });

    return response;
  };

  // show toast message
  showToastMessage = (toastType = "success", message = "") => {
    ToastUtils.handleToast({
      operation: toastType,
      message: message
    });
  };

  handleStateChange = ({ key, value, cb }) => {
    this.setState(
      {
        [key]: value
      },
      () => {
        cb && cb();
      }
    );
  };

  getBlockContentHandler = async blockType => {
    const blockId = get(this.state, `isActiveOnClickTemplateBlocks._id`);
    const templateId = get(this.props, `match.params.templateId`);
    await this.props.getTemplateBlockContent(blockId, blockType, templateId);
  };

  getBlockFunctionsHandler = async () => {
    await this.props.getBlockFunctions();
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };
    return <TemplateBuild {...stateMethodProps} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
