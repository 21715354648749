import React from "react";
import { Router } from "react-router-dom";

import history from "../history";
import AppRoutes from "./routes";

const Routes = () => (
  <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
    <AppRoutes />
  </Router>
);

export default Routes;
