import { PreviewTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

const RECEIVE_PREVIEW_IMAGES = (
  payload = null,
  type = PreviewTypes.RECEIVE_PREVIEW_IMAGES
) => {
  return {
    type,
    payload
  };
};

const REQUEST_PREVIEW_DATA = (
  payload = {},
  type = PreviewTypes.REQUEST_PREVIEW_DATA
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_DOCUMENT_STATUS = (
  payload = null,
  type = PreviewTypes.RECEIVE_DOCUMENT_STATUS
) => {
  return {
    type,
    payload
  };
};

const showPreviewLoader = loaderEnable => dispatch => {
  dispatch(
    REQUEST_PREVIEW_DATA({
      isPreviewLoading: loaderEnable
    })
  );
};

const getPreviewImages = (documentId, showLoader = false) => async dispatch => {
  const URL = `/summary/documents/${documentId}/pageimages`;

  // start loading
  if (showLoader) {
    dispatch(
      REQUEST_PREVIEW_DATA({
        isPreviewLoading: true
      })
    );
  }

  const response = await FetchUtils.getRequest(URL);

  if (!showLoader) {
    dispatch(
      REQUEST_PREVIEW_DATA({
        isPreviewLoading: false
      })
    );
  }
  if (response.status == 200 && response.data) {
    dispatch(
      RECEIVE_PREVIEW_IMAGES({
        previewImages: response.data.data
      })
    );
    return response.data
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.err")
    });
    return response.data.error
  }
};

const getPageImage = (documentId, imageId) => async dispatch => {
  const responseType = {
    responseType: "blob"
  };

  const URL = `/summary/documents/${documentId}/images/${imageId}`;
  const response = await FetchUtils.getDataWithHeader(
    URL,
    "Fetch Images",
    null,
    responseType
  );

  if (get(response, `data`)) {
    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const getDocumentStatus = documentId => async dispatch => {
  const URL = `/summary/documents/${documentId}`;

  const response = await FetchUtils.getData(URL, "image polling");

  if (response.success && response.data) {
    dispatch(
      RECEIVE_DOCUMENT_STATUS({
        documentStatus: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const getDocumentPdf = (documentId, userId) => async dispatch => {
  const responseType = {
    responseType: "blob"
  };

  const URL = `/summary/users/${userId}/documents/${documentId}/file`;
  const response = await FetchUtils.getDataWithHeader(
    URL,
    "Fetch Document Pdf",
    null,
    responseType
  );

  if (get(response, `data`)) {
    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const getImage = test => async dispatch => {
  const responseType = {
    responseType: "blob"
  };

  const URL = test;

  const response = await FetchUtils.getDataWithHeader(
    URL,
    "Fetch Image",
    null,
    responseType,
    false
  );

  return response.data;
};

export {
  getPreviewImages,
  getPageImage,
  getDocumentStatus,
  getDocumentPdf,
  getImage,
  showPreviewLoader
};
