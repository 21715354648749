import React from "react";
import styled, { css } from "styled-components";
import { FaInfoCircle } from "react-icons/fa";
import hexToRgba from "utils/hexToRgba";

/**
 * Input Box
 */
const InputBox = ({
  label,
  name,
  id,
  handleInputChange,
  type,
  disabled,
  hintData,
  maxLength,
  defaultValue,
  inputRef,
  width,
  labelFontSize,
  fieldType,
  error,
  placeholder,
  value
}) => {
  return (
    <FormGroup width={width}>
      <Label labelFontSize={labelFontSize} htmlFor={id}>
        {label}
      </Label>
      {hintData && (
        <IconWrapper>
          <StyledInfoIcon size={12} data-rh={hintData} data-rh-at="left" />
        </IconWrapper>
      )}
      {fieldType === "input" && (
        <Input
          type={type}
          name={name}
          id={id}
          ref={inputRef}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          disabled={disabled}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      )}
      {fieldType === "textarea" && (
        <TextArea
          type={type}
          name={name}
          id={id}
          value={value}
          ref={inputRef}
          defaultValue={defaultValue}
          maxLength={maxLength}
          disabled={disabled}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
      )}
      <ErrorMessage className="error-message-input">{error}</ErrorMessage>
    </FormGroup>
  );
};

const SharedLabelCss = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  opacity: 0.64;
  display: inline-block;
  margin-bottom: 6px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  cursor: pointer;
  display: block;
  text-align: left;
  font-size: ${props => props.labelFontSize || "10px"};
`;

const Label = styled.label`
  ${SharedLabelCss}
`;

const IconWrapper = styled.span`
  width: 19px;
  height: auto;
  display: inline-block;
  padding: 5px;
  margin: -5px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  font-size: 10px;
  color: ${props => props.theme.COLOR.ERROR};
  position: absolute;
  margin-top: 5px;
  left: 0;
`;

const SharedInputCss = css`
  box-sizing: border-box;
  display: block;
  width: 100%;
  resize: none;
  border-radius: 4px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  border: 1px solid ${props => props.theme.COLOR.INPUT_BORDER_COLOR};
  background: ${props => props.theme.COLOR.INPUT};
  &::placeholder {
    ${props => props.theme.SNIPPETS.FONT_STYLE};
    opacity: 0.4;
  }

  &:focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 7px 8px;
  height: 80px;
  ${SharedInputCss}
`;

const Input = styled.input`
  padding: 7px 8px;
  ${SharedInputCss}
`;

const StyledInfoIcon = styled(FaInfoCircle)`
  margin-top: 2px;
  size: 10px;
  transform: translate(5px, 1px);
  cursor: pointer;
  color: ${props => props.theme.COLOR.MAIN};
`;

const FormGroup = styled.div`
  box-sizing: border-box;
  width: ${props => props.width || "100%"};
  margin-bottom: 20px;
  position: relative;
`;

InputBox.defaultProps = {
  fieldType: "input",
  inputChangeHandler: () => {}
};

export default InputBox;
