// libraries
import React from "react";
import styled from "styled-components";

// components
import UploadDropZone from "components/chooseFile";
import Checkbox from "components/checkbox";
import Button from "components/button";

const UploadFiles = props => {
  let {
    handleIncludeDividerCheckbox,
    includeDividerValue,
    showCheckbox,
    noOfFilesAllowed,
    pdfFiles
  } = props;

  return (
    <ChooseFileContainer>
      {pdfFiles.length < noOfFilesAllowed ? (
        <span>
          <UploadDropZone uploadFileType={"pdf"} {...props} />
          {showCheckbox && (
            <>
              <Checkbox
                label="Include Divider"
                checked={includeDividerValue}
                handleChange={handleIncludeDividerCheckbox}
              />
              {noOfFilesAllowed}
              <ButtonWrapper>
                <FileInput type="file" accept=".ppt, .pptx" id="upload" />
                {includeDividerValue && (
                  <Button
                    onClick={() => {
                      document.querySelector("#upload").click();
                    }}
                    buttonClass="upload-slide"
                    text="Upload Slide"
                    float="none"
                  />
                )}
              </ButtonWrapper>
            </>
          )}
        </span>
      ) : (
        <Heading>Limit of uploading documents/pages of pdf is reached.</Heading>
      )}
    </ChooseFileContainer>
  );
};

const Heading = styled.div`
  text-align: "center";
  font-weight: 600;
`;

const ButtonWrapper = styled.div``;

const FileInput = styled.input`
  display: none;
`;

const ChooseFileContainer = styled.div`
  width: 30%;
`;

export default UploadFiles;
