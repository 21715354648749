import * as types from "./types";

export const pmSetSetupDetails = (
  payload = {},
  type = types.PM_SET_SETUP_DETAILS
) => {
  return {
    type,
    payload
  };
};
export const pmSetSelectedContentListAction = (
  payload = {},
  type = types.PM_SET_SELECTED_CONTENT
) => {
  return {
    type,
    payload
  };
};

export const onSelectedFiltersChangeAction = (
  payload = {},
  type = types.ON_SELECTED_FILTERS_CHANGE
) => {
  //console.debug("onSelectedFiltersChangeAction: value=",payload);
  return {
    type,
    payload
  };
};
