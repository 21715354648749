import { AUTH_CONFIG } from "./auth0-config";
import history from "../history";
import { get } from "lodash";

//get service for login count
import { setUserLoginCount } from "./services";
import { isLocalhost } from "../utils/helpers";
import { webAuth, setLocalStorageSession, clearLocalStorageSession } from "../services/webAuth";

export default class Auth {
  constructor(ORIGIN) {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.origin = ORIGIN;
    console.debug('Auth.ctor() ', { ORIGIN });
    console.debug('Auth.ctor() ',  {AUTH_CONFIG});
    this.auth0 = webAuth;
  }

  login() {
    console.debug('login() entry.')
    //remove email id from storage
    localStorage.removeItem("password-reset-email");
    this.auth0.authorize();
  }

  handleAuthentication() {
    console.debug('AuthLogin.Auth.handleAuthentication() entry.');
    this.auth0.parseHash((err, authResult) => {
      if (err) {
        // @todo keep this DRY - the token being present is all that's needed

        // check and redirect if user has last reset password flag active set by auth0 rule.
        // @todo actually check this to decide if there's an error OR
        // if this is just a 'user must reset password' response
        /* at the moment, the hash looks like this:

            "hash": "#error=unauthorized&error_description=password_reset_required%3Fjim%40mlapplied.com&state=QJAp-UuVlsPBBzBVK~7XAzc4SOqLnIRr"
           
          and the err param to the callback (here) looks like
            {
              error: "unauthorized",
              errorDescription: "password_reset_required?jim@mlapplied.com",
              state: "QJAp-UuVlsPBBzBVK~7XAzc4SOqLnIRr"
            }

         */
        // let errMsgWithEmail = (get(err, "errorDescription") || "").split("?") || [];
        // let isUserMustResetPassword = errMsgWithEmail[0] === "password_reset_required";
        let isUserMustResetPassword = err.errorDescription.startsWith('password_reset_required');
    
        if (isUserMustResetPassword) {
          this.handleResetLastPassword(err);
          return;
        }

        console.log(`AuthLogin.Auth.handleAuthentication() ${err.error}`);
        console.log(err);
        this.logout();
        return;
      }

      console.debug('AuthLogin.Auth.handleAuthentication() success', JSON.stringify(authResult,null,2));
 
      this.setSession(authResult);

/*
 * handleAuthentication() was calling an async method without waiting for
 * it to resolve. all setTotalLoginsCountForUser() did was call another
 * async method, but waiting for it. so, we wait for the first promise to
 * resolve, but in async function that no one is waiting for...
 * 
  //set total login counts for user
  setTotalLoginsCountForUser = async () => {
    await setUserLoginCount();
  };

      //set total logins count
      this.setTotalLoginsCountForUser();
 */
      setUserLoginCount()
        .then( console.log( 'handleAuthentication() returned from setUserLoginCount'));

      // navigate to the home route
      /**
       * The application has a Home Page Route which prevents a 
       * blank render of screen. There is nothing on "/" of the
       * tool. The homepage is set on Home. At all the instances
       * in the application, /home is set/pushed/used for homepage
       * redirect. 
       */
      history.replace("/home");
    });
  }

  /**
   * Handle Reset Last Password - Will be executed when auth0 rule fails to check if user has its last password reset flag active
   *
   * @memberof Auth
   * @param {Object} err - err object described with type and description
   */
  handleResetLastPassword = err => {
    //extract password reset flag with email
    // Handle Last password reset for user
    let errMsgWithEmail = (get(err, "errorDescription") || "").split("?") || [];
    let checkIfUserHasPasswordReset =
      errMsgWithEmail[0] === "password_reset_required";

    if (checkIfUserHasPasswordReset && errMsgWithEmail[1]) {
      let email = errMsgWithEmail[1];

      //set reset password flag
      localStorage.setItem("password-reset-email", email);

      // logout with new user route
      let returnTo = `${this.origin}/password-reset`;
      this.auth0.logout({
        returnTo
      });
    } else {
      history.replace("/login");
    }
  };

  //remove unauthorized flag for user.
  resetUserFlag = () => {
    localStorage.removeItem("unauthorized");
    window.setTimeout(() => this.logout(), 1000);
  };

  getAccessToken() {
    return localStorage.getItem("token");
  }

  getIdToken() {
    return localStorage.getItem("idToken");
  }

  setSession(authResult) {
    this.setAuthCookie(authResult);
    setLocalStorageSession(authResult);
  }

  renewSession(callback) {
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        clearLocalStorageSession();
        this.logout();
        console.log(err);
        return;
      }
      this.setSession(authResult);
      callback && callback(err, authResult);
    });
  }

  logout() {
    this.deleteAthCookie();
    console.debug('logout() entry');
    clearLocalStorageSession();
    localStorage.removeItem("password-reset-email");
    sessionStorage.removeItem("isLastToolSet");

    let returnTo = this.origin + "/login";
    this.auth0.logout({
      returnTo
    });
  }

  isAuthenticated() {
    console.debug('isAuthenticated() entry.');

    return null !== localStorage.getItem("token");
  }

  deleteAthCookie() {
    const domainValue = `; domain=${this._getAuthCookieDomain()}`;
    document.cookie = `auth_token =; Path=/${domainValue}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  setAuthCookie(authResult) {
    const set = (name, value, expiresAt, domain) => {
      let expires = '';
      let domainValue = '';

      if (expiresAt !== undefined) {
        const date = new Date(expiresAt * 1000);
        expires = `; expires=${date.toUTCString()}`;
      }

      if (domain) {
        domainValue = `; domain=${domain}`;
      }

      document.cookie = `${name}=${value || ''}${expires};path=/${domainValue}`;
    };

    const expiresAt = get(authResult, 'idTokenPayload.exp');
    const date = new Date(expiresAt * 1000);

    set('auth_token', window.btoa('true'), date.toUTCString(), this._getAuthCookieDomain());
  }

  isAuthCookiePresent() {
    const authCookieValue = window.btoa('true');
    return document.cookie.split(';').reduce( (isAuthCookieSet, cookie) => {
      const cookieStr = cookie.trim();
      const [cookieName, _] = cookieStr.split('=');

      if (cookieName === 'auth_token') {
        isAuthCookieSet = cookieStr.substring(11) === authCookieValue;
      }

      return isAuthCookieSet;
    }, false);
  }

  _getAuthCookieDomain = () => isLocalhost() ? 'localhost' : process.env.REACT_APP_AUTH_COOKIE_DOMAIN;
}
