import React from "react";
import styled, { css } from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { Close } from "assets/icons";
import { get } from "lodash";

const getColor = props => {
  if (props.isSelected) {
    return hexToRgba(props.theme.COLOR.USER_PRIMARY, "0.7");
  } else {
    return props.theme.COLOR_PALLETE.LIGHT_GREY;
  }
};

const Placeholder = props => {
  let {
    selectedThumbnail,
    selectedThumbnailId,
    layoutType,
    imageMatrix,
    onSelect,
    selectedPlaceholder,
    id,
    placeholderData,
    index,
    deleteSelectedModule,
    enabled,
    deleted
  } = props;

  let isSelected = !!(selectedPlaceholder && id === selectedPlaceholder);

  let placeholderText = `Select a ${placeholderData.layoutId.toLowerCase()} from left panel to add it here.`;

  if (selectedThumbnail && !deleted) {
    return (
      <BorderWrapper
        isSelected={isSelected}
        layoutType={layoutType}
        onClick={e => {
          onSelect({
            event: e,
            index,
            id,
            layoutType,
            imageMatrix,
            ...placeholderData,
            selectedThumbnailId
          });
        }}
      >
        <Thumbnail src={selectedThumbnail} />
        <CloseIconWrapper>
          <CloseIcon
            onClick={e => {
              deleteSelectedModule({
                event: e,
                index,
                id,
                layoutType,
                ...placeholderData
              });
            }}
          />
        </CloseIconWrapper>
      </BorderWrapper>
    );
  } else if (deleted && !isSelected) {
    return (
      <BorderWrapper
        layoutType={layoutType}
        onClick={e => {
          onSelect({
            event: e,
            index,
            id,
            layoutType,
            imageMatrix,
            ...placeholderData,
            selectedThumbnailId: ""
          });
        }}
        isSelected={isSelected || deleted || !enabled}
      >
        <TextWrapper show={isSelected || deleted || !enabled}>
          <Text>{`The selected module was either disabled or deleted. Please select a new module.`}</Text>
        </TextWrapper>
      </BorderWrapper>
    );
  } else {
    // show helper text when placeholder is neither clicked nor any thumbnail is selected for brochure front and back
    let showHelperTextForBrochureFrontBack =
      (get(placeholderData, `layoutId`) === "Front Cover" ||
        get(placeholderData, `layoutId`) === "Back Cover") &&
      !isSelected &&
      !selectedThumbnail;

    return (
      <BorderWrapper
        layoutType={layoutType}
        onClick={e => {
          onSelect({
            event: e,
            index,
            id,
            layoutType,
            imageMatrix,
            ...placeholderData,
            selectedThumbnailId: !selectedThumbnail ? "" : selectedThumbnailId
          });
        }}
        isSelected={isSelected}
        selectedThumbnail={selectedThumbnail}
      >
        {showHelperTextForBrochureFrontBack && (
          <CoverHelperText>{get(placeholderData, `layoutId`)}</CoverHelperText>
        )}
        {!selectedThumbnail ? (
          <TextWrapper show={isSelected}>
            <Text>{placeholderText}</Text>
          </TextWrapper>
        ) : (
          <>
            <Thumbnail src={selectedThumbnail} />
            <CloseIconWrapper>
              <CloseIcon
                onClick={e => {
                  deleteSelectedModule({
                    event: e,
                    index,
                    id,
                    layoutType,
                    ...placeholderData
                  });
                }}
              />
            </CloseIconWrapper>
          </>
        )}
      </BorderWrapper>
    );
  }
};
const BorderWrapper = styled.div`
  margin: 0 auto;
  border-radius: 4px;
  border: 2px dashed ${props => getColor(props)};
  background-color: ${props =>
    !props.selectedThumbnail
      ? props.isSelected
        ? hexToRgba(props.theme.COLOR.USER_PRIMARY, "0.12")
        : props.theme.COLOR.CONTAINER
      : null};
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  position: relative;
`;

const SharedHelperTextCss = css`
  width: 100%;
  padding: 0 4px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;

  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 1)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TextWrapper = styled.div`
  ${SharedHelperTextCss}
  display: ${props => (props.show ? `block` : `none`)};
`;

const Text = styled.span``;

const CoverHelperText = styled.span`
  ${SharedHelperTextCss}
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

export default Placeholder;
