import React from "react";
import BlockPreviewData from "./components/BlockPreviewData";
import { connect } from "react-redux";
import styled from "styled-components";
import { get, findIndex, size } from "lodash";
import {
  SlidePreviewRightArrow as RightArrow,
  SlidePreviewLeftArrow as LeftArrow
} from "assets/icons";
import { Link } from "react-router-dom";

class BlockUploader extends React.Component {
  findIndexOfSelectedBlock = (selectedBlockIdIndex, navigationDirection) => {
    const { onClickPreviewTemplateBlock, templateBlocks } = this.props;
    navigationDirection === "left"
      ? onClickPreviewTemplateBlock(templateBlocks[selectedBlockIdIndex - 1])
      : onClickPreviewTemplateBlock(templateBlocks[selectedBlockIdIndex + 1]);
  };

  render() {
    const { isActiveOnClickTemplateBlocks, templateBlocks } = this.props;
    const nameOfClickedTemplateBlock = get(
      isActiveOnClickTemplateBlocks,
      `name`
    );
    const indexOfSelectedBlock = findIndex(templateBlocks, {
      modifiedId: get(isActiveOnClickTemplateBlocks, `modifiedId`)
    });

    const { templateId } = this.props.match.params;
    return (
      <>
        <div className="upload-blocks">
          <Link exact to={`/summarymaker/build/${templateId}`}>
            <Button visibility={size(templateBlocks)}>New Document</Button>
          </Link>

          <div className="upload-blocks-border">
            {nameOfClickedTemplateBlock ? (
              <>
                <AlignArrows>
                  <LeftArrowStyle
                    isVisible={indexOfSelectedBlock > 0}
                    onClick={() =>
                      this.findIndexOfSelectedBlock(
                        indexOfSelectedBlock,
                        "left"
                      )
                    }
                  >
                    <LeftArrow />
                  </LeftArrowStyle>

                  <Heading>{nameOfClickedTemplateBlock}</Heading>

                  <RightArrowStyle
                    isVisible={indexOfSelectedBlock < templateBlocks.length - 1}
                    onClick={() =>
                      this.findIndexOfSelectedBlock(
                        indexOfSelectedBlock,
                        "right"
                      )
                    }
                  >
                    <RightArrow />
                  </RightArrowStyle>
                </AlignArrows>

                <BlockPreviewData {...this.props} />
              </>
            ) : (
              <HeadingHidden>Block Name</HeadingHidden>
            )}
          </div>
        </div>
      </>
    );
  }
}

BlockUploader.defaultProps = {
  isActiveOnClickTemplateBlocks: {
    name: ""
  }
};

const Button = styled.button`
  padding: 10px;
  background: ${props => props.theme.COLOR.USER_PRIMARY};
  visibility: ${props => (props.visibility ? "visible" : "hidden")};
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
  top: -5px;
`;

const AlignArrows = styled.span`
  position: relative;
  right: 1.5rem;
`;

const HeadingHidden = styled.span`
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 5px 10px;
  visibility: hidden;
`;

const LeftArrowStyle = styled.span`
  margin-right: 15px;
  cursor: pointer;
  visibility: ${props => (props.isVisible ? "default" : "hidden")};
`;

const RightArrowStyle = styled.span`
  margin-left: 15px;
  cursor: pointer;
  visibility: ${props => (props.isVisible ? "default" : "hidden")};
`;

const Heading = styled.span`
  font-size: 26px;
  font-weight: 600;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 4px;
`;

const mapStateToProps = state => {
  const createTemplatePreviewRenderer = state.domain;
  return createTemplatePreviewRenderer;
};

export default connect(mapStateToProps)(BlockUploader);
