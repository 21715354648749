import React, { Component } from "react";
import get from "lodash/get";
import ValidationUtils from "utils/ValidationUtils";
import EditableInput from "./index";

const UI_STRINGS = {
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input."
};

class EditableInputPage extends Component {
  state = {
    isFieldEditable: false,
    titleError: ""
  };

  inputRef = React.createRef();

  inputEditHandler = event => {
    event.stopPropagation();
    let { isAnyValueEdited } = this.props;
    // check if any other field is edited (if there are >1 input fields this condition is applicable)
    if (isAnyValueEdited) return;
    // set isAnyValueEdited so that any other logo can't be edited

    this.props.checkIfFieldEdited && this.props.checkIfFieldEdited(true);
    this.setState(
      {
        isFieldEditable: !this.state.isFieldEditable
      },
      () => {
        get(this.inputRef, `current`) && this.inputRef.current.focus();
      }
    );
  };

  // validation handler
  handleTitleValidation = () => {
    let titleValue = get(this.inputRef, `current.value`);
    let { titleError } = this.state;

    if (ValidationUtils.checkIfEmptyField(titleValue)) {
      titleError = UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfspecialChar(titleValue)) {
      titleError = UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfWhiteSpace(titleValue)) {
      titleError = UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
    } else {
      titleError = null;
    }

    this.setState({
      titleError
    });
  };

  inputSaveHandler = event => {
    event.stopPropagation();
    let { titleError } = this.state;
    let { title = "" } = this.props;
    this.props.checkIfFieldEdited && this.props.checkIfFieldEdited(false);
    if (titleError) {
      this.inputRef.current.focus();
      return;
    }

    // if title is not changed, do not save and show edit icon again
    if (title === get(this.inputRef, `current.value`)) {
      this.setState({
        isFieldEditable: false
      });
      return;
    }

    // cb handler to send the value to the actual component
    this.props.saveInputValueHandler &&
      this.props.saveInputValueHandler(get(this.inputRef, `current.value`));

    this.setState({
      isFieldEditable: false
    });
  };

  onResetHandler = event => {
    event.stopPropagation();
    let { title = "" } = this.props;

    this.inputRef.current.value = title; // reset to the actual value

    this.props.checkIfFieldEdited && this.props.checkIfFieldEdited(false);
    this.setState({
      isFieldEditable: false,
      titleError: ""
    });
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };
    return <EditableInput {...stateMethodProps} />;
  }
}

EditableInputPage.defaultProps = {
  saveInputValueHandler: () => {},
  checkIfFieldEdited: () => {}
};

export default EditableInputPage;
