const SUCCESS_DIVIDER_LIST = "SUCCESS_DIVIDER_LIST";
const LOADING_DIVIDER_LIST = "LOADING_DIVIDER_LIST";
const SUCCESS_BLANK_SLIDE_LIST = "SUCCESS_BLANK_SLIDE_LIST";
const LOADING_BLANK_SLIDE_LIST = "LOADING_BLANK_SLIDE_LIST";

export const DividerBlankTypes = {
  SUCCESS_DIVIDER_LIST,
  LOADING_DIVIDER_LIST,
  SUCCESS_BLANK_SLIDE_LIST,
  LOADING_BLANK_SLIDE_LIST
};
