import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import * as Sentry from '@sentry/react';
import AllProvider from "./Provider";
import TagManager from "react-gtm-module";
import "reset-css";

// Sentry
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

//configure GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};

if (process.env.REACT_APP_GTM_ID !== null) {
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<AllProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
