// libraries
import React from "react";

// components
import CoverBlock from "./pptTemplate/container";
import HtmlTemplate from "./htmlTemplate/container";
import AppendixPageSet from "./appendixPageSet";
import StaticPageSet from "./staticPageSet";
import DataTable from "./datatable/container";
import LayoutBlock from "./layoutBlock/container";

export default function BlockPreviewData(props) {
  const dataTypeFromActiveTemplate = props.isActiveOnClickTemplateBlocks;
  switch (dataTypeFromActiveTemplate.type) {
    case "pptTemplate":
      return <CoverBlock {...props} />;
    case "datatable":
      return <DataTable {...props} />;
    case "staticPageSet":
      return <StaticPageSet {...props} />;
    case "htmlTemplate":
      return <HtmlTemplate {...props} />;
    case "appendixPageSet":
      return <AppendixPageSet {...props} />;
    case "layout":
      return <LayoutBlock {...props} />;
    default:
      return null;
  }
}
