let tableData = [
  {
    templateName: "Flier",
    fields: [
      {
        label: "Name",
        key: "name",
        isRequired: true
      },
      {
        label: "Customer Name",
        key: "customerName",
        isRequired: true
      },
      {
        label: "Created Date",
        key: "createdAt",
        isRequired: true
      },
      {
        label: "Template",
        key: "template.name",
        isRequired: true
      },
      {
        label: "Updated Date",
        key: "updatedAt",
        isRequired: true
      }
    ],
    actionFields: ["canEdit", "canDelete", "canClone"],
    searchFields: ["name", "description", "customerName"]
  }
];

export { tableData };
