import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
//Components
import RadioButton from "components/radioBtn";

export const RepoSelection = props => {
  let {
    selectedRepo,
    handleRepoChanges,
    contentRepoList,
    hidContentRepoList
  } = props;
  return (
    <RepoContaier
      hidContentRepoList={hidContentRepoList}
      className="repoclasss"
    >
      <Heading>Select Repository</Heading>
      {Array.isArray(contentRepoList) &&
        contentRepoList.map((item, index) => {
          return (
            <RadioButtonWrapper key={index}>
              <RadioButton
                radioType="controlled"
                key={index}
                id={index}
                name="repo"
                label={item.title}
                checked={item.title === selectedRepo}
                handleChange={e =>
                  handleRepoChanges({ key: "selectedRepo", value: item.title })
                }
              />
            </RadioButtonWrapper>
          );
        })}
    </RepoContaier>
  );
};

const Heading = styled.h3`
  margin-bottom: 22px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.7)};
`;

const RepoContaier = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 16px 0 40px 12px;
  background: #fff;
  color: ${props => props.theme.COLOR.MAIN};
  box-shadow: 8px 9px 5px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 40px;
  display: ${props => (props.hidContentRepoList ? "block" : "none")};
`;

const RadioButtonWrapper = styled.div`
  margin-top: 10px;
  label {
    color: ${props => props.theme.COLOR.MAIN};
    font-size: 14px;
  }
  .checkmark:after {
    width: 6px;
    height: 6px;
  }

  .checkmark {
    top: 2px;
  }

  label {
    padding-left: 20px;
  }
`;
