import React from "react";
import styled from "styled-components";
import { get } from "lodash";
import CustomBlock from "./components/customBlock";
import { DropTarget } from "react-dnd";
import { QuestionIconSM } from "assets/icons";

const Types = {
  BLOCKITEM: "blockItem"
};

const CUSTOM_BLOCKS_NO_DATA_TEXT =
  "Please drag blocks from above and drop here to configure your template.";

const customBlockTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem();
    return {
      builtinBlockDetail: item
    };
  }
};

// DnD DropTarget - collect
const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
};

const CustomBlockCarousel = props => {
  let { connectDropTarget, customBlocks } = props;
  return connectDropTarget(
    <div>
      <Heading>
        Custom Blocks
        <QuestionIcon />
      </Heading>
      <div className="custom-blocks">
        <CustomBlocks>
          {!get(customBlocks, `length`) && (
            <NoDataText>{CUSTOM_BLOCKS_NO_DATA_TEXT}</NoDataText>
          )}
          <CustomBlock {...props} />
        </CustomBlocks>
      </div>
    </div>
  );
};

const QuestionIcon = styled(QuestionIconSM)`
  height: 16px;
  margin-left: 10px;
`;

const Heading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin: 10px 0 10px 0;
  text-align: left;
`;

const NoDataText = styled.span`
  padding: 0 4px;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.COLOR.SECONDARY};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
`;

const CustomBlocks = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export default DropTarget(
  Types.BLOCKITEM,
  customBlockTarget,
  collect
)(CustomBlockCarousel);
