import React from "react";
import styled, { css } from "styled-components";
import { flatMap } from "lodash";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
import hexToRgba from "utils/hexToRgba";
import "react-accessible-accordion/dist/fancy-example.css";
import { Dropdown } from "assets/icons";
import ListItemContainer from "./components/listItemContainer";

const ContentAccordian = props => {
  const {
    chooseContentData,
    handleChooseContentChecbox,
    checkIfExist,
    preExapandedFlag,
    randomKey,
    highlightText
  } = props;

  let preExpandedArray = [];
  chooseContentData.forEach((ele, index) => {
    preExpandedArray.push(index);
  });

  return (
    <StyledAccordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      preExpanded={preExapandedFlag ? preExpandedArray : []}
      key={randomKey}
    >
      {flatMap(chooseContentData, (item, index) => {
        if (!item) return null;
        const { title, children, documentTypes, _id } = item;

        let preExpandedArraySubCat = [];
        children[1].subCategories.forEach((ele, subCatIndex) => {
          preExpandedArraySubCat.push(`${index}${subCatIndex}`);
        });

        return (
          <AccordionItem key={index} uuid={index}>
            <StyledHead>
              <StyledButton title={title}>
                {highlightText(title)}
                <StyledRightIcon />
              </StyledButton>
            </StyledHead>
            <StyledPanel>
              {children[0].modules.length ? (
                <ListItemContainer
                  modules={children[0].modules}
                  handleChooseContentChecbox={handleChooseContentChecbox}
                  checkIfExist={checkIfExist}
                  IsSubCatModule={false}
                  documentTypes={documentTypes}
                  id={_id}
                  highlightText={highlightText}
                />
              ) : null}
              {children[1].subCategories && (
                // subcatagory UI
                <StyledAccordion
                  allowMultipleExpanded={true}
                  allowZeroExpanded={true}
                  preExpanded={preExapandedFlag ? preExpandedArraySubCat : []}
                  key={randomKey}
                >
                  {flatMap(
                    children[1].subCategories,
                    (subCatItem, subCatIndex) => {
                      const {
                        title,
                        children,
                        documentTypes,
                        _id
                      } = subCatItem;
                      return (
                        <AccordionItem
                          key={subCatIndex}
                          uuid={`${index}${subCatIndex}`}
                        >
                          <StyledHead>
                            <SubCatStyledButton name={title}>
                              {highlightText(title)}
                              <StyledRightIcon />
                            </SubCatStyledButton>
                          </StyledHead>
                          <StyledPanel>
                            {children[0].modules.length && (
                              <ListItemContainer
                                modules={children[0].modules}
                                handleChooseContentChecbox={
                                  handleChooseContentChecbox
                                }
                                checkIfExist={checkIfExist}
                                IsSubCatModule={true}
                                documentTypes={documentTypes}
                                id={_id}
                                highlightText={highlightText}
                              />
                            )}
                          </StyledPanel>
                        </AccordionItem>
                      );
                    }
                  )}
                </StyledAccordion>
              )}
            </StyledPanel>
          </AccordionItem>
        );
      })}
    </StyledAccordion>
  );
};

const StyledHead = styled(AccordionItemHeading)`
  border: 0;
  cursor: pointer;
`;

const ButtonStyledCSS = css`
  outline: 0;
  padding: 6px 18% 7px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
  border-radius: 3px;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  cursor: inherit;
  width: 38.1%;
  margin-right: 11.8%;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.74)};
  user-select: none;
  &[aria-expanded="true"] {
    svg {
      transform: rotate(0);
    }
  }
`;

const StyledButton = styled(AccordionItemButton)`
  ${ButtonStyledCSS}
  background-color: ${props => props.theme.COLOR_PALLETE.LIGHT_BLUE};
`;

const SubCatStyledButton = styled(AccordionItemButton)`
  ${ButtonStyledCSS}
  width: calc(38.1% - 26px);
  background-color: ${props => props.theme.COLOR_PALLETE.SLIGHT_GREY};
`;

const StyledAccordion = styled(Accordion)`
  border: 0;

  .accordion__item + .accordion__item {
    border-top: none;
  }
`;

const StyledPanel = styled(AccordionItemPanel)`
  padding-left: 18px;
  margin-bottom: 20px;
`;

const StyledRightIcon = styled(Dropdown)`
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(-90deg);
  color: ${props => props.theme.COLOR.MAIN};
`;

export default ContentAccordian;
