import React, { Component } from "react";
import { connect } from "react-redux";

import { mapStateToProps, actions } from "./mapStateToProps";

const TabList = [
  {
    title: "Account",
    value: "account"
  },
  {
    title: "Design",
    value: "design"
  },
  {
    title: "Updates",
    value: "updates"
  },
  {
    title: "Search Priorities",
    value: "searchPriorities"
  },
  {
    title: "Announcement",
    value: "announcement"
  },
  {
    title: "Security",
    value: "security"
  },
  {
    title: "Fonts",
    value: "fonts"
  }
];

const container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Container extends Component {
      state = {
        searchKeyWord: null,
        selectedTab: "account",
        isAccountEnabled: true
      };

      componentDidMount() {
        this.fetchSystemConfigurationData();
        this.fetchSearchPrioritiesData();
        let state = this.props.location.state;

        // set props state and then make it empty so to prevent it from being on annoucement taba only
        if (state && state.activeTab) {
          this.setState(
            {
              selectedTab: state.activeTab
            },
            this.makeStateEmpty
          );
        }
      }

      // make props location state empty
      makeStateEmpty = () => {
        if (this.props.location.state) {
          this.props.history.replace({
            state: {}
          });
        }
      };

      componentDidUpdate(prevProps) {
        let state = this.props.location.state || {};

        const { activeTab } = state;
        // make annoucement tab being same page by comparing previous and current state

        if (activeTab && activeTab !== prevProps.activeTab) {
          this.setState(
            {
              selectedTab: activeTab
            },
            this.makeStateEmpty
          );
        }
      }

      // make props state
      componentWillUnmount() {
        this.props.history.replace({
          state: {}
        });
      }

      onTabSelected = selectedTab => {
        this.setState({
          selectedTab: selectedTab.value
        });
      };

      fetchSearchPrioritiesData = async () => {
        await this.props.getSearchPriorities();
      };

      /**
       * Fetch all the annoucements from API
       */
      fetchAnnouncementData = async () => {
        await this.props.getAnnouncementData();
      };

      /**
       * Fetch the System Configuration data
       */
      fetchSystemConfigurationData = async () => {
        await this.props.getSystemConfiguration();

        let systemData = this.props.systemConfigurationData || {};

        let isAccountEnabled = systemData.enable || false;
        this.setState({
          isAccountEnabled: isAccountEnabled
        });
      };

      saveSystemConfigurationData = async body => {
        let id = this.props.systemConfigurationData["_id"];
        let response = await this.props.saveSystemConfiguration(body, id);
        this.setState({
          isAccountEnabled:
            this.props.systemConfigurationData["enable"] || false
        });

        return response;
      };

      _tabHandler = label => {
        this.setState({
          selectedTab: label
        });
      };

      render() {
        const { state } = this;
        const MainProps = {
          ...state,
          ...this.props,
          TabList,
          onTabSelected: this.onTabSelected,
          isLoading: false,
          fetchAnnouncementData: this.fetchAnnouncementData,
          saveSystemConfigurationData: this.saveSystemConfigurationData,
          tabHandler: this._tabHandler
        };

        return <Main {...MainProps} />;
      }
    }
  );
export default container;
