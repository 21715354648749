import { FilterTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

//filters dispatcher
export const onLibraryFilters = (
  payload = {},
  type = FilterTypes.SUCCESS_LIBRARY_FILTERS
) => {
  return {
    type,
    payload
  };
};

export const isLibraryFiltersLoading = (
  payload = {},
  type = FilterTypes.LOADING_LIBRARY_FILTERS
) => {
  return {
    type,
    payload
  };
};

/**
 * Get Library Filters List
 */
const getLibraryFiltersList = contentRepoId => async dispatch => {
  const URL = `/content-repositories/${contentRepoId}/content-filters?excludeEmptyParents=true`;

  // start loading
  dispatch(isLibraryFiltersLoading({ isFiltersLoading: true }));

  const response = await FetchUtils.getData(
    URL,
    "Get Libray By Topic for Setup==>"
  );

   // stop loading
   dispatch(isLibraryFiltersLoading({ isFiltersLoading: false }));
 
    if (response.success && response.data) {
      //dispatch data list on success
      dispatch(
        onLibraryFilters({
          libraryFiltersList: response.data
        })
      );
      // stop loading
      dispatch(isLibraryFiltersLoading({ isLoading: false }));
    } else {
      // stop loading
      dispatch(isLibraryFiltersLoading({ isLoading: false }));
      ToastUtils.handleToast({
        operation: "error",
        message: get(response, "data.error.message")
      });
    }
  
    //return reponse to check in component if required
    return response;
  };

export { getLibraryFiltersList }
