import React from "react";
import styled from "styled-components";
import "intro.js/introjs.css";
import CustomTable from "components/customTable";
import { links, toolTab } from "./data";
import { Link } from "react-router-dom";

// images
import { OptumHOmePage } from "assets/images";

export const LandingPageComponent = props => {
  const title = "Welcome to the Presentation Hub";
  const description = `Whether you’re looking to build a finalist or other sales support presentation – we’ve got the right resources for you to use on the hub. Use the navigation below to find the right tools and resources to work for you.`;
  return (
    <>
      <LandingPageWrapper>
        <PageDetails>
          <TitleDescription>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <Text
              download
              href="https://optum-static-asset.s3.amazonaws.com/homepage/Presentation+support+request_v2.oft"
            >
              Request Presentation Support
            </Text>
          </TitleDescription>

          <RightImage className="right-image">
            <Image className="image" src={OptumHOmePage} />
          </RightImage>
        </PageDetails>

        <ToolTab>
          {toolTab.map(tool => {
            if (tool.downloadLink) {
              return (
                <Tool download href={tool.downloadLink} target="_blank">
                  <ToolDetail>
                    <ToolDescription>
                      <ToolTitle> {tool.title}</ToolTitle>
                      <br />
                      {tool.description}
                      <br />
                      {tool.appendPdfText && (
                        <CustomText href={tool.appendPdfTextLink}>
                          {tool.appendPdfText}
                        </CustomText>
                      )}
                      <br />
                    </ToolDescription>
                  </ToolDetail>
                </Tool>
              );
            } else if (tool.redirect) {
              return (
                <Tool href={tool.redirect} target="_blank">
                  <ToolDetail>
                    <ToolDescription>
                      <ToolTitle> {tool.title}</ToolTitle>
                      <br />
                      {tool.description}
                      <br />
                      {tool.appendPdfText && (
                        <CustomText href={tool.appendPdfTextLink}>
                          {tool.appendPdfText}
                        </CustomText>
                      )}
                      <HelperContainer href={tool.helperTextLink}>
                        {tool.helperText && tool.helperText}
                      </HelperContainer>
                    </ToolDescription>
                  </ToolDetail>
                </Tool>
              );
            }
          })}
        </ToolTab>
        <br />
        <br />

        <ResourceLinks>
          <Resources>
            <CustomTable
              data={props.resourcesData || []}
              tableHeader={props.cols}
              columnWidth={props.columnWidth}
              tableColumnHeader={props.tableColumnHeader}
              renderHead={props.renderHead}
              showIcon={props.showIcon}
              expanderWidth="25"
              _newButton
              _searchBox
              role="admin"
              renderTableCell={props.renderTableCell}
            />
          </Resources>

          <Links>
            <LinkHeading>Quick Links</LinkHeading>
            <LinkTable>
              {links.map(eachLink => {
                return (
                  <QuickLink>
                    <Link
                      onClick={() => {
                        props.redirectLinks(eachLink);
                      }}
                      download
                    >
                      {eachLink.title}
                    </Link>
                  </QuickLink>
                );
              })}
            </LinkTable>
          </Links>
        </ResourceLinks>
      </LandingPageWrapper>
    </>
  );
};
const CustomText = styled.p`
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 1rem;
  padding: 0 2.5rem;
  line-height: normal;
  font-family: ${props => props.theme.FONT.REG};
  position: relative;
  top: 1rem;
`;

const HelperContainer = styled.a`
  color: #4472c4;
  font-size: 1rem;
  text-decoration: underline !important;
  position: relative;
  top: 2rem;
`;

const Text = styled.a`
  color: #4472c4;
  font-size: 1.5rem;
  display: flex;
  text-decoration: underline;
  margin: 1.5rem 0;
`;

const PageDetails = styled.div`
  padding: 80px 0 22px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const TitleDescription = styled.div`
  max-width: 43%;
  display: inline-block;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  padding-bottom: 10px;
  font-size: 32px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

const Description = styled.p`
  padding-top: 5px;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

const RightImage = styled.div`
  vertical-align: top;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 20px 0;
  }
`;

const Image = styled.img`
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const ToolTab = styled.div`
  padding: 15px 0 13px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  a {
    width: 19%;
    text-decoration: none;
  }
`;

const ToolDetail = styled.div`
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.7);
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 45%;
    margin-bottom: 10px;
  }
`;

const ToolDescription = styled.span`
  font-size: 20px;
  line-height: 26px;
  height: 6.2rem;
  font-family: ${props => props.theme.FONT.REG};
  color: #e87722;
  text-decoration: none;
  cursor: pointer;
`;

const ToolTitle = styled.span`
  display: inline-block;
  font-weight: bold;
  color: #e87722;
`;

const ResourceLinks = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Resources = styled.div`
  width: 50%;

  .table-wrapper {
    margin: 0;
    padding: 0;

    .rt-thead.-header {
      min-width: 100% !important;
    }

    .rt-tbody {
      min-width: 100% !important;

      color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
    }

    .rt-th:last-child {
      text-align: center;
      .header-title {
        padding: 0;
        .column-name {
          padding: 0;
        }
      }
    }

    .rt-tr .rt-td:nth-child(2) span a {
      cursor: pointer;
      font-weight: 500;
      color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .rt-tr .rt-td:nth-child(5) {
      text-align: center;
    }
  }

  .heading {
    padding: 10px 0 24px;
    font-size: 30px;
  }

  @media (max-width: 1300px) {
    width: 60%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Links = styled.div`
  width: 42%;

  @media (max-width: 1300px) {
    width: 35%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const LinkHeading = styled.h2`
  padding: 10px 0 24px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

const LinkTable = styled.ul`
  padding: 32px 67px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 4px 8px 20px 0 rgba(0, 0, 0, 0.14);
`;

const QuickLink = styled.li`
  font-size: 15px;
  padding: 15px 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  text-decoration: underline;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};

  a {
    text-decoration: none;
    color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
    text-decoration: underline;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #e87722;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    color: #e87722;
  }
`;

const LandingPageWrapper = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 106px auto 0;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }

  .content {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

const Tool = styled.a``;

export default LandingPageComponent;
