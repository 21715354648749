// libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { get, find } from "lodash";

// components
import Rates from "./index";

//services
import {
  uploadFile,
  uploadFileToAws
} from "tools/summaryMaker/store/actions/uploadFileActions";

const mapStateToProps = state => {
  const {
    domain: {
      SELECTED_TEMPLATE,
      RECEIVE_TEMPLATE_BLOCKS,
      RECEIVE_BUILD_DOCUMENT_DATA
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...SELECTED_TEMPLATE,
    ...RECEIVE_TEMPLATE_BLOCKS,
    ...SUCCESS_USER_PROFILE,
    ...RECEIVE_BUILD_DOCUMENT_DATA
  };
};

const mapDispatchToProps = {
  uploadFile,
  uploadFileToAws
};

class RatesPage extends Component {
  state = {};

  componentDidMount() {
    const currentTab = this.props.getCurrentTabDetails();
    let { currentDocumentData, ratesWorkBookName } = this.props;

    if (!ratesWorkBookName) {
      // get data from redux if not present in container state
      this.props.handleStateChange({
        key: "ratesWorkBookName",
        value: get(
          currentDocumentData,
          `blocks.${get(currentTab, `name`).toLowerCase()}.workbookName`
        )
      });
    }
  }

  uploadFileToRepo = async ({ metaDeta, file }, cb) => {
    const templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = (
      find(templateBlocks, eachBlock => {
        return eachBlock.type === "datatable";
      }) || {}
    )._id;

    delete metaDeta.resource;
    delete metaDeta.title;

    let postData = {
      ...metaDeta,
      fileType: "excel",
      name: get(metaDeta, `fileName`)
    };

    let response = await this.props.uploadFile(
      userId,
      postData,
      documentId,
      contentBlockId
    );

    let { presignedUrl = "" } = get(response, `data`);
    await this.props.uploadFileToAws(presignedUrl, file);

    // empty previewPageDetails to again start build
    this.props.handleStateChange({
      key: "previewPageDetails",
      value: []
    });

    cb();

    this.props.handleStateChange({
      key: "ratesWorkBookName",
      value: postData.name
    });
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Rates {...stateMethodProps} />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RatesPage);
