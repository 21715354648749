import React from "react";
import styled, { css } from "styled-components";
import { map, each, includes, size } from "lodash";
import Checkbox from "components/checkbox";
import container from "./container";

const Deck = ({
  title,
  id,
  childList,
  expand,
  handleParentCheckbox,
  handleExpandDeck,
  selectSlides,
  handleCheckBoxChange,
  addPresentation,
  isRequired,
  handleMouseOver,
  previewThumbnailUrl,
  singlePreviewSlideData,
  handleMouseLeave,
  imageOnload,
  popupPosition,
  popupHorizontalPosition
}) => {
  let selectedSlides = [],
    groupName = "",
    firstElem = false,
    lastElem = false;

  each(childList, ({ _id }) => {
    if (selectSlides.indexOf(_id) > -1 && !includes(selectedSlides, _id)) {
      selectedSlides.push(_id);
    }
  });

  const renderChild = map(childList, (item, index) => {
    let {
      title,
      _id,
      group,
      isRequired: listIsRequired,
      isNew,
      isUpdated
    } = item;
    if (group && groupName !== group.title) {
      groupName = group.title;
      firstElem = true;
    }
    if (
      (childList[index + 1] &&
        group &&
        childList[index + 1].group &&
        childList[index + 1].group.title !== group.title) ||
      (childList.length - 1 === index &&
        childList[childList.length - 1].group) ||
      (childList[index + 1] && group && !childList[index + 1].group)
    ) {
      lastElem = true;
    }

    let className = `${group ? "group" : ""} ${firstElem ? "first" : ""} ${
      firstElem && childList[index - 1] && childList[index - 1].group
        ? "adjacent-group"
        : ""
    } ${lastElem ? "last" : ""}`;

    firstElem = false;
    lastElem = false;

    return (
      <DeckList className={className} key={index} isRequired={listIsRequired}>
        <Checkbox
          label={title}
          id={_id}
          checked={selectedSlides.indexOf(_id) > -1 ? true : false}
          handleChange={e => {
            handleCheckBoxChange(
              e.target.checked,
              _id,
              (group && group.title) || "",
              childList,
              item
            );
          }}
          useButton={true}
          addPresentation={() => addPresentation(_id, childList, item)}
          handleMouseOver={e => handleMouseOver(e, item)}
          handleMouseLeave={handleMouseLeave}
          hideTitle={true}
        />
        {isNew && <NewLabel title="New">N</NewLabel>}
        {isUpdated && <UpdateLabel title="Updated">U</UpdateLabel>}
        {previewThumbnailUrl && (singlePreviewSlideData || {})._id === _id && (
          <PreviewImagewrapper
            popupPosition={popupPosition}
            popupHorizontalPosition={popupHorizontalPosition}
          >
            <PreviewThumbnail src={previewThumbnailUrl} onLoad={imageOnload} />
          </PreviewImagewrapper>
        )}
      </DeckList>
    );
  });

  size(childList) && (title = `${title} (${size(childList)})`);

  return (
    <DeckHolder>
      <DeckHeader
        className={`${
          selectedSlides.length === childList.length ? "full-selected" : ""
        } ${selectedSlides.length > 0 ? "partial-selected" : ""}`}
        isRequired={isRequired}
      >
        <Expand onClick={handleExpandDeck} expand={expand}></Expand>
        <Checkbox
          label={title}
          id={id}
          checked={selectedSlides.length === childList.length}
          handleChange={handleParentCheckbox}
          deck={true}
        />
      </DeckHeader>
      {expand && expand ? <DeckListHolder>{renderChild}</DeckListHolder> : ""}
    </DeckHolder>
  );
};

const BorderCSS = css`
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  background-color: ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
  opacity: 0.3;
`;

const DeckHolder = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;

  .group {
    background: #eef7f9;
    &:first-of-type {
      padding-top: 4px;
    }
  }

  .first {
    padding-top: 4px;
    position: relative;

    &:not(:first-child) {
      margin-top: 2px;
    }

    &:after {
      ${props => props.theme.SNIPPETS.BOX_SHADOW_DARK};
      ${BorderCSS};
      top: 0;
    }
  }

  .group:not(:first-of-type).adjacent-group {
    margin-top: 10px;
  }

  .last {
    padding-bottom: 7px;
    position: relative;
    &:before {
      ${BorderCSS};
      bottom: 0;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.38);
    }
  }
`;

const RequiredCheckBox = css`
  input[type="checkbox"] {
    ${props => props.isRequired && `cursor :not-allowed`}
    &:checked {
      ${props =>
        props.isRequired &&
        `background-color: ${props.theme.COLOR_PALLETE.BROWNISH_GREY}; border: solid 2px ${props.theme.COLOR_PALLETE.BROWNISH_GREY};`}
    }
  }
`;

const DeckHeader = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  .checkbox-container {
    width: 100%;
    padding: 4px 0 4px 12%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    input {
      position: absolute;
      top: 7px;
      left: 5%;
    }

    label {
      width: 80%;
      display: inline-block;
      vertical-align: middle;
      /* text-overflow: ellipsis; */
      /* white-space: nowrap; */
      /* overflow: hidden; */
    }
    ${RequiredCheckBox}
  }

  &.partial-selected {
    input {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      border: solid 2px ${props => props.theme.COLOR.USER_PRIMARY};
      ${props =>
        props.isRequired &&
        `background-color: ${props.theme.COLOR_PALLETE.BROWNISH_GREY}; border: solid 2px ${props.theme.COLOR_PALLETE.BROWNISH_GREY};`}
      &::after {
        display: none;
      }
    }
  }

  &.full-selected {
    input {
      &::after {
        display: block;
      }
    }
  }
`;

const Expand = styled.button`
  width: 10px;
  height: 10px;
  border: none;
  display: inline-block;
  position: absolute;
  left: -6px;
  top: 13px;
  z-index: 1;
  transform: translateY(-50%);
  background-color: transparent;
  outline: none;
  cursor: pointer;
  &:after {
    content: "";
    width: 12px;
    height: 2px;
    display: ${props => (!props.expand ? "block" : "none")};
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
    transform: translate(-50%, -50%) rotate(270deg);
  }
  &:before {
    content: "";
    width: 12px;
    height: 2px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: ${props => props.theme.COLOR_PALLETE.COOL_BLUE};
    transform: translate(-50%, -50%);
  }
`;

const DeckListHolder = styled.ul`
  margin-left: 11%;
`;

const DeckList = styled.li`
  position: relative;
  .checkbox-container {
    width: 90%;
    padding: 4px 6%;
    display: inline-block;
    ${RequiredCheckBox}
  }

  button {
    padding: 5px ​2% 4px;
    color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  }
`;

const UpdateLabel = styled.span`
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  padding: 4px 5px 3px 7px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background-color: ${props => props.theme.COLOR.DODGER_BLUE};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  font-weight: bold;
  position: relative;
  right: 10px;
  top: 5px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
`;

const NewLabel = styled.span`
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  padding: 4px 5px 3px 7px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background-color: ${props => props.theme.COLOR_PALLETE.APPLE_GREEN};
  color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  font-weight: bold;
  position: relative;
  right: 10px;
  top: 5px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
`;

const PreviewImagewrapper = styled.figure`
  width: 300px;
  border: 1px solid #cccccc;
  position: absolute;
  z-index: 4;
  ${props =>
    props.popupPosition ? "visibility: visible" : "visibility: hidden"};
  ${props =>
    props.popupHorizontalPosition === "left" ? "left: 6%" : "right: 0"};
  ${props => (props.popupPosition === "bottom" ? "top: 30px" : "bottom: 30px")};
`;

const PreviewThumbnail = styled.img`
  width: 100%;
  display: block;
`;

export default container(Deck);
