const RECEIVE_PREVIEW_IMAGES = "RECEIVE_PREVIEW_IMAGES";
const REQUEST_PREVIEW_DATA = "REQUEST_PREVIEW_DATA";
const RECEIVE_PAGE_IMAGE = "RECEIVE_PAGE_IMAGE";
const RECEIVE_DOCUMENT_STATUS = "RECEIVE_DOCUMENT_STATUS";

export const PreviewTypes = {
  RECEIVE_PREVIEW_IMAGES,
  REQUEST_PREVIEW_DATA,
  RECEIVE_PAGE_IMAGE,
  RECEIVE_DOCUMENT_STATUS
};
