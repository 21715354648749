// libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import LandingPageComponent from "./index";
import { data } from "./data";
import styled, { css } from "styled-components";
import { CopyLink } from "assets/icons";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";

//services
import { getProfileDetail } from "pages/app/services/getUserDetail";

const mapStateToProps = state => {
  const { SUCCESS_USER_PROFILE, SUCCESS_USER_LIST, MENU_STATE } = state;

  return {
    ...SUCCESS_USER_PROFILE,
    ...SUCCESS_USER_LIST,
    ...MENU_STATE
  };
};

document.execCommand("copy");

const mapDispatchToProps = {
  getProfileDetail
};

class LandingPageContainer extends Component {
  state = {
    resourcesData: data,
    cols: [
      {
        col1: "Name",
        col2: "Types",
        col3: "Notes",
        col4: "Action"
      }
    ],
    columnWidth: [240, 80, 200, 78],
    tableColumnHeader: ["name", "types", "notes", ""]
  };

  componentDidMount() {
    //check if token is present to get profile data
    if (localStorage.getItem("token")) {
      this.props.getProfileDetail(true);
    }
    // set user detail profile if present and get presentation meta details
    this.setUserProfile();
  }

  componentDidUpdate(prevProps) {
    // fetch and store user detail to fetch user id and get presentation required detail.
    if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
      this.setUserProfile();
    }
  }

  redirectLinks = LinkData => {
    LinkData.redirect
      ? this.props.history.push(LinkData.redirect)
      : LinkData.redirectLink
      ? window.open(LinkData.redirectLink)
      : (document.location.href = LinkData.downloadLink);
  };

  renderHead = () => {
    return (
      <div className="heading">
        <HeadingName>Internal Resources</HeadingName>
      </div>
    );
  };

  // function to show last column in table
  showIcon = rowData => {
    return (
      <CopyLinkIconWrapper title="Copy Link">
        <CopyLink onClick={() => this.copyLink(rowData)} />
      </CopyLinkIconWrapper>
    );
  };

  /**
   * Get User Profile based on API respone and store in local state
   *
   */
  setUserProfile = () => {
    const { userProfileMeta } = this.props;
    userProfileMeta &&
      this.setState({
        userDetail: userProfileMeta
      });
  };

  copyLink = rowData => {
    let dummy = document.createElement("textarea");
    let copyText = rowData.original.redirectLink
      ? rowData.original.redirectLink
      : rowData.original.downloadLink;
    document.body.appendChild(dummy);
    dummy.value = copyText;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    ToastUtils.handleToast({
      operation: "success",
      message: "Link copied successfully"
    });
  };

  /**
   * called for rendering table cells
   */
  renderTableCell = ({ row, col, highlightText }) => {
    if (col === "Name") {
      return (
        <a
          title={row.value}
          download
          onClick={() => {
            this.redirectLinks(row.original);
          }}
        >
          {get(row, `value`)}
        </a>
      );
    } else if (col === "Types") {
      return <span title={row.value}>{get(row, `value`)}</span>;
    } else if (col === "Notes") {
      return <span title={row.value}>{get(row, `value`)}</span>;
    } else if (col === "Action") {
      return <span> {this.showIcon(row)} </span>;
    } else if (typeof row.value !== "object") {
      return <span title={row.value}> {highlightText(row.value)} </span>;
    }
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <LandingPageComponent {...stateMethodProps} />;
  }
}

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
`;

const SharedIconWrapperCss = css`
  padding: 0 8px;
  height: auto;
  display: inline-block;
  svg:hover {
    opacity: 0.7;
  }

  svg {
    height: 17px;
    cursor: pointer;
  }
`;

const CopyLinkIconWrapper = styled.span`
  ${SharedIconWrapperCss};
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageContainer);
