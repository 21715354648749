import React from "react";
import styled from "styled-components";

import Container from "./container";
import Checkbox from "components/checkbox";
import SlidesWithGroup from "./components/slidesWithGroup";
import GroupOptionContainerMain from "../GroupOptionContainerMain";

const ContentSlideGroup = props => {
  let { groupDetails, handleChange, contentSlideUpdate, isLoading } = props;

  let {
    selectedGroup,
    searchValue,
    isSearching,
    slideList,
    searchSlideList,
    groupId,
    errorEmptyList,
    categoryDetail
  } = groupDetails;

  return (
    <GroupContainer>
      <GroupOptionContainerMain {...props} isLoading={isLoading} />
      <RearrangeBlock>
        <Checkbox
          id="rearrange"
          label="User may rearrange and/remove slides from group."
        />
      </RearrangeBlock>
      <SlidesWithGroup
        handleChange={handleChange}
        searchList={searchSlideList}
        selectedGroup={selectedGroup}
        searchValue={searchValue}
        isSearching={isSearching}
        slideList={slideList}
        contentSlideUpdate={contentSlideUpdate}
        groupId={groupId}
        errorEmptyList={errorEmptyList}
        categoryDetail={categoryDetail}
      />
    </GroupContainer>
  );
};

export default Container(ContentSlideGroup);

const GroupContainer = styled.div`
  font-family: "Maven Pro", sans-serif;
  padding: 16px 15px 17px 12px;
`;

const RearrangeBlock = styled.div`
  margin: 19px 0;
  visibility: hidden;

  .checkbox-container {
    input {
      margin: 4px 0 0;
      display: inline-block;
      vertical-align: top;
    }

    label {
      width: calc(93% - 20px);
      margin-left: 18px;
      display: inline-block;
    }
  }
`;
