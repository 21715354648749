const data = [
  {
    name: "Strategy + ROS Document (Sales)",
    types: "DOCX",
    notes: "Sales Strategy Document + ROS",
    downloadLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/10212021+Strategy+%2B+ROS+thks.docx"
  },
  {
    name: "UHC PowerPoint Template",
    types: "PPTX",
    notes: "Wide PowerPoint Template for UnitedHealthcare",
    downloadLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/uhc_widescreen_2020-11-06_v1.pptx"
  },
  {
    name: "UHC Brand Resources",
    types: "Website",
    notes: "Brand resources for UnitedHealthcare",
    redirectLink:
      "https://authgateway3.entiam.uhg.com/idp/prp.wsf?wa=wsignin1.0&wtrealm=urn%3ahub%3auhg&wctx=https%3a%2f%2fhub.unitedhealthgroup.com%2fsites%2fhub%2fUnitedHealthcare%2fResources%2fBrand-Resources%2f_layouts%2f15%2fAuthenticate.aspx%3fSource%3d%252Fsites%252Fhub%252FUnitedHealthcare%252FResources%252FBrand%252DResources%252FPages%252FHome%252Easpx&wreply=https%3a%2f%2fhub.unitedhealthgroup.com%2f_trust%2fdefault.aspx"
  },
  {
    name: "Recent Finalist - Samaritan Health Plans",
    types: "PPTX",
    notes: "Sample Finalist Presentation",
    redirectLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Samaritan+Health+Plans+Finalist+20220217+Final.pptx"
  },
  {
    name: "Recent Finalist - MetroPlus Health Plan",
    types: "PPTX",
    notes: "Sample Finalist Presentation",
    redirectLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/MetroPlus+Health+Plan+Finalist+DRAFT+01252022+V22.pptx"
  },
  {
    name: "Recent Finalist - Charter Communications",
    types: "PPTX",
    notes: "Sample Finalist Presentation",
    redirectLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Charter+Communications+Finalist+01132022+FINAL+1.1.pptx"
  },
  {
    name: "Recent Finalist - AEP",
    types: "PPTX",
    notes: "Sample Finalist Presentation",
    redirectLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/AEP+Finalist+Presentation_2.25.22_Final+Slide+Deck_INTERNAL.pptx"
  },
  {
    name: "OptumRx PowerPoint Template",
    types: "PPTX",
    notes: "Wide PowerPoint Template for OptumRx",
    downloadLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Rx-2022-01-13.pptx"
  },
  {
    name: "Optum Specialty PowerPoint Template",
    types: "PPTX",
    notes: "Standard PowerPoint Template for Optum Specialty Pharmacy",
    downloadLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Specialty-2022-01-13.pptx"
  },
  {
    name: "Optum Brand Resources",
    types: "Website",
    notes: "Brand resources for Optum",
    redirectLink:
      "https://hub.uhg.com/sites/hub/Optum/Resources/Brand/Pages/default.aspx"
  },
  {
    name: "Connect To Market",
    types: "Website",
    notes:
      "Internal sales and client management support site for carve-in and UHC branded materials",
    redirectLink:
      "http://ctm.uhc.com/content/ctm/key-accounts/en/products-and-services/a-z-products-services-list/pharmacy.html"
  }
];

const toolTab = [
  {
    title: "SLIDE",
    description: "LIBRARY",
    redirect: "/library/build",
    appendPdfText: "Search, preview and download slides",
    helperText: "Help Guide",
    helperTextLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/OptumRx-UserGuide_final.pdf"
  },
  {
    title: "NEW! PRESENTATION",
    description: "GENERATOR",
    redirect: "/presentation/list",
    appendPdfText: "Create co-branded presentations",
    helperText: "Help Guide",
    helperTextLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Presentation+Generator+User+Guide+V1+KS+(1).pdf"
  },
  {
    title: "NEW! MICROSITE",
    description: "GENERATOR",
    appendPdfText: "Create custom prospect sites",
    redirect: "https://msm.optum.clatter.io"
  },
  {
    title: "NEW! VIDEO",
    description: "LIBRARY",
    appendPdfText: "Download and save video links",
    redirect: "https://msmsites.optum.clatter.io/sites/videos/library"
  },
  {
    title: "VIRTUAL FINALIST",
    description: "PLAYBOOK(PDF)",
    appendPdfText: "Step-by-step guide to execute meetings",
    downloadLink: "https://clatter.io/files/optum/ORx-vfp.pdf"
  }
];

const links = [
  {
    title: "Subject Matter Expert Listing",
    downloadLink: "https://clatter.io/files/optum/sme.pdf"
  },
  {
    title: "Professional Printing",
    downloadLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Professional+Printing.pdf"
  },
  {
    title: "Client Experience Centers and Tours",
    downloadLink:
      "https://optum-static-asset.s3.amazonaws.com/homepage/Client+Tours.pdf"
  },
  {
    title: "Instructions: Video Recording",
    downloadLink:
        "https://optum-static-asset.s3.amazonaws.com/homepage/Instructions+for+Video+Recording.pdf"
  }
];

export { data, links, toolTab };
