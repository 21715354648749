import React, { PureComponent } from "react";
import styled from "styled-components";
import { get, filter, trim } from "lodash";
import ValidationUtils from "utils/ValidationUtils";
import uniqId from "uniqid";
import ToastUtils from "utils/handleToast";
import { connect } from "react-redux";
import { PollingUtils } from "utils/PollingUtils";

//components
import { Delete, Show, Hidden } from "assets/icons";
import { mapStateToProps, actions } from "./mapStateToProps";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

const UI_STRINGS = {
  DELETE_THEME:
    "Do you want to remove this template from existing presentations?",
  EMPTY_FIELD_ERROR_MESSAGE: "This field is required.",
  SPECIAL_CHAR_ERROR_MESSAGE: "Please do not enter the special character.",
  WHITE_SPACE_ERROR_MESSAGE: "Please enter a valid input.",
  UNSAVED_PROMPT_ERROR:
    "You haven't saved your progress. Hitting refresh or back will lose your work."
};

const Container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class SortableTree extends PureComponent {
      constructor(props) {
        super(props);
        this.state = {
          treeData: [],
          showThemePreview: false,
          activeThemeDetails: {},
          disableUploadButton: false,
          validFileStatus: null,
          themeNameError: "",
          previewDataPayload: [],
          isEdited: false,
          hideContinue: false
        };

        this.themeNameRef = React.createRef();
      }

      pollingStack = {};
      componentDidMount() {
        window.addEventListener("beforeunload", this.checkIfThemeUploading);

        this.startingPolling();
        this.getContentRepoName();
      }

      startingPolling = async () => {
        await this.fetchThemeList();
        let { treeData } = this.state;
        treeData.forEach(item => {
          if (item.ingestId) {
            const pollingId = uniqId();
            this.pollingStack[pollingId] = new PollingUtils();
            this.pollingStack[pollingId].startPolling({
              pollingAction: () => {
                // start polling using ingestId
                this.pollingActionForUpload(
                  { id: item.ingestId },
                  pollingId,
                  true
                );
              },
              timeoutDuration: 600000, // 10 min
              timeoutCallback: () => {
                this.props.stopThemeUpload();
                this.pollingStack[pollingId].stopPolling();
                ToastUtils.handleToast({
                  operation: "error",
                  message:
                    "File upload is taking too long. Please try again later."
                });
              }
            });
          }
        });
      };

      checkIfThemeUploading = e => {
        if (this.state.disableUploadButton) {
          e.preventDefault();
          e.returnValue = UI_STRINGS.UNSAVED_PROMPT_ERROR;
        } else {
          return null;
        }
      };

      componentWillUnmount() {
        window.removeEventListener("beforeunload", this.checkIfThemeUploading);

        let pollingKey = Object.keys(this.pollingStack);
        this.clearAllPolling(pollingKey);
      }

      clearAllPolling = (pollingKey = []) => {
        pollingKey.forEach(item => {
          this.pollingStack[item].stopPolling();
        });
      };

      // manage valid file status
      manageValidFileStatus = obj => {
        this.setState(obj);
      };

      fetchThemeList = async flag => {
        await this.props.getThemeList(
          this.props.match.params.contentRepoId,
          flag
        );
        this.setState({
          treeData: this.props.themeListData || {}
        });
      };

      getContentRepoName = () => {
        this.props.getContentRepoName(this.props.match.params.contentRepoId);
      };

      /**
       * Function to handle Drag drop changes
       * @param {Object} This will have the updated tree object of the data
       */
      handleTreeOnChange = treeData => {
        this.setState(
          {
            treeData
          },
          () => this.changeThemeOrder(treeData)
        );
      };

      /**
       * Reorder themes after drag and drop
       * @param {array} themes
       */
      changeThemeOrder = async themes => {
        let themeIds = Array.isArray(themes)
          ? themes.map(({ _id }) => _id)
          : [];
        themeIds.length &&
          (await this.props.reorderThemes(
            this.props.match.params.contentRepoId,
            { themeIds }
          ));
      };

      /**
       * Function to generate the icons that are required on the drag drop container
       */
      generateButtonNodeList = rowInfo => {
        let { enable, _id: themeId, ingestId } = rowInfo;
        let message = `Theme has been successfully ${
          !enable ? "enabled" : "disabled"
        }.`;

        return [
          <DeleteStyledIcon
            onClick={() => this.deleteDeactivateTheme(themeId, enable)}
            title="Delete"
            ingestId={ingestId}
          >
            <DeleteIcon />
          </DeleteStyledIcon>,
          <StyledIcon
            title={enable ? "Enabled" : "Disabled"}
            onClick={() =>
              this.saveEditedThemeDetails({ enable: !enable }, themeId, message)
            }
            ingestId={ingestId}
          >
            {enable ? <Show size={15} /> : <Hidden size={15}></Hidden>}
          </StyledIcon>
        ];
      };

      /**
       * Delete or deactivate a theme
       */
      deleteDeactivateTheme = (themeId, enable) => {
        let { contentRepoId } = this.props.match.params;
        DeleteConfirmationAlert({
          message: UI_STRINGS.DELETE_THEME,
          note: `Clicking on "Yes" will delete the theme permanently and remove from the existing presentations.
          ${
            enable
              ? '<br/> Clicking on "No" will disable the theme and keep them in existing presentations.'
              : ""
          }
          `,
          onYesClick: async () => {
            await this.props.deleteOrDeactivateTheme(
              contentRepoId,
              themeId,
              "deleted"
            );

            this.fetchThemeList();
          },
          onNoClick: async (flag = true) => {
            let msg = `Theme has been successfully disabled`;
            flag &&
              enable &&
              this.saveEditedThemeDetails({ enable: false }, themeId, msg);
          }
        });
      };

      /**
       * Function to handle the preview Modal of the theme
       */
      _handleTitleClick = selectedId => {
        let themeList = this.props.themeListData || {};

        let activeThemeData = filter(themeList, eachTheme => {
          return eachTheme._id === selectedId;
        });
        this.setState({
          showThemePreview: true,
          activeThemeDetails: activeThemeData[0]
        });
      };

      /**
       * Function to handle the Modal Close state
       */
      handleModal = flag => {
        let { activeThemeDetails, isEdited } = this.state;
        if (isEdited) activeThemeDetails.subMasterDetails = null;

        this.setState({
          showThemePreview: false,
          isEdited: false,
          hideContinue: false
        });
      };

      /**
       * function to save edited data
       * @param {Object} body data to be posted
       * @param {String} themeId current edited theme
       */
      saveEditedThemeDetails = async (body, themeId, message) => {
        let contentRepositoryId = get(this.props.match.params, `contentRepoId`);

        await this.props.saveTheme(contentRepositoryId, themeId, body, message);
        this.fetchThemeList();
      };

      saveEditedTaggedSlide = async (body, themeId, flag) => {
        this.setState({ showThemePreview: flag });
        let { isEdited, activeThemeDetails } = this.state;
        let contentRepositoryId = get(this.props.match.params, `contentRepoId`);
        await this.props.saveSlideTagging(contentRepositoryId, themeId, body);
        if (isEdited) {
          await this.fetchThemeList();
          let themeList = this.props.themeListData || {};
          let activeThemeData = filter(themeList, eachTheme => {
            return eachTheme._id === themeId;
          });

          activeThemeDetails = activeThemeData[0];
        }
        let ingestId = activeThemeDetails.ingestId;
        this.handleStateChange({ key: "isEdited", value: false });
        this.handleStateChange({ key: "hideContinue", value: false });
        if (
          this.props.taggingComplete &&
          this.props.taggingComplete.success &&
          ingestId
        ) {
          const pollingId = uniqId();
          this.pollingStack[pollingId] = new PollingUtils();
          this.pollingStack[pollingId].startPolling({
            pollingAction: () => {
              // start polling using ingestId
              this.pollingActionForUpload({ id: ingestId }, pollingId);
            },
            timeoutDuration: 600000, // 10 min
            timeoutCallback: () => {
              this.props.stopThemeUpload();
              this.pollingStack[pollingId].stopPolling();
              ToastUtils.handleToast({
                operation: "error",
                message:
                  "File upload is taking too long. Please try again later."
              });
            }
          });
        }
      };

      handleFileChange = () => {
        let themeNameError = this.handleTextValidation(
          this.themeNameRef.current.value
        );

        this.setState({
          themeNameError
        });

        if (themeNameError) {
          return true;
        }
        return false;
      };

      /**
       *
       * @param {Object} { metaDeta, file } metaData of the file to be uploaded, original file
       * @param {*} cb callback function to clear the file drop zone
         to clear the file drop zone
       */
      uploadFileToRepo = async ({ metaDeta, file }, cb) => {
        metaDeta.title = trim(get(this.themeNameRef, `current.value`));
        delete metaDeta.resource;

        this.manageValidFileStatus({ disableUploadButton: true });

        // post metaData to get presigned url and ingestID
        await this.props.createTheme(
          this.props.match.params.contentRepoId,
          metaDeta
        );
        let { themePresignedIngestDetails } = this.props || {};

        if (!Object.keys(themePresignedIngestDetails).length) {
          this.manageValidFileStatus({ disableUploadButton: false });
          cb && cb();
          return;
        }

        const { presignedUrl, ingestId } = themePresignedIngestDetails || {};
        // use presigned url to upload file to aws
        await this.props.uploadThemeToAws(presignedUrl, file);
        if (this.props.themeUploadStatus) {
          this.themeNameRef.current.value = "";
          this.manageValidFileStatus({ disableUploadButton: false });
          cb && cb();
          const pollingId = uniqId();
          this.pollingStack[pollingId] = new PollingUtils();
          this.pollingStack[pollingId].startPolling({
            pollingAction: () => {
              // start polling using ingestId
              this.pollingActionPreviewData({ id: ingestId }, pollingId);
            },
            timeoutDuration: 930000, // theme upload step function has timeout of 15min, let's set FE one to 15min 30s
            timeoutCallback: () => {
              this.props.stopThemeUpload();
              this.pollingStack[pollingId].stopPolling();
              ToastUtils.handleToast({
                operation: "error",
                message:
                  "Preview data loading is taking too long. Please try again later."
              });
            }
          });
        }
      };

      pollingActionPreviewData = async ({ id }, pollingId) => {
        this.props.getPreviewDataPollingStatus &&
          (await this.props.getPreviewDataPollingStatus(id));
        let { previewDataPollingStatus } = this.props || {};

        if (get(previewDataPollingStatus, `data.status`) === "File Parsed") {
          this.pollingStack[pollingId].stopPolling();
          await this.fetchThemeList(true);
          this._handleTitleClick(previewDataPollingStatus.data.theme);
          this.props.hideProgressBar();
          this.setState({
            showThemePreview: true
          });
        } else if (get(previewDataPollingStatus, `data.status`) === "Failed") {
          this.pollingStack[pollingId].stopPolling();
          this.setState({ disableUploadButton: false });

          ToastUtils.handleToast({
            operation: "error",
            message: get(previewDataPollingStatus, `data.message`)
          });
        }
      };

      pollingActionForUpload = async ({ id }, pollingId, flag) => {
        this.props.getFilePollingStatus &&
          (await this.props.getFilePollingStatus(id));
        let { filePollingStatus } = this.props || {};
        if (get(filePollingStatus, `data.status`) === "File Parsed" && flag) {
          this.pollingStack[pollingId].stopPolling();
          return;
        }

        if (get(filePollingStatus, `data.status`) === "Completed") {
          // stop polling if status is completed
          this.pollingStack[pollingId].stopPolling();
          this.setState({ disableUploadButton: false });

          // If error log consists data it means the theme uploaded consists for
          // invalid characters, due to which the uploaded theme will not fetch proper images.
          if (get(filePollingStatus, `data.errorLog`).length) {
            ToastUtils.handleToast({
              operation: "warning",
              message: this._getWarningMessage(
                get(filePollingStatus, `data.errorLog`)
              )
            });
          } else {
            ToastUtils.handleToast({
              operation: "success",
              message: "Theme has been successfully uploaded."
            });
          }
          this.fetchThemeList();
        } else if (
          // stop request polling if service status is not authenticated or status is failed
          get(filePollingStatus, `status` !== 200) ||
          get(filePollingStatus, `data.status`) === "Failed"
        ) {
          this.setState({ disableUploadButton: false });
          this.pollingStack[pollingId].stopPolling();
          ToastUtils.handleToast({
            operation: "error",
            message: `<span style="font-weight: 900;">The uploaded theme has the following errors. Please rectify and reupload:</span>
            <ul style="list-style: disc; font-weight: 900; margin-left: 15px;">
              <li>${get(filePollingStatus, `data.message`)}</li>
            </ul>`
          });

          await this.fetchThemeList();
        }
      };

      /**
       * Get the warning message for invalid characters in placeholder
       */
      _getWarningMessage = (warningArr = []) => {
        if (!warningArr.length) return;

        let subMessage = ``;

        warningArr.forEach((eachSubmaster = {}) => {
          if (Array.isArray(eachSubmaster.invalidPlaceholdersName)) {
            subMessage += `<li><u>${eachSubmaster.title
              .charAt(0)
              .toUpperCase() + eachSubmaster.title.slice(1)}</u> - 
                  ${eachSubmaster.invalidPlaceholdersName.join(", ")}
                </li>`;
          }
        });

        let message = `
          <div><span>Your theme has been successfully uploaded.<span><div>
          <div><span>The placeholder values should not consist of the following special characters(\\/=?\`<>).<span><div>
          <div><span>As the below sub-master(s) contain these characters, they will have issues  while fetching images in placeholder:<span><div>
            <ul style="list-style: disc; margin-left: 15px;">
              ${subMessage}
          </ul>
          `;

        return message;
      };

      handleTextValidation = value => {
        if (ValidationUtils.checkIfEmptyField(value)) {
          return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(value)) {
          return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        } else {
          return null;
        }
      };

      handleStateChange = ({ key, value }) => {
        this.setState({
          [key]: value
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props,
          handleTitleClick: this._handleTitleClick,
          handleModal: this.handleModal
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

const DeleteIcon = styled(Delete)`
  width: 14px;
  height: 15px;
`;

const StyledIcon = styled.span`
  pointer-events: ${props => (props.ingestId ? "none" : "initial")};
  cursor: pointer;
`;

const DeleteStyledIcon = styled.span`
  pointer-events: ${props => (props.ingestId ? "none" : "initial")};
  cursor: pointer;
`;

export default Container;
