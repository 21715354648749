import React from "react";
import styled from "styled-components";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

// components
import StepDetails from "components/buildProcess/stepDetails";
import FullPageLoader from "components/FullPageLoader";
import ViewTabs from "components/ViewTabs";
import { RepoSelection } from "./component/repoOption";
import { Filters } from "./component/Filters";
import SlidePreview from "components/slidePreview";
import featureFlags from "utils/featureFlags";
import ReactHintFactory from "react-hint";
import Build from "./component/build";
import { get, find } from "lodash";

const ReactHint = ReactHintFactory(React);

/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <CustomHintContent>
      <span>{content}</span>
    </CustomHintContent>
  );
};

const UISTRINGS = {
  VIEW_BY_TOPIC:
    "Whether you’re looking to build a finalist presentation or other sales support presentation – we’ve got the right resources for you. Please use the navigation on the left to access the appropriate slide repository.",
  VIEW_BY_TOPIC_REQUIRED:
    "<br><span class='bolde-note'>Slides with gray checkbox(es) are required and cannot be removed from presentation.</span>",
  VIEW_BY_SEARCH:
    "Enter a keyword or phrase below to find content. You can add a slide directly to your presentation from the search results.",
  VIEW_BY_SEARCH_REQUIRED:
    "<br><span class='bolde-note'>Gray cross button(s) are required slides and cannot be removed from presentation.</span>",
  APPENDIX_LIMIT:
    "You have exceeded 150 slides. Overview slide won't be included in your presentation.",
  SKIPDONE_LABEL: "Close and Get Started"
};

export const LibraryComponent = props => {
  let {
    selectedTabValue,
    maximumSlideCount,
    showModal,
    closeModal,
    addRemoveSlideFromPreview,
    hideModal,
    onPreview,
    requiredSlides,
    selectSlides,
    isRepoLoading,
    isThemeLoading,
    isSearchLoading,
    isTopicLoading,
    isFiltersLoading,
    isSlideDetailLoading,
    isBUildScreen,
    onBuildHandler,
    filters,
    contentRepoList,
    isBlankSlideListLoading,
    isDividerListLoading,
    isCoverListLoading,
    steps,
    onExit,
    stepsEnabled,
    viewByTopicSearch,
    isSideMenuOpen
  } = props;

  let slidesLength = selectSlides && selectSlides.length;
  let hidContentRepoList = (contentRepoList || []).length > 1;
  let isFilterActive =
    get(featureFlags, "presentation.showPresentationFilter") && filters.length;
  let isFullWidth = hidContentRepoList || isFilterActive;
  let firstTimeTour = steps;

  // remove content repo step
  if (
    stepsEnabled &&
    !hidContentRepoList &&
    find(firstTimeTour, { element: ".repoclasss" })
  ) {
    firstTimeTour.splice(0, 1);
  }

  let requiredSlideMessage =
    Array.isArray(requiredSlides) && requiredSlides.length
      ? selectedTabValue === "View by Topic"
        ? UISTRINGS.VIEW_BY_TOPIC_REQUIRED
        : UISTRINGS.VIEW_BY_SEARCH_REQUIRED
      : "";

  let libraryDescription =
    selectedTabValue === "View by Topic"
      ? UISTRINGS.VIEW_BY_TOPIC + requiredSlideMessage
      : UISTRINGS.VIEW_BY_SEARCH + requiredSlideMessage;

  let isShowFullPageLoader =
    isRepoLoading ||
    isThemeLoading ||
    isSearchLoading ||
    isTopicLoading ||
    isFiltersLoading ||
    isSlideDetailLoading ||
    isBlankSlideListLoading ||
    isDividerListLoading ||
    isCoverListLoading;

  let showTeps =
    (contentRepoList || []).length &&
    !isSideMenuOpen &&
    (steps || []).length > 1;

  return (
    <>
      {showTeps && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
          options={{
            skipLabel: UISTRINGS.SKIPDONE_LABEL,
            doneLabel: UISTRINGS.SKIPDONE_LABEL,
            disableInteraction: true,
            exitOnOverlayClick: false,
            showBullets: false,
            keyboardNavigation: false
          }}
        />
      )}

      {isShowFullPageLoader && <FullPageLoader layerIndex={23} />}
      {isBUildScreen ? (
        <LibraryWrapper>
          <Build {...props} />
        </LibraryWrapper>
      ) : (
        <LibraryWrapper>
          <ReactHint events onRenderContent={onRenderContent} />
          <StepDetails
            _showCount
            maximumSlideCount={maximumSlideCount}
            _slideCount={slidesLength}
            _preview
            _build
            title={"Welcome to the Slide Library"}
            description={libraryDescription}
            onPreview={() => onPreview()}
            onBuild={() => onBuildHandler(true)}
            libraryTool={true}
          />
          {/* {overviewLimitExceeded && <Warning>{UISTRINGS.APPENDIX_LIMIT}</Warning>} */}
          <LibraryMainContainer isFullWidth={!isFullWidth}>
            <LibraryAside isFullWidth={!isFullWidth}>
              <RepoSelection
                {...props}
                hidContentRepoList={hidContentRepoList}
              />
              <FiltersAside
                className="filter-elem"
                isFilterActive={isFilterActive}
              >
                <Filters {...props} />
              </FiltersAside>
            </LibraryAside>
            <LibraryMain isFullWidth={!isFullWidth}>
              <ViewContainer>
                <ViewTabs {...props} />
              </ViewContainer>
            </LibraryMain>
          </LibraryMainContainer>
        </LibraryWrapper>
      )}
      {showModal ? (
        <SlidePreview
          closeModal={closeModal}
          hideModal={hideModal}
          addRemovePresentation={addRemoveSlideFromPreview}
          {...props}
        />
      ) : null}
    </>
  );
};

const LibraryWrapper = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  width: ${props => props.theme.WRAPPER.WIDTH};
  margin: 106px auto 0;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }

  .content {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
`;

const LibraryAside = styled.aside`
  flex-basis: 24%;
  align-self: baseline;
  display: ${props => (props.isFullWidth ? `none` : `block`)};
`;

const FiltersAside = styled.div`
  width: 100%;
  padding: 12px 12px 73px;
  border-radius: 4px;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  display: ${props => (props.isFilterActive ? `block` : `none`)};
`;

const LibraryMainContainer = styled.div`
  margin: 38px 0;
  display: flex;
  justify-content: ${({ isFullWidth }) =>
    isFullWidth ? `center` : `space-between`};
`;

const LibraryMain = styled.div`
  flex-basis: ${props => (props.isFullWidth ? `90%` : `68.9%`)};
`;

const ViewContainer = styled.div`
  .tabsData {
    padding-top: 12px;
    border-radius: 3px;
    background-color: ${props => props.theme.COLOR.WHITE};
    display: none;
    position: relative;
    ${props => props.theme.SNIPPETS.BOX_SHADOW};

    &.active-tab {
      display: block;
    }
  }
`;

const CustomHintContent = styled.div`
  width: 200px;
  padding: 10px;
  background-color: ${props => props.theme.COLOR.BLACK};
  border-radius: 4px;

  span {
    color: ${props => props.theme.COLOR.WHITE};
  }
`;

export default LibraryComponent;
