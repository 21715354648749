/**
 * import all app reducers and export them to combine and integrate in the store
 * !Do not modify the store to combine reducers.
 */
import sampleReducer from "./sampleReducer";

// app level services
import header from "components/header/services/reducer";

// services associated with renewal tool
import users from "pages/users/services/reducer";
import reports from "pages/reports/services/reports/reducer";

// services associated with presentation tool
import presentation from "tools/presentation/pages/dashboard/services/reducer";
import presentationSetupReducers from "tools/presentation/pages/build/services";
import userProfile from "pages/app/services/getUserDetail/reducer";
import contentRepoList from "tools/presentation/pages/admin/contentRepo/dashboard/services/reducer";
import setup from "tools/presentation/pages/admin/setup/services";
import themeList from "tools/presentation/pages/admin/contentRepo/themes/services/reducer";
import contentSlideList from "tools/presentation/pages/admin/contentRepo/contentSlides/services/reducer";
import presentationHistoryList from "tools/presentation/pages/admin/history/services/reducer";
import announcementList from "components/header/components/announcement/services/reducer";
import filters from "tools/presentation/pages/filters/services/reducer";
import imageList from "tools/presentation/pages/admin/imageFolder/services/reducer";

// services associated with marketingMaterial tool
import fetchDeleteCategories from "tools/marketingMaterial/pages/admin/categories/dashboard/services/reducer";
import getCategories from "tools/marketingMaterial/pages/admin/categories/categoryCreate/services/reducer";
import documentList from "tools/marketingMaterial/pages/documents/services/reducer";
import documentAdminData from "tools/marketingMaterial/pages/admin/dashboard/services/reducer";
import createDocument from "tools/marketingMaterial/pages/documentBuild/services/reducer";
import moduleList from "tools/marketingMaterial/pages/admin/modules/services/reducer";
import clientConfig from "services/clientConfig/reducer";

// services associated with summary maker tool
import summaryMakerDashboardReducer from "tools/summaryMaker/store/reducers/dashboardReducer";
import buildReducer from "tools/summaryMaker/store/reducers/buildReducer";
import previewBuildReducer from "tools/summaryMaker/store/reducers/previewBuildReducer";
import contentBlockReducer from "tools/summaryMaker/store/reducers/contentBlockReducer";
import documentBuildReducer from "tools/summaryMaker/store/reducers/documentBuildReducer";

//services asscociated with library tool
import repoReducers from "tools/library/store/reducers/repoReducer";
import themeReducers from "tools/library/store/reducers/themeReducer";
import filterReducer from "tools/library/store/reducers/filterReducer";
import topicReducer from "tools/library/store/reducers/topicReducer";
import searchReducer from "tools/library/store/reducers/searchReducer";
import savePresentationReducer from "tools/library/store/reducers/savePresentationReducer";
import presentationBuildStatusReducer from "tools/library/store/reducers/presentationBuildStatusReducer";
import triggerPresentationBuildReducer from "tools/library/store/reducers/presentationBuildStatusReducer";
import libraryDownloadReducer from "tools/library/store/reducers/libraryDownloadReducer";
import downloadDeckReducer from "tools/library/store/reducers/downloadDeckReducer";
import fileStatusReducer from "tools/library/store/reducers/fileStatusReducer";
import dividerBlankReducer from "tools/library/store/reducers/dividerBlankReducer";

// summary maker admin
import templateDashboardReducer from "tools/summaryMaker/store/reducers/templateDashboardReducer";
import templateCreationReducer from "tools/summaryMaker/store/reducers/templateCreationReducer";

import { combineReducers } from "redux";

const domain = {
  ...summaryMakerDashboardReducer,
  ...buildReducer,
  ...previewBuildReducer,
  ...contentBlockReducer,
  ...documentBuildReducer,
  ...templateCreationReducer,
  ...templateDashboardReducer
};

const lib = {
  ...repoReducers,
  ...themeReducers,
  ...filterReducer,
  ...topicReducer,
  ...searchReducer,
  ...savePresentationReducer,
  ...presentationBuildStatusReducer,
  ...triggerPresentationBuildReducer,
  ...libraryDownloadReducer,
  ...downloadDeckReducer,
  ...fileStatusReducer,
  ...dividerBlankReducer
};

//TODO add these as reducers once reducers for ui and app are available
// const ui = {};
// const app = {};

let allReducersCombined = {
  ...sampleReducer,
  ...reports,
  ...presentationSetupReducers,
  ...presentation,
  ...userProfile,
  ...setup,
  ...contentRepoList,
  ...themeList,
  ...getCategories,
  ...fetchDeleteCategories,
  ...documentList,
  ...documentAdminData,
  ...createDocument,
  ...contentSlideList,
  ...moduleList,
  ...presentationHistoryList,
  ...announcementList,
  ...users,
  ...clientConfig,
  ...filters,
  ...imageList,
  ...header,
  domain: combineReducers(domain),
  lib: combineReducers(lib)
};

export default allReducersCombined;
