import React from "react";
import styled from "styled-components";
import { map } from "lodash";
import hexToRgba from "utils/hexToRgba";
import PageImage from "../PageImage";

const PagesPanel = props => {
  let { pagesDetails, setWidthOptionForPreview } = props;

  return (
    <ContentWrapper
      setWidthOptionForPreview={setWidthOptionForPreview === "true"}
    >
      <Container setWidthOptionForPreview={setWidthOptionForPreview === "true"}>
        <Pages>
          {map(pagesDetails, (eachPage, index) => {
            return (
              <PageContent key={index}>
                <PageImageWrapper>
                  <PageImage
                    {...props}
                    eachPage={eachPage}
                    pageIndex={index}
                    imageKey="thumbnailKey"
                    dataUriKey="previewImageDataUri"
                  />
                </PageImageWrapper>
              </PageContent>
            );
          })}
        </Pages>
      </Container>
    </ContentWrapper>
  );
};

const Container = styled.div`
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
    border-radius: 4px;
    outline: 1px solid ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
  }
`;

const Pages = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContent = styled.div`
  margin-bottom: 20px;
`;

const PageImageWrapper = styled.div`
  margin: 0 auto;
  position: relative;
`;

const ContentWrapper = styled.div`
  width: 24%;
  margin-right: 20px;
  padding: 20px 0;
  height: 37rem;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};

  overflow: auto;
  overflow-x: hidden;
  @media only screen and (min-width: 661px) {
    height: ${props => (props.setWidthOptionForPreview ? "40.9rem" : "37rem")};
  }

  @media only screen and (min-width: 991px) {
    height: ${props => (props.setWidthOptionForPreview ? "56.6rem" : "37rem")};
  }

  @media only screen and (min-width: 1121px) {
    height: ${props => (props.setWidthOptionForPreview ? "70rem" : "37rem")};
  }
  @media only screen and (min-width: 1440px) {
    height: ${props => (props.setWidthOptionForPreview ? "75.4rem" : "37rem")};
  }
  @media only screen and (min-width: 1920px) {
    height: ${props => (props.setWidthOptionForPreview ? "78.4rem" : "49rem")};
  }
`;

export default PagesPanel;
