// libraries
import React from "react";
import { get } from "lodash";
import styled from "styled-components";

// components
import StyledDropzone from "components/chooseFile";

export default function index(props) {
  const { isActiveOnClickTemplateBlocks } = props;
  return (
    <>
      <Heading>
        PDF file:
        <Filename>
          {isActiveOnClickTemplateBlocks.pdfUploadedFileName
            ? get(isActiveOnClickTemplateBlocks, `pdfUploadedFileName`)
            : get(isActiveOnClickTemplateBlocks, `pdfFilename`)}
        </Filename>
      </Heading>
      <StyledDropzone
        {...props}
        hint="Drag a PDF file here or click to browse"
        uploadFileType="pdf"
      />
    </>
  );
}

const Filename = styled.span`
  font-size: 14px;
  margin-left: 3px;
`;

const Heading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin: 10px 0;
  text-align: left;
`;
