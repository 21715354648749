import React from "react";
import styled from "styled-components";
import { get, replace } from "lodash";
import { useDrag } from "react-dnd";
import hexToRgba from "utils/hexToRgba";

const Module = props => {
  let { eachModule, isLayoutTypeThumbnail, contentItemRemovalHandler } = props;
  const [{ opacity }, dragRef] = useDrag({
    item: {
      ...eachModule,
      type: `${get(eachModule, `layout`)}`,
      url: get(eachModule, `url`)
    },
    collect: monitor => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1
      };
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        contentItemRemovalHandler(get(item, `_id`), item);
      }
    }
  });

  return (
    <ModuleTypeWrapper
      key={get(eachModule, `id`)}
      ref={dragRef}
      style={{
        opacity: opacity,
        cursor: "pointer"
      }}
    >
      {isLayoutTypeThumbnail ? (
        <EachThumbnailModule title={get(eachModule, `url`)}>
          <ThumbnailWrapper title={get(eachModule, `name`)}>
            <ThumbnailImage
              src={get(eachModule, `url`)}
              alt={get(eachModule, `name`)}
            />
          </ThumbnailWrapper>
          <ModuleName title={get(eachModule, `name`)}>
            {replace(get(eachModule, `name`), '.png', '')}
          </ModuleName>
        </EachThumbnailModule>
      ) : (
        <EachListModule>
          <ModuleName title={get(eachModule, `name`)}>
            {get(eachModule, `name`)}
          </ModuleName>
        </EachListModule>
      )}
    </ModuleTypeWrapper>
  );
};

const ModuleTypeWrapper = styled.div`
  transform: translate3d(0, 0, 0);
`;

const EachListModule = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  background-color: ${props =>
    hexToRgba(props.theme.COLOR_PALLETE.COOL_BLUE, 0.09)};
  margin-right: 20px;
`;

const ThumbnailWrapper = styled.div``;

const ThumbnailImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const ModuleName = styled.span`
  font-family: ${props => `${props.theme.FONT.REG}`};
  opacity: 0.7;
  color: ${props => props.theme.COLOR.MAIN};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin-top: 5px;
  width: 100%;
`;

const EachThumbnailModule = styled.div`
  width: 95%;
  margin-bottom: 20px;
  touch-action: pan-x;
`;

export default Module;
