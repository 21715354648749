import React from "react";
import Container from "./container";
import styled from "styled-components";

//Components
import ThemeHeader from "./components/themeHeader";
import ThemeMainContent from "./components/themeMainContent";
import ThemeFooter from "./components/themeFooter";
import FullPageLoader from "components/FullPageLoader";

const ThemePreview = props => {
  return (
    <ThemePreviewContainer>
      <ThemeHeader {...props} />
      <ThemeMainContent {...props} />
      <ThemeFooter {...props} />
    </ThemePreviewContainer>
  );
};

const ThemePreviewContainer = styled.div`
  padding-top: 30px;
  text-align: left;
`;

export default Container(ThemePreview);
