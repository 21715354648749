import { pmSetSetupDetails } from "./actions";
import { pmSetSelectedContentListAction } from "./actions";
import { onSelectedFiltersChangeAction } from "./actions";

const pmSetSetupDetailsData = payload => async dispatch => {
  dispatch(pmSetSetupDetails(payload));
};

const pmSetSelectedContent = payload => async dispatch => {
  dispatch(pmSetSelectedContentListAction(payload));
};

const onSelectedFiltersChange = payload => async dispatch => {
  dispatch(onSelectedFiltersChangeAction(payload));
}

export { pmSetSelectedContent, pmSetSetupDetailsData, onSelectedFiltersChange };
