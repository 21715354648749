import * as types from "./types";
import { isLocalhost } from '../../../utils/helpers';

export const handleSetToolsList = (payload) => {
  // let's check if we are running the app on localhost if so, we need to map tools urls to localhost ones
  const getToolUrl = tool => {
    if (!isLocalhost()) {
      return tool.url;
    }

    const url = new URL(tool.url);

    return url.href.replace(url.origin, `http://localhost:${tool.local_port}`);
  };

  return {
    type: types.TOOLS_LIST,
    payload: {
      tools: payload.tools.map(tool => ({
        ...tool,
        url: getToolUrl(tool),
      })),
    },
  };
};

/**
 *
 * Store/Reset/Remove data dynamically based on .
 * @param {Object} data - API response on success/error/loading
 * @param {String} type - type for sucess/error/loading
 */

export const handleLastLoggedInTool = (
  payload = {},
  type = types.LAST_LOGGED_IN_TOOL
) => {
  return {
    type,
    payload
  };
};

export const handleMenuOpenClose = (payload = {}, type = types.MENU_STATE) => {
  return {
    type,
    payload
  };
};
