import axios from "axios";
import { get } from "lodash";
import history from "../history";
import { logError } from "./sentryLogger";
import ToastUtils from "utils/handleToast";
import { getAxiosClientWithRefreshToken } from "./axiosUtils";
import { clearLocalStorageSession } from "../services/webAuth";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const TIMEOUT_MESSAGE = {
  message: `Something’s wrong. we can’t communicate with the servers right now. we’ll try again. if this persists, please contact support.`
};

const client = getAxiosClientWithRefreshToken({ baseURL: BASE_URL });

/**  Convert response to FE Format to handle changes if done in API response */
export const handleReponseConvert = ({ response, error }) => {
  const responseData = (response && response.data) || null;
  const errorResponse = (error && get(error, "response.data")) || null;
  let formattedResponse = {};

  if (response && response.status) {
    let statusCodeRange =
      (response.status >= 200 && response.status <= 299) ||
      response.status === 304;
    if ((responseData && responseData.success) || statusCodeRange) {
      formattedResponse = {
        success: true,
        status: 200,
        ...responseData,
        data: get(responseData, "data") || response || null
      };
    } else {
      formattedResponse = {
        success: false,
        status: responseData.error.code,
        data: get(error, "response.data.error") || TIMEOUT_MESSAGE
      };
    }
  } else if (errorResponse) {
    formattedResponse = {
      success: false,
      status: 404,
      data: get(error, `response.data.error`) || TIMEOUT_MESSAGE
    };

    if (get(error, "response.status") === 403) {
      let errorMessage = get(errorResponse, "error.message");
      //show No Permission to access route
      ToastUtils.handleToast({
        operation: "error",
        message: errorMessage || TIMEOUT_MESSAGE
      });

      // Check if user has access to a route and if not redirect to dashboard
      history.push("/home");
    } else if (get(error, "response.status") === 401) {
      clearLocalStorageSession();
      history.push("/logout");
    }
  } else {
    formattedResponse = {
      success: false,
      status: 404,
      data: TIMEOUT_MESSAGE
    };
  }

  return formattedResponse;
};

/**  API Calling methods integrated with axios */

class FetchUtils {
  // set headers with authentication token
  setHeaders(headersValue = {}, responseType = {}) {
    return {
      ...responseType,
      headers: {
        ...headersValue
      }
    };
  }

  postData = async (url, body, log) => {
    try {
      const response = await client.post(url, body);
      /**
       * There is no need to handle the error of the .catch block here
       * The handleResponseConvert Function is serving the error through
       * a common function which is used across the application.
       */
      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.postData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  putData = async (url, body, log) => {
    try {
      const response = await client.put(url, body);

      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.putData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  patchData = async (url, body, log) => {
    try {
      const response = await client.patch(url, body);
      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.patchData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  deleteData = async (url, log) => {
    try {
      const response = await client.delete(url);
      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.deleteData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  getData = async (url, log) => {
    try {
      return client.get(url)
        .then((response) => handleReponseConvert({ response }))
        .catch((error) => {
          console.log("FetchUtils.getData err", JSON.stringify({ error, log }, null, 2));
          logError({
            error,
            extraData: error.response
          });
          return handleReponseConvert({ error });
        });
    } catch (error) {
      console.log("FetchUtils.getData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  /**
   * The getData method above handles the error message
   * very vaguely. @TODO reset/separate this file to use the
   * axios inteceptors for handling the response and request
   * parameters efficiently.
   */
  getRequest = async (url) => client.get(url)
    .then(response => {
      return response;
    }).catch(error => {
      logError({
        error,
        extraData: error.response
      });
      return error.response;
    });

  //@ NOTE: this method seems to be not used in the PMA
  // leaving only that we would not have to clean up multiple files
  getDataWithHeader = async (
    url,
    log,
    customHeader = {},
    responseType,
    isAttachBaseurl = true,
    timeout = 60000 //default timeout if nothing is sent
  ) => {
    const apiUrl = isAttachBaseurl ? BASE_URL + url : url;
    try {
      const headers = this.setHeaders(customHeader, responseType);

      const response = await client.get(apiUrl, {
        data: {},
        timeout,
        ...headers
      });

      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.getDataWithHeader error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  // aws request utils
  awsPutData = async (apiUrl, body, headers, log) => {
    try {
      let config = {
        headers: headers,
        timeout: 0
      };
      // we need to use clean axios instance here as if we
      // post with token it will result in error when reaching aws
      const response = await axios.put(apiUrl, body, config);
      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.getDataWithHeader error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };
}

export default new FetchUtils();
