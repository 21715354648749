import React from "react";
import Container from "./container";
import styled from "styled-components";
import DatePicker from "components/datePicker";
import RadioButton from "components/radioBtn";
import Button from "components/button";
import Select from "react-select";
import moment from "moment";
import BgWrapper from "components/bgWrapper";
import FullPageLoader from "components/FullPageLoader";

import hexToRgba from "utils/hexToRgba";

const Reports = ({
  props,
  state,
  handleDateChange,
  toolOptions,
  getAdvanceData,
  reportingStats,
  selectedTool,
  handleChange,
  clientConfig,
  setAdvanceDataParam
}) => {
  let { startDate, endDate } = state;

  const reportData = props.reportData || {};

  const reportLocation = props.reportLocation || {};
  const startDateFormattedValue = moment(startDate).format("MMM D, YYYY");
  const endDateFormattedValue = moment(endDate).format("MMM D, YYYY");
  const currentDate = new Date();

  return (
    <>
      {(props.reportDataLoading || props.reportAdvanceLoading) && (
        <FullPageLoader layerIndex={2} />
      )}
      <PageWrapper>
        <TopWrapper>
          <Heading>Reporting</Heading>
          <DropdownWrapper>
            {toolOptions.length > 1 && (
              <ReactSelect
                options={toolOptions}
                onChange={handleChange}
                value={toolOptions.filter(
                  ({ value }) => value === selectedTool
                )}
                placeholder="Select Tool"
                className="custom-select"
                classNamePrefix="custom-select"
              />
            )}
          </DropdownWrapper>
        </TopWrapper>
        <AdvancedStats>
          <DateText>Created Between</DateText>
          <DatePickerContainer>
            <DatePicker
              placeholderText="From"
              handleChange={date => handleDateChange("startDate", date)}
              toolTip="From Date"
              value={startDate}
              currentMaxDate={currentDate}
            />
          </DatePickerContainer>
          <DatePickerContainer>
            <DatePicker
              placeholderText="To"
              handleChange={date => handleDateChange("endDate", date)}
              toolTip="To Date"
              value={endDate}
              currentMaxDate={currentDate}
            />
          </DatePickerContainer>
          <AdvancedStateHeading>Advanced Stats</AdvancedStateHeading>
          <RadioButtonContainer>
            {reportingStats[selectedTool] &&
              reportingStats[selectedTool]["advanceStats"].map(
                (item, index) => {
                  return (
                    <RadioButtonWrapper key={index}>
                      <RadioButton
                        radioType="controlled"
                        key={Math.random()}
                        id={item.id}
                        name="advance_stat"
                        label={item.label}
                        checked={
                          
                          item.id ===
                          reportingStats[selectedTool]["selectedOption"]
                        }
                        handleChange={e =>
                          setAdvanceDataParam({
                            key: "selectedOption",
                            value: e.target.id,
                            generateParam: item.value
                          })
                        }
                      />
                    </RadioButtonWrapper>
                  );
                }
              )}
          </RadioButtonContainer>
          <GenerateButton>
            <Button
              onClick={() => getAdvanceData()}
              width={"147px"}
              text="Generate"
              float="none"
            />
          </GenerateButton>
        </AdvancedStats>
        <QuickStats>
          <QuickStatHeadingContainer>
            <QuickStatHeading>Quick Stats</QuickStatHeading>
            {/* <RefreshIconImage /> */}
          </QuickStatHeadingContainer>
          <DateRange>
            {startDateFormattedValue} - {endDateFormattedValue}
          </DateRange>
          <ResultsList>
            {reportingStats[selectedTool] &&
              reportingStats[selectedTool]["quickStats"].map((item, index) => {
                // Checking if the value is integer to avoid the below condition if value is 0
                // The second condition is to hide totlaLandingPageVisits for uhc client
                if (
                  (!Number.isInteger(reportData[item.id]) &&
                    !reportData[item.id]) ||
                  (item.id === "totalLandingPageVists" &&
                    clientConfig.hideTotalLandingPageVisits)
                )
                  return null;

                let makeBold =
                  [
                    "totalLandingPageVists",
                    "totalLogins",
                    "uniqueLogins",
                    "dashboardVisits"
                  ].indexOf(item.id) > -1;

                let hideTotalLogins =
                  (clientConfig || {}).hideDashboardCount &&
                  item.id === "totalLogins";

                return (
                  !hideTotalLogins && (
                    <List key={index}>
                      <ListText makeBold={makeBold}>{item.label}</ListText>
                      <ListNumber makeBold={makeBold}>
                        {reportData[item.id] ? reportData[item.id] : item.value}
                      </ListNumber>
                    </List>
                  )
                );
              })}
          </ResultsList>
          <DownloadButton
            href={reportLocation.location}
            target="_blank"
            download
          >
            Download
          </DownloadButton>
        </QuickStats>
      </PageWrapper>
    </>
  );
};

const ReactSelect = styled(Select)`
  margin-top: 13px;
  color: #000;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    border: none;
    outline: none;
  }
`;

const PageWrapper = styled.div`
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 40px;
`;

const QuickStats = styled.div`
  width: 36.66%;
  display: inline-block;
  vertical-align: top;
  padding: 30px;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  background-color: ${props => props.theme.COLOR.WHITE};
`;

const ResultsList = styled.ul`
  margin: 38px 0 47px;
`;

const List = styled.li`
  margin-bottom: 25px;
`;

const QuickStatHeadingContainer = styled.div``;

// const RefreshIconImage = styled(RefreshIcon)`
//   float: right;
//   cursor: pointer;
// `;

const GenerateButton = styled.div`
  a {
    display: inline-block;
  }

  button {
    height: 40px;
  }
`;

const TopWrapper = styled.div`
  margin: 42px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DownloadButton = styled.a`
  display: inline-block;
  width: 99.3%;
  border-radius: 4px;
  text-align: center;
  padding: 9.5px 0 10.5px;
  border: 1px solid;
  border-color: ${props => props.theme.COLOR.MAIN};
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: ${props => props.theme.COLOR.MAIN};
  cursor: pointer;
  transition: all 0.5s ease 0s;
  &:hover {
    color: ${props => props.theme.COLOR.WHITE};
    background: ${props => props.theme.COLOR.MAIN};
    border-color: ${props => props.theme.COLOR.MAIN};
  }
`;

const DropdownWrapper = styled.div`
  border-radius: 4px;
  min-width: 185px;
  flex-basis: 15.8%;

  /*Override custom select list*/
  .custom-select__control {
    /*When select list is focuses*/
    &--is-focused {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }

    /*When select list is in active state*/
    &--menu-is-open {
      border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      box-shadow: 0 0 0 1px ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        border-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }

  /*Overriding option styling*/
  .custom-select__menu {
    > div > div {
      background-color: ${props => props.theme.COLOR.WHITE};

      &:hover {
        background-color: ${props =>
          hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.2)};
      }
    }

    .custom-select__option--is-selected {
      background-color: ${props => props.theme.COLOR.USER_PRIMARY};

      &:hover {
        background-color: ${props => props.theme.COLOR.USER_PRIMARY};
      }
    }
  }
`;

const ListText = styled.span`
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.HEADING};
  margin-bottom: 4px;
  font-size: 12px;
  ${props => props.makeBold && `font-weight: bold`}
`;

const ListNumber = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.HEADING};
  margin-bottom: 4px;
  font-size: 12px;
  float: right;
  ${props => props.makeBold && `font-weight: bold`}
`;

const DatePickerContainer = styled.div`
  display: inline-block;
  width: 185px;
  margin-right: 10px;
  input {
    ${props => props.theme.SNIPPETS.FONT_STYLE}
    color: ${props => props.theme.COLOR.HEADING};
    font-weight: bold;
    margin-bottom: 4px;
    opacity: 0.7;
    letter-spacing: normal;
    font-size: 12px;
    padding-left: 7px;
  }
`;

const AdvancedStats = styled.div`
  width: calc(100% - 36.7%);
  display: inline-block;
`;

const Heading = styled.h2`
  flex-basis: 18%;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-weight: bold;
  font-size: 30px;
  color: ${props => props.theme.COLOR.MAIN};
`;

const AdvancedStateHeading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.HEADING};
  font-weight: bold;
  margin-top: 61px;
  opacity: 0.7;
  letter-spacing: normal;
  font-size: 20px;
  margin-bottom: 20px;
`;

const QuickStatHeading = styled.h3`
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.HEADING};
  font-weight: bold;
  opacity: 0.7;
  letter-spacing: normal;
  font-size: 20px;
  margin-top: -4px;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 21px;
`;

const DateText = styled.span`
  display: block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.HEADING};
  font-weight: bold;
  margin-bottom: 4px;
  opacity: 0.7;
  letter-spacing: normal;
  font-size: 12px;
`;

const DateRange = styled.span`
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.HEADING};
  font-weight: bold;
  margin-top: 8px;
  opacity: 0.7;
  letter-spacing: normal;
  font-size: 12px;
`;

const RadioButtonWrapper = styled.div`
  margin-top: 10px;
  label {
    color: ${props => props.theme.COLOR.HEADING};
  }
`;

export default Container(BgWrapper(Reports));
