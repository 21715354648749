import React from "react";
import Container from "./container";
import { Redirect } from "react-router-dom";
import Loader from "components/loader";
import { includes, get } from "lodash";
import ToastUtils from "utils/handleToast";

const HomePage = props => {
  let roles = get(props, "userProfileMeta.roles") || [];
  let $isLoading = roles.length ? false : true;

  /**
   * If the selected tool is not present allow redirection else check if the selected tool and available roles match
   *1. When clicking on site logo send the selected tool in state to compare and redirect on the selected tool route default page
   *2. When changing the tool from tool selection dropdown send the selected tool
   *
   * @param {*} toolSlug Send the currently selected tool
   * @returns Boolean value
   */
  let checkToolAccess = toolSlug => {
    // let's start by checking if given tool is enabled or not
    if (!props.tools.find(tool => tool.slug === toolSlug)) {
      return false;
    }

    // we are hard-coding PG as the default tool, assuming that it will always be enabled (for now)
    return toolSlug === 'pg';
  };

  // Redirect to sales after login
  if (!$isLoading) {
    if (includes(roles, "RG-Admin") && checkToolAccess("rg")) {
      return <Redirect to={"/renewal/admin"} />;
    } else if (
      includes(roles, "RG-Underwriter") &&
      checkToolAccess("rg")
    ) {
      return <Redirect to={"/renewal/rates"} />;
    } else if (includes(roles, "RG-Sales") && checkToolAccess("rg")) {
      return <Redirect to={"/renewal/renewals"} />;
    } else if (
      (includes(roles, "PG-Users") || includes(roles, "PG-System-Admin")) &&
      checkToolAccess("pg")
    ) {
      if (window.location.origin.includes('pma.') && includes(roles, "PG-System-Admin")) {
        return <Redirect to={"/presentation/admin/content-repo"} />;
      }

      return <Redirect to={"/presentation/admin/content-repo"} />;
    } else if (
      (includes(roles, "MG-Admin") || includes(roles, "MG-Users")) &&
      checkToolAccess("mg")
    ) {
      return <Redirect to={"/marketing-materials/documents"} />;
    } else if (
      includes(roles, "Summary Maker Admin") &&
      checkToolAccess("sm")
    ) {
      return <Redirect to={"/summarymaker/dashboard"} />;
    } else if (includes(roles, "Summary Maker") && checkToolAccess("sm")) {
      return <Redirect to={"/summarymaker/dashboard"} />;
    } else if (includes(roles, "PG-Library") && checkToolAccess("lib")) {
      return <Redirect to={"/library/build"} />;
    } else {
      //Show if user is created but no roles are assigned to user
      ToastUtils.handleToast({
        operation: "error",
        message:
          "No roles have been assigned to the user. Please contact an admin for assistance."
      });
      return <Redirect to={"/logout"} />;
    }
  } else {
    return <Loader />;
  }
};

export default Container(HomePage);
