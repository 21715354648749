let tableData = {
  fields: [
    {
      label: "Name",
      key: "name"
    },
    {
      label: "Description",
      key: "description"
    },
    {
      label: "Created Date",
      key: "createdAt"
    },
    {
      label: "Status",
      key: "active"
    },
    {
      label: "Created By",
      key: "createdBy.name"
    },
    {
      label: "Updated Date",
      key: "updatedAt"
    }
  ],
  actionFields: ["canEdit", "canDelete", "canClone"],
  searchFields: ["name", "description"]
};

const templateList = [
  {
    name: "Template 1",
    description: "Description 1",
    createdAt: "2020-08-13T07:12:30.610Z",
    status: "Saved",
    user: "Abc",
    updatedAt: "2020-08-13T07:12:30.610Z"
  },
  {
    name: "Template 2",
    description: "Description 2",
    createdAt: "2020-08-13T07:12:30.610Z",
    status: "Completed",
    user: "Abc",
    updatedAt: "2020-08-13T07:12:30.610Z"
  },
  {
    name: "Template 3",
    description: "Description 3",
    createdAt: "2020-08-13T07:12:30.610Z",
    status: "Saved",
    user: "Abc",
    updatedAt: "2020-08-13T07:12:30.610Z"
  },
  {
    name: "Template 4",
    description: "Description 4",
    createdAt: "2020-08-13T07:12:30.610Z",
    status: "Saved",
    user: "Abc",
    updatedAt: "2020-08-13T07:12:30.610Z"
  }
];

export { tableData, templateList };
