import React from "react";
import styled from "styled-components";
import { Drag } from "assets/icons";
import { NavLink } from "react-router-dom";
export default function ContentRepoDetails(props) {
  const {
    name,
    dimensions,
    maximumSlides,
    numberOfSlides,
    description,
    _id
  } = props.item;
  return (
    <CardWrapper className="__test_content_repo">
      {!props.isSearchEnabled && (
        <Column>
          <Drag />
        </Column>
      )}
      <ColumnName>
        <ContentRepoName>
          <LinkAnchor
            to={`/presentation/admin/content-repo/${_id}/content-slides`}
          >
            {name}
          </LinkAnchor>
        </ContentRepoName>
        <ContentDescription>
          {description && "Description:"} {description}
        </ContentDescription>
      </ColumnName>
      <ColumnDimension>
        <ContentDimension>{dimensions.ratio}</ContentDimension>
        <ContentDescription>
          {Math.round(dimensions.width * 100) / 100}x
          {Math.round(dimensions.height * 100) / 100}
        </ContentDescription>
      </ColumnDimension>
      <ColumnNoSlides>{numberOfSlides}</ColumnNoSlides>
      <ColumnMaxSlides>{maximumSlides}</ColumnMaxSlides>

      <ColumnActionButtons className="__test_action_buttons">
        {props.showIcon(props.item)}
      </ColumnActionButtons>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  display: flex;
  &:hover {
    cursor: auto;
  }
`;

const Column = styled.span`
  background: white;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: grab;
  }
`;

const LinkAnchor = styled(NavLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: rgba(33, 94, 255, 0.7);
  }
`;

const ColumnName = styled.span`
  width: 35%;
  padding: 10px 8px;
`;
const ColumnDimension = styled.span`
  width: 15%;
  padding: 10px 8px;
  font: ${props => props.theme.FONT.REG};
  font-size: 12px;
`;

const ColumnMaxSlides = styled.span`
  width: 10%;
  font: ${props => props.theme.FONT.REG};
  font-size: 12px;
  padding: 10px 8px;
  position: relative;
`;

const ColumnNoSlides = styled.span`
  width: 15%;
  font: ${props => props.theme.FONT.REG};
  font-size: 12px;
  padding: 10px 8px;
  position: relative;
`;

const ColumnActionButtons = styled.span`
  width: 25%;
  padding: 10px 8px;
  font-size: 12px;
`;

const ContentRepoName = styled.p`
  color: ${props => props.theme.COLOR.USER_PRIMARY};
  font: ${props => props.theme.FONT.REG};
  font-size: 12px;
  width: 100%;
  padding-right: 5px;
  display: block;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ContentDescription = styled.div`
  color: ${props => props.theme.COLOR.HEADING};
  font-size: 10px;
  text-overflow: ellipsis;
  font-size: 10px;
  opacity: 0.54;
  overflow: hidden;
  white-space: nowrap;
`;

const ContentDimension = styled.p`
  font-size: 12px;
  color: black;
  font: ${props => props.theme.FONT.REG};
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
