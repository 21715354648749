import React, { Component, useEffect } from "react";
import { useState } from "react";

import { tableData } from "./data";
import { get, map, fill, each, trim, size } from "lodash";
import {
  Delete,
  Duplicate,
  EditWithNoShadow,
  Show,
  Hidden
} from "assets/icons";
import { MdDone, MdClear } from "react-icons/md";

import styled from "styled-components";
import Dashboard from "./index";
import Moment from "react-moment";
import { connect } from "react-redux";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";

// utils
import ValidationUtils from "utils/ValidationUtils";
import UI_STRINGS from "utils/stringConstants";

// services
import {
  getTemplateList,
  updateTemplate,
  cloneDocumentAdmin
} from "tools/summaryMaker/store/actions/templateDashboardActions";
import { deleteTemplateFromAdminDashboard } from "tools/summaryMaker/store/actions/dashboardActions";
import { createTemplate } from "tools/summaryMaker/store/actions/templateCreationActions";
import { trackToolDashboardCount } from "services/trackDashboardCount";

const mapStateToProps = state => {
  const {
    domain: {
      RECEIVE_TEMPLATE_LIST_DASHBOARD,
      REQUEST_TEMPLATE_LIST_DASHBOARD,
      RECEIVE_ACTIVE_TEMPLATE
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...RECEIVE_TEMPLATE_LIST_DASHBOARD,
    ...REQUEST_TEMPLATE_LIST_DASHBOARD,
    ...SUCCESS_USER_PROFILE,
    ...RECEIVE_ACTIVE_TEMPLATE
  };
};

const mapDispatchToProps = {
  getTemplateList,
  updateTemplate,
  createTemplate,
  deleteTemplateFromAdminDashboard,
  cloneDocumentAdmin
};

function InputBox({
  row,
  updateTemplate,
  getTemplateListHandler,
  handleValidation
}) {
  const [showPatchRequest, setshowPatchRequest] = useState(false);
  const [rowValue, setrowValue] = useState(row.row.name);
  const [errorMessage, seterrorMessage] = useState(null);
  const onCellValueChange = e => {
    seterrorMessage(handleValidation(e.target.value));
    setshowPatchRequest(true);
    setrowValue(e.target.value);
    if (row.row.name === e.target.value) {
      setshowPatchRequest(false);
    }
  };

  useEffect(()=>{
    setrowValue(row.row.name)
  },[row])

  return (
    <div>
      <InputTemplateBox
        value={rowValue}
        type="text"
        title={rowValue}
        onChange={onCellValueChange}
        isErrorMessage={errorMessage}
      />

      {showPatchRequest && (
        <UpdateBoxWrapper>
          {size(rowValue) > 0 && !errorMessage ? (
            <UpdateBoxWrapperDone>
              <MdDone
                onClick={async () => {
                  await updateTemplate({ name: rowValue }, row.original._id);
                  getTemplateListHandler();
                }}
              />
            </UpdateBoxWrapperDone>
          ) : (
            <span></span>
          )}
          <UpdateBoxWrapperClear>
            <MdClear
              onClick={() => {
                setrowValue(row.row.name);
                setshowPatchRequest(false);
                seterrorMessage(null);
              }}
            />
          </UpdateBoxWrapperClear>
        </UpdateBoxWrapper>
      )}
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}

class DashboardContainer extends Component {
  state = {
    tableColumnsKeys: [],
    tableColumns: [],
    columnWidth: [],
    templateFormFields: [],
    showAddTemplateModal: false,
    rerendercomponent: true,
    filteredData: [],
    currentPage: 0,
    showActive: true,
    templateList: []
  };

  nameRef = React.createRef();
  descriptionRef = React.createRef();

  componentDidMount() {
    this.getTemplateListHandler();
    this._extractMetadata();
    this._extractColumns();
    this._calculateColumnsWidth();
    this.setTemplateFormFields();
    this.trackSummarMakerDashboardCount();
  }

  componentDidUpdate(prevProps) {
    // check if user profile data is recieved and get the url
    if (prevProps.userProfileMeta !== this.props.userProfileMeta) {
      this.trackSummarMakerDashboardCount();
    }
  }

  getTemplateListHandler = async () => {
    await this.props.getTemplateList();
    const { templateListDashboard } = this.props;

    this.setState({
      templateList: templateListDashboard,
      filteredData: this.state.showActive
        ? templateListDashboard.filter(element => element.active)
        : templateListDashboard
    });
  };

  handelTemplateActiveStatus = (activeTemplatesStatus = "") => {
    const filteredData = activeTemplatesStatus
      ? this.state.templateList.filter(element => element.active)
      : this.state.templateList;
    const showActive = activeTemplatesStatus ? true : false;
    this.setState({
      showActive,
      filteredData,
      currentPage: 0
    });
  };

  setCurrentPage = (page = 0) => {
    this.setState({
      currentPage: page
    });
  };

  setCurrentPage = (page = 0) => {
    this.setState({
      currentPage: page
    });
  };

  /**
   *Log whenever a user hits dashboard
   *
   */
  trackSummarMakerDashboardCount = async () => {
    let userId = (this.props.userProfileMeta || {})._id;
    userId && (await trackToolDashboardCount(userId, "sm"));
  };

  /**
   *Log whenever a user hits dashboard
   *
   */
  trackSummarMakerDashboardCount = async () => {
    let userId = (this.props.userProfileMeta || {})._id;
    userId && (await trackToolDashboardCount(userId, "sm"));
  };

  /**
   * check if form fields are valid or not
   * @returns Boolean stating whether fields are valid or not
   */
  checkIfFieldsAreValid = () => {
    let templateFormFields = this.state.templateFormFields;
    let isFieldValid = true;

    each(templateFormFields, eachField => {
      let value = get(eachField, `ref.current.value`);

      eachField.error = this.handleValidation(value, eachField.key);
      if (eachField.error) {
        isFieldValid = false;
      }
    });

    this.setState({
      templateFormFields
    });

    return isFieldValid;
  };

  setTemplateFormFields = () => {
    const templateFormFields = [
      {
        key: "name",
        label: "Template Name*",
        fieldType: "input",
        type: "text",
        ref: this.nameRef,
        placeholder: "Enter name of the new template",
        error: ""
      },
      {
        key: "description",
        label: "Description",
        fieldType: "textarea",
        type: "text",
        ref: this.descriptionRef,
        placeholder: "Enter description",
        error: ""
      }
    ];

    this.setState({
      templateFormFields
    });
  };

  /**
   *handle validation for form fields
   * @returns {String} appropriate error message
   */
  handleValidation = (value, key) => {
    if (ValidationUtils.checkIfEmptyField(value) && key !== "description") {
      return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfWhiteSpace(value)) {
      return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfspecialChar(value)) {
      return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
    }

    return null;
  };

  handleInputChange = (e, fieldIndex) => {
    let { templateFormFields } = this.state;
    let error =
      templateFormFields[fieldIndex].key === "description" && !e.target.value
        ? null
        : this.handleValidation(e.target.value);

    templateFormFields[fieldIndex].error = error;

    this.setState({
      templateFormFields
    });
  };

  // extract metadata of the selected template for getting columns info
  _extractMetadata = () => {
    let { tableColumnsKeys } = this.state;

    tableColumnsKeys = map(get(tableData, `fields`), eachMetadata => {
      return get(eachMetadata, `key`);
    });
    tableColumnsKeys.push(""); // pushing empty string for action column to be displayed
    this.setState({
      tableColumnsKeys
    });
  };

  createTemplateHandler = async () => {
    let areFieldsValid = this.checkIfFieldsAreValid();
    if (!areFieldsValid) return;

    const postData = {
      name: get(this.nameRef, `current.value`),
      ...(get(this.descriptionRef, `current.value`) && {
        description: get(this.descriptionRef, `current.value`)
      })
    };

    const response = await this.props.createTemplate(postData);
    const { currentTemplateData } = this.props;

    if (get(response, `success`)) {
      this.props.history.push(
        `/summarymaker/template/create/${get(currentTemplateData, `_id`)}`
      );
    }
  };

  /**
   * called for rendering table cells
   */
  renderTableCell = ({ row, col, highlightText, colName, index }) => {
    if (col === "Created Date" || col === "Updated Date") {
      return (
        <span>
          <Moment
            format="MM/DD/YYYY HH:mm"
            titleFormat="MM/DD/YYYY HH:mm"
            withTitle
          >
            {row.value}
          </Moment>
        </span>
      );
    } else if (index + 1 === Object.values(colName).length) {
      return <ActionButton>{this.showIcon(row)}</ActionButton>;
    }
    if (col === "Name") {
      return (
        <>
          <InputBox
            row={row}
            updateTemplate={this.props.updateTemplate}
            getTemplateListHandler={this.getTemplateListHandler}
            handleValidation={this.handleValidation}
          />
        </>
      );
    }

    if (col === "Status") {
      let status = row.value ? "Active" : "Inactive";
      return <span title={status}>{status}</span>;
    } else if (!trim(row.value)) {
      return <span>-</span>;
    } else if (col === "Created By") {
      return <span title={row.value}>{get(row.row, `createdBy.name`)}</span>;
    } else if (typeof row.value !== "object") {
      return <span title={row.value}> {highlightText(row.value)} </span>;
    }
  };

  _extractColumns = () => {
    let { tableColumns } = this.state;
    let columnNames = {};

    // formatting columns name as col1 : columnName
    each(tableData.fields, (eachMetadata, index) => {
      columnNames[`col${index + 1}`] = get(eachMetadata, `label`);
    });

    // pushing last action column
    columnNames[`col${tableData.fields.length + 1}`] = "Actions";
    tableColumns = [
      {
        ...columnNames
      }
    ];

    this.setState({
      tableColumns
    });
  };

  _calculateColumnsWidth = () => {
    let { columnWidth } = this.state;
    // "+ 1" as actions column width also has to be included
    let eachColumnWidth = 900 / (get(tableData, `fields.length`) + 1);

    // each column width will be of same size, therefore populating same width length many times in the column width array
    columnWidth = fill(Array(tableData.fields.length + 1), eachColumnWidth);
    columnWidth[0] = 180;
    columnWidth[3] = 77;
    this.setState({
      columnWidth
    });
  };

  updateTemplateHandler = async rowData => {
    const { updateTemplate } = this.props;
    const templateId = get(rowData, `_id`);

    let postData = {
      active: !rowData.active
    };

    await updateTemplate(
      postData,
      templateId,
      `Template has been successfully ${
        rowData.active ? "disabled" : "enabled"
      }.`
    );
    this.getTemplateListHandler();
  };

  cloneTemplate = async rowData => {
    await this.props.cloneDocumentAdmin(rowData._id);
    this.getTemplateListHandler();
  };

  showIcon = row => {
    const rowData = get(row, `value`) || get(row, `row`);
    const { actionFields } = tableData;
    const modifiedActionFields = [
      ...actionFields,
      ...(rowData.active ? ["canShow"] : ["canHide"])
    ];
    const templateId = get(row, `value._id`);

    const Icon = {
      canEdit: (
        <EditWithNoShadow
          onClick={() =>
            this.props.history.push(
              `/summarymaker/template/create/${rowData._id}`
            )
          }
        />
      ),
      canDelete: (
        <Delete
          onClick={() => {
            DeleteConfirmationAlert({
              onYesClick: () => {
                this.props.deleteTemplateFromAdminDashboard(templateId);
                this.getTemplateListHandler();
              }
            });
          }}
        />
      ),
      canClone: <Duplicate onClick={() => this.cloneTemplate(rowData)} />,
      canHide: <Hidden onClick={() => this.updateTemplateHandler(rowData)} />,
      canShow: <Show onClick={() => this.updateTemplateHandler(rowData)} />
    };

    return (
      <>
        {map(modifiedActionFields, (eachActionField, index) => {
          return (
            <IconWrapper iconName={eachActionField} key={index}>
              {Icon[eachActionField]}
            </IconWrapper>
          );
        })}
      </>
    );
  };

  renderHead = () => {
    return (
      <div className="heading">
        <HeadingName>Templates</HeadingName>
      </div>
    );
  };

  addNewTemplateHandler = () => {
    this.setState(
      {
        showAddTemplateModal: !this.state.showAddTemplateModal
      },
      () => {
        this.setTemplateFormFields();
      }
    );
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Dashboard {...stateMethodProps} />;
  }
}

const ActionButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorMessage = styled.p`
  font-size: 9px;
  color: ${props => props.theme.COLOR.ERROR};
  margin-top: 5px;
`;

const IconWrapper = styled.span`
  display: inline-block;
  svg {
    cursor: pointer;
    max-width: ${props =>
      props.iconName === "canHide" || props.iconName === "canShow"
        ? "32px"
        : "18px"};
  }
`;

const HeadingName = styled.span`
  font-size: 30px;
`;

const InputTemplateBox = styled.input`
  width: 8.3rem;
  border: ${props => (props.isErrorMessage ? "1px solid" : "none")};
  font-size: 12px;
  text-overflow: ${props => (size(props.value) > 10 ? "ellipsis" : "initial")};
  font-family: ${props => props.theme.FONT.REG};
`;

const UpdateBoxWrapper = styled.span`
  position: relative;
  top: 0.2rem;
  left: 0.2rem;
`;

const UpdateBoxWrapperClear = styled.span`
  position: relative;
  left: 0.2rem;
  cursor: pointer;
`;

const UpdateBoxWrapperDone = styled.span`
  cursor: pointer;
`;

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
