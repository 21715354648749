//Lib
import React from "react";
import styled from "styled-components";

//Component
import RadioButton from "components/radioBtn";
import Container from "./container";
import PreviewModal from "components/previewModal";

const presentationDownloadForm = ({
  closeModalHandler,
  handleChange,
  formHandler,
  selectedPresentation
}) => {
  const { pdfLocation, pptLocation, zipLocation } = selectedPresentation || {};
  const radioButton = [
    {
      label: "pdf",
      id: "pdf",
      name: "options",
      url: pdfLocation.url
    },
    {
      label: "ppt",
      id: "ppt",
      name: "options",
      url: pptLocation.url
    },
    {
      label: "both",
      id: "zip",
      name: "options",
      url: zipLocation && zipLocation.url
    }
  ];

  return (
    <PreviewModal closeModal={closeModalHandler} width="40%">
      <PreviewModalWrapper>
        <RadioWrapper>
          {radioButton.map((radio, index) => {
            const { name, label, id, url } = radio;
            return (
              url && (
                <RadioButtonWrapper key={index}>
                  <RadioButton
                    name={name}
                    label={label}
                    id={id}
                    handleChange={() => handleChange(id)}
                    defaultChecked={index === 0}
                  />
                </RadioButtonWrapper>
              )
            );
          })}
        </RadioWrapper>
        <ButtonWrapper>
          <DownloadButton onClick={() => formHandler()}>
            Download
          </DownloadButton>
        </ButtonWrapper>
      </PreviewModalWrapper>
    </PreviewModal>
  );
};

presentationDownloadForm.defaultProps = {
  downloadOverlayHandler: () => {}
};

const PreviewModalWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 32px 0 37px;
  @media (max-width: 995px) {
    width: 80%;
  }
  @media (max-width: 995px) {
    width: 90%;
  }
`;

const RadioButtonWrapper = styled.div`
  width: 10%;
  display: inline-block;
  margin-top: 9px;
  label {
    padding-left: 16px;
    font-size: 16px;
    text-transform: uppercase;
    opacity: 0.8;
    color: ${props => props.theme.COLOR.HEADING};
  }
  .checkmark {
    top: 3px;
    width: 10px;
    height: 10px;
    left: -1px;
    box-sizing: content-box;
  }
`;

const RadioWrapper = styled.div`
  width: 90%;
  padding: 0 60px 0px 25px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 200px;
  margin: 30px auto 0;
`;

const DownloadButton = styled.button`
  border: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  border-radius: 4px;
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  color: ${props => props.theme.COLOR.WHITE};
  padding: 12px 20px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    background: ${props => props.theme.COLOR.WHITE};
    color: ${props => props.theme.COLOR.USER_PRIMARY};
  }
`;

export default Container(presentationDownloadForm);
