import { TemplateDashboardTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

const RECEIVE_TEMPLATE_LIST_DASHBOARD = (
  payload = null,
  type = TemplateDashboardTypes.RECEIVE_TEMPLATE_LIST_DASHBOARD
) => {
  return {
    type,
    payload
  };
};

const REQUEST_TEMPLATE_LIST_DASHBOARD = (
  payload = {},
  type = TemplateDashboardTypes.REQUEST_TEMPLATE_LIST_DASHBOARD
) => {
  return {
    type,
    payload
  };
};

const getTemplateList = () => async dispatch => {
  const URL = `/summary/templates?dashboard=true`;

  // start loading
  dispatch(
    REQUEST_TEMPLATE_LIST_DASHBOARD({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_TEMPLATE_LIST_DASHBOARD({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_TEMPLATE_LIST_DASHBOARD({
        templateListDashboard: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};
const updateTemplate = (body, templateId, message) => async dispatch => {
  const URL = `/summary/templates/${templateId}`;
  // start loading
  dispatch(
    REQUEST_TEMPLATE_LIST_DASHBOARD({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, body, "patch template data");

  // stop loading
  dispatch(
    REQUEST_TEMPLATE_LIST_DASHBOARD({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: message || "Template has been updated successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

// Cloning Document on the Admin Side
const cloneDocumentAdmin = templateId => async dispatch => {
  const URL = `/summary/templates/${templateId}`;
  // start loading
  dispatch(
    REQUEST_TEMPLATE_LIST_DASHBOARD({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, "clone document");

  // start loading
  // start loading
  dispatch(
    REQUEST_TEMPLATE_LIST_DASHBOARD({
      isTemplateListLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Document has been cloned successfully."
    });

    return response.data;
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};
export { getTemplateList, updateTemplate, cloneDocumentAdmin };
