import React from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { forEach, includes } from "lodash";

const Category = ({
  _id,
  title,
  handleSelectAllSlides,
  selectSlides,
  contentSlidesListId
}) => {
  let selectedSlides = [];
  forEach(contentSlidesListId[_id], singleSlide => {
    if (
      selectSlides.indexOf(singleSlide) > -1 &&
      !includes(selectedSlides, singleSlide)
    )
      selectedSlides.push(singleSlide);
  });

  let changeText =
    (contentSlidesListId[_id] || []).length === selectedSlides.length;
  return (
    <TitleContainer>
      <CategoryHeader>{title}</CategoryHeader>
      <SelectAllText
        onClick={() => handleSelectAllSlides(_id, changeText)}
      >{`[${
        changeText ? "Deselect All Slides" : "Select All Slides"
      }]`}</SelectAllText>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

const CategoryHeader = styled.h3`
  padding-left: 11px;
  display: inline-block;
  vertical-align: top;
  color: ${props => props.theme.COLOR.HEADING};
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  opacity: 0.9;
  &:not(:first-of-type) {
    padding-top: 23px;
    border-top: 2px solid
      ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, 0.1)};
  }
`;

const SelectAllText = styled.span`
  margin-left: 10px;
  display: inline-block;
  vertical-align: sub;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => props.theme.COLOR.DEFAULT_USER_PRIMARY};
  opacity: 0.9;
  cursor: pointer;
`;

export default Category;
