import React from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import hexToRgba from "utils/hexToRgba";

//components
import BgWrapper from "components/bgWrapper";
import BuiltinCarousel from "./components/builtinCarousel";
import CustomCarousel from "./components/customCarousel";
import TemplateBlocks from "./components/templateBlocks";
import BlockUploader from "./components/blockUploader";
import FullPageLoader from "components/FullPageLoader";

const TemplateBuild = props => {
  const { isTemplateListLoading, isTemplateUpdating, isBlockUpdating } = props;
  const isShowFullPageLoader =
    isTemplateListLoading || isTemplateUpdating || isBlockUpdating;
  return (
    <PageWrapper>
      {isShowFullPageLoader && <FullPageLoader layerIndex={3} />}
      <BuiltinCarousel {...props} />
      <CustomCarousel {...props} />
      <TemplateBlockWrapper className="template-block-with-preview">
        <TemplateBlocks {...props} />
        <BlockUploader {...props} />
      </TemplateBlockWrapper>
    </PageWrapper>
  );
};

const TemplateBlockWrapper = styled.div``;

const PageWrapper = styled.div`
  max-width: 1024px;
  margin: 30px auto;
  padding: 0 30px;
  user-select: none;

  .custom-blocks {
    width: 100%;
    padding: 22px 15px;
    border: 1px dashed ${props => props.theme.COLOR.MAIN};
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 25px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${props =>
        hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
      border-radius: 4px;
      outline: 1px solid
        ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
    }
  }

  .template-block-with-preview {
    display: flex;
    margin-top: 30px;
    text-align: right;
  }

  .template-blocks {
    width: 20%;
  }

  .template-blocks-border {
    border: 1px dashed ${props => props.theme.COLOR.MAIN};
    background: white;
    border-radius: 8px;
    padding: 15px;
    height: 600px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    overflow: auto;
    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: ${props =>
        hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
      border-radius: 4px;
      outline: 1px solid
        ${props => hexToRgba(props.theme.COLOR.DEAD_BLACK, 0.2)};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .upload-blocks {
    width: 80%;
  }

  .upload-blocks-border {
    padding: 20px;
    overflow: auto;
    margin-left: 5%;
    text-align: center;
    margin-top: 0.5rem;
    border: 1px dashed ${props => props.theme.COLOR.MAIN};
    background: white;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    height: 93.5%;
  }

  .error-message-input {
    display: block;
    top: 22px;
    margin-top: -5px;
  }
`;

export default BgWrapper(TemplateBuild);
