const SELECTED_TEMPLATE = "SELECTED_TEMPLATE";
const RECEIVE_TEMPLATE_LIST = "RECEIVE_TEMPLATE_LIST";
const REQUEST_DATA = "REQUEST_DATA";
const RECEIVE_DOCUMENT_DATA = "RECEIVE_DOCUMENT_DATA";
const RECEIVE_DOCUMENT_DASHBOARD = "RECEIVE_DOCUMENT_DASHBOARD";

export const DashboardTypes = {
  RECEIVE_TEMPLATE_LIST,
  SELECTED_TEMPLATE,
  REQUEST_DATA,
  RECEIVE_DOCUMENT_DATA,
  RECEIVE_DOCUMENT_DASHBOARD
};
