import React from "react";
import { map, get, has, find, includes, isObject } from "lodash";
import styled, { css } from "styled-components";

// components
import StepDetails from "components/buildProcess/stepDetails";
import Input from "components/inputField";
import DatePicker from "components/datePicker";
import hexToRgba from "utils/hexToRgba";
import CustomerLogo from "./cobrandLogo";

const Documents = props => {
  let {
    fields,
    handleInputChange,
    documentFields,
    modifyStepHandler,
    activeStep,
    onDocumentSaveData,
    templateList,
    selectedTemplate,
    templateClickHandler,
    isEnableTemplateSelection
  } = props;

  const isDocumentEdited = get(props, `match.params.documentId`);
  const isCobrandLogoVisible = isObject(
    find(get(selectedTemplate, `variables`), e=> e.label == 'customerLogo')
  );
  return (
    <>
      <StepDetails
        title="Documents"
        _next
        _save
        onSave={() => onDocumentSaveData("Document")}
        _previous={activeStep !== 0}
        onNext={async () => {
          let isMoveToNextStep = await onDocumentSaveData("Document");

          if (isMoveToNextStep) {
            modifyStepHandler(activeStep + 1, "Document");
          }
        }}
        onPrevious={() => modifyStepHandler(activeStep - 1)}
      />
      {get(templateList, `length`) > 1 && !isDocumentEdited && (
        <>
          <SectionHeading>Please select a template</SectionHeading>
          <TemplateGallery>
            {map(templateList, (eachTemplateData, index) => {
              let isActiveTemplate =
                get(eachTemplateData, `_id`) === get(selectedTemplate, `_id`) ||
                (index === 0 && !!!get(selectedTemplate, `_id`));

              return (
                <TemplateWrapper key={get(eachTemplateData, `_id`)}>
                  <TemplateImageWrapper
                    isActiveTemplate={isActiveTemplate}
                    isEnableTemplateSelection={isEnableTemplateSelection}
                  >
                    <TemplateImage
                      src={get(eachTemplateData, "thumbnailLocation")}
                      title={get(eachTemplateData, `description`)}
                      onClick={() => templateClickHandler(eachTemplateData)}
                    />
                  </TemplateImageWrapper>
                  <TemplateTitle title={get(eachTemplateData, `name`)}>
                    {get(eachTemplateData, `name`)}
                  </TemplateTitle>
                </TemplateWrapper>
              );
            })}
          </TemplateGallery>
          <FormHeading>
            {`Create a new Document from the ${get(
              selectedTemplate,
              `name`
            )} template`}
          </FormHeading>
        </>
      )}
      <FieldsWrapper>
        {map(fields, (eachField, index) => {
          let { label, type, fieldType, value } = eachField;

          const inputField = (
            <InputFieldWrapper key={index}>
              {(type === "text" || type === "textarea") && (
                <Input
                  index={index}
                  label={label}
                  type={type}
                  fieldType={fieldType}
                  name={label}
                  error={get(documentFields, [index, `error`])}
                  value={value || ""}
                  handleInputChange={e =>
                    handleInputChange(e.target.value, index, type)
                  }
                />
              )}
              {type === "date" && (
                <DatePickerContainer>
                  <DatePicker
                    className={"date-field"}
                    title={label}
                    toolTip={label}
                    value={new Date(value)}
                    handleChange={date => {
                      handleInputChange(date, index, type);
                    }}
                  />
                </DatePickerContainer>
              )}
            </InputFieldWrapper>
          );

          return inputField;
        })}
      </FieldsWrapper>
      <CobrandLogoWrapper>
        {isCobrandLogoVisible ? <CustomerLogo {...props} /> : ""}
      </CobrandLogoWrapper>
    </>
  );
};

const SectionHeading = styled.span`
  display: inline-block;
  margin-bottom: 20px;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 17px;
  font-weight: bold;
  margin-top: 2%;
`;

const FormHeading = styled.h4`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  color: ${props => props.theme.COLOR.MAIN};
  font-size: 18px;
  font-weight: bold;
  margin-top: 21px;
`;

const TemplateWrapper = styled.div`
  margin-right: 2%;
  cursor: pointer;
  cursor: ${props => (props.showDocumentCreationForm ? "pointer" : "default")};
  width: 12%;
`;

const TemplateImageWrapper = styled.div`
  cursor: ${props =>
    props.isEnableTemplateSelection ? "cursor" : "not-allowed"};
  border: ${props =>
    props.isActiveTemplate
      ? `5px solid ${props.theme.COLOR.USER_PRIMARY}`
      : ""};
  padding: ${props => (props.isActiveTemplate ? `1px` : "")};
  border-radius: 2px;
`;

const TemplateTitle = styled.h3`
  margin-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
  font-size: 14px;
`;

const TemplateImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 2px;
`;

const TemplateGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const InputFieldWrapper = styled.div``;

const sharedInputStyle = css`
  box-sizing: border-box;
  display: block;
  width: 100%;
  resize: none;
  border-radius: 4px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  font-family: ${props => props.theme.FONT.LATO};
  font-size: 12px;
  border: 1px solid ${props => props.theme.COLOR.INPUT_BORDER_COLOR};
  background: ${props => props.theme.COLOR.INPUT};
  &::placeholder {
    ${props => props.theme.SNIPPETS.FONT_STYLE};
    opacity: 0.4;
  }

  &:focus {
    outline: none;
  }
`;

const FieldsWrapper = styled.div`
  width: 40%;
  padding: 24px 41px;
  margin: 28px 0 50px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  background: ${props => props.theme.COLOR.WHITE};
`;

const LabelCSS = css`
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  opacity: 0.64;
  display: inline-block;
  margin-bottom: 6px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  cursor: pointer;
  display: block;
  text-align: left;
  font-size: ${props => props.labelFontSize || "10px"};
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
  vertical-align: top;
  &:nth-of-type(2) {
    margin-right: 20px;
  }

  .date-field {
    label:first-child {
      ${LabelCSS}
      margin-bottom: 0;
    }
    input {
      height: 30px;
      ${sharedInputStyle}
      padding: 0;
    }
    svg {
      top: 66%;
    }
  }
`;

const CobrandLogoWrapper = styled.div`
  width: 40%;
`;

export default Documents;
