import React from "react";
import styled from "styled-components";
import StepDetails from "components/buildProcess/stepDetails";
import UploadedFiles from "./uploadedFiles";
import UploadFiles from "./uploadFiles";
import { get } from "lodash";

const Appendix = props => {
  let {
    modifyStepHandler,
    activeStep,
    onDocumentSaveData,
    getCurrentTabDetails
  } = props;
  const currentTab = getCurrentTabDetails();

  return (
    <>
      <StepDetails
        title={get(currentTab, `name`)}
        _next
        _save
        _previous={activeStep !== 0}
        onNext={async () => {
          let isMoveToNextStep = await onDocumentSaveData("Appendix");
          if (isMoveToNextStep) {
            modifyStepHandler(activeStep + 1, "Appendix");
          }
        }}
        onPrevious={() => modifyStepHandler(activeStep - 1)}
        onSave={() => onDocumentSaveData("Appendix")}
        description={`Upload, Edit and Delete ${get(
          currentTab,
          `name`
        )} Items.`}
        {...props}
      />
      <PageWrapper>
        <UploadedFiles {...props} />
        <UploadFiles {...props} showCheckbox={false} />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  margin: 20px 0 100px;
  justify-content: space-between;
  align-items: flex-start;

  .checkbox-container {
    margin-top: 20px;
    label {
      font-size: 14px;
    }
  }

  .upload-slide {
    margin-top: 20px;
  }
`;

export default Appendix;
