// libraries
import React, { useRef, useState } from "react";
import { map, size } from "lodash";
import styled, { css } from "styled-components";

// components
import { Close } from "assets/icons";
import { SlidePreviewLeftArrow, SlidePreviewRightArrow } from "assets/icons";

const ButtonScrollSlider = props => {
  const { contentElements, isShowDeleteIcon } = props;
  const sliderRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(0);

  // handler for scroll left or right
  const sliderHandler = (navigationDirection = "left") => {
    const scrollAmount =
      navigationDirection === "left"
        ? sliderRef.current.scrollLeft - 150
        : sliderRef.current.scrollLeft + 150;

    sliderRef.current.scrollLeft = scrollAmount;
    setScrollAmount(scrollAmount);
  };

  return (
    <Slider>
      {scrollAmount > 0 && (
        <LeftArrowIconWrapper onClick={() => sliderHandler("left")}>
          <SlidePreviewLeftArrow className="left-arrow" />
        </LeftArrowIconWrapper>
      )}
      <UploadedFiles ref={sliderRef}>
        {map(contentElements, (eachElement, index) => {
          return (
            <ImageWrapper key={index}>
              <Image src={eachElement.url} />
              {isShowDeleteIcon && (
                <CloseIconWrapper>
                  <CloseIcon />
                </CloseIconWrapper>
              )}
            </ImageWrapper>
          );
        })}
      </UploadedFiles>
      {size(contentElements) > 4 && (
        <RightArrowIconWrapper onClick={() => sliderHandler("right")}>
          <SlidePreviewRightArrow className="right-arrow" />
        </RightArrowIconWrapper>
      )}
    </Slider>
  );
};

ButtonScrollSlider.defaultProps = {
  contentElements: [],
  isShowDeleteIcon: true
};

const ArrowIconWrapper = css`
  width: 40px;
  height: 40px;
  display: inline-block;
  background: ${props => props.theme.COLOR.NAVIGATION_SLIDER};
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
`;

const RightArrowIconWrapper = styled.span`
  ${ArrowIconWrapper};
  position: absolute;
  right: 0px;
`;

const LeftArrowIconWrapper = styled.span`
  ${ArrowIconWrapper};
  position: absolute;
  left: 0px;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 22px;
  height: 22px;
`;

const CloseIconWrapper = styled.div`
  background-color: ${props => props.theme.COLOR.WHITE};
  position: absolute;
  top: -7px;
  right: -6px;
  width: 20px;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
`;

const Slider = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  max-width: 200px;
  min-height: 100px;
  margin-right: 20px;
  position: relative;
`;

const Image = styled.img`
  height: auto;
  max-width: 150px;
  min-width: 100px;
  min-height: 50px;
  display: block;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  background-color: rgba(0, 0, 0, 0.1);
`;

const UploadedFiles = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  scroll-behavior: smooth;
  padding: 10px;
  align-items: center;
`;

export default ButtonScrollSlider;
