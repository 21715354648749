import React from "react";
import container from "./container";
import styled from "styled-components";
import CustomTable from "components/customTable";
import UploadFiles from "./components/uploadFiles";

const Fonts = props => {
  return (
    <>
      {true && (
        <Wrapper>
          <TableWrapper>
            <CustomTable
              data={props.fonts || []}
              tableHeader={props.cols}
              columnWidth={props.columnWidth}
              tableColumnHeader={props.tableColumnHeader}
              renderHead={props.renderHead}
              showIcon={props.showIcon}
              expanderWidth="25"
              handleNewButtonClick={false}
              searchFields={props.searchFields}
              renderTableCell={props.renderTableCell}
              searchFieldName="name"
              role="admin" // eslint-disable-line jsx-a11y/aria-role
              _newButton
              defaultSorted={[
                {
                  id: "name",
                  desc: false
                }
              ]}
            />
          </TableWrapper>

          <UploadFiles {...props} />
        </Wrapper>
      )}
    </>
  );
};

export default container(Fonts);

const Wrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  .table-wrapper {
    background-color: transparent;
    padding: 0;
  }

  .heading {
    margin-bottom: 10px;
    font-size: 20px;
    color: ${props => props.theme.COLOR_PALLETE.GREY};
  }

  .admin-search {
    position: static;
    top: 0;
  }
`;

const TableWrapper = styled.div`
  width: 75%;
  padding: 27px 40px 48px;
  background-color: #fff;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
`;
