import React from "react";
import styled from "styled-components";

import { DropTarget } from "react-dnd";
import TemplateBlockContainer from "./components/templateBlock";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import ToastUtils from "utils/handleToast";
import { QuestionIconSM } from "assets/icons";
import { size } from "lodash";

const Types = {
  CUSTOMITEM: "customitem"
};
const CUSTOM_BLOCKS_NO_DATA_TEXT =
  "Please drag blocks from above and drop here to configure your template.";

const customTemplateTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem();
    return {
      customTemplate: item
    };
  }
};

// DnD DropTarget - collect
const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
};

class TemplateBlocks extends React.Component {
  componentDidMount() {
    this.props.clearOffAllTemplateBlocks();
    const { templateId } = this.props.match.params;
    this.props.addTemplateBlocksAdmin(templateId);
  }

  componentWillUnmount() {
    this.props.clearOffAllTemplateBlocks();
  }

  onDragEnd = result => {
    const { templateUpdateHandler } = this.props;
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const stateObj = this.props.templateBlocks;
    const [replaced] = stateObj.splice(source.index, 1);
    stateObj.splice(destination.index, 0, replaced);
    this.props.reorderTemplateBlock(stateObj);
    this.props.onClickPreviewTemplateBlock(stateObj[destination.index], true);
    templateUpdateHandler(stateObj, false, true);
    return;
  };

  render() {
    let {
      connectDropTarget,
      onClickPreviewTemplateBlock,
      isActiveOnClickTemplateBlocks,
      templateBlocks
    } = this.props;

    return connectDropTarget(
      <div className="template-blocks">
        <Heading>
          Template Blocks
          <QuestionIcon />
        </Heading>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="template-blocks-border">
            <CustomBlocks>
              {size(templateBlocks) === 0 && (
                <NoDataText>{CUSTOM_BLOCKS_NO_DATA_TEXT}</NoDataText>
              )}
              <TemplateBlockContainer
                removeBlockTemplate={this.props.removeBlockTemplate}
                stateTemplateBlocks={this.props.templateBlocks}
                onClickPreviewTemplateBlock={onClickPreviewTemplateBlock}
                isActiveOnClickTemplateBlocks={isActiveOnClickTemplateBlocks}
              />
            </CustomBlocks>
          </div>
        </DragDropContext>
      </div>
    );
  }
}

const QuestionIcon = styled(QuestionIconSM)`
  height: 16px;
  margin-left: 10px;
`;

const Heading = styled.h3`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.COLOR.MAIN};
  margin: 10px 0 10px 0;
  text-align: left;
`;

const NoDataText = styled.span`
  padding: 0 4px;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.COLOR.SECONDARY};
  display: block;
`;
const SpacingWrapper = styled.div`
  margin: 0 0 10px 0;
`;

const CustomBlocks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
  flex-direction: column;
`;

const mapStateToProps = state => {
  return { templateBlocksDetails: state.domain.REQUEST_TEMPLATE_BLOCK_CLIENT };
};

export default connect(mapStateToProps)(
  DropTarget(Types.CUSTOMITEM, customTemplateTarget, collect)(TemplateBlocks)
);
