import React from "react";
import { DragSource } from "react-dnd";
import styled from "styled-components";
import EditableInput from "components/editableInput/container";
import { Close } from "assets/icons";
import { get } from "lodash";
import { connect } from "react-redux";

const Types = {
  CUSTOMITEM: "customitem"
};

const customTemplateSource = {
  beginDrag(props, monitor, component) {
    return {
      props
    };
  },

  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { addToCustomTemplate, templateHandler } = props;
      const droppedItem = monitor.getItem();
      const item = droppedItem.props.eachBlock;
      addToCustomTemplate(item);
      templateHandler(droppedItem);
    }
  }
};

function Block(props) {
  let {
    removeBlock,
    isAnyFileNameEdited,
    setFileNameEditedFlag,
    saveInputValueHandler,
    connectDragSource,
    isDragging
  } = props;

  return connectDragSource(
    <div
      key={get(props, `eachBlock.modifiedId`)}
      style={{
        marginRight: "20px",
        opacity: `${isDragging ? 0.2 : 1}`,
        transform: "translate3d(0, 0, 0)",
        cursor: "pointer"
      }}
    >
      <BlockWrapper>
        <ImageWrapper>
          <StyledImage src={props.eachBlock.thumbnailLocation} />
          <CloseIconWrapper>
            <CloseIcon onClick={() => removeBlock(props.index)} />
          </CloseIconWrapper>
        </ImageWrapper>

        <EditableInput
          defaultValue={get(props.eachBlock, `name`)}
          saveInputValueHandler={value =>
            saveInputValueHandler(value, props.index)
          }
          title={get(props.eachBlock, `name`)}
          isAnyValueEdited={isAnyFileNameEdited}
          checkIfFieldEdited={setFileNameEditedFlag}
        />
      </BlockWrapper>
    </div>
  );
}

const ImageWrapper = styled.div`
  width: 100px;
  position: relative;
  border: 1px solid ${props => props.theme.COLOR.MODAL_FADED_GREY};
  padding: 4px;
`;

const CloseIconWrapper = styled.div`
  background-color: ${props => props.theme.COLOR.WHITE};
  position: absolute;
  top: -7px;
  right: -6px;
  width: 20px;
  border-radius: 50%;
  height: 20px;
  cursor: pointer;
`;

const BlockWrapper = styled.div`
  position: relative;
  margin-right: 20px;
  display: inline-block;
  max-width: 100px;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 22px;
  height: 22px;
`;

const StyledImage = styled.img`
  height: auto;
  display: block;
  max-width: 51px;
  margin: 0 auto;
  max-height: 66px;
`;

const mapStateToProps = state => {
  return { templateBlocksDetails: state.domain.REQUEST_TEMPLATE_BLOCK_CLIENT };
};

export default connect(mapStateToProps)(
  DragSource(Types.CUSTOMITEM, customTemplateSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(Block)
);
