import React from "react";
import styled from "styled-components";

import TabHeader from "components/tabHeader";
import { ViewBySearch } from "./components/ViewData";
import { ViewByTopic } from "./components/ViewByTopic";
import Container from "./container";
import { useSelector } from "react-redux";

let TabList = [
  { title: "View by Topic", value: "View by Topic" },
  { title: "View by Search", value: "View by Search" }
];

/**
 * Component defined for view tabs
 * @param {*} props
 */
const ViewTab = props => {
  const {
    setTab,
    selectedTabValue,
    handleModal,
    viewByTopicSearch,
    viewByScreenRef,
    searchLibrarySearch,
    fromPresentationScreen
  } = props;

  /**
   * There are two tools sharing the same logic but
   * holding the values in different places. There is a
   * pre-existing variable searchLibrarySearch which was
   * working fine for PM tool but was breaking for Library tool.
   *
   * Both are using different action types. Hence putting a
   * condition to rightly distinguish between the two.
   */
  const searchSlideResultsFromStore = useSelector(
    state => state.SUCCESS_LIBRARY_TOPIC
  );
  return (
    <>
      <ViewHeader>
        <TabHeader
          data={TabList}
          manageStates={setTab}
          active={selectedTabValue}
        />
      </ViewHeader>

      <ViewScrollContainer ref={viewByScreenRef}>
        {selectedTabValue === "View by Topic" ? (
          <ViewTopic className="tabsData active-tab">
            <ViewByTopic
              topics={viewByTopicSearch[selectedTabValue]}
              fromPresentationScreen={fromPresentationScreen}
              {...props}
              addPresentation={handleModal}
            />
          </ViewTopic>
        ) : (
          <ViewSearch className="tabsData active-tab">
            <ViewBySearch
              //  searchLibrarySearch is for PM tool searches & the other one is for library
              data={
                searchLibrarySearch
                  ? searchLibrarySearch
                  : searchSlideResultsFromStore.libraryBySearchList
              }
              addPresentation={(e, id, data) => handleModal(id, null, data)}
              {...props}
            />
          </ViewSearch>
        )}
      </ViewScrollContainer>
    </>
  );
};

const ViewHeader = styled.div`
  width: 278px;
  position: relative;
  top: 3px;
  .active {
    border-bottom: none;
  }

  ul {
    li {
      padding: 9px 0 10px;
    }
  }
`;

const ViewTopic = styled.div``;

const ViewSearch = styled.div`
  .search-wrapper {
    width: 94%;
    display: block;
    margin: 27px auto;

    input {
      min-width: auto;
      width: calc(100% - 45px);
    }
  }
`;

const ViewScrollContainer = styled.div`
  ${props => props.theme.SNIPPETS.BOX_SHADOW};

  .slide-scrollbar {
    .shadow {
      width: 100%;
      height: 56px;
      border-radius: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(
        to bottom,
        ${props => props.theme.COLOR.SCROLL_SHADOW},
        ${props => props.theme.COLOR.WHITE}
      );
      pointer-events: none;
    }

    .shadowTop {
      background-image: linear-gradient(
        to top,
        ${props => props.theme.COLOR.SCROLL_SHADOW},
        ${props => props.theme.COLOR.WHITE}
      );
      top: 0;
    }
  }
`;

export default Container(ViewTab);
