import React from "react";
import styled from "styled-components";

import container from "./container";
import StepDetails from "components/buildProcess/stepDetails";
import ReactRange from "./components/rangeSlider";
import LayoutTypeSelector from "./components/layoutTypeSelector";
import SortableSlidesGrid from "./components/sortableSildeGrid";
import SlidesSelector from "./components/slideSelector";
import { filter } from "lodash";

const UI_STRING = {
  DESCRIPTION:
    "All items below are drag-and drop . Hold shift to select multiple slides. If you’d like to add more slides to your presentation, you can select “library” from the top menu. Any new slides added to your presentation will be automatically placed at the bottom of this “sort” screen and temporarily highlighted in green so you can easily find them.",
  APPENDIX_LIMIT:
    "You have exceeded 150 slides. Overview slide won't be included in your presentation.",
  GREYEDOUTSLIDES:
    "<br><span style='color: red'>Note: The Divider/Blank, has been removed/updated by Admin. Please remove/replace the grayed out slides with new Divider/Blank slides</span>"
};

const Sort = props => {
  let isThumbnailLayout = props.selectedLayout === "thumbnail";
  let {
    onPreview,
    isEdited,
    onSave,
    isSidebarSticky,
    overviewLimitExceeded,
    sideBarRef,
    thumbnailLayoutRef,
    getUnseenOverlaySlideId,
    selectedSlidesListDetail
  } = props;

  let greyedOutSlideText = filter(selectedSlidesListDetail, singleSlide => {
    if (singleSlide.disabledThemeSlide) return singleSlide;
  });

  return (
    <SortContainer>
      <StepDetails
        _next
        _preview
        _save={isEdited}
        onPreview={() => {
          onPreview();
        }}
        title="Sort"
        onSave={onSave}
        onNext={() => {
          props.nextStepHandler(4);
          getUnseenOverlaySlideId("sort");
        }}
        description={
          greyedOutSlideText.length
            ? UI_STRING.DESCRIPTION + UI_STRING.GREYEDOUTSLIDES
            : UI_STRING.DESCRIPTION
        }
      />
      {overviewLimitExceeded && <Warning>{UI_STRING.APPENDIX_LIMIT}</Warning>}
      <Actions>
        {isThumbnailLayout && (
          <RangeWrapper>
            <ReactRange {...props} />
          </RangeWrapper>
        )}
        <LayoutTypeSelector {...props} />
      </Actions>
      <Main isSticky={isSidebarSticky}>
        <Sidebar isSticky={isSidebarSticky} ref={sideBarRef}>
          <SlidesSelector {...props} />
        </Sidebar>
        <Slides isThumbnailLayout={isThumbnailLayout} ref={thumbnailLayoutRef}>
          <SortableSlidesGrid {...props} />
        </Slides>
      </Main>
    </SortContainer>
  );
};

const Warning = styled.span`
  margin-top: 20px;
  width: 54%;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;

const SortContainer = styled.div`
  max-width: ${props => props.theme.WRAPPER.MAX_WIDTH};
  margin: 0 auto;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  margin-bottom: 50px;

  .content {
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const RangeWrapper = styled.div`
  margin-right: 3.6%;
  display: inline-block;
`;

const Actions = styled.div`
  margin: 15px 0;
  display: flex;
  justify-content: flex-end;
`;

const Sidebar = styled.div`
  width: 20%;
  border-radius: 3px;

  ${props =>
    props.isSticky &&
    `
    width: 19%;
    position: fixed;
    top: 95px;
    left: 2.5%
    z-index: 3;
    max-width: 250px;
  `};

  @media all and (min-width: 1265px) {
    left: calc((100vw - 1266px) / 2);
  }
`;

const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${props => (props.isSticky ? `flex-end` : `space-between`)};
  position: relative;
`;

const Slides = styled.div`
  box-sizing: border-box;
  width: calc(80% - 20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${props =>
    !props.isThumbnailLayout &&
    `
    padding: 40px 10px 30px 15px;
    background-color: ${props.theme.COLOR.WHITE};
    border-radius: 3px;
    ${props.theme.SNIPPETS.BOX_SHADOW};
  `}
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 4px;
    outline: 1px solid slategrey;
  }
`;

export default container(Sort);
