// libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import LandingPageComponent from "./index";
import { get, uniqBy } from "lodash";
import { getToolsList, setLastLoggedInTool } from "components/header/services";

//services
import { getProfileDetail } from "pages/app/services/getUserDetail";

const mapStateToProps = state => {
  const { SUCCESS_USER_PROFILE, SUCCESS_USER_LIST, MENU_STATE, TOOLS_LIST } = state;

  return {
    ...SUCCESS_USER_PROFILE,
    ...SUCCESS_USER_LIST,
    ...MENU_STATE,
    ...TOOLS_LIST,
  };
};

document.execCommand("copy");

const mapDispatchToProps = {
  getProfileDetail,
  getToolsList,
};

class LandingPageContainer extends Component {
  state = {
    cols: [
      {
        col1: "Name",
        col2: "Types",
        col3: "Notes",
        col4: "Action"
      }
    ],
    columnWidth: [240, 80, 200, 78],
    tableColumnHeader: ["name", "types", "notes", ""],
    toolOptions: this.props.toolOptions ? this.props.toolOptions : []
  };

  async componentDidMount() {
    //check if token is present to get profile data
    if (localStorage.getItem("token")) {
      await this.props.getToolsList();
      await this.props.getProfileDetail(true);
      this.props.userProfileMeta && this.setToolOptions();
    }
    // set user detail profile if present and get presentation meta details
    this.setUserProfile();
  }

  componentDidUpdate(prevProps) {
    // fetch and store user detail to fetch user id and get presentation required detail.
    if (this.props.userProfileMeta !== prevProps.userProfileMeta) {
      this.setUserProfile();
    }
  }

  /**
   * Get User Profile based on API respone and store in local state
   *
   */
  setUserProfile = () => {
    const { userProfileMeta } = this.props;
    userProfileMeta &&
      this.setState({
        userDetail: userProfileMeta
      });
  };

  /**
   * Check current Active user roles and set the tool options
   *
   */
  setToolOptions = () => {
    const { tools } = this.props;
    this.updateToolOptions(tools);
  };

  // update tool options array based on the tool last logged in
  updateToolOptions = (toolOptions) => {
    const { lastLoggedInTool } = this.props;

    const newToolList = [...toolOptions];
    const lastToolIndex = newToolList.findIndex(
      ({ slug }) => slug === lastLoggedInTool
    );

    if (lastToolIndex >= 0 && newToolList.length > 0) {
      newToolList.splice(lastToolIndex, 1);
      newToolList.unshift(toolOptions[lastToolIndex]);
    }
    newToolList[0].default = true;

    this.setState({
      toolOptions: newToolList
    });
  };

  // Set the page/document title
  setDocumentTitle = (title = "") => {
    let location = this.props.location;
    location.pathname === "/optumhome" && (title = "Presentation HUB");
    document.title = title || get(this.props, "clientConfig.siteName", "");
  };

  /**
   *Callback function for dropdown option  click
   * @param event event object of the option
   * @param selectedTool The selected option data
   */
  handleOptionClick = ({ selectedTool, event }) => {
    event.preventDefault();
    this.setLastTool(selectedTool);

    // url parameter is present only if selectedTool comes from V2
    // so whenever it is present we will redirect whole app to the new tool
    return window.location.replace(selectedTool.url);
  };

  // set last tool used in redux persist so that last tool used is available even on window close then open
  setLastTool = selectedTool => setLastLoggedInTool(selectedTool.slug || "rg");

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <LandingPageComponent {...stateMethodProps} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageContainer);
