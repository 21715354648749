import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { get } from 'lodash'

function ConfigureHelmet (props) {
  let faviconUrl = get(props, 'clientConfig.favicon', '')
  let title = get(props, 'clientConfig.siteName', '')
  const plausibleEnabled = get(props, 'clientConfig.analyticsConfig.plausible.enabled', false);
  const plausibleDomain = get(props, 'clientConfig.analyticsConfig.plausible.domain', '');

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        {title && <title>{title}</title>}
        {faviconUrl && (
          <link rel='shortcut icon' id='favicon' href={faviconUrl} />
        )}
        {plausibleEnabled && (
          <script
            defer
            data-domain={`clatter.io,${plausibleDomain}`}
            event-tool="PGA"
            src="https://plausible.io/js/script.file-downloads.local.outbound-links.pageview-props.js"
          />
        )}
      </Helmet>
    </>
  )
}

export default connect(state => {
  const { CLIENT_CONFIG_SUCCESS, CLIENT_CONFIG_LOADING } = state

  return {
    ...CLIENT_CONFIG_SUCCESS,
    ...CLIENT_CONFIG_LOADING
  }
}, {})(ConfigureHelmet)
