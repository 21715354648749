import React from "react";
import styled from "styled-components";
import BgWrapper from "components/bgWrapper";
import { tableData } from "./data";
import CustomTable from "components/customTable";
import FullPageLoader from "components/FullPageLoader";

const summaryMakerUser = props => {
  let {
    tableColumnsKeys,
    tableColumns,
    columnWidth,
    renderHead,
    showIcon,
    isTemplateListLoading,
    handleDocumentAddModal,
    documentDashboardData,
    renderTableCell
  } = props;

  return (
    <PageWrapper>
      {isTemplateListLoading && <FullPageLoader layerIndex={2} />}

      <DashboardTable>
        <CustomTable
          data={documentDashboardData || []}
          columnWidth={columnWidth}
          searchFields={tableData[0].searchFields}
          renderHead={renderHead}
          showIcon={showIcon}
          expanderWidth="25"
          tableColumnHeader={tableColumnsKeys}
          role="admin" // eslint-disable-line jsx-a11y/aria-role
          tableHeader={tableColumns}
          searchFieldName="name"
          handleNewButtonClick={handleDocumentAddModal}
          renderTableCell={renderTableCell}
          defaultSorted={[
            {
              id: "updatedAt",
              desc: true
            }
          ]}
        />
      </DashboardTable>
    </PageWrapper>
  );
};

const DashboardTable = styled.div``;

const PageWrapper = styled.div`
  max-width: 1024px;
  margin: 0px auto 30px;
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  .new-button {
    button {
      position: absolute;
      top: -103px;
      right: 0;
    }
  }

  .admin-search {
    position: absolute;
    top: 80px;
  }

  .heading {
    margin-bottom: 86px;
    font-size: 21px;
  }

  .action-buttons {
    width: 94%;
    display: flex;
    justify-content: space-between;
  }
  .overlay-content {
    z-index: 1000;
  }
`;

export default BgWrapper(summaryMakerUser);
