// This data for other cases may come from BE, for OE this data for topics is hard wired
import { SinglePlaceHolder, StackPlaceHolder } from "assets/images";

const layoutDetails = [
  {
    _id: "5ddccb1482698a49f86769bf",
    name: "Page 1",
    layoutTypeDetails: {
      None: [
        {
          width: "75%",
          height: "75%",
          type: "none"
        }
      ],
      Single: [
        {
          width: "75%",
          height: "75%",
          type: "single",
          placeHolder: SinglePlaceHolder
        }
      ],
      Stack: [
        {
          width: "75%",
          height: "50%",
          type: "stack",
          minHeight: "96px",
          placeHolder: StackPlaceHolder
        },
        {
          width: "75%",
          height: "50%",
          type: "stack",
          placeHolder: StackPlaceHolder
        }
      ],
      Quad: [
        {
          width: "47%",
          height: "50%",
          type: "quad",
          placeHolder: StackPlaceHolder
        },
        {
          width: "47%",
          height: "50%",
          type: "quad",
          placeHolder: StackPlaceHolder
        },
        {
          width: "47%",
          height: "50%",
          type: "quad",
          placeHolder: StackPlaceHolder
        },
        {
          width: "47%",
          height: "50%",
          type: "quad",
          placeHolder: StackPlaceHolder
        }
      ]
    }
  }
];

export { layoutDetails };
