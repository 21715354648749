// libraries
import React, { Component } from "react";
// components
import LayoutBlock from "./index";
import { get, filter, toLower, find } from "lodash";

const LAYOUT_OPTIONS = [
  {
    label: "None",
    checked: false,
    key: "none"
  },
  {
    label: "Single",
    checked: false,
    key: "single"
  },
  {
    label: "Stack",
    checked: false,
    key: "stack"
  },
  {
    label: "Quad",
    checked: false,
    key: "quad"
  }
];

const layoutDropdownOption = [
  {
    _id: "0",
    title: "Single"
  },
  {
    _id: "1",
    title: "Stack"
  },
  {
    _id: "2",
    title: "Quad"
  }
];

class LayoutBlockpage extends Component {
  state = {
    layoutContentElements: [],
    layoutOptions: [],
    selectedLayout: "single"
  };

  sliderRef = React.createRef();
  layoutDropdownOption = layoutDropdownOption;

  componentDidMount() {
    this.getLayoutBlocks();
    this.setState({
      layoutOptions: LAYOUT_OPTIONS
    });
  }

  getLayoutBlocks = async (isUpload = false) => {
    if (!get(this.props, `layoutBlocks.length`) || isUpload) {
      await this.props.getBlockContentHandler("layoutBlocks");
    }

    const { layoutBlocks } = this.props;
    this.setState({
      layoutContentElements: layoutBlocks
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.layoutBlocks !== prevProps.layoutBlocks) {
      this.setState({
        layoutContentElements: this.props.layoutBlocks
      });
    }
  }

  layoutOptionHandler = (e, label) => {
    const { layoutOptions } = this.state;
    const { handleStateChange } = this.props;
    const activeCheckbox = find(layoutOptions, { label });
    activeCheckbox.checked = e.target.checked;

    handleStateChange({ key: "isBlockEdited", value: true });
    this.setState({
      layoutOptions
    });
  };

  saveHandler = () => {
    const { layoutOptions } = this.state;
    const { showToastMessage, updateTemplateBlockHandler } = this.props;

    // get the selected layout
    const selectedOptions = filter(layoutOptions, eachOption => {
      return get(eachOption, `checked`);
    }).map(eachCheckedOption => {
      return get(eachCheckedOption, `key`);
    });

    if (!selectedOptions.length) {
      showToastMessage("error", "Please select layout options.");
      return;
    }

    updateTemplateBlockHandler({ layoutOptions: selectedOptions });
  };

  handleDropDownChange = e => {
    const selectedLayout = (
      find(this.layoutDropdownOption, {
        _id: e.target.value
      }) || {}
    ).title;

    this.setState({
      selectedLayout: toLower(selectedLayout)
    });
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <LayoutBlock {...stateMethodProps} />;
  }
}

export default LayoutBlockpage;
