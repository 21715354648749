import FetchUtilsV2 from "utils/FetchUtilsV2.js";
const postContentRepositoryAcls = async (url, body) => {
  const response = await FetchUtilsV2.postData(url, body, "Post Data ==>");
  return response;
};

const deleteContentRepositoryAcls = async (url) => {
  const response = await FetchUtilsV2.deleteData(url, "Delete Data ==>");
  return response;
};

const getContentRepositoryAcls = async url => {
  const response = await FetchUtilsV2.getData(url, "Get Data ==>");
  return response ? response.data : [];
};

export {
  postContentRepositoryAcls,
  getContentRepositoryAcls,
  deleteContentRepositoryAcls
};
