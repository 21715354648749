import { logError } from "./sentryLogger";
import { getAxiosClientWithRefreshToken } from "./axiosUtils";
import { handleReponseConvert } from "./FetchUtils";

const client = getAxiosClientWithRefreshToken({ baseURL: process.env.REACT_APP_CLATTER_API_V2_URL });

/**  API Calling methods integrated with axios */
class FetchUtilsV2 {
  getData = async (url, log) => {
    try {
      return client.get(url)
        .then((response) => handleReponseConvert({ response }))
        .catch((error) => {
          console.log("FetchUtilsV2.getData err", JSON.stringify({ error, log }, null, 2));
          return handleReponseConvert({ error });
        });
    } catch (error) {
      console.log("FetchUtilsV2.getData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  postData = async (url, body, log) => {
    try {
      const response = await client.post(url, body);
      /**
       * There is no need to handle the error of the .catch block here
       * The handleResponseConvert Function is serving the error through
       * a common function which is used across the application.
       */
      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.postData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

  deleteData = async (url, log) => {
    try {
      const response = await client.delete(url);
      /**
       * There is no need to handle the error of the .catch block here
       * The handleResponseConvert Function is serving the error through
       * a common function which is used across the application.
       */
      return handleReponseConvert({
        response
      });
    } catch (error) {
      console.log("FetchUtils.postData error", JSON.stringify({ error, log }, null, 2));
      logError({
        error,
        extraData: error.response
      });
      return handleReponseConvert({
        error
      });
    }
  };

}

export default new FetchUtilsV2();
