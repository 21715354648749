import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { ProfileProvider } from "context/ProfileContext";
import Routes from "routes";
import GlobalStyle from "globalStyle";
import Helmet from "pages/helmet";
import { PersistGate } from "redux-persist/integration/react";
// configure redux store
import { store, persistor } from "./store";
import CustomThemeProvider from "./utils/themeProvider";

function AllProvider() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomThemeProvider>
          <ProfileProvider>
            <Helmet />
            <Fragment>
              <Routes />
              <GlobalStyle />
            </Fragment>
          </ProfileProvider>
        </CustomThemeProvider>
      </PersistGate>
    </Provider>
  );
}
export default AllProvider;
