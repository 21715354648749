import React, { Component } from "react";
import { get, flatten, map, forEach } from "lodash";
import ToastUtils from "utils/handleToast";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
const UI_STRINGS = {
  DELETE_THEME:
    "Do you want to remove this template from existing presentations?"
};
const Container = Main =>
  class ThemePreview extends Component {
    constructor(props) {
      super(props);

      this.state = {
        activeThemeName: {
          value: "",
          error: "",
          initialValue: ""
        },
        isThemeTitleEditable: false,

        activeThemeDetails: {},
        isSlidePreviewToggleOpen: true,
        showContent: true,
        currentActiveIndex: 0,
        slideTypeName: "",
        slideTaggedAs: "",
        slideThumbnail: null,
        availableSlides: [],
        slideType: "Cover",
        taggedLayout: []
      };

      this.titleRef = React.createRef();
    }

    componentDidMount() {
      this.fetchRequiredDetailsForPreview();
    }

    fetchRequiredDetailsForPreview = () => {
      let { activeThemeName } = this.state;
      let activeThemeDetails = this.props.activeThemeDetails || {};
      this.formatThemeData(activeThemeDetails);
      activeThemeName.value = get(activeThemeDetails, `title`);
      activeThemeName.initialValue = get(activeThemeDetails, `title`);
      this.setState({
        activeThemeDetails,
        activeThemeName
      });
    };

    formatThemeData = activeThemeDetails => {
      let previewRequiredFields = [
        "Blank",
        "Cover",
        "Divider",
        "PrimaryCover",
        "PrimaryContent",
        "Content"
      ];
      let availableSlides = [];
      for (const property in activeThemeDetails) {
        if (previewRequiredFields.indexOf(property) > -1) {
          if (activeThemeDetails[property].length > 1) {
            // set a property count for every slide as per the index
            let activeThemeDetailsWithCount = map(
              activeThemeDetails[property],
              (field, index) => {
                field.count = index + 1;
                return field;
              }
            );

            availableSlides.push(activeThemeDetailsWithCount);
          } else {
            activeThemeDetails[property][0].count = 1;
            availableSlides.push(activeThemeDetails[property][0]);
          }
        }
      }
      // flatten the array in case if there is more than 1 type of slide
      availableSlides =
        this.props.activeThemeDetails.subMasterDetails ||
        flatten(availableSlides);
      this.setState({
        availableSlides
      });
    };

    setTagging = slideType => {
      let { availableSlides } = this.state;
      if (slideType === "PrimaryCover" || slideType === "PrimaryContent") {
        availableSlides.forEach(item => {
          if (
            slideType === item.editedSlideType ||
            (!item.editedSlideType && slideType === item.slideType)
          ) {
            ToastUtils.handleToast({
              operation: "warning",
              message: `You have already selected one slide as ${slideType}`
            });
          }
        });
      }
      this.props.handleStateChange({ key: "hideContinue", value: false });
      this.setState(
        {
          slideType
        },
        () => {
          this.taggedSlide(this.state.slideType);
        }
      );
    };

    taggedSlide = slideValue => {
      let { availableSlides, currentActiveIndex } = JSON.parse(
        JSON.stringify(this.state)
      );

      availableSlides[currentActiveIndex].editedSlideType = slideValue;
      this.setState({
        availableSlides
      });
    };

    continueHandler = () => {
      let { activeThemeDetails, isEdited } = this.props;
      let { availableSlides } = this.state;
      let isPrimary = false;
      availableSlides.forEach(item => {
        if (
          item.editedSlideType === "PrimaryCover" ||
          item.editedSlideType === "PrimaryCover"
        ) {
          isPrimary = true;
        }
      });
      let { totalSlidesBasedOnPrimaryContent } = activeThemeDetails;

      if (totalSlidesBasedOnPrimaryContent && isEdited && isPrimary) {
        DeleteConfirmationAlert({
          message: `A new layout has been tagged as primary content. This would lead to re-generation of ${totalSlidesBasedOnPrimaryContent} thumbnails/ppt slides.`,
          onYesClick: async () => {
            this.postProcessingThemeLayout();
          }
        });
      } else {
        this.postProcessingThemeLayout();
      }
    };

    postProcessingThemeLayout = () => {
      let { availableSlides } = this.state;
      let { isEdited } = this.props;
      let primaryCover = 0,
        primaryContent = 0;

      availableSlides.forEach(item => {
        if (
          (!item.editedSlideType && item.slideType === "PrimaryCover") ||
          item.editedSlideType === "PrimaryCover"
        ) {
          primaryCover++;
        } else if (
          (!item.editedSlideType && item.slideType === "PrimaryContent") ||
          item.editedSlideType === "PrimaryContent"
        ) {
          primaryContent++;
        }
      });

      if (primaryContent === 1 && primaryCover === 1) {
        let body = this.formatTaggingSlideData(availableSlides);
        if (isEdited) body = this.formatEditTaggingData(availableSlides);
        this.props.saveEditedTaggedSlide(
          body,
          this.state.activeThemeDetails._id,
          false
        );
      } else {
        if (primaryContent > 1 || primaryCover > 1) {
          ToastUtils.handleToast({
            operation: "error",
            message: "There can be only one primary cover and primary content."
          });
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message:
              "You have either not selected PrimaryCover or PrimaryContent or both not selected"
          });
        }
      }
    };

    formatEditTaggingData = data => {
      let layoutDetails = [];

      forEach(data, item => {
        if (item.editedSlideType) {
          layoutDetails.push({
            slideType: item.editedSlideType,
            layoutId: item._id
          });
        }
      });

      return {
        layoutDetails
      };
    };

    formatTaggingSlideData = data => {
      let subMasterDetails = data.map(item => {
        return {
          title: item.title,
          slideType: item.editedSlideType || item.slideType,
          order: item.order,
          thumbnailLocation: {
            url: item.thumbnailLocation.url,
            key: item.thumbnailLocation.key
          }
        };
      });
      return { subMasterDetails };
    };

    /**
     * Function when title editing is cancelled
     */
    _onThemeTitleCancel = () => {
      let { activeThemeName } = this.state;
      activeThemeName["value"] = activeThemeName.initialValue;
      activeThemeName.error = "";

      this.setState({
        activeThemeName,
        isThemeTitleEditable: false
      });
    };

    /**
     * Function for saving the updated title value
     */
    _onThemeTitleSave = () => {
      let {
        activeThemeName,
        activeThemeDetails: { _id: themeId }
      } = this.state;
      let { saveEditedThemeDetails, handleModal } = this.props;
      if (activeThemeName.error) {
        ToastUtils.handleToast({
          operation: "error",
          message: "Please enter a valid theme title."
        });
        return false;
      }

      if (activeThemeName.value !== activeThemeName["initialValue"]) {
        saveEditedThemeDetails(
          { title: activeThemeName.value.trim() },
          themeId,
          "Theme title has been updated successfully."
        );
        handleModal();
      }
      activeThemeName["initialValue"] = activeThemeName.value;

      this.setState({
        activeThemeName,
        isThemeTitleEditable: false
      });
    };

    /**
     * Function to handle the title input change
     * @param {String} the value of the title input
     */
    _onThemeTitleChange = value => {
      let { activeThemeName } = this.state;

      activeThemeName["error"] = this.props.handleTextValidation(value);
      activeThemeName["value"] = value;
      this.setState({
        activeThemeName
      });
    };

    /**
     * Function to handle the edit icon click of title
     */
    _onThemeTitleEdit = () => {
      this.setState(
        {
          isThemeTitleEditable: true
        },
        () => {
          this.titleRef.current.focus();
        }
      );
    };

    /**
     * Handle the Toggle bar
     */
    _onSlideBarToggle = () => {
      this.setState({
        isSlidePreviewToggleOpen: !this.state.isSlidePreviewToggleOpen
      });

      //This is used to display the content once the full width is achievent
      setTimeout(() => {
        this.setState({
          showContent: !this.state.showContent
        });
      }, 450);
    };

    navigationHandler = (previous, next) => {
      let { currentActiveIndex, activeThemeDetails } = this.state;

      currentActiveIndex = previous
        ? currentActiveIndex - 1
        : currentActiveIndex + 1;

      this.setState({
        currentActiveIndex,
        activeThemeDetails,
        isThemeTitleEditable: false
      });
    };

    // edit mode
    handleEditSlideTagging = flag => {
      let { availableSlides, activeThemeDetails } = this.state;
      if (flag) {
        activeThemeDetails.subMasterDetails = availableSlides;
      } else {
        activeThemeDetails.subMasterDetails = null;
      }

      this.props.handleStateChange({
        key: "activeThemeDetails",
        value: activeThemeDetails
      });
      this.props.handleStateChange({ key: "isEdited", value: flag });
      this.props.handleStateChange({ key: "hideContinue", value: flag });
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props,
        onThemeTitleEdit: this._onThemeTitleEdit,
        onThemeTitleChange: this._onThemeTitleChange,
        onThemeTitleSave: this._onThemeTitleSave,
        onThemeTitleCancel: this._onThemeTitleCancel,
        onSlideBarToggle: this._onSlideBarToggle,
        prevCtaHandler: this._prevCtaHandler,
        nextCtaHandler: this._nextCtaHandler
      };

      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
