import AnnouncementReducers from "./announcement/reducer";
import AccountReducers from "./systemConfiguration/reducer";
import SearchPriorities from "./searchPriorities/reducer";
import Fonts from "./fonts/reducer";

export default {
  ...AnnouncementReducers,
  ...AccountReducers,
  ...SearchPriorities,
  ...Fonts
};
