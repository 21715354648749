import React, { Component } from "react";
import { map, get, each, find, size } from "lodash";
import { connect } from "react-redux";

// components
import Document from "./index";
import ValidationUtils from "utils/ValidationUtils";
import UI_STRINGS from "utils/stringConstants";

//services
import { getDocumentData } from "tools/summaryMaker/store/actions/documentBuildActions";

import {
  getTemplateList,
  getSelectedTemplate
} from "tools/summaryMaker/store/actions/dashboardActions";

import ToastUtils from "utils/handleToast";
import { filter } from "lodash";

const mapStateToProps = state => {
  const {
    domain: {
      RECEIVE_BUILD_DOCUMENT_DATA,
      RECEIVE_DOCUMENT_DATA,
      RECEIVE_TEMPLATE_LIST,
      SELECTED_TEMPLATE
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...SUCCESS_USER_PROFILE,
    ...RECEIVE_TEMPLATE_LIST,
    ...RECEIVE_BUILD_DOCUMENT_DATA,
    ...RECEIVE_DOCUMENT_DATA,
    ...SELECTED_TEMPLATE
  };
};

const mapDispatchToProps = {
  getDocumentData,
  getTemplateList,
  getSelectedTemplate
};

class DocumentPage extends Component {
  state = {
    documentFields: [],
    fields: [],
    isTemplateChanged: false,
    customerLogoList: []
  };

  templateClickHandler = async selectedTemplate => {
    const { isEnableTemplateSelection } = this.props;
    if (!isEnableTemplateSelection) return;

    await this.props.getSelectedTemplate(selectedTemplate);
  };

  componentDidMount() {
    this.getCurrentDocumentData();
  }

  resetDocumentFields = () => {
    let { documentFields } = this.props;

    each(documentFields, eachField => {
      eachField.value = "";
      eachField.error = "";
    });

    this.props.handleStateChange({
      key: "documentFields",
      value: documentFields,
      cb: this.getCurrentDocumentData
    });
  };

  /**
   *  gives all the fields for the document along with name and description
   *
   * @memberof DocumentPage
   * @returns {Array} consists of all the dynamic fields along with static ones
   */
  getStaticDocumentFields = () => {
    let { currentDocumentData = [] } = this.props;
    let { documentFields = [] } = this.props;

    const selectedTemplate = get(this.props, `selectedTemplate`);
    const isDocumentEdited = get(this.props, `match.params.documentId`);

    const documentName = find(documentFields, i => i._id === "name");
    // these fields will always be displayed
    let staticDocumentFields = [
      {
        _id: "name",
        label: "Document Name",
        type: "string",
        value: get(documentName, `value`)
          ? get(documentName, `value`)
          : get(currentDocumentData, `name`)
      }
    ];

    let dynamicDocumentData = [];
    if (get(selectedTemplate, `variables.length`)) {
      dynamicDocumentData = get(selectedTemplate, `variables`);

      each(dynamicDocumentData, eachField => {
        // if in edit mode take values from last saved data
        eachField.value = !isDocumentEdited
          ? get(
              find(
                this.props.documentFields,
                eachVariable => eachVariable._id === eachField._id
              ),
              `value`
            )
          : get(
              find(
                get(currentDocumentData, `variables`),
                eachVariable => eachVariable._id === eachField._id
              ),
              `value.content`
            );
        eachField.error = "";
      });
    }

    let allFields = [
      ...staticDocumentFields
      // ...(!!dynamicDocumentData ? dynamicDocumentData : []) //TODO uncomment this when dynamic fields have to be shown
    ];

    // setting default date for all date fields
    each(allFields, eachField => {
      if (get(eachField, `type`) === "date" && !!!get(eachField, `value`)) {
        eachField.value = new Date();
      }
    });

    return allFields;
  };

  getCurrentDocumentData = async () => {
    let allFields = this.getStaticDocumentFields();

    this.props.handleStateChange({
      key: "documentFields",
      value: allFields,
      cb: this.setDocumentFields()
    });
  };

  /**
   *handle validation for form fields
   * @returns {String} appropriate error message
   */
  handleValidation = value => {
    if (ValidationUtils.checkIfEmptyField(value)) {
      return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfWhiteSpace(value)) {
      return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
    } else if (ValidationUtils.checkIfspecialChar(value)) {
      return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
    } else if(value.includes('.') || value.includes('*')){
      return UI_STRINGS.CANNOT_CONTAIN_MESSAGE;
    }

    return null;
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.userProfileMeta !== this.props.userProfileMeta ||
      get(this.props, `selectedTemplate._id`) !==
        get(prevProps, `selectedTemplate._id`) ||
      get(this.props, `currentDocumentData`) !==
        get(prevProps, `currentDocumentData`)
    ) {
      this.getCurrentDocumentData();
    }
  }

  // format fields for the form
  setDocumentFields = () => {
    let allFields = this.getStaticDocumentFields();

    let fields = map(allFields, eachField => {
      return {
        type:
          get(eachField, `type`) === "string" ? "text" : get(eachField, `type`),
        label: get(eachField, `label`),
        fieldType: get(eachField, `type`) === "string" ? "input" : "textarea",
        value: get(eachField, `value.content`) || get(eachField, `value`)
      };
    });

    this.setState({
      fields
    });
  };

  handleInputChange = (value, fieldPosition, type) => {
    const { fields } = this.state;
    let { documentFields } = this.props;
    documentFields[fieldPosition].value = value;

    if (
      type !== "date" &&
      documentFields[fieldPosition]._id !== "description"
    ) {
      documentFields[fieldPosition].error = this.handleValidation(value);
    }

    fields[fieldPosition].value = value;
    this.setState({
      fields
    });

    this.props.handleStateChange({
      key: "documentFields",
      value: documentFields
    });

    this.props.handleStateChange({
      key: "isDataEdited",
      value: true
    });
  };

  // called on upload click
  logoUploadHandler = async e => {
    this.handleStateChange({
      key: "allowLogoUpload",
      value: true
    });

    let customerName = filter(this.state.setupDetails, ["key", "customerName"]);
    let reader = new FileReader();
    let fileName = get(e, "target.files[0].name", null);
    if (/\.(jpe?g|png|gif)$/i.test(fileName)) {
      ToastUtils.handleToast({ operation: "dismiss" });
      let logoPreviewUri = null;
      reader.addEventListener(
        "load",
        () => {
          logoPreviewUri = reader.result;

          this.selectedLogoHandler(logoPreviewUri);
          this.setState({
            saveLogo: false,
            showSaveProfileCheckbox: true
          });

          this.handleSelectedLogo(
            logoPreviewUri,
            this.props.userProfileMeta.name,
            this.state.saveLogo
          );
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    } else {
      ToastUtils.handleToast({
        operation: "error",
        message: "Please upload valid file format [.png or .jpg]."
      });
    }
  };
  // handle state change
  handleStateChange = ({ key, value }) => {
    this.setState({
      [key]: value
    });
  };

  deleteCurrentLogoHandler = async ({ deletedLogo }) => {
    const { currentDocumentData } = this.props;

    let value = null,
      id = null;

    if (
      currentDocumentData &&
      currentDocumentData.variables &&
      size(currentDocumentData.variables) > 0
    ) {
      id = this.state.selectedLogoId
        ? this.state.selectedLogoId
        : currentDocumentData.variables[0]._id;
      value = currentDocumentData.variables[0].value;
    }

    if (id && value && value.type === "coBrandLogo") {
      await this.props.handleStateChange({ key: "isDataEdited", value: true });
      await this.props.handleStateChange({ key: "cobrandLogoUrl", value: "" });
      await this.props.onDocumentSaveData("document");
      // await this.deleteLogoHandler(id);
      await this.getCurrentDocumentData();
    } else {
      this.props.selectedLogoHandler(null);
      this.setState({
        showSaveProfileCheckbox: false
      });
      return;
    }
  };

  onLogoClick = id => {
    let { contentRepo } = this.props;

    const imgLogoList = this.props.customerLogoList;
    const selectedLogo = find(imgLogoList, item => item._id === id);

    // show full cropper if new image is uploaded
    this.handleStateChange({
      key: "cropData",
      value: { top: 0, left: 0, width: 1000, height: 1000 }
    });

    this.selectedLogoHandler(selectedLogo);
    this.setState({
      showSaveProfileCheckbox: false
    });
  };

  deleteLogoHandler = async logoId => {
    const responce = await this.props.deleteLogo(
      get(this.props, `userProfileMeta._id`),
      logoId
    );

    if (responce.success) {
      this.props.selectedLogoHandler(null);
    }
    await this.props.getActiveDocumentData();
    // fetch the logo list on delete
    await this.props.getCustomerLogoList(
      get(this.props, `userProfileMeta._id`)
    );

    // logo selected and the logo deleted from the list is same empty the placeholder
    if (logoId === this.state.selectedLogoId) {
      this.setState({
        selectedLogo: null
      });
    }
  };
  /**
   * set flag stating if any logo title is edited
   * @param {Boolean} isAnyLogoEdited whose value needs to be checked
   */
  setLogoEditedFlag = isAnyLogoEdited => {
    this.setState({
      isAnyLogoEdited
    });
  };

  /**
   *
   * @param selectedLogo logo which was selected from the bottom or uploaded
   * @param {boolean} isImageSourceUrl states the selectedLogo is image url or data uri
   */
  selectedLogoHandler = selectedLogo => {
    if (!selectedLogo) {
      this.setState({
        selectedLogo: null,
        selectedLogoId: null
      });
      this.props.handleStateChange({
        kay: "cobrandLogoUrl",
        value: null
      });
      this.state.selectedLogo && this.onStepEdit();
      return;
    }
    this.setState({
      selectedLogo: selectedLogo.url || selectedLogo,
      croppedImage: selectedLogo.url || selectedLogo, // storing same data as selectedLogo so that croppedImage could be used for preview in the placeholder and selectedLogo as the imageSrc
      selectedLogoId: selectedLogo._id
    });
    this.onStepEdit();
  };

  /**
   * Call on any input field edited in a step
   *
   */
  onStepEdit = (flag = true) => {
    let { completedSteps, activeStep } = this.state;
    let newStepArray = filter(completedSteps, ele => {
      return ele !== activeStep;
    });
    this.setState({
      completedSteps: newStepArray,
      isEdited: flag
    });
  };

  /**
   * Upload customer logo
   *
   * @param {Object} imageData Data to be sent
   * @param {Object} title Customer name
   * @param {boolean} saveToProfile states whether the uploaded image has to saved in profile
   */
  handleSelectedLogo = async (imageData, customerName, saveToProfile) => {
    const { currentDocumentData } = this.props;
    // data to be sent in upload post
    let data = {
      imageData,
      saveToProfile,
      title: customerName
    };
    await this.props.onDocumentSaveData("document");
    let response = await this.props.createNewCustomerLogo(
      this.props.userProfileMeta._id,
      data
    );

    this.setState({
      selectedLogoId: get(response, `data._id`)
    });

    await this.props.setUrlForDocument(get(response, `data.url`));
    this.onStepEdit();
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Document {...stateMethodProps} />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentPage);
