import * as types from "./types";
import createReducersFromTypes from "store/createReducersFromTypes";
import {dfs} from "./graph"; // @todo we need a solid convention for "platform utility" layer
import { filter, size } from "lodash";

// This ensures the string values being used repeatedly everywhere, any change in implementation would turn out to be a big debugging problem.
// Hence storing the SEARCH_LIBRARY_SLIDES in a const variable to ensure the intactness.
export const SEARCH_LIBRARY_SLIDES = "SEARCH_LIBRARY_SLIDES";

const initialState = {
  contentRepos: [], // the current list of content repository objects
  contentReposById: {}, // a dictionary providing O(1) retrieval i.e. contentReposById[ contentRepoId ]
  slides: [], // the slides from the current content repository
  filteredSlides: [] // the slides from the current content repository that match the current filter criteria
};

const getSlides = hierarchy => {
  let slides = [];

  const appendSlide = v => {
    if ("slide" === v.label) slides.push(v);
  };

  hierarchy && hierarchy.forEach(t => {
    dfs(t, appendSlide);
  });
  return slides;
};

const intersect = (a, b) => {
  console.log(a, b);
  var setB = new Set(b);
  return filter(a, x=> setB.has(x))
}

/*
 slides have a .contentFilter[] which is the list of filter-value ids
 that apply to the slide

 filterValues is the set of filter-value ids selected in the UI.
 for example, when just one filter-value is selected:
[ "605e79ec8661fdd052ba3b78" ]

 when the filter-value-set is checked (i.e. [x] All), then the
 'F-0' value is included:

[
  "F-0",
  "605e79ec8661fdd052ba3b78",
  "605e79ec8661fdd052ba3b79"
]

 currently, we only implement simple OR across all selected filter values
 
 the complete solution consists of

    filter fn creator makeSlideFilter( filters ) => fn(slide) => boolean

    input: the repo filters
    output: a function that takes a slide and returns true if the
      slide should be included i.e. it's a pass filter for use with
      Array.filter below

    for each filter, create either an Or term or and And term based
    on the filter .queryType

    then, combine the filter terms in an Or term

    finally, evaluate the expression and return the result

    an Or term looks like:

    return true if ANY of the filters values (i.e. .children) are both
    
    a) in selectedFilterValues and 
    b) in slide.contentFilter

    Or( filter, slide, selectedFilterValues ) {
      let passValues = intersect( filter.children, selectedFilterValues); 
      if( 0 == passValues.length )
        // if none of this filters values are selected then
        // we don't even need to check the slide
        return false;

      // there are some of this filter's values selected
      // check if this slide has any of them

      return 0 < intersect(slide.contentFilter, passValues)
    }

    an And term looks like:

    Or( filter, slide, selectedFilterValues ) {
      return intersect(slide.contentFilter, filter.children)
    }
    
 */
const makeOrTerm = (filter, selectedFilterValues) => {
  //console.debug('makeOrTerm: ', {filter,selectedFilterValues});
  const OrTerm = slide => {
    //console.debug('OrTerm: ', {children:filter.children});
    let filterValues = filter.children.map(c => c._id);
    //console.debug('OrTerm: ', {filterValues});
    let passValues = intersect(filterValues, selectedFilterValues);
    //console.debug('OrTerm: ', {passValues});

    if (0 === passValues.length)
      // if none of this filters values are selected then
      // we don't even need to check the slide
      return false;

    // there are some of this filter's values selected
    // check if this slide has any of them

    return 0 < intersect(slide.contentFilter, passValues).length;
  };
  return OrTerm;
};

const makeAndTerm = (filter, selectedFilterValues) => {
  //console.debug('makeAndTerm: ', {filter,selectedFilterValues});
  const AndTerm = slide => {
    let filterValues = filter.children.map(c => c._id);
    let passValues = intersect(filterValues, selectedFilterValues);
    //console.debug('AndTerm: ', {passValues});

    if (0 === passValues.length)
      // if none of this filters values are selected then
      // we don't even need to check the slide
      return false;

    // there are some of this filter's values selected
    // check if this slide has all of them

    return (
      passValues.length === intersect(slide.contentFilter, passValues).length
    );
  };
  return AndTerm;
};

const makeSlideFilter = (filters, selectedFilterValues) => {
  const passFilters = filters.map(f =>
    "or" === f.queryType
      ? makeOrTerm(f, selectedFilterValues)
      : makeAndTerm(f, selectedFilterValues)
  );
  const passFilter = slide => passFilters.some(f => f(slide));
  return passFilter;
};

const getFilteredSlides = (slides, filters, selectedFilterValues) => {
  //console.debug( {filters, selectedFilterValues });
  if (selectedFilterValues.length < 1) {
    return slides;
  }

  // remove the F-* entries
  const _fv = selectedFilterValues.filter(fv => !fv.startsWith("F-"));
  //console.debug( { _fv } );

  const passFilter = makeSlideFilter(filters, _fv);

  return slides.filter(passFilter);
};

// we need a copy of the hierachy with only the filtered results. ViewByTopic displays
// the hierarchy it is given. it's clearly a "better" idea in terms of memory pressure to
// 'mark' the slides as e.g. hidden or not. And, to modify ViewByTopic to not display slides
// when slide.hidden etc.
const updateFilteredSlides = (hierarchy, filteredSlides) => {
  const filteredSlideIds = filteredSlides.map(s => s._id);
  const updateHidden = v => {
    if ("slide" === v.label) {
      v.hidden = filteredSlideIds.indexOf(v._id) === -1;
      //console.debug("updateFilteredSlides: ", v._id, v.hidden, filteredSlideIds.length > 0 ? filteredSlideIds[0] : "[]")
    }
  };
  hierarchy && hierarchy.forEach(t => {
    dfs(t, updateHidden);
  });
};

// see todo.md for notes about why this reducer is here (for now), and the plan
// to move it. In the interests getting integration testing underway, we're going to
// land this here and move it a future change.
const reducerPM = (state = initialState, action) => {
  switch (action.type) {
    case "PM_SET_SELECTED_CONTENT":
      let selectedContentRepoId = action.payload._id;
      return {
        ...state,
        selectedContentRepoId
      };
    case "SUCCESS_LIBRARY_FILTERS":
      //console.debug(`reducerPM: ${action.type}`, {payload:action.payload});
      let filters = action.payload.libraryFiltersList;
      return {
        ...state,
        filters
      };
    case "SUCCESS_LIBRARY_TOPIC":
      // payload.libraryByTopicList is the hierarchy returned by GET /content-slides
      let contentRepoId = state.selectedContentRepoId;
      let repoFilters = state.filters;
      let hierarchy = action.payload.libraryByTopicList;
      let slides = getSlides(hierarchy);
      let selectedFilters = state.selectedFilters;
      let filteredSlides = getFilteredSlides(
        slides,
        repoFilters,
        Array.isArray(selectedFilters) ? selectedFilters : []
      );
      updateFilteredSlides(hierarchy, filteredSlides);
      //console.debug('reducerPM: selectedFilters=',selectedFilters);
      return {
        ...state,
        contentReposById: {
          [contentRepoId]: {
            hierarchy,
            slides
          }
        },
        slides,
        filteredSlides
      };
    case "ON_SELECTED_FILTERS_CHANGE":
      contentRepoId = state.selectedContentRepoId;
      const { payload } = action;
      selectedFilters = payload;
      repoFilters = state.filters;
      slides = state.slides;
      //console.debug("reducerPM: ON_SELECTED_FILTERS_CHANGE ", payload);
      filteredSlides = getFilteredSlides(
        slides,
        repoFilters,
        Array.isArray(selectedFilters) ? selectedFilters : []
      );
      // During initialState value, this sets to undefined giving error!
      if (size(state.contentReposById)) {
        hierarchy = state.contentReposById[contentRepoId].hierarchy;
      }
      updateFilteredSlides(hierarchy, filteredSlides);
      return {
        ...state,
        selectedFilters: payload,
        filteredSlides
      };
      break;
    case SEARCH_LIBRARY_SLIDES: {
      // This will return the Slides that are Search through the Library PM
      const searchSlides = action.payload.slides;
      return {
        ...state,
        searchResultsSlides: searchSlides
      };
    }
    default:
  }
  return state;
};

let _r = createReducersFromTypes(types);
_r["pm"] = reducerPM;

export default _r;
