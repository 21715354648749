// libraries
import React from "react";
import styled from "styled-components";

// components
import UploadDropZone from "components/chooseFile";

const UploadFiles = props => {
  const notice = `NOTE: File format with .ttf, .otf and .woff are allowed.`;
  return (
    <ChooseFileContainer>
      {
        <span>
          <UploadDropZone uploadFileType={"otf"} {...props} />
          <Note {...props}>{notice}</Note>
        </span>
      }
    </ChooseFileContainer>
  );
};

const ChooseFileContainer = styled.div`
  width: 23%;

  .btn-container {
    div:first-child {
      margin: 0 0 10px;
    }
  }
`;

const Note = styled.div`
  min-height: 36px;
  margin-top: 11px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  opacity: 0.64;
  text-align: left;
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

export default UploadFiles;
