import React from "react";
import styled from "styled-components";
import { get } from "lodash";

const ThemeFooter = props => {
  let {
    activeThemeDetails,
    currentActiveIndex,
    continueHandler,
    availableSlides,
    hideContinue,
    isEdited,
    navigationHandler
  } = props;

  return (
    <FooterContainer>
      <SlideCta>
        <ThemeType>{`Type: ${
          activeThemeDetails.isMaster ? "Master" : "SubMaster"
        }`}</ThemeType>
        <ThemeTypeName>
          Name: {get(availableSlides[currentActiveIndex], `title`)}
        </ThemeTypeName>
      </SlideCta>
      <SlideCta>
        {isEdited ||
        (activeThemeDetails.ingestId && activeThemeDetails.subMasterDetails) ? (
          <ContinueButton
            onClick={e => continueHandler()}
            hideContinue={hideContinue}
          >
            Save &amp; Continue
          </ContinueButton>
        ) : (
          <>
            <IconWrapper
              title="Previous Slide"
              disabled={currentActiveIndex === 0}
              onClick={() => navigationHandler(true, false)}
            >
              Previous
            </IconWrapper>
            <IconWrapper
              title="Next Slide"
              disabled={currentActiveIndex === availableSlides.length - 1}
              onClick={() => navigationHandler(false, true)}
            >
              Next
            </IconWrapper>
          </>
        )}
      </SlideCta>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px 12px 28px;
  background-color: ${props => props.theme.COLOR.MAIN};

  span {
    color: ${props => props.theme.COLOR.WHITE};
    font-size: 14px;
    line-height: 18px;
    font-family: ${props => `${props.theme.FONT.REG}`};
  }
`;

const ThemeType = styled.span`
  font-weight: bold;
`;

const ThemeTypeName = styled.span`
  margin-left: 36px;
  font-weight: bold;
`;

const SlideCta = styled.div`
  vertical-align: top;
`;

const ContinueButton = styled.button`
  padding: 7px 12px;
  display: inline-block;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  background-color: ${props => props.theme.COLOR.WHITE};
  border: none;
  border-radius: 5px;
  outline: none;
  transition: all ease 0.4s;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.COLOR.USER_PRIMARY};
    background: ${props => props.theme.COLOR.WHITE};
  }
  ${props => props.hideContinue && "pointer-events : none"};
  ${props => props.hideContinue && "opacity : 0.5"};
`;

const IconWrapper = styled.button`
  padding: 7px 12px;
  &:first-child {
    margin-right: 5px;
  }
  border: 1px solid ${props => props.theme.COLOR_PALLETE.GREY};
  border-radius: 5px;
  font-family: ${props => props.theme.FONT.REG};
  font-size: 16px;
  font-weight: bold;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 0.9)};
  background: ${props => props.theme.COLOR.WHITE};
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  &:hover {
    background: ${props => !props.disabled && props.theme.COLOR_PALLETE.GREY};
    color: ${props => !props.disabled && props.theme.COLOR.WHITE};
    border: 1px solid ${props => props.theme.COLOR.WHITE};
  }
`;

export default ThemeFooter;
