//Lib
import React from "react";
import styled from "styled-components";

//Components
import Container from "./container";
import StepDetails from "components/buildProcess/stepDetails";
import { BuildOption } from "./components/BuildOption";
import PresentationShare from "components/presentationShareForm";
import ProgressPopup from "components/buildProcess/progressPopup";
import { buildMarketingDoucment } from "assets/images";
import { get, filter } from "lodash";

const Build = props => {
  let {
    buildProgress,
    handleDownload,
    buildValue,
    onEdit,
    presentationData,
    checkIfDownloadAvailable,
    isBuilding,
    options,
    getDocumentName,
    checkIfShareAvailable
  } = props;

  const UI_STRINGS = {
    DOWNLOADABLE_BUILD_COMPLETE: `Your ${getDocumentName(
      buildValue
    )} has been built successfully.`,
    SHAREABLE_BUILD_COMPLETE: `Your ${getDocumentName(
      buildValue
    )} has been built. Please check your email for a download link.`,
    BUILD_INCOMPLETE: `Choose the format below and click Build to export.`,
    BUILD_INPROGRESS: `Building your ${getDocumentName(buildValue)}`
  };

  // Check if build is complete
  let isDownloadable = checkIfDownloadAvailable(buildValue);
  let isOnlyShareable = checkIfShareAvailable(presentationData, buildValue);

  let isBuildProgress = isBuilding && (!isDownloadable || !isOnlyShareable);

  let sharePPTactive = isDownloadable || isOnlyShareable;

  let shareOptions = filter(options, option => {
    return Object.keys(option).length;
  });
  // Presentation Build Options
  let option = shareOptions.map(build => {
    return (
      <BuildOption
        labelValue={build.label}
        idValue={build.id}
        selected={buildValue === build.id}
        key={build.id}
        changedValue={props.changeHandler}
      />
    );
  });

  return (
    <BuildWrapper>
      <StepDetails
        title="Build"
        description={
          isDownloadable
            ? UI_STRINGS.DOWNLOADABLE_BUILD_COMPLETE
            : isOnlyShareable
            ? UI_STRINGS.SHAREABLE_BUILD_COMPLETE
            : isBuildProgress
            ? UI_STRINGS.BUILD_INPROGRESS
            : UI_STRINGS.BUILD_INCOMPLETE
        }
        _share={sharePPTactive}
        _edit="Edit"
        onEdit={onEdit}
        _download={isDownloadable}
        onDownload={handleDownload}
        _build={!isDownloadable && !isOnlyShareable}
        onShare={props.overlayHandler}
        onBuild={props.buildHandler}
      />
      <MainContainer>
        {!isBuildProgress && option}

        {props.showShare && (
          <PresentationShare
            submitText="Send"
            showcheckbox={true}
            checkboxLabel="Send copy to myself"
            shareDescription="Send a copy to another user via email"
            {...props}
            selectedPresentation={presentationData}
          />
        )}
      </MainContainer>
      <ProgressPopup
        show={isBuildProgress}
        gifSrc={buildMarketingDoucment}
        width={get(buildProgress, "percentage")}
        status={get(buildProgress, "status")}
        message={UI_STRINGS.BUILD_INPROGRESS}
      />
    </BuildWrapper>
  );
};

const BuildWrapper = styled.div``;

const MainContainer = styled.div`
  margin-top: 40px;

  .form-group {
    margin-bottom: 10px;
    color: ${props => props.theme.COLOR.HEADING};

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-overlay {
    top: calc(50% + 53px);
  }
`;

export default Container(Build);
