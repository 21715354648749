
/* DFS(t,v, fn) - depth-first traversal of a tree t beginning at node (vertex) v.
 * call fn() on each vertex e.g. to debug print its identity
 * we assume the graph is represented by a children[] member in each node.
 * leaf nodes may have a zero-length children[] array, or may not have a .children attribute
 * at all. note that show the tree as a parameter because it's conventional to represent a
 * tree T = (V,E). we don't need that here because our edges are represented by the .children[]
 * array in each node.
 * 
 * DFS(t,v,fn) = {
 *   if( v.children && Array.isArray(v.children) && v.children.length > 0) {
 *     v.children.forEach( cv => {
 *       DFS( t, v );
 *     });
 *   }
 * }
 */

/* here we dispense with the tree t parameter
 */
const dfs = (v, fn) => {
  // first call fn() on v 
  fn( v );
  if( (v.children !== undefined) && Array.isArray(v.children) && v.children.length > 0) {
    v.children.forEach( cv => {
      if( cv == undefined ) {
        // somehow, there IS an undefined entry in the .children[] list in some case
        // todo(jim) - get to the bottom of it
        console.error( 'PANIC: dfs() visiting undefined node in v.children' );
        console.error( 'children: ' , v.children );
        process.exit(1);
      } else {
        // only traverse into actual nodes!
        dfs( cv, fn );
      }
    });
  }
}

const assertWellFormedChildren = (v) => {
  if( v.children == undefined )
    return;

  if( v.children.some( _c => _c == undefined ) ) {
    console.log( 'assertWellFormed: v has at least one undefined .children[]');
    console.log( v._id, v.label, v.title );
  }

  if( v.children.some( _c => _c == null ) ) {
    console.log( 'assertWellFormed: v has at least one undefined .children[]');
    console.log( v._id, v.label, v.title );
  }


}

const assertWellFormedTree = (t) => {
  dfs( t, assertWellFormedChildren );
}

module.exports = {
  dfs,
  assertWellFormedTree
}