//import network services
import * as services from "./components/announcement/services";
import * as lastLoggedInToolServices from "./services";

const mapStateToProps = state => {
  const {
    SUCCESS_USER_PROFILE,
    SUCCESS_ANNOUNCEMENT,
    LOADING_ANNOUNCEMENT,
    LAST_LOGGED_IN_TOOL,
    MENU_STATE,
    TOOLS_LIST,
  } = state;
  return {
    ...SUCCESS_USER_PROFILE,
    ...SUCCESS_ANNOUNCEMENT,
    ...LOADING_ANNOUNCEMENT,
    ...LAST_LOGGED_IN_TOOL,
    ...MENU_STATE,
    ...TOOLS_LIST,
  };
};

const actions = {
  ...services,
  ...lastLoggedInToolServices
};

export { mapStateToProps, actions };
