import { DashboardTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

const REQUEST_DATA = (payload = {}, type = DashboardTypes.REQUEST_DATA) => {
  return {
    type,
    payload
  };
};

const uploadFile = (
  userId,
  postData,
  documentId,
  blockId
) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}/blocks/${blockId}/files`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, postData, "post user data");

  if (!response.success || !response.data) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const uploadFileTemplate = (
  postData,
  templateId,
  blockId
) => async dispatch => {
  const URL = `/summary/blocks/${blockId}/files?templateId=${templateId}`;

  // start loading
  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: true
    })
  );

  const response = await FetchUtils.postData(URL, postData, "post user data");

  if (!response.success || !response.data) {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const uploadFileToAws = (presignedUrl, file) => async dispatch => {
  const headers = {
    "Content-Type": file.type
  };

  const response = await FetchUtils.awsPutData(
    presignedUrl,
    file,
    headers,
    "Upload file to aws"
  );

  dispatch(
    REQUEST_DATA({
      isTemplateListLoading: false
    })
  );

  if (response.success) {
    ToastUtils.handleToast({
      operation: "success",
      message: "File uploaded successfully."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

export { uploadFile, uploadFileToAws, uploadFileTemplate };
