//libraries
import React from "react";
import styled from "styled-components";
import { get } from "lodash";

// components
import DraggableSort from "components/draggableSort";
import { ThumbNail, List, MoveDouble, Delete } from "assets/icons";
import hexToRgba from "utils/hexToRgba";
import EditableInput from "components/editableInput/container";
import Image from "./../thumbnailImage";

const UploadedFiles = props => {
  let {
    onListUpdate,
    pdfFiles,
    onChangeLayoutType,
    isLayoutTypeThumbnail,
    saveInputValueHandler,
    setFileNameEditedFlag,
    isAnyFileNameEdited,
    activeFileId,
    deleteFileHandler,
    deleteFile,
    getImageHandler
  } = props;

  return (
    <UploadedContents>
      <LayoutTypeSelector>
        <IconWrapper
          onClick={() => onChangeLayoutType(true)}
          active={isLayoutTypeThumbnail}
        >
          <ThumbNail />
        </IconWrapper>
        <IconWrapper
          active={!isLayoutTypeThumbnail}
          onClick={() => onChangeLayoutType(false)}
        >
          <List />
        </IconWrapper>
      </LayoutTypeSelector>
      {!get(pdfFiles, `length`) && (
        <NoDataText>No appendix files found.</NoDataText>
      )}
      <DraggableSort
        onListUpdate={onListUpdate}
        axis="x"
        style={
          isLayoutTypeThumbnail
            ? {
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                minHeight: "30px"
              }
            : {
                width: "100%",
                display: "block",
                marginTop: "30px"
              }
        }
        listData={pdfFiles}
        handlesGroup={false}
        allowExternalDrop={false}
        renderChild={(data, index) =>
          isLayoutTypeThumbnail ? (
            <PdfFileContainer key={get(data, `_id`)}>
              <ThumbnailWrapper
                onClick={e => deleteFileHandler(get(data, `_id`))}
              >
                {activeFileId === get(data, `_id`) && (
                  <DeleteWrapper title="Delete">
                    <Delete
                      className="delete-logo"
                      onClick={() => deleteFile(get(data, `_id`))}
                    />
                  </DeleteWrapper>
                )}
                <ThumbnailDragHover>
                  <Image
                    data={data}
                    getImageHandler={getImageHandler}
                    imageIndex={index}
                    {...props}
                  />

                  <FileName>
                    <EditableInput
                      defaultValue={get(data, `name`)}
                      title={get(data, `name`)}
                      saveInputValueHandler={value =>
                        saveInputValueHandler(value, get(data, `_id`))
                      }
                      isAnyValueEdited={isAnyFileNameEdited}
                      checkIfFieldEdited={setFileNameEditedFlag}
                    />
                  </FileName>
                </ThumbnailDragHover>
              </ThumbnailWrapper>
            </PdfFileContainer>
          ) : (
            <ListTypePdfFileContainer key={index}>
              <Row
                key={get(data, `_id`)}
                onClick={e => deleteFileHandler(get(data, `_id`))}
              >
                <DragHover>
                  <BorderWrapper>
                    <DragIcon />
                    <FileName>
                      <EditableInput
                        defaultValue={get(data, `name`)}
                        inputFieldClassName="input-field"
                        title={get(data, `name`)}
                        saveInputValueHandler={value =>
                          saveInputValueHandler(value, get(data, `_id`))
                        }
                        isAnyValueEdited={isAnyFileNameEdited}
                        checkIfFieldEdited={setFileNameEditedFlag}
                      />
                    </FileName>
                    <ListDeleteWrapper title="Delete">
                      <Delete
                        onClick={() => deleteFile(get(data, `_id`))}
                        className="delete-logo"
                      />
                    </ListDeleteWrapper>
                  </BorderWrapper>
                </DragHover>
              </Row>
            </ListTypePdfFileContainer>
          )
        }
      />
    </UploadedContents>
  );
};

const NoDataText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.7;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
`;

const ThumbnailDragHover = styled.div`
  height: 100%;
`;

const ListDeleteWrapper = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 17px;
  cursor: pointer;
  top: 4px;
  svg {
    width: 13px;
    height: 18px;
  }
`;

const DeleteWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.theme.COLOR.WHITE};
  border-radius: 50%;
  box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  right: -8px;
  cursor: pointer;
  top: -6px;
  svg {
    width: 10.2px;
    height: 12px;
    position: relative;
    top: 2px;
    left: 5px;
  }
`;

const Row = styled.div`
  width: 90%;
  position: relative;
  padding: 8px 0 9px 35px;
  margin-left: 1%;
  background-color: ${props =>
    hexToRgba(props.theme.COLOR_PALLETE.COOL_BLUE, 0.09)};
  border-radius: 2px;
  position: relative;
  cursor: move;
`;

const BorderWrapper = styled.div`
  position: relative;
`;

const DragHover = styled.div`
  width: 100%;
`;

const DragIcon = styled(MoveDouble)`
  display: block;
  position: absolute;
  left: -21px;
  top: 9px;
  height: 14px;
  width: 9px;
`;

const LayoutTypeSelector = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const ListTypePdfFileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 0 10px;
  border: 1px solid transparent;
  margin-bottom: 16px;
  &.drop-before {
    ${DragHover} {
      ${props =>
        `&:before {
          content: " ";
          position: absolute;
          top: -7px;
          right: 0;
          left : 0;
          border: 1px solid ${hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.74)};
      }`}
    }
  }

  &.drop-after {
    ${DragHover} {
      ${props =>
        `&:before {
        content: " ";
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        border: 1px solid ${hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.74)};
      }`}
    }
  }

  &.selected {
    ${Row} {
      background-color: ${props =>
        hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.3)};
      ${props => props.theme.SNIPPETS.BOX_SHADOW};
    }
  }

  .edit-icon {
    right: 38px;
  }

  .input-field {
    padding: 3px 70px 0 0px;
  }

  .save-reset-icon {
    top: 10px;
    right: 35px;
  }

  .error-message-input {
    display: block;
    bottom: -26px;
  }
`;

const IconWrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;
  svg {
    vertical-align: middle;

    g {
      fill-opacity: ${props => (props.active ? "1" : "0.5")};
      fill: ${props => props.theme.COLOR.BROWNISH_GREY};
    }
  }

  :hover {
    g {
      fill-opacity: 1;
      fill: ${props => props.theme.COLOR.BROWNISH_GREY};
      transition: 0.2s ease-in-out;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  height: 100%;
  position: relative;
`;

const PdfFileContainer = styled.div`
  width: calc(100% / 5);
  margin-right: 4%;
  margin-top: 30px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  cursor: pointer;
  margin-bottom: 10px;
  background-color: ${props => props.theme.COLOR.WHITE};

  .error-message-input {
    display: block;
    top: 24px;
  }

  &.drop-before {
    ${ThumbnailDragHover} {
      position: relative;

      ${props =>
        `&:before {
          content: " ";
          position: absolute;
          top: 0;
          bottom:0;
          right: unset;
          left:-5%;
          border: 1px solid ${hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.74)};
      }`}
    }
  }

  &.drop-after {
    ${ThumbnailDragHover} {
      position: relative;

      ${props =>
        `&:before {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -6%;
        left: 'unset';
        border: 1px solid ${hexToRgba(props.theme.COLOR.USER_PRIMARY, 0.74)};
      }`}
    }
  }
`;

const FileName = styled.span`
  display: inline-block;
  width: 100%;
  padding: 4px 5px;
`;

const UploadedContents = styled.div`
  width: 65%;
  ${props => props.theme.SNIPPETS.BOX_SHADOW}
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  align-items: flex-start;
`;

export default UploadedFiles;
