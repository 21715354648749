import React from "react";
import { Route } from "react-router-dom";

// routes
import { Build } from "tools/library/pages";

const Library = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        path="/library/build"
        render={props => checkAuthSession(<Build auth={auth} {...props} />)}
      />
    </>
  );

  return routes;
};

export default Library;
