import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import hexToRgba from "utils/hexToRgba";
import { Close } from "assets/icons";
import { useDrop } from "react-dnd";
import { get } from "lodash";

const getColor = props => {
  if (props.isSelected || props.isOver) {
    return hexToRgba(props.theme.COLOR.USER_PRIMARY, "0.7");
  } else {
    return props.theme.COLOR_PALLETE.LIGHT_GREY;
  }
};

/**
 * This is a simple card placeholder which represents the placeholder section on the right.
 * 0- for None
 * 1- for Single
 * 2- for Stack
 * 4- for Quad
 **/
const Placeholder = forwardRef((props, ref) => {
  let {
    setSelectedThumbNail,
    activeLayout,
    currentIndex,
    selectedThumbnail,
    removeSelectedDocument,
    placeholderName,
    placeholderDimensions,
    type,
    pageLayoutIndex,
    activePlaceholderDetails,
    modules
  } = props;

  let [currentDroppedItem, setDroppedItem] = useState(selectedThumbnail);

  const [item, drop] = useDrop({
    accept: type,
    drop: (droppedItem, monitor, component) => {
      setDroppedItem(droppedItem);
      setSelectedThumbNail(
        get(activeLayout, `_id`),
        placeholderName,
        currentIndex,
        droppedItem,
        pageLayoutIndex
      );
    },
    collect: (monitor, props) => {
      return {
        isOver: monitor.isOver()
      };
    },
    canDrop: (item, monitor) => {
      // allow drop if nothing is selected, item type matches and none is not selected
      return (
        !!!selectedThumbnail &&
        item.type === placeholderDimensions.type &&
        !!activePlaceholderDetails[get(activeLayout, `_id`)]
      );
    }
  });

  return (
    <BorderWrapper
      width={placeholderDimensions.width}
      isEmpty={!selectedThumbnail}
      isOver={item.isOver}
      ref={drop}
      isLayoutSelected={get(modules, `length`)}
    >
      {get(selectedThumbnail, `url`) ? (
        <>
          <Thumbnail src={get(selectedThumbnail, `url`)} />
          <CloseIconWrapper>
            <CloseIcon
              onClick={event => {
                removeSelectedDocument(
                  get(activeLayout, `_id`),
                  placeholderName,
                  currentIndex,
                  currentDroppedItem,
                  pageLayoutIndex
                );
              }}
            />
          </CloseIconWrapper>
        </>
      ) : get(modules, `length`) ? (
        <Thumbnail src={placeholderDimensions.placeHolder} />
      ) : (
        <NoneBackground></NoneBackground>
      )}
    </BorderWrapper>
  );
});

Placeholder.defaultProps = {
  selectedThumbnail: null,
  removeSelectedDocument: () => {}
};

const NoneBackground = styled.div`
  width: 20%;
  height: 250px;
  background: white;
`;

const BorderWrapper = styled.div`
  margin: 0 auto;
  border-radius: 4px;
  border: ${props =>
    props.isLayoutSelected
      ? `2px dashed ${getColor(props)}`
      : "2px solid transparent"};
  background-color: ${props =>
    props.isOver ? props.theme.COLOR.CONTAINER : props.theme.COLOR.WHITE};
  outline: none;
  transition: border 0.24s ease-in-out;
  position: relative;
  margin-bottom: 25px;
  width: ${props => props.width};
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-width: 100%;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${props => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

export default Placeholder;
