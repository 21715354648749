import { DocumentBuildTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

const RECEIVE_BUILD_DOCUMENT_DATA = (
  payload = {},
  type = DocumentBuildTypes.RECEIVE_BUILD_DOCUMENT_DATA
) => {
  return {
    type,
    payload
  };
};

const REQUEST_BUILD_DOCUMENT_DATA = (
  payload = null,
  type = DocumentBuildTypes.REQUEST_BUILD_DOCUMENT_DATA
) => {
  return {
    type,
    payload
  };
};

const REQUEST_GET_COVER_DATA = (
  payload = null,
  type = DocumentBuildTypes.REQUEST_GET_COVER_DATA
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_COVER_DATA = (
  payload = {},
  type = DocumentBuildTypes.RECEIVE_COVER_DATA
) => {
  return {
    type,
    payload
  };
};

const setCurrentDocumentData = currentDocumentData => dispatch => {
  dispatch(
    RECEIVE_BUILD_DOCUMENT_DATA({
      currentDocumentData
    })
  );
};

const getDocumentData = (userId, documentId) => async dispatch => {
  const URL = `/summary/users/${userId}/documents/${documentId}`;

  // start loading
  dispatch(
    REQUEST_BUILD_DOCUMENT_DATA({
      isCurrentDocumentLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_BUILD_DOCUMENT_DATA({
      isCurrentDocumentLoading: false
    })
  );
  if (response.success && response.data) {
    dispatch(
      RECEIVE_BUILD_DOCUMENT_DATA({
        currentDocumentData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const getCoverData = (blockId, templateId, templateType=null) => async dispatch => {
  const URL = `/summary/blocks/${blockId}/content?templateId=${templateId}`;

  // start loading
  dispatch(
    REQUEST_GET_COVER_DATA({
      isCurrentDocumentLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_GET_COVER_DATA({
      isCurrentDocumentLoading: false
    })
  );

  if (response.success && response.data) {
    if(templateType == 'pptTemplate'){
      dispatch(
        RECEIVE_COVER_DATA({
          coverBlockData: response.data
        })
      );
    }
    return response.data
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const createDocument = (
  userId,
  postData,
  documentId = null
) => async dispatch => {
  const URL = documentId
    ? `/summary/users/${userId}/documents/${documentId}`
    : `/summary/users/${userId}/documents`;

  // start loading
  dispatch(
    REQUEST_BUILD_DOCUMENT_DATA({
      isCurrentDocumentLoading: true
    })
  );

  const response = documentId
    ? await FetchUtils.patchData(URL, postData, "patch document data")
    : await FetchUtils.postData(URL, postData, "post document data");

  // start loading
  dispatch(
    REQUEST_BUILD_DOCUMENT_DATA({
      isCurrentDocumentLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_BUILD_DOCUMENT_DATA({
        currentDocumentData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

export {
  getDocumentData,
  setCurrentDocumentData,
  createDocument,
  getCoverData
};
