import React, { Component } from "react";
import { get, filter, toLower, forEach, size } from "lodash";
import { walk, getFlatDataFromTree } from "react-sortable-tree";
const Container = Main =>
  class ViewTabs extends Component {
    state = {
      previewThumbnailUrl: "",
      singlePreviewSlideData: {},
      popupPosition: "",
      elementScrrenY: 0,
      popupHorizontalPosition: "",
      leftOffeset: 0,
      contentSlidesListId: {},
      contentSlidesListDetails: {}
    };

    viewByScreenRef = React.createRef();

    componentDidMount() {
      const { selectedTabValue, viewByTopicSearch } = this.props;
      if ((viewByTopicSearch[selectedTabValue] || []).length)
        this.createSlideGroup(viewByTopicSearch[selectedTabValue]);
    }

    componentDidUpdate(prevProps) {
      const { selectedTabValue, viewByTopicSearch } = this.props;
      if (
        prevProps.viewByTopicSearch[selectedTabValue] !==
        viewByTopicSearch[selectedTabValue]
      ) {
        if ((viewByTopicSearch[selectedTabValue] || []).length)
          this.createSlideGroup(viewByTopicSearch[selectedTabValue]);
      }
    }

    // make a slide group to add or remove group of slides on category/header click
    createSlideGroup = async viewByTopicList => {
      let { contentSlidesListId, contentSlidesListDetails } = this.state;
      let updatedContentSlidesListId = {},
        updatedContentSlidesListDetails = {};

      // walk through every object of content slide to get slideIds and Its details
      await walk({
        treeData: viewByTopicList,
        getNodeKey: ({ node: treeNode }) => treeNode._id,
        callback: rowInfo => {
          let { node } = rowInfo;
          let { children, _id, label } = node;

          if (label === "category" || label === "header") {
            contentSlidesListId[_id] = contentSlidesListId[_id]
              ? contentSlidesListId[_id]
              : [];

            contentSlidesListDetails[_id] = contentSlidesListDetails[_id]
              ? contentSlidesListDetails[_id]
              : [];

            updatedContentSlidesListId[_id] = [];
            updatedContentSlidesListDetails[_id] = [];

            let flattened = getFlatDataFromTree({
              treeData: children,
              getNodeKey: ({ node }) => node._id,
              ignoreCollapsed: false
            });

            forEach(flattened, elem => {
              let { node } = elem;

              let { label: slideLabel, _id: slideId, isRequired } = node;
              if (
                slideLabel === "slide" &&
                contentSlidesListId[_id].indexOf(slideId) < 0 &&
                !isRequired
              ) {
                contentSlidesListId[_id].push(slideId);
                contentSlidesListDetails[_id].push(node);
              }

              if (
                slideLabel === "slide" &&
                contentSlidesListId[_id].indexOf(slideId) !== -1 &&
                !isRequired
              ) {
                updatedContentSlidesListId[_id].push(slideId);
                updatedContentSlidesListDetails[_id].push(node);
              }
            });
          }
        },
        ignoreCollapsed: false
      });

      await this.setState({
        contentSlidesListId:
          size(updatedContentSlidesListId) > 0
            ? updatedContentSlidesListId
            : contentSlidesListId,
        contentSlidesListDetails:
          size(updatedContentSlidesListDetails) > 0
            ? updatedContentSlidesListDetails
            : contentSlidesListDetails
      });
    };

    /**
     * Add or remove group of slides on category/header click
     *
     * @param {string} _id header/category id
     * @param {boolean} flag flag to add remove slide
     */
    handleSelectAllSlides = async (_id, flag) => {
      const { selectedTabValue, viewByTopicSearch } = this.props;
      await this.createSlideGroup(viewByTopicSearch[selectedTabValue]);
      let { contentSlidesListId, contentSlidesListDetails } = this.state;
      if (flag) {
        this.props.handleSelectedSlides &&
          this.props.handleSelectedSlides(
            contentSlidesListId[_id],
            false,
            "",
            contentSlidesListDetails[_id]
          );
      } else {
        this.props.handleSelectedSlides &&
          this.props.handleSelectedSlides(
            contentSlidesListId[_id],
            true,
            "",
            contentSlidesListDetails[_id]
          );
      }
    };

    handleMouseOver = (e, slideDetail) => {
      let { selectedThemeLayout } = this.props;
      let { elementScrrenY, leftOffeset } = this.state;
      let thumbnailLocation =
        this.getThumbnailBasedOnSlideType &&
        this.getThumbnailBasedOnSlideType(
          selectedThemeLayout,
          slideDetail,
          "thumbnail"
        );

      elementScrrenY = e.clientY;
      leftOffeset = e.target.getBoundingClientRect().left;

      this.setState({
        previewThumbnailUrl: thumbnailLocation,
        singlePreviewSlideData: slideDetail,
        elementScrrenY,
        leftOffeset
      });
    };

    handleMouseLeave = () => {
      this.setState({
        previewThumbnailUrl: "",
        singlePreviewSlideData: {},
        popupPosition: "",
        elementScrrenY: 0,
        popupHorizontalPosition: "",
        leftOffeset: 0
      });
    };

    imageOnload = e => {
      let {
        elementScrrenY,
        popupPosition,
        popupHorizontalPosition,
        leftOffeset
      } = this.state;

      popupPosition = "bottom";
      popupHorizontalPosition = "left";

      if (elementScrrenY + e.target.offsetHeight + 30 > window.innerHeight)
        popupPosition = "top";

      if (
        leftOffeset -
          this.viewByScreenRef.current.offsetLeft +
          e.target.offsetWidth >
        this.viewByScreenRef.current.offsetWidth
      )
        popupHorizontalPosition = "right";

      this.setState({
        popupPosition,
        popupHorizontalPosition
      });
    };

    /**
     * Get themeList associated to slected slide based on its type
     *
     * @param {String} themeId selected theme id
     * @param {Object} slide selected slide
     * @returns thumbnail/file url
     */
    getThumbnailBasedOnSlideType = (themeId, slide, type) => {
      let slideType = toLower(get(slide, "slideLayoutType"));
      switch (slideType) {
        case "content":
          return this.getThemeBasedUrl(
            themeId,
            get(slide, "slideListByThemes"),
            type
          );

        case "divider":
          return this.getThemeBasedUrl(
            themeId,
            get(slide, "slideListByDividers"),
            type
          );

        case "blank":
          return this.getThemeBasedUrl(
            themeId,
            get(slide, "slideListByBlanks"),
            type
          );

        case "cover":
          return this.getThemeBasedUrl(
            themeId,
            get(slide, "slideListByCovers"),
            type
          );

        default:
          return null;
      }
    };

    /**
     *Get thumbnail/file location based on the selected theme
     *
     * @param {*} type thumbnail/ file
     * @param {*} themeId Currently selected theme ID
     * @param {*} themedSlides Slides based on available themes
     */
    getThemeBasedUrl = (themeId, themedSlides, type = "thumbnail") => {
      let selectedThemeSlide = filter(themedSlides, slide => {
        // Check if the themeId matches selected theme and return the slide
        return get(slide, "themeId") === themeId;
      });
      if (selectedThemeSlide.length && type === "thumbnail") {
        return get(selectedThemeSlide[0], "thumbnailLocation.url");
      } else if (selectedThemeSlide.length && type === "file") {
        return get(selectedThemeSlide[0], "fileLocation.url");
      }
      return null;
    };

    render() {
      const $this = this;
      /** Merge States and Methods */
      const stateMethodProps = {
        ...$this,
        ...$this.state,
        ...$this.props
      };
      return <Main {...stateMethodProps} />;
    }
  };

export default Container;
