import React, { useEffect, useState } from "react";
import { get } from "lodash";

import DatableBlock from "./index";

export default function DataTable(props) {
  const {
    getBlockFunctions,
    blockFunctions,
    updateTemplateBlockHandler,
    isActiveOnClickTemplateBlocks,
    handleStateChange
  } = props;

  const {
    etlFunction,
    landscape,
    ingestFunction
  } = isActiveOnClickTemplateBlocks;

  const [listValue, setListValue] = useState({});

  const [functionDownForm, setFuntionDownForm] = useState([
    {
      label: "Ingest Function:",
      _id: "0",
      values: [],
      name: "ingest"
    },
    {
      label: "ETL Function:",
      _id: "1",
      values: [],
      name: "etl"
    },
    {
      label: "Orientation:",
      _id: "2",
      values: [
        {
          _id: "false",
          label: "Portrait"
        },
        {
          _id: "true",
          label: "Landscape"
        }
      ]
    }
  ]);

  useEffect(() => {
    if (blockFunctions == null) {
      (async () => {
        await getBlockFunctions();
      })();
    }
  }, []);

  useEffect(() => {
    if (blockFunctions != null) {
      setFuntionDownForm(prev =>
        prev.map(form => {
          const values = form.name
            ? blockFunctions.filter(func => func.type == form.name)
            : form.values;

          return {
            ...form,
            values,
            value: listValue[form._id]
          };
        })
      );
    }

    if (blockFunctions != null && !Object.keys(listValue).length) {
      setListValue({
        0: get(
          blockFunctions.find(func => func.title == ingestFunction),
          "_id"
        ),
        1: get(
          blockFunctions.find(func => func.title == etlFunction),
          "_id"
        ),
        2: String(landscape)
      });
    }
  }, [blockFunctions, listValue]);

  const onSavehandler = () => {
    let payload = {};

    let ingestFunctionValue = functionDownForm[0].values.find(
      func => func._id == listValue[0]
    );

    let etlFunctionValue = functionDownForm[1].values.find(
      func => func._id == listValue[1]
    );

    let landscapeValue = listValue[2];

    if (ingestFunctionValue) {
      payload["ingestFunction"] = ingestFunctionValue._id;
    }

    if (etlFunctionValue) {
      payload["etlFunction"] = etlFunctionValue._id;
    }

    if (landscapeValue) {
      payload["landscape"] = landscapeValue == "true";
    }

    updateTemplateBlockHandler(payload);
  };

  const onChangeHandler = (e, id) => {
    handleStateChange({ key: "isBlockEdited", value: true });
    const value = functionDownForm[id].values[e.target.selectedIndex]._id;
    setListValue(prev => ({ ...prev, [id]: value }));
  };

  const blockProps = {
    ...props,
    functionDownForm,
    onSavehandler,
    onChangeHandler
  };

  return <DatableBlock {...blockProps} />;
}
