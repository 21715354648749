// libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { get, find, size, map, each, filter } from "lodash";

// components
import HtmlTemplateUI from "./index";
import ValidationUtils from "utils/ValidationUtils";
import UI_STRINGS from "utils/stringConstants";

//services
import {
  uploadFile,
  uploadFileToAws
} from "tools/summaryMaker/store/actions/uploadFileActions";

const mapStateToProps = state => {
  const {
    domain: {
      SELECTED_TEMPLATE,
      RECEIVE_TEMPLATE_BLOCKS,
      RECEIVE_BUILD_DOCUMENT_DATA
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...SELECTED_TEMPLATE,
    ...RECEIVE_TEMPLATE_BLOCKS,
    ...SUCCESS_USER_PROFILE,
    ...RECEIVE_BUILD_DOCUMENT_DATA
  };
};

const mapDispatchToProps = {
  uploadFile,
  uploadFileToAws
};

class HtmlTemplateUIPage extends Component {
  state = {
    showPreview: false,
    overlayHtmlTemplateUIImage: null,
    coverData: [],
    isHtmlTemplateUIImagePresent: false,
    isCustomerNamePresent: false,
    isReportDatePresent: false,
    fields: [],
    documentHtmlTemplateUIFields: [],
    imageGallery: [],
    inputFieldGallery: []
  };

  coverImageVariable = false;
  customerNameVariable = false;
  reportDateVariable = false;

  async componentDidMount() {
    const activeDocumentData = await this.props.getActiveDocumentData();
    this.props.handleStateChange({
      key: "isDataEdited",
      value: true
    });
    const currentTab = await this.props.getCurrentTabDetails();
    let { currentDocumentData, ratesWorkBookName } = this.props;
    const { _id: blockId, variables: tabVariables } = currentTab;
    
    const htmlBlock = find(currentDocumentData.blocks, i=>i.type=='htmlTemplate');
    const documentVariables = get(htmlBlock, `variables`, null)
    const variables = documentVariables ? documentVariables : tabVariables;
    
    if (!ratesWorkBookName) {
      // get data from redux if not present in container state
      this.props.handleStateChange({
        key: "ratesWorkBookName",
        value: get(
          currentDocumentData,
          `blocks.${get(currentTab, `name`)}.workbookName`
        )
      });
    }

    if (variables) {
      let imageTypeVariables = [];
      let inputTypeVariables = [];

      map(variables, eachVariable => {
        if (eachVariable.type === "image") {
          imageTypeVariables.push(eachVariable);
        } else if (
          eachVariable.type === "string" ||
          eachVariable.type === "date"
        ) {
          inputTypeVariables.push(eachVariable);
        }
      });

      await this.setState({
        imageGallery: imageTypeVariables,
        inputFieldGallery: inputTypeVariables
      });
      size(inputTypeVariables) > 0 && (await this.setDocumentFields());
      size(imageTypeVariables) > 0 &&
        this.fetchHtmlTemplateUIImageData(blockId);
    }
  }

  getCurrentDocumentHtmlTemplateUIData = async () => {
    await this.props.handleStateChange({
      key: "documentHtmlTemplateUIFields",
      value: this.state.fields
    });
  };

  // format fields for the form
  setDocumentFields = async () => {
    let allFields = this.getStaticDocumentFields();
    let fields = map(allFields, eachField => {
      return {
        type:
          get(eachField, `type`) === "string" ? "text" : get(eachField, `type`),
        label: get(eachField, `label`),
        fieldType: get(eachField, `type`) === "string" ? "input" : "textarea",
        value:
          get(eachField, `value.content`) || get(eachField, `value`)
            ? get(eachField, `value`)
            : get(eachField, `defaultValue`),
        _id: get(eachField, `_id`),
        required: get(eachField, `required`)
      };
    });

    await this.setState({
      fields
    });

    this.getCurrentDocumentHtmlTemplateUIData();
  };

  handleInputChange = (value, fieldPosition, type) => {
    const { fields } = this.state;
    const { documentHtmlTemplateUIFields } = this.props;
    documentHtmlTemplateUIFields[fieldPosition].value = value;

    if (
      type !== "date" &&
      documentHtmlTemplateUIFields[fieldPosition]._id !== "description"
    ) {
      documentHtmlTemplateUIFields[fieldPosition].error =
        this.handleValidation(value);
    }

    fields[fieldPosition].value = value;
    fields[fieldPosition].edited = true;
    this.setState({
      fields
    });

    this.props.handleStateChange({
      key: "documentHtmlTemplateUIFields",
      value: documentHtmlTemplateUIFields
    });

    this.props.handleStateChange({
      key: "isDataEdited",
      value: true
    });
  };

  /**
   *handle validation for form fields
   * @returns {String} appropriate error message
   */
  handleValidation = value => {
    if (ValidationUtils.checkIfWhiteSpace(value)) {
      return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
    }
    return null;
  };

  setHtmlTemplateUIImageDetails = () => {
    const {
      currentDocumentData: {
        blocks: { cover }
      },
      htmlTemplateImage
    } = this.props;

    let isHtmlTemplateUIImageContentPresent = false;

    map(cover && cover.variables, eachVariable => {
      if (eachVariable.type === "image" && eachVariable.content) {
        isHtmlTemplateUIImageContentPresent = eachVariable.content;
      }
    });
    if (
      isHtmlTemplateUIImageContentPresent &&
      htmlTemplateImage &&
      size(htmlTemplateImage) > 0
    ) {
      map(htmlTemplateImage, eachImage => {
        if (eachImage.url === isHtmlTemplateUIImageContentPresent) {
          this.props.handleStateChange({
            key: "selectedHtmlLayout",
            value: eachImage._id
          });

          this.props.handleStateChange({
            key: "htmlTemplateDetails",
            value: eachImage
          });

          this.props.handleStateChange({
            key: "isDataEdited",
            value: true
          });
        }
      });
    } else if (htmlTemplateImage && size(htmlTemplateImage) > 0) {
      this.props.handleStateChange({
        key: "selectedHtmlLayout",
        value: htmlTemplateImage[0]._id
      });

      this.props.handleStateChange({
        key: "htmlTemplateDetails",
        value: htmlTemplateImage[0]
      });

      this.props.handleStateChange({
        key: "isDataEdited",
        value: true
      });
    }
  };

  /**
   *  gives all the fields for the document along with name and description
   *
   * @memberof DocumentPage
   * @returns {Array} consists of all the dynamic fields along with static ones
   */
  getStaticDocumentFields = () => {
    let { inputFieldGallery } = this.state;

    // these fields will always be displayed
    let staticDocumentFields = [];

    if (size(inputFieldGallery) > 0) {
      map(inputFieldGallery, eachInputField => {
        eachInputField.type !== "date"
          ? staticDocumentFields.push({
              _id: eachInputField._id,
              label: eachInputField.label,
              type: eachInputField.type,
              defaultValue: eachInputField.defaultValue,
              required: eachInputField.required,
              value: eachInputField.content ? eachInputField.content : ""
            })
          : staticDocumentFields.push({
              _id: eachInputField._id,
              label: eachInputField.label,
              type: "date",
              required: eachInputField.required,
              value: eachInputField.content
                ? eachInputField.content
                : new Date()
            });
      });
    }

    let allFields = [...staticDocumentFields];

    // setting default date for all date fields
    each(allFields, eachField => {
      if (get(eachField, `type`) === "date" && !!!get(eachField, `value`)) {
        eachField.value = new Date();
      }
    });

    return allFields;
  };

  fetchHtmlTemplateUIImageData = async blockId => {
    await this.props.handleStateChange({
      key: "htmlImageVariableId",
      value: this.state.imageGallery[0]._id
    });

    const {
      selectedTemplate: { _id: templateId }
    } = this.props;
    if (blockId && templateId) {
      const htmlBlockData = await this.props.getCoverData(blockId, templateId);
      this.props.handleStateChange({
        key: "htmlTemplateImage",
        value: htmlBlockData ? htmlBlockData : []
      });

      htmlBlockData &&
        size(htmlBlockData) > 0 &&
        this.setHtmlTemplateUIImageDetails();
    }
  };

  handleHtmlTemplateUIPreview = (imgSrc = "") => {
    this.setState({
      showPreview: !this.state.showPreview,
      overlayHtmlTemplateUIImage: imgSrc
    });
  };

  uploadFileToRepo = async ({ metaDeta, file }, cb) => {
    const templateBlocks = get(this.props, `selectedTemplate.blocks`) || [];
    const userId = get(this.props, `userProfileMeta._id`);
    const documentId = get(this.props, `currentDocumentData._id`);
    const contentBlockId = (
      find(templateBlocks, eachBlock => {
        return eachBlock.type === "datatable";
      }) || {}
    )._id;

    delete metaDeta.resource;
    delete metaDeta.title;

    let postData = {
      ...metaDeta,
      fileType: "excel",
      name: get(metaDeta, `fileName`)
    };

    let response = await this.props.uploadFile(
      userId,
      postData,
      documentId,
      contentBlockId
    );

    let { presignedUrl = "" } = get(response, `data`);
    await this.props.uploadFileToAws(presignedUrl, file);

    // empty previewPageDetails to again start build
    this.props.handleStateChange({
      key: "previewPageDetails",
      value: []
    });

    cb();

    this.props.handleStateChange({
      key: "ratesWorkBookName",
      value: postData.name
    });
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <HtmlTemplateUI {...stateMethodProps} />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HtmlTemplateUIPage);
