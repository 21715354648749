import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Container from "./container";
import StepDetails from "components/buildProcess/stepDetails";
import { Filters } from "./components/Filters";
import featureFlags from "utils/featureFlags";
import ViewTabs from "components/ViewTabs";
import ReactHintFactory from "react-hint";
import { get } from "lodash";

const ReactHint = ReactHintFactory(React);
const UISTRINGS = {
  VIEW_BY_TOPIC:
    "Add slides (or decks) below by selecting the content. Click on blue hyperlinks to view the slide.",
  VIEW_BY_TOPIC_REQUIRED:
    "<br><span class='bolde-note'>Slides with gray checkbox(es) are required and cannot be removed from presentation.</span>",
  VIEW_BY_SEARCH:
    "Enter a keyword or phrase below to find content. You can add a slide directly to your presentation from the search results.",
  VIEW_BY_SEARCH_REQUIRED:
    "<br><span class='bolde-note'>Gray cross button(s) are required slides and cannot be removed from presentation.</span>",
  APPENDIX_LIMIT:
    "You have exceeded 150 slides. Overview slide won't be included in your presentation."
};
/**
 * render hint on hover
 */
const onRenderContent = (target, content) => {
  return (
    <CustomHintContent>
      <span>{content}</span>
    </CustomHintContent>
  );
};

/**
 * Main library component
 * @param {*} props
 */
const Library = props => {
  let {
    addRequiredSlidesSlides,
    checkIfSlideUseforReport,
    selectedSlidesListDetail,
    selectedTabValue,
    maximumSlideCount,
    nextStepHandler,
    onPreview,
    onSave,
    isEdited,
    overviewLimitExceeded,
    getUnseenOverlaySlideId,
    filters,
    requiredSlides
  } = props;

  let withRequiredSlides = checkIfSlideUseforReport(
    addRequiredSlidesSlides(selectedSlidesListDetail)
  );

  let requiredSlideMessage =
    Array.isArray(requiredSlides) && requiredSlides.length
      ? selectedTabValue === "View by Topic"
        ? UISTRINGS.VIEW_BY_TOPIC_REQUIRED
        : UISTRINGS.VIEW_BY_SEARCH_REQUIRED
      : "";

  let slidesLength = withRequiredSlides && withRequiredSlides.length;
  let isFilterActive =
    get(featureFlags, "presentation.showPresentationFilter") &&
    filters.length &&
    selectedTabValue === "View by Topic";

  let libraryDescription =
    selectedTabValue === "View by Topic"
      ? UISTRINGS.VIEW_BY_TOPIC + requiredSlideMessage
      : UISTRINGS.VIEW_BY_SEARCH + requiredSlideMessage;

  const { slides, filteredSlides, searchResultSlides } = props;
  //console.log( { slides });
  //console.log( { filteredSlides });
  //console.log( { searchResultSlides });
  //console.log( {filters} );

  return (
    <LibraryWrapper>
      <ReactHint events onRenderContent={onRenderContent} />
      <StepDetails
        _showCount
        maximumSlideCount={maximumSlideCount}
        _slideCount={slidesLength}
        _preview
        _save={isEdited}
        _next
        title={"Library"}
        description={libraryDescription}
        onNext={() => {
          nextStepHandler(3);
          getUnseenOverlaySlideId("library");
        }}
        onPreview={() => onPreview()}
        onSave={onSave}
      />
      {overviewLimitExceeded && <Warning>{UISTRINGS.APPENDIX_LIMIT}</Warning>}
      <LibraryMainContainer isFullWidth={!isFilterActive}>
        {isFilterActive ? (
          <FiltersAside>
            <Filters {...props} />
          </FiltersAside>
        ) : null}
        <LibraryMain isFullWidth={!isFilterActive}>
          <ViewContainer>
            <ViewTabs
              {...props}
              fromPresentationScreen= {true}
              slides={props.slides}
              filteredSlides={props.filteredSlides}
              searchLibrarySearch={props.searchResultSlides}
            />
          </ViewContainer>
        </LibraryMain>
      </LibraryMainContainer>
    </LibraryWrapper>
  );
};

const LibraryWrapper = styled.div``;

const Warning = styled.span`
  margin-top: 20px;
  width: 54%;
  display: inline-block;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 12px;
  color: ${props => props.theme.COLOR_PALLETE.LIPSTICK};
`;

const FiltersAside = styled.aside`
  padding: 12px 12px 73px;
  flex-basis: 24%;
  align-self: baseline;
  border-radius: 4px;
  background-color: ${props => props.theme.COLOR.WHITE};
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
`;

const LibraryMainContainer = styled.div`
  margin: 38px 0;
  display: flex;
  justify-content: ${({ isFullWidth }) =>
    isFullWidth ? `center` : `space-between`};
`;

const LibraryMain = styled.div`
  flex-basis: ${props => (props.isFullWidth ? `90%` : `68.9%`)};
`;

const ViewContainer = styled.div`
  .tabsData {
    padding-top: 12px;
    border-radius: 3px;
    background-color: ${props => props.theme.COLOR.WHITE};
    display: none;
    position: relative;
    ${props => props.theme.SNIPPETS.BOX_SHADOW};

    &.active-tab {
      display: block;
    }
  }
`;

const CustomHintContent = styled.div`
  width: 200px;
  padding: 10px;
  background-color: ${props => props.theme.COLOR.BLACK};
  border-radius: 4px;

  span {
    color: ${props => props.theme.COLOR.WHITE};
  }
`;

export default Container(Library);
