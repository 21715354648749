import React, { Component } from "react";
import { EditWithNoShadow, Delete } from "assets/icons";
import styled, { css } from "styled-components";
import { map, get, filter, size } from "lodash";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import PollingUtils from "utils/PollingUtils";
import ToastUtils from "utils/handleToast";
import { useState } from "react";
import { MdDone, MdClear } from "react-icons/md";

import { mapStateToProps, actions } from "components/header/mapStateToProps";
import { connect } from "react-redux";

const container = Main =>
  connect(
    mapStateToProps,
    actions
  )(
    class Container extends Component {
      state = {
        fonts: [],
        editFontId: null,
        cols: [
          {
            col1: "Fonts Name",
            col2: "Sample Text",
            col3: "Action"
          }
        ],
        fontsData: [],
        columnWidth: [200, 250, 50],
        searchFields: ["name"],
        tableColumnHeader: ["name", "location", ""]
      };

      componentDidMount() {
        this._fetchFontList();
      }

      _fetchFontList = async () => {
        await this.props.getFontList();
        await this.deleteNullFonts();
        this.formatResponse(this.props.fontData);
      };

      InputBox = (row, updateFont, getTemplateListHandler) => {
        const [showPatchRequest, setshowPatchRequest] = useState(false);
        const [rowValue, setrowValue] = useState(
          row.name ? row.name : row.family
        );
        const [errorMessage, seterrorMessage] = useState(null);
        const onCellValueChange = e => {
          setshowPatchRequest(true);
          setrowValue(e.target.value);
          if (row.name) {
            if (row.name === e.target.value) {
              setshowPatchRequest(false);
            }
          } else if (row.family === e.target.value) {
            setshowPatchRequest(false);
          }
        };

        return (
          <div>
            <InputTemplateBox
              value={rowValue}
              type="text"
              title={rowValue}
              onChange={onCellValueChange}
              isErrorMessage={errorMessage}
            />

            <UpdateBoxWrapper>
              {showPatchRequest && size(rowValue) > 0 && !errorMessage ? (
                <UpdateBoxWrapperDone>
                  <MdDone
                    onClick={async () => {
                      let { success } = await updateFont(
                        { name: rowValue },
                        row._id
                      );
                      success &&
                        ToastUtils.handleToast({
                          operation: "success",
                          message: "Font Rename successfully."
                        });
                      this._fetchFontList();
                      this.setState({
                        editFontId: null
                      });
                    }}
                  />
                </UpdateBoxWrapperDone>
              ) : (
                <span></span>
              )}
              <UpdateBoxWrapperClear>
                <MdClear
                  onClick={() => {
                    this.setState({
                      editFontId: null
                    });
                  }}
                />
              </UpdateBoxWrapperClear>
            </UpdateBoxWrapper>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </div>
        );
      };

      /**
       * called for rendering table cells
       */
      renderTableCell = ({ row, col, highlightText }) => {
        let original = row.original;
        !original && (original = row.row);
        const { editFontId } = this.state;
        if (col === "Fonts Name") {
          if (original.family || original.status === "Completed") {
            if (editFontId && editFontId === original._id) {
              return this.InputBox(
                original,
                this.props.createFont,
                this._fetchFontList
              );
            } else {
              return (
                <FontName>
                  {highlightText(
                    original.name ? original.name : original.family
                  )}
                  <SubHeading>{`(${original.style})`}</SubHeading>
                </FontName>
              );
            }
          } else {
            let name = get(original, `fileName`)
              .split(".")
              .slice(0, -1)
              .join(".");
            return (
              <FontName>
                {highlightText(name)}
                <SubHeading>{`(In Progress)`}</SubHeading>
              </FontName>
            );
          }
        } else if (col === "Sample Text") {
          if (original.family || original.status === "Completed") {
            return (
              <SampleText original={original}>Lorem ipsum is simply</SampleText>
            );
          } else {
            return <span></span>;
          }
        } else if (col === "Action") {
          if (original.family || original.status === "Completed") {
            return this.showIcon(original);
          } else {
            return <span></span>;
          }
        }
      };

      renderHead = () => {
        return (
          <div className="heading">
            <HeadingName>Fonts</HeadingName>
          </div>
        );
      };

      editFont = fontId => {
        this.setState({
          editFontId: fontId
        });
      };

      deleteFontData = async (fontId, isPollingTimeOut) => {
        let responce = await this.props.deleteFont(fontId, isPollingTimeOut);
        responce.success && this._fetchFontList();
      };

      // function to show last column in table
      showIcon = rowData => {
        return (
          <>
            <IconWrapper title="Delete">
              <Delete
                onClick={() => {
                  DeleteConfirmationAlert({
                    onYesClick: () => {
                      this.deleteFontData(rowData._id);
                    }
                  });
                }}
              />
            </IconWrapper>
          </>
        );
      };

      uploadFileToRepo = async ({ metaDeta, file }, cb) => {
        delete metaDeta.resource;
        delete metaDeta.title;

        let postData = {
          ...metaDeta,
          fileType: get(metaDeta, `fileName`).split(".")[
            get(metaDeta, `fileName`).split(".").length - 1
          ]
        };

        let { data, success } = await this.props.createFont(postData);

        if (success && data.presignedUrl) {
          // use presigned url to upload file to aws
          let { success } =
            (await this.props.uploadFontToAws(data.presignedUrl, file)) || {};

          if (!success) {
            cb();
            return;
          }

          this.handlePolling(get(metaDeta, `fileName`));
          cb();
        } else {
          ToastUtils.handleToast({
            operation: "error",
            message: get(data, "message")
          });
        }
      };

      handlePolling = fileName => {
        // start polling
        PollingUtils.startPolling({
          pollingAction: () => {
            this.startPollingForFonts();
          },
          timeoutCallback: async () => {
            PollingUtils.stopPolling();
            ToastUtils.handleToast({
              operation: "error",
              message: "Uploading is taking too long. Please try again later."
            });
            this.deleteNullFonts();
          },
          timeoutDuration: 60000
        });
      };

      deleteNullFonts = async () => {
        await this.props.getFontList(true);
        this.formatResponse(this.props.fontData);

        let { fonts } = this.state;

        map(fonts, eachFont => {
          !eachFont.family &&
            eachFont.status !== "Completed" &&
            this.deleteFontData(eachFont._id, true);
        });

        await this.props.getFontList(true);
        this.formatResponse(this.props.fontData);
      };

      startPollingForFonts = async fileName => {
        await this.props.getFontList(true);

        const fontData = this.props.fontData;
        this.formatResponse(fontData);

        let isAnyFontStatusNull = filter(fontData, eachFont => {
          return get(eachFont, `family`) === null;
        });

        if (isAnyFontStatusNull.length === 0) {
          PollingUtils.stopPolling();
          await this.props.getFontList(true);
          this.formatResponse(this.props.fontData);
        }
      };

      formatResponse = fontData => {
        let fonts = [];

        map(fontData, eachFamily => {
          if (eachFamily.fonts) {
            map(eachFamily.fonts, eachFont => {
              fonts.push(eachFont);
            });
          }
        });

        this.setState({
          fonts
        });
      };

      render() {
        const $this = this;

        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

const SharedIconWrapperCss = css`
  padding: 0 8px;
  height: auto;
  display: inline-block;
  svg:hover {
    opacity: 0.7;
  }

  svg {
    height: 15px;
    cursor: pointer;
  }
`;

const IconWrapper = styled.span`
  ${SharedIconWrapperCss};
`;

const ShareIconWrapper = styled.span`
  ${SharedIconWrapperCss};
`;

const FontName = styled.span`
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

const SampleText = styled.span`
  @font-face {
    font-family: ${props => `${props.original.family}${props.original.style}`};
    src: url(${props => props.original.location});
  }

  font-family: ${props => `${props.original.family}${props.original.style}`};
  color: ${props => props.theme.COLOR_PALLETE.BROWNISH_GREY};
`;

const SubHeading = styled.span`
  display: block;
  font-size: 9px;
`;

const HeadingName = styled.span`
  margin-left: -2px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;
const InputTemplateBox = styled.input`
  width: 68%;
  border: none;
  border-bottom: 1px solid ${props => props.theme.COLOR.USER_PRIMARY};
  font-size: 12px;
  text-overflow: ${props => (size(props.value) > 10 ? "ellipsis" : "initial")};
  font-family: ${props => props.theme.FONT.REG};
  background-color: transparent;
  box-sizing: border-box;
  outline: none;
`;

const UpdateBoxWrapper = styled.span`
  position: relative;
  top: 0.2rem;
  left: 0.2rem;
`;

const UpdateBoxWrapperClear = styled.span`
  position: relative;
  left: 0.2rem;
  cursor: pointer;
`;

const UpdateBoxWrapperDone = styled.span`
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  font-size: 9px;
  color: ${props => props.theme.COLOR.ERROR};
  margin-top: 8px;
`;

export default container;
