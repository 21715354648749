import { RepoTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

export const onContentRepoSuccess = (
  payload = {},
  type = RepoTypes.SUCCESS_CONTENTREPO_LIST
) => {
  return {
    type,
    payload
  };
};

export const isContentRepoLoading = (
  payload = {},
  type = RepoTypes.LOADING_CONTENTREPO
) => {
  return {
    type,
    payload
  };
};

const getContentRepoList = userId => async dispatch => {
  const URL = `/${userId}/content-repository`;
  // start loading
  dispatch(isContentRepoLoading({ isRepoLoading: true }));

  const response = await FetchUtils.getData(
    URL,
    "Get Content Repo List for Setup==>"
  );
    // stop loading
  dispatch(isContentRepoLoading({ isRepoLoading: false }));

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onContentRepoSuccess({
        contentRepoList: response.data
      })
    );
  } else {
    //stop loading
    dispatch(isContentRepoLoading({ isRepoLoading: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

export { getContentRepoList };
