import React, { Component } from "react";
import {
  getSelectedTemplate,
  getTemplateList,
  getDocumentList,
  deleteDocument,
  cloneDocument
} from "tools/summaryMaker/store/actions/dashboardActions";
import { setCurrentDocumentData } from "tools/summaryMaker/store/actions/documentBuildActions";

import { connect } from "react-redux";
import { tableData } from "./data";
import { get, map, fill, each, find, includes } from "lodash";
import {
  ShareColored,
  Delete,
  Duplicate,
  EditWithNoShadow
} from "assets/icons";
import styled from "styled-components";
import Dashboard from "./index";
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import { TiDeleteOutline } from "react-icons/ti";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
import { getDocumentPdf } from "tools/summaryMaker/store/actions/previewBuildActions";
import ToastUtils from "utils/handleToast";
import { downloadFile } from "utils/download";
import { Link } from "react-router-dom";

const mapStateToProps = state => {
  const {
    domain: {
      RECEIVE_TEMPLATE_LIST,
      REQUEST_DATA,
      SELECTED_TEMPLATE,
      RECEIVE_DOCUMENT_DATA,
      RECEIVE_DOCUMENT_DASHBOARD
    },
    SUCCESS_USER_PROFILE
  } = state;

  return {
    ...RECEIVE_TEMPLATE_LIST,
    ...REQUEST_DATA,
    ...SELECTED_TEMPLATE,
    ...SUCCESS_USER_PROFILE,
    ...RECEIVE_DOCUMENT_DATA,
    ...RECEIVE_DOCUMENT_DASHBOARD
  };
};

const mapDispatchToProps = {
  getSelectedTemplate,
  getTemplateList,
  setCurrentDocumentData,
  getDocumentList,
  deleteDocument,
  cloneDocument,
  getDocumentPdf
};

class DashboardContainer extends Component {
  state = {
    tableColumnsKeys: [],
    tableColumns: [],
    columnWidth: [],
    modalFields: []
  };

  // called on click of templates
  templateClickHandler = selectedTemplate => {
    this.props.getSelectedTemplate(selectedTemplate);
  };

  setTableMetaData = () => {
    let { userProfileMeta = {} } = this.props;
    let tableMetaData = tableData;
    let isAppendCreatedByColumn =
      get(userProfileMeta, `roles`) &&
      userProfileMeta.roles.indexOf("Summary Maker Admin") !== -1 &&
      !find(tableMetaData[0].fields, { label: "Created By" });

    if (isAppendCreatedByColumn) {
      tableMetaData[0].fields.push({
        label: "Created By",
        key: "createdBy.name"
      });
    }

    this.setState(
      {
        tableMetaData
      },
      () => {
        this._extractMetadata();
        this._extractColumns();
        this._calculateColumnsWidth();
      }
    );
  };

  editDocument = (documentId, templateId) => {
    this.props.history.push(
      `/summarymaker/template/${templateId}/build/${documentId}`
    );
  };

  componentDidMount() {
    this.setTableMetaData();
    this.getDocumentList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userProfileMeta !== this.props.userProfileMeta) {
      this.getDocumentList();
      this.setTableMetaData();
    }
  }

  getDocumentList = async () => {
    const userId = get(this.props, `userProfileMeta._id`);
    if (!userId) return;
    await this.props.getDocumentList(userId);
    let { documentDashboardData } = this.props;

    this.setState({
      documentList: documentDashboardData
    });
  };

  // extract metadata of the selected template for getting columns info
  _extractMetadata = () => {
    let { tableColumnsKeys, tableMetaData } = this.state;

    tableColumnsKeys = map(get(tableMetaData[0], `fields`), eachMetadata => {
      return get(eachMetadata, `key`);
    });
    tableColumnsKeys.push(""); // pushing empty string for action column to be displayed

    this.setState({
      tableColumnsKeys
    });
  };

  /**
   * called for rendering table cells
   */
  renderTableCell = ({ row, col, highlightText, colName, index }) => {
    if (col === "Created Date" || col === "Updated Date") {
      return (
        <span>
          <Moment
            format="MM/DD/YYYY HH:mm"
            titleFormat="MM/DD/YYYY HH:mm"
            withTitle
          >
            {row.value}
          </Moment>
        </span>
      );
    } else if (index + 1 === Object.values(colName).length) {
      return <ActionButton>{this.showIcon(row)}</ActionButton>;
    }
    if (col === "Customer Name") {
      if (!get(row.row, `customerName`)) {
        return "-";
      }
    }
    if (col === "Template") {
      if (get(row.row, `template.name`) === "Template Deleted") {
        return (
          <DeletedTemplateName title={row.value}>
            {get(row.row, `template.name`)}
          </DeletedTemplateName>
        );
      } else {
        return <span title={row.value}>{get(row.row, `template.name`)}</span>;
      }
    } else if (col === "Created By") {
      return <span title={row.value}>{get(row.row, `createdBy.name`)}</span>;
    } else if (typeof row.value !== "object") {
      return <span title={row.value}> {highlightText(row.value)} </span>;
    }
  };

  _extractColumns = () => {
    let { tableColumns, tableMetaData } = this.state;
    let columnNames = {};

    // formatting columns name as col1 : columnName
    each(tableMetaData[0].fields, (eachMetadata, index) => {
      columnNames[`col${index + 1}`] = get(eachMetadata, `label`);
    });

    // pushing last action column
    columnNames[`col${tableMetaData[0].fields.length + 1}`] = "Actions";
    tableColumns = [
      {
        ...columnNames
      }
    ];

    this.setState({
      tableColumns
    });
  };

  _calculateColumnsWidth = () => {
    let { columnWidth, tableMetaData } = this.state;
    // "+ 1" as actions column width also has to be included
    let eachColumnWidth = 900 / (get(tableMetaData[0], `fields.length`) + 1);

    // each column width will be of same size, therefore populating same width length many times in the column width array
    columnWidth = fill(
      Array(tableMetaData[0].fields.length + 1),
      eachColumnWidth
    );

    this.setState({
      columnWidth
    });
  };

  documentDeleteHandler = async documentId => {
    const userId = get(this.props, `userProfileMeta._id`);

    await this.props.deleteDocument(userId, documentId);
    this.getDocumentList();
  };

  cloneDocumentHandler = async documentId => {
    const userId = get(this.props, `userProfileMeta._id`);
    this.props.setCurrentDocumentData({});
    await this.props.cloneDocument(userId, documentId);
  };

  downloadPdf = async (documentId, userId, documentName) => {
    const response = await this.props.getDocumentPdf(documentId, userId);

    if (get(response, "data")) {
      ToastUtils.handleToast({
        operation: "success",
        message: "The document will be automatically downloaded"
      });
      const url = URL.createObjectURL(response.data);
      downloadFile(url, documentName);
    } else {
      ToastUtils.handleToast({
        operation: "warning",
        message: "The template is not properly built"
      });
    }
  };

  renderDocumentDownloadPopup = rowData => {
    const documentId = rowData._id;
    const documentName = rowData.name;
    let crosMarkFlag = true;
    let userId = rowData.createdBy._id;

    includes(get(this.props, `userProfileMeta.roles`), "Summary Maker Admin")
      ? (userId = rowData.createdBy._id)
      : (userId = rowData.createdBy);

    // Ensuring that onClicking the function, the modal popup will close: used by <CrossMark> Component
    const closePopupOnCrossMark = () => {
      let btn = document.querySelector(".react-confirm-alert-button-group");
      if (btn && btn.querySelectorAll("button")) {
        crosMarkFlag = false;
        btn.querySelectorAll("button")[0].click();
      }
    };

    let note =
      "The document you are trying to open was built from a template that has been deleted. You can download the most recently generated PDF, or you can delete the document, or you can leave the document as it is.";

    confirmAlert({
      message: "Template Deleted by Admin",
      buttons: [
        {
          label: "Continue",
          onClick: () => {}
        },
        {
          label: "Download",
          onClick: () => this.downloadPdf(documentId, userId, documentName)
        },
        {
          label: "Delete",
          onClick: () => this.documentDeleteHandler(get(rowData, `_id`))
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,

      childrenElement: () => (
        <>
          <CrossMark
            onClick={() => {
              closePopupOnCrossMark(false);
            }}
          >
            <TiDeleteOutline />
          </CrossMark>
          {note && (
            <div
              style={{
                fontSize: 12,
                paddingTop: 15,
                lineHeight: 1.3,
                width: "85%"
              }}
              className="delete-confirm-note"
            >
              {note}
            </div>
          )}
        </>
      )
    });
  };

  // icons under the Action tab
  showIcon = row => {
    let { tableMetaData } = this.state;
    let { actionFields } = tableMetaData[0];
    let rowData = get(row, `value`) || get(row, `row`);
    let documentId = get(rowData, `_id`);
    let templateId = get(rowData, `template._id`);
    let Icon = {
      canEdit: (
        <Link to={`/summarymaker/template/${templateId}/build/${documentId}`}>
          <EditWithNoShadow
            onClick={() => {
              if (rowData.template._id === "Deleted") {
                this.renderDocumentDownloadPopup(rowData);
              } else {
                this.props.setCurrentDocumentData({});
              }
            }}
          />
        </Link>
      ),
      canDelete: (
        <Delete
          onClick={() => {
            DeleteConfirmationAlert({
              onYesClick: () => {
                this.documentDeleteHandler(get(rowData, `_id`));
              }
            });
          }}
        />
      ),
      canClone: (
        <Link to={`/summarymaker/template/${templateId}/build/${documentId}`}>
          <Duplicate
            onClick={() => this.cloneDocumentHandler(get(rowData, `_id`))}
          />
        </Link>
      ),
      canShare: <ShareColored />
    };

    return (
      <>
        {map(actionFields, (eachActionField, index) => {
          return <IconWrapper key={index}>{Icon[eachActionField]}</IconWrapper>;
        })}
      </>
    );
  };

  renderHead = () => {
    return (
      <div className="heading">
        <HeadingName>Documents</HeadingName>
      </div>
    );
  };

  handleDocumentAddModal = () => {
    this.props.getSelectedTemplate(null);
    this.props.history.push(`/summarymaker/build`);
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Dashboard {...stateMethodProps} />;
  }
}

const ActionButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrapper = styled.span`
  display: inline-block;
  svg {
    cursor: pointer;
    max-width: 18px;
  }
`;

const DeletedTemplateName = styled.span`
  font-style: italic;
`;

const HeadingName = styled.span``;

const CrossMark = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 30px;
  cursor: pointer;
`;

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
