import axios from "axios";
import { refreshAccessToken } from "../services/webAuth";

export const getAxiosClientWithRefreshToken = ({ baseURL, authHeaderName = "Authorization" }) => {
  const client = axios.create({
    baseURL: baseURL
  });

  console.log(`Axios configured to use ${baseURL} for API requests`);

  axios.defaults.timeout = 60000;

  client.interceptors.request.use(
    async (config) => {

      const auth0Token = localStorage.getItem("token");

      if (auth0Token) {
        config.headers.common[authHeaderName] = `Bearer ${auth0Token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // check if response status is 401 and this request hasn't been retried yet (to avoid infinite loop)
      if (error.response && error.response.status === 401 && !originalRequest.alreadyRetried) {
        try {
          // grab a fresh access token
          const newToken = await refreshAccessToken();

          // retry the request with the new token
          originalRequest.headers[authHeaderName] = `Bearer ${newToken}`;

          error.config.alreadyRetried = true; // set the flag to avoid infinite loop in case of next 401

          return await client(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      // return any error if not related to 401
      return Promise.reject(error);
    }
  );

  return client;
};
