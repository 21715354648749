const RECEIVE_TEMPLATE_BLOCKS_ADMIN = "RECEIVE_TEMPLATE_BLOCKS_ADMIN";
const REQUEST_TEMPLATE_BLOCKS_ADMIN = "REQUEST_TEMPLATE_BLOCKS_ADMIN";
const REQUEST_TEMPLATE_BLOCK_CLIENT = "REQUEST_TEMPLATE_BLOCK_CLIENT";
const CREATE_TEMPLATE_PREVIEW_RENDER = "CREATE_TEMPLATE_PREVIEW_RENDER";
const RECEIVE_ACTIVE_TEMPLATE = "RECEIVE_ACTIVE_TEMPLATE";
const REQUEST_ACTIVE_TEMPLATE = "REQUEST_ACTIVE_TEMPLATE";
const REQUEST_TEMPLATE_UPDATE = "REQUEST_TEMPLATE_UPDATE";
const RECEIVE_TEMPLATE_BLOCK_CONTENT = "RECEIVE_TEMPLATE_BLOCK_CONTENT";
const RECEIVE_BLOCK_FUNCTIONS = "RECEIVE_BLOCK_FUNCTIONS";

const REQUEST_GET_COVER_IMAGE_DATA = "REQUEST_GET_COVER_IMAGE_DATA";
const RECEIVE_COVER_IMAGE_DATA = "RECEIVE_COVER_IMAGE_DATA";

export const TemplateCreationTypes = {
  REQUEST_TEMPLATE_BLOCKS_ADMIN,
  RECEIVE_TEMPLATE_BLOCKS_ADMIN,
  REQUEST_TEMPLATE_BLOCK_CLIENT,
  CREATE_TEMPLATE_PREVIEW_RENDER,
  REQUEST_ACTIVE_TEMPLATE,
  RECEIVE_ACTIVE_TEMPLATE,
  REQUEST_TEMPLATE_UPDATE,
  RECEIVE_TEMPLATE_BLOCK_CONTENT,
  RECEIVE_BLOCK_FUNCTIONS,
  REQUEST_GET_COVER_IMAGE_DATA,
  RECEIVE_COVER_IMAGE_DATA
};
