// libraries
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// components
import Button from "components/button";
import hexToRgba from "utils/hexToRgba";
import { get } from "lodash";
import Checkbox from "components/checkbox";
import StyledDropzone from "components/chooseFile";

export default function index(props) {
  const noOfDocumentsRef = useRef(null);
  const noOfPagesRef = useRef(null);
  const {
    updateTemplateBlockHandler,
    showToastMessage,
    handleStateChange,
    isActiveOnClickTemplateBlocks
  } = props;

  const InitialState = {
    divider: get(isActiveOnClickTemplateBlocks, `divider`)
  };

  const UploadFileNameUser = isActiveOnClickTemplateBlocks.pdfUploadedFileName
    ? get(isActiveOnClickTemplateBlocks, `pdfUploadedFileName`)
    : get(isActiveOnClickTemplateBlocks, `pdfFilename`);

  const [checkboxCheck, setcheckboxCheck] = useState(InitialState.divider);

  useEffect(() => {
    handleStateChange({ key: "isBlockEdited", value: true });
  }, []);

  const onKeyPressHandler = e => {
    handleStateChange({ key: "isBlockEdited", value: true });
    let charCode = e.which || e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  const saveHandler = () => {
    if (
      !get(noOfDocumentsRef, `current.value`) ||
      !get(noOfPagesRef, `current.value`)
    ) {
      showToastMessage("error", "Please fill all the details.");
      return;
    }

    handleStateChange({ key: "isBlockEdited", value: true });

    const appendixConfig = {
      maxDocNumber: Number(get(noOfDocumentsRef, `current.value`)),
      maxPages: Number(get(noOfPagesRef, `current.value`)),
      divider: checkboxCheck,
      items: []
    };

    updateTemplateBlockHandler(appendixConfig);
  };

  const handleCheckbox = () => {
    handleStateChange({ key: "isBlockEdited", value: true });
    setcheckboxCheck(!checkboxCheck);
  };

  return (
    <>
      <BlockHeader>
        <Button
          text="Save"
          width="100px"
          float="none"
          buttonClass="save"
          onClick={saveHandler}
        />
      </BlockHeader>
      <DocumentNumber>
        <Text>Maximum # of uploaded documents:</Text>
        <InputField
          type="number"
          defaultValue={get(isActiveOnClickTemplateBlocks, `maxDocNumber`)}
          ref={noOfDocumentsRef}
          onKeyPress={onKeyPressHandler}
        />
      </DocumentNumber>

      <DocumentNumber>
        <Text>Maximum # of pages:</Text>
        <InputField
          type="number"
          defaultValue={get(isActiveOnClickTemplateBlocks, `maxPages`)}
          ref={noOfPagesRef}
          onKeyPress={onKeyPressHandler}
        />
      </DocumentNumber>

      <CheckboxWrapper>
        <Checkbox
          label={"Divider Page"}
          checked={checkboxCheck}
          handleChange={handleCheckbox}
        ></Checkbox>
        {UploadFileNameUser && checkboxCheck && (
          <Text margin>
            File Name:
            <Filename>{UploadFileNameUser}</Filename>
          </Text>
        )}
      </CheckboxWrapper>

      {checkboxCheck && (
        <StyledDropzone
          {...props}
          uploadFileType="pdf"
          hint="Upload a PDF for Appendix Page"
        />
      )}
    </>
  );
}

const DocumentNumber = styled.div`
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
`;

const CheckboxWrapper = styled.div`
  text-align: left;
  margin: 16px 0 16px 0;
`;

const Filename = styled.span`
  font-size: 14px;
  margin-top: 5px;
  margin-left: 3px;
`;

const Text = styled.span`
  ${props => props.theme.SNIPPETS.FONT_STYLE};
  width: 350px;
  display: inline-block;
  font-size: 17px;
  color: ${props => props.theme.COLOR.MAIN};
  font-weight: bold;
  margin: ${props => (props.margin ? "20px 0 0 0" : "0")};
`;

const InputField = styled.input`
  width: 38px;
  height: 26px;
  margin: 9px 5px 0;
  padding: 5px 5px;
  font-size: 14px;
  font-family: ${props => props.theme.FONT.REG};
  background-color: ${props => hexToRgba(props.theme.COLOR.WHITE, "0.18")};
  border: 1px solid
    ${props =>
      props.isError
        ? props.theme.COLOR_PALLETE.RED
        : hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.4")};
  border-radius: 4px;
  color: ${props => props.theme.COLOR_PALLETE.GREY};
  box-sizing: border-box;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.13);
  }
`;

const BlockHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;
