import FetchUtils from "utils/FetchUtils";
import ToastUtils from "utils/handleToast";
import { get } from "lodash";

import {
  onThemeListSuccess,
  isThemeDataLoading,
  onPreviewDataSuccess,
  onTaggingSuccess,
  onUploadProgress
} from "./actions";

/**
 * Delete a theme/deactivate based on user response
 * @param {string} contentRepositoryId
 * @param {string} themeId
 * @param {string} action - deleted/deactivated based on user response
 */
const deleteOrDeactivateTheme = (
  contentRepositoryId,
  themeId,
  action = "deleted"
) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/${themeId}`;

  // start loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: true
    })
  );

  const response = await FetchUtils.deleteData(
    URL,
    "Delete/deactivate a Theme"
  );

  // stop loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: `Theme ${action} successfully.`
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

/**
 * Get Content repositories list
 *
 */
const getThemeList = (contentRepositoryId, flag) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes`;

  // start loading
  !flag &&
    dispatch(
      isThemeDataLoading({
        isThemeLoading: true
      })
    );

  const response = await FetchUtils.getData(URL);

  // stop loading
  !flag &&
    dispatch(
      isThemeDataLoading({
        isThemeLoading: false
      })
    );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        themeListData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

const saveTheme = (
  contentRepositoryId,
  themeId,
  body,
  message
) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/${themeId}`;

  const response = await FetchUtils.patchData(URL, body, "save theme data");

  if (response && response.success) {
    // dispatch logo list on success
    ToastUtils.handleToast({
      operation: "success",
      message: message || "Theme has been successfully updated.",
      autoclose: 3000
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const getContentRepoName = contentRepositoryId => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}`;

  // start loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  // stop loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        selectedContentRepo: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**
 * Reorder themes under a content repo
 * @param {string} contentRepositoryId
 * @param {array} payload
 */
const reorderThemes = (contentRepositoryId, payload) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/reorder`;
  //start loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: true
    })
  );

  const response = await FetchUtils.putData(URL, payload, "Reorder themes");

  // stop loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );

  if (response.success && response.data) {
    ToastUtils.handleToast({
      operation: "success",
      message: "Theme has been successfully reordered."
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const createTheme = (contentRepositoryId, body) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes`;
  dispatch(
    isThemeDataLoading({
      showPopup: true
    })
  );
  const response = await FetchUtils.postData(URL, body, "save theme data");

  dispatch(
    onUploadProgress({
      percentCompleted: 0
    })
  );

  if (response.success && response.data) {
    // dispatch logo list on success
    dispatch(
      onUploadProgress({
        percentCompleted: 25
      })
    );

    dispatch(
      onThemeListSuccess({
        themePresignedIngestDetails: response.data
      })
    );
  } else {
    // hide loader
    dispatch(
      isThemeDataLoading({
        showPopup: false
      })
    );

    dispatch(
      onThemeListSuccess({
        themePresignedIngestDetails: {}
      })
    );

    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const uploadThemeToAws = (presignedUrl, file) => async dispatch => {
  const headers = {
    "Content-Type": file.type
  };

  const response = await FetchUtils.awsPutData(
    presignedUrl,
    file,
    headers,
    "Upload theme to aws"
  );

  if (response.success) {
    dispatch(
      onUploadProgress({
        percentCompleted: 50
      })
    );

    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        themeUploadStatus: true
      })
    );
  } else {
    // stop loading
    dispatch(
      isThemeDataLoading({
        showPopup: false
      })
    );
  }

  return response;
};

const getPreviewDataPollingStatus = id => async dispatch => {
  const URL = `/status/${id}`;

  const response = await FetchUtils.getData(URL, "Preview data polling status");

  if (response.success && response.data.status !== "Failed") {
    dispatch(
      onPreviewDataSuccess({
        previewDataPollingStatus: response
      })
    );

    if (get(response, `data.status`) === "File Received") {
      dispatch(
        onUploadProgress({
          percentCompleted: 75
        })
      );
    } else if (get(response, `data.status`) === "File Parsed") {
      dispatch(
        onUploadProgress({
          percentCompleted: 100
        })
      );

      ToastUtils.handleToast({
        operation: "success",
        message:
          "Your theme has been successfully uploaded. Please tag the master layouts below so we can associate them with the correct slide layouts.",
        autoClose: 1000000
      });
    }

    dispatch(
      isThemeDataLoading({
        showPop: true
      })
    );
  } else {
    dispatch(
      onPreviewDataSuccess({
        previewDataPollingStatus: response
      })
    );
    // stop loading
    dispatch(
      isThemeDataLoading({
        showPopup: false
      })
    );
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
  return response;
};

const getFilePollingStatus = id => async dispatch => {
  const URL = `/status/${id}`;

  const response = await FetchUtils.getData(URL, "File polling status");

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        filePollingStatus: response
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }

  return response;
};

const stopThemeUpload = () => dispatch => {
  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );
};

const saveSlideTagging = (
  contentRepositoryId,
  themeId,
  body
) => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes/${themeId}`;

  dispatch(
    isThemeDataLoading({
      isThemeLoading: true
    })
  );

  const response = await FetchUtils.patchData(URL, body, "save slide tagging");

  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );

  if (response && response.success) {
    dispatch(
      onTaggingSuccess({
        taggingComplete: response
      })
    );

    // dispatch logo list on succese
    ToastUtils.handleToast({
      operation: "success",
      message: "Slide tagging has been successfully updated.",
      autoclose: 3000
    });
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

const hideProgressBar = () => dispatch => {
  // stop loading
  dispatch(
    isThemeDataLoading({
      showPopup: false
    })
  );
  dispatch(
    onUploadProgress({
      percentCompleted: 0
    })
  );
};

export {
  deleteOrDeactivateTheme,
  getThemeList,
  saveTheme,
  getContentRepoName,
  reorderThemes,
  createTheme,
  getFilePollingStatus,
  stopThemeUpload,
  uploadThemeToAws,
  getPreviewDataPollingStatus,
  saveSlideTagging,
  hideProgressBar
};
