import React from "react";
import { Route } from "react-router-dom";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
// routes
import { SummaryMakerAdmin } from "tools/summaryMaker/pages";
import { Dashboard } from "tools/summaryMaker/pages";
import { Build } from "tools/summaryMaker/pages";
import { TemplateBuild } from "tools/summaryMaker/pages";

//DND provider in routes has been introduced to eliminate 2 HTML5 backend issues
// reference for which is on https://github.com/react-dnd/react-dnd/issues/186
const SummaryMakerRoutes = (auth, checkAuthSession) => {
  const routes = (
    <>
      <Route
        path="/summarymaker/templates"
        render={props =>
          checkAuthSession(<SummaryMakerAdmin auth={auth} {...props} />)
        }
      />

      <Route
        path="/summarymaker/dashboard"
        render={props => checkAuthSession(<Dashboard auth={auth} {...props} />)}
      />

      <Route
        path="/summarymaker/template/:templateId/build/:documentId"
        render={props =>
          checkAuthSession(
            <DndProvider backend={Backend}>
              <Build auth={auth} {...props} />
            </DndProvider>
          )
        }
      />

      <Route
        path="/summarymaker/build"
        exact
        render={props =>
          checkAuthSession(
            <DndProvider backend={Backend}>
              <Build auth={auth} {...props} />
            </DndProvider>
          )
        }
      />

      <Route
        path="/summarymaker/build/:templateId"
        exact
        render={props =>
          checkAuthSession(
            <DndProvider backend={Backend}>
              <Build auth={auth} {...props} />
            </DndProvider>
          )
        }
      />

      <Route
        path="/summarymaker/template/create/:templateId"
        render={props =>
          checkAuthSession(
            <DndProvider backend={Backend}>
              <TemplateBuild auth={auth} {...props} />
            </DndProvider>
          )
        }
      />
    </>
  );

  return routes;
};

export default SummaryMakerRoutes;
