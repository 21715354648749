import { BuildTypes } from "tools/summaryMaker/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

// action creators
const REQUEST_TEMPLATE_DATA = (
  payload = {},
  type = BuildTypes.REQUEST_TEMPLATE_DATA
) => {
  return {
    type,
    payload
  };
};

const RECEIVE_TEMPLATE_BLOCKS = (
  payload = {},
  type = BuildTypes.RECEIVE_TEMPLATE_BLOCKS
) => {
  return {
    type,
    payload
  };
};

const getTemplateBlocks = templateId => async dispatch => {
  const URL = `/summary/templates/${templateId}/blocks`;

  // start loading
  dispatch(
    REQUEST_TEMPLATE_DATA({
      isTemplateBlocksLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  dispatch(
    REQUEST_TEMPLATE_DATA({
      isTemplateBlocksLoading: false
    })
  );

  if (response.success && response.data) {
    dispatch(
      RECEIVE_TEMPLATE_BLOCKS({
        templateBlocks: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.message")
    });
  }
};

export { getTemplateBlocks };
