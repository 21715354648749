import React, { Component } from "react";
import styled, { css } from "styled-components";
import { flatMap } from "lodash";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
import hexToRgba from "utils/hexToRgba";
import "react-accessible-accordion/dist/fancy-example.css";
import { Dropdown, PreviewBig } from "assets/icons";
import { FaFileImage } from "react-icons/fa";
import TabHeader from "components/tabHeader";
import SearchBox from "components/searchBox";

const TabList = [
  { title: "Thumbnails", id: "thumbnails", value: "thumbnails" },
  { title: "Lists", id: "list", value: "list" }
];

class ModuleSelector extends Component {
  _renderAccordian = ({
    moduleList,
    isThumbnailView,
    isSelectable,
    isSearchActive,
    preExpandedCatArray,
    randomKey,
    showAllModules,
    selectedPlaceholder,
    searchAndLayoutSpecificModules,
    highlightText,
    hidePreviouslySelectedAccItem,
    selectedOption
  }) => {
    let {
      onModuleSelected,
      onOpenCloseModulePreview,
      selectedLayoutType,
      _getModuleThumbnail
    } = this.props;

    let moduleListAcc =
      !showAllModules && !Object.keys(selectedPlaceholder).length
        ? []
        : isSearchActive ||
          (!showAllModules && Object.keys(selectedPlaceholder).length)
        ? searchAndLayoutSpecificModules
        : moduleList;

    // Filter modules based on selected document type
    let filteredModules = (list => {
      if (Array.isArray(list) && list.length) {
        return list.map((eachItem = {}) => {
          if (eachItem) {
            let { documentTypes = [], title } = eachItem;

            let isPreviouslySelected =
              title === "Previously Selected…" || !documentTypes.length;
            // each category consist of a array of document types
            // If the selected document type matches any one of the categories document type then filter it.
            let validDocument =
              Array.isArray(documentTypes) &&
              documentTypes.filter(eachType => {
                return eachType._id === selectedOption.id;
              });

            if (validDocument.length) return eachItem;
            else if (isPreviouslySelected) return eachItem;
          }
          return null;
        });
      }
    })(moduleListAcc);

    return (
      <>
        <StyledAccordion
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          preExpanded={isSearchActive ? preExpandedCatArray : []}
          key={randomKey}
        >
          {flatMap(filteredModules, (item, index) => {
            if (!item) return null;
            const { title, children } = item;
            //subcatagory preExpanded array
            let preExpandedArraySubCat = [];
            children[1].subCategories.forEach((ele, subCatIndex) => {
              if (ele.children[0].modules.length > 0) {
                preExpandedArraySubCat.push(`${index}${subCatIndex}`);
              }
            });

            return (
              <AccordionItem
                key={index + title}
                uuid={index}
                className={
                  hidePreviouslySelectedAccItem &&
                  title === "Previously Selected…"
                    ? "hide_accordion__item"
                    : ""
                }
              >
                <StyledHead>
                  <StyledButton title={title}>
                    {highlightText(title)}
                    <StyledRightIcon />
                  </StyledButton>
                </StyledHead>
                <StyledPanel>
                  {/* module + subcatagory */}
                  {children.length !== 0 &&
                    flatMap(children, (catChild = {}, catChildIndex) => {
                      // Filter modules based on selected document type
                      let filteredSubCat = (list => {
                        if (Array.isArray(list) && list.length) {
                          return list.map((eachItem = {}) => {
                            if (eachItem) {
                              let { documentTypes = [] } = eachItem;
                              // each category consist of a array of document types
                              // If the selected document type matches any one of the categories document type then filter it.
                              let validDocument =
                                Array.isArray(documentTypes) &&
                                documentTypes.filter(eachType => {
                                  return eachType._id === selectedOption.id;
                                });

                              if (validDocument.length) return eachItem;
                            }
                            return null;
                          });
                        }
                      })(catChild.subCategories);

                      return Object.keys(catChild)[0] === "modules" ? (
                        // module UI
                        flatMap(
                          catChild.modules,
                          (moduleItem, moduleItemIndex) => {
                            const { thumbnails, title, id } = moduleItem;
                            // Get the thumbnail according to selected layout
                            let thumbnailDisableAndLocation = _getModuleThumbnail(
                              {
                                thumbnails,
                                selectedLayoutType
                              }
                            );

                            let {
                              thumbnailLocation,
                              isDisabled
                            } = thumbnailDisableAndLocation;
                            return (
                              <ModuleBlock key={title + moduleItemIndex}>
                                {isThumbnailView
                                  ? !!thumbnailLocation && (
                                      <>
                                        {isDisabled && <DisabledOverlay />}
                                        <ThumbnailWrapper
                                          key={moduleItemIndex}
                                          isSelectable={isSelectable}
                                        >
                                          <ImageWrapper
                                            onClick={e =>
                                              onModuleSelected({
                                                event: e,
                                                subItem: moduleItem,
                                                thumbnailLocation,
                                                moduleId: id
                                              })
                                            }
                                            isDisabled={isDisabled}
                                          >
                                            <Image src={thumbnailLocation} />
                                          </ImageWrapper>
                                          <Title
                                            onClick={() =>
                                              onOpenCloseModulePreview({
                                                catagoryModules:
                                                  catChild.modules,
                                                Index: moduleItemIndex
                                              })
                                            }
                                            isDisabled={isDisabled}
                                          >
                                            {highlightText(title)}
                                          </Title>
                                        </ThumbnailWrapper>
                                      </>
                                    )
                                  : !!thumbnailLocation && (
                                      <>
                                        <ListItem
                                          key={moduleItemIndex}
                                          isSelectable={isSelectable}
                                        >
                                          <ModuleContainer
                                            onClick={e =>
                                              onModuleSelected({
                                                event: e,
                                                subItem: moduleItem,
                                                thumbnailLocation,
                                                moduleId: id
                                              })
                                            }
                                            isDisabled={isDisabled}
                                          >
                                            <StyledImageIcon />
                                            <ItemTitle>
                                              {highlightText(title)}
                                            </ItemTitle>
                                          </ModuleContainer>
                                          <PreviewIconWrapper
                                            onClick={() =>
                                              onOpenCloseModulePreview({
                                                catagoryModules:
                                                  catChild.modules,
                                                Index: moduleItemIndex
                                              })
                                            }
                                          >
                                            <PreviewBig size={15} />
                                          </PreviewIconWrapper>
                                        </ListItem>
                                      </>
                                    )}
                              </ModuleBlock>
                            );
                          }
                        )
                      ) : (
                        // subcatagory UI
                        <StyledAccordion
                          allowMultipleExpanded={true}
                          allowZeroExpanded={true}
                          preExpanded={
                            isSearchActive ? preExpandedArraySubCat : []
                          }
                          key={randomKey}
                        >
                          {flatMap(
                            filteredSubCat,
                            (subCatItem, subCatIndex) => {
                              if (!subCatItem) return null;
                              const { title, children } = subCatItem;
                              return (
                                <AccordionItem
                                  key={subCatIndex + title}
                                  uuid={`${index}${subCatIndex}`}
                                >
                                  <StyledHead>
                                    <SubCatStyledButton title={title}>
                                      {highlightText(title)}
                                      <StyledRightIcon />
                                    </SubCatStyledButton>
                                  </StyledHead>
                                  <StyledPanel>
                                    {children.length !== 0 &&
                                      flatMap(
                                        children,
                                        (subCatChild, subCatChildIndex) => {
                                          return flatMap(
                                            subCatChild.modules,
                                            (
                                              subCatModuleItem,
                                              subCatModuleItemIndex
                                            ) => {
                                              const {
                                                thumbnails,
                                                title,
                                                id
                                              } = subCatModuleItem;
                                              // Get the thumbnail according to selected layout
                                              let thumbnailDisableAndLocation = _getModuleThumbnail(
                                                {
                                                  thumbnails,
                                                  selectedLayoutType
                                                }
                                              );

                                              let {
                                                thumbnailLocation,
                                                isDisabled
                                              } = thumbnailDisableAndLocation;

                                              return (
                                                <ModuleBlock
                                                  key={
                                                    title +
                                                    subCatModuleItemIndex
                                                  }
                                                >
                                                  {isThumbnailView
                                                    ? !!thumbnailLocation && (
                                                        <>
                                                          {isDisabled && (
                                                            <DisabledOverlay />
                                                          )}
                                                          <ThumbnailWrapper
                                                            key={
                                                              subCatModuleItemIndex
                                                            }
                                                            isSelectable={
                                                              isSelectable
                                                            }
                                                          >
                                                            <ImageWrapper
                                                              onClick={e =>
                                                                onModuleSelected(
                                                                  {
                                                                    event: e,
                                                                    subItem: subCatModuleItem,
                                                                    thumbnailLocation,
                                                                    moduleId: id
                                                                  }
                                                                )
                                                              }
                                                              isDisabled={
                                                                isDisabled
                                                              }
                                                            >
                                                              <Image
                                                                src={
                                                                  thumbnailLocation
                                                                }
                                                              />
                                                            </ImageWrapper>
                                                            <Title
                                                              onClick={() =>
                                                                onOpenCloseModulePreview(
                                                                  {
                                                                    catagoryModules:
                                                                      subCatChild.modules,
                                                                    Index: subCatModuleItemIndex
                                                                  }
                                                                )
                                                              }
                                                              isDisabled={
                                                                isDisabled
                                                              }
                                                            >
                                                              {highlightText(
                                                                title
                                                              )}
                                                            </Title>
                                                          </ThumbnailWrapper>
                                                        </>
                                                      )
                                                    : !!thumbnailLocation && (
                                                        <>
                                                          <ListItem
                                                            key={
                                                              subCatModuleItemIndex
                                                            }
                                                            isSelectable={
                                                              isSelectable
                                                            }
                                                            isDisabled={
                                                              isDisabled
                                                            }
                                                          >
                                                            <ModuleContainer
                                                              onClick={e =>
                                                                onModuleSelected(
                                                                  {
                                                                    event: e,
                                                                    subItem: subCatModuleItem,
                                                                    thumbnailLocation,
                                                                    moduleId: id
                                                                  }
                                                                )
                                                              }
                                                              isDisabled={
                                                                isDisabled
                                                              }
                                                            >
                                                              <StyledImageIcon />
                                                              <ItemTitle>
                                                                {highlightText(
                                                                  title
                                                                )}
                                                              </ItemTitle>
                                                            </ModuleContainer>
                                                            <PreviewIconWrapper
                                                              onClick={() =>
                                                                onOpenCloseModulePreview(
                                                                  {
                                                                    catagoryModules:
                                                                      subCatChild.modules,
                                                                    Index: subCatModuleItemIndex
                                                                  }
                                                                )
                                                              }
                                                            >
                                                              <PreviewBig
                                                                size={15}
                                                              />
                                                            </PreviewIconWrapper>
                                                          </ListItem>
                                                        </>
                                                      )}
                                                </ModuleBlock>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                  </StyledPanel>
                                </AccordionItem>
                              );
                            }
                          )}
                        </StyledAccordion>
                      );
                    })}
                </StyledPanel>
              </AccordionItem>
            );
          })}
        </StyledAccordion>

        {isSearchActive &&
          showAllModules &&
          !(filteredModules || []).length && <NoResult>No data found</NoResult>}

        {!showAllModules && !Object.keys(selectedPlaceholder).length && (
          <NoResult>
            Note: Please select the layout from the right to view the compatible
            module.
          </NoResult>
        )}
      </>
    );
  };

  render() {
    const {
      moduleList,
      onClickTab,
      selectedTabId,
      handleModuleSearchChange,
      selectedPlaceholder,
      isSearchActive,
      handleResetSearchInput,
      preExpandedCatArray,
      randomKey,
      searchAndLayoutSpecificModules,
      showAllModules,
      highlightText,
      hidePreviouslySelectedAccItem,
      selectedOption
    } = this.props;

    const isThumbnailView = selectedTabId === "thumbnails";
    const isSelectable = !!(
      selectedPlaceholder && Object.keys(selectedPlaceholder).length
    );

    return (
      <ModuleSelectorWrapper className="module-list-wrapper">
        <TabHeaderWrapper>
          <TabHeader
            data={TabList}
            manageStates={onClickTab}
            active={selectedTabId}
            width="auto"
          />
        </TabHeaderWrapper>
        <ScrollableItem>
          <SearchBoxWrapper className="module-search">
            <SearchBox
              handleChange={handleModuleSearchChange}
              float="initial"
              placeholder={"Search..."}
            />
            <ResetButton onClick={handleResetSearchInput}>Reset</ResetButton>
          </SearchBoxWrapper>
          {this._renderAccordian({
            moduleList,
            isThumbnailView,
            isSelectable,
            isSearchActive,
            preExpandedCatArray,
            randomKey,
            showAllModules,
            selectedPlaceholder,
            searchAndLayoutSpecificModules,
            highlightText,
            hidePreviouslySelectedAccItem,
            selectedOption
          })}
        </ScrollableItem>
      </ModuleSelectorWrapper>
    );
  }
}

const ModuleSelectorWrapper = styled.div`
  width: 303.5px;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;

  @media (max-width: 1200px) {
    width: 24.28%;
  }

  &.sticky-module-list {
    position: fixed;
    margin-right: 0;
    top: 80px;

    @media (max-width: 1200px) {
      width: 22.5%;
    }
  }

  .hide_accordion__item {
    display: none;
  }
`;

const TabHeaderWrapper = styled.div``;

const ScrollableItem = styled.div`
  height: calc(100vh - 435px);
  min-height: 490px;
  max-height: 560px;
  padding: 20px 18px 26px;
  overflow: auto;
  background-color: ${props => props.theme.COLOR.WHITE};
  border-radius: 3px;
  ${props => props.theme.SNIPPETS.BOX_SHADOW};
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 4px;
    outline: 1px solid slategrey;
  }
`;

const StyledHead = styled(AccordionItemHeading)`
  border: 0;
  cursor: pointer;
`;

const Title = styled.span`
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 3px;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 14px;
  color: ${props =>
    props.isDisabled
      ? hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)
      : hexToRgba(props.theme.COLOR.DEFAULT_USER_PRIMARY, 0.8)};
  opacity: ${props => (props.isDisabled ? 0.7 : 1)};
`;

const StyledButton = styled(AccordionItemButton)`
  min-height: 30px;
  outline: 0;
  padding: 6px 18% 7px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
  border-radius: 3px;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-weight: bold;
  font-size: 14px;
  cursor: inherit;
  background-color: ${props => props.theme.COLOR_PALLETE.LIGHT_BLUE};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.74)};
  user-select: none;
  &[aria-expanded="true"] {
    svg {
      transform: rotate(0);
    }
  }
`;

const SubCatStyledButton = styled(AccordionItemButton)`
  min-height: 30px;
  outline: 0;
  padding: 6px 18% 7px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 15px;
  border-radius: 3px;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-weight: bold;
  font-size: 14px;
  cursor: inherit;
  background-color: ${props => props.theme.COLOR_PALLETE.SLIGHT_GREY};
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.BROWNISH_GREY, 0.74)};
  user-select: none;
  &[aria-expanded="true"] {
    svg {
      transform: rotate(0);
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  border: 0;
`;

const StyledPanel = styled(AccordionItemPanel)`
  padding: 0 10px;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  border-radius: 4px;
  border: 2px dashed
    ${props => hexToRgba(props.theme.COLOR_PALLETE.LIGHT_GREY, "0.59")};
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const ThumbnailWrapper = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
`;

const StyledRightIcon = styled(Dropdown)`
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(-90deg);
  color: ${props => props.theme.COLOR.MAIN};
`;

const ListItem = styled.div`
  margin-bottom: 12px;
  padding: 0 35px 0 5px;
  position: relative;
  ${props => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemTitle = styled.span`
  width: calc(100% - 26px);
  vertical-align: bottom;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
  cursor: pointer;
  user-select: none;
`;

const StyledImageIcon = styled(FaFileImage)`
  padding-right: 5px;
  transform: translateY(2px);
  color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
`;

const PreviewIconWrapper = styled.span`
  position: absolute;
  opacity: 0.8;
  top: 2px;
  right: 5px;
  cursor: pointer;
`;

const SearchBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .search-wrapper {
    margin-bottom: 16px;
    flex-basis: 73%;
    min-width: 92px;
    svg {
      top: 2px;
    }
    input {
      min-width: auto;
      width: calc(100% - 45px);
      font-size: 12px;
      height: 30px;
    }
  }
`;

const ResetButton = styled.button`
  flex-basis: 22%;
  ${props => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  color: ${props => props.theme.COLOR.WHITE};
  background-color: ${props => props.theme.COLOR.USER_PRIMARY};
  &:hover { color: ${props =>
    props.theme.COLOR.USER_PRIMARY};  background-color: ${props =>
  props.theme.COLOR.WHITE}; border: 1px solid; }
  cursor: ${props => (!props.disabled ? "pointer" : "not-allowed")};
  min-width: 38px;
  height: 30px;
  font-size: 12px;
`;

const NoResult = styled.div`
  color: ${props => props.theme.COLOR_PALLETE.DARK_GREY};
  text-align: center;
  font-weight: bold;
`;

const ModuleBlock = styled.div`
  position: relative;
`;

const DisabledOverlay = styled.div`
  background-color: rgba(60, 52, 52, 0.5);
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 23px;
  left: 0;
  right: 0;
  pointer-events: none;
`;

const DisabledListItem = css`
  svg,
  span {
    color: ${props => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.6)};
    opacity: 1;
    cursor: not-allowed;
  }
`;

const ModuleContainer = styled.div`
  ${props => (props.isDisabled ? DisabledListItem : "")};
`;

export default ModuleSelector;
