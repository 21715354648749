import { SearchTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";
import { ConvertQueryParamsArrayObjectToString } from "utils/queryString";

export const onLibrarySearchSuccess = (
  payload = {},
  type = SearchTypes.SUCCESS_LIBRARY_SEARCH
) => {
  return {
    type,
    payload
  };
};

export const isLibraryTopicSearchLoading = (
  payload = {},
  type = SearchTypes.LOADING_LIBRARY_TOPIC_SEARCH
) => {
  return {
    type,
    payload
  };
};

const getLibraryBySearch = (
  contentRepoId,
  selectedTheme,
  { search, filters }
) => async dispatch => {
  const filtersList = filters.map(elem => ({ "filter[]": elem }));

  const params = ConvertQueryParamsArrayObjectToString([
    { search },
    {
      searchByTopic: true
    },
    ...filtersList
  ]);

  const URL = `/${contentRepoId}/${selectedTheme}/content-slides${
    params ? `?${params}` : ""
  }`;

  // start loading
  dispatch(isLibraryTopicSearchLoading({ isSearchLoading: true }));

  const response = await FetchUtils.getData(
    URL,
    "Get Libray By Search for Setup==>"
  );

  // start loading
  dispatch(isLibraryTopicSearchLoading({ isSearchLoading: false }));

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onLibrarySearchSuccess({
        libraryBySearchList: search ? response.data : []
      })
    );
  } else {
    // stop loading
    dispatch(isLibraryTopicSearchLoading({ isSearchLoading: false }));
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

// reset search result
const resetSearchResult = () => dispatch => {
  dispatch(
    onLibrarySearchSuccess({
      libraryBySearchList: []
    })
  );
};

export { getLibraryBySearch, resetSearchResult };
