import React, { Component } from "react";
import state, { UI_STRINGS } from "./state";
import { each, filter, get, throttle, map } from "lodash";
import ValidationUtils from "utils/ValidationUtils";
import ToastUtils from "utils/handleToast";
import {
  getPagelayouts,
  getUsersModules,
  createDocument,
  getDocument,
  triggerDocumentBuild,
  getPresentationBuildStatus,
  getLayoutMetadata
} from "./services";
import { connect } from "react-redux";
import { MarketingMaterialImages } from "assets/images";
import moment from "moment";
import PollingUtils from "utils/PollingUtils";
import DeleteConfirmationAlert from "components/DeleteConfirmationAlert";
/**
 * Map the state to props.
 */
const mapStateToProps = state => {
  const {
    LOADING_CREATE_DOCUMENT,
    FETCH_CREATE_DOCUMENT_SUCCESS,
    SUCCESS_USER_PROFILE
  } = state;
  return {
    ...LOADING_CREATE_DOCUMENT,
    ...FETCH_CREATE_DOCUMENT_SUCCESS,
    ...SUCCESS_USER_PROFILE
  };
};

const Container = Main =>
  connect(mapStateToProps, {
    getPagelayouts,
    getUsersModules,
    createDocument,
    getDocument,
    triggerDocumentBuild,
    getPresentationBuildStatus,
    getLayoutMetadata
  })(
    class Placemat extends Component {
      constructor(props) {
        super(props);
        this.state = { ...state };
      }

      componentDidMount() {
        this._clearInputFields();
        this._fetchPagelayoutsList();
        this._fetchModuleList();
        this.setUserProfile();

        // Add event listener to check back button
        window.addEventListener("beforeunload", this.checkBackButton);
      }

      componentWillUnmount() {
        window.removeEventListener("beforeunload", this.checkBackButton);
      }

      checkBackButton = e => {
        if (this.state.isDocumentEdited || this.state.isBuilding) {
          e.preventDefault();
          e.returnValue = UI_STRINGS.UNSAVED_PROMPT_ERROR;
        } else {
          return;
        }
      };

      // To clear input field each time on mount
      _clearInputFields = () => {
        let {
          buildSetupDetails,
          selectedModules,
          selectedDoctype,
          chooseContent
        } = this.state;
        buildSetupDetails = buildSetupDetails.map(elem => {
          elem.value = "";
          elem.error = "";
          elem.isEdited = false;
          return elem;
        });

        selectedModules = [];
        selectedDoctype = [];
        chooseContent = false;

        this.setState({
          buildSetupDetails,
          selectedModules,
          selectedDoctype,
          chooseContent
        });
      };

      /**
       * Get User Profile based on API respone and store in local state
       *
       */
      setUserProfile = () => {
        const { userProfileMeta, match } = this.props;
        userProfileMeta &&
          match.params.documentId &&
          this._fetchDocumentUpdateData(
            userProfileMeta._id,
            match.params.documentId
          );
      };

      componentDidUpdate(prevProps, prevState) {
        this._handleStickySidebar();

        if (
          this.state.isDataFilledOnEdit &&
          this.props.userProfileMeta &&
          Object.keys(this.state.templateTypes).length
        ) {
          this.setUserProfile();
          this.setState({
            isDataFilledOnEdit: !(
              this.props.userProfileMeta === prevProps.userProfileMeta
            )
          });
        }

        // build code
        if (prevState.showProgressModal !== this.state.showProgressModal) {
          // Only scroll on build
          this._scrollToBottom();
        }

        if (
          this.props.userProfileMeta &&
          this.props.userProfileMeta === prevProps.userProfileMeta &&
          !this.state.userId
        ) {
          this.setState({ userId: this.props.userProfileMeta._id });
        }

        // ckeck mark on tab title
        if (this.state.isDocumentEdited) {
          let { completedSteps, moduleEdited, pageLayoutEdited } = this.state,
            tempCompletedSteps = [];

          if (pageLayoutEdited) {
            tempCompletedSteps = [];
          } else if (moduleEdited && !pageLayoutEdited) {
            tempCompletedSteps = [0];
          } else if (!moduleEdited && !pageLayoutEdited) {
            tempCompletedSteps = completedSteps.filter(ele => ele !== 0);
          }

          if (
            JSON.stringify(this.state.completedSteps) !==
            JSON.stringify(tempCompletedSteps)
          ) {
            this.setState({ completedSteps: tempCompletedSteps });
          }
        }

        // search variables set to empty on tab change
        if (
          (prevState.activeStep !== this.state.activeStep ||
            prevState.chooseContent !== this.state.chooseContent) &&
          this.state.searchValue !== ""
        ) {
          this.handleChooseContentSearchChange({ isReset: true });
          this.handleResetSearchInput();
        }
      }

      // build UI functions starts
      /**
       * Update the radio button value on click
       * @param {String} Id of the radio button selected
       */
      changeHandler = id => {
        this.setState({ buildValue: id });
      };

      /**
       * Function to scroll to bottom when build is in progress
       */
      _scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
      };

      /**
       * Handle when the download is click
       */
      downloadHandler = () => {
        let { buildValue } = this.state,
          { documentData } = this.props;
        // Get urls
        let pptUrl = get(documentData, "pptLocation.url");
        let pdfUrl = get(documentData, "pdfLocation.url");
        let zipUrl = get(documentData, "zipLocation.url");

        if (buildValue === "ppt" && pptUrl) {
          document.location.href = pptUrl;
        } else if (buildValue === "pdf" && pdfUrl) {
          document.location.href = pdfUrl;
        } else if (buildValue === "zip" && zipUrl) {
          document.location.href = zipUrl;
        }
      };

      /**
       * Handle when the edit is click
       */
      editHandler = () => {
        this.setState({ activeStep: 0, buildValue: "ppt" });
      };

      /**
       *Check completed builds
       *
       * @param {*} buildType Type of build
       * @returns Boolean value
       */
      checkIfCompleted = buildType => {
        let { buildValue } = this.state,
          { documentData } = this.props,
          type = buildType || buildValue;
        // Get urls
        let pptUrl = get(documentData, "pptLocation.url"),
          pdfUrl = get(documentData, "pdfLocation.url"),
          zipUrl = get(documentData, "zipLocation.url");

        if (type === "ppt" && pptUrl) return true;
        else if (type === "pdf" && pdfUrl) return true;
        else if (type === "zip" && zipUrl) return true;
        else return false;
      };

      //Go to next step on build Click
      documentBuildHandler = () => {
        let { buildValue } = this.state;

        this.onBuild({ buildOption: buildValue });
      };

      /**
       * Get the build progres by looping object keys of the completed steps in response.
       *
       * @param {*} response Build response object
       * @returns
       */
      getCompletedPercentage = response => {
        let { buildProgress } = this.state,
          progress = { ...buildProgress };

        if (response.status === "Failed") {
          progress = {
            percentage: 100,
            status: "Failed"
          };
        } else if (response.status === "Completed") {
          progress = {
            percentage: 100,
            status: "Completed"
          };
        } else {
          // Iterate percentage
          let percentage = buildProgress.percentage
            ? buildProgress.percentage + 10
            : 10;
          if (percentage < 100) {
            progress = {
              percentage,
              status: "InProgress"
            };
          }
        }
        return progress;
      };

      /**
       *The polling action for document build
       *
       * @param {*} id
       */
      pollingActionForBuild = async id => {
        const { userId, documentId } = this.state;
        // document Id of the current document
        let response = await this.props.getPresentationBuildStatus(id),
          pollingResponseStatus = get(response, "data.status"),
          responseData = get(response, "data") || {},
          // Get progress data for show progress bar
          progressData = this.getCompletedPercentage(responseData),
          completedSteps = [];

        this.setState({
          buildProgress: progressData
        });

        if (
          responseData &&
          pollingResponseStatus === "Completed" &&
          progressData.status !== "Failed"
        ) {
          completedSteps = [0, 1, 2];
          PollingUtils.stopPolling();
          // Get document data

          this.setState({
            isBuilding: false,
            pptLocation: responseData,
            buildProgress: progressData,
            showProgressModal: false,
            completedSteps
          });

          await this.props.getDocument(userId, documentId);
        } else if (
          pollingResponseStatus === "Failed" ||
          progressData.status === "Failed"
        ) {
          ToastUtils.handleToast({
            operation: "error",
            message: UI_STRINGS.BUILD_ERROR,
            autoclose: false
          });
          PollingUtils.stopPolling();
          this.setState({
            isBuilding: false,
            buildProgress: progressData
          });
        }
      };

      /**
       *Function for starting polling for build
       *
       * @param {*} buildId
       */
      startBuildPolling = buildId => {
        // Get timeout duration each slide takes approx 4s
        let timeoutDuration = 100000;

        PollingUtils.startPolling({
          pollingAction: () => {
            this.pollingActionForBuild(buildId);
          },
          timeoutCallback: () => {
            this.setState({
              isBuilding: false,
              buildProgress: {
                status: "Failed",
                percentage: 100
              }
            });
            ToastUtils.handleToast({
              operation: "error",
              message: UI_STRINGS.BUILD_ERROR,
              autoclose: false
            });
          },
          timeoutDuration
        });
      };

      /**
       *Start Build process
       *
       * @param {*} { buildOption } The type of build selected ppt/pdf/zip
       */
      onBuild = async ({ buildOption: contentType }) => {
        let { userId, documentId } = this.state;

        if (documentId) {
          this.setState({
            buildProgress: {
              status: "InProgress",
              percentage: 0
            },
            isBuilding: true,
            showProgressModal: true
          });

          //trigger document build api
          let response = await this.props.triggerDocumentBuild(
            userId,
            documentId,
            contentType
          );

          let { buildId } = response.data;
          // on successfully triggering build
          if (buildId) {
            this.startBuildPolling(buildId);
          } else {
            this.setState({
              isBuilding: false
            });
            // Show error message popup
            ToastUtils.handleToast({
              operation: "error",
              message: get(response, "data.message", false)
            });
          }
        } else {
          this.setState({
            isBuilding: false
          });
          // Show error message popup
          ToastUtils.handleToast({
            operation: "error",
            message: UI_STRINGS.UNSAVED_BUILD_ERROR
          });
        }
      };

      // build UI functions ends

      _fetchDocumentUpdateData = async (userId, documentId) => {
        this.setState({ documentId });
        await this.props.getDocument(userId, documentId);

        this.props.documentData &&
          this.editDocumentHandler(this.props.documentData || {});
      };
      /**
       * handle get document data
       * @param {Array} documentData document data from api
       */
      editDocumentHandler = documentData => {
        let { buildSetupDetails, selectedOption, templateTypes } = this.state,
          selectedTemplateTypes = documentData.hasOwnProperty("documentType")
            ? templateTypes[documentData.documentType.title.toLowerCase()]
            : {},
          completedSteps = [],
          validModuleDataForBuild = true,
          pptUrl = get(documentData, "pptLocation.url"),
          pdfUrl = get(documentData, "pdfLocation.url"),
          zipUrl = get(documentData, "zipLocation.url"),
          stepOneCompleted = false;

        const {
          projectName,
          clientName,
          documentTitle,
          previouslySelected
        } = documentData;

        buildSetupDetails[0].value = projectName;
        buildSetupDetails[1].value = clientName;
        buildSetupDetails[2].value = documentTitle || "";

        if (documentData.hasOwnProperty("documentType")) {
          selectedOption = {
            id: documentData.documentType._id,
            label: documentData.documentType.title,
            value: documentData.documentType.title
          };
        }

        Object.keys(selectedTemplateTypes || {}).forEach(item => {
          if (Array.isArray(selectedTemplateTypes[item].value)) {
            selectedTemplateTypes[item].value = selectedTemplateTypes[
              item
            ].value.map(valueItem => {
              documentData.modules.forEach(eachModule => {
                if (
                  eachModule.pageLayout.layoutType ===
                    selectedTemplateTypes[item].label &&
                  eachModule.pageLayout._id === valueItem.id
                ) {
                  valueItem.active = true;
                }
              });

              if (
                valueItem.title === "No Back" &&
                (valueItem.category === "placemat" ||
                  valueItem.category === "flier") &&
                documentData.modules.length === 1
              ) {
                valueItem.active = true;
              }

              return valueItem;
            });
          }

          return selectedTemplateTypes[item];
        });

        const tempLayoutData = this.handleCreateLayoutData(
          selectedTemplateTypes
        );

        const injectThumbnailSrc = (imageMatrix, thumbnails) => {
          let uploadedmodule = thumbnails.filter(
            ele => ele.imageMatrix === imageMatrix
          );

          return uploadedmodule.length ? uploadedmodule[0].location : null;
        };

        documentData.modules.forEach(eachModule => {
          const pageLaoutId = eachModule.pageLayout._id;
          tempLayoutData.forEach(layout => {
            if (layout.id === pageLaoutId && eachModule.module.length > 0) {
              eachModule.module.forEach((item, i) => {
                if (item.moduleId) {
                  let imageMatrix =
                    layout.imageMatrix === "quadrant" &&
                    ((layout.selectedLayout === "2 Rows 1 Col" && i === 1) ||
                      (layout.selectedLayout === "1 Col 2 Rows" && i === 0))
                      ? "halfpage"
                      : layout.imageMatrix;

                  let orderNo = i;

                  if (layout.selectedLayout === "2 Rows 1 Col") {
                    orderNo = i === 1 ? 2 : i === 2 ? 1 : i;
                  } else if (layout.selectedLayout === "4 Quad") {
                    orderNo = i === 1 ? 2 : i === 2 ? 3 : i === 3 ? 1 : i;
                  }
                  layout.placeholder[orderNo].moduleId = item.moduleId._id;
                  layout.placeholder[orderNo].deleted = item.moduleId.deleted;
                  layout.placeholder[orderNo].enabled = item.moduleId.enable;
                  layout.placeholder[orderNo].thumbnailSrc = injectThumbnailSrc(
                    imageMatrix,
                    item.moduleId.thumbnails
                  );
                }
              });
            }
          });
        });

        tempLayoutData.some((layout, i) => {
          let tempBreak = false;
          if (layout.selectedLayout !== "No Back") {
            each(layout.placeholder, thumbnail => {
              if (!thumbnail.thumbnailSrc) {
                validModuleDataForBuild = false;
                tempBreak = true;
              }
            });
          }
          if (tempBreak) return true;
          else return false;
        });

        if (
          Object.keys(selectedTemplateTypes || {}).length &&
          Object.keys(selectedTemplateTypes || {}).length ===
            tempLayoutData.length
        ) {
          stepOneCompleted = true;
        } else {
          stepOneCompleted = false;
          validModuleDataForBuild = false;
        }

        completedSteps =
          (pptUrl || pdfUrl || zipUrl) && validModuleDataForBuild
            ? [0, 1, 2]
            : !(pptUrl || pdfUrl || zipUrl) && validModuleDataForBuild
            ? [0, 1]
            : stepOneCompleted
            ? [0]
            : [];

        this.handlePreviouslySelectedModuleData(previouslySelected || []);

        this.setState({
          buildSetupDetails,
          selectedOption,
          layoutData: tempLayoutData,
          completedSteps,
          isDocumentEditFlow: true,
          isLayoutTemplateSelected: true,
          selectedLayoutTemplates: tempLayoutData
        });
      };

      // update choose content UI as per previously selected module on document edit
      handlePreviouslySelectedModuleData = previouslySelected => {
        let selectedModules = [],
          selectedDoctype = [],
          { moduleList } = this.state;

        each(previouslySelected, prevItem => {
          let docTypes = {
            id: "",
            documents: [
              { title: "Placemat", active: false },
              { title: "Flier", active: false },
              { title: "Brochure", active: false }
            ]
          };

          each(moduleList, listItem => {
            each(listItem.children[0].modules, module => {
              if (module.id === prevItem) {
                selectedModules.push(module);

                docTypes.id = listItem._id;
                each(listItem.documentTypes, documentType => {
                  each(docTypes.documents, item => {
                    if (item.title === documentType.title) {
                      item.active = true;
                    }
                  });
                });

                selectedDoctype.push(docTypes);
              }
            });

            each(listItem.children[1].subCategories, subCategory => {
              each(subCategory.children[0].modules, subModule => {
                if (subModule.id === prevItem) {
                  selectedModules.push(subModule);

                  docTypes.id = subCategory._id;
                  each(subCategory.documentTypes, documentType => {
                    each(docTypes.documents, item => {
                      if (item.title === documentType.title) {
                        item.active = true;
                      }
                    });
                  });

                  selectedDoctype.push(docTypes);
                }
              });
            });
          });
        });

        if (selectedModules.length && selectedDoctype.length) {
          this.setState(
            {
              selectedModules,
              selectedDoctype,
              isChooseModulesEdited: true,
              preExapandedFlag: true
            },
            () => this.handleCompatibleDocumentType(false)
          );
        }
      };

      // Function to make sticky module list in layout UI
      _handleStickySidebar = () => {
        if (this.state.activeStep === 1) {
          let moduleListWrapper = document.querySelector(
            ".module-list-wrapper"
          );

          window.addEventListener("scroll", () => {
            if (window.scrollY > 240) {
              moduleListWrapper.classList.add("sticky-module-list");
            } else {
              moduleListWrapper.classList.remove("sticky-module-list");
            }
          });
        }
      };

      /**
       * module list service call and data mapping
       */
      _fetchModuleList = async () => {
        await this.props.getUsersModules({ term: "" });
        this.props.usersModules &&
          this.moduleListDataFormating(this.props.usersModules, false);
      };

      moduleListDataFormating = (moduleListData, isPreExpanded) => {
        let moduleList = [],
          randomKey = Math.random();
        moduleListData.forEach(
          ({ name, label, children, documentTypes, _id }) => {
            let catItem = this.catItemsHandler(
              name,
              label,
              children,
              documentTypes,
              _id
            );
            moduleList.push(catItem);
          }
        );
        this.setState({
          moduleList,
          chooseContentData: moduleList,
          preExapandedFlag: isPreExpanded,
          randomKey
        });
      };

      catItemsHandler = (name, label, children, documentTypes, _id) => {
        return {
          label,
          title: name,
          children: this.catagoryChildrenHandler(children),
          documentTypes,
          _id
        };
      };

      catagoryChildrenHandler = children => {
        let moduleList = children.filter(item => item.label === "module");
        let subcategoryList = children.filter(
          item => item.label === "subcategory"
        );

        let catChildrens = [];
        let modules = this.modulesHandler(moduleList);
        let subCategories = this.subCategoriesHandler(subcategoryList);
        catChildrens.push({ modules }, { subCategories });
        return catChildrens;
      };

      modulesHandler = moduleList => {
        return moduleList.map(
          ({
            name,
            label,
            thumbnails,
            _id,
            author,
            description,
            moduleAssets,
            createdAt,
            updatedAt
          }) => ({
            label,
            title: name,
            id: _id,
            thumbnails: this.thumbnailsHandler(thumbnails),
            author,
            description,
            fileName: moduleAssets,
            createdAt,
            updatedAt
          })
        );
      };

      thumbnailsHandler = thumbnails => {
        return thumbnails.map(({ imageMatrix, location }) => ({
          location: location,
          imageMatrix: imageMatrix.toLowerCase()
        }));
      };

      subCategoriesHandler = subcategoryList => {
        return subcategoryList.map(
          ({ name, label, children, documentTypes, _id }) => ({
            label,
            title: name,
            children: this.subCatagoryModuleHandler(children),
            documentTypes,
            _id
          })
        );
      };

      subCatagoryModuleHandler = subCatmodules => {
        let subCatChildrens = [];
        let modules = this.modulesHandler(subCatmodules);
        subCatChildrens.push({ modules });
        return subCatChildrens;
      };

      /**
       * Function to get the pagelayout list and data mapping
       */
      _fetchPagelayoutsList = async () => {
        await this.props.getPagelayouts();
        this.props.pagelayoutList &&
          this.templateTypesDataFormatting(this.props.pagelayoutList);
      };

      templateTypesDataFormatting = pagelayoutList => {
        let templateTypes = {};
        let documentTypeOption = [];
        Array.isArray(pagelayoutList) &&
          pagelayoutList.forEach(({ title, pageLayouts, _id }) => {
            // dropdown value data formating
            let docType = { value: title, label: title, id: _id };
            documentTypeOption.push(docType);

            // choose content data formating
            let newTitle =
              title && typeof title === "string" && title.toLowerCase();
            templateTypes[newTitle] = {};

            pageLayouts.forEach(layouts => {
              switch (layouts.layoutType) {
                case "Front Cover":
                case "Front Side":
                  if (!templateTypes[newTitle].hasOwnProperty("frontcover")) {
                    templateTypes[newTitle]["frontcover"] = {
                      label: layouts.layoutType,
                      value: []
                    };
                  }

                  let frontCoverVal = this.valueObjectHandler(
                    layouts,
                    newTitle
                  );

                  templateTypes[newTitle]["frontcover"].value.push(
                    frontCoverVal
                  );

                  break;
                case "Back Side":
                case "Back Cover":
                  if (!templateTypes[newTitle].hasOwnProperty("backcover")) {
                    templateTypes[newTitle]["backcover"] = {
                      label: layouts.layoutType,
                      value: []
                    };
                  }

                  let backCoverVal = this.valueObjectHandler(layouts, newTitle);

                  templateTypes[newTitle]["backcover"].value.push(backCoverVal);
                  break;
                case "Inside Spread":
                  if (!templateTypes[newTitle].hasOwnProperty("insidespread")) {
                    templateTypes[newTitle]["insidespread"] = {
                      label: layouts.layoutType,
                      value: []
                    };
                  }

                  let insideSpreadVal = this.valueObjectHandler(
                    layouts,
                    newTitle
                  );

                  templateTypes[newTitle]["insidespread"].value.push(
                    insideSpreadVal
                  );
                  break;
                default:
                  console.log("default");
              }
            });

            let flierOrplacemat =
              newTitle === "flier" || newTitle === "placemat";

            if (flierOrplacemat) {
              let noBack = {
                title: "No Back",
                src:
                  newTitle === "flier"
                    ? MarketingMaterialImages.NoBack
                    : MarketingMaterialImages.NoBackDouble,
                active: false,
                category: newTitle,
                view: false
              };
              templateTypes[newTitle]["backcover"].value.push(noBack);
            }
          });

        this.setState({ templateTypes, documentTypeOption });
      };

      valueObjectHandler = (layouts, newTitle) => {
        let title, src, previewSrc, classString;

        if (
          layouts.layoutDimension === "17 * 11" &&
          layouts.layoutMatrix === "Full Page"
        ) {
          title = "Wide Single";
          src = MarketingMaterialImages.WideSingle;
          previewSrc = MarketingMaterialImages.TemplateWideSingle;
          classString = "wide-single";
        } else if (layouts.layoutMatrix === "2 Rows") {
          title = layouts.layoutMatrix;
          src = MarketingMaterialImages.RowDouble;
          previewSrc = MarketingMaterialImages.TemplateRowDouble;
          classString = "row-double";
        } else if (layouts.layoutMatrix === "2 Col") {
          title = layouts.layoutMatrix;
          src = MarketingMaterialImages.ColDouble;
          previewSrc = MarketingMaterialImages.TemplateColDouble;
          classString = "col-double";
        } else if (layouts.layoutMatrix === "2 Rows 1 Col") {
          title = layouts.layoutMatrix;
          src = MarketingMaterialImages.RowFirstTri;
          previewSrc = MarketingMaterialImages.TemplateRowFirstTri;
          classString = "row-first-tri";
        } else if (layouts.layoutMatrix === "1 Col 2 Rows") {
          title = layouts.layoutMatrix;
          src = MarketingMaterialImages.ColFirstTri;
          previewSrc = MarketingMaterialImages.TemplateColFirstTri;
          classString = "col-first-tri";
        } else if (layouts.layoutMatrix === "4 Quad") {
          title = layouts.layoutMatrix;
          src = MarketingMaterialImages.Quad;
          previewSrc = MarketingMaterialImages.TemplateQuad;
          classString = "quad";
        } else {
          title = layouts.layoutMatrix;
          src = MarketingMaterialImages.Single;
          previewSrc = MarketingMaterialImages.TemplateSingle;
          classString = "single";
        }

        let val = {
          title,
          src,
          active:
            newTitle === "brochure" && layouts.layoutType === "Front Cover"
              ? true
              : false,
          category: newTitle,
          view: false,
          previewSrc,
          classString,
          layoutDimension: layouts.layoutDimension,
          id: layouts._id,
          imageMatrix: layouts.imageMatrix.toLowerCase()
        };

        return val;
      };

      // step handler
      nextStepHandler = stepToSetActive => {
        // if (stepToSetActive === 1 || stepToSetActive === 2) {
        if (!this.state.selectedOption) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please choose document type.",
            autoClose: 3000
          });
        } else {
          this.handleMainButtonClick(true, stepToSetActive);
          this.setState({
            tempStepToSetActive: stepToSetActive,
            tabClicked: true,
            buildValue: "ppt"
          });
        }
      };

      modifyStep = activeStep => {
        this.setState({ activeStep });
      };

      // input change handler
      onChangeInput = ({ value, label, key }) => {
        const buildSetupDetails = [...this.state.buildSetupDetails];
        each(buildSetupDetails, item => {
          if (item.label === label && item.editable) {
            item.isEdited = !!this.state.documentId;
            item.value = value;
            item.error = this.handleSetupDetailsValidation(
              item.value,
              item.required
            );
            this.handleSetupDataChange(key, item.value, item.error);
          }
        });

        this.setState({
          buildSetupDetails,
          isDocumentEdited: true
        });
      };

      handleSetupDetailsValidation = (value, required) => {
        if (required && ValidationUtils.checkIfEmptyField(value)) {
          return UI_STRINGS.EMPTY_FIELD_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfspecialChar(value)) {
          return UI_STRINGS.SPECIAL_CHAR_ERROR_MESSAGE;
        } else if (ValidationUtils.checkIfWhiteSpace(value)) {
          return UI_STRINGS.WHITE_SPACE_ERROR_MESSAGE;
        }
      };

      /**
       * handle setup textbox datachange
       * @param {String} label key name
       * @param {String} value value of the text field
       * @param {String} error error when validation fails
       */
      handleSetupDataChange = (label, value = "", error = "") => {
        let { buildSetupDetails } = this.state;
        buildSetupDetails[label] = buildSetupDetails[label]
          ? buildSetupDetails[label]
          : {};
        buildSetupDetails[label].value = value;
        buildSetupDetails[label].error = error;
        this.setState({
          buildSetupDetails
        });
      };

      // dropdown change handler
      handleChange = selectedOption => {
        let { matchModules, isDocumentEditFlow } = this.state,
          hidePreviouslySelectedAccItem = false;

        if (
          (!isDocumentEditFlow &&
            this.state.documentId &&
            this.state.selectedOption &&
            this.state.selectedOption.value !== selectedOption.value) ||
          (isDocumentEditFlow &&
            this.props.documentData.documentType &&
            this.state.selectedOption.value !== selectedOption.value)
        ) {
          DeleteConfirmationAlert({
            message: UI_STRINGS.DOCUMENT_TYPE_CHANGE_MESSAGE,
            onYesClick: () => {
              this.handleTemplateTypeDataOnDocTypeChange(
                this.state.selectedOption.value
              );

              this._clearSelectedPlaceholder();

              if (this.state.moduleList[0].title === "Previously Selected…") {
                hidePreviouslySelectedAccItem =
                  matchModules.indexOf(`Select ${selectedOption.value}`) < 0;
              }

              this.setState({
                selectedOption,
                isDocumentTypeEdited: true,
                isDocumentEdited: true,
                layoutData: [],
                completedSteps: [],
                hidePreviouslySelectedAccItem
              });
            }
          });
        } else {
          this.setState((state, props) => ({
            selectedOption,
            isDocumentTypeEdited: !(
              state.selectedOption &&
              state.selectedOption.value === selectedOption.value
            ),
            isDocumentEdited: !(
              state.selectedOption &&
              state.selectedOption.value === selectedOption.value
            )
          }));

          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        }
      };

      handleTemplateTypeDataOnDocTypeChange = prevLayoutType => {
        let { templateTypes } = this.state;
        let selectedLayoutOptions = templateTypes[prevLayoutType.toLowerCase()];

        each(Object.keys(selectedLayoutOptions), item => {
          each(selectedLayoutOptions[item].value, val => {
            val.active = !!(
              val.category === "brochure" && item === "frontcover"
            );
          });
        });

        templateTypes[prevLayoutType.toLowerCase()] = selectedLayoutOptions;
        this.setState({ templateTypes });
      };

      // get start click
      handleMainButtonClick = (isNextCta, goToTabIndex) => {
        const buildSetupDetails = [...this.state.buildSetupDetails];
        each(buildSetupDetails, item => {
          item.error = this.handleSetupDetailsValidation(
            item.value,
            item.required
          );
        });

        this.setState({ buildSetupDetails });
        //check for error
        let errorStatus = buildSetupDetails.filter(item => item.error);

        if (errorStatus.length) {
          ToastUtils.handleToast({
            operation: "error",
            message: "Please fill all the required fields.",
            autoClose: 3000
          });
          return;
        } else if (!isNextCta) {
          this.handleCreateDocument(isNextCta, goToTabIndex);
        } else if (isNextCta) {
          this.createLayoutData(isNextCta, goToTabIndex);
        }

        if (
          goToTabIndex === 1 &&
          !errorStatus.length &&
          this.state.isLayoutTemplateSelected
        ) {
          this.layoutMetadataHandler();

          this.setState({
            isLayoutTemplateSelected: false
          });
        }
      };

      // get the thumbnail images of all the layouts that are selected
      layoutMetadataHandler = () => {
        let { selectedLayoutTemplates } = this.state;
        let selectedLayoutIds = [];

        selectedLayoutIds = map(selectedLayoutTemplates, eachLayoutTemplate => {
          return get(eachLayoutTemplate, "id");
        });

        this.props.getLayoutMetadata(selectedLayoutIds);
      };

      // create document functionality starts
      createLayoutData = async (isNextCta, goToTabIndex) => {
        this.handleTabChange(isNextCta, goToTabIndex);
      };

      handleTabChange = (isNextCta, goToTabIndex) => {
        const {
          layoutData,
          templateTypes,
          selectedOption,
          isDocumentTypeEdited
        } = this.state;

        let selectedLayoutOptions =
          templateTypes[selectedOption.value.toLowerCase()];

        if (
          layoutData.length < Object.keys(selectedLayoutOptions).length ||
          isDocumentTypeEdited
        ) {
          const layoutData = this.handleCreateLayoutData(selectedLayoutOptions);

          if (Object.keys(selectedLayoutOptions).length === layoutData.length) {
            this.setState({ layoutData }, () =>
              this.handleCreateDocument(isNextCta, goToTabIndex)
            );
          } else if (!isNextCta) {
            this.handleCreateDocument(isNextCta, goToTabIndex);
          } else {
            ToastUtils.handleToast({
              operation: "error",
              message: "Please choose template.",
              autoClose: 3000
            });
          }
        } else {
          this.handleCreateDocument(isNextCta, goToTabIndex);
        }
        this.setState({
          isChangeTemplateClicked: false
        });
      };

      // create document service call
      handleCreateDocument = async (isNextCta, goToTabIndex) => {
        const {
          buildSetupDetails,
          selectedOption,
          clientLogoImageData,
          isDocumentEdited,
          documentId,
          layoutData,
          pageLayoutEdited,
          moduleEdited,
          userId,
          isDocumentTypeEdited,
          templateTypes,
          selectedModules,
          isModuleChanged,
          moduleList
        } = this.state;
        let body = {},
          modules = [],
          validModuleDataForSave = true,
          selectedLayoutData = layoutData,
          validModuleDataForBuild = true,
          stepOneCompleted = false,
          completedSteps = [],
          previouslySelected = [];

        if (!moduleList.length) {
          ToastUtils.handleToast({
            operation: "error",
            message: "No modules present to create document",
            autoClose: 3000
          });
        }

        // set previously selected data
        each(selectedModules, prevMod => {
          previouslySelected.push(prevMod.id);
        });

        //set modules data
        each(selectedLayoutData, item => {
          let moduleIdArr = [],
            moduleArr = [];

          each(item.placeholder, (placeholder, i) => {
            if (placeholder.moduleId !== "") {
              let imageMatrix =
                item.imageMatrix === "quadrant" &&
                ((item.selectedLayout === "2 Rows 1 Col" && i === 2) ||
                  (item.selectedLayout === "1 Col 2 Rows" && i === 0))
                  ? "halfpage"
                  : item.imageMatrix;

              let orderNo = i;

              if (item.selectedLayout === "2 Rows 1 Col") {
                orderNo = i === 1 ? 2 : i === 2 ? 1 : i;
              } else if (item.selectedLayout === "4 Quad") {
                orderNo = i === 1 ? 3 : i === 2 ? 1 : i === 3 ? 2 : i;
              }

              moduleIdArr[orderNo] = {
                moduleId: placeholder.moduleId,
                imageMatrix
              };
            }
          });

          if (
            moduleIdArr.length === 0 ||
            item.placeholder.length === moduleIdArr.length
          ) {
            moduleArr = moduleIdArr;
          } else if (this.state.activeStep !== 0) {
            goToTabIndex = 1;
            ToastUtils.handleToast({
              operation: "error",
              message:
                "Please select a module for all regions in the template prior to saving.",
              autoClose: 3000
            });

            validModuleDataForSave = false;
          }
          if (this.state.isChangeTemplateClicked) {
            moduleArr = [];
          }

          let moduleObj = {
            pageLayout: item.id,
            ...(moduleArr.length > 0 && {
              module: moduleArr
            })
          };

          if (item.selectedLayout !== "No Back") {
            modules.push(moduleObj);
          }
        });

        // build validation
        layoutData.some((layout, i) => {
          let tempBreak = false;
          if (layout.selectedLayout !== "No Back") {
            each(layout.placeholder, thumbnail => {
              if (!thumbnail.thumbnailSrc) {
                validModuleDataForBuild = false;
                tempBreak = true;
              }
            });
          }
          if (tempBreak) return true;
          else return false;
        });

        if (
          !validModuleDataForBuild &&
          goToTabIndex === 2 &&
          validModuleDataForSave
        ) {
          goToTabIndex = 1;
          if (!isDocumentEdited) {
            ToastUtils.handleToast({
              operation: "error",
              message:
                "All modules must be filled before you can build your document.",
              autoclose: false,
              ref: this.toastError
            });
          }
        }

        let selectedLayoutOptions = selectedOption
          ? templateTypes[selectedOption.value.toLowerCase()]
          : {};

        if (
          Object.keys(selectedLayoutOptions).length &&
          Object.keys(selectedLayoutOptions).length === layoutData.length
        ) {
          stepOneCompleted = true;
        } else {
          stepOneCompleted = false;
        }

        // complete step handler
        if (!documentId) {
          completedSteps = stepOneCompleted ? [0] : [];
        } else if (documentId && !this.state.isDocumentEditFlow) {
          completedSteps = !stepOneCompleted
            ? []
            : validModuleDataForSave && !validModuleDataForBuild
            ? [0]
            : [0, 1];
        } else if (documentId && this.state.isDocumentEditFlow) {
          completedSteps = !stepOneCompleted
            ? []
            : pageLayoutEdited || !validModuleDataForBuild
            ? [0]
            : [0, 1];
        }
        body = !documentId
          ? {
              projectName: buildSetupDetails[0].value.trim(),
              clientName: buildSetupDetails[1].value.trim(),
              ...(clientLogoImageData.dataUri && {
                imageData: clientLogoImageData.dataUri
              }),
              ...(buildSetupDetails[2].value && {
                documentTitle: buildSetupDetails[2].value.trim()
              }),
              ...(stepOneCompleted && { modules }),
              ...(isDocumentTypeEdited && {
                documentType: selectedOption && selectedOption.id
              })
            }
          : {
              ...(buildSetupDetails[0].isEdited && {
                projectName: buildSetupDetails[0].value.trim()
              }),
              ...(buildSetupDetails[1].isEdited && {
                clientName: buildSetupDetails[1].value.trim()
              }),

              ...(clientLogoImageData.dataUri &&
                clientLogoImageData.isEdited && {
                  imageData: clientLogoImageData.dataUri
                }),
              ...(buildSetupDetails[2].value &&
                buildSetupDetails[2].isEdited && {
                  documentTitle: buildSetupDetails[2].value.trim()
                }),
              ...((pageLayoutEdited || moduleEdited) && { modules }),
              ...(isDocumentTypeEdited && {
                documentType: selectedOption && selectedOption.id
              }),
              ...(isModuleChanged && { previouslySelected })
            };
        moduleList.length && this.handleAddPreviouslySelectedModule();

        const response =
          Object.keys(body).length && isDocumentEdited && validModuleDataForSave
            ? await this.props.createDocument(body, userId, documentId)
            : null;
        if (response && response.success && response.data) {
          let buildSetupDetails = [],
            clientLogoImageData = { ...this.state.clientLogoImageData };

          buildSetupDetails = this.state.buildSetupDetails.map(item => {
            item.isEdited = false;
            return item;
          });

          clientLogoImageData.isEdited = false;

          this.setState({
            documentId: response.data._id,
            isDocumentEdited: false,
            originalBodyObject: response.data,
            buildSetupDetails,
            clientLogoImageData,
            pageLayoutEdited: false,
            moduleEdited: false,
            completedSteps,
            isDocumentTypeEdited: false,
            chooseContent: !!(!isNextCta && goToTabIndex === 0),
            isModuleChanged: false
          });
          if (isNextCta || (!isNextCta && goToTabIndex === 0)) {
            this.setState({
              activeStep: goToTabIndex,
              tabClicked: false
            });
          } else {
            const successMessage = "Document saved successfully.";

            // Show success message on successfull category creation
            ToastUtils.handleToast({
              operation: "success",
              message: successMessage
            });
          }
        }

        if (isNextCta && documentId && !isDocumentEdited) {
          this.setState({
            activeStep: goToTabIndex,
            tabClicked: false,
            chooseContent: false
          });
        } else if (
          !isNextCta &&
          !goToTabIndex &&
          documentId &&
          !isDocumentEdited
        ) {
          this.setState({
            chooseContent: true
          });
        }
      };

      // add previously selected modules to module list for choose content flow
      handleAddPreviouslySelectedModule = () => {
        let state = JSON.parse(JSON.stringify(this.state)),
          { selectedModules, moduleList = [] } = state;

        if (
          selectedModules.length &&
          moduleList[0].title !== "Previously Selected…"
        ) {
          let selectedModuleCatagory = {
            label: "category",
            title: "Previously Selected…",
            children: [{ modules: selectedModules }, { subCategories: [] }]
          };

          moduleList.unshift(selectedModuleCatagory);
        } else if (
          selectedModules.length &&
          moduleList[0].title === "Previously Selected…"
        ) {
          moduleList[0].children[0].modules = selectedModules;
        } else if (
          !selectedModules.length &&
          moduleList[0].title === "Previously Selected…"
        ) {
          moduleList.shift();
        }

        this.setState({ moduleList });
      };

      // on template selection
      handleTemplateClick = (i, tempCat, subCat) => {
        let templateTypes = { ...this.state.templateTypes };
        each(templateTypes[tempCat][subCat].value, item => {
          item.active = false;
        });

        templateTypes[tempCat][subCat].value[i].active = true;

        this.setState({ templateTypes });

        let selectedLayoutOptions = this.state.templateTypes[
          this.state.selectedOption.value.toLowerCase()
        ];

        let layoutDataTemp = this.handleCreateLayoutData(selectedLayoutOptions);
        this.setState({
          selectedLayoutTemplates: layoutDataTemp,
          isLayoutTemplateSelected: true
        });

        // on edit change layoutData
        if (this.state.documentId || this.state.selectedModules.length) {
          let layoutData = layoutDataTemp.map((ele, index) => {
            if (
              this.state.layoutData[index] !== undefined &&
              ele.id === this.state.layoutData[index].id
            ) {
              return this.state.layoutData[index];
            } else {
              this.setState({ pageLayoutEdited: true });
              return layoutDataTemp[index];
            }
          });
          this.setState({
            layoutData,
            isChooseModulesEdited: true,
            isDocumentEdited:
              Object.keys(selectedLayoutOptions).length === layoutData.length
          });
        }
      };

      // common function to create layoutData
      handleCreateLayoutData = selectedLayoutOptions => {
        let layoutData = [];
        each(selectedLayoutOptions, item => {
          let activeOption = filter(item.value, function(val) {
            return val.active;
          });

          if (activeOption.length > 0) {
            const obj = {
              layoutId: item.label,
              selectedLayout: activeOption[0].title,
              classString: activeOption[0].classString,
              placeholder: this.placeholderThumbnails(activeOption[0].title),
              id: activeOption[0].id,
              imageMatrix: activeOption[0].imageMatrix
            };

            layoutData.push(obj);
          }
        });

        return layoutData;
      };

      placeholderThumbnails = title => {
        const tempTitle = title;
        const count =
          tempTitle === "2 Col" || tempTitle === "2 Rows"
            ? 2
            : tempTitle === "1 Col 2 Rows" || tempTitle === "2 Rows 1 Col"
            ? 3
            : tempTitle === "4 Quad"
            ? 4
            : 1;
        const placeholder = [];
        for (let i = 0; i < count; i++) {
          const obj = {
            thumbnailSrc: "",
            moduleId: ""
          };
          placeholder.push(obj);
        }
        return placeholder;
      };

      // on click of view(link bellow layout templates)
      handleViewClick = item => {
        this.setState({
          isPopupOpen: true,
          templatePreviewSource: item.previewSrc
        });
      };

      handlePopupClose = () => {
        this.setState({ isPopupOpen: false });
      };

      // client logo edit
      editImage = (img, data) => {
        let clientLogoImageData = { ...this.state.clientLogoImageData };
        if (data) {
          clientLogoImageData.editImageDimensions.left = data.left;
          clientLogoImageData.editImageDimensions.top = data.top;
          clientLogoImageData.editImageDimensions.height = data.height;
          clientLogoImageData.editImageDimensions.width = data.width;
          clientLogoImageData.dataUri = img;
          clientLogoImageData.isEdited = !!this.state.documentId;
        }

        this.setState({ clientLogoImageData, isDocumentEdited: true });
      };

      /**
       * Function to update Image data on new file Upload
       * @param {Array} will have the Default File Array
       * @param {String} Name of the changed input field
       */
      onFileUpload = async file => {
        let clientLogoImageData = { ...this.state.clientLogoImageData };
        clientLogoImageData.dataUri = await this._getDataUri(file);
        clientLogoImageData.isEdited = !!this.state.documentId;
        this.setState({ clientLogoImageData, isDocumentEdited: true });
      };

      /**
       * Function to get the data URI of the file uploaded
       * @param {Array} Array of the file uploaded
       */
      _getDataUri = async file => {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        const result = await new Promise((resolve, reject) => {
          reader.onload = function() {
            resolve(reader.result);
          };
        });
        return result;
      };

      /**
       * layou tab fucnctions starts
       *
       */
      // preview of modules
      onOpenCloseModulePreview = props => {
        const { catagoryModules, Index } = props;

        if (catagoryModules) {
          // Remove error toast message
          ToastUtils.handleToast({ operation: "dismiss" });

          let { selectedLayoutType } = this.state,
            activeSlide = 0;

          const handlePreviewModuleImageData = module => {
            let imageDataArr = module.thumbnails.map((thumbnail, idx) => {
              let { imageMatrix, location } = thumbnail;
              if (selectedLayoutType === imageMatrix) {
                activeSlide = idx;
              }

              return {
                url: location,
                imageType:
                  imageMatrix === "fullpage"
                    ? "Full Page layout"
                    : imageMatrix === "halfpage"
                    ? "Half Page layout"
                    : "quadrant",
                disableAddButton: selectedLayoutType !== imageMatrix,
                imageMatrix
              };
            });

            return imageDataArr;
          };

          let tempModulePreviewData = catagoryModules.map(module => {
            activeSlide = 0;
            return {
              slideTitle: module.fileName || [],
              type: "File",
              imageData: handlePreviewModuleImageData(module),
              moduleId: module.id,
              slideInfo: [
                {
                  title: "Author",
                  value: module.author
                },
                {
                  title: "Date Created",
                  value: module.createdAt
                    ? new moment(module.createdAt).format("MM/DD/YYYY")
                    : ""
                },
                {
                  title: "Date Modified",
                  value: module.updatedAt
                    ? new moment(module.updatedAt).format("MM/DD/YYYY")
                    : ""
                },
                {
                  title: "Description",
                  value: module.description
                }
              ],
              activeSlide: activeSlide
            };
          });

          this.setState({
            activePreviewModuleIndex: Index,
            modulePreviewData: tempModulePreviewData
          });
        } else {
          this.setState({
            modulePreviewData: []
          });
        }
      };

      // Tab change functionality
      onClickTab = ({ tabId, value }) => {
        this.setState({
          selectedTabId: value
        });
      };

      // search functionality for module
      handleModuleSearchChange = e => {
        let state = JSON.parse(JSON.stringify(this.state)),
          {
            isSearchActive,
            moduleList,
            layoutSpecificModuleResults,
            showAllModules
          } = state,
          catagorySearchResults = [],
          randomKey = "",
          preExpandedCatArray = [],
          currentModuleList = showAllModules
            ? moduleList
            : layoutSpecificModuleResults;

        // catagory search
        catagorySearchResults = currentModuleList.map((cat, index) => {
          let moduleSearch = [],
            subCatSearch = [];
          if (!cat) return null;
          // module search
          each(get(cat.children[0], "modules"), mod => {
            if (
              mod.title.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
            ) {
              moduleSearch.push(mod);
            }
          });

          // subcatagory search
          each(get(cat.children[1], "subCategories"), subCat => {
            let subCatModSearch = [];
            // subcatagory module search
            each(get(subCat.children[0], "modules"), subCatMod => {
              if (
                subCatMod.title
                  .toLowerCase()
                  .indexOf(e.target.value.toLowerCase()) >= 0
              ) {
                subCatModSearch.push(subCatMod);
              }
            });

            if (
              subCat.title
                .toLowerCase()
                .indexOf(e.target.value.toLowerCase()) >= 0
            ) {
              subCatSearch.push(subCat);
            } else if (subCatModSearch.length > 0) {
              let tempSubCat = {
                children: [{ modules: subCatModSearch }],
                label: subCat.label,
                title: subCat.title,
                documentTypes: subCat.documentTypes
              };

              subCatSearch.push(tempSubCat);
            }
          });

          if (
            cat.title.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
          ) {
            preExpandedCatArray.push(index);
            return cat;
          } else if (moduleSearch.length > 0 || subCatSearch.length > 0) {
            let tempCat = {
              children: [
                { modules: moduleSearch },
                { subCategories: subCatSearch }
              ],
              label: cat.label,
              title: cat.title,
              documentTypes: cat.documentTypes
            };

            preExpandedCatArray.push(index);
            randomKey = Math.random();
            return tempCat;
          } else {
            return null;
          }
        });

        isSearchActive = e.target.value.length > 0 ? true : false;

        this.setState({
          isSearchActive,
          preExpandedCatArray,
          randomKey,
          catagorySearchResults,
          searchAndLayoutSpecificModules: catagorySearchResults,
          searchValue: e.target.value
        });
      };

      // function to reset search input
      handleResetSearchInput = () => {
        let inputEle = document.querySelector(
            ".module-search .search-wrapper input"
          ),
          randomKey = Math.random();

        if (inputEle) {
          this._fetchModuleList();
          inputEle.value = "";
          this.setState({
            isSearchActive: false,
            searchValue: "",
            catagorySearchResults: [],
            randomKey
          });
        }
      };

      /**
       * for appending escape character if search string contains special characters
       * @param {String} str string to be search for special characters
       */
      escapeRegExp = str => {
        return str.replace(/([*+?^=!:${}()|\[\]\/\\])/g, "\\$1"); // eslint-disable-line no-useless-escape
      };

      /**
       * for highlighlighting search text
       * @param {String} rowVal cell value
       */
      highlightText = rowVal => {
        if (typeof rowVal === "number") {
          rowVal = rowVal.toString();
        }
        if (rowVal && this.state.searchValue) {
          let searchValue = this.state.searchValue.trim();
          searchValue = this.escapeRegExp(searchValue);
          // split row value with separator as the regex of search value
          let parts = rowVal.split(new RegExp(`(${searchValue})`, "gi"));
          return (
            <span>
              {" "}
              {parts.map((part, i) => (
                <span
                  key={i}
                  style={
                    part.toLowerCase() === searchValue.toLowerCase() &&
                    searchValue.length > 2
                      ? { backgroundColor: "#f3c200" }
                      : {}
                  }
                >
                  {part}
                </span>
              ))}{" "}
            </span>
          );
        }
        return <span>{rowVal}</span>;
      };

      /**
       * Function to get selectedLayout thumbnails
       * @param {Array} thumbnails
       * @param {String} selectedType Type of thumbnail
       */
      _getModuleThumbnail = ({ thumbnails, selectedLayoutType }) => {
        let item = { thumbnailLocation: "", isDisabled: false };
        if (
          this.state.showAllModules &&
          thumbnails.length > 0 &&
          selectedLayoutType
        ) {
          let tempItem = thumbnails.filter(
            ele => ele.imageMatrix === selectedLayoutType
          );

          item.thumbnailLocation = tempItem.length
            ? tempItem[0].location
            : thumbnails[0].location;

          item.isDisabled = tempItem.length
            ? false
            : thumbnails[0].imageMatrix !== selectedLayoutType
            ? true
            : false;
        } else if (
          !this.state.showAllModules &&
          thumbnails.length > 0 &&
          selectedLayoutType
        ) {
          let tempItem = thumbnails.filter(
            ele => ele.imageMatrix === selectedLayoutType
          );

          item.thumbnailLocation = tempItem.length ? tempItem[0].location : "";
          item.isDisabled = false;
        } else if (
          thumbnails.length > 0 &&
          !selectedLayoutType &&
          this.state.showAllModules
        ) {
          item.thumbnailLocation = thumbnails[0].location;

          item.isDisabled = false;
        }

        return item;
      };

      // on click of module on left side
      onModuleSelected = props => {
        let {
            selectedLayoutType,
            selectedPlaceholder,
            layoutData,
            documentId
          } = this.state,
          { layoutType, layoutId, index } = selectedPlaceholder;

        let currentThumbnail = 0;

        for (let i = 0; i < get(props.subItem, "thumbnails").length; i++) {
          if (
            props.subItem.thumbnails[i].imageMatrix === selectedLayoutType &&
            currentThumbnail === 0
          ) {
            currentThumbnail = i;
          }
        }

        let matchModule =
          selectedLayoutType ===
          props.subItem.thumbnails[currentThumbnail].imageMatrix;

        if (
          selectedLayoutType &&
          selectedPlaceholder &&
          Object.keys(selectedPlaceholder).length &&
          matchModule
        ) {
          layoutData = this.loopLayout({
            layoutData,
            layoutId,
            layoutType,
            selectedData: {
              index,
              thumbnailSrc: props.thumbnailLocation,
              moduleId: props.moduleId,
              ...props.subItem
            }
          });
        }
        // Show error message if no placeholder was selected before selecting module
        if (!Object.keys(selectedPlaceholder).length) {
          ToastUtils.handleToast({
            operation: "error",
            message:
              "Please select a region in the middle area prior to selecting a topic.",
            autoclose: false,
            ref: this.toastError
          });
        } else if (!matchModule) {
          ToastUtils.handleToast({
            operation: "error",
            message: "This module is not compatible with selected region.",
            autoclose: false,
            ref: this.toastError
          });
        } else {
          this.setState(
            {
              selectedThumbnail: props.thumbnailLocation,
              layoutData,
              isDocumentEdited: true,
              moduleEdited: !!documentId
            },
            () => {
              this._clearSelectedPlaceholder();
            }
          );
        }
      };

      onAddRemovePresentation = (
        presentationAddStatus,
        activeSlideDetails,
        slideNo
      ) => {
        let {
            selectedLayoutType,
            selectedPlaceholder,
            layoutData,
            documentId
          } = this.state,
          { layoutType, layoutId, index } = selectedPlaceholder;

        if (!presentationAddStatus) {
          if (
            selectedLayoutType &&
            selectedPlaceholder &&
            Object.keys(selectedPlaceholder).length
          ) {
            layoutData = this.loopLayout({
              layoutData,
              layoutId,
              layoutType,
              selectedData: {
                index,
                thumbnailSrc: get(activeSlideDetails, [
                  "imageData",
                  slideNo,
                  "url"
                ]),
                moduleId: get(activeSlideDetails, ["moduleId"])
              }
            });
          }
          this.setState(
            {
              selectedThumbnail: get(activeSlideDetails, [
                "imageData",
                slideNo,
                "url"
              ]),
              layoutData,
              isDocumentEdited: true,
              moduleEdited: !!documentId,
              modulePreviewData: []
            },
            () => {
              this._clearSelectedPlaceholder();
            }
          );
        } else {
          let layoutData = [...this.state.layoutData];
          layoutData = this.loopLayout({
            layoutData,
            layoutId: selectedPlaceholder.layoutId,
            layoutType: selectedPlaceholder.layoutType,
            selectedData: {
              index: selectedPlaceholder.index,
              thumbnailSrc: "",
              moduleId: ""
            }
          });
          this.setState(
            {
              layoutData,
              isDocumentEdited: true,
              moduleEdited: !!this.state.documentId,
              modulePreviewData: []
            },
            () => {
              this._clearSelectedPlaceholder();
            }
          );
        }
      };

      // change template link redirect to document setup tab
      handleChangeTemplate = () => {
        this.setState({
          activeStep: 0,
          isChangeTemplateClicked: true
        });
      };

      // on click of on right side panel placeholder
      onPlaceholderClick = props => {
        let {
          layoutId,
          layoutType,
          index,
          id,
          imageMatrix,
          selectedThumbnailId
        } = props;
        // Clear selected module
        this._clearSelectedModule();

        // Remove error toast message
        ToastUtils.handleToast({ operation: "dismiss" });

        this.setState(
          {
            selectedPlaceholder: {
              index,
              id,
              layoutId,
              layoutType,
              imageMatrix,
              selectedThumbnailId
            }
          },
          !this.state.showAllModules ? () => this.handleSpecificModule() : null
        );

        this._changeActiveState(
          {
            layoutId,
            layoutType,
            index
          },
          true
        );
      };

      /**
       * Handle selected module delete
       *
       */
      deleteSelectedModule = props => {
        let layoutData = [...this.state.layoutData];

        layoutData = this.loopLayout({
          layoutData,
          layoutId: props.layoutId,
          layoutType: props.layoutType,
          selectedData: {
            index: props.index,
            thumbnailSrc: "",
            moduleId: ""
          }
        });
        this.setState(
          {
            layoutData,
            isDocumentEdited: true,
            moduleEdited: !!this.state.documentId
          },
          () => {
            this._clearSelectedPlaceholder();
          }
        );
      };

      /**
       * Function to clear the selected module.
       */
      _clearSelectedModule = () => {
        this.setState({
          selectedThumbnail: ""
        });
      };

      _changeActiveState = (data = {}) => {
        let { layoutData } = this.state;
        let tempData = layoutData.map(ele => {
          if (ele.layoutId === data.layoutId) {
            this.setState({
              selectedLayoutType:
                (data.layoutType === "1 Col 2 Rows" && data.index > 0) ||
                (data.layoutType === "2 Rows 1 Col" && data.index < 2) ||
                data.layoutType === "2 Rows" ||
                data.layoutType === "4 Quad"
                  ? "quadrant"
                  : data.layoutType === "Wide Single"
                  ? "fullpage"
                  : "halfpage"
            });
          }
          return {
            layoutId: ele.layoutId,
            isActive: ele.layoutId === data.layoutId,
            selectedLayout:
              ele.layoutId === data.layoutId
                ? data.layoutType
                : ele.selectedLayout,
            classString: ele.classString,
            imageMatrix: ele.imageMatrix,
            placeholder: ele.placeholder,
            id: ele.id
          };
        });
        this.setState({
          layoutData: tempData,
          selectedLayoutId: data.layoutId
        });
      };

      /**
       * Function to clear the selected placeholder.
       */
      _clearSelectedPlaceholder = () => {
        this.setState({
          selectedPlaceholder: {},
          selectedLayoutType: ""
        });
      };

      /**
       *  Function for looping through layout Data.
       *
       * @param {Object} layoutData selected layout consisting of the below keys.
       * @param {String} layoutId unique id/label of the layout.
       * @param {String} layoutType type of layout selected.
       * @param {Object} selectedData selected placeholder data.
       */
      loopLayout = ({ layoutData, layoutId, selectedData }) => {
        layoutData.forEach(ele => {
          if (ele.layoutId === layoutId) {
            if (selectedData) {
              ele["placeholder"][selectedData.index] = {
                thumbnailSrc: selectedData.thumbnailSrc,
                moduleId: selectedData.moduleId,
                ...selectedData
              };
            }
          }
        });

        return layoutData;
      };

      // search functionality for choose content
      handleChooseContentSearchChange = ({ isReset, e }) => {
        if (isReset) {
          let inputEle = document.querySelector(
              ".choose-content-search .search-wrapper input"
            ),
            term = "";

          if (inputEle) {
            inputEle.value = "";
            this.handleChooseContentSearchData(term);
          }
        } else {
          let term = e.target.value.trim();
          if (term.length >= 3 || !term.length) {
            this.handleChooseContentSearchData(term, e.target.value);
          } else {
            this.setState({
              searchValue: ""
            });
          }
        }
      };

      handleChooseContentSearchData = throttle(async (term, val) => {
        let isPreExpanded = !!term.length;

        await this.props.getUsersModules({ term });

        this.props.usersModules &&
          this.moduleListDataFormating(this.props.usersModules, isPreExpanded);

        this.setState({
          searchValue: term.length >= 3 ? val : ""
        });
      }, 1000);

      handleChooseContentChecbox = (moduleItem, docTypes, e) => {
        this.handleSelectedDoctypeArr(docTypes, this.state.selectedDoctype, e);
        this.handleSelectedModuleArr(moduleItem, this.state.selectedModules);
      };

      // selected doctype array
      handleSelectedDoctypeArr = (docTypes, selectedDoctype, e) => {
        if (e.target.checked) {
          selectedDoctype.push(docTypes);
          this.setState({ selectedDoctype });
        } else if (!e.target.checked) {
          this.removefromSelectedDoctype(selectedDoctype, "id", docTypes.id);
        }
      };

      removefromSelectedDoctype = (selectedDoctype, attr, value) => {
        let i = selectedDoctype.length;
        while (i--) {
          if (
            selectedDoctype[i] &&
            selectedDoctype[i].hasOwnProperty(attr) &&
            selectedDoctype[i][attr] === value
          ) {
            selectedDoctype.splice(i, 1);
            return;
          }
        }
        this.setState({ selectedDoctype });
      };

      handleSelectedModuleArr = (moduleItem, selectedModules) => {
        const found = this.checkIfExist(moduleItem.id);
        if (selectedModules.length === 8 && !found) {
          ToastUtils.handleToast({
            operation: "error",
            message: "You have reached the maximum of 8 modules.",
            autoclose: false,
            ref: this.toastError
          });
        } else if (selectedModules.length < 8 && !found) {
          selectedModules.push(moduleItem);
          this.setState(
            {
              selectedModules,
              isChooseModulesEdited: true,
              isModuleChanged: true,
              isDocumentEdited: true
            },
            () => this.handleCompatibleDocumentType(true)
          );
        } else if (selectedModules.length <= 8 && found) {
          ToastUtils.handleToast({ operation: "dismiss" });
          this.removefromSelectedModules(selectedModules, "id", moduleItem.id);
        }
      };

      checkIfExist = id => {
        let selectedModules = this.state.selectedModules;
        return selectedModules.some(el => el.id === id);
      };

      removefromSelectedModules = (selectedModules, attr, value) => {
        let i = selectedModules.length;
        while (i--) {
          if (
            selectedModules[i] &&
            selectedModules[i].hasOwnProperty(attr) &&
            selectedModules[i][attr] === value
          ) {
            selectedModules.splice(i, 1);
          }
        }
        this.setState(
          {
            selectedModules,
            isChooseModulesEdited: true,
            isModuleChanged: true,
            isDocumentEdited: true
          },
          () => this.handleCompatibleDocumentType(true)
        );
      };

      handleBackClick = () => {
        this.setState({ chooseContent: false });
      };

      // Function to show and hide document type buttons based on selected modules
      handleCompatibleDocumentType = isModuleCheckboxClicked => {
        let { selectedModules, selectedDoctype } = this.state,
          matchModules = [],
          placemat = false,
          brochure = false,
          flier = false;

        each(selectedDoctype, item => {
          each(item.documents, doc => {
            if (doc.title === "Placemat" && doc.active && !placemat)
              placemat = true;

            if (doc.title === "Brochure" && doc.active && !brochure)
              brochure = true;

            if (doc.title === "Flier" && doc.active && !flier) {
              each(selectedModules, module => {
                each(module.thumbnails, thumbnail => {
                  if (
                    thumbnail.imageMatrix === "halfpage" ||
                    thumbnail.imageMatrix === "quadrant"
                  )
                    flier = true;
                });
              });
            }
          });
        });

        if (selectedModules.length > 7) {
          matchModules = placemat ? ["Select Placemat"] : [];
        } else if (selectedModules.length > 4 && selectedModules.length < 8) {
          matchModules =
            placemat && brochure
              ? ["Select Placemat", "Select Brochure"]
              : brochure && !placemat
              ? ["Select Brochure"]
              : placemat && !brochure
              ? ["Select Placemat"]
              : [];
        } else if (selectedModules.length < 5) {
          matchModules =
            placemat && brochure && flier
              ? ["Select Placemat", "Select Flier", "Select Brochure"]
              : brochure && flier && !placemat
              ? ["Select Flier", "Select Brochure"]
              : placemat && flier && !brochure
              ? ["Select Placemat", "Select Flier"]
              : placemat && brochure && !flier
              ? ["Select Placemat", "Select Brochure"]
              : flier && !placemat && !brochure
              ? ["Select Flier"]
              : placemat && !brochure && !flier
              ? ["Select Placemat"]
              : brochure && !placemat && !flier
              ? ["Select Brochure"]
              : [];
        }

        this.setState({
          matchModules,
          isDocumentEdited: isModuleCheckboxClicked
        });
      };

      // choose content select document type button click
      handleCompatibleDocumentClick = clickedButton => {
        let state = JSON.parse(JSON.stringify(this.state)),
          { documentTypeOption, templateTypes, selectedOption } = state;

        let selectedDocument =
          clickedButton === "Select Placemat"
            ? "Placemat"
            : clickedButton === "Select Flier"
            ? "Flier"
            : "Brochure";

        let selectedDocumentType = documentTypeOption.filter(
          ele => ele.value === selectedDocument
        );

        let selectedLayoutOptions =
          templateTypes[selectedDocument.toLowerCase()];

        each(Object.keys(selectedLayoutOptions), item => {
          each(selectedLayoutOptions[item].value, val => {
            val.active = false;
          });
        });

        each(Object.keys(selectedLayoutOptions), item => {
          selectedLayoutOptions[item].value.some((val, idx) => {
            let tempBreak = false;
            each(this.state.selectedModules, (module, i) => {
              if (
                get(module.thumbnails[0], "imageMatrix") === val.imageMatrix &&
                val.title !== "No Back"
              ) {
                val.active = true;
                tempBreak = true;
              } else if (
                selectedLayoutOptions[item].value.length === idx + 1 &&
                this.state.selectedModules.length === i + 1 &&
                !tempBreak
              ) {
                selectedLayoutOptions[item].value[0].active = true;
                tempBreak = true;
              }
            });
            if (tempBreak) return true;
            else return false;
          });
        });

        templateTypes[selectedDocument.toLowerCase()] = selectedLayoutOptions;

        let layoutData = this.handleCreateLayoutData(selectedLayoutOptions);

        if (
          this.state.documentId &&
          selectedOption &&
          (selectedDocumentType[0].value !== selectedOption.value ||
            (selectedDocumentType[0].value === selectedOption.value &&
              this.state.isChooseModulesEdited &&
              this.state.layoutData.length))
        ) {
          DeleteConfirmationAlert({
            message: UI_STRINGS.DOCUMENT_TYPE_CHANGE_MESSAGE,
            onYesClick: () => {
              this._clearSelectedPlaceholder();

              this.setState(
                {
                  selectedOption: selectedDocumentType[0],
                  layoutData,
                  documentByChooseContent: true,
                  isDocumentEdited: true,
                  isDocumentTypeEdited: true,
                  completedSteps: [],
                  pageLayoutEdited: true,
                  templateTypes,
                  isChooseModulesEdited: false
                },
                () => this.handleCreateDocument(true, 1)
              );
            }
          });
        } else {
          // in brochure default one layout selected so we get layout length = 1
          this.setState(
            (state, props) => ({
              pageLayoutEdited:
                !state.layoutData.length ||
                (state.layoutData.length === 1 &&
                  selectedDocumentType[0].value === "Brochure"),

              selectedOption: selectedDocumentType[0],

              ...((!state.layoutData.length ||
                (state.layoutData.length === 1 &&
                  selectedDocumentType[0].value === "Brochure")) && {
                layoutData
              }),

              documentByChooseContent: true,

              templateTypes,

              isDocumentTypeEdited: !(
                state.selectedOption &&
                state.selectedOption.value === selectedDocumentType[0].value
              ),

              isDocumentEdited:
                !state.layoutData.length ||
                (state.layoutData.length === 1 &&
                  selectedDocumentType[0].value === "Brochure"),

              isChooseModulesEdited: false
            }),
            () => this.handleCreateDocument(true, 1)
          );
        }
      };

      openExapand = () => {
        let preExapandedFlag = !this.state.preExapandedFlag;
        this.setState({
          preExapandedFlag,
          randomKey: Math.random()
        });
      };

      handleModuleRadioChange = activeModuleFilter => {
        this.setState({ activeModuleFilter }, () =>
          this.handleModuleFilterOnRadioChange()
        );
      };

      handleModuleFilterOnRadioChange = () => {
        let { activeModuleFilter, selectedPlaceholder } = this.state;

        if (activeModuleFilter === "layoutSpecificModule") {
          this.setState({ showAllModules: false });
          if (!Object.keys(selectedPlaceholder).length) {
            this.setState({
              selectedLayoutType: "",
              searchAndLayoutSpecificModules: []
            });
          } else {
            this.handleSpecificModule();
          }
        } else {
          this.handleSpecificModule();
          this.setState({
            showAllModules: true,
            layoutSpecificModuleResults: []
          });
        }
      };

      handleSpecificModule = () => {
        let state = JSON.parse(JSON.stringify(this.state)),
          layoutSpecificModuleResults = [],
          preExpandedCatArray = [],
          randomKey = "",
          {
            moduleList,
            selectedLayoutType,
            catagorySearchResults,
            isSearchActive
          } = state,
          currentModuleList = isSearchActive
            ? catagorySearchResults
            : moduleList;

        // catagory filter
        layoutSpecificModuleResults = currentModuleList.map((cat, index) => {
          let moduleFilter = [],
            subCatFilter = [];
          if (!cat) return null;
          each(get(cat.children[0], "modules"), mod => {
            let compatibleThumbnails = [];
            each(mod.thumbnails, ele => {
              // moduleFilter.thumbnails.push(ele)
              if (ele.imageMatrix === selectedLayoutType) {
                compatibleThumbnails.push(ele);
              }
            });

            if (compatibleThumbnails.length) {
              mod.thumbnails = compatibleThumbnails;
              moduleFilter.push(mod);
            }
          });

          // subcatagory filter
          each(get(cat.children[1], "subCategories"), subCat => {
            let subCatModFilter = [];
            // subcatagory module filter
            each(get(subCat.children[0], "modules"), subCatMod => {
              let compatibleSubCatThumbnails = [];
              each(subCatMod.thumbnails, subele => {
                if (subele.imageMatrix === selectedLayoutType) {
                  compatibleSubCatThumbnails.push(subele);
                }
              });

              if (compatibleSubCatThumbnails.length) {
                subCatMod.thumbnails = compatibleSubCatThumbnails;
                subCatModFilter.push(subCatMod);
              }
            });

            if (subCatModFilter.length > 0) {
              let tempSubCat = {
                children: [{ modules: subCatModFilter }],
                label: subCat.label,
                title: subCat.title,
                documentTypes: subCat.documentTypes
              };

              subCatFilter.push(tempSubCat);
            }
          });

          if (moduleFilter.length > 0 || subCatFilter.length > 0) {
            let tempCat = {
              children: [
                { modules: moduleFilter },
                { subCategories: subCatFilter }
              ],
              label: cat.label,
              title: cat.title,
              documentTypes: cat.documentTypes
            };

            preExpandedCatArray.push(index);
            randomKey = Math.random();
            return tempCat;
          } else {
            return null;
          }
        });

        this.setState({
          layoutSpecificModuleResults,
          preExpandedCatArray,
          randomKey,
          searchAndLayoutSpecificModules: layoutSpecificModuleResults
        });
      };

      render() {
        const $this = this;
        /** Merge States and Methods */
        const stateMethodProps = {
          ...$this,
          ...$this.state,
          ...$this.props
        };

        return <Main {...stateMethodProps} />;
      }
    }
  );

export default Container;
