//Lib
import React, {useEffect, useState} from "react";
import styled, { css } from "styled-components";
import { default as ReactSelect } from "react-select";

//Components
import Container from "./container";
import hexToRgba from "utils/hexToRgba";
import { Close } from "assets/icons";
import CheckBox from "../checkbox";
import { getContentRepositoryAcls } from "./services";

const normalizeAcls = aclsResponse => {
  if (!aclsResponse || !Array.isArray(aclsResponse)) {
    return [];
  }
  return aclsResponse.map(aclEntry => ({
    label: aclEntry.who,
    value: aclEntry._id
  }));
};

const getAcls = async (contentRepoId) => {
  const url = `/content-repositories/${contentRepoId}/acls`;
  const response = await getContentRepositoryAcls(url);
  return response;
};

const getAclsOptions = async () => {
  // @todo mock
  return {
    success: true,
    data: [{
      _id: '64fe9e7c96b45d6492449068',
      who: 'Everyone',
      what: {
        idType: 'repository',
        id: 'repository_id'
      },
      permissions: ['List', 'Read']
    }]
  }
};

const contentRepoShareForm = ({
                                 noteCharsRemaining,
                                 manageInputStates,
                                 handleShareChange,
                                 setAclsOptions,
                                 handleNotifyPeopleChange,
                                 form,
                                 overlayHandler,
                                 formHandler,
                                 isSharing,
                                 isRepeatedEmail,
                                  notifyPeople
                               }) => {
  const descriptionHeadingText = `Grant/Revoke access to "${form.contentRepoOptions.name}"`;

  const [contentRepositoryAclsOptions, setContentRepositoryAclsOptions] = useState([]);

  const handleSelectChange = (value) => {
    if (value) {
      form.selectedContentRepositoryAcls = value;
      handleShareChange(value)
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      (async () => {
        // get acls options
        const aclsOptions = await getAclsOptions();
        // set acls options
        setAclsOptions(aclsOptions.data);

        // get normalized acls options for select component
        const normalizedAclsOptions = normalizeAcls(aclsOptions.data);
        // set normalized acls options for select component
        setContentRepositoryAclsOptions(normalizedAclsOptions);

        // get selected content repository acls
        const acls = await getAcls(form.contentRepoOptions.id);
        // get normalized acls options for selected content repository
        const normalizedAcls = normalizeAcls(acls);
        handleSelectChange(normalizedAcls);
      })();
    }

    return () => mounted = false
  }, []);

  return (
    <ConfirmBoxWrapper>
      <ConfirmBoxOverlay onClick={overlayHandler} />
      <ConfirmBox className="form-overlay">
        <CloseIconWrapper>
          <CloseIcon onClick={overlayHandler} />
        </CloseIconWrapper>
        <MainHeading>Grant/Revoke Access</MainHeading>
        <DescriptionWrapper>
          <DescriptionHeading>{descriptionHeadingText}</DescriptionHeading>
          <SelectContainer>
            <ReactSelect
              isMulti
              onChange={handleSelectChange}
              value={form.selectedContentRepositoryAcls}
              options={contentRepositoryAclsOptions}
              placeholder={"Add people and groups"}
            />
          </SelectContainer>
          {form.shareIds.error ? <Error>{form.shareIds.error}</Error> : null}
          <CheckBoxWrapper>
            <CheckBox
              label={"Notify people"}
              id={1}
              handleChange={(event) => handleNotifyPeopleChange(event)}
            />
          </CheckBoxWrapper>
          {notifyPeople && <TextBoxWrapper>
            <TextArea
              maxLength={500}
              onChange={(e) =>
                manageInputStates({ propName: "note", value: e.target.value })
              }
            />
            <TextAreaHelperText>
              {noteCharsRemaining - form.note.value.length} characters remaining
            </TextAreaHelperText>
           </TextBoxWrapper>
          }
          <ShareButton
            onClick={() => formHandler()}
            isSharing={isSharing}
            disabled={!!(isSharing || isRepeatedEmail)}
          >
            {isSharing ? "Sending" : "Done"}
          </ShareButton>
        </DescriptionWrapper>
      </ConfirmBox>
    </ConfirmBoxWrapper>
  );
};

contentRepoShareForm.defaultProps = {
  overlayHandler: () => {},
  submitText: "Share",
};

const SelectContainer = styled.div`
  padding: 0 1px;
  margin-bottom: 20px;
  font-size: 12px;
`;

const SharedHelperTextCss = css`
  ${(props) => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 10px;
  color: ${(props) => props.theme.COLOR.HEADING};
  opacity: 0.8;
  margin-top: 7px;
  display: inline-block;
`;

const ShareButton = styled.button`
  ${(props) => props.theme.SNIPPETS.SHARED_BUTTON_CSS}
  color: ${(props) => props.theme.COLOR.WHITE};
  background-color: ${(props) => props.theme.COLOR.USER_PRIMARY};
  width: 100%;
  margin-top: 30px;
  opacity: ${(props) => (props.isSharing ? 0.5 : 1)};
  cursor: ${(props) => (props.isSharing ? "not-allowed" : "pointer")};

  &:hover {
    color: ${(props) =>
  props.isSharing
    ? props.theme.COLOR.WHITE
    : props.theme.COLOR.USER_PRIMARY};
    background-color: ${(props) =>
  props.isSharing
    ? props.theme.COLOR.USER_PRIMARY
    : props.theme.COLOR.WHITE};
    border: ${(props) => (props.isSharing ? "none" : "1px solid")};
  }
`;

const SharedHeadingTextCss = css`
  ${(props) => props.theme.SNIPPETS.FONT_STYLE};
  font-size: 12px;
  color: ${(props) => props.theme.COLOR.HEADING};
  font-weight: bold;
  opacity: 0.64;
  margin-bottom: 4px;
  display: inline-block;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  display: inline-block;
`;

const DescriptionHeading = styled.span`
  ${SharedHeadingTextCss};
  margin-bottom: 20px;
`;

const TextAreaHelperText = styled.span`
  ${SharedHelperTextCss};
`;

const TextBoxHeading = styled.span`
  ${SharedHeadingTextCss};
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 3px;
  resize: none;
  height: 80px;
  border-radius: 4px;
  ${(props) => props.theme.SNIPPETS.FONT_STYLE};
  color: ${(props) => hexToRgba(props.theme.COLOR_PALLETE.GREY, 0.8)};
  font-family: ${(props) => props.theme.FONT.LATO};
  font-size: 12px;
  &:focus {
    outline: none;
  }
`;

const TextBoxWrapper = styled.div`
  margin-top: 5px;
`;

const MainHeading = styled.span`
  display: block;
  ${(props) => props.theme.SNIPPETS.FONT_STYLE};
  opacity: 0.7;
  font-weight: bold;
  font-size: 30px;
  color: ${(props) => props.theme.COLOR.HEADING};
  margin-bottom: 20px;
`;

const ConfirmBoxWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

const ConfirmBox = styled.div`
  width: 66.51%;
  padding: 31px 40px 37px;
  position: fixed;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.COLOR.WHITE};
  z-index: 20;
  @media (max-width: 968px) {
    width: 518px;
  }
  @media (min-width: 1025px) {
    width: 681px;
  }
`;

const CloseIcon = styled(Close)`
  transform: translate(-50%, -50%);
  margin-left: 50%;
  margin-top: 50%;
  width: 26px;
  height: 26px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -13px;
  background-color: ${(props) => props.theme.COLOR.WHITE};
  width: 34px;
  border-radius: 50%;
  height: 34px;
  cursor: pointer;
`;

const ConfirmBoxOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => hexToRgba(props.theme.COLOR.BLACK, 0.36)};
  z-index: 20;
`;

const Error = styled.span`
  position: absolute;
  bottom: -10px;
  left: 0;
  color: ${(props) => hexToRgba(props.theme.COLOR_PALLETE.ERROR, "1")};
  ${(props) => props.theme.SNIPPETS.FONT_STYLE}
  font-size: 10px;
  font-weight: normal;
  pointer-events: none;
`;

const CheckBoxWrapper = styled.div`
  input {
    margin-right: 12px;
  }
`;

export default Container(contentRepoShareForm);
