import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { get } from "lodash";

const Image = props => {
  let { getImageHandler, imageIndex, data } = props;

  useEffect(() => {
    (async () => {
      if (
        (!get(data, `dataUri`) ||
          (get(data, `isStatusCurrent`) &&
            !get(data, `isFinalPreviewFetched`))) &&
        !get(data, `isFinalPreviewFetched`)
      ) {
        await getImageHandler(
          get(data, `thumbnail`),
          imageIndex,
          get(data, `isStatusCurrent`)
        );
      }
    })();
  }, [get(data, `dataUri`), get(data, `isFinalPreviewFetched`)]);

  return (
    <>
      <ImageWrapper>
        <PageImage src={get(data, `dataUri`)} />
      </ImageWrapper>
    </>
  );
};

const ImageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-height: 200px;
  min-height: 149px;
`;

const PageImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-height: 150px;
  max-width: 136px;
`;

export default withTheme(Image);
