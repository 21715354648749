import React, { Component } from "react";
import Module from "./index";

class ModulePage extends Component {
  state = {};

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Module {...stateMethodProps} />;
  }
}

export default ModulePage;
