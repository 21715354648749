import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import FetchUtilsV2 from 'utils/FetchUtilsV2';
import { osName, osVersion, browserName, browserVersion } from 'react-device-detect';

import Auth from "AuthLogin";

import App from "pages/app";
import history from "../history";
import profileContextWrapper from "utils/profileContextWrapper";

// Authentication routes
import AuthRoutes, { authRoutesList } from "./Auth";

// Common routes
import Users from "./Users";
import Reports from "./reports";

// Optum Home Page
import OptumHomePage from "../pages/optumHomePage/routes";

// Master Home Page
import MasterHomePage from "../pages/masterHomePage/routes";

// Reset Password
import ResetPassword from "./ResetPassword";

// Renewal routes
import RenewalRoutes from "tools/renewal/routes";

// Presentation routes
import PresentationRoutes from "tools/presentation/routes";

// Marketing Material routes
import MarketingMaterialRoutes from "tools/marketingMaterial/routes";

// Summary Maker routes
import SummaryMakerRoutes from "tools/summaryMaker/routes";

// Library routes
import Library from "tools/library/routes";
import Loader from "components/loader";

// initiate Auth0 instance
const auth = new Auth(window.location.origin);

// check auth session and redirect if not authenticated
const checkAuthSession = (Component, props) => {
  const isOnAuthRoutes = authRoutesList.includes(window.location.pathname);

  let checkIfUserLoggedIn =
    localStorage.getItem("isLoggedIn") && localStorage.getItem("token");

  //check if reset password route is active
  if (props) {
    let { pathname } = props.history.location;
    if (!checkIfUserLoggedIn && pathname === "/password-reset") {
      return Component;
    }
    // checking authCookie to handle SSO between old & new app
    if (!isOnAuthRoutes && !auth.isAuthCookiePresent()) {
      props.history.push('/logout');
    }
  }

  return checkIfUserLoggedIn ? Component : <Redirect to="/login" />;
};

// merge all routes in allRoutesList array
const allRoutesList = auth => [
  AuthRoutes(auth, history, checkAuthSession),
  ResetPassword(auth, checkAuthSession),
  PresentationRoutes(auth, checkAuthSession),
  RenewalRoutes(auth, checkAuthSession),
  MarketingMaterialRoutes(auth, checkAuthSession),
  Users(auth, checkAuthSession),
  Reports(auth, checkAuthSession),
  SummaryMakerRoutes(auth, checkAuthSession),
  Library(auth, checkAuthSession),
  OptumHomePage(auth, checkAuthSession),
  MasterHomePage(auth, checkAuthSession)
];

const TrackPageviewEvent = () => {
  const location = useLocation();
  const searchSlideResultsFromStore = useSelector(state => state.SUCCESS_USER_PROFILE);
  const activeUser = searchSlideResultsFromStore && searchSlideResultsFromStore.userProfileMeta
    ? searchSlideResultsFromStore.userProfileMeta
    : false;

  useEffect(() => {
    (async () => {
      if (!activeUser) {
        return;
      }

      // call api-v2 to record PAGEVISIT event
      await FetchUtilsV2.postData(
        '/events',
        {
          type: 'PAGEVISIT',
          data: {
            app_version: process.env.REACT_APP_VERSION || 'dev',
            browser_name: browserName,
            browser_version: browserVersion,
            current_url: window.location.href,
            screen_height: window.innerHeight,
            screen_width: window.innerWidth,
            os_name: osName,
            os_version: osVersion,
            tool: 'PMA',
            user_id: activeUser._id,
            user_email: activeUser.email,
            user_name: activeUser.name,
          },
        }
      );
    })();
  }, [location.key, activeUser]);

  return null;
};

// App route components
const AppRoutes = () => {
  const history = useHistory();

  const isOnAuthRoute = authRoutesList.includes(window.location.pathname);
  const [sessionRenewPending, setSessionRenewPending] = useState(
    auth.isAuthCookiePresent() && !isOnAuthRoute
  );

  useEffect(() => {
    if (!auth.isAuthCookiePresent() && !isOnAuthRoute) {
      return history.push('/logout')
    }

    if (auth.isAuthCookiePresent() && !isOnAuthRoute) {
      auth.renewSession(() => setSessionRenewPending(false))
    }
  }, [])

  // this loader hides blinking login page when session is being refreshed
  // by authCookie / SSO (user logged in new app)
  if (sessionRenewPending) {
    return <Loader />
  }

  return (
    <>
      <TrackPageviewEvent />

      <Route
        path="/"
        render={props =>
          checkAuthSession(profileContextWrapper(App, auth, props), props)
        }
      />
      {allRoutesList(auth)}
    </>
  );
}

export default AppRoutes;
