import { ThemeTypes } from "tools/library/store/types";
import { get } from "lodash";
import ToastUtils from "utils/handleToast";
import FetchUtils from "utils/FetchUtils";

const isThemeDataLoading = (
  payload = {},
  type = ThemeTypes.LOADING_THEME_LIST
) => {
  return {
    type,
    payload
  };
};

const onThemeListSuccess = (
  payload = {},
  type = ThemeTypes.SUCCESS_THEME_LIST
) => {
  return {
    type,
    payload
  };
};

const coverSuccess = (payload = null, type = ThemeTypes.SUCCESS_COVER) => {
  return {
    type,
    payload
  };
};

const coverLoading = (payload = null, type = ThemeTypes.LOADING_COVER) => {
  return {
    type,
    payload
  };
};

const getThemeList = contentRepositoryId => async dispatch => {
  const URL = `/content-repositories/${contentRepositoryId}/themes`;

  // start loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: true
    })
  );

  const response = await FetchUtils.getData(URL);

  // stop loading
  dispatch(
    isThemeDataLoading({
      isThemeLoading: false
    })
  );

  if (response.success && response.data) {
    //dispatch data list on success
    dispatch(
      onThemeListSuccess({
        themeListData: response.data
      })
    );
  } else {
    ToastUtils.handleToast({
      operation: "error",
      message: get(response, "data.error.message")
    });
  }

  //return reponse to check in component if required
  return response;
};

/**  Get covers list data
 *  @param {String} - contentRepoId
 *
 */
const getCoverList = (contentRepo, selectedTheme) => async dispatch => {
  const URL = `/${contentRepo}/${selectedTheme}/slides?type=Cover`;

  // start loading
  dispatch(coverLoading({ isCoverListLoading: true }));

  const response = await FetchUtils.getData(URL, "Get Cover list==>");

  if (response.success && response.data) {
    dispatch(coverSuccess({ coverList: response.data }));

    // stop loading
    dispatch(coverLoading({ isCoverListLoading: false }));
  } else {
    // stop loading
    dispatch(coverLoading({ isCoverListLoading: false }));

    ToastUtils.handleToast({
      operation: "error",
      message:
        response.data && response.data.error && response.data.error.message
    });
  }

  return response;
};

export { getThemeList, getCoverList };
