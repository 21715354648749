import React from "react";

//components
import Container from "./container.jsx";
import ViewMore from "./components/viewMore";

const AnnouncementPopup = props => {
  let {
    isAnnouncementLoading,
    showAll,
    userProfileMeta,
    showAllAnnouncement,
    announcementList
  } = props;

  return (
    <>
      {showAll ? (
        <ViewMore
          isAnnouncementLoading={isAnnouncementLoading}
          userProfileMeta={userProfileMeta}
          showAllAnnouncement={showAllAnnouncement}
          announcementList={announcementList}
          {...props}
        />
      ) : null}
    </>
  );
};

export default Container(AnnouncementPopup);
