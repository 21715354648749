// libraries
import React, { Component } from "react";
// components
import Topics from "./index";
import {
  get,
  filter,
  isEqual,
  toLower,
  each,
  upperFirst,
  find,
  map
} from "lodash";
import { connect } from "react-redux";

// services
import { setBlockContentData } from "tools/summaryMaker/store/actions/contentBlockActions";

const mapStateToProps = state => {
  const {
    domain: {
      REQUEST_BLOCK_CONTENT,
      RECEIVE_BLOCK_CONTENT,
      RECEIVE_TEMPLATE_BLOCKS,
      RECEIVE_BUILD_DOCUMENT_DATA
    }
  } = state;

  return {
    ...REQUEST_BLOCK_CONTENT,
    ...RECEIVE_BLOCK_CONTENT,
    ...RECEIVE_TEMPLATE_BLOCKS,
    ...RECEIVE_BUILD_DOCUMENT_DATA
  };
};

const mapDispatchToProps = {
  setBlockContentData
};

class TopicsPage extends Component {
  state = {
    isLayoutTypeThumbnail: true,
    modules: []
  };

  componentDidMount() {
    this.getTopicsBlockData();
    this.props.handleStateChange({
      key: "isDataEdited",
      value: true
    });
  }

  getTopicsBlockData = () => {
    let { topicsContentBlock } = this.props;
    let {
      activePlaceholderDetails,
      layoutDetails,
      topicsSelectedType,
      selectedTemplate
    } = this.props;

    this.setState({
      modules: topicsContentBlock
    });

    // remove this hard coded filter once "none" option is shown as filter will not be present at first
    let topicsLastSavedDetails = get(
      this.props,
      `currentDocumentData.blocks.topics`
    );

    let lastSelectedOption = get(this.props, [
      "currentDocumentData",
      "blocks",
      "topics",
      "pageLayouts",
      0,
      "layout"
    ]);

    /***
     * allTypesFromLayoutTemplateData @Object which has the TemplateBlockData
     * layoutTemplateDataArray is the covert the @Object into @Array and convert each starting String to UpperCase
     *
     **/
    let allTypesFromLayoutTemplateData;

    selectedTemplate.blocks.map(blocksData => {
      if (blocksData.name.toLowerCase() === "topics") {
        allTypesFromLayoutTemplateData = blocksData.layoutOptions;
      }
    });

    let layoutTemplateDataArray = map(
      allTypesFromLayoutTemplateData,
      templateData => {
        return upperFirst(templateData);
      }
    );

    if (
      !activePlaceholderDetails.length &&
      !topicsSelectedType &&
      !lastSelectedOption
    ) {
      // setting activePlaceholders details so that activedetail has Array[0]th index layout as default
      this.setActivePlaceholderDetails(
        layoutDetails,
        layoutTemplateDataArray[0]
      );
      this.props.handleStateChange({
        key: "topicsSelectedType",
        value: layoutTemplateDataArray[0]
      });
      this.filterTopicModules(layoutTemplateDataArray[0]);
    } else {
      let selectedType =
        topicsSelectedType ||
        (activePlaceholderDetails[layoutDetails[0]._id] &&
          activePlaceholderDetails[layoutDetails[0]._id].type) ||
        lastSelectedOption ||
        layoutTemplateDataArray[0];

      this.props.handleStateChange({
        key: "topicsSelectedType",
        value: selectedType
      });

      this.filterTopicModules(selectedType);
    }

    // if in edit mode i.e pagelayouts is present in current document data
    if (
      get(topicsLastSavedDetails, `pageLayouts`) &&
      get(this.props.topicsContentBlock, `length`) &&
      !this.props.isPageLayoutSet &&
      this.props.match.params.documentId
    ) {
      this.setLastSavedTopics(topicsLastSavedDetails);
    }
  };

  setLastSavedTopics = topicsLastSavedDetails => {
    let pageLayouts = get(topicsLastSavedDetails, `pageLayouts`);
    let { layoutDetails } = this.props;
    each(pageLayouts, eachPageLayout => {
      each(layoutDetails, eachLayout => {
        let oldLayout = eachLayout.layoutTypeDetails || {};
        let selectedType = oldLayout[upperFirst(eachPageLayout.layout)];

        //check if the pagelayout is already set if not set the last saved details as default
        if (!this.props.isPageLayoutSet) {
          this.setLayoutTypeDetails(selectedType, eachPageLayout);
        }
      });
    });

    this.props.handleStateChange({
      key: "isPageLayoutSet",
      value: true
    });

    // setting activePlaceholderDetails
    this.setActivePlaceholderDetails(layoutDetails, pageLayouts[0].layout);

    // setting layoutDetails
    this.props.handleStateChange({
      key: "layoutDetails",
      value: layoutDetails
    });
  };

  setActivePlaceholderDetails = (layoutDetails, selectedLayout) => {
    let activePlaceholderDetails = [];
    this.filterTopicModules(selectedLayout);

    // if pageLayouts is set just filter the modules and return
    if (this.props.isPageLayoutSet) {
      return;
    }

    activePlaceholderDetails[layoutDetails[0]._id] =
      layoutDetails[0].layoutTypeDetails[upperFirst(selectedLayout)];

    this.props.handleStateChange({
      key: "activePlaceholderDetails",
      value: activePlaceholderDetails
    });
  };

  setLayoutTypeDetails = (selectedType, currentPageLayout) => {
    let topicsContentBlock = this.props.topicsContentBlock;
    let toBeFilteredModules = [];

    each(selectedType, (eachType, index) => {
      eachType.selectedThumbnail = find(
        topicsContentBlock,
        eachBlock => eachBlock._id === currentPageLayout.content[index]
      );

      toBeFilteredModules.push(eachType.selectedThumbnail);
    });

    let filteredModules = filter(topicsContentBlock, eachBlock => {
      return toBeFilteredModules.indexOf(eachBlock) === -1;
    });

    this.props.setBlockContentData(filteredModules, "topicsContentBlock");
    return selectedType;
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.topicsContentBlock, this.props.topicsContentBlock) &&
      this.props.topicsSelectedType !== "None"
    ) {
      this.getTopicsBlockData();
    }
  }

  // remove content item after drop from content palette list
  contentItemRemovalHandler = (contentItemId, contentItem) => {
    let { topicsContentBlock } = this.props;
    let modules = filter(topicsContentBlock, eachModule => {
      return get(eachModule, `_id`) !== contentItemId;
    });

    this.setState({
      modules
    });

    this.props.setBlockContentData(modules, "topicsContentBlock");
    // filtering again so that the filter stays put
    this.filterTopicModules(get(contentItem, `layout`));
  };

  // called after cross click so that removed module is added to the list and  is again available for drag
  setModules = moduleItem => {
    let { modules } = this.state;
    let { topicsContentBlock } = this.props;

    modules.push(moduleItem);

    // set topicsContentBlock also since modules contain filtered data
    topicsContentBlock.push(moduleItem);
    this.setState({
      modules
    });

    this.props.setBlockContentData(topicsContentBlock, "topicsContentBlock");
  };

  // filter modules
  filterTopicModules = eachType => {
    let { topicsContentBlock } = this.props;
    if (toLower(eachType) === "none") {
      this.setState({
        modules: []
      });
      return;
    }

    let filteredTopics = filter(topicsContentBlock, eachTopic => {
      return toLower(get(eachTopic, `layout`)) === toLower(eachType);
    });

    this.setState({
      modules: filteredTopics
    });
  };

  handleModuleStateChange = ({ key, value, cb }) => {
    this.setState(
      {
        [key]: value
      },
      () => {
        cb && cb();
      }
    );
  };

  render() {
    /** Merge States and Methods */
    const stateMethodProps = {
      ...this,
      ...this.state,
      ...this.props
    };

    return <Topics {...stateMethodProps} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicsPage);
